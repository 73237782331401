// Import dependencies
import React, { useState } from 'react';
import { activate } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';


// Import CSS & assets
import './ActivateForm.css';

// Import components
import Logo from '../../common/logo/Logo';


const ActivateForm = () => {

    // Get activation token from url
    const { activationToken } = useParams();

    // Get name from url
    const [searchParams, setSearchParams] = useSearchParams();
    function getUserName() {
        return searchParams.get("username");
    }

    // Set hooks
    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    // Logo
    const [logoAnimate, setLogoAnimate] = useState(false);
    // User
    const [user, setUser] = useState(null);

    // Set global funcitons
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Set specific functions
    // Loading
    const handleLoading = () => {
        setIsLoading(true);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }
    const handleLoadedSuccess = () => {
        setIsLoading(false);
        setIsLoadedSuccess(true);
        showLogo();
        // Redirect if its sign in success
        setTimeout(() => {
            navigate('/dashboard');
        }, 4000);
    }
    const handleSuccess = (msg) => {
        console.log("SUCCESS");
        handleLoadedSuccess();
    }
    const handleLoadedFail = () => {
        setIsLoading(false);
        setIsLoadedFail(true);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
        handleLoadedFail();
    }
    // Logo
    const showLogo = () => {
        setLogoAnimate(true);
    }
    // Activation
    const handleActivation = () => {
        if (!isLoadedSuccess && !isLoadedFail) {
            console.log("ACTIVATION IN PROGRESS");
            console.log("activationToken:", activationToken);
            handleLoading();
            dispatch(activate({ activationToken }, handleSuccess, handleFail)); // navigate sert a faire une redirection après
        }
    }


    return (
        <>
            <div className="auth__activateform">
                {/* <button onClick={handleLoading}>LOADING</button>
                <button onClick={handleLoadedSuccess}>SUCCESS</button>
                <button onClick={handleLoadedFail}>FAIL</button> */}
                <Logo state={logoAnimate} />
                {getUserName() !== null ?
                    <h1>Bienvenue {getUserName()} ! 👋</h1>
                    :
                    <h1>Bienvenue ! 👋</h1>
                }
                <button className={`auth__activateform_button ${isLoading ? "loading" : ""} ${isLoadedSuccess ? "loadedSuccess" : ""} ${isLoadedFail ? "loadedFail" : ""}`} onClick={handleActivation}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Activer mon compte"}</button>
                <h4 className={isLoadedFail ? "auth__activateform_message-error" : ""}>{isLoading ? "Activation de votre compte en cours..." : isLoadedSuccess ? "Votre compte a été activé avec succes!" : isLoadedFail ? "Une erreur est survenue, veuillez contacter notre service technique." : "‎"}</h4>
            </div>
        </>
    );
};

export default ActivateForm;