// HELPERS FUNCTION FOR NAVBAR

var target_index = null;

export const set_target_index = (index) => {
    target_index = index;
}

export const get_target_index = () => {
    return target_index;
}