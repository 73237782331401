// Import dependencies
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Import CSS & assets
import './Navbar.css';
import logo from '../../../assets/logo.svg';
import ico_dashboard_fill from '../../../assets/ico/ico_dashboard_fill.svg';
import ico_feedback_blue from '../../../assets/ico/ico_feedback_blue.svg';

// Import external functions
import * as helpers from './helpers';


var Navbar = ({ layout, isApp, targetSection, setTargetSection, currentSection, updateCurrentSection, version, environment, isMapExpanded, setIsMapExpanded }) => {

    // Set gobal variables
    const navbar_width = 60;
    var section_index_current = 0;

    // Set global functions
    const navigate = useNavigate();

    // Set hooks
    const [indicatorOffset, setIndicatorOffset] = useState({ top: 0, left: 0 });
    const [searchParams, setSearchParams] = useSearchParams();

    // On load : Set scroll listener
    useEffect(() => {
        document.getElementById('section_data').addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            if (document.getElementById('section_data')) {
                document.getElementById('section_data').removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    // On load : Auto scroll to position on a certain section from url param
    useEffect(() => {
        if (searchParams.get("section") === "team") {
            handleNavItemClick(1);
        }
    }, []);
    // On load : Position navbar-indicator
    useEffect(() => {
        if (window.innerWidth < 550) {
            document.getElementById("navbar-indicator").style.left = document.getElementById("navbar_item_0").offsetLeft + "px";
        }
    }, []);


    // Set specific functions
    // Global move handler (from scroll or nav item)
    const handleMove = (section_index) => {
        if (section_index !== section_index_current && helpers.get_target_index() === null) {
            // console.log("SECTION UPDATE to ", section_index);
            // Update section_index_current
            section_index_current = section_index;
            // Set new state for indicatorOffset
            // setIndicatorOffset({ top: section_index * navbar_width, left: 0 });
            var navbar_item = document.getElementById('navbar_item_' + section_index);
            setIndicatorOffset({ top: navbar_item.offsetTop, left: navbar_item.offsetLeft });
            // Send data to parent
            updateCurrentSection(section_index);
            if (window.innerWidth < 550) {
                // Scroll all other section to top
                setTimeout(() => {
                    for (var i = 0; i < layout.length; i++) {
                        if (i !== section_index) {
                            document.getElementById("section_" + i).scrollTo(0, 0);
                        }
                    }
                }, 500);
                // Scroll navbar if small screen
                setTimeout(() => {
                    // console.log("SCROLL TO", document.getElementById("navbar_item_" + section_index).offsetLeft);
                    document.getElementById("navbar-container").scrollTo((document.getElementById("navbar_item_" + section_index).offsetLeft + 30) - (window.innerWidth / 2), 0);
                }, 300);
            }
        }
    }
    // Scroll handler
    const handleScroll = () => {
        // Calculate indicator offset
        var section_index = 0;
        for (var i = layout.length - 1; i >= 0; i--) {
            if (document.getElementById("section_" + i)) {
                if (window.innerWidth < 550 && document.getElementById("section_" + i).getBoundingClientRect().left <= 0) {
                    section_index = i;
                    // console.log("Match x at ", i);
                    if (section_index === helpers.get_target_index()) {
                        // Reset target index so section can update
                        helpers.set_target_index(null);
                    }
                    break;
                }
                if (window.innerWidth >= 550 && document.getElementById("section_" + i).getBoundingClientRect().top < document.getElementById("section_separator").getBoundingClientRect().top + 80) {
                    section_index = i;
                    // console.log("Match y at ", i);
                    if (section_index === helpers.get_target_index()) {
                        // Reset target index so section can update
                        helpers.set_target_index(null);
                    }
                    break;
                }
            }
        }
        // Move indicator
        handleMove(section_index);
        // Reset targetSection
        setTargetSection(null);

        // Show or Hide the list top arrow
        if (document.getElementById("combinations_list_arrow")) {
            if (document.getElementById("combinations_list").getBoundingClientRect().top < 150) {
                if (!document.getElementById("combinations_list_arrow").classList.contains("combi__title_sticky_img_show")) {
                    document.getElementById("combinations_list_arrow").classList.add("combi__title_sticky_img_show");
                }
            }
            else {
                if (document.getElementById("combinations_list_arrow").classList.contains("combi__title_sticky_img_show")) {
                    document.getElementById("combinations_list_arrow").classList.remove("combi__title_sticky_img_show");
                }
            }
        }
    };
    // Navbar item click handler
    const handleNavItemClick = (section_index) => {
        if (isMapExpanded === true) {
            if (helpers.get_target_index() !== section_index) {
                // Set target index
                helpers.set_target_index(section_index);
                setTargetSection(section_index);
            }
            setIsMapExpanded(false);
        }
        else {
            // Set target index
            helpers.set_target_index(section_index);
            setTargetSection(section_index);
        }
    }
    useEffect(() => {
        if (targetSection !== null) {
            // If mobile => SWIPE
            if (window.innerWidth < 550) {
                // Get target scroll position
                const scrollX_pos = document.getElementById("section_" + targetSection).offsetLeft;
                // Scroll
                document.getElementById('section_data').scrollTo({ top: 0, left: scrollX_pos, behavior: 'smooth' });
            }
            // Else => SCROLL
            else {
                // Get target scroll position
                // const scrollY_pos = document.getElementById("section_" + targetSection).offsetTop;
                var delta = 0;
                if (isApp === true) { delta = 15 };
                const scrollY_pos = document.getElementById("section_" + targetSection).offsetTop - delta; //_UI
                // Scroll
                // console.log("isMapExpanded", isMapExpanded);
                if (isMapExpanded === true) {
                    document.getElementById('section_data').scrollTo({ top: scrollY_pos, left: 0, behavior: 'instant' });
                }
                else {
                    document.getElementById('section_data').scrollTo({ top: scrollY_pos, left: 0, behavior: 'smooth' });
                }
            }
        }
    }, [targetSection]);


    return (
        <div className='app__navbar'>
            <div className="app__navbar_top_container">
                <img onClick={() => { navigate('/dashboard') }} src={logo} alt="logo" />
            </div>
            <div id="navbar-container" className={`app__navbar_middle_container ${isApp ? "container_app" : ""}`}>
                <div id="navbar-indicator" className={`app__navbar_middle_indicator ${layout[0].isVisible ? "" : "app__navbar_middle_indicator_hidden"} ${(isApp && isMapExpanded === true) ? "app__navbar_middle_indicator_hidden" : ""}`} style={{ top: indicatorOffset.top + 'px', left: indicatorOffset.left + 'px' }}></div>
                {/* <div className="app__navbar_separator"></div> */}
                {layout.map((item, index) => {
                    if (item.isVisible) {
                        return (
                            <div key={index} id={`navbar_item_${index}`} className={`app__navbar_middle_item ${isMapExpanded === true ? "app__navbar_middle_item_hidden" : ""}`} onClick={() => { handleNavItemClick(index) }}>
                                <img src={currentSection === index ? item.ico_fill : item.ico_outline} alt="" />
                                <span>{item.title_short}</span>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div key={index} id={`navbar_item_${index}`} className="app__navbar_middle_item app__navbar_middle_item_disabled">
                                <img src={currentSection === index ? item.ico_fill : item.ico_outline} alt="" />
                                <span>{item.title_short}</span>
                            </div>
                        )
                    }
                })}
            </div>
            <div className="app__navbar_bottom_container">
                {isApp &&
                    <div className="app__navbar_middle_item" onClick={() => { navigate('/dashboard'); }}>
                        <img src={ico_dashboard_fill} alt="" />
                        <span>Retour au Dashboard</span>
                    </div>
                }
                {/* <div className="app__navbar_bottom_item_container">
                    <div className="app__navbar_bottom_item">
                        <img src={ico_feedback_blue} alt="" />
                        <span>Donner mon avis</span>
                    </div>
                </div> */}
                <div className="app__navbar_bottom_info">{version}{(environment==="DEV" || environment==="PREPROD") && <><br></br>{environment}</>}</div>
            </div>
        </div>
    );
};

export default Navbar;