import { TEAM_CREATE, TEAM_GET, TEAM_UPDATE } from '../constants/actionTypes';

const teamsReducer = (state = { teamData: null }, action) => {
    switch (action.type) {
        case TEAM_GET:
            return action.payload;
        case TEAM_CREATE:
            return { ...state, teamData: action?.payload };
        case TEAM_UPDATE:
            return { ...state, teamData: action?.payload };
        default:
            return state;
    }
};

export default teamsReducer;