import React from 'react';

const GeocoderResult = ({ result, selectResult, index }) => {

    // Get tag
    var tag = "NC";
    if (result.properties.type === "housenumber") { tag = "Adresse" }
    else if (result.properties.type === "street") { tag = "Voie" }
    else if (result.properties.type === "locality") { tag = "Lieu-dit" }
    else if (result.properties.type === "municipality") { tag = "Commune" }
    if (result?.geometry_name) { tag = "Parcelle" }

    // Get label
    var label = "NC";
    if (result?.properties?.label) { label = result.properties.label }
    else if (result?.geometry_name) {
        var section = result.properties.section;
        if (section.substring(0, 1) === "0") { section = section.substring(1) }
        label = "Parcelle " + section + "-" + parseInt(result.properties.numero) + " de la commune de " + result.properties.nom_com;
    }


    return (
        <div className="app___geocoderresult_container" onClick={() => { selectResult(index) }}>
            <div className="app__geocoderresult_tag_container">
                <span className="app__geocoderresult_tag">{tag}</span>
            </div>
            <div className="app__geocoderresult_label">{label}</div>
        </div>
    );
};

export default GeocoderResult;