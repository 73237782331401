// Import dependencies
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Import CSS & assets
import './Mobile.css';
import Logo from '../../../components/common/logo/Logo';
import title from '../../../assets/title_white.svg';


const Mobile = ({ version }) => {

    // Set global functions
    const navigate = useNavigate();

    // User
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    // Logo
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [logoAnimate, setLogoAnimate] = useState(false);
    useEffect(() => {
        setLogoAnimate(false);
        setTimeout(() => {
            setLogoAnimate(true);
            redirect();
        }, 300)
    }, []);

    // Redirect
    const redirect = () => {
        setTimeout(() => {
            // Hide logo
            setIsRedirecting(true);
            setTimeout(() => {
                if (user?.result) {
                    console.log("CONNECTED");
                    navigate('/dashboard');
                }
                else {
                    console.log("NOT CONNECTED");
                    navigate('/auth?type=signin');
                }
            }, 600)
        }, 5500)
    }


    return (
        <div className="mobile__container">
            <div className={`mobile__center_container ${isRedirecting ? 'mobile__center_container_hidden' : ''}`}>
                <Logo state={logoAnimate} />
                <div className="mobile__title_container">
                    <img src={title} alt="title" />
                </div>
            </div>
            {/* <div className="mobile__version">{version}</div> */}
        </div>
    );
};

export default Mobile;