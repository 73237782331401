// Import dependencies
import React from 'react';

// Import components
import Title from '../../common/title/Title';

// Import CSS & assets
import './Features.css';

const featuresData = [
    {
        title: 'Maximisez vos chances de concrétiser un projet immobilier',
        text: 'Générez vos études capacitaires en un temps record et recentrez vos équipes de développement foncier sur le coeur de métier : la négociation.',
    },
    {
        title: "Assurez-vous d'exploiter le plein potentiel de chaque foncier",
        text: 'Prenez les meilleures décisions : obtenez les résultats qui correspondent à vos critères de performance et explorez toutes les possibilités.',
    },
    // {
    //     title: 'Ne loupez plus aucun lead foncier',
    //     text: 'Obtenez vos bilans financiers instantanément et qualifiez avec précision tous vos leads fonciers.',
    // },
    {
        title: 'Accordez plus de temps à la prospection foncière',
        text: "Finissez en avec les casse-têtes réglementaires. Simplifiez la tâche de vos développeurs fonciers à travers un outil intuitif et simple d'utilisation",
    },
];

const Features = () => (
    <div className="landing__features section__padding">
        <a className="anchor" id="features"></a>
        <div className="landing__features_heading">
            <h1 className="gradient__text">La technologie révolutionnaire de PropLab aide les développeurs fonciers à optimiser les parcelles.</h1>
            <p onClick={() => { document.getElementById('contact').scrollIntoView() }}>Demander une démonstration</p>
        </div>
        <div className="landing__features_container">
            {featuresData.map((item, index) => (
                <div className="landing__features_item" key={index}>
                    <div className="landing__features_item_title">
                        <Title title={item.title} />
                    </div>
                    <div className="landing__features_item_text">
                        <p>{item.text}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default Features;
