// Import dependencies
import React from 'react';

// Import components
import Title from '../../common/title/Title';
import TitleText from '../../common/titletext/TitleText';

// Import CSS & assets
import './Solution.css';


const Solution = () => {
    return (
        <div className="landing__solution">
            <a className="anchor" id="solution"></a>
            <div className="landing__solution_title_container">
                <Title title="Notre Solution" />
            </div>
            <div className="landing__solution_topline_container">
                <h1 className="gradient__text">Vos études capacitaires.</h1>
                <h1 className="gradient__text">Simples. Rapides. Optimisées.</h1>
                {/* <button type="button">Essayer Maintenant</button> */}
            </div>
            <div className="landing__solution_list_container">
                <div className="landing__solution_list_item">
                    <Title title="Optimisation" />
                    <p>Vous révélez le meilleur potentiel de chaque parcelle en optimisant la surface construite.</p>
                </div>
                <div className="landing__solution_list_item">
                    <Title title="Réactivité" />
                    <p>Vous obtenez votre étude de capacité et vos bilans financiers en quelques minutes.</p>
                </div>
                <div className="landing__solution_list_item">
                    <Title title="Fiabilité" />
                    <p>Vous qualifiez mieux les parcelles et ne loupez plus aucun lead foncier.</p>
                </div>
                <div className="landing__solution_list_item">
                    <Title title="Simplicité" />
                    <p>Vos équipes bénéficient d'un outil expert accessible à tous.</p>
                </div>
            </div>
        </div>
    );
};

export default Solution;