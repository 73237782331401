import React, { useState } from 'react';

// Import CSS & assets
import ico_dots from '../../../../assets/ico/ico_dots.svg';
import ico_close from '../../../../assets/ico/ico_close.svg';
import ico_unfold_open from '../../../../assets/ico/ico_unfold_open.svg';
import ico_unfold_close from '../../../../assets/ico/ico_unfold_close.svg';

const Title = ({ rule_id, name, item, title_Update, title_Delete, title_OpenClose }) => {

    const handleTitleChange = (newTitle) => {
        if (name !== newTitle) {
            title_Update(rule_id, newTitle);
        }
    }

    // Document actions
    const [isActions, setIsActions] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [isOpen, setIsOpen] = useState(true);

    const handleClickDelete = () => {
        setIsActions(false);
        setIsDelete(true);
    }

    const handleClickOpenClose = () => {
        title_OpenClose(rule_id, !isOpen)
        setIsOpen(!isOpen);
    }


    return (
        <div id={"title__" + rule_id} className="app__ruleset_title">
            <div className="app__ruleset_title_header">
                {/* <div className="app__ruleset_title_label">{name}</div> */}
                {item?.numerotation &&
                <span className="app__ruleset_title_label_numerotation">{item?.numerotation}</span>
                }
                <input className="app__ruleset_title_label_input" type="text" name="title" placeholder="Titre de l'ensemble de règles" onChange={(e) => { }} onBlur={(e) => { handleTitleChange(e.target.value) }} defaultValue={name}></input>
                <div className="app__ruleset_title_button_container2" onClick={handleClickOpenClose}>
                    {isOpen ?
                        <img src={ico_unfold_close} alt="dots" />
                        :
                        <img src={ico_unfold_open} alt="dots" />
                    }
                </div>
                <div className="app__ruleset_title_button_container" onClick={() => { setIsDelete(true) }}>
                    <img src={ico_close} alt="dots" />
                </div>
            </div>
            <div id={"title_info__" + rule_id} className="app__sectiondata_widget_spacer_10" style={{ fontSize: "11px", fontStyle: 'italic', color: 'grey' }}></div>
            {isActions &&
                <div className="app__ruleset_document_actions_container scale-up-topright" onMouseLeave={() => { setIsActions(false) }}>
                    <div className="app__landsvalidation_popup_button_second" onClick={handleClickDelete}>Supprimer ce titre</div>
                </div>
            }
            {isDelete &&
                <div className="app__ruleset_title_actions_container">
                    <div className="app__bound_type_modify_title_big" style={{ color: "#062134", fontWeight: "600", margin: "0px" }}>Supprimer ce titre ?</div>
                    <div className="app__ruleset_document_deletion_container" style={{ width: "300px" }}>
                        <div className="app__landsvalidation_popup_button" onClick={() => { setIsDelete(false) }}>Conserver</div>
                        <div className="app__landsvalidation_popup_button_second" onClick={() => { title_Delete(rule_id) }}>Supprimer</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Title;