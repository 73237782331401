import React, { useState, useEffect } from 'react';

// Import external functions
import * as helpers from '../../../../helpers/Other_helpers';

// Import components
import DoughnutChart from '../../../charts/DoughnutChart';
import HorizontalBarChart from '../../../charts/HorizontalBarChart';
import ScatterChart from '../../../charts/ScatterChart';
import Sru from '../sru/Sru';
import ZoneABC from '../zoneABC/ZoneABC';

// Import CSS & assets
import './Context.css';
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import ico_filter from '../../../../assets/ico/ico_close_white.svg';
import ico_arrow from '../../../../assets/ico/ico_arrow_up.svg';
import ico_walk_fill from '../../../../assets/ico/ico_walk_fill.svg';
import ico_car_fill from '../../../../assets/ico/ico_car_fill.svg';
import ico_transit_station from '../../../../assets/ico/ico_transit_station.svg';
import ico_doctor from '../../../../assets/ico/ico_doctor.svg';
import ico_school from '../../../../assets/ico/ico_school.svg';
import ico_grocery from '../../../../assets/ico/ico_grocery.svg';
import ico_park from '../../../../assets/ico/ico_park.svg';
import fade from '../../../../assets/fade_grey.svg';

var filterHouse_value = true;
var filterFlat_value = true;
var filterOther_value = true;

var nbHousingAll_value = 0;
var nbHousingHouse_value = 0;
var nbHousingFlat_value = 0;
var nbHousingOther_value = 0;

var nbPopAll_value = 0;
var nbPopAge_value = 0;
var nbPopFamily_value = 0;

const dau_nature = ["Nouvelle construction", "Travaux sur construction existante"];
const dau_nature2 = ["Nouvelle construction", "Transformation sans impact sur la surface", "Transformation avec extension de surface", "Transformation avec diminution de surface", "Extension de surface sans transformation", "Diminution de surface sans transformation"];
const dau_dest = ["Habitation", "Hébergement hôtelier", "Bureaux", "Commerce", "Artisanat", "Industrie", "Agriculture", "Entrepôt", "Service public ou d'intérêt collectif"];
const dau_log_type = ["Logement individuel", "Plusieurs logements individuels", "Collectif (hors résidence)", "Résidence"];

const Context = ({ capacity, mapLayers, setMapLayers, highlightedSitadel }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }
    useEffect(() => {
        if (mapLayers["context_sitadel"]["isVisible"] === true) {
            setSitadelDetail(true);
        }
        else {
            setSitadelDetail(false);
        }
    }, [mapLayers["context_sitadel"]["isVisible"]])
    useEffect(() => {
        console.log("Scroll to", highlightedSitadel);
        var dom_item = document.getElementById(highlightedSitadel);
        if (dom_item !== undefined && dom_item !== null) {
            var topList = dom_item.offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 12;
        }
    }, [highlightedSitadel])

    // Chart options
    const pieChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start'
            },
        }
    }
    const barChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            xAxis: {
                display: false,
            }
        }
    }
    const scatterChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start'
            },
        },
        scales: {
            x: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value + " m²";
                    }
                }
            },
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value + " €";
                    }
                }
            }
        }
    }

    // __________ POPULATION
    const [populationDetail, setPopulationDetail] = useState(false);
    const handlePopulationDetail = () => {
        if (populationDetail === true) {
            // Scroll
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection - 15;
        }
        setPopulationDetail(!populationDetail);
    }

    const [nbPopAll, setNbPopAll] = useState(0);
    const [nbPopAge, setNbPopAge] = useState(0);
    const [nbPopFamily, setNbPopFamily] = useState(0);

    const getPopulationNb = () => {
        // Get nbPopAll & nbPopAge
        if (capacity?.context?.population?.gender_age?.Cellule) {
            var list_age = [];
            capacity.context.population.gender_age.Cellule.forEach(cellule => {
                var test_all = true;
                var test_age = true;
                var code_age = "";
                for (var i = 0; i < cellule.Modalite.length; i++) {
                    if (cellule.Modalite[i]['@variable'] === 'SEXE' && cellule.Modalite[i]['@code'] !== 'ENS') {
                        test_all = false;
                        test_age = false;
                    }
                    if (cellule.Modalite[i]['@variable'] === 'AGE15_15_90' && cellule.Modalite[i]['@code'] !== 'ENS') {
                        test_all = false;
                        code_age = cellule.Modalite[i]['@code'];
                    }
                    else if (cellule.Modalite[i]['@variable'] === 'AGE15_15_90' && cellule.Modalite[i]['@code'] === 'ENS') {
                        test_age = false;
                    }
                }
                // nbPopAll
                if (test_all) {
                    var value = parseInt(cellule.Valeur);
                    setNbPopAll(value);
                    nbPopAll_value = value;
                }
                // nbPopAge
                if (test_age) {
                    list_age.push({
                        code: code_age,
                        index: parseInt(code_age) + 7,
                        value: parseInt(cellule.Valeur)
                    });
                }
            });
            var sum_value_age = 0;
            var sum_index_age = 0;
            list_age.forEach(age => {
                sum_value_age += age.value;
                sum_index_age += age.value * age.index;
            });
            var medium_age = Math.round(sum_index_age / sum_value_age);
            setNbPopAge(medium_age);
            nbPopAge_value = medium_age;
        }

        // Get nbPopFamily
        if (capacity?.context?.population?.csp_familymembers?.Cellule) {
            var list_family = [];
            capacity.context.population.csp_familymembers.Cellule.forEach(cellule => {
                if (cellule.Mesure['@code'] === "POP") {
                    var test_family = true;
                    var code_family = "";
                    for (var i = 0; i < cellule.Modalite.length; i++) {
                        if (cellule.Modalite[i]['@variable'] === 'CS1_8' && cellule.Modalite[i]['@code'] !== 'ENS') {
                            test_family = false;
                        }
                        if (cellule.Modalite[i]['@variable'] === 'NPERC' && cellule.Modalite[i]['@code'] !== 'ENS') {
                            code_family = cellule.Modalite[i]['@code'];
                        }
                        else if (cellule.Modalite[i]['@variable'] === 'NPERC' && cellule.Modalite[i]['@code'] === 'ENS') {
                            test_family = false;
                        }
                    }
                    // nbPopFamily
                    if (test_family) {
                        list_family.push({
                            code: code_family,
                            index: parseInt(code_family),
                            value: parseInt(cellule.Valeur)
                        });
                    }
                }
            });
            var sum_value_family = 0;
            var sum_index_family = 0;
            list_family.forEach(family => {
                sum_value_family += family.value;
                sum_index_family += family.value * family.index;
            });
            var medium_family = (sum_index_family / sum_value_family).toFixed(1);
            setNbPopFamily(medium_family);
            nbPopFamily_value = medium_family;
        }

    }

    // __________ HOUSING
    const [housingDetail, setHousingDetail] = useState(false);
    const handleHousingDetail = () => {
        if (housingDetail === true) {
            // Scroll
            var topList = document.getElementById("housing_anchor").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 10;
        }
        setHousingDetail(!housingDetail);
    }
    const [nbHousingAll, setNbHousingAll] = useState(0);
    const [nbHousingHouse, setNbHousingHouse] = useState(0);
    const [nbHousingFlat, setNbHousingFlat] = useState(0);
    const [nbHousingOther, setNbHousingOther] = useState(0);

    const getHousingNb = () => {
        var database = "";
        if (capacity?.context?.housing?.type_area_csp?.Cellule) {
            database = "type_area_csp";
        }
        else if (capacity?.context?.housing?.type_rooms_occupancy?.Cellule) {
            database = "type_rooms_occupancy";
        }
        else if (capacity?.context?.housing?.type_rooms_seniority?.Cellule) {
            database = "type_rooms_seniority";
        }
        else if (capacity?.context?.housing?.type_category_builtyear?.Cellule) {
            database = "type_category_builtyear";
        }
        if (database !== "") {
            var list = capacity.context.housing[database].Cellule;
            for (var i = 0; i < list.length; i++) {
                var test_all = true;
                var test_house = true;
                var test_flat = true;
                for (var j = 0; j < list[i].Modalite.length; j++) {
                    // Check TYPLR
                    if (list[i].Modalite[j]["@variable"] === "TYPLR") {
                        if (list[i].Modalite[j]["@code"] === "ENS") {
                            test_house = false;
                            test_flat = false;
                        }
                        else if (list[i].Modalite[j]["@code"] === "1") {
                            test_all = false;
                            test_flat = false;
                        }
                        else if (list[i].Modalite[j]["@code"] === "2") {
                            test_house = false;
                            test_all = false;
                        }
                        else {
                            test_all = false;
                            test_house = false;
                            test_flat = false;
                        }
                    }
                    // Check other variables
                    if (list[i].Modalite[j]["@variable"] !== "TYPLR") {
                        if (list[i].Modalite[j]["@code"] !== "ENS") {
                            test_all = false;
                            test_house = false;
                            test_flat = false;
                        }
                    }
                }
                // Get all value
                if (test_all) {
                    var value = parseInt(list[i].Valeur);
                    setNbHousingAll(value);
                    // setNbHousingAll(helpers.get_pretty_num(value));
                    nbHousingAll_value = value;
                }
                // Get house value
                if (test_house) {
                    var value = parseInt(list[i].Valeur);
                    setNbHousingHouse(value);
                    // setNbHousingHouse(helpers.get_pretty_num(value));
                    nbHousingHouse_value = value;
                }
                // Get flat value
                if (test_flat) {
                    var value = parseInt(list[i].Valeur);
                    setNbHousingFlat(value);
                    // setNbHousingFlat(helpers.get_pretty_num(value));
                    nbHousingFlat_value = value;
                }
            }
            // Get other value
            var value = nbHousingAll_value - (nbHousingHouse_value + nbHousingFlat_value);
            setNbHousingOther(value);
            // setNbHousingOther(helpers.get_pretty_num(value));
            nbHousingOther_value = value;
        }
    }

    useEffect(() => {
        getHousingNb();
        getPopulationNb();
        updateCharts_All();
    }, [capacity?.context?.housing])

    // Filters
    const [filterHouse, setFilterHouse] = useState(true);
    const switchFilterHouse = (value) => {
        setFilterHouse(value);
        filterHouse_value = value;
        updateCharts();
    }
    const [filterFlat, setFilterFlat] = useState(true);
    const switchFilterFlat = (value) => {
        setFilterFlat(value);
        filterFlat_value = value;
        updateCharts();
    }
    const [filterOther, setFilterOther] = useState(true);
    const switchFilterOther = (value) => {
        setFilterOther(value);
        filterOther_value = value;
        updateCharts();
    }

    const updateCharts = () => {
        setChartData_Type(getChartData("housing", "type_area_csp", "numbers", { modalities: ["TYPLR", "SURF_15", "CS1_8"], TYPLR: ["all"], SURF_15: ["ENS"], CS1_8: ["ENS"] }, "TYPLR", "all", false, ""));
        setChartData_Area(getChartData("housing", "type_area_csp", "numbers", { modalities: ["TYPLR", "SURF_15", "CS1_8"], TYPLR: ["ENS"], SURF_15: ["all"], CS1_8: ["ENS"] }, "TYPLR", "ENS", false, ""));
        setChartData_Room(getChartData("housing", "type_rooms_occupancy", "numbers", { modalities: ["TYPLR", "STOCD", "NBPIR"], TYPLR: ["ENS"], STOCD: ["ENS"], NBPIR: ["all"] }, "TYPLR", "ENS", false, ""));
        setChartData_Category(getChartData("housing", "type_category_builtyear", "numbers", { modalities: ["TYPLR", "CATL", "ACHL20"], TYPLR: ["ENS"], CATL: ["all"], ACHL20: ["ENS"] }, "TYPLR", "ENS", false, ""));
        setChartData_Status(getChartData("housing", "type_rooms_occupancy", "numbers", { modalities: ["TYPLR", "STOCD", "NBPIR"], TYPLR: ["ENS"], STOCD: ["all"], NBPIR: ["ENS"] }, "TYPLR", "ENS", false, ""));
        setChartData_BuiltYear(getChartData("housing", "type_category_builtyear", "numbers", { modalities: ["TYPLR", "CATL", "ACHL20"], TYPLR: ["ENS"], CATL: ["ENS"], ACHL20: ["all"] }, "TYPLR", "ENS", true, ""));
        setChartData_Seniority(getChartData("housing", "type_rooms_seniority", "numbers", { modalities: ["TYPLR", "ANEMR2", "NBPIR"], TYPLR: ["ENS"], ANEMR2: ["all"], NBPIR: ["ENS"] }, "TYPLR", "ENS", false, ""));
    }

    const updateCharts_All = () => {
        // Population
        setChartData_CSP(getChartData("population", "csp_familymembers", "numbers", { modalities: ["NPERC", "CS1_8"], NPERC: ["ENS"], CS1_8: ["all"] }, "NPERC", "ENS", false, "POP"));
        setChartData_Age(getChartData("population", "gender_age", "numbers", { modalities: ["SEXE", "AGE15_15_90"], SEXE: ["ENS"], AGE15_15_90: ["all"] }, "SEXE", "ENS", false, ""));
        setChartData_Family(getChartData("population", "csp_familymembers", "numbers", { modalities: ["NPERC", "CS1_8"], NPERC: ["all"], CS1_8: ["ENS"] }, "CS1_8", "ENS", false, "POP"));

        // Housing
        updateCharts();

        // DVF
        setChartData_DVF(getChartData_DVF());
    }

    const getChartData = (data_type, data_label, value_type, filters, filter_target, chart_type, reverse, mesure) => {

        // Get insee data
        var data_insee;

        if (data_type === "housing") {

            // Check if data exists
            if (!capacity?.context?.housing?.type_area_csp || capacity?.context?.housing?.type_area_csp === "error") {
                var chartData = {
                    labels: [],
                    datasets: [
                        {
                            label: '',
                            data: [],
                            backgroundColor: colors,
                            borderColor: "#F1F1F1",
                            hoverBorderColor: "#F1F1F1",
                            borderWidth: 1,
                            hoverBorderWidth: 1
                        },
                    ],
                };
                return chartData;
            }
            else {
                data_insee = capacity.context.housing[data_label];
            }
        }
        else if (data_type === "population") {

            // Check if data exists
            if (!capacity?.context?.population?.csp_familymembers || capacity?.context?.population?.csp_familymembers === "error") {
                var chartData = {
                    labels: [],
                    datasets: [
                        {
                            label: '',
                            data: [],
                            backgroundColor: colors,
                            borderColor: "#F1F1F1",
                            hoverBorderColor: "#F1F1F1",
                            borderWidth: 1,
                            hoverBorderWidth: 1
                        },
                    ],
                };
                return chartData;
            }
            else {
                data_insee = capacity.context.population[data_label];
            }
        }
        else {
            // Check if data exists
            if (capacity?.context === "error" || capacity?.context === "fetching") {
                var chartData = {
                    labels: [],
                    datasets: [
                        {
                            label: '',
                            data: [],
                            backgroundColor: colors,
                            borderColor: "#F1F1F1",
                            hoverBorderColor: "#F1F1F1",
                            borderWidth: 1,
                            hoverBorderWidth: 1
                        },
                    ],
                };
                return chartData;
            }
        }


        // Check data validity
        if (!data_insee?.Variable) {
            console.log("ERROR CONTEXT DATA NOT VALID");
            var chartData = {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: colors,
                        borderColor: "#F1F1F1",
                        hoverBorderColor: "#F1F1F1",
                        borderWidth: 1,
                        hoverBorderWidth: 1
                    },
                ],
            };
            return chartData;
        }


        // Get all accepted values
        var target_labels = {};
        var target = "";
        for (var i = 0; i < filters.modalities.length; i++) {
            if (filters[filters.modalities[i]][0] !== "ENS") {
                target = filters.modalities[i];
                // Get its values
                for (var j = 0; j < data_insee.Variable.length; j++) {
                    if (data_insee.Variable[j]["@code"] === filters.modalities[i]) {
                        for (var k = 0; k < data_insee.Variable[j].Modalite.length; k++) {
                            if (data_insee.Variable[j].Modalite[k]["@code"] !== "ENS") {
                                target_labels[data_insee.Variable[j].Modalite[k]["@code"]] = data_insee.Variable[j].Modalite[k]["Libelle"];
                                filters[filters.modalities[i]].push(data_insee.Variable[j].Modalite[k]["@code"]);
                            }
                        }
                    }
                }
            }
        }
        // Update filters list
        var filters_list = [];
        if (filterHouse_value === true && filterFlat_value === true && filterOther_value === true) {
            if (chart_type === "all") {
                filters_list.push("all");
            }
            else {
                filters_list.push("ENS");
            }
        }
        else {
            if (filterHouse_value === true) {
                filters_list.push("1");
            }
            if (filterFlat_value === true) {
                filters_list.push("2");
            }
            if (filterOther_value === true) {
                filters_list.push("3");
            }
        }
        if (filters[filter_target]) {
            // if (chart_type === "all" && filters_list.length === 1 && filters_list[0] === "all") {
            if (chart_type === "all") {
                // filters[filter_target] = filters_list;
            }
            else {
                filters[filter_target] = filters_list;
            }
        }
        // Get cellules
        var target_values = {};
        var sum = 0;
        if (data_insee?.Cellule && data_insee.Cellule.length > 0) {
            for (var i = 0; i < data_insee.Cellule.length; i++) {
                if (mesure === "" || mesure === data_insee.Cellule[i].Mesure["@code"]) {
                    // Check modalities
                    var isCorresponding = true;
                    var modality = "";
                    for (var j = 0; j < data_insee.Cellule[i].Modalite.length; j++) {
                        if (!filters[data_insee.Cellule[i].Modalite[j]["@variable"]].includes(data_insee.Cellule[i].Modalite[j]["@code"])) {
                            isCorresponding = false;
                            break;
                        }
                        else if (data_insee.Cellule[i].Modalite[j]["@variable"] === target) {
                            modality = data_insee.Cellule[i].Modalite[j]["@code"];
                        }
                    }
                    // Get value
                    if (isCorresponding) {
                        if (target_values[modality]) {
                            target_values[modality] += parseInt(data_insee.Cellule[i].Valeur);
                        }
                        else {
                            target_values[modality] = parseInt(data_insee.Cellule[i].Valeur);
                        }
                        sum += parseInt(data_insee.Cellule[i].Valeur);
                    }
                }
            }
        }

        // Set values to 0 if chart type all
        if (chart_type === "all" && (filters_list.length === 0 || (filters_list.length > 0 && filters_list[0] !== "all"))) {
            for (var i = 0; i < filters[target].length; i++) {
                if (filters[target][i] !== "all" && !filters_list.includes(filters[target][i])) {
                    target_values[filters[target][i]] = 0;
                }
            }
        }

        // Reset values types
        if (value_type === "percentage") {
            for (var i = 0; i < filters[target].length; i++) {
                if (filters[target][i] !== "all") {
                    target_values[filters[target][i]] = Math.round((target_values[filters[target][i]] / sum) * 100);
                }
            }
        }
        else if (value_type === "scale") {

        }

        // Create return values
        var labels = [];
        var data = [];
        for (var i = 0; i < filters[target].length; i++) {
            if (filters[target][i] !== "all") {
                labels.push(target_labels[filters[target][i]]);
                data.push(target_values[filters[target][i]]);
            }
        }

        // Reverse data
        if (reverse) {
            labels.reverse();
            data.reverse();
        }

        // Colors
        var colors = [
            '#062132',
            '#00364c',
            '#004e65',
            '#006679',
            '#008089',
            '#009a93',
            '#00b398',
            '#1ecd97',
        ];
        if (labels.length <= 4) {
            colors = [
                '#062132',
                '#004e65',
                '#008089',
                '#00b398',
            ];
        }

        var chartData = {
            labels: labels,
            datasets: [
                {
                    label: '',
                    data: data,
                    backgroundColor: colors,
                    borderColor: "#F1F1F1",
                    hoverBorderColor: "#F1F1F1",
                    borderWidth: 1,
                    hoverBorderWidth: 1
                },
            ],
        };

        return chartData;
    }

    const getChartData_DVF = () => {

        if (!capacity?.context?.dvf?.dvf_stats || Object.keys(capacity.context.dvf.dvf_stats).length === 0) {

            var chartData = {
                datasets: [],
            };

            return chartData;
        }

        // Colors
        var colors = [
            '#062132',
            '#00364c',
            '#004e65',
            '#006679',
            '#008089',
            '#009a93',
            '#00b398',
            '#1ecd97',
        ];
        if (Object.keys(capacity.context.dvf.dvf_stats).length <= 4) {
            colors = [
                '#062132',
                '#004e65',
                '#008089',
                '#00b398',
            ];
        }
        if (Object.keys(capacity.context.dvf.dvf_stats).length <= 3) {
            colors = [
                '#062132',
                '#008089',
                '#1ecd97',
            ];
        }
        if (Object.keys(capacity.context.dvf.dvf_stats).length <= 2) {
            colors = [
                '#062132',
                '#00b398',
            ];
        }

        var chartData = {
            datasets: []
        }

        if (capacity?.context?.dvf?.dvf_stats) {
            var index = 0;
            Object.keys(capacity.context.dvf.dvf_stats).forEach(stat => {
                var data = [];
                for (var i = 0; i < capacity.context.dvf.dvf_stats[stat].list_price.length; i++) {
                    data.push({
                        x: capacity.context.dvf.dvf_stats[stat].list_price[i].area,
                        y: capacity.context.dvf.dvf_stats[stat].list_price[i].value,
                    });
                }
                chartData.datasets.push({
                    label: stat,
                    data: data,
                    backgroundColor: colors[index],
                });
                index++;
            });
        }

        return chartData;

    }

    const [chartData_CSP, setChartData_CSP] = useState(getChartData("population", "csp_familymembers", "numbers", { modalities: ["NPERC", "CS1_8"], NPERC: ["ENS"], CS1_8: ["all"] }, "NPERC", "ENS", false, "POP"));
    const [chartData_Age, setChartData_Age] = useState(getChartData("population", "gender_age", "numbers", { modalities: ["SEXE", "AGE15_15_90"], SEXE: ["ENS"], AGE15_15_90: ["all"] }, "SEXE", "ENS", false, ""));
    const [chartData_Family, setChartData_Family] = useState(getChartData("population", "csp_familymembers", "numbers", { modalities: ["NPERC", "CS1_8"], NPERC: ["all"], CS1_8: ["ENS"] }, "CS1_8", "ENS", false, "POP"));


    const [chartData_Type, setChartData_Type] = useState(getChartData("housing", "type_area_csp", "numbers", { modalities: ["TYPLR", "SURF_15", "CS1_8"], TYPLR: ["all"], SURF_15: ["ENS"], CS1_8: ["ENS"] }, "TYPLR", "all", false, ""));
    const [chartData_Area, setChartData_Area] = useState(getChartData("housing", "type_area_csp", "numbers", { modalities: ["TYPLR", "SURF_15", "CS1_8"], TYPLR: ["ENS"], SURF_15: ["all"], CS1_8: ["ENS"] }, "TYPLR", "ENS", false, ""));
    const [chartData_Room, setChartData_Room] = useState(getChartData("housing", "type_rooms_occupancy", "numbers", { modalities: ["TYPLR", "STOCD", "NBPIR"], TYPLR: ["ENS"], STOCD: ["ENS"], NBPIR: ["all"] }, "TYPLR", "ENS", false, ""));
    const [chartData_Category, setChartData_Category] = useState(getChartData("housing", "type_category_builtyear", "numbers", { modalities: ["TYPLR", "CATL", "ACHL20"], TYPLR: ["ENS"], CATL: ["all"], ACHL20: ["ENS"] }, "TYPLR", "ENS", false, ""));
    const [chartData_Status, setChartData_Status] = useState(getChartData("housing", "type_rooms_occupancy", "numbers", { modalities: ["TYPLR", "STOCD", "NBPIR"], TYPLR: ["ENS"], STOCD: ["all"], NBPIR: ["ENS"] }, "TYPLR", "ENS", false, ""));
    const [chartData_BuiltYear, setChartData_BuiltYear] = useState(getChartData("housing", "type_category_builtyear", "numbers", { modalities: ["TYPLR", "CATL", "ACHL20"], TYPLR: ["ENS"], CATL: ["ENS"], ACHL20: ["all"] }, "TYPLR", "ENS", true, ""));
    const [chartData_Seniority, setChartData_Seniority] = useState(getChartData("housing", "type_rooms_seniority", "numbers", { modalities: ["TYPLR", "ANEMR2", "NBPIR"], TYPLR: ["ENS"], ANEMR2: ["all"], NBPIR: ["ENS"] }, "TYPLR", "ENS", false, ""));

    const [chartData_DVF, setChartData_DVF] = useState(getChartData_DVF());


    // TRANSACTION
    const [transactionDetail, setTransactionDetail] = useState(false);
    const handleTransactionDetail = () => {
        if (transactionDetail === true) {
            // Scroll
            var topList = document.getElementById("transactions_anchor").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 10;
        }
        setTransactionDetail(!transactionDetail);
    }


    // SITADEL
    const [sitadelDetail, setSitadelDetail] = useState(false);
    const handleSitadelDetail = () => {
        if (sitadelDetail === true && mapLayers["context_sitadel"]["isVisible"] === true) {
            layer_Toggle("context_sitadel");
        }
        if (sitadelDetail === true) {
            // Scroll
            var topList = document.getElementById("sitadel_anchor").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 10;
        }
        setSitadelDetail(!sitadelDetail);
    }

    // MERIMEE
    const [merimeeDetail, setMerimeeDetail] = useState(false);
    const handleMerimeeDetail = () => {
        if (merimeeDetail === true) {
            // Scroll
            var topList = document.getElementById("merimee_anchor").offsetTop;
            var topSection = document.getElementById("section_3").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 10;
        }
        setMerimeeDetail(!merimeeDetail);
    }


    // GOOGLE PLACES
    const [transitDetail, setTransitDetail] = useState(false);
    const [doctorDetail, setDoctorDetail] = useState(false);
    const [schoolDetail, setSchoolDetail] = useState(false);
    const [groceryDetail, setGroceryDetail] = useState(false);
    const [parkDetail, setParkDetail] = useState(false);



    if (capacity?.context?.housing) {

        return (
            <>
                {/* ZONE ABC */}
                <ZoneABC capacity={capacity} />
                {/* INSEE POPULATION */}
                {capacity?.context?.population?.gender_age?.Cellule &&
                    <>
                        <div className="app__sectiondata_widget_title">Données statistiques de la population (à l'échelle de la commune)</div>
                        <div className="app__sectiondata_widget_layout_A">
                            <div className="app__sectiondata__widget" style={{ width: window.innerWidth > 550 ? '47%' : '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div className="app__sectiondata_widget_title">Nombre total d'habitants</div>
                                <div className="app__sectiondata_widget_value_container">
                                    <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbPopAll)}</div>
                                    <div className="app__sectiondata_widget_value_legend">habitants</div>
                                </div>
                            </div>
                            {window.innerWidth > 550 &&
                                <>
                                    <div className="app__sectiondata__widget" style={{ width: '23%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <div className="app__sectiondata_widget_title">Âge moyen</div>
                                        <div className="app__sectiondata_widget_value_container">
                                            <div className="app__sectiondata_widget_value_text">{nbPopAge}</div>
                                            <div className="app__sectiondata_widget_value_legend">ans</div>
                                        </div>
                                    </div>
                                    <div className="app__sectiondata__widget" style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <div className="app__sectiondata_widget_title">Composition moyenne d'un ménage</div>
                                        <div className="app__sectiondata_widget_value_container">
                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbPopFamily)}</div>
                                            <div className="app__sectiondata_widget_value_legend">personnes</div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        {/* POPULATION DETAILS */}
                        <div id="population_list" className={`app__context_population_detail_container ${populationDetail ? "" : "app__context_population_detail_container_hidden"}`}>
                            {/* CSP */}
                            {capacity?.context?.population?.csp_familymembers?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                    <div className="app__sectiondata_widget_title">Répartition par catégorie socio-professionnelle (en nombre de personnes)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_CSP} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Agriculteurs exploitants</div>
                                        <div className="app__context_chart_legend_item">Artisans, commerçants, chefs d'entreprise</div>
                                        <div className="app__context_chart_legend_item">Cadres</div>
                                        <div className="app__context_chart_legend_item">Professions intermédiaires</div>
                                        <div className="app__context_chart_legend_item">Employés</div>
                                        <div className="app__context_chart_legend_item">Ouvriers</div>
                                        <div className="app__context_chart_legend_item">Retraités</div>
                                        <div className="app__context_chart_legend_item">Sans activité</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* AGE */}
                            {capacity?.context?.population?.gender_age?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par tranche d'âge (en nombre de personnes)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Age} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Moins de 14 ans</div>
                                        <div className="app__context_chart_legend_item">De 15 à 29 ans</div>
                                        <div className="app__context_chart_legend_item">De 30 à 44 ans</div>
                                        <div className="app__context_chart_legend_item">De 45 à 59 ans</div>
                                        <div className="app__context_chart_legend_item">De 60 à 74 ans</div>
                                        <div className="app__context_chart_legend_item">De 75 à 89 ans</div>
                                        <div className="app__context_chart_legend_item">Plus de 90 ans</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* FAMILY */}
                            {capacity?.context?.population?.csp_familymembers?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par composition du ménage (en nombre de personnes)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Family} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">1 personne</div>
                                        <div className="app__context_chart_legend_item">2 personnes</div>
                                        <div className="app__context_chart_legend_item">3 personnes</div>
                                        <div className="app__context_chart_legend_item">4 personnes</div>
                                        <div className="app__context_chart_legend_item">5 personnes</div>
                                        <div className="app__context_chart_legend_item">6 personnes ou plus</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                        </div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : INSEE - Millésime 2022 - RP 2019
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__context_housing_detail_button_container" onClick={handlePopulationDetail}>
                            {populationDetail &&
                                <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                            }
                            <div className="app__context_housing_detail_button">{populationDetail ? 'Afficher moins de statistiques de la population' : 'Afficher plus de statistiques de la population'}</div>
                            {!populationDetail &&
                                <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                            }
                        </div>
                        <div className="app__sectiondata_widget_spacer_50"></div>
                    </>
                }
                {/* INSEE HOUSING */}
                {capacity?.context?.housing?.type_area_csp?.Cellule &&
                    <>
                        <div id="housing_anchor" className="app__sectiondata_widget_title">Données statistiques du parc immobilier résidentiel (à l'échelle de la commune)</div>
                        {/* TYPES */}
                        {capacity?.context?.housing?.type_area_csp?.Cellule &&
                            <>
                                <div className={window.innerWidth > 550 ? "app__sectiondata_widget_layout_A" : "app__sectiondata_widget_layout_B"}>
                                    <div className="app__sectiondata__widget">
                                        <div className="app__sectiondata_widget_title">Nombre total de logements</div>
                                        <div className="app__sectiondata_widget_value_container">
                                            {/* <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingAll)}</div> */}
                                            {(filterHouse && !filterFlat && !filterOther) ?
                                                <>
                                                    <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingHouse)}</div>
                                                    <div className="app__sectiondata_widget_value_legend">maisons</div>
                                                </>
                                                :
                                                (!filterHouse && filterFlat && !filterOther) ?
                                                    <>
                                                        <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingFlat)}</div>
                                                        <div className="app__sectiondata_widget_value_legend">appartements</div>
                                                    </>
                                                    :
                                                    (!filterHouse && !filterFlat && filterOther) ?
                                                        <>
                                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingOther)}</div>
                                                            <div className="app__sectiondata_widget_value_legend">autres logements</div>
                                                        </>
                                                        :
                                                        (filterHouse && filterFlat && !filterOther) ?
                                                            <>
                                                                <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingHouse + nbHousingFlat)}</div>
                                                                <div className="app__sectiondata_widget_value_legend">logements</div>
                                                            </>
                                                            :
                                                            (filterHouse && !filterFlat && filterOther) ?
                                                                <>
                                                                    <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingHouse + nbHousingOther)}</div>
                                                                    <div className="app__sectiondata_widget_value_legend">logements</div>
                                                                </>
                                                                :
                                                                (!filterHouse && filterFlat && filterOther) ?
                                                                    <>
                                                                        <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingFlat + nbHousingOther)}</div>
                                                                        <div className="app__sectiondata_widget_value_legend">logements</div>
                                                                    </>
                                                                    :
                                                                    (!filterHouse && !filterFlat && !filterOther) ?
                                                                        <>
                                                                            <div className="app__sectiondata_widget_value_text">0</div>
                                                                            <div className="app__sectiondata_widget_value_legend">logement</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num(nbHousingAll)}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">logements</div>
                                                                        </>
                                            }
                                        </div>
                                    </div>
                                    <div className={window.innerWidth > 550 ? "app__sectiondata__widget" : ""}>
                                        <div className="app__sectiondata_widget_title">Filtre sur le type de logement</div>
                                        <div className="app__context_filter_container">
                                            <div className="app__context_filter">
                                                <span className={`app__context_filter_button ${filterHouse ? "app__context_filter_button_active" : ""}`} onClick={() => { switchFilterHouse(!filterHouse) }}>Maisons <img src={ico_filter} alt="filterclose" /></span> <span className={`app__context_filter_button ${filterFlat ? "app__context_filter_button_active" : ""}`} onClick={() => { switchFilterFlat(!filterFlat) }}>Appartements <img src={ico_filter} alt="filterclose" /></span> <span className={`app__context_filter_button ${filterOther ? "app__context_filter_button_active" : ""}`} onClick={() => { switchFilterOther(!filterOther) }}>Autres <img src={ico_filter} alt="filterclose" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                    <div className="app__sectiondata_widget_title">Répartition par typologie du logement (en nombre de logements et pourcentage)</div>
                                    <div className="app__context_chart_container_160">
                                        <div className="app__context_chart_container_half">
                                            <DoughnutChart chartData={chartData_Type} chartOptions={pieChartOptions} />
                                        </div>
                                        <div style={{ flex: 1, marginLeft: 20 }}>
                                            <div className="app__context_chart_legend_full_container">
                                                <div className={`app__context_chart_legend_full_item ${filterHouse ? "" : "app__context_chart_legend_full_item_hidden"}`}>
                                                    <div className="app__context_chart_legend_full_item_title_container">
                                                        <div className="app__context_chart_legend_full_color" style={{ backgroundColor: '#062132' }}></div>
                                                        <div className="app__context_chart_legend_full_item_title">Maisons</div>
                                                    </div>
                                                    <div className="app__context_chart_lagend_full_item_body_container">
                                                        <strong>{helpers.get_pretty_num(nbHousingHouse)}</strong> logements<br />soit <strong>{(Math.round((nbHousingHouse / nbHousingAll) * 100) < 1) ? "moins de 1 %" : Math.round((nbHousingHouse / nbHousingAll) * 100).toString() + " %"}</strong> {`${window.innerWidth > 550 ? "du parc immobilier résidentiel de la commune" : ""}`}
                                                    </div>
                                                </div>
                                                <div className={`app__context_chart_legend_full_item ${filterFlat ? "" : "app__context_chart_legend_full_item_hidden"}`}>
                                                    <div className="app__context_chart_legend_full_item_title_container">
                                                        <div className="app__context_chart_legend_full_color" style={{ backgroundColor: '#004e65' }}></div>
                                                        <div className="app__context_chart_legend_full_item_title">Appartements</div>
                                                    </div>
                                                    <div className="app__context_chart_lagend_full_item_body_container">
                                                        <strong>{helpers.get_pretty_num(nbHousingFlat)}</strong> logements<br />soit <strong>{(Math.round((nbHousingFlat / nbHousingAll) * 100) < 1) ? "moins de 1 %" : Math.round((nbHousingFlat / nbHousingAll) * 100).toString() + " %"}</strong> {`${window.innerWidth > 550 ? "du parc immobilier résidentiel de la commune" : ""}`}
                                                    </div>
                                                </div>
                                                <div className={`app__context_chart_legend_full_item ${filterOther ? "" : "app__context_chart_legend_full_item_hidden"}`}>
                                                    <div className="app__context_chart_legend_full_item_title_container">
                                                        <div className="app__context_chart_legend_full_color" style={{ backgroundColor: '#008089' }}></div>
                                                        <div className="app__context_chart_legend_full_item_title">Autres</div>
                                                    </div>
                                                    <div className="app__context_chart_lagend_full_item_body_container">
                                                        <strong>{helpers.get_pretty_num(nbHousingOther)}</strong> logements<br />soit <strong>{(Math.round((nbHousingOther / nbHousingAll) * 100) < 1) ? "moins de 1 %" : Math.round((nbHousingOther / nbHousingAll) * 100).toString() + " %"}</strong> {`${window.innerWidth > 550 ? "du parc immobilier résidentiel de la commune" : ""}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {/* HOUSING DETAILS */}
                        <div className={`app__context_housing_detail_container ${housingDetail ? "" : "app__context_housing_detail_container_hidden"}`}>
                            {/* AREAS */}
                            {capacity?.context?.housing?.type_area_csp?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                    <div className="app__sectiondata_widget_title">Répartition par surface du logement (en nombre de logements)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Area} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Moins de 30m²</div>
                                        <div className="app__context_chart_legend_item">De 30 à 40m²</div>
                                        <div className="app__context_chart_legend_item">De 40 à 60m²</div>
                                        <div className="app__context_chart_legend_item">De 60 à 80m²</div>
                                        <div className="app__context_chart_legend_item">De 80 à 100m²</div>
                                        <div className="app__context_chart_legend_item">De 100 à 120m²</div>
                                        <div className="app__context_chart_legend_item">Plus de 120m²</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* ROOMS */}
                            {capacity?.context?.housing?.type_rooms_occupancy?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par nombre de pièces du logement (en nombre de logements)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Room} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">1 pièce</div>
                                        <div className="app__context_chart_legend_item">2 pièces</div>
                                        <div className="app__context_chart_legend_item">3 pièces</div>
                                        <div className="app__context_chart_legend_item">4 pièces</div>
                                        <div className="app__context_chart_legend_item">5 pièces</div>
                                        <div className="app__context_chart_legend_item">6 pièces ou plus</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* CATEGORIES */}
                            {capacity?.context?.housing?.type_category_builtyear?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par catégorie du logement (en nombre de logements)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Category} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Logements vacants</div>
                                        <div className="app__context_chart_legend_item">Résidences secondaires</div>
                                        <div className="app__context_chart_legend_item">Logements occasionnels</div>
                                        <div className="app__context_chart_legend_item">Résidences principales</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* BUILT YEAR */}
                            {capacity?.context?.housing?.type_category_builtyear?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par année de construction du logement (en nombre de logements)</div>
                                    <div className="app__context_chart_container_160">
                                        {/* <DoughnutChart chartData={get_chart_data("type_category_builtyear", { modalities: ["TYPLR", "CATL", "ACHL20"], TYPLR: ["ENS"], CATL: ["ENS"], ACHL20: ["all"] }, "percentage")} chartOptions={pieChartOptions} /> */}
                                        <HorizontalBarChart chartData={chartData_BuiltYear} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Avant 1919</div>
                                        <div className="app__context_chart_legend_item">De 1919 à 1945</div>
                                        <div className="app__context_chart_legend_item">De 1946 à 1970</div>
                                        <div className="app__context_chart_legend_item">De 1971 à 1990</div>
                                        <div className="app__context_chart_legend_item">De 1991 à 2005</div>
                                        <div className="app__context_chart_legend_item">De 2006 à 2015</div>

                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* STATUS */}
                            {capacity?.context?.housing?.type_rooms_occupancy?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par statut d'occupation du logement (en nombre de logements)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Status} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Propriétaire</div>
                                        <div className="app__context_chart_legend_item">Locataire d'un logement vide (non HLM)</div>
                                        <div className="app__context_chart_legend_item">Locataire d'un logement vide (HLM)</div>
                                        <div className="app__context_chart_legend_item">Locataire d'un logement meublé</div>
                                        <div className="app__context_chart_legend_item">Logé gratuitement</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                            {/* SENIORITY */}
                            {capacity?.context?.housing?.type_rooms_seniority?.Cellule &&
                                <>
                                    <div className="app__sectiondata_widget_title">Répartition par ancienneté d'emménagement dans le logement (en nombre de logements)</div>
                                    <div className="app__context_chart_container_160">
                                        <HorizontalBarChart chartData={chartData_Seniority} chartOptions={barChartOptions} />
                                    </div>
                                    <div className="app__context_chart_legend_container">
                                        <div className="app__context_chart_legend_item">Moins de 2 ans</div>
                                        <div className="app__context_chart_legend_item">De 2 à 4 ans</div>
                                        <div className="app__context_chart_legend_item">De 5 à 9 ans</div>
                                        <div className="app__context_chart_legend_item">De 10 à 19 ans</div>
                                        <div className="app__context_chart_legend_item">De 20 à 29 ans</div>
                                        <div className="app__context_chart_legend_item">30 ans ou plus</div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer"></div>
                                </>
                            }
                        </div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : INSEE - Millésime 2022 - RP 2019
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__context_housing_detail_button_container" onClick={handleHousingDetail}>
                            {housingDetail &&
                                <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                            }
                            <div className="app__context_housing_detail_button">{housingDetail ? 'Afficher moins de statistiques du parc immobilier résidentiel' : 'Afficher plus de statistiques du parc immobilier résidentiel'}</div>
                            {!housingDetail &&
                                <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                            }
                        </div>
                        <div className="app__sectiondata_widget_spacer_40"></div>
                    </>
                }
                {/* SRU */}
                <Sru capacity={capacity} />
                {/* DVF */}
                {(capacity?.context?.dvf?.dvf_transactions && Object.keys(capacity.context.dvf.dvf_transactions).length > 0) &&
                    <>
                        <div id="transactions_anchor" className="app__sectiondata_widget_title_container">
                            <div className="app__sectiondata_widget_title">Données statistiques des transactions immobilières (dans le secteur du terrain)</div>
                            <div className="app__sectiondata_widget_layer">
                                <img src={ico_map_layers} alt="ico_map_layers" />
                                <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_dvf" checked={mapLayers.context_dvf.isVisible} onChange={() => { layer_Toggle("context_dvf") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_dvf">Toggle</label></div>
                            </div>
                        </div>
                        <div className="app__sectiondata_widget_layout_A">
                            {Object.keys(capacity.context.dvf.dvf_stats).map((item, index) => {
                                if ((index <= 1 || window.innerWidth > 550) && index < 3) {
                                    return (
                                        // <div key={index} className="app__sectiondata__widget" style={{ width: (100 / Object.keys(capacity.context.dvf.dvf_stats).length) + '%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <div key={index} className="app__sectiondata__widget" style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <div className="app__sectiondata_widget_title">Prix moyen : {item}</div>
                                            <div className="app__sectiondata_widget_value_container">
                                                <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.context.dvf.dvf_stats[item].sum / capacity.context.dvf.dvf_stats[item].list_price.length).toFixed(0))}</div>
                                                <div className="app__sectiondata_widget_value_legend">€ / m²</div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="app__sectiondata_widget_spacer"></div>
                        <div className="app__sectiondata_widget_title">Visualisation des transactions immobilières par type de bien</div>
                        <div className="app__context_chart_container_200">
                            <ScatterChart chartData={chartData_DVF} chartOptions={scatterChartOptions} />
                        </div>
                        {(capacity?.context?.dvf?.dvf_transactions_land && capacity.context.dvf.dvf_transactions_land.length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="app__sectiondata_widget_title">Transactions ayant concernées une parcelle du terrain étudié</div>
                                <div id="transactions_list" className={`app__sectiondata_transaction_container ${(!transactionDetail && capacity.context.dvf.dvf_transactions_land.length > 2) ? "app__sectiondata_transaction_container_small" : ""}`}>
                                    {capacity.context.dvf.dvf_transactions_land.map((item, index) => {
                                        // Get land label
                                        var land_label = "Parcelle";
                                        if (capacity.context.dvf.dvf_transactions[item].land_id.length > 1) {
                                            land_label = "Parcelles";
                                        }
                                        for (var i = 0; i < capacity.context.dvf.dvf_transactions[item].land_id.length; i++) {
                                            var land_section = capacity.context.dvf.dvf_transactions[item].land_id[i].substring(8, 10);
                                            if (land_section.substring(0, 1) === "0") { land_section = land_section.substring(1) };
                                            var land_numero = parseInt(capacity.context.dvf.dvf_transactions[item].land_id[i].substring(10, capacity.context.dvf.dvf_transactions[item].land_id[i].length));
                                            if (i === 0) {
                                                land_label = land_label + " ";
                                            }
                                            else if (i === capacity.context.dvf.dvf_transactions[item].land_id.length - 1) {
                                                land_label = land_label + " et ";
                                            }
                                            else {
                                                land_label = land_label + ", ";
                                            }
                                            land_label = land_label + land_section + "-" + land_numero;
                                        }
                                        // Get local list
                                        var local_list = [];
                                        capacity.context.dvf.dvf_transactions[item].local.forEach(local => {
                                            var local_label = "";
                                            local_label = local_label + local.type;
                                            if (local.rooms !== null && local.rooms > 0) {
                                                local_label = local_label + " (" + local.rooms + "p)";
                                            }
                                            if (local.area !== null && local.area > 0) {
                                                local_label = local_label + " de " + local.area + " m²";
                                            }
                                            local_list.push(local_label);
                                        });
                                        if (capacity.context.dvf.dvf_transactions[item].land_area > 0) {
                                            local_list.push('Terrain de ' + capacity.context.dvf.dvf_transactions[item].land_area + ' m²');
                                        }
                                        // Get local text
                                        var local_text = "La transaction comprend " + local_list.length;
                                        if (local_list.length > 1) {
                                            local_text = local_text + " lots :";
                                        }
                                        else {
                                            local_text = local_text + " lot :";
                                        }
                                        for (var i = 0; i < local_list.length; i++) {
                                            if (i === 0) {
                                                local_text = local_text + " ";
                                            }
                                            else if (i === local_list.length - 1) {
                                                local_text = local_text + " et ";
                                            }
                                            else {
                                                local_text = local_text + ", ";
                                            }
                                            local_text = local_text + local_list[i];
                                        }

                                        return (
                                            <div key={index}>
                                                {index > 0 &&
                                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                                }
                                                <div className="app__sectiondata__widget">
                                                    <div className="app__buildings_value_main">{land_label} • Transaction du {(capacity.context.dvf.dvf_transactions[item].date).split("-").reverse().join("/")}</div>
                                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                                    <div className="app__land_data_detail_container">
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Type</div>
                                                            <div className="app__land_data_detail_value">{capacity.context.dvf.dvf_transactions[item].nature}</div>
                                                        </div>
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Montant</div>
                                                            <div className="app__land_data_detail_value">{helpers.get_pretty_num(capacity.context.dvf.dvf_transactions[item].value)} €</div>
                                                        </div>
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Date</div>
                                                            <div className="app__land_data_detail_value">{(capacity.context.dvf.dvf_transactions[item].date).split("-").reverse().join("/")}</div>
                                                        </div>
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Référence DVF</div>
                                                            <div className="app__land_data_detail_value">{item}</div>
                                                        </div>
                                                    </div>
                                                    {local_text !== "La transaction comprend 0 lot :" &&
                                                        <>
                                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                                            <div className="app__land_data_detail_container">
                                                                <div className="app__land_data_detail_box">
                                                                    <div className="app__land_data_detail_title">Détail</div>
                                                                    {/* {local_list.map((item, index) => {
                                                            return (
                                                                <div key={index} className="app__land_data_detail_value">{item}</div>
                                                            )
                                                        })} */}
                                                                    <div className="app__land_data_detail_value">{local_text}</div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {capacity.context.dvf.dvf_transactions_land.length > 2 &&
                                    <div className="app__context_housing_detail_button_container2">
                                        {!transactionDetail ?
                                            <img className="app__fade" src={fade} alt="" />
                                            :
                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                        }
                                        <div className="app__context_housing_detail_button_container" onClick={handleTransactionDetail}>
                                            {transactionDetail &&
                                                <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                            <div className="app__context_housing_detail_button">{transactionDetail ? 'Afficher moins de transactions' : 'Afficher les ' + capacity.context.dvf.dvf_transactions_land.length + ' transactions'}</div>
                                            {!transactionDetail &&
                                                <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Base DVF - Etalab
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer_30"></div>
                    </>
                }
                {/* SITADEL */}
                {capacity?.context?.sitadel &&
                    <>
                        {capacity.context.sitadel.length > 0 ?
                            <>
                                <div id="sitadel_anchor" className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Relevé des demandes d'autorisation d'urbanisme (dans le secteur du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_sitadel" checked={mapLayers.context_sitadel.isVisible} onChange={() => { layer_Toggle("context_sitadel") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_sitadel">Toggle</label></div>
                                    </div>
                                </div>
                                <div id="sitadel_list" className={`app__sectiondata_sitadel_container ${(!sitadelDetail && capacity.context.sitadel.length > 3) ? "app__sectiondata_sitadel_container_small" : ""}`}>
                                    {capacity.context.sitadel.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="app__sectiondata__widget" id={"sitadel_" + item?.id}>
                                                    <div className="app__buildings_value_main" style={{ marginBottom: "4px" }}>{item?.type === "PA" ? "Permis d'Aménager" : item?.type === "PC" ? "Permis de Construire" : item?.type === "DP" ? "Déclaration Préalable" : item?.type}{item?.id && <span style={{ opacity: 0.5, fontSize: "14px" }}>{" • " + item.id}</span>}</div>
                                                    <div className="app__sectiondata_widget_value_legend">{item?.address}</div>
                                                    <div className="app__sectiondata_widget_spacer_15"></div>
                                                    <div className="app__land_data_detail_title" style={{ marginBottom: "5px", borderTop: "1px solid lightgrey", paddingTop: "3px" }}>Détails de la DAU</div>
                                                    <div className="app__land_data_detail_container" style={{ justifyContent: (item?.date_start || item?.date_end) ? "space-between" : "start" }}>
                                                        <div className="app__land_data_detail_box" style={{ minWidth: (item?.date_start || item?.date_end) ? "" : "52.5%" }}>
                                                            <div className="app__land_data_detail_title">Statut</div>
                                                            <div className="app__land_data_detail_value">{item?.status === 2 ? "Autorisé" : item?.status === 4 ? "Annulé" : item?.status === 5 ? "Commencé" : item?.status === 6 ? "Terminé" : "NC"}</div>
                                                        </div>
                                                        {item?.date_aut &&
                                                            <div className="app__land_data_detail_box">
                                                                <div className="app__land_data_detail_title">Date d'obtention de l'autorisation</div>
                                                                <div className="app__land_data_detail_value">{helpers.get_pretty_date_dd_mm_yyyy_from_yyy_mm_dd(item?.date_aut) || "NC"}</div>
                                                            </div>
                                                        }
                                                        {item?.date_start &&
                                                            <div className="app__land_data_detail_box">
                                                                <div className="app__land_data_detail_title">Date de début de travaux</div>
                                                                <div className="app__land_data_detail_value">{helpers.get_pretty_date_dd_mm_yyyy_from_yyy_mm_dd(item?.date_start) || "NC"}</div>
                                                            </div>
                                                        }
                                                        {item?.date_end &&
                                                            <div className="app__land_data_detail_box">
                                                                <div className="app__land_data_detail_title">Date de fin de travaux</div>
                                                                <div className="app__land_data_detail_value">{helpers.get_pretty_date_dd_mm_yyyy_from_yyy_mm_dd(item?.date_end) || "NC"}</div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {item?.dem_label &&
                                                        <>
                                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                                            <div className="app__land_data_detail_title" style={{ marginBottom: "5px", borderTop: "1px solid lightgrey", paddingTop: "3px" }}>Détails du demandeur</div>
                                                            <div className="app__land_data_detail_container" style={{ justifyContent: "start" }}>
                                                                <div className="app__land_data_detail_box" style={{ minWidth: "52.5%" }}>
                                                                    <div className="app__land_data_detail_title">Nom</div>
                                                                    <div className="app__land_data_detail_value">{item?.dem_label || "NC"}</div>
                                                                </div>
                                                                {item?.dem_siren &&
                                                                    <>
                                                                        <div className="app__land_data_detail_box" style={{ minWidth: "27%" }}>
                                                                            <div className="app__land_data_detail_title">SIREN</div>
                                                                            <div className="app__land_data_detail_value">{item?.dem_siren || "NC"}</div>
                                                                        </div>
                                                                        <div className="app__land_data_detail_box">
                                                                            <div className="app__land_data_detail_title">Lien Pappers</div>
                                                                            <div className="app__land_data_button" style={{ width: "115px" }}><a href={"https://www.pappers.fr/entreprise/" + item.dem_label.replace(/ /g, "-") + "-" + item.dem_siren} target="_blanck">Ouvrir sur Pappers</a></div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {item?.land_area &&
                                                        <>
                                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                                            <div className="app__land_data_detail_title" style={{ marginBottom: "5px", borderTop: "1px solid lightgrey", paddingTop: "3px" }}>Détails du terrain</div>
                                                            <div className="app__land_data_detail_container" style={{ justifyContent: "start" }}>
                                                                {item?.lands &&
                                                                    <div className="app__land_data_detail_box" style={{ minWidth: "52.5%" }}>
                                                                        <div className="app__land_data_detail_title" style={{ marginBottom: "4px" }}>Parcelle(s)</div>
                                                                        <div className="app__land_data_detail_value">{item?.lands.map(land => {
                                                                            var list = land.split("-");
                                                                            return (
                                                                                <span style={{ border: "1px solid lightgrey", borderRadius: "3px", padding: "0px 3px", marginRight: "2px", fontSize: "11px" }}>{list[1]}{list[0]}</span>
                                                                            )
                                                                        })}</div>
                                                                    </div>
                                                                }
                                                                {item?.land_area &&
                                                                    <div className="app__land_data_detail_box">
                                                                        <div className="app__land_data_detail_title">Superficie</div>
                                                                        <div className="app__land_data_detail_value">{helpers.get_pretty_num(item?.land_area) + " m²" || "NC"}</div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {item?.nature &&
                                                        <>
                                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                                            <div className="app__land_data_detail_title" style={{ marginBottom: "5px", borderTop: "1px solid lightgrey", paddingTop: "3px" }}>Détails du projet</div>
                                                            <div className="app__land_data_detail_container" style={{ justifyContent: "start" }}>
                                                                <div className="app__land_data_detail_box" style={{ minWidth: "52.5%" }}>
                                                                    <div className="app__land_data_detail_title">Nature</div>
                                                                    {item?.nature2 ?
                                                                        <div className="app__land_data_detail_value">{dau_nature2[item.nature2 - 1]}</div>
                                                                        :
                                                                        <div className="app__land_data_detail_value">{dau_nature[item.nature2 - 1]}</div>
                                                                    }
                                                                </div>
                                                                {item?.dest &&
                                                                    <div className="app__land_data_detail_box">
                                                                        <div className="app__land_data_detail_title">Destination</div>
                                                                        <div className="app__land_data_detail_value">{dau_dest[item.dest - 1]}</div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="app__land_data_detail_container" style={{ justifyContent: "start", marginTop: "3px" }}>
                                                                <div className="app__land_data_detail_box" style={{ minWidth: "52.5%" }}>
                                                                    <div className="app__land_data_detail_title">Surface de plancher existante</div>
                                                                    <div className="app__land_data_detail_value">{((item?.area_log_start + item?.area_loc_start) || 0).toFixed(2)} m²</div>
                                                                </div>
                                                                <div className="app__land_data_detail_box">
                                                                    {item?.dest > 1 ?
                                                                        <>
                                                                            <div className="app__land_data_detail_title">{item?.area_loc_created > item?.area_loc_deleted ? "Surface de plancher créée" : "Surface de plancher démolie"}</div>
                                                                            <div className="app__land_data_detail_value">{helpers.get_pretty_num((item?.area_loc_created > item?.area_loc_deleted ? item?.area_loc_created || 0 : item?.area_loc_deleted || 0).toFixed(2))} m²</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="app__land_data_detail_title">{item?.area_log_created > item?.area_log_deleted ? "Surface de plancher créée" : "Surface de plancher démolie"}</div>
                                                                            <div className="app__land_data_detail_value">{helpers.get_pretty_num((item?.area_log_created > item?.area_log_deleted ? item?.area_log_created || 0 : item?.area_log_deleted || 0).toFixed(2))} m²</div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {item?.is_pc_hab === true &&
                                                                <>
                                                                    <div className="app__land_data_detail_container" style={{ justifyContent: "start", marginTop: "3px" }}>
                                                                        <div className="app__land_data_detail_box" style={{ minWidth: "52.5%" }}>
                                                                            <div className="app__land_data_detail_title">Nombre de logement(s)</div>
                                                                            <div className="app__land_data_detail_value">{((item?.log_nb) || 0).toFixed(0)}</div>
                                                                        </div>
                                                                        <div className="app__land_data_detail_box">
                                                                            <div className="app__land_data_detail_title">Type de logement(s)</div>
                                                                            <div className="app__land_data_detail_value">{(item?.log_nb_col || item?.log_nb_ind || item?.log_nb_res) ?
                                                                                <>
                                                                                    {item?.log_nb_col === 1 && "1 collectif "}
                                                                                    {item?.log_nb_col > 1 && item.log_nb_col.toFixed(0) + " collectifs "}
                                                                                    {item?.log_nb_ind === 1 && "1 individuel "}
                                                                                    {item?.log_nb_ind > 1 && item.log_nb_ind.toFixed(0) + " individuels "}
                                                                                    {item?.log_nb_res === 1 && "1 logement en résidence "}
                                                                                    {item?.log_nb_res > 1 && item.log_nb_res.toFixed(0) + " logements en résidence "}
                                                                                </>
                                                                                :
                                                                                "NC"}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {(item?.log_rep && item.log_rep.length === 6) &&
                                                                        <div className="app__land_data_detail_container" style={{ justifyContent: "start", marginTop: "3px" }}>
                                                                            <div className="app__land_data_detail_box" style={{ minWidth: "17.5%" }}>
                                                                                <div className="app__land_data_detail_title">Nombre de T1</div>
                                                                                <div className="app__land_data_detail_value">{((item.log_rep[0]) || 0).toFixed(0)}</div>
                                                                            </div>
                                                                            <div className="app__land_data_detail_box" style={{ minWidth: "17.5%" }}>
                                                                                <div className="app__land_data_detail_title">Nombre de T2</div>
                                                                                <div className="app__land_data_detail_value">{((item.log_rep[1]) || 0).toFixed(0)}</div>
                                                                            </div>
                                                                            <div className="app__land_data_detail_box" style={{ minWidth: "17.5%" }}>
                                                                                <div className="app__land_data_detail_title">Nombre de T3</div>
                                                                                <div className="app__land_data_detail_value">{((item.log_rep[2]) || 0).toFixed(0)}</div>
                                                                            </div>
                                                                            <div className="app__land_data_detail_box" style={{ minWidth: "15.7%" }}>
                                                                                <div className="app__land_data_detail_title">Nombre de T4</div>
                                                                                <div className="app__land_data_detail_value">{((item.log_rep[3]) || 0).toFixed(0)}</div>
                                                                            </div>
                                                                            <div className="app__land_data_detail_box" style={{ minWidth: "15.7%" }}>
                                                                                <div className="app__land_data_detail_title">Nombre de T5</div>
                                                                                <div className="app__land_data_detail_value">{((item.log_rep[4]) || 0).toFixed(0)}</div>
                                                                            </div>
                                                                            <div className="app__land_data_detail_box" style={{ minWidth: "15.7%" }}>
                                                                                <div className="app__land_data_detail_title">Nombre de T6+</div>
                                                                                <div className="app__land_data_detail_value">{((item.log_rep[5]) || 0).toFixed(0)}</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div className="app__sectiondata_widget_spacer_10"></div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {capacity.context.sitadel.length > 3 &&
                                    <div className="app__context_housing_detail_button_container2">
                                        {!sitadelDetail ?
                                            <img className="app__fade" src={fade} alt="" />
                                            :
                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                        }
                                        <div className="app__context_housing_detail_button_container" onClick={handleSitadelDetail}>
                                            {sitadelDetail &&
                                                <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                            <div className="app__context_housing_detail_button">{sitadelDetail ? "Afficher moins de DAU" : 'Afficher les ' + capacity.context.sitadel.length + ' DAU'}</div>
                                            {!sitadelDetail &&
                                                <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <div className="app__sectiondata_widget_title">Relevé des demandes d'autorisation d'urbanisme (dans le secteur du terrain)</div>
                                <div className="app__sectiondata_widget_value_legend">Aucune DAU n'a été recensée dans le secteur du terrain depuis le 1er Janvier 2018</div>
                                <div className="app__sectiondata_widget_spacer_20"></div>
                            </>
                        }
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Base Sitadel - Ministère de la Transition Ecologique
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer_30"></div>
                    </>
                }
                {/* MERIMEE */}
                {capacity?.context?.merimee &&
                    <>
                        {capacity.context.merimee.length > 0 ?
                            <>
                                <div id="merimee_anchor" className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Présence d'immeubles protégés au titre des Monuments Historiques (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__sectiondata_widget_layer">
                                        <img src={ico_map_layers} alt="ico_map_layers" />
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_merimee" checked={mapLayers.context_merimee.isVisible} onChange={() => { layer_Toggle("context_merimee") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_merimee">Toggle</label></div>
                                    </div>
                                </div>
                                <div id="merimee_list" className={`app__sectiondata_merimee_container ${(!merimeeDetail && capacity.context.merimee.length > 3) ? "app__sectiondata_merimee_container_small" : ""}`}>
                                    {capacity.context.merimee.map((item, index) => {
                                        var url = "https://www.pop.culture.gouv.fr/notice/merimee/" + item.fields.reference;
                                        return (
                                            <div key={index}>
                                                <div className="app__sectiondata__widget">
                                                    <div className="app__buildings_value_main">{item?.fields?.appellation_courante || item?.fields?.titre_editorial_de_la_notice}</div>
                                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                                    <div className="app__land_data_detail_container">
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Protection</div>
                                                            <div className="app__land_data_detail_value">{item?.fields?.date_de_protection || item?.fields?.date_et_typologie_de_la_protection}</div>
                                                        </div>
                                                        {((item?.fields?.statut || item?.fields?.statut_juridique_de_l_edifice) && window.innerWidth > 550) &&
                                                            <div className="app__land_data_detail_box" style={{ maxWidth: "30%" }}>
                                                                <div className="app__land_data_detail_title">Statut juridique</div>
                                                                <div className="app__land_data_detail_value">{item?.fields?.statut || item?.fields?.statut_juridique_de_l_edifice}</div>
                                                            </div>
                                                        }
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Fiche détaillée</div>
                                                            <div className="app__land_data_button">
                                                                <a href={url} target="_blanck">Plateforme Ouverte du Patrimoine</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {item?.fields?.precision_sur_la_protection &&
                                                    <>
                                                        <div className="app__sectiondata_widget_spacer_10"></div>
                                                        <div className="app__land_data_detail_box">
                                                            <div className="app__land_data_detail_title">Détail de la protection</div>
                                                            <div className="app__land_data_detail_value">{item.fields.precision_sur_la_protection}</div>
                                                        </div>
                                                    </>
                                                } */}
                                                </div>
                                                <div className="app__sectiondata_widget_spacer_10"></div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {capacity.context.merimee.length > 3 &&
                                    <div className="app__context_housing_detail_button_container2">
                                        {!merimeeDetail ?
                                            <img className="app__fade" src={fade} alt="" />
                                            :
                                            <div className="app__sectiondata_widget_spacer_10"></div>
                                        }
                                        <div className="app__context_housing_detail_button_container" onClick={handleMerimeeDetail}>
                                            {merimeeDetail &&
                                                <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                            <div className="app__context_housing_detail_button">{merimeeDetail ? "Afficher moins d'immeubles" : 'Afficher les ' + capacity.context.merimee.length + ' immeubles'}</div>
                                            {!merimeeDetail &&
                                                <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <div className="app__sectiondata_widget_title">Présence d'immeubles protégés au titre des Monuments Historiques (dans un rayon de 1000m autour du terrain)</div>
                                <div className="app__sectiondata_widget_value_legend">Aucun immeuble classé Monument Historique recensé dans un rayon de 1000 m</div>
                                <div className="app__sectiondata_widget_spacer_20"></div>
                            </>
                        }
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : Base Mérimée - Ministère de la Culture
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                        <div className="app__sectiondata_widget_spacer_30"></div>
                    </>
                }
                {/* ISODISTANCE */}
                {capacity?.context?.iso_distance?.car_5?.point &&
                    <>
                        <div className="app__sectiondata_widget_title_container">
                            <div className="app__sectiondata_widget_title">Commodités à proximité immédiate du terrain</div>
                            <div className="app__sectiondata_widget_layer">
                                <img src={ico_map_layers} alt="ico_map_layers" />
                                <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_context_isodistance" checked={mapLayers.context_isodistance.isVisible} onChange={() => { layer_Toggle("context_isodistance") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_context_isodistance">Toggle</label></div>
                            </div>
                        </div>
                        <div className="app__sectiondata_widget_layout_A" style={{ gap: "12px" }}>
                            <div className="app__sectiondata__widget">
                                <div className="app__sectiondata_widget_title">Surface couverte en 5 minutes en voiture</div>
                                <div className="app__context_iso_container">
                                    <img src={ico_car_fill} alt="iso_car" />
                                    <div>
                                        <div className="app__context_iso_value_main">5 minutes</div>
                                        <div className="app__context_iso_value_second">en voiture</div>
                                    </div>
                                </div>
                            </div>
                            <div className="app__sectiondata__widget">
                                <div className="app__sectiondata_widget_title">Surface couverte en 10 minutes à pied</div>
                                <div className="app__context_iso_container">
                                    <img src={ico_walk_fill} alt="iso_walk" />
                                    <div>
                                        <div className="app__context_iso_value_main">10 minutes</div>
                                        <div className="app__context_iso_value_second">à pied</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* TRANSIT STATION */}
                        {(capacity?.landBase?.location?.nearby?.transit_station && capacity?.landBase?.location?.nearby?.transit_station.length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                    <div className="app__sectiondata_widget_title">Transport en commun (dans un rayon de 500m autour du terrain)</div>
                                    <div className="app__context_iso_container_small">
                                        <img src={ico_transit_station} alt="iso_transit" />
                                        <div>
                                            <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity?.landBase?.location?.nearby?.transit_station.length}</span> arrêts de transport en commun</div>
                                            <div className="app__context_iso_value_second_small">arrêts de bus, métro, tramway ou train</div>
                                        </div>
                                    </div>
                                    <div className={`app__context_iso_value_container ${transitDetail ? "" : "app__context_iso_value_container_hidden"}`}>
                                        {capacity?.landBase?.location?.nearby?.transit_station.map((item, index) => {
                                            if (item?.name) {
                                                var url = "https://www.google.com/maps/search/?api=1&query=" + item?.coordinates[0] + "%2C" + item?.coordinates[1] + "&query_place_id=" + item?.place_id + "&hl=fr";
                                                return (
                                                    <div className="app__context_iso_value_third">• <a href={url} target="_blank" rel="noopener noreferrer">{item?.name}</a></div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { setTransitDetail(!transitDetail) }}>
                                        {transitDetail ?
                                            <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            :
                                            <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {/* DOCTOR */}
                        {(capacity?.landBase?.location?.nearby?.doctor && capacity?.landBase?.location?.nearby?.doctor.length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                    <div className="app__sectiondata_widget_title">Etabilssement de santé (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__context_iso_container_small">
                                        <img src={ico_doctor} alt="iso_doctor" />
                                        <div>
                                            <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity?.landBase?.location?.nearby?.doctor.length}</span> établissements de santé</div>
                                            <div className="app__context_iso_value_second_small">cabinets médicaux, maisons de santé, cliniques ou hôpitaux</div>
                                        </div>
                                    </div>
                                    <div className={`app__context_iso_value_container ${doctorDetail ? "" : "app__context_iso_value_container_hidden"}`}>
                                        {capacity?.landBase?.location?.nearby?.doctor.map((item, index) => {
                                            if (item?.name) {
                                                var url = "https://www.google.com/maps/search/?api=1&query=" + item?.coordinates[0] + "%2C" + item?.coordinates[1] + "&query_place_id=" + item?.place_id + "&hl=fr";
                                                return (
                                                    <div className="app__context_iso_value_third">• <a href={url} target="_blank" rel="noopener noreferrer">{item?.name}</a></div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { setDoctorDetail(!doctorDetail) }}>
                                        {doctorDetail ?
                                            <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            :
                                            <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {/* SCHOOL */}
                        {(capacity?.landBase?.location?.nearby?.school && capacity?.landBase?.location?.nearby?.school.length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                    <div className="app__sectiondata_widget_title">Etabilssement scolaire (dans un rayon de 1500m autour du terrain)</div>
                                    <div className="app__context_iso_container_small">
                                        <img src={ico_school} alt="iso_school" />
                                        <div>
                                            <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity?.landBase?.location?.nearby?.school.length}</span> établissements scolaires</div>
                                            <div className="app__context_iso_value_second_small">crèches, écoles, collèges, lycées, cours privés ou clubs sportif</div>
                                        </div>
                                    </div>
                                    <div className={`app__context_iso_value_container ${schoolDetail ? "" : "app__context_iso_value_container_hidden"}`}>
                                        {capacity?.landBase?.location?.nearby?.school.map((item, index) => {
                                            if (item?.name) {
                                                var url = "https://www.google.com/maps/search/?api=1&query=" + item?.coordinates[0] + "%2C" + item?.coordinates[1] + "&query_place_id=" + item?.place_id + "&hl=fr";
                                                return (
                                                    <div className="app__context_iso_value_third">• <a href={url} target="_blank" rel="noopener noreferrer">{item?.name}</a></div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { setSchoolDetail(!schoolDetail) }}>
                                        {schoolDetail ?
                                            <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            :
                                            <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {/* GROCERY */}
                        {(capacity?.landBase?.location?.nearby?.grocery && capacity?.landBase?.location?.nearby?.grocery.length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                    <div className="app__sectiondata_widget_title">Magasin d'alimentation (dans un rayon de 1000m autour du terrain)</div>
                                    <div className="app__context_iso_container_small">
                                        <img src={ico_grocery} alt="iso_grocery" />
                                        <div>
                                            <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity?.landBase?.location?.nearby?.grocery.length}</span> magasins d'alimentation</div>
                                            <div className="app__context_iso_value_second_small">boulangeries, épiceries ou supermarchés</div>
                                        </div>
                                    </div>
                                    <div className={`app__context_iso_value_container ${groceryDetail ? "" : "app__context_iso_value_container_hidden"}`}>
                                        {capacity?.landBase?.location?.nearby?.grocery.map((item, index) => {
                                            if (item?.name) {
                                                var url = "https://www.google.com/maps/search/?api=1&query=" + item?.coordinates[0] + "%2C" + item?.coordinates[1] + "&query_place_id=" + item?.place_id + "&hl=fr";
                                                return (
                                                    <div className="app__context_iso_value_third">• <a href={url} target="_blank" rel="noopener noreferrer">{item?.name}</a></div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { setGroceryDetail(!groceryDetail) }}>
                                        {groceryDetail ?
                                            <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            :
                                            <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {/* PARK */}
                        {(capacity?.landBase?.location?.nearby?.park && capacity?.landBase?.location?.nearby?.park.length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="app__sectiondata__widget" style={{ paddingBottom: "8px" }}>
                                    <div className="app__sectiondata_widget_title">Parc (dans un rayon de 1500m autour du terrain)</div>
                                    <div className="app__context_iso_container_small">
                                        <img src={ico_park} alt="iso_park" />
                                        <div>
                                            <div className="app__context_iso_value_second_strong"><span className="app__context_iso_value_main_small">{capacity?.landBase?.location?.nearby?.park.length}</span> parcs</div>
                                            <div className="app__context_iso_value_second_small">parcs, bois, jardins ou espaces publiques</div>
                                        </div>
                                    </div>
                                    <div className={`app__context_iso_value_container ${parkDetail ? "" : "app__context_iso_value_container_hidden"}`}>
                                        {capacity?.landBase?.location?.nearby?.park.map((item, index) => {
                                            if (item?.name) {
                                                var url = "https://www.google.com/maps/search/?api=1&query=" + item?.coordinates[0] + "%2C" + item?.coordinates[1] + "&query_place_id=" + item?.place_id + "&hl=fr";
                                                return (
                                                    <div className="app__context_iso_value_third">• <a href={url} target="_blank" rel="noopener noreferrer">{item?.name}</a></div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div className="app__context_housing_detail_button_container" style={{ marginTop: "6px" }} onClick={() => { setParkDetail(!parkDetail) }}>
                                        {parkDetail ?
                                            <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            :
                                            <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        <div className="app__sectiondata_widget_spacer_10"></div>
                        <div className="app__sectiondata_widget_info_container">
                            <div className="app__sectiondata_widget_info_text">
                                Source : IGN - Service de calcul d'isochrones & Google Places
                            </div>
                            <div className="app__sectiondata_widget_info_icon">i</div>
                        </div>
                    </>
                }
            </>
        )
    }
    else {
        return (
            <div className="app__sectiondata_loading_container">
                <div className="app__sectiondata_loading"></div>
                <div className="app__sectiondata_loading_text">Nous analysons les données contextuelles</div>
                <div className="app__sectiondata_loading_text_small_container">
                    <div className="app__sectiondata_noaccess_text_small">• Zonage ABC</div>
                    <div className="app__sectiondata_noaccess_text_small">• Statistiques de la population</div>
                    <div className="app__sectiondata_noaccess_text_small">• Statistiques du parc immobilier</div>
                    <div className="app__sectiondata_noaccess_text_small">• Transactions immobilières</div>
                    <div className="app__sectiondata_noaccess_text_small">• Demandes d'autorisation d'urbanisme</div>
                    <div className="app__sectiondata_noaccess_text_small">• Présence de monuments historiques</div>
                    <div className="app__sectiondata_noaccess_text_small">• Commodités à proximité du terrain</div>
                </div>
            </div>
        )
    }
};

export default Context;