import * as map_helpers from '../components/app/map/Map_helpers';
import * as helpers from './Other_helpers';
import * as turf from '@turf/turf';


export const get_combinations_rework = (capacity) => {

    const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    // Safety check
    if (!capacity?.buildable?.volume?.levels && !capacity.buildable.volume.levels.length > 1) {
        console.log("UNABLE TO SIMULATE");
        return;
    }
    if (!capacity.buildable.volume.levels[1]?.workingSurfaces && !capacity.buildable.volume.levels[1].workingSurfaces.length > 0) {
        console.log("UNABLE TO SIMULATE");
        return;
    }


    // Get parameters
    const buildingWidth = capacity?.buildable?.volume?.parameters?.max_building_width || 10;
    const buildingOffsetMain_type = capacity?.buildable?.volume?.parameters?.min_offset_facade_main || { type: "recul", value: 6 };
    const buildingOffsetMain = get_buildingOffsetFromType(capacity, buildingOffsetMain_type);
    const buildingOffsetSecond_type = capacity?.buildable?.volume?.parameters?.min_offset_facade_edge || { type: "recul", value: 4 };
    const buildingOffsetSecond = get_buildingOffsetFromType(capacity, buildingOffsetSecond_type);


    // __________ Skeleton Lines and buffers

    var workingLines = [];
    var skeletonLines = [];
    var skeletonLines_combinations = [];

    // Loop on each working surface
    capacity.buildable.volume.levels[1].workingSurfaces.forEach((workingSurface, workingSurface_index) => {



        // Check if working Surface is big enought to work on it
        if (workingSurface.properties.area > (buildingWidth * buildingWidth)) {

            console.log("________________ ZONE", workingSurface_index);

            var currentWorkingLines = [];
            var currentSkeletonLines = [];
            var currentSkeletonLines_combinations = [];
            var currentSkeletonLines_mandatory = {
                all: [],
                few: []
            }

            // Create prorata availableSurfaces
            workingSurface.properties.availableSurfaces = [];
            capacity.buildable.volume.levels[1].availableSurfaces.forEach(availableSurface => {
                var intersection = turf.intersect(workingSurface.properties.real_poly, availableSurface);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    var ratio = availableSurface.properties.buildableArea / availableSurface.properties.availableArea;
                    intersection.properties.area = intersection_area;
                    intersection.properties.buildableArea = intersection_area * ratio;
                    intersection.properties.matching_points = [];
                    workingSurface.properties.availableSurfaces.push(intersection);
                }
            })
            // Create prorata maxBuildableSurfaces
            workingSurface.properties.maxBuildableSurfaces = [];
            capacity.buildable.volume.levels[1].maxBuildableSurfaces.forEach(maxBuildableSurface => {
                var intersection = turf.intersect(workingSurface.properties.real_poly, maxBuildableSurface);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    var ratio = maxBuildableSurface.properties.buildableArea / maxBuildableSurface.properties.availableArea;
                    intersection.properties.area = intersection_area;
                    intersection.properties.buildableArea = intersection_area * ratio;
                    intersection.properties.matching_points = [];
                    workingSurface.properties.maxBuildableSurfaces.push(intersection);
                }
            })

            // Create skeleton lines from rules : alignments
            if (capacity?.buildable?.volume?.parameters?.alignments.length > 0) {
                capacity?.buildable?.volume?.parameters?.alignments.forEach(alignment => {

                    // Loop on each alignment line
                    alignment.lines.forEach(line => {

                        // Check if alignment line is inside working surface
                        var intersection = turf.intersect(turf.buffer(line, 0.0005, { steps: 1 }), workingSurface);
                        // console.log("intersection", intersection);
                        if (intersection !== null && turf.area(intersection) > 1) {

                            // Loop on each line segment of alignment line
                            for (var i = 1; i < line.geometry.coordinates.length; i++) {

                                // Create workingLine
                                var currentWorkingLine = get_line_data([map_helpers.mercator_to_local(line.geometry.coordinates[i - 1], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(line.geometry.coordinates[i], capacity.landBase.union.center.geometry.coordinates)]);
                                currentWorkingLine.line_coords_global = [line.geometry.coordinates[i - 1], line.geometry.coordinates[i]];

                                // Create skeletonLine on positive side
                                var currentSkeletonLine_pos = get_offset_line_data(currentWorkingLine, (buildingWidth / 2));
                                currentSkeletonLine_pos.line_coords_global = [map_helpers.local_to_mercator(currentSkeletonLine_pos.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(currentSkeletonLine_pos.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                                // Check this line is inside workingSurface
                                var intersection_skeleton_pos = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine_pos.line_coords_global), 0.0005, { steps: 1 }), workingSurface);
                                console.log("intersection_skeleton_pos", intersection_skeleton_pos);
                                if (intersection_skeleton_pos !== null && turf.area(intersection_skeleton_pos) > 1) {
                                    // Keep creating skeleton line
                                    currentSkeletonLine_pos = get_extended_line_data(currentSkeletonLine_pos, capacity);
                                    currentSkeletonLine_pos.line_coords_global = [map_helpers.local_to_mercator(currentSkeletonLine_pos.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(currentSkeletonLine_pos.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                                    currentSkeletonLine_pos.line_buffer_test = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine_pos.line_coords_global, { name: 'line 1' }), 0.001, { steps: 1 }), workingSurface);
                                    currentSkeletonLine_pos.line_buffer = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine_pos.line_coords_global), (buildingWidth / 2000), { steps: 1 }), workingSurface);
                                    currentSkeletonLine_pos.sublines = [];
                                    currentSkeletonLine_pos.intersections = {};
                                    currentSkeletonLine_pos.origin = "rule";
                                    currentSkeletonLine_pos.isMandatory = true;

                                    // Get intersection with workingSurface
                                    currentSkeletonLine = get_line_intersections_surface(currentSkeletonLine_pos, workingSurface, capacity);

                                    // CHECK IF SKELETON LINE IS VALID
                                    var test = true;
                                    // // Check if buffer area is not too small compared to expexted area => means that working surface is eaten big part
                                    // if (get_line_area_validity(currentSkeletonLine_pos, buildingWidth) === false) {test = false};
                                    // // Check if not too close of another skeleton line
                                    // var distance_test = get_line_distance_validity(currentSkeletonLine_pos, currentSkeletonLines);
                                    // if (distance_test === false) {
                                    //     test = false;
                                    // }
                                    // else if (distance_test !== true) {
                                    //     var new_skeletonLines_data = get_lines_removing_item(currentSkeletonLines, distance_test, currentSkeletonLines_mandatory);
                                    //     currentSkeletonLines = new_skeletonLines_data.new_currentSkeletonLines;
                                    //     currentSkeletonLines_mandatory = new_skeletonLines_data.new_currentSkeletonLines_mandatory;
                                    // }

                                    // Add skeleton lines
                                    if (test === true) {
                                        currentSkeletonLines.push(currentSkeletonLine_pos);
                                        if (alignment.alignAll === true) {
                                            currentSkeletonLines_mandatory.all.push(currentSkeletonLines.length - 1);
                                        }
                                        else {
                                            currentSkeletonLines_mandatory.few.push(currentSkeletonLines.length - 1);
                                        }
                                    }
                                }

                                // Create skeletonLine on negative side
                                var currentSkeletonLine_neg = get_offset_line_data(currentWorkingLine, -(buildingWidth / 2));
                                currentSkeletonLine_neg.line_coords_global = [map_helpers.local_to_mercator(currentSkeletonLine_neg.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(currentSkeletonLine_neg.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                                // Check this line is inside workingSurface
                                var intersection_skeleton_neg = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine_neg.line_coords_global), 0.0005, { steps: 1 }), workingSurface);
                                console.log("intersection_skeleton_neg", intersection_skeleton_neg);
                                if (intersection_skeleton_neg !== null && turf.area(intersection_skeleton_neg) > 1) {
                                    // Keep creating skeleton line
                                    currentSkeletonLine_neg = get_extended_line_data(currentSkeletonLine_neg, capacity);
                                    currentSkeletonLine_neg.line_coords_global = [map_helpers.local_to_mercator(currentSkeletonLine_neg.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(currentSkeletonLine_neg.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                                    currentSkeletonLine_neg.line_buffer_test = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine_neg.line_coords_global, { name: 'line 1' }), 0.001, { steps: 1 }), workingSurface);
                                    currentSkeletonLine_neg.line_buffer = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine_neg.line_coords_global), (buildingWidth / 2000), { steps: 1 }), workingSurface);
                                    currentSkeletonLine_neg.sublines = [];
                                    currentSkeletonLine_neg.intersections = {};
                                    currentSkeletonLine_neg.origin = "rule";
                                    currentSkeletonLine_neg.isMandatory = true;

                                    // Get intersection with workingSurface
                                    currentSkeletonLine = get_line_intersections_surface(currentSkeletonLine_neg, workingSurface, capacity);


                                    // CHECK IF SKELETON LINE IS VALID
                                    var test = true;
                                    // // Check if buffer area is not too small compared to expexted area => means that working surface is eaten big part
                                    // if (get_line_area_validity(currentSkeletonLine_neg, buildingWidth) === false) {test = false};
                                    // // Check if not too close of another skeleton line
                                    // var distance_test = get_line_distance_validity(currentSkeletonLine_neg, currentSkeletonLines);
                                    // if (distance_test === false) {
                                    //     test = false;
                                    // }
                                    // else if (distance_test !== true) {
                                    //     var new_skeletonLines_data = get_lines_removing_item(currentSkeletonLines, distance_test, currentSkeletonLines_mandatory);
                                    //     currentSkeletonLines = new_skeletonLines_data.new_currentSkeletonLines;
                                    //     currentSkeletonLines_mandatory = new_skeletonLines_data.new_currentSkeletonLines_mandatory;
                                    // }     

                                    // Add skeleton lines
                                    if (test === true) {
                                        currentSkeletonLines.push(currentSkeletonLine_neg);
                                        if (alignment.alignAll === true) {
                                            currentSkeletonLines_mandatory.all.push(currentSkeletonLines.length - 1);
                                        }
                                        else {
                                            currentSkeletonLines_mandatory.few.push(currentSkeletonLines.length - 1);
                                        }
                                    }
                                }
                            }

                        }
                    })
                })
            }



            // Create skeleton lines from working surface bounds
            for (var i = 1; i < workingSurface.geometry.coordinates[0].length; i++) {

                // Create workingLine
                var currentWorkingLine = get_line_data([map_helpers.mercator_to_local(workingSurface.geometry.coordinates[0][i - 1], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(workingSurface.geometry.coordinates[0][i], capacity.landBase.union.center.geometry.coordinates)]);
                currentWorkingLine.line_coords_global = [workingSurface.geometry.coordinates[0][i - 1], workingSurface.geometry.coordinates[0][i]];
                currentWorkingLines.push(currentWorkingLine);

                // Create skeletonLine
                var currentSkeletonLine = get_offset_line_data(currentWorkingLine, (buildingWidth / 2));
                currentSkeletonLine.line_coords_global = [map_helpers.local_to_mercator(currentSkeletonLine.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(currentSkeletonLine.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                currentSkeletonLine = get_extended_line_data(currentSkeletonLine, capacity);
                currentSkeletonLine.line_coords_global = [map_helpers.local_to_mercator(currentSkeletonLine.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(currentSkeletonLine.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                currentSkeletonLine.line_buffer_test = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine.line_coords_global), 0.001, { steps: 1 }), workingSurface);
                currentSkeletonLine.line_buffer = turf.intersect(turf.buffer(turf.lineString(currentSkeletonLine.line_coords_global), (buildingWidth / 2000), { steps: 1 }), workingSurface);
                currentSkeletonLine.sublines = [];
                currentSkeletonLine.intersections = {};
                currentSkeletonLine.origin = "workingSurface";
                currentSkeletonLine.isMandatory = false;

                // Get intersection with workingSurface
                currentSkeletonLine = get_line_intersections_surface(currentSkeletonLine, workingSurface, capacity);

                // CHECK IF SKELETON LINE IS VALID
                var test = true;
                // Check if buffer area is not too small compared to expexted area => means that working surface is eaten big part
                if (get_line_area_validity(currentSkeletonLine, buildingWidth) === false) { test = false };
                // Check if not too close of another skeleton line
                var distance_test = get_line_distance_validity(currentSkeletonLine, currentSkeletonLines);
                if (distance_test === false) {
                    test = false;
                }
                else if (distance_test !== true) {
                    var new_skeletonLines_data = get_lines_removing_item(currentSkeletonLines, distance_test, currentSkeletonLines_mandatory);
                    currentSkeletonLines = new_skeletonLines_data.new_currentSkeletonLines;
                    currentSkeletonLines_mandatory = new_skeletonLines_data.new_currentSkeletonLines_mandatory;
                }

                if (test === true) {
                    // Add skeleton lines
                    currentSkeletonLines.push(currentSkeletonLine);
                }

            }
            console.log("currentSkeletonLines", currentSkeletonLines);
            console.log("currentSkeletonLines_mandatory", currentSkeletonLines_mandatory);

            // Check skeletonLines inside availableSurfaces
            workingSurface = get_lines_in_available(currentSkeletonLines, workingSurface);
            console.log("workingSurface", workingSurface);

            // Add intermediate skeleton lines
            currentSkeletonLines = get_intermediate_lines(currentSkeletonLines, capacity, workingSurface, buildingWidth, buildingOffsetMain);

            // Add intersections and get unmatching lits
            var currentSkeletonLines_unmatch_analysis = get_lines_unmatching_pairs(currentSkeletonLines, workingSurface, buildingWidth, buildingOffsetMain)
            currentSkeletonLines = currentSkeletonLines_unmatch_analysis.lines_list;

            // Get combinations ids with filter on unmatching and mandatory
            var currentSkeletonLines_combinations_ids = get_lines_all_combinations([...Array(currentSkeletonLines.length).keys()], currentSkeletonLines_unmatch_analysis.unmatching_list, currentSkeletonLines_mandatory);
            console.log("currentSkeletonLines_combinations_ids", currentSkeletonLines_combinations_ids);

            // Create real combinations
            currentSkeletonLines_combinations_ids.forEach((combination_ids, combination_index) => {

                try {
                    // Get shriked lines, groups and coords
                    console.log(combination_index, " => combination_ids", combination_ids);
                    var currentSkeletonLines_shrinked_groups_coords = get_lines_shrinked_and_ordered(combination_ids, currentSkeletonLines, capacity, buildingWidth);
                    console.log("currentSkeletonLines_shrinked_groups_coords", currentSkeletonLines_shrinked_groups_coords);

                    // STANDARD
                    // Initialize current combination
                    var currentSkeletonLines_combination = { ids: combination_ids, lines: [], buffers: [] };
                    // Create final elements
                    var groups_lines = [];
                    var groups_lines_inside = [];
                    // var groups_buffers = [];
                    currentSkeletonLines_shrinked_groups_coords.standard.lines_groups_coords.forEach((lines_group, lines_group_index) => {
                        var line = turf.lineString(lines_group);
                        groups_lines.push(line);
                        var line_inside = turf.lineString(currentSkeletonLines_shrinked_groups_coords.standard.lines_groups_coords_inside[lines_group_index]);
                        line_inside.properties.points_to_modify = new Array(line_inside.geometry.coordinates.length).fill(null);
                        groups_lines_inside.push(line_inside);
                        // groups_buffers.push(get_line_buffer_square(line, buildingWidth, workingSurface, capacity));
                    })
                    currentSkeletonLines_combination.lines = groups_lines;
                    currentSkeletonLines_combination.lines_inside = groups_lines_inside;
                    // currentSkeletonLines_combination.buffers = groups_buffers;
                    currentSkeletonLines_combination.workingSurface = workingSurface;
                    // Add current combination
                    currentSkeletonLines_combinations.push(currentSkeletonLines_combination);

                    // EXTENDED
                    if (currentSkeletonLines_shrinked_groups_coords.extended !== null) {
                        // Initialize current combination
                        var currentSkeletonLines_combination = { ids: combination_ids, lines: [] };
                        // Create final elements
                        var groups_lines = [];
                        var groups_lines_inside = [];
                        var groups_buffers = [];
                        currentSkeletonLines_shrinked_groups_coords.extended.lines_groups_coords.forEach((lines_group, lines_group_index) => {
                            var line = turf.lineString(lines_group);
                            groups_lines.push(line);
                            var line_inside = turf.lineString(currentSkeletonLines_shrinked_groups_coords.extended.lines_groups_coords_inside[lines_group_index]);
                            line_inside.properties.points_to_modify = new Array(line_inside.geometry.coordinates.length).fill(null);
                            groups_lines_inside.push(line_inside);
                            // groups_buffers.push(get_line_buffer_square(line, buildingWidth, workingSurface, capacity));
                        })
                        currentSkeletonLines_combination.lines = groups_lines;
                        currentSkeletonLines_combination.lines_inside = groups_lines_inside;
                        // currentSkeletonLines_combination.buffers = groups_buffers;
                        currentSkeletonLines_combination.workingSurface = workingSurface;
                        // Add current combination
                        currentSkeletonLines_combinations.push(currentSkeletonLines_combination);
                    }

                } catch (error) {
                    console.log("ERROR creating currentSkeletonLines_combination", error);
                }

            })

        }

        workingLines.push(currentWorkingLines);
        skeletonLines.push(currentSkeletonLines);
        if (currentSkeletonLines_combinations !== undefined && currentSkeletonLines_combinations.length > 0) {
            skeletonLines_combinations.push(currentSkeletonLines_combinations);
        }
    })
    console.log("workingLines", workingLines);
    console.log("skeletonLines", skeletonLines);
    console.log("skeletonLines_combinations", skeletonLines_combinations);


    // Create combinations
    var combinations = [];

    skeletonLines_combinations.forEach((skeletonLines_group, index1) => {
        // initialize group of workingSurface
        var combinations_group = [];

        skeletonLines_group.forEach((skeletonLines_combination, index2) => {

            console.log("---------- COMBINATION", index1, index2);

            // Initialize combination
            var combination = {
                id: skeletonLines_combinations.length > 1 ? letters[index1] + "-" + combinations_group.length : combinations_group.length.toString(),
                buildings: [],
                offset_buffers: null,
                undergrounds: [],
                parking: {
                    ext: {
                        area: 0,
                        area_real: 0,
                        nb_spot: 0
                    },
                    sub: {
                        area: 0,
                        area_real: 0,
                        nb_spot: 0,
                    }
                },
                area: 0,
                area_real: 0,
                facade_area: 0,
                groundArea: 0,
                groundArea_real: 0,
                parkingArea: 0,
                parkingArea_real: 0,
                validity: true,
                message: "",
                test: { skeletonLine: skeletonLines_combinations[index1][index2] }
            }

            try {
                // Get buildings
                var combination_buildings = get_combi_buildings(skeletonLines_combination, skeletonLines[index1], capacity, buildingWidth);
                console.log("combination_buildings", combination_buildings);
                if (combination_buildings?.error !== null) {
                    combination.message = combination_buildings.error;
                    combination.validity = false;
                }
                combination.buildings = combination_buildings.buildings;

                // Get buildings offset_buffer
                var combination_buffer = get_combi_offset_buffer(combination_buildings.buildings, buildingOffsetMain);
                combination.offset_buffers = combination_buffer;
                combination.test.skeletonLine.buffers = combination_buffer;

                // Get undergrounds and parking
                combination.undergrounds = combination_buildings.undergrounds;
                combination.parking.sub.area = combination_buildings.underground_data.undergrounds_area_total;
                combination.parking.sub.area_real = combination_buildings.underground_data.undergrounds_area_total;
                combination.parking.sub.nb_spot = combination_buildings.underground_data.undergrounds_nb_spot_total;
                combination.parking.ext.area = combination_buildings.parkingExt_data.extParking_area;
                combination.parking.ext.area_real = combination_buildings.parkingExt_data.extParking_area;
                combination.parking.ext.nb_spot = combination_buildings.parkingExt_data.extParking_nb_spot;

                // Get area
                combination_buildings.buildings.forEach((building, buildings_index) => {
                    combination.area += building.parameters.area;
                    combination.area_real += building.parameters.area_real;
                    combination.facade_area += building.parameters.facade_area;
                    combination.groundArea += building.levels[0].area;
                    combination.groundArea_real += building.levels[0].area_real;
                })

                combinations_group.push(combination);

            } catch (error) {
                console.log("ERROR creating combination", error);
            }

        })

        combinations.push(combinations_group);

    })
    console.log("combinations", combinations);


    var result = combinations;










    // var model = {
    //     "id": 0,
    //     "old_id": 0,
    //     "buildings": ["test"],
    //     "parking": {
    //         "ext": {
    //             "area": -373.40198170095937,
    //             "area_real": -373.40198170095937,
    //             "nb_place": -15
    //         },
    //         "sub": {
    //             "area": 373.40198170095937,
    //             "area_real": 373.40198170095937,
    //             "nb_place": 12,
    //             "levels": []
    //         }
    //     },
    //     "area": 1867.0099064373271,
    //     "area_real": 1867.0099064373271,
    //     "groundArea": 373.40198170095937,
    //     "groundArea_real": 373.40198170095937,
    //     "parkingArea": 0,
    //     "parkingArea_real": 0,
    //     "validity": true,
    //     "message": "",
    //     "test": {}
    // }

    // var result = [];

    // var base = skeletonLines_combinations;
    // base.forEach((item, index) => {
    //     item.forEach((item2, index2) => {
    //         result.push({...model,id: index2, test: {skeletonLine: base[index][index2]} })
    //     })
    // })
    // result = [result];


    return result


}

// _______________________________________________________________CALCULUS
const get_buildingOffsetFromType = (capacity, buildingOffset_type) => {
    var value = null;

    if (buildingOffset_type?.type === "recul") {
        value = buildingOffset_type?.value
    }
    else if (buildingOffset_type?.type === "prospect" && capacity?.buildable?.volume?.parameters?.max_height) {
        value = capacity?.buildable?.volume?.parameters?.max_height / buildingOffset_type?.value
    }

    if (value === null || value === undefined) { value = 6 }

    return value
}


const get_lines_all_combinations = (lines_list, unmatching_list, mandatory_list) => {
    // console.log("unmatching_list", unmatching_list);

    var all_combinations = [];
    for (var i = 1; i <= lines_list.length; i++) {
        // Get combinations
        var current_combinations = helpers.k_combinations(lines_list, i);
        // console.log("current_combinations", current_combinations);

        // Filter with unmatching and mandatory lists
        var current_combinations_filtered = [];
        current_combinations.forEach(combination => {
            var combination_test_index = get_lines_matching(combination);
            var combination_test = "";
            if (combination_test_index.length > 0) {
                combination_test_index.forEach(list_index => {
                    var list_items = [];
                    list_index.forEach(item => {
                        list_items.push(combination[item]);
                    })
                    combination_test = combination_test + "-" + list_items.join("-") + "-|";
                })
            }
            else {
                combination_test = "-" + combination[0] + "-|";
            }
            // console.log("combination_test", combination_test);


            // Test unmatch
            var test_unmatch = false;
            if (!unmatching_list.some(unmatch => combination_test.includes(unmatch))) {
                test_unmatch = true;
            }

            // Test mandatory
            var test_mandatory_few = false;
            if (mandatory_list.few.length === 0 || mandatory_list.few.some(mandatory => combination_test.includes("-" + mandatory + "-"))) {
                test_mandatory_few = true;
            }
            var test_mandatory_all = false;
            if (mandatory_list.all.length === 0 || (combination.length === mandatory_list.all.length && mandatory_list.all.every(mandatory => combination_test.includes("-" + mandatory + "-")))) {
                test_mandatory_all = true;
            }

            // console.log("test_unmatch", test_unmatch);
            // console.log("test_mandatory_few", test_mandatory_few);
            // console.log("test_mandatory_all", test_mandatory_all);
            if (test_unmatch === true && test_mandatory_few === true && test_mandatory_all === true) {
                current_combinations_filtered.push(combination);
            }
        })
        // Concat
        all_combinations = all_combinations.concat(current_combinations_filtered);
    }

    return all_combinations
}

const get_lines_matching = (lines_list) => {
    var matching_list = [];
    lines_list.forEach((n1, index1) => {
        lines_list.forEach((n2, index2) => {
            if (index2 > index1) {
                matching_list.push([index1, index2]);
            }
        })
    })

    return matching_list
}

const get_lines_unmatching_pairs = (lines_list, workingSurface, buildingWidth, buildingOffsetMain) => {
    var unmatchingList = [];
    var matchingList = get_lines_matching(lines_list);

    for (var match_index = 0; match_index < matchingList.length; match_index++) {
        var current_lines_list = matchingList[match_index];
        var test = false;
        var line1 = lines_list[current_lines_list[0]];
        var line2 = lines_list[current_lines_list[1]];

        // Check if crossing
        var intersects = turf.lineIntersect(turf.lineString(line1.line_coords_global), turf.lineString(line2.line_coords_global));
        if (intersects.features.length > 0) {
            // Check if intersect is inside land polygon
            var inside = turf.booleanPointInPolygon(intersects.features[0], workingSurface);
            // Check angle between lines
            var angle = get_angle_between_two_lines(line1, line2);
            // Check if not one of the line if completely inside the other one
            var isInside = is_line_inside_other(line1, line2, intersects.features[0]);
            console.log("inside", inside, " - angle", angle, " - isInside", isInside);
            if (inside === true && (angle > 30 && angle < 150) && isInside === false) {
                test = true;
                // Add to intersections data of both lines
                lines_list[current_lines_list[0]].intersections["line_" + current_lines_list[1]] = intersects.features[0].geometry.coordinates;
                lines_list[current_lines_list[1]].intersections["line_" + current_lines_list[0]] = intersects.features[0].geometry.coordinates;
            }
            else {
                test = get_lines_unmatching_pairs_distance(line1, line2, buildingWidth, buildingOffsetMain);
            }
        }
        // If not crossing, check if distance is big enought not to overlap buildings
        else {
            test = get_lines_unmatching_pairs_distance(line1, line2, buildingWidth, buildingOffsetMain);
        }

        if (test === false) {
            unmatchingList.push("-" + matchingList[match_index][0] + "-" + matchingList[match_index][1] + "-");
        }

    }

    return { lines_list, unmatching_list: unmatchingList }
}

const get_line_intersections_surface = (line, workingSurface, capacity) => {

    var landLines = turf.polygonToLine(turf.intersect(workingSurface, capacity.landBase.union.geometry));

    // Get intersections of line with land bounds
    var intersects_land = turf.lineIntersect(turf.lineString(line.line_coords_global), landLines);
    var intersects_land_list = [];
    intersects_land.features.forEach(intersection_point => {
        intersects_land_list.push(intersection_point.geometry.coordinates);
    })
    line.intersections.land = intersects_land_list;

    return line

}

const get_lines_unmatching_pairs_distance = (line1, line2, buildingWidth, buildingOffsetMain) => {
    var test = false;

    // var midpoint = turf.midpoint(turf.point(line1.line_coords_global[0]), turf.point(line1.line_coords_global[1]));
    var midpoint = turf.centroid(line1.line_buffer_test);
    var distance = turf.pointToLineDistance(midpoint, turf.lineString(line2.line_coords_global)) * 1000;
    if (distance >= (buildingWidth + buildingOffsetMain)) {
        test = true;
    }

    return test
}

const get_line_area_validity = (line, buildingWidth) => {

    var area_test = false;

    if (line?.intersections?.land && line.intersections.land.length === 2 && line?.line_buffer?.geometry?.type === "Polygon") {

        var lineInside_length = turf.distance(turf.point(line.intersections.land[0]), turf.point(line.intersections.land[1])) * 1000;
        var area_expected = lineInside_length * buildingWidth;
        var area_buffer = turf.area(line?.line_buffer);
        var ratio = Math.round(100 * area_buffer / area_expected);

        if (ratio >= 95 && lineInside_length >= 1 * buildingWidth && area_buffer >= (buildingWidth * buildingWidth / 2)) {
            area_test = true;
        }
        else {
            // console.log("AREA NOT VALID");
            // console.log("lineInside_length", lineInside_length);
            // console.log("area_expected", area_expected);
            // console.log("area_buffer", area_buffer);
            // console.log("ratio", ratio);
        }
    }

    return area_test
}

const get_line_distance_validity = (line, lines_list) => {
    var close_test = true;
    try {
        for (var j = 0; j < lines_list.length; j++) {
            console.log("distance validity", lines_list.length, j);
            console.log(lines_list[j].line_buffer_test);
            console.log(line.line_buffer_test);
            var intersection = turf.intersect(lines_list[j].line_buffer_test, line.line_buffer_test);
            if (intersection !== null) {
                var intersection_area = turf.area(intersection);
                // console.log("intersection_area", intersection_area.toFixed(2), ((intersection_area / turf.area(line.line_buffer_test)) * 100).toFixed(1) + "%");
                if (intersection_area > 6 && ((intersection_area / turf.area(line.line_buffer_test)) * 100) > 15) {
                    // Check if it keeps current or previous one
                    if (lines_list[j].isMandatory === true || turf.area(lines_list[j].line_buffer) > turf.area(line.line_buffer)) {
                        close_test = false;
                    }
                    else {
                        close_test = j;
                    }
                    break;
                }
            }
        }
    } catch (error) {
        close_test = false
    }

    return close_test
}

const is_line_inside_other = (line1, line2, intersection) => {
    if (!line1?.intersections?.land || line1?.intersections?.land.length < 2 || !line2?.intersections?.land || line2?.intersections?.land.length < 2) {
        return null
    }
    // Get line land point farest from intersection
    var line1_land_point = line1.intersections.land[0];
    if (turf.distance(turf.point(line1.intersections.land[1]), intersection) > turf.distance(turf.point(line1.intersections.land[0]), intersection)) {
        line1_land_point = line1.intersections.land[1];
    }
    // Check if line1 land point is inside line2 buffer
    var intersect = turf.intersect(turf.buffer(turf.point(line1_land_point), 0.0001, { steps: 1 }), line2.line_buffer);
    if (intersect !== null) {
        return true
    }
    // If not => try the other way around
    else {
        // Get line land point farest from intersection
        var line2_land_point = line2.intersections.land[0];
        if (turf.distance(turf.point(line2.intersections.land[1]), intersection) > turf.distance(turf.point(line2.intersections.land[0]), intersection)) {
            line2_land_point = line2.intersections.land[1];
        }
        // Check if line2 land point is inside line1 buffer
        var intersect = turf.intersect(turf.buffer(turf.point(line2_land_point), 0.0001, { steps: 1 }), line1.line_buffer);
        if (intersect !== null) {
            return true
        }
        else {
            return false
        }
    }

}

const get_lines_removing_item = (currentSkeletonLines, removing_index, currentSkeletonLines_mandatory) => {
    console.log("removing", removing_index);
    console.log("currentSkeletonLines_mandatory", currentSkeletonLines_mandatory);
    var new_currentSkeletonLines = [];
    var new_currentSkeletonLines_mandatory = { all: [], few: [] };

    for (var i = 0; i < currentSkeletonLines.length; i++) {
        if (i !== removing_index) {
            new_currentSkeletonLines.push(currentSkeletonLines[i]);
        }
    }

    currentSkeletonLines_mandatory.all.forEach(line_index => {
        if (line_index < removing_index) {
            new_currentSkeletonLines_mandatory.all.push(line_index);
        }
        else if (line_index > removing_index) {
            new_currentSkeletonLines_mandatory.all.push(line_index - 1);
        }
    })

    currentSkeletonLines_mandatory.few.forEach(line_index => {
        if (line_index < removing_index) {
            new_currentSkeletonLines_mandatory.few.push(line_index);
        }
        else if (line_index > removing_index) {
            new_currentSkeletonLines_mandatory.few.push(line_index - 1);
        }
    })

    console.log("new_currentSkeletonLines_mandatory", new_currentSkeletonLines_mandatory);


    return { new_currentSkeletonLines, new_currentSkeletonLines_mandatory }

}

const get_lines_in_available = (currentSkeletonLines, workingSurface) => {

    workingSurface.properties.availableSurfaces.forEach(availableSurface => {
        currentSkeletonLines.forEach((skeletonLine, line_index) => {
            // Check if skeleton line is insdie availableSurface
            var intersection = turf.intersect(availableSurface, turf.buffer(turf.lineString([skeletonLine.intersections.land[0], skeletonLine.intersections.land[1]]), 0.0005, { steps: 1 }));
            if (intersection !== null && turf.area(intersection) >= 1) {

                var points = [];
                // Check lands intersections
                if (turf.booleanPointInPolygon(turf.point(skeletonLine.intersections.land[0]), turf.buffer(availableSurface, 0.0001, { steps: 1 }))) {
                    // points.push(0);
                    points.push(skeletonLine.intersections.land[0].join(";"));
                }
                if (turf.booleanPointInPolygon(turf.point(skeletonLine.intersections.land[1]), turf.buffer(availableSurface, 0.0001, { steps: 1 }))) {
                    // points.push(1);
                    points.push(skeletonLine.intersections.land[1].join(";"));
                }

                // if (points.length > 0) {
                if (points !== "") {
                    // availableSurface.properties.skeletonLines["line_" + line_index] = points;
                    availableSurface.properties.matching_points = availableSurface.properties.matching_points.concat(points);
                }
            }
        })
    })

    workingSurface.properties.maxBuildableSurfaces.forEach(maxBuildableSurface => {
        currentSkeletonLines.forEach((skeletonLine, line_index) => {
            // Check if skeleton line is insdie maxBuildableSurface
            var intersection = turf.intersect(maxBuildableSurface, turf.buffer(turf.lineString([skeletonLine.intersections.land[0], skeletonLine.intersections.land[1]]), 0.0005, { steps: 1 }));
            if (intersection !== null && turf.area(intersection) >= 1) {

                var points = [];
                // Check lands intersections
                if (turf.booleanPointInPolygon(turf.point(skeletonLine.intersections.land[0]), turf.buffer(maxBuildableSurface, 0.0001, { steps: 1 }))) {
                    // points.push(0);
                    points.push(skeletonLine.intersections.land[0].join(";"));
                }
                if (turf.booleanPointInPolygon(turf.point(skeletonLine.intersections.land[1]), turf.buffer(maxBuildableSurface, 0.0001, { steps: 1 }))) {
                    // points.push(1);
                    points.push(skeletonLine.intersections.land[1].join(";"));
                }

                // if (points.length > 0) {
                if (points !== "") {
                    // maxBuildableSurface.properties.skeletonLines["line_" + line_index] = points;
                    maxBuildableSurface.properties.matching_points = maxBuildableSurface.properties.matching_points.concat(points);
                }
            }
        })
    })

    return workingSurface
}

const get_intermediate_lines = (lines_list, capacity, workingSurface, buildingWidth, buildingOffsetMain) => {
    var intermediateList = [];
    var matchingList = get_lines_matching(lines_list);

    for (var match_index = 0; match_index < matchingList.length; match_index++) {
        var current_lines_list = matchingList[match_index];
        var line1 = lines_list[current_lines_list[0]];
        var line2 = lines_list[current_lines_list[1]];

        // Check angle between lines => only for almost parallels lines
        var angle = get_angle_between_two_lines(line1, line2);
        if (angle < 20 || angle > 160) {

            var midpoint = turf.midpoint(turf.point(line1.line_coords_global[0]), turf.point(line1.line_coords_global[1]));
            var distance = turf.pointToLineDistance(midpoint, turf.lineString(line2.line_coords_global)) * 1000;

            var nbIntermediateLines = Math.floor(distance / (buildingWidth + buildingOffsetMain)) - 1;
            if (nbIntermediateLines >= 1) {
                // Create intermediate lines
                for (var i = 1; i <= nbIntermediateLines; i++) {
                    var intermediateSkeletonLine = get_offset_line_data(line1, (buildingWidth + buildingOffsetMain));
                    intermediateSkeletonLine.line_coords_global = [map_helpers.local_to_mercator(intermediateSkeletonLine.line_coords_local[0], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(intermediateSkeletonLine.line_coords_local[1], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)];
                    intermediateSkeletonLine.line_buffer_test = turf.intersect(turf.buffer(turf.lineString(intermediateSkeletonLine.line_coords_global, { name: 'line 1' }), 0.001, { steps: 1 }), workingSurface);
                    intermediateSkeletonLine.origin = "intermediate_" + current_lines_list[0] + "-" + current_lines_list[1];
                    intermediateSkeletonLine.sublines = [];
                    intermediateSkeletonLine.intersections = {};

                    lines_list[current_lines_list[0]].sublines.push(intermediateSkeletonLine);
                    intermediateList.push(intermediateSkeletonLine);
                }
            }
        }
    }

    return lines_list
}

const get_lines_shrinked_and_ordered = (combination_ids, currentSkeletonLines, capacity, buildingWidth) => {
    console.log("combination_ids", combination_ids);
    console.log("currentSkeletonLines", currentSkeletonLines);

    var extended_min_length = (buildingWidth / 2) + 5;

    var currentSkeletonLines_shrinked = [];
    var currentSkeletonLines_shrinked_full = [];

    // Get string indexes to check match
    var combination_ids_string = combination_ids.map(i => 'line_' + i);

    // Initialize groups of lines to separate buildings
    var lines_groups = [[]];
    var lines_groups_full = [[]];

    // Loop th all combination ids
    for (var i = 0; i < combination_ids.length; i++) {

        // Get intersection of current skeleton line with others
        var intersections = [];
        var intersections_inside = [];
        var intersections_full = [];
        var intersections_full_inside = [];
        var isEdge = false;
        var isExtend = false;
        // Get intersections
        combination_ids_string.forEach(line_id => {
            if (line_id in currentSkeletonLines[combination_ids[i]].intersections) {
                // console.log("line ", combination_ids[i], " intersects ", line_id);
                intersections.push(currentSkeletonLines[combination_ids[i]].intersections[line_id]);
                intersections_full.push(currentSkeletonLines[combination_ids[i]].intersections[line_id]);
                intersections_inside.push(currentSkeletonLines[combination_ids[i]].intersections[line_id]);
                intersections_full_inside.push(currentSkeletonLines[combination_ids[i]].intersections[line_id]);
            }
        })

        // If 2 intersections, try extending
        if (intersections.length === 2) {
            // Get land_intersections order compare to intersections order
            var max = { index: 0, value: 0 };
            currentSkeletonLines[combination_ids[i]].intersections.land.forEach((land_point, index) => {
                var distance = turf.distance(turf.point(intersections[0]), turf.point(land_point));
                if (distance > max.value) {
                    max.index = index;
                    max.value = distance;
                }
            })
            var min_index = 0;
            if (max.index === 0) { min_index = 1; }
            var intersections_land_index = [min_index, max.index];
            // Loop on each intersection
            intersections.forEach((intersection, index) => {
                var distance = turf.distance(turf.point(intersection), turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[intersections_land_index[index]])) * 1000;
                if (distance >= extended_min_length) {
                    // Get a point 10m further than bound intersection
                    var bearing = turf.bearing(turf.midpoint(turf.point(currentSkeletonLines[combination_ids[i]].line_coords_global[0]), turf.point(currentSkeletonLines[combination_ids[i]].line_coords_global[1])), turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[intersections_land_index[index]]));
                    var destination = turf.destination(turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[intersections_land_index[index]]), 0.01, bearing);
                    var intersection_coords = turf.getCoord(destination);
                    // Add intersection point
                    if (index === 0) {
                        intersections_full.unshift(intersection_coords);
                        intersections_full_inside.unshift(currentSkeletonLines[combination_ids[i]].intersections.land[intersections_land_index[index]]);
                        intersections_full.unshift(intersections[index]);
                        intersections_full_inside.unshift(intersections_inside[index]);
                    }
                    else {
                        intersections_full.push(intersection_coords);
                        intersections_full_inside.push(currentSkeletonLines[combination_ids[i]].intersections.land[intersections_land_index[index]]);
                        intersections_full.push(intersections[index]);
                        intersections_full_inside.push(intersections_inside[index]);
                    }

                    isExtend = true;
                }

            })

        }
        // If no intersections => add start and end point
        else if (intersections.length <= 0) {
            intersections = currentSkeletonLines[combination_ids[i]].line_coords_global;
            intersections_full = currentSkeletonLines[combination_ids[i]].line_coords_global;
            intersections_inside = currentSkeletonLines[combination_ids[i]].intersections?.land || currentSkeletonLines[combination_ids[i]].line_coords_global;
            intersections_full_inside = currentSkeletonLines[combination_ids[i]].intersections?.land || currentSkeletonLines[combination_ids[i]].line_coords_global;
            // Add group if current is not empty
            if (lines_groups[lines_groups.length - 1].length > 0) {
                lines_groups.push([]);
                lines_groups_full.push([]);
            }
            isEdge = true;
        }
        // If only one intersection => add farest point intersecting land
        else if (intersections.length === 1) {
            // Simple variante
            var max = { index: 0, value: 0 }
            currentSkeletonLines[combination_ids[i]].intersections.land.forEach((land_point, index) => {
                var distance = turf.distance(turf.point(intersections[0]), turf.point(land_point));
                if (distance > max.value) {
                    max.index = index;
                    max.value = distance;
                }
            })
            // Get a point 10m further than bound intersection
            var bearing = turf.bearing(turf.midpoint(turf.point(currentSkeletonLines[combination_ids[i]].line_coords_global[0]), turf.point(currentSkeletonLines[combination_ids[i]].line_coords_global[1])), turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[max.index]));
            var destination = turf.destination(turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[max.index]), 0.01, bearing);
            var intersection_coords = turf.getCoord(destination);
            intersections.push(intersection_coords);
            intersections_full.push(intersection_coords);
            intersections_inside.push(currentSkeletonLines[combination_ids[i]].intersections.land[max.index]);
            intersections_full_inside.push(currentSkeletonLines[combination_ids[i]].intersections.land[max.index]);

            // Extended variante
            var min_index = 0;
            if (max.index === 0) { min_index = 1; }
            var distance = turf.distance(turf.point(intersections_full[0]), turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[min_index])) * 1000;
            // console.log("distance", distance);
            if (distance >= extended_min_length) {
                // Get a point 10m further than bound intersection
                var bearing = turf.bearing(turf.midpoint(turf.point(currentSkeletonLines[combination_ids[i]].line_coords_global[0]), turf.point(currentSkeletonLines[combination_ids[i]].line_coords_global[1])), turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[min_index]));
                var destination = turf.destination(turf.point(currentSkeletonLines[combination_ids[i]].intersections.land[min_index]), 0.01, bearing);
                var intersection_coords = turf.getCoord(destination);
                intersections_full.unshift(intersection_coords);
                intersections_full_inside.unshift(currentSkeletonLines[combination_ids[i]].intersections.land[min_index]);
                // Add intersection point because
                intersections_full.unshift(intersections[0]);
                intersections_full_inside.unshift(intersections_inside[0]);

                isExtend = true;
            }

            isEdge = true;
        }
        // Avoid error, intersections must be at least 2
        if (intersections.length < 2) {
            console.log("ERROR intersections");
            intersections = currentSkeletonLines[combination_ids[i]].line_coords_global;
            intersections_inside = currentSkeletonLines[combination_ids[i]].line_coords_global;
        }
        if (intersections_full.length < intersections.length) {
            console.log("ERROR intersections_full");
            intersections_full = intersections;
            intersections_full_inside = intersections_inside;
        }
        console.log("line_index", combination_ids[i]);
        console.log("intersections", intersections);
        console.log("intersections_full", intersections_full);


        // Create each shrinked lines
        for (var j = 1; j < intersections.length; j++) {
            var currentSkeletonLine_shrinked = get_line_data([map_helpers.mercator_to_local(intersections[j - 1], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(intersections[j], capacity.landBase.union.center.geometry.coordinates)]);
            currentSkeletonLine_shrinked.line_coords_global = [intersections[j - 1], intersections[j]];
            currentSkeletonLine_shrinked.lineInside_coords_global = [intersections_inside[j - 1], intersections_inside[j]];
            currentSkeletonLine_shrinked.lineInside_coords_local = [map_helpers.mercator_to_local(intersections_inside[j - 1], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(intersections_inside[j], capacity.landBase.union.center.geometry.coordinates)];
            currentSkeletonLine_shrinked.isEdge = isEdge;
            currentSkeletonLine_shrinked.isExtend = false;
            currentSkeletonLines_shrinked.push(currentSkeletonLine_shrinked);

            // Add to group
            lines_groups[lines_groups.length - 1].push(currentSkeletonLines_shrinked.length - 1);
        }


        // Create each full shrinked lines
        for (var j = 1; j < intersections_full.length; j++) {
            var testExtended = false;
            if (isExtend && ((j < intersections_full.length - 1 && intersections_full[j - 1][0] === intersections_full[j + 1][0]) || (j > 1 && intersections_full[j][0] === intersections_full[j - 2][0]))) {
                testExtended = true;
            }
            var testEdge = false;
            if (isEdge && testExtended === false) {
                testEdge = true;
            }
            var currentSkeletonLine_shrinked = get_line_data([map_helpers.mercator_to_local(intersections_full[j - 1], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(intersections_full[j], capacity.landBase.union.center.geometry.coordinates)]);
            currentSkeletonLine_shrinked.line_coords_global = [intersections_full[j - 1], intersections_full[j]];
            currentSkeletonLine_shrinked.lineInside_coords_global = [intersections_full_inside[j - 1], intersections_full_inside[j]];
            currentSkeletonLine_shrinked.lineInside_coords_local = [map_helpers.mercator_to_local(intersections_full_inside[j - 1], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(intersections_full_inside[j], capacity.landBase.union.center.geometry.coordinates)];
            currentSkeletonLine_shrinked.isEdge = testEdge;
            currentSkeletonLine_shrinked.isExtend = testExtended;
            currentSkeletonLines_shrinked_full.push(currentSkeletonLine_shrinked);

            // Add to group
            lines_groups_full[lines_groups_full.length - 1].push(currentSkeletonLines_shrinked_full.length - 1);
        }
    }

    // Get ordered lines groups
    var lines_groups_organized_data = get_lines_groups_ordered(lines_groups, currentSkeletonLines_shrinked);
    var lines_groups_full_organized_data = null;
    if (currentSkeletonLines_shrinked.length < currentSkeletonLines_shrinked_full.length) {
        lines_groups_full_organized_data = get_lines_groups_ordered(lines_groups_full, currentSkeletonLines_shrinked_full);
    }



    return {
        lines: currentSkeletonLines_shrinked,
        standard: lines_groups_organized_data,
        extended: lines_groups_full_organized_data
    }
}

const get_lines_groups_ordered = (lines_groups, currentSkeletonLines_shrinked) => {
    console.log("lines_groups", lines_groups);
    console.log("currentSkeletonLines_shrinked", currentSkeletonLines_shrinked);
    // Reorganize lines groups
    // console.log("lines_groups", lines_groups);
    var lines_groups_organized = [];
    var lines_groups_coords = [];
    var lines_groups_coords_inside = [];
    lines_groups.forEach((lines_group) => {
        var lines_group_organized = [];
        var lines_group_organized_coords = [];
        var lines_group_organized_coords_inside = [];
        var index_taken = [];
        var isClose = false;
        var last_noExtend_index = null;
        // Loop inside lines_group as much times as number of items
        for (var j = 0; j < lines_group.length; j++) {
            // Set coords to search for match
            var coords_match = null;
            if (lines_group_organized.length > 0) {
                coords_match = currentSkeletonLines_shrinked[lines_group_organized[lines_group_organized.length - 1]].line_coords_global;
            }

            var matching_index = null;
            var coords_start_index = null;
            var coords_end_index = null;
            var coords_first_index = null;

            for (var i = 0; i < lines_group.length; i++) {
                // Check if index is already taken
                if (index_taken.includes(i)) { continue }
                // Get last no extend index fot closed buildings
                if (currentSkeletonLines_shrinked[lines_group[i]].isEdge === false && currentSkeletonLines_shrinked[lines_group[i]].isExtend === false) {
                    last_noExtend_index = i;
                }
                // Get first one
                if (lines_group_organized.length === 0) {
                    // Check if edge or last item (for closed buildings that has no edge)
                    // if (currentSkeletonLines_shrinked[lines_group[i]].isEdge || i === lines_group.length - 1) {
                    if (currentSkeletonLines_shrinked[lines_group[i]].isEdge) {
                        // Add line index
                        lines_group_organized.push(lines_group[i]);
                        // Add coords if only one line
                        if (lines_group.length === 1) {
                            lines_group_organized_coords = currentSkeletonLines_shrinked[lines_group[i]].line_coords_global;
                            lines_group_organized_coords_inside = currentSkeletonLines_shrinked[lines_group[i]].lineInside_coords_global;
                        }
                        // Fill taken index list
                        index_taken.push(i);
                        break
                    }
                    // Check if last one (closed buildings)
                    if (i === lines_group.length - 1) {
                        isClose = true;
                        // => Get as first point the last_noExtend_index
                        // Add line index
                        lines_group_organized.push(lines_group[last_noExtend_index]);
                        // Add coords if only one line
                        if (lines_group.length === 1) {
                            lines_group_organized_coords = currentSkeletonLines_shrinked[lines_group[last_noExtend_index]].line_coords_global;
                            lines_group_organized_coords_inside = currentSkeletonLines_shrinked[lines_group[last_noExtend_index]].lineInside_coords_global;
                        }
                        // Fill taken index list
                        index_taken.push(last_noExtend_index);
                        break
                    }
                }
                // Get following ones
                else {

                    var matching_current = false;

                    if (currentSkeletonLines_shrinked[lines_group[i]].line_coords_global[0][0] === coords_match[0][0]) {
                        coords_start_index = 0;
                        coords_end_index = 1;
                        coords_first_index = 1;
                        matching_index = i;
                        matching_current = true;
                    }
                    else if (currentSkeletonLines_shrinked[lines_group[i]].line_coords_global[0][0] === coords_match[1][0]) {
                        coords_start_index = 0;
                        coords_end_index = 1;
                        coords_first_index = 0;
                        matching_index = i;
                        matching_current = true;
                    }
                    else if (currentSkeletonLines_shrinked[lines_group[i]].line_coords_global[1][0] === coords_match[0][0]) {
                        coords_start_index = 1;
                        coords_end_index = 0;
                        coords_first_index = 1;
                        matching_index = i;
                        matching_current = true;
                    }
                    else if (currentSkeletonLines_shrinked[lines_group[i]].line_coords_global[1][0] === coords_match[1][0]) {
                        coords_start_index = 1;
                        coords_end_index = 0;
                        coords_first_index = 0;
                        matching_index = i;
                        matching_current = true;
                    }

                    if (matching_current === true && matching_index !== null && currentSkeletonLines_shrinked[lines_group[i]].isExtend === true) {
                        break
                    }

                }
            }

            if (matching_index !== null) {
                // Add line index of current line
                lines_group_organized.push(lines_group[matching_index]);
                // Add coords of first line (did not know its direction before)
                if (lines_group_organized_coords.length === 0) {
                    lines_group_organized_coords.push(currentSkeletonLines_shrinked[lines_group_organized[lines_group_organized.length - 2]].line_coords_global[coords_first_index]);
                    lines_group_organized_coords_inside.push(currentSkeletonLines_shrinked[lines_group_organized[lines_group_organized.length - 2]].lineInside_coords_global[coords_first_index]);
                }
                // Add coords of current line
                lines_group_organized_coords.push(currentSkeletonLines_shrinked[lines_group[matching_index]].line_coords_global[coords_start_index]);
                lines_group_organized_coords_inside.push(currentSkeletonLines_shrinked[lines_group[matching_index]].lineInside_coords_global[coords_start_index]);
                // Add last coords if final line (edge or last)
                if (currentSkeletonLines_shrinked[lines_group[matching_index]]?.isEdge || j === lines_group.length - 1) {
                    lines_group_organized_coords.push(currentSkeletonLines_shrinked[lines_group[matching_index]].line_coords_global[coords_end_index]);
                    lines_group_organized_coords_inside.push(currentSkeletonLines_shrinked[lines_group[matching_index]].lineInside_coords_global[coords_end_index]);
                }
                // Fill taken index list
                index_taken.push(matching_index);
            }
        }

        // If closed lines => set first and last coords to middle of line
        if (isClose === true) {
            var midpoint_coords = turf.getCoord(turf.midpoint(turf.point(lines_group_organized_coords[0]), turf.point(lines_group_organized_coords[1])));
            lines_group_organized_coords[0] = midpoint_coords;
            lines_group_organized_coords_inside[0] = midpoint_coords;
            lines_group_organized_coords.push(midpoint_coords);
            lines_group_organized_coords_inside.push(midpoint_coords);
        }

        lines_groups_organized.push(lines_group_organized);
        lines_groups_coords.push(lines_group_organized_coords);
        lines_groups_coords_inside.push(lines_group_organized_coords_inside);
    })
    // console.log("lines_groups_organized",lines_groups_organized);
    // console.log("lines_groups_coords",lines_groups_coords);

    return { lines_groups: lines_groups_organized, lines_groups_coords, lines_groups_coords_inside }
}

const get_line_buffer_square = (line, buildingWidth, workingSurface, capacity) => {

    var buffer = turf.buffer(line, (buildingWidth / 2000), { steps: 1 });
    var coords_list = buffer.geometry.coordinates[0];
    // console.log("coords_list", coords_list);

    // Remove last one
    coords_list.splice(-1);


    var coords_new = [];
    var coords_last = coords_list[coords_list.length - 1];
    var coords_last_last = coords_list[coords_list.length - 2];
    var angle_last = (get_angle_between_two_lines({ line_coords_global: [coords_list[coords_list.length - 2], coords_list[coords_list.length - 1]] }, { line_coords_global: [coords_list[coords_list.length - 1], coords_list[0]] }) * 100);
    var angle_last_last = (get_angle_between_two_lines({ line_coords_global: [coords_list[coords_list.length - 3], coords_list[coords_list.length - 2]] }, { line_coords_global: [coords_list[coords_list.length - 2], coords_list[coords_list.length - 1]] }) * 100);
    var index_to_delete = [];

    for (var i = 0; i < coords_list.length; i++) {

        if (index_to_delete.includes(i)) continue

        var i_current = i;
        var i_prev = (i > 0) ? (i - 1) : coords_list.length - 1;
        var i_next = (i < coords_list.length - 1) ? (i + 1) : 0;
        var i_next_next = (i < coords_list.length - 2) ? (i + 2) : (i === coords_list.length - 2) ? 0 : 1;
        var angle = (get_angle_between_two_lines({ line_coords_global: [coords_list[i_prev], coords_list[i_current]] }, { line_coords_global: [coords_list[i_current], coords_list[i_next]] }) * 100);
        var angle_next = (get_angle_between_two_lines({ line_coords_global: [coords_list[i_current], coords_list[i_next]] }, { line_coords_global: [coords_list[i_next], coords_list[i_next_next]] }) * 100);
        // console.log("Lines ", i_prev, i_current, i_next);
        // console.log("angle", (angle).toFixed(3));
        // console.log("angle_next", (angle_next).toFixed(3));

        if (Math.round(angle_last_last) === 13500 && Math.round(angle_last) === 9000 && Math.round(angle) === 13500) {
            // console.log("EDGE");
            if (coords_new.length > 0) {
                // Remove last points
                coords_new.splice(-1);
            }
            else {
                index_to_delete.push(i_prev);
            }
        }
        if (!(Math.round(angle_last_last) === 9000 && Math.round(angle_last) === 13500) && !(Math.round(angle) === 13500 && Math.round(angle_next) === 9000) && Math.round(angle_last / 10) === Math.round(angle / 10)) {
            // console.log("BEVEL");
            // Add intersection point
            var line1 = turf.lineString([coords_last_last, coords_last]);
            var line2 = turf.lineString([coords_list[i_current], coords_list[i_next]]);
            var intersects = turf.lineIntersect(turf.transformScale(line1, 10), turf.transformScale(line2, 10));
            if (intersects.features.length > 0) {
                if (coords_new.length > 0) {
                    // Remove last points
                    coords_new.splice(-1);
                }
                else {
                    index_to_delete.push(i_prev);
                }
                // Add intersection point
                coords_new.push(intersects.features[0].geometry.coordinates);
            }
        }
        else {
            coords_new.push(coords_list[i_current]);
        }

        // Update last avriables
        coords_last_last = coords_last;
        coords_last = coords_list[i_current];
        angle_last_last = angle_last;
        angle_last = angle;
    }

    buffer.geometry.coordinates[0] = coords_new;

    // Intersect land
    var buffer_intersected = turf.intersect(buffer, workingSurface);
    buffer_intersected = turf.intersect(buffer_intersected, capacity.landBase.union.geometry);

    // Get area
    if (buffer_intersected !== null) {
        buffer_intersected.properties.area = turf.area(buffer_intersected);
        buffer_intersected.properties.area_max = line?.properties?.area_max || null;
    }

    return buffer_intersected

}








// _______________________________________________________________LINES DATA

const get_line_data = (line_coords) => {
    const line_type = get_line_type(line_coords[0][0], line_coords[1][0], line_coords[0][1], line_coords[1][1]);
    var line_length = null;
    var line_coef = null;
    var line_origin = null;
    var linePerp_coef = null;
    var linePerp_start_origin = null;
    var linePerp_end_origin = null;

    if (line_type === "linear") {
        line_length = get_line_length(line_coords);
        line_coef = get_line_coef(line_coords);
        line_origin = get_line_origin(line_coords[0], line_coef);
        linePerp_coef = get_linePerp_coef(line_coef);
        linePerp_start_origin = get_line_origin(line_coords[0], linePerp_coef);
        linePerp_end_origin = get_line_origin(line_coords[1], linePerp_coef);
    }
    return {
        line_type: line_type,
        line_length: line_length,
        line_coef: line_coef,
        line_origin: line_origin,
        line_coords_local: line_coords,
        linePerp_coef: linePerp_coef,
        linePerp_start_origin: linePerp_start_origin,
        linePerp_end_origin: linePerp_end_origin
    }
}

const get_line_type = (x1, x2, y1, y2) => {
    if (x1 === x2) { return "vertical" }
    else if (y1 === y2) { return "horizontal" }
    else { return "linear" }
}

const get_line_length = (line_coords) => {
    if (line_coords === null || line_coords === undefined || line_coords.length === 0) {
        return 0
    }
    const line_length = Math.sqrt(Math.pow((line_coords[0][0] - line_coords[1][0]), 2) + Math.pow((line_coords[1][1] - line_coords[0][1]), 2));
    return line_length
}

const get_line_coef = (line_coords) => {
    const line_coef = (line_coords[1][1] - line_coords[0][1]) / (line_coords[1][0] - line_coords[0][0]);
    return line_coef
}

const get_line_origin = (line_coords, line_coef) => {
    const line_origin = line_coords[1] - line_coef * line_coords[0];
    return line_origin
}

const get_linePerp_coef = (line_coef) => {
    const linePerp_coef = -1 / line_coef;
    return linePerp_coef
}

const get_angle_between_two_lines = (line1, line2) => {

    var L1_bearing = turf.bearing(turf.point(line1.line_coords_global[0]), turf.point(line1.line_coords_global[1]));
    var L2_bearing = turf.bearing(turf.point(line2.line_coords_global[0]), turf.point(line2.line_coords_global[1]));
    var bearing_angle = L2_bearing - L1_bearing + 180;
    if (bearing_angle > 360) {
        bearing_angle -= 360;
    }
    if (bearing_angle < -360) {
        bearing_angle += 360;
    }
    if (bearing_angle < 0) {
        bearing_angle += 360;
    }
    if (bearing_angle > 180) {
        bearing_angle -= 180;
    }

    return bearing_angle
}

const get_offset_line_data = (line_data, offset) => {
    var line_origin = get_lineOffset_origin(line_data, offset);
    var coords_start = get_coords_intersection_two_equations({ line_coef: line_data.line_coef, line_origin }, { line_coef: line_data.linePerp_coef, line_origin: line_data.linePerp_start_origin });
    var coords_end = get_coords_intersection_two_equations({ line_coef: line_data.line_coef, line_origin }, { line_coef: line_data.linePerp_coef, line_origin: line_data.linePerp_end_origin });
    var line_offset_data = get_line_data([coords_start, coords_end]);
    return line_offset_data
}

const get_lineOffset_origin = (line_data, offset) => {
    var lineOffset_origin = offset * Math.sqrt(line_data.line_coef * line_data.line_coef + 1) + line_data.line_origin;
    if (line_data.line_coords_local[0][0] > line_data.line_coords_local[1][0]) {
        lineOffset_origin = -offset * Math.sqrt(line_data.line_coef * line_data.line_coef + 1) + line_data.line_origin;
    }
    return lineOffset_origin
}

const get_coords_intersection_two_equations = (line1_data, line2_data) => {
    // y1 = a1 * x1 + b1 & y2 = a2 * x2 + b2 => y1 = y2 so => a1 * x1 + b1 = a2 * x2 + b2 => x (a1 - a2) = b2 - b1 => x = (b2 - b1) / (a1 - a2)
    var x = (line2_data.line_origin - line1_data.line_origin) / (line1_data.line_coef - line2_data.line_coef);
    var y = line1_data.line_coef * x + line1_data.line_origin;
    return [x, y]
}

const get_extended_line_data = (line_data, capacity) => {
    var target_length = Math.max(capacity.landBase.union.bbox.lengthH, capacity.landBase.union.bbox.lengthV) * 3;
    var multiplicator = target_length / line_data.line_length;
    var extended_line_turf = turf.transformScale(turf.lineString(line_data.line_coords_global), multiplicator, { mutate: true });
    var extended_line_data = get_line_data([map_helpers.mercator_to_local(extended_line_turf.geometry.coordinates[0], capacity.landBase.union.center.geometry.coordinates), map_helpers.mercator_to_local(extended_line_turf.geometry.coordinates[1], capacity.landBase.union.center.geometry.coordinates)]);
    extended_line_data.line_coords_global = extended_line_turf.geometry.coordinates;

    return extended_line_data
}



// ______________ COMBI

const get_combi_buildings = (skeletonLines_combination, skeletonLines, capacity, buildingWidth) => {
    var error = null;

    // _____ Get buffers of all buildings
    var buffers_data = get_combi_buffers_from_lines(skeletonLines_combination.lines, buildingWidth, skeletonLines_combination.workingSurface, capacity);
    var buffers = buffers_data.buffers;
    var buffers_area = buffers_data.buffers_area;

    // _____ Get buildings
    var buildings_data = get_combi_buildings_from_buffers(buffers, capacity);
    var buildings = buildings_data.buildings;
    var buildings_area_total = buildings_data.buildings_area_total;
    var buildings_facade_area_total = buildings_data.buildings_facade_area_total;
    var buildings_area_ground = buildings_data.buildings_area_ground;

    // _____ Get undergrounds
    var underground_data = get_combi_underground_from_buildings(buildings_data, capacity);
    var undergrounds = underground_data.undergrounds;
    var parkingExt_data = underground_data.parkingExt;
    console.log("parkingExt_data", parkingExt_data);

    // Check if reduce needed from ext parking
    skeletonLines_combination.workingSurface.properties.availableSurfaces.forEach(surface => {
        surface.properties.buildableArea_i = surface.properties.buildableArea;
    })
    if (parkingExt_data.extParking_reduce.area_ground > 0) {
        // Get lines inside coordinates to compare to matching points
        var lines_inside_coords = [];
        skeletonLines_combination.lines_inside.forEach((line_inside, line_inside_index) => {
            line_inside.geometry.coordinates.forEach((coordinate, coordinate_index) => {
                lines_inside_coords.push(coordinate.join(";"));
            })
        })
        console.log("lines_inside_coords", lines_inside_coords);
        var surfaces_to_reduce = [];
        // Get max buildable area set from rules
        var buildableArea_ground_sum = 0;
        var buildableArea_ground_sum_filtered = 0;
        var area_to_remove_from_rules_sum = 0;
        skeletonLines_combination.workingSurface.properties.availableSurfaces.forEach((surface, surface_index) => {
            buildableArea_ground_sum += surface?.properties?.buildableArea || 0;
            var toReduce = false;
            for (var i = 0; i < lines_inside_coords.length; i++) {
                if (surface.properties.matching_points.includes(lines_inside_coords[i])) {
                    toReduce = true;
                    surfaces_to_reduce.push(surface_index);
                    area_to_remove_from_rules_sum += surface?.properties?.area - surface?.properties?.buildableArea || 0;
                    buildableArea_ground_sum_filtered += surface?.properties?.buildableArea || 0;
                    break;
                }
            }
        })
        console.log("surfaces_to_reduce", surfaces_to_reduce);
        console.log("buildableArea_ground_sum", buildableArea_ground_sum);
        console.log("area_to_remove_from_rules_sum", area_to_remove_from_rules_sum);
        // Check if ext parking needs to reduce buildable area more than rules
        if (buildableArea_ground_sum > buildings_area_ground - parkingExt_data.extParking_reduce.area_ground) {
            var area_buildable_max = buildings_area_ground - parkingExt_data.extParking_reduce.area_ground;
            var area_to_remove = buildableArea_ground_sum - area_buildable_max;
            console.log("NEED TO REDUCE BUILDINGS AREA BECAUSE OF EXT PARKING", area_buildable_max);
            // Dispatch area to reduce in prorata of surfaces
            skeletonLines_combination.workingSurface.properties.availableSurfaces.forEach((surface, surface_index) => {
                if (surfaces_to_reduce.includes(surface_index)) {
                    var area_to_remove_prorata = area_to_remove * (surface?.properties?.buildableArea / buildableArea_ground_sum_filtered);
                    surface.properties.buildableArea_i = surface.properties.buildableArea - area_to_remove_prorata;
                }
            })
        }
    }
    console.log("skeletonLines_combination.workingSurface.properties.availableSurfaces", JSON.parse(JSON.stringify(skeletonLines_combination.workingSurface.properties.availableSurfaces)));


    // _____ Reduce buffers if necessary
    // console.log("buildings_area_ground", buildings_area_ground);
    // console.log("buildings_area_total", buildings_area_total);
    var isReduced = false;

    // ___ Ground area restriction
    if (skeletonLines_combination.workingSurface.properties.availableSurfaces.length > 1 || skeletonLines_combination.workingSurface.properties.availableSurfaces[0].properties.buildableArea_i < buildings_area_ground) {
        // console.log("AREA GROUND IS RESTRICTED", skeletonLines_combination);
        // Get area that each building has to remove
        // Get exact area of buildings inside each available Surface
        skeletonLines_combination.workingSurface.properties.availableSurfaces.forEach(availableSurface => {
            var buildings_area_ground_surface_totale = 0;
            var buildings_area_ground_surface = [];
            buildings.forEach(building => {
                var intersection = turf.intersect(building.levels[0].polygon, availableSurface);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    buildings_area_ground_surface_totale += intersection_area;
                    buildings_area_ground_surface.push(intersection_area);
                }
            })
            // console.log("buildings_area_ground_surface_totale", buildings_area_ground_surface_totale);
            // console.log("buildings_area_ground_surface", buildings_area_ground_surface);
            // Check if need to reduce area
            if (buildings_area_ground_surface_totale > availableSurface.properties.buildableArea_i) {
                var ground_area_to_remove = buildings_area_ground_surface_totale - availableSurface.properties.buildableArea_i;
                // console.log("NEED TO REDUCE AREA", ground_area_to_remove);
                isReduced = true;
                var isReducing_all = [];
                // Loop on each inside line
                skeletonLines_combination.lines_inside.forEach((line_inside, line_inside_index) => {
                    var current_ground_area_to_remove = buildings_area_ground_surface[line_inside_index] * ground_area_to_remove / buildings_area_ground_surface_totale;
                    // console.log("current_ground_area_to_remove", current_ground_area_to_remove);
                    var points_to_modify = new Array(line_inside.geometry.coordinates.length).fill(null);
                    var max_distance_total = 0;
                    var isReducing = false;
                    line_inside.geometry.coordinates.forEach((coordinate, coordinate_index) => {
                        if (availableSurface.properties.matching_points.includes(coordinate.join(";"))) {
                            var max_distance = null;
                            if (coordinate_index === 0) {
                                max_distance = (turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index + 1])) * 1000) - (buildingWidth / 2) - 2;
                                if (line_inside.geometry.coordinates.length === 2) {
                                    max_distance = ((turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index + 1])) * 1000) / 2) - 2.5;
                                }
                            }
                            else {
                                max_distance = (turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index - 1])) * 1000) - (buildingWidth / 2) - 2;
                                if (line_inside.geometry.coordinates.length === 2) {
                                    max_distance = ((turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index - 1])) * 1000) / 2) - 2.5;
                                }
                            }
                            max_distance_total += max_distance;
                            points_to_modify[coordinate_index] = { max_distance };
                            isReducing = true;
                        }
                    })
                    isReducing_all.push(isReducing);
                    // if (isReducing === false) {
                    //     error = "Closed building can not be reduced";
                    // }
                    // Calculate prorata
                    points_to_modify.forEach((point_to_modify, point_index) => {
                        if (point_to_modify !== null) {
                            var area_to_reduce = point_to_modify.max_distance * current_ground_area_to_remove / max_distance_total;
                            var distance_to_reduce = area_to_reduce / buildingWidth;
                            point_to_modify.area = area_to_reduce;
                            point_to_modify.distance = distance_to_reduce;
                            if (distance_to_reduce > point_to_modify.max_distance) {
                                error = "Too small edges to reduce";
                            }
                            if (line_inside.properties.points_to_modify[point_index] === null || line_inside.properties.points_to_modify[point_index].distance < point_to_modify.distance) {
                                line_inside.properties.points_to_modify[point_index] = point_to_modify;
                            }
                        }
                    })
                    // console.log("points_to_modify", points_to_modify);
                })
                if (!isReducing_all.includes(true)) {
                    error = "No building coulb be reduced";
                }
            }
        })
    }

    // ___ Total area restrictions
    if (skeletonLines_combination.workingSurface.properties.maxBuildableSurfaces.length > 1 || skeletonLines_combination.workingSurface.properties.maxBuildableSurfaces[0].properties.buildableArea < buildings_area_ground) {
        // console.log("AREA GROUND IS RESTRICTED", skeletonLines_combination);
        // Get area that each building has to remove
        // Get exact area of buildings inside each available Surface
        skeletonLines_combination.workingSurface.properties.maxBuildableSurfaces.forEach(maxBuildableSurface => {
            var buildings_area_ground_surface_totale = 0;
            var buildings_area_ground_surface = [];
            buildings.forEach(building => {
                var intersection = turf.intersect(building.levels[0].polygon, maxBuildableSurface);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    buildings_area_ground_surface_totale += intersection_area;
                    buildings_area_ground_surface.push(intersection_area);
                }
            })
            // console.log("buildings_area_ground_surface_totale", buildings_area_ground_surface_totale);
            // console.log("buildings_area_ground_surface", buildings_area_ground_surface);
            // Check if need to reduce area
            if (buildings_area_ground_surface_totale > maxBuildableSurface.properties.buildableArea) {
                var ground_area_to_remove = buildings_area_ground_surface_totale - maxBuildableSurface.properties.buildableArea;
                // console.log("NEED TO REDUCE AREA", ground_area_to_remove);
                isReduced = true;
                var isReducing_all = [];
                // Loop on each inside line
                skeletonLines_combination.lines_inside.forEach((line_inside, line_inside_index) => {
                    var current_ground_area_to_remove = buildings_area_ground_surface[line_inside_index] * ground_area_to_remove / buildings_area_ground_surface_totale;
                    // console.log("current_ground_area_to_remove", current_ground_area_to_remove);
                    var points_to_modify = new Array(line_inside.geometry.coordinates.length).fill(null);
                    var max_distance_total = 0;
                    var isReducing = false;
                    line_inside.geometry.coordinates.forEach((coordinate, coordinate_index) => {
                        if (maxBuildableSurface.properties.matching_points.includes(coordinate.join(";"))) {
                            var max_distance = null;
                            if (coordinate_index === 0) {
                                max_distance = (turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index + 1])) * 1000) - (buildingWidth / 2) - 2;
                                if (line_inside.geometry.coordinates.length === 2) {
                                    max_distance = ((turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index + 1])) * 1000) / 2) - 2.5;
                                }
                            }
                            else {
                                max_distance = (turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index - 1])) * 1000) - (buildingWidth / 2) - 2;
                                if (line_inside.geometry.coordinates.length === 2) {
                                    max_distance = ((turf.distance(turf.point(coordinate), turf.point(line_inside.geometry.coordinates[coordinate_index - 1])) * 1000) / 2) - 2.5;
                                }
                            }
                            max_distance_total += max_distance;
                            points_to_modify[coordinate_index] = { max_distance };
                            isReducing = true;
                        }
                    })
                    isReducing_all.push(isReducing);
                    // if (isReducing === false) {
                    //     error = "Closed building can not be reduced";
                    // }
                    // Calculate prorata
                    points_to_modify.forEach((point_to_modify, point_index) => {
                        if (point_to_modify !== null) {
                            var area_to_reduce = point_to_modify.max_distance * current_ground_area_to_remove / max_distance_total;
                            var distance_to_reduce = area_to_reduce / buildingWidth;
                            point_to_modify.area = area_to_reduce;
                            point_to_modify.distance = distance_to_reduce;
                            if (distance_to_reduce > point_to_modify.max_distance) {
                                error = "Too small edges to reduce";
                            }
                            if (line_inside.properties.points_to_modify[point_index] === null || line_inside.properties.points_to_modify[point_index].distance < point_to_modify.distance) {
                                line_inside.properties.points_to_modify[point_index] = point_to_modify;
                            }
                        }
                    })
                    // console.log("points_to_modify", points_to_modify);
                })
                if (!isReducing_all.includes(true)) {
                    error = "No building coulb be reduced";
                }
            }
        })
    }

    // Reduce buffers area and recreate buildings if isReduced
    if (isReduced === true && error === null) {

        // Get reduced lines inside
        skeletonLines_combination.lines_inside.forEach((line_inside, line_inside_index) => {
            var area_removed = 0;
            line_inside.properties.points_to_modify.forEach((point_to_modify, point_index) => {
                if (point_to_modify !== null) {
                    var point_current = turf.point(line_inside.geometry.coordinates[point_index]);
                    var point_next = null;
                    if (point_index === 0) {
                        point_next = turf.point(line_inside.geometry.coordinates[point_index + 1]);
                    }
                    else {
                        point_next = turf.point(line_inside.geometry.coordinates[point_index - 1]);
                    }
                    var bearing = turf.bearing(point_current, point_next);
                    var destination = turf.destination(point_current, point_to_modify.distance / 1000, bearing);
                    line_inside.geometry.coordinates[point_index] = turf.getCoord(destination);

                    area_removed += point_to_modify.area;
                }
            })
            line_inside.properties.area_max = buffers[line_inside_index].properties.area - area_removed;
        })

        // _____ Get reduced buffers
        buffers_data = get_combi_buffers_from_lines(skeletonLines_combination.lines_inside, buildingWidth, skeletonLines_combination.workingSurface, capacity);
        buffers = buffers_data.buffers;
        buffers_area = buffers_data.buffers_area;

        // _____ Get reduced buildings
        buildings_data = get_combi_buildings_from_buffers(buffers, capacity);
        buildings = buildings_data.buildings;
        buildings_area_total = buildings_data.buildings_area_total;
        buildings_facade_area_total = buildings_data.buildings_facade_area_total;
        buildings_area_ground = buildings_data.buildings_area_ground;

        // _____ Get reduced undergrounds
        underground_data = get_combi_underground_from_buildings(buildings_data, capacity);
        undergrounds = underground_data.undergrounds;
        parkingExt_data = underground_data.parkingExt;

    }

    console.log("underground_data", underground_data);

    return { error, buildings, undergrounds, underground_data, parkingExt_data }
}


const get_combi_buffers_from_lines = (lines, buildingWidth, workingSurface, capacity) => {
    var buffers = [];
    var buffers_area = 0;
    lines.forEach((line, line_index) => {

        var buffer = get_line_buffer_square(line, buildingWidth, workingSurface, capacity);
        if (buffer !== null) {
            buffers.push(buffer);
            buffers_area += buffer.properties.area;
        }

    })

    return { buffers, buffers_area }
}

const get_combi_buildings_from_buffers = (buffers, capacity) => {

    var buildings = [];
    var buildings_area_total = 0;
    var buildings_facade_area_total = 0;
    var buildings_area_ground = 0;

    buffers.forEach(buffer => {

        // Get parameters
        var parameters = get_combi_buffer_parameters(buffer, capacity);
        console.log("PARAMS", parameters);

        // Get levels data
        var levels = get_combi_buffer_levels_data(buffer, parameters, capacity);
        console.log("LEVELS", levels);

        // Get area
        levels.forEach((level, level_index) => {
            parameters.area += level.area;
            parameters.area_real += level.area_real;
            buildings_area_total += level.area_real;
            buildings_facade_area_total += level.facade_area;
            if (level_index === 0) {
                buildings_area_ground += level.area_real;
            }
            parameters.facade_area += level.facade_area;
        })

        buildings.push({ parameters, levels });

    })

    return { buildings, buildings_area_total, buildings_facade_area_total, buildings_area_ground }
}

const get_combi_buffer_parameters = (buffer, capacity) => {

    var parameters = {};

    parameters.width = capacity?.buildable?.volume?.parameters?.max_building_width;

    parameters.height_facade = capacity?.buildable?.volume?.parameters?.max_height_facade;
    if (capacity?.buildable?.volume?.parameters?.max_height_facade_zones && capacity?.buildable?.volume?.parameters?.max_height_facade_zones.length > 1) {
        for (var i = 0; i < capacity?.buildable?.volume?.parameters?.max_height_facade_zones.length; i++) {
            var intersection = turf.intersect(buffer, capacity?.buildable?.volume?.parameters?.max_height_facade_zones[i].perimeter);
            if (intersection !== null) {
                var intersection_area = turf.area(intersection);
                if (intersection_area >= parameters.width * parameters.width / 2) {
                    parameters.height_facade = capacity?.buildable?.volume?.parameters?.max_height_facade_zones[i].height;
                    break;
                }
            }
        }
    }
    parameters.height = capacity?.buildable?.volume?.parameters?.max_height;
    parameters.level_height = capacity?.buildable?.volume?.parameters?.min_height_level;
    parameters.top_level_offset = capacity?.buildable?.volume?.parameters?.min_top_levels_offset;

    parameters.levels = get_combi_buffer_levels(parameters, capacity);

    parameters.area = 0;
    parameters.area_real = 0;
    parameters.facade_area = 0;


    return parameters

}

const get_combi_buffer_levels = (parameters, capacity) => {

    var footprint_levels = [];
    var levels = capacity?.buildable?.volume?.levels;
    var levels_all = capacity?.buildable?.volume?.levels_all;

    // // If building height facade = global height facade => no need to calculate, take global levels
    // if (parameters.height_facade === capacity?.buildable?.volume?.parameters?.max_height_facade) {
    //     footprint_levels = [...levels];
    // }
    // // If not => calulate custom levels for building
    // else {

    var target_elevations = [0.01];
    var height_level_ground = capacity?.buildable?.volume?.parameters?.min_height_level?.ground || capacity?.buildable?.volume?.parameters?.levelHeight;
    var height_level_current = capacity?.buildable?.volume?.parameters?.min_height_level?.current || capacity?.buildable?.volume?.parameters?.levelHeight;
    var height_level_top = capacity?.buildable?.volume?.parameters?.min_height_level?.top || capacity?.buildable?.volume?.parameters?.levelHeight;
    var global_height = height_level_ground;
    var height_above_facade = 0;
    if (capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level > 0) {
        height_above_facade += height_level_top;
        height_above_facade += height_level_current * (capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level - 1);
    }
    console.log("height_above_facade", height_above_facade);
    var height_max = Math.min(parameters.height, parameters.height_facade + height_above_facade);


    // Get nb current levels (from height facade minus ground and top height)
    var nb_current_levels = Math.floor((height_max - height_level_ground - height_level_top) / height_level_current);
    console.log("nb_current_levels", nb_current_levels);
    if (nb_current_levels > 0) {
        for (var i = 0; i < nb_current_levels; i++) {
            target_elevations.push(parseFloat(global_height.toFixed(2)));
            global_height += height_level_current;
        }
    }
    // Add top level
    if (global_height + height_level_top <= height_max) {
        target_elevations.push(parseFloat(global_height.toFixed(2)));
        global_height += height_level_top;
    }
    // Add final top elevation
    target_elevations.push(parseFloat(global_height.toFixed(2)));
    console.log("target_elevations", target_elevations);

    // Creat building levels
    target_elevations.forEach(target_elevation => {
        for (var i = 0; i < levels_all.length; i++) {
            if (levels_all[i].elevation === target_elevation) {
                footprint_levels.push({ ...levels_all[i] });
            }
        }
    })




    // var top_level_height = capacity?.buildable?.volume?.parameters?.levelHeight;
    // if (capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) {
    //     top_level_height = capacity?.buildable?.volume?.parameters?.min_height_level?.top;
    // }
    // else if (capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0) {
    //     top_level_height = capacity?.buildable?.volume?.parameters?.min_height_level?.current;
    // }
    // var top_level_elevation = parameters.height_facade - top_level_height;
    // var top_level_elevation_real = 0;
    // var under_top = true;
    // for (var level_i = 0; level_i < levels.length; level_i++) {
    //     if (under_top === true && levels[level_i].elevation <= top_level_elevation) {
    //         footprint_levels.push({ ...levels[level_i] });
    //         top_level_elevation_real = levels[level_i].elevation;
    //     }
    //     else {
    //         if (under_top === true && levels[level_i].elevation > top_level_elevation) {
    //             under_top = false;
    //             break
    //         }
    //         // if (under_top === false && levels[level_i].elevation >= (top_level_elevation_real + top_level_height)) {
    //         //     var modified_level = { ...levels[level_i] };
    //         //     modified_level.elevation = top_level_elevation_real + top_level_height;
    //         //     footprint_levels.push(modified_level);
    //         //     break;
    //         // }
    //     }
    // }
    // var under_top = true;
    // for (var level_i = 0; level_i < levels_all.length; level_i++) {
    //     if (under_top === true && levels_all[level_i].elevation > top_level_elevation) {
    //         under_top = false;
    //     }
    //     if (under_top === false && levels_all[level_i].elevation >= (top_level_elevation_real + top_level_height)) {
    //         var modified_level = { ...levels_all[level_i] };
    //         modified_level.elevation = top_level_elevation_real + top_level_height;
    //         footprint_levels.push(modified_level);
    //         break;
    //     }
    // }

    // }

    return footprint_levels

}

const get_combi_buffer_levels_data = (buffer, parameters, capacity) => {

    var levels = [];

    for (var i = 1; i < parameters.levels.length; i++) {

        var polygon = null;

        // Check if necessary to compute
        // if (i > 1 && Math.round(parameters.levels[i].maxBuildableArea) === Math.round(parameters.levels[i - 1].maxBuildableArea)) {
        //     polygon = levels[levels.length - 1].polygon;
        //     console.log("NO COMPUTATION");
        // }
        // else {
        for (var j = 0; j < parameters.levels[i].workingSurfaces.length; j++) {
            var intersection = turf.intersect(buffer, parameters.levels[i].workingSurfaces[j]);
            if (intersection !== null) {
                if (polygon === null) {
                    polygon = intersection;
                }
                else {
                    polygon = turf.union(polygon, intersection);
                }
            }
        }
        // }

        if (polygon !== null) {
            var area_real = turf.area(polygon);
            var area = buffer?.properties?.area_max || area_real;
            if (area_real <= area * 0.9) { area = area_real }
            levels.push({
                polygon,
                area: area,
                area_real: area_real,
                elevations: [parameters.levels[i - 1].elevation === 0.01 ? 0 : parameters.levels[i - 1].elevation, parameters.levels[i].elevation],
            })
        }


    }


    // Top level offset && max height facade
    if (parameters?.top_level_offset?.offset > 0 && parameters?.top_level_offset?.nb_level > 0) {
        var offset = Math.min(((parameters.width / 2) - 2), parameters?.top_level_offset?.offset);
        console.log("offset", offset);
        var nb_level_i = 0;
        if (levels.length > parameters?.top_level_offset?.nb_level) {
            var poly_prev = levels[levels.length - parameters?.top_level_offset?.nb_level - 1].polygon;
            var poly_prev_offset = turf.buffer(poly_prev, - (offset / 1000), { steps: 1 });
            for (var i = levels.length - 1; i > 0; i--) {
                nb_level_i++;
                if (nb_level_i > parameters?.top_level_offset?.nb_level) { break }
                var poly_current = levels[i].polygon;
                var poly_intersect = turf.intersect(poly_current, poly_prev_offset);
                if (poly_intersect === null) {

                }
                else {
                    levels[i].polygon = poly_intersect;
                    var area = turf.area(poly_intersect);
                    levels[i].area = area;
                    levels[i].area_real = area;
                }
            }
        }
    }
    if (capacity?.buildable?.volume?.parameters?.max_height_facade_zones && capacity?.buildable?.volume?.parameters?.max_height_facade_zones.length > 1) {
        for (var j = 0; j < capacity?.buildable?.volume?.parameters?.max_height_facade_zones.length; j++) {
            var nb_levels_to_clip = null;
            for (var i = 0; i < levels.length; i++) {
                if (levels[i].elevations[1] > capacity?.buildable?.volume?.parameters?.max_height_facade_zones[j].height) {
                    // First levels to be cliped => get nb levels to clip in total
                    if (nb_levels_to_clip === null) {
                        nb_levels_to_clip = levels.length - i;
                    }
                    // Clip level if not attique
                    if (nb_levels_to_clip !== null && nb_levels_to_clip > capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level) {
                        var poly_diff = turf.difference(levels[i].polygon, capacity?.buildable?.volume?.parameters?.max_height_facade_zones[j].perimeter);
                        if (poly_diff !== null) {
                            levels[i].polygon = poly_diff;
                            var area = turf.area(poly_diff);
                            levels[i].area = area;
                            levels[i].area_real = area;
                        }
                    }
                }
            }
        }
    }

    // Check levels polygon area are bigger than building width * building with / 2
    var levels_curated = [];
    for (var i = 0; i < levels.length; i++) {
        if (levels[i]?.polygon?.geometry?.type === "Polygon" && levels[i]?.area >= (capacity?.buildable?.volume?.parameters?.max_building_width * capacity?.buildable?.volume?.parameters?.max_building_width / 2)) {
            var level_current = levels[i];
            level_current.facade_area = turf.length(turf.polygonToLine(levels[i].polygon)) * 1000 * (levels[i].elevations[1] - levels[i].elevations[0]);
            levels_curated.push(level_current);
        }

        else if (levels[i]?.polygon?.geometry?.type === "MultiPolygon" && levels[i]?.area >= (capacity?.buildable?.volume?.parameters?.max_building_width * capacity?.buildable?.volume?.parameters?.max_building_width / 2)) {
            var poly_coords = [];
            var poly_area_real = 0;

            levels[i]?.polygon?.geometry?.coordinates.forEach(coords => {
                var poly = turf.polygon(coords);
                var poly_area = turf.area(poly);
                if (poly_area >= (capacity?.buildable?.volume?.parameters?.max_building_width * capacity?.buildable?.volume?.parameters?.max_building_width / 2)) {
                    poly_coords.push(coords);
                    poly_area_real += poly_area;
                }
            })

            var poly_area_full = Math.min(levels[i].area, poly_area_real);


            if (poly_coords.length === 1) {
                var polygon = turf.polygon(poly_coords[0]);
                levels_curated.push({
                    polygon,
                    area: poly_area_full,
                    area_real: poly_area_real,
                    facade_area: turf.length(turf.polygonToLine(polygon)) * 1000 * (levels[i].elevations[1] - levels[i].elevations[0]),
                    elevations: levels[i].elevations,
                })
            }
            else if (poly_coords.length > 1) {
                var polygon = turf.multiPolygon(poly_coords);
                levels_curated.push({
                    polygon,
                    area: poly_area_full,
                    area_real: poly_area_real,
                    facade_area: turf.length(turf.polygonToLine(polygon)) * 1000 * (levels[i].elevations[1] - levels[i].elevations[0]),
                    elevations: levels[i].elevations,
                })
            }

        }
    }



    return levels_curated

}

const get_combi_underground_from_buildings = (buildings_data, capacity) => {

    // Get parking area needed to be created
    var parking_area_needed = 0;
    if (capacity?.buildable?.volume?.parameters?.parking?.type === "value") {
        parking_area_needed = capacity?.buildable?.volume?.parameters?.parking?.value;
    }
    else if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio_area") {
        parking_area_needed = capacity?.buildable?.volume?.parameters?.parking?.value * buildings_data.buildings_area_total;
    }
    else if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio_spot") {
        parking_area_needed = capacity?.buildable?.volume?.parameters?.parking?.value * Math.max(capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_sub, capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_ext) * buildings_data.buildings_area_total;
    }

    // Get free area in surface for ext parking
    var land_area_available_for_parking = 0;
    var land_area_available_for_parking_no_building = 0;
    if (capacity?.buildable?.volume?.parameters?.parking?.type_ext === true) {
        // Loop on each buildable infra zone
        capacity?.buildable?.volume?.parameters?.buildable_infra_zones.forEach(buildable_zone => {
            var zone_free_area = Math.min(buildable_zone?.buildableArea, buildable_zone?.area);
            if (zone_free_area >= capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_ext) {
                land_area_available_for_parking += zone_free_area;
                land_area_available_for_parking_no_building += zone_free_area;
            }
        })
        // Substract building ground area
        land_area_available_for_parking = Math.max(0, land_area_available_for_parking - buildings_data.buildings_area_ground);
        // Mutiply by ratio to take into account not filling 100% of surface with parking slot because of shape or accessibilty
        land_area_available_for_parking = land_area_available_for_parking * capacity?.buildable?.volume?.parameters?.parking?.land_area_usage_ratio;
    }
    console.log("parking_area_needed", parking_area_needed);
    console.log("land_area_available_for_parking", land_area_available_for_parking);
    console.log("buildings_data", buildings_data);
    console.log("ratio", capacity?.buildable?.volume?.parameters?.parking?.land_area_usage_ratio);


    // CREATE UNDERGROUND PARKINGS

    var undergrounds = [];
    var undergrounds_area_total = 0;
    var undergrounds_nb_spot_total = 0;
    var undergrounds_area_delta = 0;

    if (parking_area_needed > 0 && parking_area_needed > land_area_available_for_parking && capacity?.buildable?.volume?.parameters?.parking?.type_sub === true && (capacity?.buildable?.volume?.parameters?.max_nb_level_infra === null || capacity?.buildable?.volume?.parameters?.max_nb_level_infra > 0)) {

        // Get min area of parking to create
        var min_area_parking = parking_area_needed - land_area_available_for_parking;
        console.log("min_area_parking to create underground", min_area_parking);

        if (min_area_parking > 0) {

            var ids_buildings = [...Array(buildings_data.buildings.length).keys()];

            var ids_unmatch = [];

            var ids_list = [];
            ids_buildings.forEach(id => {
                // ids_list.push(id.toString());
                ids_list.push(id.toString() + "c");
                ids_unmatch.push([id.toString(), id.toString() + "c"]);
            })

            if (buildings_data.buildings.length > 1) {
                var mix_list = [];
                for (var i = 2; i <= ids_buildings.length; i++) {
                    mix_list = mix_list.concat(helpers.k_combinations(ids_buildings, i));
                }
                // console.log("mix_list", mix_list);
                mix_list.forEach(mix => {
                    ids_list.push(mix.join("-") + "c");
                    mix.forEach(mix_item => {
                        ids_unmatch.push([mix_item.toString(), mix.join("-") + "c"]);
                        ids_unmatch.push([mix_item.toString() + "c", mix.join("-") + "c"]);
                    })
                })
            }

            // console.log("ids_unmatch", ids_unmatch);

            var ids_list_data = {};
            ids_list.forEach(id => {
                var data = {
                    ids: [],
                    convex: false,
                    area: 0,
                    polygon: null
                }
                // Get ids and convex
                if (id.includes("-")) {
                    data.convex = true;
                    data.ids = id.slice(0, -1).split("-");
                    data.ids = data.ids.map(el => parseInt(el));
                }
                else if (id.includes("c")) {
                    data.convex = true;
                    data.ids = [parseInt(id.slice(0, -1))];
                }
                else {
                    // data.ids = [parseInt(id)];
                }
                // Get polygon
                data.polygon = buildings_data.buildings[data.ids[0]].levels[0].polygon;
                if (data.ids.length > 1) {
                    for (var i = 1; i < data.ids.length; i++) {
                        data.polygon = turf.union(data.polygon, buildings_data.buildings[data.ids[i]].levels[0].polygon);
                    }
                }
                if (data.polygon?.geometry?.type === "Polygon" || data.polygon?.geometry?.type === "MultiPolygon") {
                    if (data.convex === true) {
                        data.polygon = turf.convex(data.polygon);
                    }
                    data.polygon = turf.intersect(data.polygon, capacity?.landBase?.union?.geometry);
                    // Get area
                    data.area = turf.area(data.polygon);
                }

                ids_list_data[id] = data;
            })
            // console.log("ids_list_data", ids_list_data);


            var undergrounds_all_combi_ids = get_undergound_all_combinations(ids_list, ids_unmatch);
            // console.log("undergrounds_all_combi_ids", undergrounds_all_combi_ids);

            // Get best solution for each combination
            var max_nb_infra = capacity?.buildable?.volume?.parameters?.max_nb_level_infra || 4;
            var undergrounds_all_combi_data = [];
            var best_combi_data = { weight: Infinity };
            var best_combi_data_neg = { weight: Infinity };
            undergrounds_all_combi_ids.forEach(combi => {
                var area_niv = 0;
                combi.forEach(id => {
                    area_niv += ids_list_data[id].area;
                })
                var nb_niv = Math.min(Math.ceil(min_area_parking / area_niv), max_nb_infra);
                var weight = ((area_niv * nb_niv) - min_area_parking) * nb_niv;
                undergrounds_all_combi_data.push({
                    ids: combi,
                    area_niv: area_niv,
                    area_total: area_niv * nb_niv,
                    area_delta: (area_niv * nb_niv) - min_area_parking,
                    weight: weight,
                    nb_niv: nb_niv,
                })
                if (weight >= 0 && weight < best_combi_data.weight) {
                    best_combi_data = {
                        ids: combi,
                        area_niv: area_niv,
                        area_total: area_niv * nb_niv,
                        area_delta: (area_niv * nb_niv) - min_area_parking,
                        weight: weight,
                        nb_niv: nb_niv,
                    }
                }
                if (weight < best_combi_data.weight) {
                    best_combi_data = {
                        ids: combi,
                        area_niv: area_niv,
                        area_total: area_niv * nb_niv,
                        area_delta: (area_niv * nb_niv) - min_area_parking,
                        weight: weight,
                        nb_niv: nb_niv,
                    }
                }
            })
            if (best_combi_data.weight === Infinity) { best_combi_data = best_combi_data_neg };
            console.log("undergrounds_all_combi_data", undergrounds_all_combi_data);
            // console.log("best_combi_data", best_combi_data);

            // Create undergrounds
            var best_combi_polygon = ids_list_data[best_combi_data.ids[0]].polygon;
            if (best_combi_data.ids.length > 1) {
                for (var i = 1; i < best_combi_data.ids.length; i++) {
                    best_combi_polygon = turf.union(best_combi_polygon, ids_list_data[best_combi_data.ids[i]].polygon);
                }
            }

            var infra_level_height = capacity?.buildable?.volume?.parameters?.min_height_level?.sublevels || 3;
            var last_elevation = 0;
            var best_combi_levels = [];
            for (var i = 0; i < best_combi_data.nb_niv; i++) {
                best_combi_levels.push({
                    area: best_combi_data.area_niv,
                    nb_spot: Math.floor(best_combi_data.area_niv / capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_sub),
                    elevations: [last_elevation, last_elevation - infra_level_height],
                    polygon: best_combi_polygon
                })
                last_elevation -= infra_level_height;
            }

            undergrounds = [{
                parameters: {
                    area_niv: best_combi_data.area_niv,
                    area_total: best_combi_data.area_niv * best_combi_data.nb_niv,
                    area_delta: (best_combi_data.area_niv * best_combi_data.nb_niv) - min_area_parking,
                    nb_niv: best_combi_data.nb_niv,
                    polygon: best_combi_polygon,
                    nb_spot: best_combi_data.nb_niv * Math.floor(best_combi_data.area_niv / capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_sub),
                },
                levels: best_combi_levels
            }]
            console.log("undergrounds", undergrounds);

            undergrounds_area_total += undergrounds[0].parameters.area_total;

            undergrounds_nb_spot_total += undergrounds[0].parameters.nb_spot;

            undergrounds_area_delta += undergrounds[0].parameters.area_delta;

        }

    }

    // CREATE EXT PARKINGS
    var extParking_area = 0;
    var extParking_nb_spot = 0;
    var extParking_reduce = {
        area_total: 0,
        area_ground: 0
    }

    if (parking_area_needed > 0 && capacity?.buildable?.volume?.parameters?.parking?.type_ext === true) {

        // Get area parking already existing in undergrounds (calculated from nb spots)
        // var undergrounds_area_total_spot = undergrounds_nb_spot_total * capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_sub;
        var undergrounds_area_total_spot = undergrounds_area_total;
        console.log("undergrounds_area_total_spot", undergrounds_area_total_spot);

        // Calculate parking area and nb spot to create in ext
        extParking_area = Math.max(parking_area_needed - undergrounds_area_total_spot, 0);
        if (extParking_area > 0) {
            extParking_nb_spot = Math.floor(extParking_area / capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_ext);
        }
        console.log("extParking_area", extParking_area);

        // Check if there is enought space in ext to create all parking needed
        if (extParking_area > land_area_available_for_parking) {
            console.log("!!! TOO MANY EXT PARKING NEEDED");
            var ratio_global = null;
            if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio_area") {
                ratio_global = capacity?.buildable?.volume?.parameters?.parking?.value;
            }
            else if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio_spot") {
                ratio_global = capacity?.buildable?.volume?.parameters?.parking?.value * Math.max(capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_sub, capacity?.buildable?.volume?.parameters?.parking?.avg_area_spot_ext);
            }
            if (ratio_global !== null) {
                // Calculate nb_niv moy
                var nb_niv_numerator = 0;
                buildings_data.buildings.forEach(building => {
                    nb_niv_numerator += building.levels.length * building.parameters.area;
                })
                var nb_niv = nb_niv_numerator / buildings_data.buildings_area_total;
                // Calculate nb sub moy
                var nb_sub_numerator = 0;
                undergrounds.forEach(ug => {
                    nb_sub_numerator += ug.levels.length * ug.parameters.area_total;
                })
                var nb_sub = nb_sub_numerator / undergrounds_area_total;
                if (isNaN(nb_sub)) { nb_sub = 0 }
                var R3 = (undergrounds_area_total / nb_sub) / buildings_data.buildings_area_ground;
                if (isNaN(R3)) { R3 = 0 }
                console.log("Spk land", land_area_available_for_parking_no_building);
                console.log("R1", ratio_global);
                console.log("nb_niv", nb_niv);
                console.log("R2", capacity?.buildable?.volume?.parameters?.parking?.land_area_usage_ratio);
                console.log("nb_sub", nb_sub);
                console.log("R3", R3);
                var optimal_building_ground_area_old = land_area_available_for_parking_no_building / ((ratio_global * nb_niv / capacity?.buildable?.volume?.parameters?.parking?.land_area_usage_ratio) + 1);
                var optimal_building_ground_area = (capacity?.buildable?.volume?.parameters?.parking?.land_area_usage_ratio * land_area_available_for_parking_no_building) / ((ratio_global * nb_niv) + capacity?.buildable?.volume?.parameters?.parking?.land_area_usage_ratio - (R3 * nb_sub));
                console.log("optimal_building_ground_area", optimal_building_ground_area);
                console.log("optimal_building_ground_area_old", optimal_building_ground_area_old);
                extParking_reduce.area_ground = buildings_data.buildings_area_ground - optimal_building_ground_area;
            }
            else {
                extParking_reduce.area_ground = extParking_area - land_area_available_for_parking;
            }
        }

    }



    return { undergrounds, undergrounds_area_total, undergrounds_nb_spot_total, undergrounds_area_delta, parkingExt: { extParking_area, extParking_nb_spot, extParking_reduce } }
}

const get_undergound_all_combinations = (buildings_list, unmatch_list) => {

    var all_combinations = [];
    for (var i = 1; i <= buildings_list.length; i++) {
        // Get combinations
        var current_combinations = helpers.k_combinations(buildings_list, i);
        // console.log("current_combinations", current_combinations);

        var current_combinations_filtered = [];
        for (var j = 0; j < current_combinations.length; j++) {
            var test = true;
            if (current_combinations[j].length > 1) {
                for (var k = 0; k < unmatch_list.length; k++) {
                    var current_test = unmatch_list[k].every((el) => {
                        return current_combinations[j].includes(el)
                    })
                    // console.log("current_test", current_combinations[j], unmatch_list[k], current_test);
                    if (current_test === true) {
                        test = false;
                        break;
                    }
                }
            }
            if (test === true) {
                current_combinations_filtered.push(current_combinations[j])
            }
        }

        // Concat
        all_combinations = all_combinations.concat(current_combinations_filtered);
    }

    return all_combinations
}

const get_combi_offset_buffer = (buildings, offset_main) => {

    // Create all buffers
    var offset_buffers = [];
    buildings.forEach(building => {
        if (building?.levels && building.levels.length > 0 && building.levels[0]?.polygon?.geometry?.type === "Polygon") {
            offset_buffers.push(turf.buffer(building.levels[0]?.polygon, (offset_main - 0.1) / 1000, { steps: 1 }));
        }
    })

    return offset_buffers

}