const sru_data = {
    code: [
        '01031',
        '01043',
        '01053',
        '01142',
        '01160',
        '01185',
        '01194',
        '01249',
        '01262',
        '01281',
        '01283',
        '01289',
        '01313',
        '01322',
        '01344',
        '01354',
        '01376',
        '01419',
        '01427',
        '01451',
        '02064',
        '02168',
        '02340',
        '02691',
        '02722',
        '03013',
        '03023',
        '03095',
        '03098',
        '03101',
        '03185',
        '03190',
        '03236',
        '03310',
        '03321',
        '04112',
        '04143',
        '04152',
        '04242',
        '05061',
        '06004',
        '06011',
        '06012',
        '06018',
        '06027',
        '06029',
        '06030',
        '06032',
        '06033',
        '06038',
        '06044',
        '06048',
        '06054',
        '06064',
        '06065',
        '06069',
        '06075',
        '06079',
        '06083',
        '06084',
        '06085',
        '06088',
        '06090',
        '06095',
        '06104',
        '06105',
        '06108',
        '06112',
        '06114',
        '06118',
        '06122',
        '06123',
        '06130',
        '06136',
        '06147',
        '06148',
        '06149',
        '06152',
        '06155',
        '06157',
        '06159',
        '06161',
        '07102',
        '07281',
        '07319',
        '08105',
        '08328',
        '08409',
        '08480',
        '08491',
        '10081',
        '10297',
        '10321',
        '10325',
        '10333',
        '10343',
        '10362',
        '10387',
        '11069',
        '11106',
        '11116',
        '11145',
        '11170',
        '11202',
        '11262',
        '11266',
        '11379',
        '11397',
        '11429',
        '12133',
        '12176',
        '12202',
        '13001',
        '13002',
        '13004',
        '13005',
        '13007',
        '13010',
        '13014',
        '13015',
        '13016',
        '13018',
        '13019',
        '13021',
        '13022',
        '13023',
        '13026',
        '13027',
        '13028',
        '13030',
        '13031',
        '13032',
        '13033',
        '13035',
        '13036',
        '13037',
        '13039',
        '13040',
        '13041',
        '13042',
        '13043',
        '13044',
        '13045',
        '13046',
        '13047',
        '13048',
        '13050',
        '13051',
        '13053',
        '13054',
        '13055',
        '13056',
        '13059',
        '13060',
        '13062',
        '13063',
        '13066',
        '13069',
        '13070',
        '13071',
        '13072',
        '13073',
        '13074',
        '13075',
        '14366',
        '13077',
        '13078',
        '13080',
        '13081',
        '13082',
        '13083',
        '13084',
        '13085',
        '13086',
        '13087',
        '13088',
        '13091',
        '13092',
        '13097',
        '13098',
        '47001',
        '13103',
        '13104',
        '13105',
        '13106',
        '13107',
        '13108',
        '13110',
        '13112',
        '13113',
        '13114',
        '13117',
        '13118',
        '13119',
        '14068',
        '14076',
        '14098',
        '14101',
        '14118',
        '14167',
        '14181',
        '14271',
        '14301',
        '14327',
        '14341',
        '59002',
        '14371',
        '14431',
        '14437',
        '14488',
        '14654',
        '14738',
        '15012',
        '15014',
        '15267',
        '16015',
        '16061',
        '16078',
        '16089',
        '16090',
        '16102',
        '16113',
        '16138',
        '16154',
        '16166',
        '16167',
        '16287',
        '16291',
        '16358',
        '16374',
        '17010',
        '17021',
        '17028',
        '17086',
        '17094',
        '17142',
        '17146',
        '17168',
        '17200',
        '17264',
        '17274',
        '17291',
        '17299',
        '17300',
        '17306',
        '17333',
        '17380',
        '17407',
        '17414',
        '17415',
        '17421',
        '17449',
        '17452',
        '17461',
        '18033',
        '18050',
        '18141',
        '18205',
        '18213',
        '18267',
        '19005',
        '19031',
        '19123',
        '19153',
        '19229',
        '19274',
        '21054',
        '21166',
        '21171',
        '21231',
        '21278',
        '21355',
        '21390',
        '21515',
        '21540',
        '21617',
        '22054',
        '22055',
        '22081',
        '22093',
        '22106',
        '22113',
        '22168',
        '22171',
        '22176',
        '22186',
        '22187',
        '22194',
        '22198',
        '22203',
        '22211',
        '22215',
        '22251',
        '22258',
        '22278',
        '22343',
        '22360',
        '22389',
        '24026',
        '24037',
        '24053',
        '24102',
        '24138',
        '24312',
        '24322',
        '24340',
        '24557',
        '25031',
        '25048',
        '25056',
        '25057',
        '25228',
        '25284',
        '25304',
        '25367',
        '25388',
        '25463',
        '25527',
        '25539',
        '25547',
        '25580',
        '26037',
        '26057',
        '26058',
        '26064',
        '26084',
        '26088',
        '26124',
        '26197',
        '26198',
        '26252',
        '26281',
        '26313',
        '26362',
        '27016',
        '27022',
        '27090',
        '27105',
        '27213',
        '27229',
        '27230',
        '27275',
        '27299',
        '27375',
        '27448',
        '27469',
        '27507',
        '27528',
        '27562',
        '27602',
        '27681',
        '27701',
        '28070',
        '28085',
        '28110',
        '28134',
        '28209',
        '28218',
        '28220',
        '28227',
        '28229',
        '28348',
        '28359',
        '28404',
        '29011',
        '29019',
        '29020',
        '29039',
        '29051',
        '29061',
        '29069',
        '29075',
        '29170',
        '29189',
        '29212',
        '29216',
        '29232',
        '29235',
        '29241',
        '29293',
        '2A004',
        '59008',
        '2B033',
        '2B037',
        '2B120',
        '30007',
        '30011',
        '30028',
        '30047',
        '30060',
        '30075',
        '30082',
        '30123',
        '30125',
        '30128',
        '30132',
        '30141',
        '30155',
        '30156',
        '30169',
        '30189',
        '30202',
        '30206',
        '30209',
        '30211',
        '30217',
        '30221',
        '30223',
        '30243',
        '30258',
        '30259',
        '30284',
        '30294',
        '30305',
        '30333',
        '30351',
        '31022',
        '31032',
        '31035',
        '31044',
        '31056',
        '31069',
        '31079',
        '31091',
        '31113',
        '31116',
        '31117',
        '31118',
        '31149',
        '31150',
        '31157',
        '31165',
        '31169',
        '31182',
        '31187',
        '31188',
        '31203',
        '31230',
        '31248',
        '31254',
        '31282',
        '31291',
        '31351',
        '31389',
        '31395',
        '31410',
        '31417',
        '31421',
        '31424',
        '31433',
        '31445',
        '31446',
        '31458',
        '31460',
        '31467',
        '31488',
        '31490',
        '31499',
        '31506',
        '31526',
        '31547',
        '31555',
        '31557',
        '31561',
        '31588',
        '33003',
        '33009',
        '33013',
        '33018',
        '33032',
        '33039',
        '33056',
        '33063',
        '33065',
        '33069',
        '33075',
        '33080',
        '33090',
        '33096',
        '33099',
        '33119',
        '33122',
        '33138',
        '33162',
        '33167',
        '33192',
        '33199',
        '33200',
        '33207',
        '33234',
        '33238',
        '33243',
        '33249',
        '33273',
        '33281',
        '33312',
        '33318',
        '33322',
        '33324',
        '33363',
        '33366',
        '33376',
        '33393',
        '33397',
        '33422',
        '33433',
        '33449',
        '33483',
        '33519',
        '33522',
        '33527',
        '33529',
        '33535',
        '33539',
        '33550',
        '34003',
        '34022',
        '34023',
        '34031',
        '34032',
        '34057',
        '34058',
        '34077',
        '59009',
        '34088',
        '34090',
        '34095',
        '34101',
        '34108',
        '34113',
        '34116',
        '34120',
        '34123',
        '34129',
        '34145',
        '34146',
        '34148',
        '34150',
        '34151',
        '34157',
        '34162',
        '34169',
        '34172',
        '34198',
        '34199',
        '34202',
        '34213',
        '34217',
        '34247',
        '34255',
        '34259',
        '34270',
        '34298',
        '34299',
        '34300',
        '34301',
        '34324',
        '34327',
        '34332',
        '34336',
        '34337',
        '34341',
        '35001',
        '35006',
        '35024',
        '35032',
        '35047',
        '35049',
        '35051',
        '35055',
        '35059',
        '35066',
        '35068',
        '35076',
        '59014',
        '35115',
        '35120',
        '35125',
        '35131',
        '35139',
        '35179',
        '35189',
        '35196',
        '35206',
        '35208',
        '35210',
        '35238',
        '35240',
        '35245',
        '35275',
        '35278',
        '35281',
        '35288',
        '35299',
        '35334',
        '35352',
        '35353',
        '35360',
        '35363',
        '36005',
        '36044',
        '36063',
        '36159',
        '36202',
        '37003',
        '37018',
        '37027',
        '37050',
        '37054',
        '37109',
        '37122',
        '37139',
        '37154',
        '37156',
        '37163',
        '37172',
        '37195',
        '37208',
        '37214',
        '37233',
        '37261',
        '37266',
        '37273',
        '38053',
        '38087',
        '38111',
        '38126',
        '38133',
        '38150',
        '38151',
        '38157',
        '38158',
        '38169',
        '38179',
        '38185',
        '38193',
        '38200',
        '38229',
        '38239',
        '38249',
        '38298',
        '38317',
        '38318',
        '38337',
        '38344',
        '38348',
        '38378',
        '38382',
        '38397',
        '38400',
        '38421',
        '38423',
        '38425',
        '38449',
        '38455',
        '38468',
        '38474',
        '38485',
        '38486',
        '38516',
        '38517',
        '38524',
        '38529',
        '38537',
        '38538',
        '38544',
        '38545',
        '38547',
        '38553',
        '38562',
        '38563',
        '38565',
        '39198',
        '39526',
        '40088',
        '40192',
        '40273',
        '40279',
        '40281',
        '40312',
        '41018',
        '41047',
        '41149',
        '41269',
        '41295',
        '42005',
        '42022',
        '42044',
        '42071',
        '42095',
        '42097',
        '42099',
        '42103',
        '42110',
        '42123',
        '42127',
        '42147',
        '42183',
        '42184',
        '42186',
        '42187',
        '42189',
        '42207',
        '42218',
        '42222',
        '42223',
        '42225',
        '42234',
        '42237',
        '42256',
        '42259',
        '42271',
        '42275',
        '42279',
        '42285',
        '42302',
        '42304',
        '42305',
        '42316',
        '42323',
        '42330',
        '42332',
        '43012',
        '43041',
        '43089',
        '43157',
        '43190',
        '44005',
        '44009',
        '44018',
        '44020',
        '44021',
        '44026',
        '44030',
        '44035',
        '44047',
        '44049',
        '44052',
        '44055',
        '44069',
        '44071',
        '44072',
        '44074',
        '44101',
        '44103',
        '44109',
        '44114',
        '44120',
        '44126',
        '44130',
        '44131',
        '44132',
        '44135',
        '44143',
        '44150',
        '44151',
        '44154',
        '44162',
        '44166',
        '44168',
        '44172',
        '44175',
        '44182',
        '44184',
        '44186',
        '44190',
        '44194',
        '44198',
        '44204',
        '44210',
        '44211',
        '44215',
        '45075',
        '45089',
        '45147',
        '45169',
        '45232',
        '45234',
        '45235',
        '45274',
        '45284',
        '45285',
        '45286',
        '45298',
        '45302',
        '59017',
        '47031',
        '47032',
        '47100',
        '47145',
        '47157',
        '47201',
        '47209',
        '47310',
        '49007',
        '49015',
        '49020',
        '49023',
        '49035',
        '49069',
        '49092',
        '49099',
        '49125',
        '49129',
        '49167',
        '49180',
        '49193',
        '49200',
        '49214',
        '49215',
        '49218',
        '49223',
        '49244',
        '49246',
        '49261',
        '49267',
        '49278',
        '49298',
        '49301',
        '49307',
        '49323',
        '49328',
        '49332',
        '49353',
        '49373',
        '49377',
        '50002',
        '50041',
        '50082',
        '50129',
        '50139',
        '50502',
        '50601',
        '50615',
        '51055',
        '51058',
        '51108',
        '51172',
        '51242',
        '51250',
        '51388',
        '51454',
        '51506',
        '51573',
        '51662',
        '52448',
        '53034',
        '53054',
        '53119',
        '53130',
        '53140',
        '53201',
        '54090',
        '54115',
        '54184',
        '54215',
        '54257',
        '59024',
        '59028',
        '54274',
        '54300',
        '54304',
        '59032',
        '54318',
        '59033',
        '54328',
        '54339',
        '54357',
        '59051',
        '54395',
        '54430',
        '54439',
        '59079',
        '54482',
        '54495',
        '54498',
        '54526',
        '54547',
        '54578',
        '56003',
        '56008',
        '56036',
        '56053',
        '56067',
        '56078',
        '56083',
        '56090',
        '56098',
        '56101',
        '56107',
        '56118',
        '56121',
        '56158',
        '56162',
        '56164',
        '56166',
        '56179',
        '56185',
        '56193',
        '56206',
        '56231',
        '56240',
        '56243',
        '56247',
        '56248',
        '56251',
        '56260',
        '57012',
        '57019',
        '57032',
        '57049',
        '57058',
        '57206',
        '57221',
        '57224',
        '57227',
        '57240',
        '57283',
        '57306',
        '57332',
        '57336',
        '57412',
        '57433',
        '57447',
        '57463',
        '57474',
        '57480',
        '57487',
        '57491',
        '57508',
        '57537',
        '57591',
        '57606',
        '57628',
        '57631',
        '57647',
        '57660',
        '57663',
        '57666',
        '57672',
        '57683',
        '57751',
        '57757',
        '58088',
        '58117',
        '58121',
        '58160',
        '58194',
        '58303',
        '59094',
        '59005',
        '59112',
        '59122',
        '59011',
        '59131',
        '59153',
        '59155',
        '59160',
        '59165',
        '59170',
        '59043',
        '59044',
        '59172',
        '59052',
        '59178',
        '59090',
        '59092',
        '59179',
        '59098',
        '59107',
        '59183',
        '59205',
        '59207',
        '59143',
        '59152',
        '59225',
        '59227',
        '59234',
        '59163',
        '59253',
        '59271',
        '59272',
        '59273',
        '59276',
        '59286',
        '59202',
        '59291',
        '59299',
        '59212',
        '59220',
        '59302',
        '59314',
        '59230',
        '59324',
        '59239',
        '59327',
        '59260',
        '59268',
        '59329',
        '59340',
        '59350',
        '59359',
        '59278',
        '59279',
        '59284',
        '59360',
        '59361',
        '59295',
        '59365',
        '59301',
        '59383',
        '59390',
        '59317',
        '59392',
        '59410',
        '59328',
        '59414',
        '59339',
        '59447',
        '59343',
        '59452',
        '59352',
        '59456',
        '59484',
        '59491',
        '59509',
        '59367',
        '59368',
        '59369',
        '59378',
        '59512',
        '59386',
        '59526',
        '59544',
        '59400',
        '59560',
        '59569',
        '59421',
        '59426',
        '59428',
        '59431',
        '59574',
        '59585',
        '59599',
        '59457',
        '59459',
        '59477',
        '59482',
        '59603',
        '59489',
        '59606',
        '59504',
        '59507',
        '59508',
        '59616',
        '59632',
        '59514',
        '59524',
        '59648',
        '59527',
        '59650',
        '59553',
        '59654',
        '59566',
        '72003',
        '72095',
        '59580',
        '59581',
        '72181',
        '59588',
        '59592',
        '59598',
        '13076',
        '13102',
        '2A006',
        '34087',
        '35088',
        '59636',
        '59646',
        '54261',
        '54270',
        '59653',
        '54314',
        '59656',
        '60057',
        '60103',
        '60159',
        '60175',
        '60338',
        '60342',
        '60360',
        '60382',
        '60414',
        '60463',
        '60584',
        '60636',
        '60667',
        '60670',
        '60684',
        '61001',
        '61397',
        '62004',
        '62019',
        '62032',
        '62033',
        '62035',
        '62041',
        '62048',
        '62051',
        '62065',
        '62083',
        '62099',
        '62119',
        '62126',
        '62132',
        '62133',
        '62160',
        '62173',
        '62178',
        '62186',
        '62193',
        '62194',
        '62215',
        '62244',
        '62249',
        '62250',
        '62263',
        '62270',
        '62274',
        '62276',
        '62321',
        '62351',
        '62386',
        '62400',
        '62401',
        '62413',
        '62427',
        '62443',
        '62457',
        '62473',
        '62489',
        '62491',
        '62497',
        '62498',
        '62502',
        '62510',
        '62516',
        '62523',
        '62528',
        '62548',
        '62555',
        '62563',
        '62570',
        '62573',
        '62587',
        '62604',
        '62617',
        '62624',
        '62628',
        '62637',
        '62643',
        '62667',
        '62724',
        '62736',
        '62737',
        '62744',
        '62746',
        '62753',
        '62758',
        '62764',
        '62771',
        '62774',
        '62842',
        '62846',
        '62861',
        '62863',
        '62865',
        '62893',
        '62894',
        '62895',
        '62907',
        '63014',
        '63019',
        '63032',
        '63042',
        '63063',
        '63069',
        '63070',
        '63075',
        '63103',
        '63113',
        '63124',
        '63164',
        '63178',
        '63193',
        '63245',
        '63263',
        '63284',
        '63300',
        '63307',
        '63308',
        '63345',
        '63470',
        '64024',
        '64065',
        '64102',
        '64122',
        '64125',
        '64129',
        '64132',
        '64140',
        '64160',
        '64189',
        '64230',
        '64237',
        '64256',
        '64260',
        '64269',
        '64284',
        '64335',
        '64348',
        '64405',
        '64407',
        '64445',
        '64483',
        '64495',
        '64496',
        '64519',
        '64545',
        '64547',
        '65047',
        '65100',
        '65235',
        '65286',
        '65417',
        '65440',
        '66017',
        '66021',
        '66028',
        '66037',
        '66038',
        '66136',
        '66140',
        '66141',
        '66144',
        '66164',
        '66172',
        '66180',
        '66182',
        '66189',
        '66195',
        '66212',
        '66213',
        '66227',
        '67043',
        '67046',
        '67067',
        '67118',
        '67131',
        '67137',
        '67152',
        '67180',
        '67204',
        '67212',
        '67218',
        '67267',
        '67309',
        '67343',
        '67345',
        '67365',
        '67372',
        '67378',
        '67389',
        '67447',
        '67458',
        '67471',
        '67482',
        '67506',
        '67519',
        '67551',
        '68021',
        '68042',
        '68043',
        '68056',
        '68066',
        '68118',
        '68145',
        '68149',
        '68154',
        '68155',
        '68163',
        '68166',
        '68195',
        '68218',
        '68224',
        '68256',
        '68270',
        '68271',
        '68278',
        '68297',
        '68300',
        '68309',
        '68321',
        '68338',
        '68349',
        '68374',
        '68375',
        '68376',
        '69009',
        '69013',
        '69027',
        '69028',
        '69029',
        '69034',
        '69040',
        '69043',
        '69044',
        '69046',
        '69052',
        '69063',
        '69064',
        '69069',
        '69072',
        '69081',
        '69088',
        '69089',
        '69091',
        '69092',
        '69094',
        '69096',
        '69100',
        '69112',
        '69115',
        '69116',
        '69123',
        '54323',
        '69133',
        '69142',
        '69143',
        '69149',
        '69152',
        '69159',
        '54382',
        '69191',
        '69194',
        '69199',
        '69202',
        '69204',
        '69205',
        '69244',
        '69250',
        '69255',
        '69256',
        '69259',
        '69260',
        '69264',
        '69266',
        '69270',
        '69271',
        '69272',
        '69273',
        '69275',
        '69276',
        '69277',
        '69278',
        '69279',
        '69282',
        '69283',
        '69286',
        '69290',
        '69291',
        '69292',
        '69297',
        '71040',
        '71059',
        '71073',
        '71076',
        '71090',
        '71105',
        '71118',
        '71153',
        '71221',
        '71270',
        '71306',
        '71310',
        '71445',
        '71475',
        '71486',
        '71499',
        '54451',
        '72008',
        '72054',
        '72058',
        '69131',
        '69163',
        '72200',
        '72213',
        '72260',
        '72328',
        '72329',
        '72386',
        '73008',
        '73010',
        '73011',
        '73029',
        '73030',
        '73031',
        '73051',
        '73064',
        '73065',
        '73087',
        '73128',
        '73137',
        '73151',
        '73179',
        '73213',
        '73222',
        '73303',
        '74005',
        '74008',
        '74010',
        '74012',
        '74019',
        '74026',
        '74042',
        '74043',
        '74081',
        '74082',
        '74094',
        '74104',
        '74105',
        '74112',
        '74119',
        '74128',
        '74133',
        '74137',
        '74164',
        '74169',
        '74213',
        '74218',
        '74220',
        '74224',
        '74229',
        '74242',
        '74243',
        '74250',
        '74263',
        '74264',
        '74267',
        '74272',
        '74278',
        '74281',
        '74282',
        '74293',
        '74298',
        '74305',
        '74311',
        '75056',
        '76095',
        '76103',
        '76108',
        '76116',
        '76157',
        '76165',
        '76178',
        '76212',
        '76216',
        '76222',
        '76231',
        '76305',
        '76319',
        '76322',
        '76341',
        '76351',
        '76366',
        '76377',
        '76402',
        '76410',
        '76429',
        '76447',
        '76451',
        '76452',
        '76474',
        '76475',
        '76481',
        '76484',
        '76497',
        '76498',
        '76540',
        '76552',
        '76561',
        '76575',
        '76599',
        '76640',
        '76647',
        '76681',
        '76709',
        '77014',
        '77037',
        '77040',
        '77053',
        '77055',
        '77058',
        '77067',
        '77083',
        '77085',
        '77108',
        '77111',
        '77118',
        '77121',
        '77122',
        '77124',
        '77139',
        '77143',
        '77146',
        '77152',
        '77153',
        '77155',
        '77169',
        '77181',
        '77186',
        '77243',
        '77249',
        '77251',
        '77255',
        '77258',
        '77284',
        '77285',
        '77288',
        '77294',
        '77296',
        '77307',
        '77326',
        '77330',
        '77335',
        '77337',
        '77349',
        '77350',
        '77372',
        '77373',
        '77378',
        '77382',
        '77389',
        '77390',
        '77394',
        '77407',
        '77420',
        '77437',
        '77438',
        '77445',
        '77449',
        '77450',
        '77464',
        '77468',
        '77475',
        '77479',
        '77487',
        '77495',
        '77513',
        '77514',
        '78003',
        '78005',
        '78015',
        '78029',
        '78043',
        '78073',
        '78092',
        '78117',
        '78118',
        '78123',
        '78124',
        '78126',
        '78133',
        '78138',
        '78146',
        '78158',
        '78160',
        '78165',
        '78168',
        '78172',
        '78190',
        '78206',
        '78208',
        '78217',
        '78220',
        '78224',
        '78239',
        '78242',
        '78267',
        '78297',
        '78299',
        '78311',
        '78314',
        '78321',
        '78322',
        '78327',
        '78335',
        '78343',
        '78350',
        '78354',
        '78356',
        '78358',
        '78361',
        '78362',
        '78367',
        '78372',
        '78382',
        '78383',
        '78396',
        '78397',
        '78401',
        '78402',
        '78403',
        '78418',
        '78423',
        '78440',
        '78442',
        '78455',
        '78466',
        '78481',
        '78486',
        '78490',
        '78498',
        '78501',
        '78502',
        '78517',
        '78531',
        '78537',
        '78545',
        '78550',
        '78551',
        '78575',
        '78576',
        '78586',
        '78621',
        '78624',
        '78638',
        '78640',
        '78642',
        '78643',
        '78644',
        '78646',
        '78650',
        '78672',
        '78674',
        '78683',
        '78686',
        '78688',
        '79003',
        '79049',
        '79062',
        '79079',
        '79081',
        '79109',
        '79179',
        '79191',
        '79195',
        '80021',
        '80164',
        '80489',
        '80674',
        '80725',
        '81004',
        '81021',
        '81065',
        '81099',
        '81105',
        '81120',
        '81144',
        '81145',
        '81163',
        '81218',
        '81220',
        '81257',
        '81273',
        '82025',
        '82075',
        '82121',
        '82124',
        '82161',
        '83004',
        '83009',
        '83016',
        '83023',
        '83027',
        '83034',
        '83035',
        '83047',
        '83049',
        '83050',
        '83054',
        '83058',
        '83061',
        '83062',
        '83064',
        '83069',
        '83072',
        '83086',
        '83087',
        '83090',
        '83097',
        '83098',
        '83099',
        '83103',
        '83106',
        '83107',
        '83112',
        '83116',
        '83118',
        '83120',
        '83121',
        '83123',
        '83126',
        '83129',
        '83130',
        '83131',
        '83137',
        '83140',
        '83141',
        '83143',
        '83144',
        '83148',
        '83150',
        '83153',
        '84004',
        '84007',
        '84016',
        '84031',
        '84034',
        '84035',
        '84038',
        '84039',
        '84043',
        '84054',
        '84056',
        '84065',
        '84072',
        '84080',
        '84081',
        '84087',
        '84088',
        '84089',
        '84092',
        '84099',
        '84119',
        '84122',
        '84129',
        '84132',
        '84141',
        '85008',
        '85046',
        '85047',
        '85081',
        '85089',
        '85155',
        '85191',
        '85194',
        '85213',
        '85300',
        '86041',
        '86062',
        '86066',
        '86070',
        '86100',
        '86115',
        '86157',
        '86158',
        '86163',
        '86174',
        '86194',
        '86214',
        '86222',
        '86297',
        '87048',
        '87050',
        '87065',
        '87075',
        '87085',
        '87113',
        '87114',
        '87125',
        '87201',
        '88090',
        '88160',
        '88209',
        '88372',
        '88413',
        '88465',
        '89024',
        '89263',
        '89287',
        '89387',
        '89464',
        '90008',
        '90010',
        '90032',
        '90075',
        '90099',
        '91016',
        '91021',
        '91027',
        '91044',
        '91045',
        '91064',
        '91086',
        '91097',
        '91103',
        '91105',
        '91114',
        '91115',
        '91122',
        '91136',
        '91161',
        '91174',
        '91179',
        '91191',
        '91201',
        '91207',
        '91215',
        '91216',
        '91223',
        '91225',
        '91228',
        '91232',
        '91235',
        '91244',
        '91272',
        '91275',
        '91286',
        '91312',
        '91315',
        '91326',
        '91333',
        '91339',
        '91340',
        '91345',
        '91347',
        '91363',
        '91376',
        '91377',
        '91386',
        '91421',
        '91425',
        '91432',
        '91433',
        '91434',
        '91457',
        '91458',
        '91461',
        '91468',
        '91471',
        '91477',
        '91479',
        '91494',
        '91514',
        '91521',
        '91534',
        '91549',
        '91552',
        '91553',
        '91570',
        '91573',
        '91577',
        '91587',
        '91589',
        '91600',
        '91617',
        '91631',
        '91635',
        '91645',
        '91657',
        '91659',
        '91661',
        '91665',
        '91666',
        '91667',
        '91685',
        '91687',
        '91689',
        '91691',
        '91692',
        '92002',
        '92004',
        '92007',
        '92009',
        '92012',
        '92014',
        '92019',
        '92020',
        '92022',
        '92023',
        '92024',
        '92025',
        '92026',
        '92032',
        '92033',
        '92035',
        '92036',
        '92040',
        '92044',
        '92046',
        '92047',
        '92048',
        '92049',
        '92050',
        '92051',
        '92060',
        '92062',
        '92063',
        '92064',
        '92071',
        '92072',
        '92073',
        '92075',
        '92076',
        '92077',
        '92078',
        '93001',
        '93005',
        '93006',
        '93007',
        '93008',
        '93010',
        '93013',
        '93014',
        '93015',
        '93027',
        '93029',
        '93030',
        '93031',
        '93032',
        '93033',
        '93039',
        '93045',
        '93046',
        '93047',
        '93048',
        '93049',
        '93050',
        '93051',
        '93053',
        '93055',
        '93057',
        '93059',
        '93061',
        '93062',
        '93063',
        '93064',
        '93066',
        '93070',
        '93071',
        '93072',
        '93073',
        '93074',
        '93077',
        '93078',
        '93079',
        '94001',
        '94002',
        '94003',
        '94004',
        '94011',
        '94015',
        '94016',
        '94017',
        '94018',
        '94019',
        '94021',
        '94022',
        '94028',
        '94033',
        '94034',
        '94037',
        '94038',
        '94041',
        '94042',
        '94043',
        '94044',
        '94046',
        '94047',
        '94048',
        '94052',
        '94053',
        '94054',
        '94055',
        '94056',
        '94058',
        '94059',
        '94060',
        '94065',
        '94067',
        '94068',
        '94069',
        '94070',
        '94071',
        '94073',
        '94074',
        '94075',
        '94076',
        '94077',
        '94078',
        '94079',
        '94080',
        '94081',
        '95014',
        '95018',
        '95019',
        '95039',
        '95051',
        '95060',
        '95063',
        '95091',
        '95120',
        '95127',
        '95134',
        '95176',
        '95183',
        '95197',
        '95199',
        '95203',
        '95205',
        '95210',
        '95218',
        '95219',
        '95229',
        '95250',
        '95252',
        '95256',
        '95257',
        '95268',
        '95277',
        '95280',
        '95288',
        '95306',
        '95313',
        '95323',
        '95351',
        '95369',
        '95371',
        '95388',
        '95392',
        '95394',
        '95424',
        '95426',
        '95427',
        '95428',
        '95446',
        '95450',
        '95476',
        '95480',
        '95488',
        '95491',
        '95500',
        '95509',
        '95527',
        '95539',
        '95555',
        '95563',
        '95572',
        '95574',
        '95582',
        '95585',
        '95598',
        '95604',
        '95607',
        '95612',
        '95637',
        '95680',
        '97101',
        '97102',
        '97103',
        '97104',
        '97105',
        '97106',
        '97107',
        '97109',
        '97111',
        '97113',
        '97114',
        '97115',
        '97116',
        '97117',
        '97118',
        '97119',
        '97120',
        '97121',
        '97122',
        '97124',
        '97125',
        '97128',
        '97129',
        '97132',
        '97134',
        '97202',
        '97205',
        '97206',
        '97207',
        '97209',
        '97210',
        '97212',
        '97213',
        '97214',
        '97217',
        '97218',
        '97220',
        '97221',
        '97222',
        '97223',
        '97224',
        '97225',
        '97226',
        '97227',
        '97228',
        '97229',
        '97230',
        '97231',
        '97232',
        '97302',
        '97305',
        '97306',
        '97307',
        '97309',
        '97311',
        '97353',
        '97357',
        '97360',
        '97362',
        '97401',
        '97402',
        '97403',
        '97404',
        '97405',
        '97406',
        '97407',
        '97408',
        '97409',
        '97410',
        '97411',
        '97412',
        '97413',
        '97414',
        '97415',
        '97416',
        '97417',
        '97418',
        '97419',
        '97420',
        '97421',
        '97422',
        '97423',
        '97424',
        '97601',
        '97602',
        '97607',
        '97610',
        '97611',
        '97612',
    ],
    nb_log: [
        853,
        162,
        8260,
        321,
        1286,
        589,
        828,
        1036,
        764,
        494,
        4632,
        727,
        742,
        251,
        465,
        1498,
        411,
        470,
        792,
        526,
        699,
        2731,
        550,
        8615,
        5934,
        378,
        859,
        1581,
        302,
        514,
        5324,
        3785,
        384,
        2783,
        899,
        1941,
        316,
        61,
        205,
        4160,
        5343,
        214,
        799,
        624,
        2190,
        7420,
        1941,
        451,
        1104,
        96,
        245,
        205,
        414,
        109,
        136,
        3606,
        198,
        1352,
        1736,
        666,
        1111,
        27052,
        184,
        504,
        498,
        238,
        208,
        84,
        658,
        33,
        68,
        2061,
        90,
        82,
        74,
        35,
        721,
        1511,
        1372,
        757,
        218,
        719,
        696,
        511,
        961,
        10619,
        801,
        4151,
        406,
        329,
        3093,
        962,
        287,
        513,
        1604,
        789,
        1360,
        12726,
        5834,
        203,
        147,
        104,
        184,
        260,
        7132,
        627,
        185,
        632,
        127,
        302,
        1242,
        2000,
        15459,
        653,
        6266,
        5810,
        493,
        161,
        1820,
        759,
        67,
        208,
        265,
        46,
        375,
        136,
        1489,
        1172,
        4561,
        210,
        67,
        191,
        142,
        212,
        129,
        263,
        1249,
        269,
        2432,
        203,
        389,
        291,
        255,
        216,
        5754,
        184,
        315,
        274,
        285,
        2134,
        85618,
        7035,
        191,
        416,
        81,
        4545,
        254,
        279,
        592,
        820,
        48,
        85,
        299,
        517,
        null,
        3232,
        1905,
        317,
        684,
        108,
        252,
        650,
        277,
        464,
        475,
        332,
        253,
        604,
        1155,
        148,
        null,
        4852,
        257,
        435,
        794,
        219,
        1094,
        393,
        280,
        226,
        80,
        4748,
        87,
        485,
        196,
        1247,
        224,
        423,
        17498,
        1781,
        506,
        818,
        834,
        6329,
        1751,
        null,
        415,
        1090,
        1753,
        908,
        627,
        363,
        355,
        4646,
        256,
        6524,
        26,
        67,
        235,
        159,
        2143,
        889,
        228,
        453,
        537,
        289,
        53,
        566,
        389,
        1803,
        158,
        70,
        1067,
        48,
        339,
        252,
        124,
        144,
        592,
        360,
        723,
        444,
        2905,
        13458,
        1468,
        186,
        53,
        240,
        137,
        2903,
        226,
        313,
        181,
        233,
        10620,
        147,
        353,
        1009,
        586,
        55,
        179,
        4243,
        379,
        144,
        263,
        63,
        2670,
        2674,
        1196,
        16898,
        580,
        1266,
        440,
        1349,
        743,
        1626,
        129,
        337,
        180,
        764,
        488,
        2518,
        494,
        86,
        371,
        188,
        1018,
        217,
        152,
        226,
        113,
        975,
        331,
        83,
        6342,
        226,
        462,
        234,
        39,
        2661,
        870,
        265,
        1003,
        139,
        4329,
        170,
        375,
        2417,
        323,
        19248,
        865,
        424,
        1087,
        474,
        723,
        4811,
        496,
        357,
        388,
        1069,
        1356,
        234,
        1419,
        1628,
        304,
        177,
        351,
        278,
        245,
        3736,
        1172,
        4408,
        302,
        8595,
        1057,
        656,
        110,
        226,
        60,
        10490,
        152,
        1250,
        557,
        3460,
        338,
        388,
        264,
        326,
        401,
        587,
        3845,
        3167,
        185,
        5962,
        360,
        5036,
        456,
        4484,
        653,
        328,
        2102,
        433,
        403,
        1890,
        128,
        18901,
        482,
        2304,
        632,
        439,
        579,
        904,
        311,
        786,
        980,
        297,
        8011,
        1005,
        574,
        184,
        5206,
        null,
        5292,
        96,
        311,
        7641,
        450,
        2887,
        206,
        215,
        173,
        115,
        77,
        226,
        43,
        987,
        343,
        317,
        297,
        376,
        17995,
        997,
        61,
        22,
        171,
        268,
        228,
        86,
        380,
        1007,
        151,
        425,
        172,
        283,
        270,
        742,
        752,
        467,
        932,
        1709,
        798,
        2811,
        161,
        350,
        1549,
        819,
        113,
        193,
        4806,
        703,
        1711,
        387,
        603,
        465,
        782,
        204,
        592,
        357,
        454,
        822,
        724,
        686,
        593,
        206,
        2725,
        271,
        421,
        274,
        1619,
        806,
        405,
        1680,
        341,
        313,
        603,
        928,
        810,
        602,
        1158,
        430,
        480,
        56947,
        2454,
        570,
        909,
        1751,
        989,
        901,
        59,
        1239,
        4186,
        2117,
        27779,
        76,
        2556,
        2171,
        529,
        597,
        958,
        320,
        4729,
        1272,
        513,
        3267,
        3344,
        2523,
        1215,
        1197,
        202,
        118,
        813,
        2470,
        5110,
        487,
        8749,
        887,
        9266,
        261,
        267,
        81,
        752,
        393,
        436,
        277,
        716,
        612,
        2731,
        269,
        633,
        6017,
        721,
        2142,
        505,
        177,
        4648,
        1632,
        542,
        488,
        271,
        8642,
        2286,
        388,
        470,
        null,
        279,
        593,
        188,
        236,
        2018,
        320,
        690,
        531,
        922,
        974,
        1976,
        304,
        290,
        344,
        267,
        720,
        269,
        71,
        34499,
        627,
        852,
        595,
        86,
        291,
        234,
        349,
        286,
        901,
        275,
        402,
        254,
        5064,
        60,
        377,
        234,
        250,
        654,
        47,
        509,
        224,
        956,
        289,
        1706,
        485,
        1872,
        1352,
        375,
        903,
        435,
        337,
        null,
        3334,
        358,
        199,
        568,
        199,
        132,
        369,
        596,
        771,
        479,
        840,
        34362,
        1016,
        148,
        555,
        550,
        1845,
        7677,
        203,
        652,
        621,
        566,
        1946,
        278,
        191,
        8184,
        769,
        247,
        219,
        1776,
        659,
        544,
        1272,
        136,
        575,
        5156,
        371,
        317,
        1054,
        332,
        231,
        1582,
        1097,
        1564,
        3275,
        21391,
        333,
        373,
        4419,
        823,
        430,
        218,
        259,
        656,
        6863,
        202,
        1107,
        2977,
        870,
        20441,
        3102,
        271,
        1561,
        720,
        288,
        692,
        1442,
        983,
        692,
        696,
        250,
        227,
        1766,
        352,
        358,
        6463,
        656,
        612,
        650,
        112,
        379,
        675,
        733,
        562,
        523,
        719,
        538,
        121,
        757,
        432,
        4975,
        514,
        710,
        4515,
        889,
        2858,
        954,
        3765,
        208,
        2793,
        2780,
        483,
        1289,
        770,
        1304,
        8475,
        285,
        212,
        2592,
        519,
        1378,
        340,
        2536,
        1150,
        3799,
        326,
        387,
        774,
        887,
        542,
        982,
        2050,
        1681,
        983,
        2359,
        6243,
        1213,
        6546,
        26460,
        320,
        593,
        78,
        190,
        698,
        359,
        138,
        746,
        622,
        891,
        205,
        544,
        389,
        880,
        1176,
        305,
        482,
        139,
        581,
        439,
        341,
        2754,
        73,
        146,
        459,
        477,
        1871,
        114,
        1464,
        246,
        1218,
        1950,
        286,
        524,
        940,
        1035,
        261,
        353,
        336,
        451,
        821,
        45614,
        2394,
        382,
        116,
        187,
        401,
        616,
        380,
        4734,
        113,
        272,
        447,
        6137,
        405,
        182,
        1314,
        165,
        157,
        11072,
        286,
        2165,
        602,
        574,
        721,
        966,
        232,
        1213,
        703,
        402,
        2898,
        698,
        1397,
        15789,
        205,
        427,
        2322,
        2257,
        616,
        506,
        1827,
        null,
        329,
        443,
        495,
        212,
        1361,
        908,
        348,
        620,
        29958,
        1671,
        451,
        1177,
        413,
        562,
        1272,
        5591,
        687,
        451,
        133,
        661,
        263,
        370,
        1047,
        435,
        636,
        545,
        995,
        1812,
        486,
        1670,
        346,
        150,
        1039,
        802,
        981,
        3819,
        117,
        2887,
        445,
        166,
        362,
        814,
        441,
        15840,
        282,
        4527,
        344,
        1008,
        779,
        494,
        10098,
        632,
        318,
        956,
        601,
        43012,
        1248,
        1633,
        597,
        4496,
        355,
        385,
        321,
        8367,
        244,
        657,
        251,
        1014,
        1148,
        701,
        599,
        null,
        null,
        1711,
        713,
        2214,
        null,
        374,
        null,
        583,
        947,
        2531,
        null,
        13880,
        467,
        706,
        null,
        1214,
        405,
        490,
        850,
        5565,
        1560,
        278,
        279,
        518,
        448,
        321,
        568,
        1903,
        376,
        3130,
        281,
        466,
        179,
        9799,
        410,
        1086,
        392,
        255,
        295,
        489,
        443,
        961,
        191,
        357,
        682,
        146,
        188,
        489,
        7292,
        461,
        1479,
        553,
        602,
        2173,
        1758,
        1080,
        579,
        2676,
        2235,
        1135,
        1496,
        966,
        437,
        311,
        1216,
        859,
        19791,
        409,
        3667,
        576,
        525,
        276,
        573,
        899,
        2130,
        151,
        2702,
        322,
        1657,
        916,
        976,
        5417,
        876,
        3307,
        1926,
        252,
        786,
        201,
        47,
        6557,
        1172,
        null,
        148,
        null,
        null,
        805,
        null,
        null,
        null,
        null,
        null,
        null,
        1352,
        267,
        null,
        432,
        null,
        648,
        337,
        null,
        352,
        275,
        null,
        null,
        null,
        881,
        1484,
        null,
        null,
        null,
        2191,
        null,
        null,
        null,
        null,
        null,
        null,
        430,
        null,
        null,
        460,
        1465,
        null,
        null,
        475,
        null,
        192,
        null,
        173,
        393,
        null,
        null,
        null,
        null,
        298,
        2247,
        152,
        null,
        null,
        2115,
        null,
        153,
        null,
        null,
        560,
        null,
        null,
        2153,
        null,
        783,
        null,
        832,
        null,
        720,
        null,
        null,
        null,
        null,
        1495,
        2945,
        189,
        3432,
        null,
        1227,
        null,
        null,
        706,
        null,
        null,
        826,
        625,
        383,
        773,
        null,
        null,
        null,
        684,
        491,
        374,
        542,
        null,
        292,
        null,
        270,
        2443,
        1086,
        null,
        null,
        296,
        299,
        null,
        1409,
        null,
        257,
        null,
        165,
        null,
        null,
        163,
        219,
        null,
        898,
        162,
        304,
        null,
        null,
        null,
        null,
        null,
        1081,
        2220,
        null,
        null,
        700,
        null,
        653,
        11302,
        360,
        7806,
        8103,
        616,
        392,
        952,
        965,
        2364,
        3566,
        580,
        762,
        451,
        269,
        835,
        5699,
        289,
        1038,
        394,
        805,
        326,
        322,
        7416,
        1805,
        539,
        3895,
        1838,
        682,
        3753,
        825,
        309,
        1653,
        7316,
        341,
        5672,
        2490,
        10185,
        1270,
        2643,
        360,
        934,
        1452,
        434,
        1467,
        773,
        541,
        699,
        1225,
        1872,
        905,
        558,
        2452,
        4139,
        1112,
        1311,
        643,
        272,
        208,
        1068,
        9069,
        325,
        7234,
        1007,
        657,
        881,
        635,
        1541,
        1896,
        2484,
        368,
        1800,
        363,
        2407,
        1207,
        2035,
        2231,
        2339,
        1676,
        2093,
        157,
        1013,
        175,
        680,
        1200,
        1323,
        882,
        2650,
        459,
        1270,
        557,
        289,
        317,
        363,
        718,
        388,
        1511,
        1926,
        1248,
        729,
        970,
        327,
        823,
        531,
        508,
        1021,
        120,
        24773,
        2236,
        1055,
        1834,
        913,
        186,
        16,
        955,
        2033,
        755,
        386,
        99,
        95,
        3777,
        217,
        7353,
        1673,
        698,
        1594,
        350,
        865,
        265,
        519,
        239,
        248,
        232,
        1474,
        210,
        698,
        697,
        1173,
        314,
        228,
        10663,
        1663,
        251,
        495,
        290,
        621,
        603,
        659,
        423,
        25,
        1530,
        306,
        7436,
        169,
        560,
        755,
        675,
        367,
        12327,
        234,
        568,
        341,
        767,
        789,
        467,
        130,
        348,
        504,
        133,
        487,
        262,
        3181,
        1024,
        333,
        355,
        267,
        382,
        389,
        2516,
        1038,
        224,
        3332,
        2091,
        269,
        513,
        43,
        1392,
        229,
        163,
        242,
        5451,
        329,
        453,
        44898,
        388,
        119,
        249,
        108,
        232,
        237,
        428,
        9942,
        262,
        379,
        991,
        1210,
        449,
        210,
        989,
        544,
        141,
        15689,
        924,
        254,
        1177,
        925,
        2595,
        394,
        177,
        357,
        267,
        140,
        588,
        873,
        1323,
        837,
        359,
        1133,
        284,
        4830,
        4035,
        625,
        526,
        333,
        127,
        133,
        204,
        403,
        911,
        743,
        2346,
        748,
        1226,
        3920,
        833,
        500,
        1349,
        736,
        401,
        411,
        327,
        61804,
        null,
        118,
        768,
        1048,
        2614,
        1224,
        49,
        null,
        456,
        197,
        3716,
        1385,
        1752,
        278,
        1617,
        197,
        338,
        9782,
        13367,
        471,
        6026,
        22376,
        138,
        807,
        300,
        667,
        3163,
        1427,
        649,
        406,
        23,
        3096,
        983,
        6527,
        6413,
        339,
        807,
        289,
        737,
        165,
        697,
        8934,
        176,
        758,
        475,
        4784,
        190,
        7254,
        4435,
        1031,
        598,
        795,
        827,
        662,
        null,
        414,
        215,
        267,
        null,
        null,
        117,
        429,
        170,
        192,
        116,
        250,
        3506,
        179,
        3095,
        449,
        552,
        448,
        513,
        403,
        9791,
        899,
        355,
        856,
        191,
        957,
        1009,
        494,
        1465,
        151,
        540,
        14113,
        4599,
        283,
        418,
        1317,
        270,
        1953,
        161,
        381,
        142,
        480,
        620,
        1210,
        20,
        1200,
        156,
        418,
        503,
        546,
        874,
        496,
        1130,
        107,
        355,
        1567,
        443,
        388,
        778,
        182,
        304,
        503,
        4270,
        189,
        172,
        776,
        890,
        163,
        264854,
        1025,
        567,
        697,
        234,
        4328,
        1328,
        1410,
        2047,
        2215,
        544,
        3802,
        2745,
        1988,
        9128,
        2192,
        32130,
        846,
        124,
        778,
        3037,
        689,
        2038,
        2780,
        558,
        1144,
        469,
        228,
        2211,
        1627,
        4054,
        16920,
        446,
        1324,
        7020,
        359,
        1151,
        292,
        4851,
        897,
        931,
        53,
        175,
        1570,
        653,
        2480,
        838,
        4284,
        390,
        5531,
        904,
        754,
        271,
        2475,
        136,
        373,
        445,
        80,
        3643,
        574,
        212,
        607,
        150,
        1710,
        2247,
        265,
        1618,
        173,
        1946,
        9241,
        3685,
        7034,
        1996,
        2054,
        1189,
        714,
        389,
        126,
        3069,
        346,
        1498,
        478,
        3275,
        320,
        157,
        385,
        2055,
        220,
        1577,
        70,
        null,
        679,
        3396,
        869,
        207,
        1046,
        3591,
        221,
        1501,
        964,
        716,
        458,
        2259,
        null,
        4290,
        1049,
        1878,
        129,
        1225,
        761,
        629,
        306,
        2933,
        1131,
        1444,
        497,
        1573,
        2164,
        2390,
        309,
        1741,
        762,
        3322,
        1044,
        304,
        2967,
        582,
        423,
        192,
        217,
        1699,
        601,
        6284,
        219,
        2484,
        242,
        221,
        1427,
        172,
        2512,
        109,
        559,
        630,
        1129,
        1463,
        7229,
        3221,
        153,
        1177,
        345,
        1809,
        532,
        348,
        1114,
        238,
        125,
        1132,
        3979,
        5068,
        98,
        435,
        258,
        1673,
        227,
        3330,
        5576,
        224,
        872,
        2643,
        620,
        173,
        2822,
        3,
        4479,
        385,
        27,
        6295,
        7236,
        699,
        312,
        2512,
        1681,
        942,
        1675,
        7872,
        911,
        322,
        947,
        219,
        1308,
        897,
        436,
        1112,
        580,
        385,
        488,
        210,
        243,
        7343,
        421,
        24489,
        456,
        1030,
        233,
        233,
        5130,
        618,
        3741,
        969,
        853,
        331,
        216,
        136,
        789,
        73,
        132,
        668,
        77,
        62,
        243,
        4998,
        48,
        131,
        288,
        398,
        265,
        1580,
        135,
        280,
        201,
        627,
        362,
        3515,
        387,
        135,
        3892,
        3438,
        70,
        4347,
        321,
        440,
        4,
        907,
        105,
        594,
        464,
        86,
        0,
        312,
        606,
        492,
        2110,
        169,
        135,
        681,
        5971,
        1961,
        740,
        81,
        14685,
        62,
        229,
        125,
        1554,
        656,
        158,
        189,
        259,
        15001,
        331,
        3031,
        275,
        2711,
        55,
        227,
        785,
        1354,
        270,
        48,
        135,
        959,
        601,
        2920,
        354,
        1085,
        1475,
        153,
        291,
        262,
        1676,
        337,
        929,
        269,
        150,
        1173,
        145,
        212,
        224,
        7923,
        2482,
        155,
        141,
        976,
        552,
        4686,
        570,
        149,
        491,
        325,
        382,
        122,
        353,
        15520,
        526,
        203,
        194,
        321,
        558,
        557,
        826,
        19967,
        506,
        1078,
        149,
        43,
        549,
        5383,
        1383,
        685,
        3271,
        1013,
        6177,
        305,
        635,
        4514,
        494,
        468,
        8931,
        334,
        486,
        591,
        212,
        1516,
        4691,
        354,
        824,
        295,
        835,
        719,
        3391,
        732,
        2132,
        334,
        1307,
        77,
        1561,
        7411,
        368,
        734,
        3295,
        714,
        1866,
        680,
        2993,
        176,
        13192,
        236,
        2281,
        123,
        3203,
        171,
        4010,
        865,
        272,
        2394,
        250,
        582,
        785,
        2941,
        471,
        695,
        347,
        8160,
        1520,
        2309,
        734,
        935,
        2,
        2360,
        225,
        179,
        399,
        220,
        1840,
        5822,
        582,
        244,
        909,
        3689,
        262,
        4191,
        945,
        448,
        2646,
        990,
        238,
        510,
        2770,
        513,
        223,
        83,
        60,
        1333,
        4709,
        259,
        837,
        553,
        58,
        447,
        409,
        4780,
        321,
        1797,
        4670,
        6242,
        8590,
        11115,
        2613,
        9152,
        1764,
        6505,
        3928,
        2369,
        7514,
        10095,
        12474,
        9094,
        4463,
        1849,
        1797,
        12924,
        8053,
        6411,
        6049,
        142,
        5606,
        5352,
        21583,
        2034,
        4426,
        6293,
        8933,
        2276,
        2276,
        2589,
        7394,
        3270,
        363,
        608,
        4993,
        15270,
        10873,
        7502,
        8219,
        11691,
        9428,
        1812,
        3251,
        179,
        7981,
        8802,
        2870,
        7039,
        3559,
        279,
        1985,
        3312,
        3973,
        2229,
        19228,
        2096,
        6301,
        8512,
        8335,
        9954,
        1687,
        4465,
        3855,
        617,
        5321,
        5282,
        23227,
        9783,
        7643,
        8425,
        4015,
        757,
        3296,
        3987,
        2772,
        355,
        8760,
        4507,
        2549,
        4524,
        1458,
        5119,
        13565,
        3599,
        1863,
        3614,
        7357,
        16606,
        7486,
        3971,
        4574,
        3976,
        11642,
        2251,
        4413,
        3305,
        6342,
        394,
        215,
        2450,
        272,
        5069,
        286,
        98,
        2190,
        1676,
        1123,
        590,
        1334,
        3314,
        1553,
        274,
        2332,
        3296,
        3613,
        621,
        9642,
        1886,
        5324,
        3985,
        2987,
        14629,
        142,
        14469,
        440,
        251,
        704,
        903,
        5711,
        734,
        109,
        11924,
        409,
        2059,
        620,
        2024,
        1336,
        2356,
        611,
        701,
        2156,
        4308,
        814,
        756,
        3272,
        184,
        133,
        6650,
        4429,
        3420,
        377,
        2426,
        1270,
        1996,
        1057,
        182,
        259,
        615,
        405,
        706,
        2609,
        79,
        1407,
        1994,
        37,
        360,
        1327,
        279,
        1010,
        298,
        4894,
        64,
        423,
        1160,
        2942,
        973,
        3487,
        471,
        3321,
        11224,
        1912,
        381,
        2504,
        117,
        1632,
        5171,
        9920,
        121,
        4303,
        186,
        1894,
        306,
        966,
        880,
        65,
        984,
        1273,
        2228,
        1251,
        1397,
        1919,
        173,
        5378,
        115,
        790,
        1134,
        581,
        912,
        1062,
        560,
        338,
        172,
        456,
        370,
        1406,
        11757,
        987,
        482,
        4491,
        702,
        931,
        216,
        536,
        1436,
        1676,
        534,
        945,
        641,
        269,
        757,
        649,
        1945,
        1295,
        527,
        548,
        6764,
        2535,
        201,
        2176,
        1487,
        3047,
        30,
        43,
        67,
        22,
        589,
        1635,
        283,
        985,
        291,
        412,
        7359,
        4256,
        5434,
        4999,
        24589,
        2125,
        1606,
        3966,
        7194,
        8006,
        193,
        3618,
        386,
        2044,
        70,
        4870,
        178,
        64,
        6,
        19,
        109,
        215,
        848,
        11,
    ],
    sru_current: [
        0.5295,
        0.082,
        0.4147,
        0.1721,
        0.2695,
        0.2736,
        0.3159,
        0.2517,
        0.2512,
        0.2604,
        0.4832,
        0.2525,
        0.2022,
        0.1344,
        0.174,
        0.2674,
        0.2416,
        0.1793,
        0.263,
        0.1861,
        0.419,
        0.381,
        0.237,
        0.333,
        0.43,
        0.195,
        0.198,
        0.241,
        0.136,
        0.124,
        0.28,
        0.365,
        0.218,
        0.17,
        0.146,
        0.1836,
        0.1125,
        0.0367,
        0.1213,
        0.2066,
        0.1236,
        0.1066,
        0.1213,
        0.138,
        0.0846,
        0.1761,
        0.0842,
        0.1913,
        0.2192,
        0.0621,
        0.0663,
        0.0658,
        0.2089,
        0.0594,
        0.0472,
        0.1544,
        0.0887,
        0.0943,
        0.0967,
        0.1342,
        0.1205,
        0.142,
        0.0531,
        0.118,
        0.0637,
        0.0748,
        0.0877,
        0.0462,
        0.2846,
        0.0175,
        0.0381,
        0.1259,
        0.0556,
        0.0472,
        0.0372,
        0.0176,
        0.1663,
        0.2889,
        0.0941,
        0.0787,
        0.0821,
        0.0819,
        0.1187,
        0.1453,
        0.2672,
        0.457,
        0.3108,
        0.4867,
        0.2542,
        0.2054,
        0.6018,
        0.4514,
        0.1853,
        0.2493,
        0.2664,
        0.2474,
        0.2451,
        0.423,
        0.2496,
        0.0723,
        0.0771,
        0.0429,
        0.0579,
        0.0905,
        0.2556,
        0.2034,
        0.0698,
        0.254,
        0.0647,
        0.1055,
        0.2173,
        0.1409,
        0.2152,
        0.0716,
        0.2447,
        0.2916,
        0.0972,
        0.0806,
        0.3228,
        0.1248,
        0.0272,
        0.1037,
        0.0661,
        0.0143,
        0.1056,
        0.064,
        0.201,
        0.1491,
        0.2314,
        0.0916,
        0.0444,
        0.0514,
        0.0537,
        0.0646,
        0.0653,
        0.0693,
        0.1835,
        0.0613,
        0.2449,
        0.0731,
        0.0877,
        0.121,
        0.1169,
        0.1236,
        0.2987,
        0.0939,
        0.0733,
        0.0705,
        0.0942,
        0.1409,
        0.2169,
        0.3035,
        0.1222,
        0.1501,
        0.0444,
        0.4151,
        0.1024,
        0.0602,
        0.2226,
        0.089,
        0.0298,
        0.0364,
        0.1321,
        0.1022,
        Infinity,
        0.4285,
        0.4822,
        0.1421,
        0.1266,
        0.0533,
        0.1263,
        0.3258,
        0.1185,
        0.1172,
        0.2116,
        0.138,
        0.1033,
        0.1552,
        0.1891,
        0.0545,
        Infinity,
        0.2394,
        0.0614,
        0.1448,
        0.1688,
        0.0923,
        0.1707,
        0.0832,
        0.0748,
        0.0584,
        0.0317,
        0.309,
        0.0577,
        0.1764,
        0.1237,
        0.4518,
        0.09,
        0.2069,
        0.3011,
        0.5777,
        0.227,
        0.3418,
        0.4027,
        0.6159,
        0.3559,
        Infinity,
        0.1484,
        0.2716,
        0.3539,
        0.1811,
        0.1892,
        0.208,
        0.1174,
        0.3166,
        0.14,
        0.2815,
        0.0159,
        0.0281,
        0.1316,
        0.096,
        0.2222,
        0.233,
        0.1315,
        0.1526,
        0.2092,
        0.1269,
        0.0269,
        0.1537,
        0.1117,
        0.3966,
        0.0813,
        0.035,
        0.2054,
        0.0282,
        0.0897,
        0.0919,
        0.0726,
        0.0576,
        0.1713,
        0.1276,
        0.1685,
        0.1506,
        0.2239,
        0.3038,
        0.1154,
        0.0546,
        0.0211,
        0.1145,
        0.054,
        0.2111,
        0.0596,
        0.0791,
        0.0679,
        0.0881,
        0.3178,
        0.0915,
        0.1088,
        0.2287,
        0.2505,
        0.0314,
        0.0969,
        0.1709,
        0.0937,
        0.0734,
        0.1151,
        0.0334,
        0.2704,
        0.4408,
        0.2268,
        0.2039,
        0.1299,
        0.3044,
        0.1805,
        0.3454,
        0.2003,
        0.3014,
        0.0552,
        0.0879,
        0.0948,
        0.092,
        0.1314,
        0.2447,
        0.1122,
        0.0448,
        0.1221,
        0.0715,
        0.1418,
        0.1103,
        0.0733,
        0.122,
        0.066,
        0.1846,
        0.0978,
        0.0484,
        0.2677,
        0.1004,
        0.1177,
        0.0993,
        0.0192,
        0.1782,
        0.1817,
        0.123,
        0.2628,
        0.0662,
        0.2497,
        0.0897,
        0.1026,
        0.38,
        0.2,
        0.319,
        0.426,
        0.268,
        0.464,
        0.285,
        0.325,
        0.425,
        0.243,
        0.166,
        0.139,
        0.588,
        0.3,
        0.1287,
        0.2763,
        0.174,
        0.099,
        0.0974,
        0.1296,
        0.1215,
        0.1395,
        0.193,
        0.2522,
        0.2691,
        0.1231,
        0.2629,
        0.299,
        0.3,
        0.07,
        0.128,
        0.024,
        0.472,
        0.092,
        0.392,
        0.287,
        0.422,
        0.144,
        0.229,
        0.154,
        0.204,
        0.199,
        0.256,
        0.343,
        0.707,
        0.1285,
        0.3009,
        0.1927,
        0.4282,
        0.1991,
        0.5709,
        0.2116,
        0.1554,
        0.4248,
        0.2519,
        0.2643,
        0.4045,
        0.0906,
        0.2589,
        0.206,
        0.2082,
        0.1735,
        0.1617,
        0.1713,
        0.1371,
        0.1657,
        0.1316,
        0.1762,
        0.1617,
        0.2441,
        0.1893,
        0.1621,
        0.052,
        0.1914,
        Infinity,
        0.312,
        0.0331,
        0.1407,
        0.36,
        0.098,
        0.336,
        0.075,
        0.114,
        0.088,
        0.063,
        0.046,
        0.109,
        0.024,
        0.434,
        0.142,
        0.106,
        0.077,
        0.14,
        0.242,
        0.183,
        0.034,
        0.012,
        0.101,
        0.082,
        0.095,
        0.045,
        0.111,
        0.161,
        0.07,
        0.203,
        0.069,
        0.164,
        0.127,
        0.118,
        0.1631,
        0.149,
        0.4036,
        0.2164,
        0.2307,
        0.2219,
        0.0827,
        0.131,
        0.2012,
        0.1615,
        0.0664,
        0.0663,
        0.275,
        0.1833,
        0.1969,
        0.1416,
        0.1935,
        0.1803,
        0.1451,
        0.0942,
        0.1379,
        0.1694,
        0.1754,
        0.347,
        0.1775,
        0.1611,
        0.2034,
        0.1086,
        0.2395,
        0.1435,
        0.1147,
        0.1416,
        0.187,
        0.1969,
        0.1448,
        0.2268,
        0.1553,
        0.1619,
        0.2026,
        0.1723,
        0.2332,
        0.1432,
        0.1725,
        0.12,
        0.1276,
        0.2173,
        0.1848,
        0.0984,
        0.1921,
        0.2446,
        0.1289,
        0.2367,
        0.0397,
        0.3296,
        0.2704,
        0.2771,
        0.1957,
        0.061,
        0.2036,
        0.2212,
        0.1788,
        0.2087,
        0.2788,
        0.1836,
        0.3796,
        0.1702,
        0.1228,
        0.2875,
        0.4161,
        0.207,
        0.1075,
        0.2178,
        0.0813,
        0.0741,
        0.1741,
        0.2034,
        0.4862,
        0.1482,
        0.2332,
        0.1857,
        0.3203,
        0.091,
        0.1242,
        0.0432,
        0.1357,
        0.1281,
        0.1869,
        0.1379,
        0.1839,
        0.1455,
        0.1914,
        0.127,
        0.1394,
        0.2623,
        0.1666,
        0.1445,
        0.2285,
        0.0993,
        0.2372,
        0.0912,
        0.1617,
        0.1307,
        0.1116,
        0.217,
        0.1869,
        0.1355,
        0.195,
        Infinity,
        0.0986,
        0.142,
        0.0594,
        0.1017,
        0.1784,
        0.1133,
        0.1716,
        0.1874,
        0.1607,
        0.1162,
        0.1708,
        0.1671,
        0.1386,
        0.0792,
        0.085,
        0.1234,
        0.1358,
        0.0398,
        0.2416,
        0.137,
        0.2047,
        0.1713,
        0.033,
        0.1086,
        0.1042,
        0.0804,
        0.1215,
        0.1541,
        0.1081,
        0.0937,
        0.1076,
        0.2115,
        0.0191,
        0.1226,
        0.0778,
        0.1158,
        0.1627,
        0.0301,
        0.171,
        0.1185,
        0.172,
        0.1592,
        0.1901,
        0.1617,
        0.2226,
        0.2635,
        0.1945,
        0.2206,
        0.1405,
        0.1733,
        Infinity,
        0.307,
        0.1482,
        0.1021,
        0.2744,
        0.0937,
        0.0686,
        0.2168,
        0.1615,
        0.2237,
        0.2068,
        0.175,
        0.3075,
        0.2467,
        0.088,
        0.242,
        0.1247,
        0.2834,
        0.2846,
        0.0954,
        0.1833,
        0.1736,
        0.2413,
        0.2188,
        0.1488,
        0.1093,
        0.3716,
        0.2167,
        0.0928,
        0.161,
        0.2871,
        0.1899,
        0.2175,
        0.2361,
        0.0958,
        0.1279,
        0.3075,
        0.1812,
        0.1529,
        0.2151,
        0.1804,
        0.1273,
        0.3016,
        0.1684,
        0.1904,
        0.4678,
        0.2954,
        0.1158,
        0.1573,
        0.3195,
        0.3442,
        0.1259,
        0.1254,
        0.1115,
        0.2133,
        0.4514,
        0.1361,
        0.2377,
        0.2759,
        0.234,
        0.2466,
        0.5052,
        0.1645,
        0.1779,
        0.2099,
        0.12,
        0.2385,
        0.3251,
        0.3867,
        0.2315,
        0.1885,
        0.1226,
        0.1447,
        0.2301,
        0.1146,
        0.2439,
        0.4043,
        0.2689,
        0.242,
        0.3142,
        0.0655,
        0.1983,
        0.1328,
        0.1286,
        0.1671,
        0.1811,
        0.2172,
        0.1871,
        0.0683,
        0.2562,
        0.2148,
        0.3393,
        0.1443,
        0.2171,
        0.66,
        0.2608,
        0.2547,
        0.2265,
        0.309,
        0.112,
        0.2246,
        0.1953,
        0.1761,
        0.1763,
        0.1706,
        0.217,
        0.3758,
        0.1306,
        0.1165,
        0.327,
        0.1453,
        0.317,
        0.184,
        0.478,
        0.311,
        0.471,
        0.156,
        0.216,
        0.384,
        0.421,
        0.257,
        0.296,
        0.259,
        0.517,
        0.181,
        0.361,
        0.322,
        0.262,
        0.401,
        0.314,
        0.126,
        0.211,
        0.049,
        0.117,
        0.248,
        0.165,
        0.088,
        0.39,
        0.204,
        0.135,
        0.117,
        0.155,
        0.141,
        0.287,
        0.311,
        0.077,
        0.137,
        0.065,
        0.219,
        0.198,
        0.193,
        0.267,
        0.048,
        0.0483,
        0.1159,
        0.1387,
        0.2156,
        0.0538,
        0.1656,
        0.1309,
        0.1435,
        0.1987,
        0.1192,
        0.1539,
        0.0858,
        0.128,
        0.1086,
        0.1125,
        0.1776,
        0.1654,
        0.262,
        0.2863,
        0.201,
        0.1675,
        0.0472,
        0.0681,
        0.0444,
        0.0863,
        0.1304,
        0.2357,
        0.0697,
        0.0972,
        0.0614,
        0.2689,
        0.167,
        0.0971,
        0.1998,
        0.0793,
        0.0509,
        0.291,
        0.1006,
        0.1576,
        0.1636,
        0.1535,
        0.1634,
        0.2584,
        0.0839,
        0.1074,
        0.1544,
        0.1092,
        0.3105,
        0.1678,
        0.1335,
        0.283,
        0.1242,
        0.1354,
        0.2357,
        0.3147,
        0.1425,
        0.194,
        0.2677,
        Infinity,
        0.1416,
        0.1577,
        0.2078,
        0.128,
        0.1579,
        0.2001,
        0.1922,
        0.1449,
        0.3694,
        0.2376,
        0.1893,
        0.1182,
        0.1496,
        0.08,
        0.143,
        0.2139,
        0.1387,
        0.2224,
        0.0675,
        0.2393,
        0.1528,
        0.1462,
        0.3105,
        0.2378,
        0.0928,
        0.1958,
        0.1237,
        0.297,
        0.1254,
        0.3758,
        0.2019,
        0.1022,
        0.0976,
        0.1198,
        0.2907,
        0.2798,
        0.0696,
        0.4136,
        0.1287,
        0.0736,
        0.1709,
        0.1616,
        0.1631,
        0.3963,
        0.1534,
        0.45,
        0.1736,
        0.2889,
        0.2525,
        0.2214,
        0.4866,
        0.2167,
        0.1615,
        0.381,
        0.3554,
        0.4826,
        0.4734,
        0.3435,
        0.2901,
        0.2,
        0.1366,
        0.1415,
        0.1677,
        0.334,
        0.1294,
        0.194,
        0.1383,
        0.3181,
        0.2637,
        0.2358,
        0.2398,
        Infinity,
        Infinity,
        0.3628,
        0.2496,
        0.3288,
        Infinity,
        0.156,
        Infinity,
        0.2225,
        0.2418,
        0.6835,
        Infinity,
        0.2301,
        0.2191,
        0.3116,
        Infinity,
        0.2334,
        0.2199,
        0.2277,
        0.2183,
        0.3763,
        0.2106,
        0.0969,
        0.1127,
        0.1718,
        0.1697,
        0.1352,
        0.1022,
        0.2382,
        0.1288,
        0.2871,
        0.0823,
        0.102,
        0.0869,
        0.3089,
        0.135,
        0.1231,
        0.1276,
        0.0967,
        0.1741,
        0.1246,
        0.1569,
        0.1862,
        0.1144,
        0.0721,
        0.1458,
        0.0926,
        0.0872,
        0.1284,
        0.2382,
        0.1564,
        0.2982,
        0.247,
        0.2778,
        0.9002,
        0.3123,
        0.207,
        0.3442,
        0.2911,
        0.3849,
        0.2552,
        0.2082,
        0.3684,
        0.1805,
        0.1439,
        0.2461,
        0.194,
        0.3491,
        0.152,
        0.3318,
        0.2414,
        0.1486,
        0.1233,
        0.2179,
        0.2038,
        0.2925,
        0.076,
        0.2642,
        0.171,
        0.3073,
        0.2704,
        0.2846,
        0.2685,
        0.3221,
        0.5448,
        0.2353,
        0.144,
        0.362,
        0.126,
        0.028,
        0.371,
        0.266,
        Infinity,
        0.1025,
        Infinity,
        Infinity,
        0.19,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        0.2065,
        0.1298,
        Infinity,
        0.1959,
        Infinity,
        0.1621,
        0.1959,
        Infinity,
        0.1796,
        0.1253,
        Infinity,
        Infinity,
        Infinity,
        0.2459,
        0.2858,
        Infinity,
        Infinity,
        Infinity,
        0.2218,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        0.1936,
        Infinity,
        Infinity,
        0.1669,
        0.1925,
        Infinity,
        Infinity,
        0.2131,
        Infinity,
        0.0819,
        Infinity,
        0.1042,
        0.1691,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        0.1592,
        0.2665,
        0.0958,
        Infinity,
        Infinity,
        0.2188,
        Infinity,
        0.0873,
        Infinity,
        Infinity,
        0.1741,
        Infinity,
        Infinity,
        0.1743,
        Infinity,
        0.1925,
        Infinity,
        0.2133,
        Infinity,
        0.205,
        Infinity,
        Infinity,
        Infinity,
        Infinity,
        0.251,
        0.2532,
        0.1116,
        0.1928,
        Infinity,
        0.2578,
        Infinity,
        Infinity,
        0.1758,
        Infinity,
        Infinity,
        0.1467,
        0.1494,
        0.2292,
        0.2307,
        Infinity,
        Infinity,
        Infinity,
        0.1944,
        0.2293,
        0.2064,
        0.1992,
        Infinity,
        0.1699,
        Infinity,
        0.1882,
        0.2853,
        0.189,
        Infinity,
        Infinity,
        0.1558,
        0.1322,
        Infinity,
        0.2442,
        Infinity,
        0.1134,
        Infinity,
        0.0966,
        Infinity,
        Infinity,
        0.089,
        0.1546,
        Infinity,
        0.2531,
        0.0987,
        0.1895,
        null,
        null,
        null,
        null,
        null,
        0.2348,
        0.2336,
        null,
        null,
        0.2134,
        null,
        0.2965,
        0.4344,
        0.2125,
        0.4137,
        0.6785,
        0.2679,
        0.2007,
        0.3519,
        0.2539,
        0.4745,
        0.4643,
        0.304,
        0.3856,
        0.2561,
        0.1328,
        0.3632,
        0.428,
        0.1668,
        0.2828,
        0.2391,
        0.4149,
        0.1751,
        0.1282,
        0.3489,
        0.4218,
        0.2846,
        0.5242,
        0.5669,
        0.2756,
        0.3066,
        0.207,
        0.1488,
        0.4989,
        0.3601,
        0.1538,
        0.5816,
        0.4823,
        0.32,
        0.551,
        0.3617,
        0.1483,
        0.3258,
        0.3428,
        0.1757,
        0.5022,
        0.323,
        0.2209,
        0.3801,
        0.5108,
        0.6908,
        0.4408,
        0.325,
        0.4623,
        0.369,
        0.4427,
        0.4377,
        0.1655,
        0.1828,
        0.1013,
        0.3778,
        0.5924,
        0.161,
        0.5633,
        0.2376,
        0.2884,
        0.3099,
        0.1568,
        0.6336,
        0.5868,
        0.5221,
        0.2419,
        0.443,
        0.1905,
        0.4572,
        0.4945,
        0.6797,
        0.5371,
        0.4244,
        0.4095,
        0.5719,
        0.0984,
        0.401,
        0.1107,
        0.3215,
        0.3956,
        0.2554,
        0.4072,
        0.6851,
        0.2223,
        0.396,
        0.2922,
        0.1615,
        0.2004,
        0.1824,
        0.2318,
        0.2278,
        0.4241,
        0.5803,
        0.2481,
        0.3764,
        0.172,
        0.1926,
        0.2127,
        0.2292,
        0.1698,
        0.0949,
        0.0367,
        0.3248,
        0.2378,
        0.2322,
        0.2433,
        0.2319,
        0.1095,
        0.0101,
        0.1802,
        0.2165,
        0.2018,
        0.1509,
        0.0623,
        0.0458,
        0.1615,
        0.0963,
        0.2749,
        0.1091,
        0.1759,
        0.2068,
        0.155,
        0.1984,
        0.09,
        0.1398,
        0.096,
        0.1493,
        0.0694,
        0.1667,
        0.0964,
        0.2006,
        0.1696,
        0.1828,
        0.1605,
        0.0985,
        0.2414,
        0.2011,
        0.0796,
        0.1746,
        0.1511,
        0.126,
        0.1586,
        0.171,
        0.1836,
        0.0136,
        0.196,
        0.1218,
        0.3314,
        0.0471,
        0.1613,
        0.1639,
        0.0844,
        0.12,
        0.2073,
        0.1326,
        0.1339,
        0.1419,
        0.1811,
        0.1439,
        0.094,
        0.0482,
        0.1308,
        0.1462,
        0.0689,
        0.1452,
        0.1281,
        0.3828,
        0.2082,
        0.0778,
        0.1129,
        0.1109,
        0.1571,
        0.1281,
        0.1525,
        0.1951,
        0.1358,
        0.2511,
        0.2308,
        0.1212,
        0.2184,
        0.0264,
        0.2312,
        0.107,
        0.0878,
        0.1241,
        0.3523,
        0.1479,
        0.1243,
        0.3248,
        0.1485,
        0.0467,
        0.1375,
        0.0601,
        0.1007,
        0.1324,
        0.108,
        0.3175,
        0.1127,
        0.127,
        0.2863,
        0.1934,
        0.201,
        0.0832,
        0.1676,
        0.1885,
        0.0904,
        0.3193,
        0.203,
        0.1458,
        0.1882,
        0.1446,
        0.2602,
        0.1609,
        0.0958,
        0.2067,
        0.1302,
        0.0694,
        0.1635,
        0.1899,
        0.2082,
        0.265,
        0.2051,
        0.2109,
        0.1056,
        0.2648,
        0.1945,
        0.2198,
        0.1441,
        0.1351,
        0.0671,
        0.076,
        0.1081,
        0.2203,
        0.1619,
        0.211,
        0.2903,
        0.2236,
        0.1986,
        0.4666,
        0.2602,
        0.184,
        0.3382,
        0.1994,
        0.141,
        0.2101,
        0.214,
        0.2299,
        null,
        0.0643,
        0.2387,
        0.2793,
        0.1987,
        0.264,
        0.0309,
        null,
        0.1872,
        0.0676,
        0.526,
        0.1385,
        0.1895,
        0.1309,
        0.1559,
        0.102,
        0.1414,
        0.5296,
        0.5036,
        0.2321,
        0.3508,
        0.2834,
        0.0793,
        0.1812,
        0.1657,
        0.1632,
        0.2522,
        0.3481,
        0.1186,
        0.1794,
        0.0098,
        0.2193,
        0.1826,
        0.5302,
        0.3085,
        0.1373,
        0.3167,
        0.1283,
        0.256,
        0.1004,
        0.266,
        0.389,
        0.1076,
        0.198,
        0.1739,
        0.446,
        0.1112,
        0.448,
        0.49,
        0.433,
        0.2127,
        0.2759,
        0.197,
        0.331,
        null,
        0.1708,
        0.1591,
        0.0964,
        null,
        null,
        0.0798,
        0.1936,
        0.121,
        0.1149,
        0.0652,
        0.1343,
        0.197,
        0.068,
        0.327,
        0.171,
        0.364,
        0.193,
        0.201,
        0.152,
        0.337,
        0.286,
        0.181,
        0.409,
        0.121,
        0.171,
        0.227,
        0.154,
        0.424,
        0.0775,
        0.1665,
        0.2164,
        0.2897,
        0.1779,
        0.1877,
        0.2209,
        0.1087,
        0.2567,
        0.0938,
        0.1349,
        0.0768,
        0.1551,
        0.1584,
        0.2679,
        0.0143,
        0.2235,
        0.0954,
        0.1544,
        0.2156,
        0.1365,
        0.2717,
        0.1423,
        0.228,
        0.0712,
        0.1134,
        0.2327,
        0.1441,
        0.1401,
        0.2182,
        0.0884,
        0.1366,
        0.1933,
        0.2383,
        0.0461,
        0.0982,
        0.1978,
        0.2212,
        0.0769,
        0.2331,
        0.2578,
        0.1841,
        0.1141,
        0.1511,
        0.6637,
        0.2883,
        0.7472,
        0.47,
        0.4178,
        0.288,
        0.4882,
        0.7395,
        0.4826,
        0.7165,
        0.5663,
        0.3893,
        0.4446,
        0.0845,
        0.2935,
        0.5597,
        0.1893,
        0.2812,
        0.3357,
        0.27,
        0.3656,
        0.1844,
        0.0943,
        0.469,
        0.4356,
        0.398,
        0.2873,
        0.1298,
        0.3582,
        0.6247,
        0.2205,
        0.3128,
        0.1438,
        0.357,
        0.401,
        0.138,
        0.021,
        0.124,
        0.196,
        0.329,
        0.226,
        0.198,
        0.396,
        0.271,
        0.247,
        0.31,
        0.145,
        0.217,
        0.279,
        0.209,
        0.143,
        0.238,
        0.097,
        0.402,
        0.132,
        0.154,
        0.214,
        0.09,
        0.23,
        0.228,
        0.097,
        0.323,
        0.185,
        0.353,
        0.402,
        0.483,
        0.415,
        0.27,
        0.323,
        0.194,
        0.304,
        0.143,
        0.134,
        0.508,
        0.139,
        0.183,
        0.273,
        0.224,
        0.206,
        0.073,
        0.237,
        0.259,
        0.197,
        0.253,
        0.05,
        null,
        0.262,
        0.334,
        0.246,
        0.146,
        0.238,
        0.413,
        0.113,
        0.268,
        0.21,
        0.216,
        0.219,
        0.216,
        null,
        0.4711,
        0.1867,
        0.3859,
        0.0803,
        0.1819,
        0.1874,
        0.246,
        0.2611,
        0.3768,
        0.1955,
        0.1691,
        0.2104,
        0.4457,
        0.1639,
        0.1597,
        0.1253,
        0.2421,
        0.5066,
        0.2248,
        0.2242,
        0.1859,
        0.2692,
        0.2204,
        0.1516,
        0.0941,
        0.2369,
        0.2768,
        0.1895,
        0.5255,
        0.2058,
        0.17,
        0.1538,
        0.0977,
        0.4787,
        0.1051,
        0.3889,
        0.1817,
        0.1763,
        0.2804,
        0.3147,
        0.1321,
        0.447,
        0.3884,
        0.1003,
        0.155,
        0.2015,
        0.2073,
        0.216,
        0.118,
        0.2823,
        0.1536,
        0.1464,
        0.1891,
        0.2771,
        0.4564,
        0.0658,
        0.1349,
        0.0978,
        0.2444,
        0.0774,
        0.2631,
        0.3188,
        0.2051,
        0.3731,
        0.2147,
        0.2384,
        0.0686,
        0.3131,
        0.0041,
        0.2393,
        0.1199,
        0.0393,
        0.2853,
        0.5976,
        0.1375,
        0.1589,
        0.2445,
        0.2547,
        0.2599,
        0.7189,
        0.2217,
        0.1298,
        0.1321,
        0.2007,
        0.164,
        0.1837,
        0.1946,
        0.1815,
        0.1238,
        0.2775,
        0.1096,
        0.1547,
        0.126,
        0.1046,
        0.2372,
        0.184,
        0.385,
        0.235,
        0.421,
        0.146,
        0.163,
        0.1995,
        0.2279,
        0.1822,
        0.1317,
        0.1438,
        0.1106,
        0.1054,
        0.0629,
        0.154,
        0.0482,
        0.0496,
        0.2005,
        0.0482,
        0.0382,
        0.1349,
        0.1681,
        0.0276,
        0.0771,
        0.079,
        0.0671,
        0.0559,
        0.1952,
        0.0527,
        0.0556,
        0.069,
        0.0772,
        0.067,
        0.1898,
        0.0973,
        0.0586,
        0.1396,
        0.2861,
        0.0277,
        0.1564,
        0.0687,
        0.0999,
        0.002,
        0.1371,
        0.0448,
        0.1034,
        0.1166,
        0.0497,
        null,
        0.0396,
        0.0885,
        0.0626,
        0.0999,
        0.0648,
        0.0596,
        0.0663,
        0.1886,
        0.0996,
        0.1404,
        0.0329,
        0.1726,
        0.0258,
        0.0758,
        0.061,
        0.1351,
        0.1182,
        0.08,
        0.0651,
        0.1029,
        0.3311,
        0.1374,
        0.2191,
        0.1121,
        0.2179,
        0.03,
        0.0846,
        0.2024,
        0.1381,
        0.1225,
        0.0265,
        0.0493,
        0.1682,
        0.1524,
        0.2242,
        0.0749,
        0.1141,
        0.2022,
        0.0723,
        0.1315,
        0.1034,
        0.202,
        0.0888,
        0.1833,
        0.0938,
        0.0942,
        0.1067,
        0.0778,
        0.0935,
        0.1033,
        0.284,
        0.0914,
        0.0847,
        0.0746,
        0.1955,
        0.24,
        0.2954,
        0.167,
        0.09,
        0.1483,
        0.1455,
        0.1446,
        0.08,
        0.133,
        0.3379,
        0.146,
        0.11,
        0.0746,
        0.1366,
        0.1306,
        0.1962,
        0.2105,
        0.2877,
        0.1899,
        0.2189,
        0.0756,
        0.0216,
        0.2545,
        0.3379,
        0.319,
        0.2255,
        0.3441,
        0.2431,
        0.3399,
        0.1615,
        0.3099,
        0.3481,
        0.1836,
        0.2429,
        0.3996,
        0.1969,
        0.2991,
        0.2355,
        0.1224,
        0.2605,
        0.3189,
        0.1914,
        0.2463,
        0.1496,
        0.1887,
        0.2207,
        0.306,
        0.2056,
        0.2008,
        0.217,
        0.2982,
        0.0769,
        0.1857,
        0.3431,
        0.194,
        0.1897,
        0.2853,
        0.2813,
        0.4334,
        0.1551,
        0.279,
        0.1249,
        0.5029,
        0.1496,
        0.6918,
        0.1928,
        0.3629,
        0.1501,
        0.4642,
        0.1921,
        0.0988,
        0.2836,
        0.1433,
        0.2065,
        0.2766,
        0.3341,
        0.1692,
        0.1976,
        0.1509,
        0.3521,
        0.2334,
        0.2306,
        0.1869,
        0.1689,
        0.0011,
        0.2718,
        0.1265,
        0.0999,
        0.2075,
        0.2328,
        0.2436,
        0.3917,
        0.1762,
        0.1514,
        0.2312,
        0.3217,
        0.158,
        0.28,
        0.2332,
        0.1453,
        0.29,
        0.2213,
        0.104,
        0.1885,
        0.1759,
        0.1679,
        0.1409,
        0.0904,
        0.0735,
        0.2052,
        0.3904,
        0.1292,
        0.1842,
        0.1752,
        0.0621,
        0.1495,
        0.2263,
        0.3454,
        0.1025,
        0.1462,
        0.4632,
        0.2273,
        0.2169,
        0.6505,
        0.1946,
        0.153,
        0.1917,
        0.4481,
        0.2398,
        0.2509,
        0.3107,
        0.3524,
        0.3429,
        0.2283,
        0.4362,
        0.2246,
        0.126,
        0.6904,
        0.2542,
        0.2033,
        0.4241,
        0.2119,
        0.2746,
        0.2187,
        0.5585,
        0.0687,
        0.3552,
        0.2825,
        0.2522,
        0.1746,
        0.2584,
        0.261,
        0.3453,
        0.2494,
        0.0987,
        0.1286,
        0.555,
        0.4678,
        0.3625,
        0.4207,
        0.4032,
        0.6383,
        0.4642,
        0.285,
        0.3885,
        0.0929,
        0.5284,
        0.3209,
        0.7769,
        0.3645,
        0.224,
        0.0932,
        0.6184,
        0.3139,
        0.2203,
        0.2364,
        0.3912,
        0.2305,
        0.4539,
        0.3035,
        0.4803,
        0.4119,
        0.1814,
        0.4067,
        0.4923,
        0.0892,
        0.3924,
        0.2727,
        0.522,
        0.4202,
        0.4267,
        0.6285,
        0.2964,
        0.2664,
        0.2611,
        0.3051,
        0.6522,
        0.1501,
        0.4157,
        0.4784,
        0.3913,
        0.6722,
        0.199,
        0.4153,
        0.4249,
        0.2488,
        0.2283,
        0.4419,
        0.3857,
        0.4528,
        0.3269,
        0.3519,
        0.5448,
        0.3057,
        0.4165,
        0.245,
        0.3878,
        0.3326,
        0.2447,
        0.2226,
        0.1213,
        0.1556,
        0.1445,
        0.5632,
        0.0685,
        0.0966,
        0.1348,
        0.2026,
        0.2603,
        0.2424,
        0.1263,
        0.0961,
        0.2461,
        0.1775,
        0.2229,
        0.2446,
        0.6915,
        0.1403,
        0.3775,
        0.2265,
        0.4302,
        0.2919,
        0.1203,
        0.3968,
        0.1538,
        0.3344,
        0.0887,
        0.0859,
        0.1806,
        0.27,
        0.4248,
        0.3215,
        0.1284,
        0.4428,
        0.2149,
        0.1894,
        0.2355,
        0.199,
        0.2089,
        0.2202,
        0.2217,
        0.1286,
        0.3068,
        0.3413,
        0.2108,
        0.2034,
        0.2054,
        0.1497,
        0.0675,
        0.5067,
        0.4761,
        0.3307,
        0.1158,
        0.2012,
        0.2167,
        0.3229,
        0.2408,
        0.1487,
        0.1166,
        0.2816,
        0.1897,
        0.193,
        0.3442,
        0.0687,
        0.2654,
        0.219,
        0.0463,
        0.4506,
        0.216,
        0.127,
        0.2895,
        0.0852,
        0.4009,
        0.0457,
        0.4299,
        0.2114,
        0.3363,
        0.145,
        0.3565,
        0.1624,
        0.297,
        0.578,
        0.2553,
        0.2308,
        0.2336,
        0.0724,
        0.2729,
        0.5347,
        0.4464,
        0.0687,
        0.3958,
        0.081,
        0.4252,
        0.1021,
        0.1268,
        0.2722,
        0.0377,
        0.0965,
        0.434,
        0.3323,
        0.1962,
        0.1746,
        0.2073,
        0.0554,
        0.9654,
        0.0422,
        0.3926,
        0.2652,
        0.1036,
        0.1126,
        0.141,
        0.1527,
        0.103,
        0.1068,
        0.2589,
        0.1445,
        0.2127,
        0.3301,
        0.1237,
        0.1222,
        0.2879,
        0.2233,
        0.2741,
        0.1128,
        0.1078,
        0.2715,
        0.1804,
        0.1458,
        0.1445,
        0.3487,
        0.1253,
        0.1782,
        0.0926,
        0.1951,
        0.2411,
        0.1509,
        0.1344,
        0.3925,
        0.7147,
        0.1526,
        0.3042,
        0.2146,
        0.4726,
        0.0571,
        0.0993,
        0.1555,
        0.0809,
        0.1218,
        0.3235,
        0.0992,
        0.1678,
        0.0535,
        0.1537,
        0.5947,
        0.3156,
        0.2586,
        0.3412,
        0.3819,
        0.1352,
        0.1146,
        0.1944,
        0.1744,
        0.2265,
        0.0961,
        0.2662,
        0.1538,
        0.2321,
        0.0267,
        0.1501,
        0.0687,
        0.029,
        null,
        null,
        null,
        null,
        null,
        null,
    ],
    sru_target: [
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.25,
        0.2,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.2,
        0.2,
        0.2,
        0.2,
        0.2,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
        0.25,
    ],
    exempted: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        true,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        true,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        true,
        true,
        false,
        false,
        false,
        true,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        true,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        true,
        false,
        true,
        false,
        true,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
    ]
}



export const get_sru = (inseecode) => {
    var result_data = {
        nb_log: null,
        sru_current: null,
        sru_target: null,
        exempted: null
    }

    // Check validity
    if (inseecode === null || inseecode === undefined) {
        return result_data
    }

    // Check arrondissements
    if (inseecode.indexOf("751") === 0) { // PARIS
        inseecode = "75056";
    }
    if (inseecode.indexOf("6938") === 0) { // LYON
        inseecode = "69123";
    }
    if (inseecode.indexOf("132") === 0) { // MARSEILLE
        inseecode = "13055";
    }
    // Get index
    var index = sru_data.code.indexOf(inseecode);

    if (index > -1) {
        result_data.nb_log = sru_data.nb_log[index];
        result_data.sru_current = sru_data.sru_current[index];
        result_data.sru_target = sru_data.sru_target[index];
        result_data.exempted = sru_data.exempted[index];
    }

    return result_data
}