// Import dependencies
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


// Import CSS & assets
import './Auth.css';
import logo from '../../../assets/logo.svg';

// Import components
import AuthForm from '../../../components/auth/auth/AuthForm';

const Auth = ({version, environment}) => {

    // Set global functions
    const navigate = useNavigate();

    // Set hooks
    const [searchParams, setSearchParams] = useSearchParams();
    const [isBodyVisible, setIsBodyVisible] = useState(false);

    // Set specific functions
    // Get predefinedType
    function getPredifinedType() {
        if (searchParams.get("type") === "signin") {
            return "signIn";
        }
        else if (searchParams.get("type") === "forget") {
            return "resetPassword";
        }
        else {
            return "signUp";
        }
    }
    function getSource() {
        return searchParams.get("source");
    }
    function getCause() {
        return searchParams.get("cause");
    }
    function getOrigin() {
        return searchParams.get("origin");
    }
    const handleBackHome = () => {
        setIsBodyVisible(false);
        setTimeout(() => {
            navigate('/' + (getOrigin() !== null ? '?origin=' + getOrigin() : ''));
        }, 1000);
    }
    // Show body after 1second
    useEffect(() => {
        setTimeout(() => {
            setIsBodyVisible(true);
        }, 100);
    }, []);

    // RETURN
    return (
        <div className='auth__auth'>
            <div className='auth__header'>
                <div className="auth__header-container">
                    <div className="auth__header-logo" onClick={handleBackHome}>
                        <div className="auth__header-logo_box">
                            <img src={logo} alt='logo' />
                        </div>
                    </div>
                    <div className="auth__header-close">
                        {/* X */}
                    </div>
                </div>
            </div>
            <div className={`auth__body_container ${isBodyVisible ? 'auth__body_container_visible' : 'auth__body_container_hidden'}`}>
                {getCause() === "expired" &&
                    <div id="auth__cause" className="auth__cause"><div className="auth__cause_ico">😞</div><div className="auth__cause_text">Votre session a expiré, veuillez vous reconnecter.</div></div>
                }
                <AuthForm predefinedType={getPredifinedType()} source={getSource()} origin={getOrigin()} />
                {environment !== "PROD" &&
                    <div className="auth__footer">{version}<br></br>{environment}</div>
                }
            </div>
        </div>
    );
};

export default Auth;