import React, { useState, useEffect } from "react";
import { DragAndDrop, Drag, Drop } from "../../../../helpers/dnd";
import { reorder } from "../../../../helpers/dnd/Dnd_helpers";

// Import components
import Title from './Title';
import Rule from './Rule';
import Document from './Document';
import FileGroup from './FileGroup';

// Import external functions
import * as nodeHelpers from '../../ruleEditor_elements/helpers/NodeHelpers';
import * as rulesHelpers from '../../ruleEditor_elements/helpers/RulesHelpers';

// Import CSS & assets
import './List.css';
import ico_file from '../../../../assets/ico/ico_file.svg';
import ico_file_white from '../../../../assets/ico/ico_file_white.svg';
import ico_download from '../../../../assets/ico/ico_download.svg';
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';


const List = ({ capacity, setCapacity, mapLayers, setMapLayers, categories, setCategories, ruleCatalog_Open, setRuleUpdateTracker, highlightedRule, setHighlightedRule, mapDrawMode, setMapDrawMode, hiddenRule, setHiddenRule }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }



    useEffect(() => {
        console.log("UPDATED CATEGORIES", categories);
    }, [categories]);
    useEffect(() => {
        console.log("UPDATED RULESET", capacity.rules.ruleset);
    }, [capacity.rules.ruleset]);

    // const [categories, setCategories] = useState([
    //     {
    //         id: "q101",
    //         name: "Category 1",
    //         items: [
    //             { id: "abc", name: "First" },
    //             { id: "def", name: "Second" }
    //         ]
    //     },
    //     {
    //         id: "wkqx",
    //         name: "Category 2",
    //         items: [
    //             { id: "ghi", name: "Third" },
    //             { id: "jkl", name: "Fourth" }
    //         ]
    //     }
    // ]);


    const handleDragEnd = (result) => {
        const { type, source, destination } = result;
        if (!destination) return;

        const sourceCategoryId = source.droppableId;
        const destinationCategoryId = destination.droppableId;
        console.log("sourceCategoryId", sourceCategoryId);
        console.log("destinationCategoryId", destinationCategoryId);

        // Reordering items
        if (type === "droppable-item") {
            // If drag and dropping within the same category
            if (sourceCategoryId === destinationCategoryId) {
                const updatedOrder = reorder(
                    capacity.rules.ruleset.find((ruleGroup) => ruleGroup.id === sourceCategoryId).items,
                    source.index,
                    destination.index
                );
                const updatedCategories = capacity.rules.ruleset.map((ruleGroup) =>
                    ruleGroup.id !== sourceCategoryId
                        ? ruleGroup
                        : { ...ruleGroup, items: updatedOrder }
                );
                console.log("updatedCategories", updatedCategories);
                // setCategories(updatedCategories);

                // Update capacity
                var rules = capacity.rules;
                rules.ruleset = updatedCategories;
                // Set numerotation
                rules = rulesHelpers.getRulesNumerotation(rules);
                setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "", value: "" } });
                setRuleUpdateTracker((Date.now()).toString());

            } else {
                const sourceOrder = capacity.rules.ruleset.find(
                    (category) => category.id === sourceCategoryId
                ).items;
                const destinationOrder = capacity.rules.ruleset.find(
                    (category) => category.id === destinationCategoryId
                ).items;

                const [removed] = sourceOrder.splice(source.index, 1);
                destinationOrder.splice(destination.index, 0, removed);

                destinationOrder[removed] = sourceOrder[removed];
                delete sourceOrder[removed];

                const updatedCategories = capacity.rules.ruleset.map((category) =>
                    category.id === sourceCategoryId
                        ? { ...category, items: sourceOrder }
                        : category.id === destinationCategoryId
                            ? { ...category, items: destinationOrder }
                            : category
                );
                console.log("updatedCategories", updatedCategories);
                // setCategories(updatedCategories);
                // Update capacity
                var rules = capacity.rules;
                rules.ruleset = updatedCategories;
                // Set numerotation
                rules = rulesHelpers.getRulesNumerotation(rules);
                setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "", value: "" } });
                setRuleUpdateTracker((Date.now()).toString());
            }
        }

        // Reordering categories
        if (type === "droppable-category") {
            const updatedCategories = reorder(
                capacity.rules.ruleset,
                source.index,
                destination.index
            );
            console.log("updatedCategories", updatedCategories);
            // setCategories(updatedCategories);
            // Update capacity
            var rules = capacity.rules;
            rules.ruleset = updatedCategories;
            // Set numerotation
            rules = rulesHelpers.getRulesNumerotation(rules);
            setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "", value: "" } });
            setRuleUpdateTracker((Date.now()).toString());
        }
    };

    // __________ RULEGROUPS
    const ruleGroup_Create = () => {
        console.log("CREATE RULE GROUP");
        // Creat new element model
        var documentNew = {
            id: "ruleGroup_" + (Date.now()).toString(),
            name: "Nouvel ensemble de règles",
            items: []
        }
        // setCategories(prevValue => [...prevValue, documentNew]);
        // Get current rules
        var rules = capacity.rules;
        // Add new ruleGroup
        rules.ruleset.push(documentNew);
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: "Création d'un nouvel ensemble de règles", value: "" } });
    }

    const ruleGroup_Update = (ruleGroup_Id, ruleGroup_Name, action, value) => {
        // Get current rules
        var rules = capacity.rules;
        // Update ruleset
        rules.ruleset.forEach(ruleGroup => {
            if (ruleGroup.id === ruleGroup_Id) {
                ruleGroup.name = ruleGroup_Name;
            }

        })
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: action, value: value } });
    }

    const ruleGroup_Delete = (ruleGroup_Id) => {
        console.log("DELETE RULE GROUP", ruleGroup_Id);
        var value = "";
        // Get current rules
        var rules = capacity.rules;
        // Get current ruleset
        var ruleset = rules.ruleset;
        // Create new ruleset
        var rulesetNew = [];
        ruleset.forEach(ruleGroup => {
            if (ruleGroup.id !== ruleGroup_Id) {
                rulesetNew.push(ruleGroup);
            }
            else {
                value = ruleGroup.name;
            }
        })
        // Update rules
        rules.ruleset = rulesetNew;
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "Suppression de l'ensemble de règles intitulé", value: value } });
        setRuleUpdateTracker((Date.now()).toString());
    }


    // __________ TITLES
    const title_Create = (documentId) => {
        console.log("CREATE TITLE", documentId);
        // Create new element model
        var titleNew = { id: (Date.now()).toString(), name: "Nouveau titre", type: "title" };
        // Get current rules
        var rules = capacity.rules;
        // Get current ruleset
        var ruleset = rules.ruleset;
        // Add title
        ruleset.forEach(ruleGroup => {
            if (ruleGroup.id === documentId) {
                ruleGroup.items.push(titleNew);
            }
        })
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: "", value: "" } });
    }

    const title_Update = (title_Id, title_name) => {
        // Get current rules
        var rules = capacity.rules;
        // Search title
        for (var i = 0; i < rules.ruleset.length; i++) {
            for (var j = 0; j < rules.ruleset[i].items.length; j++) {
                if (rules.ruleset[i].items[j].id === title_Id) {
                    rules.ruleset[i].items[j].name = title_name;
                    break;
                }
            }
        }
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: "", value: "" } });
    }

    const title_Delete = (title_Id) => {
        console.log("DELETE TITLE", title_Id);
        // Get current rules
        var rules = capacity.rules;
        // Search title
        for (var i = 0; i < rules.ruleset.length; i++) {
            var itemsNew = [];
            for (var j = 0; j < rules.ruleset[i].items.length; j++) {
                if (rules.ruleset[i].items[j].id !== title_Id) {
                    itemsNew.push(rules.ruleset[i].items[j]);
                }
            }
            rules.ruleset[i].items = itemsNew;
        }
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: "", value: "" } });
    }

    const title_OpenClose = (title_Id, type) => {
        console.log("TITLE OPENCLOSE", title_Id, type);
        // Get rules insadie title
        var rules_inside = [];
        var is_inside = false;
        var rules = capacity.rules;
        for (var i = 0; i < rules.ruleset.length; i++) {
            for (var j = 0; j < rules.ruleset[i].items.length; j++) {
                if (is_inside === true && rules.ruleset[i].items[j].type === "rule") {
                    rules_inside.push(rules.ruleset[i].items[j]);
                    console.log("item_", rules.ruleset[i].items[j].id);
                    var element = document.querySelectorAll('[data-rbd-draggable-id="item_' + rules.ruleset[i].items[j].id + '"]');
                    console.log("element", element);
                    if (element.length > 0) {
                        if (type === false) {
                            element[0].classList.add("app__ruleset_rule_container_hidden");
                        }
                        else {
                            element[0].classList.remove("app__ruleset_rule_container_hidden");
                        }
                    }
                }
                else {
                    is_inside = false;
                }

                if (rules.ruleset[i].items[j].id === title_Id) {
                    is_inside = true;
                }
            }
        }

        var element_info = document.getElementById("title_info__" + title_Id);
        if (element_info) {
            if (type === false) {
                if (rules_inside.length > 1) {
                    element_info.innerText = rules_inside.length + " règles";
                }
                else if (rules_inside.length === 1) {
                    element_info.innerText = "1 règle";
                }
                else {
                    element_info.innerText = "";
                }
            }
            else {
                element_info.innerText = "";
            }
        }
        console.log("rules_inside", rules_inside);

    }

    // __________ RULES
    const rule_Create = (documentId) => {
        console.log("CREATE RULE", documentId);
        var ruleNew = { id: (Date.now()).toString(), name: "Nouvelle règle", type: "rule" };
        var prevValue = [...categories];
        console.log("prevValue", prevValue);
        prevValue.forEach(category => {
            if (category.id === documentId) {
                category.items.push(ruleNew);
            }
        });
        setCategories(prevValue);
    }

    const rule_Update = (ruleId, newRuleData, newRuleInterpreted) => {
        console.log("UPDATE RULE", ruleId);
        // Get current rules
        var rules = { ...capacity.rules };
        var ruleName = "";
        var variables_text = "";
        // Search title
        for (var i = 0; i < rules.ruleset.length; i++) {
            for (var j = 0; j < rules.ruleset[i].items.length; j++) {
                if (rules.ruleset[i].items[j].id === ruleId) {
                    rules.ruleset[i].items[j].data = { ...newRuleData };
                    if (newRuleInterpreted !== null) {
                        rules.ruleset[i].items[j].interpreted = newRuleInterpreted;
                    }
                    ruleName = rules.ruleset[i].items[j].name;
                    // Add variables
                    variables_text = " (";
                    var variables = rules.ruleset[i].items[j].data.variables_description.concat(rules.ruleset[i].items[j].data.variables_input);
                    variables.forEach((variable, variable_index) => {
                        if (variable_index > 0 && variable_index === variables.length - 1) { variables_text = variables_text + " et " }
                        else if (variable_index > 0) { variables_text = variables_text + ", " }
                        variables_text = variables_text + variable.label + " = " + variable.value
                    })
                    variables_text = variables_text + ")";
                    break;
                }
            }
        }
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        console.log("new rules", rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "Mise à jour des valeurs de la règle", value: ruleName + variables_text, keys: ["rules"] } });
        // setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "", value: "" } });
        setRuleUpdateTracker((Date.now()).toString());
    }

    const rule_Delete = (rule_Id) => {
        console.log("DELETE RULE", rule_Id);
        var ruleTitle = "";
        // Get current rules
        var rules = capacity.rules;
        // Search title
        for (var i = 0; i < rules.ruleset.length; i++) {
            var itemsNew = [];
            for (var j = 0; j < rules.ruleset[i].items.length; j++) {
                if (rules.ruleset[i].items[j].id !== rule_Id) {
                    itemsNew.push(rules.ruleset[i].items[j]);
                }
                else {
                    ruleTitle = rules.ruleset[i].items[j].name;
                }
            }
            rules.ruleset[i].items = itemsNew;
        }
        // Set numerotation
        rules = rulesHelpers.getRulesNumerotation(rules);
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "Suppression de la règle", value: ruleTitle, keys: ["rules"] } });
        setRuleUpdateTracker((Date.now()).toString());
    }

    const condition_Delete = (ruleId) => {
        console.log("REMOVE CONDITION to " + ruleId);
        // Get current rules
        var rules = capacity.rules;
        // Get current ruleset
        var ruleset = rules.ruleset;
        // Search rule
        var ruleTitle = "";
        ruleset.forEach(ruleGroup => {
            ruleGroup.items.forEach(item => {
                if (item.id === ruleId) {
                    ruleTitle = item.name;
                    // Create params if does not exists
                    if (!item?.data?.params) {
                        item.data.params = {
                            "condition": {
                                "value": false,
                                "data": null
                            },
                            "perimeter": {
                                "value": false,
                                "data": null
                            }
                        }
                    }
                    // Update params
                    item.data.params.condition = {
                        value: true,
                        data: null
                    }
                    // Re interpret rule
                    item.interpreted = nodeHelpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capacity, item?.data?.params?.perimeter);
                }
            })
        })
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "Suppression de la condition d'application de la règle", value: ruleTitle, keys: ["rules"] } });
        setRuleUpdateTracker((Date.now()).toString());
    }

    const perimeter_Delete = (ruleId) => {
        console.log("REMOVE PERIMETER to " + ruleId);
        // Get current rules
        var rules = capacity.rules;
        // Get current ruleset
        var ruleset = rules.ruleset;
        // Search rule
        var ruleTitle = "";
        ruleset.forEach(ruleGroup => {
            ruleGroup.items.forEach(item => {
                if (item.id === ruleId) {
                    ruleTitle = item.name;
                    // Create params if does not exists
                    if (!item?.data?.params) {
                        item.data.params = {
                            "condition": {
                                "value": false,
                                "data": null
                            },
                            "perimeter": {
                                "value": false,
                                "data": null
                            }
                        }
                    }
                    // Update params
                    item.data.params.perimeter = {
                        value: true,
                        data: null
                    }
                    // Re interpret rule
                    if (item?.data?.params?.condition?.data?.data?.interpreted === false) {
                        item.interpreted = [];
                    }
                    else {
                        item.interpreted = nodeHelpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capacity);
                    }
                }
            })
        })
        // Update capacity
        setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "Suppression du périmètre d'application de la règle", value: ruleTitle, keys: ["rules"] } });
        setRuleUpdateTracker((Date.now()).toString());
    }



    return (
        <>
            {/* GPU */}
            {capacity?.rules?.gpu_data && capacity?.rules?.gpu_data !== "fetching" && capacity?.rules?.gpu_data?.document && capacity?.rules?.gpu_data?.document !== "error" && capacity?.rules?.gpu_data?.zoneUrba !== "error" &&
                <>
                    <div className="app__sectiondata_widget_title">Référencement du terrain</div>
                    <div className="app__sectiondata__widget">
                        <div className="app__sectiondata_widget_title">Zonage PLU</div>
                        <div className="app__sectiondata_widget_value_container">
                            <div className="app__ruleset_document_title" style={{ marginBottom: "4px" }}>
                                {/* <div className="app__sectiondata_widget_value_text_xsmall" style={{ marginBottom: "4px" }}> */}
                                {capacity?.rules?.gpu_data?.zoneUrba.length === 1 &&
                                    <>
                                        Terrain classé en zone {capacity?.rules?.gpu_data?.zoneUrba[0]?.properties?.libelle}
                                    </>
                                }
                                {capacity?.rules?.gpu_data?.zoneUrba.length > 1 &&
                                    <>
                                        Terrain classé en zones{capacity?.rules?.gpu_data?.zoneUrba.map((zonePLU, index) => {
                                            var prefix = ", ";
                                            if (index === 0) { prefix = " " }
                                            else if (index === capacity?.rules?.gpu_data?.zoneUrba.length - 1) { prefix = " et " }
                                            return (
                                                <span key={"span_" + index}>
                                                    {prefix}<span style={{ fontSize: "18px", fontWeight: "800" }}>{zonePLU?.properties?.libelle}</span>
                                                </span>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                            {capacity?.rules?.gpu_data?.zoneUrba.length === 0 &&
                                <div className="app__sectiondata_widget_value_legend" style={{ textTransform: "capitalize" }}>{capacity?.rules?.gpu_data?.zoneUrba[0]?.properties?.libelong}</div>
                            }
                        </div>
                    </div>
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    <div className="app__sectiondata__widget">
                        <div className="app__sectiondata_widget_title">Documents applicables</div>
                        <div className="app__sectiondata_widget_value_container">
                            <div className="app__ruleset_document_title" style={{ marginBottom: "2px" }}>{capacity?.rules?.gpu_data?.document?.title}</div>
                            <div className="" style={{ marginBottom: "4px" }}>Dernière procédure approuvée le {(capacity?.rules?.gpu_data?.document?.originalName).substring((capacity?.rules?.gpu_data?.document?.originalName.length - 2))}/{(capacity?.rules?.gpu_data?.document?.originalName).substring((capacity?.rules?.gpu_data?.document?.originalName.length - 4), (capacity?.rules?.gpu_data?.document?.originalName.length - 2))}/{(capacity?.rules?.gpu_data?.document?.originalName).substring((capacity?.rules?.gpu_data?.document?.originalName.length - 8), (capacity?.rules?.gpu_data?.document?.originalName.length - 4))}</div>
                            <div className="app__land_data_button" style={{ width: "246px" }}><a href={"https://www.geoportail-urbanisme.gouv.fr/document/by-id/" + capacity?.rules?.gpu_data?.document?.id} target="_blanck">Fiche détaillée du Géoportail de l'Urbanisme</a></div>
                        </div>
                        {(capacity?.rules?.gpu_data?.files && capacity.rules.gpu_data.files !== "error" && !Array.isArray(capacity.rules.gpu_data.files) && Object.keys(capacity.rules.gpu_data.files).length > 0) &&
                            <>
                                <div className="app__sectiondata_widget_spacer_20"></div>
                                <div className="app__sectiondata_widget_title_container">
                                    <div className="app__sectiondata_widget_title">Liste des pièces écrites</div>
                                    {window.innerWidth > 550 &&
                                        <a className="app__ruleset_download_container" href={capacity?.rules?.gpu_data?.document?.archiveUrl} target="_blanck">
                                            <div className="app__ruleset_download_text">Télécharger toutes les pièces au format Zip</div>
                                            <div className="app__ruleset_download_ico"><img src={ico_download} alt="ico_download" /></div>
                                        </a>
                                    }
                                </div>
                                {Object.keys(capacity?.rules?.gpu_data?.files).map((file_group, index_group) => {
                                    return (
                                        <div key={"filegroup_" + index_group}>
                                            {index_group > 0 &&
                                                <div className="app__bound_line_container"></div>
                                            }
                                            <FileGroup file_group={file_group} index_group={index_group} files={capacity?.rules?.gpu_data?.files} />
                                        </div>
                                    )
                                })}
                            </>
                        }
                        {/* <div className="app__sectiondata_widget_spacer_10"></div>
                        <a className="app__ruleset_button" style={{ width: "265px" }} href={capacity?.rules?.gpu_data?.document?.archiveUrl} target="_blanck"><div className="app__ruleset_ico"><img src={ico_file_white} alt="ico_file" /></div><div>Télécharger toutes les pièces au format ZIP</div></a> */}
                    </div>
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    <div className="app__sectiondata_widget_info_container">
                        <div className="app__sectiondata_widget_info_text">
                            Source : Géoportail de l'Urbanisme
                        </div>
                        <div className="app__sectiondata_widget_info_icon">i</div>
                    </div>
                    <div className="app__sectiondata_widget_spacer_20"></div>
                </>
            }

            {capacity?.rules?.gpu_data === "fetching" ?
                <div className="app__sectiondata_loading_container_small">
                    <div className="app__sectiondata_loading"></div>
                    <div className="app__sectiondata_loading_text">Nous analysons les données réglementaires sur le Géoportail de l'Urbanisme</div>
                </div>
                :
                <>
                    {/* <div className="app__sectiondata_widget_title">Définition des règles</div> */}
                    <div className="app__sectiondata_widget_title_container">
                        <div className="app__sectiondata_widget_title">Règles d'urbanisme</div>
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_rules" checked={mapLayers.rules.isVisible} onChange={() => { layer_Toggle("rules") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_rules">Toggle</label></div>
                        </div>
                    </div>

                    <DragAndDrop onDragEnd={handleDragEnd}>
                        <Drop id="droppable" type="droppable-category">
                            {capacity.rules.ruleset.map((category, categoryIndex) => {
                                return (
                                    <Drag
                                        className="draggable-category"
                                        key={category.id}
                                        id={category.id + "_drag"}
                                        index={categoryIndex}
                                        type="category"
                                        subtype=""
                                    >
                                        <div key={category.id + "_container"} id={category.id + "_container"} className="category-container">
                                            <Document key={"doc__" + categoryIndex} id={category.id} name={category.name} item={category} title_Create={title_Create} ruleCatalog_Open={ruleCatalog_Open} ruleGroup_Update={ruleGroup_Update} ruleGroup_Delete={ruleGroup_Delete} />

                                            <Drop key={category.id} id={category.id} type="droppable-item" className="test">
                                                {category.items.map((item, index) => {
                                                    return (
                                                        <Drag
                                                            className={`draggable ${item.type === "title" ? "app__ruleset_title_container" : item.type === "rule" ? "app__ruleset_rule_container" : ""} ${(item.type === "rule" && ((item?.interpreted && item?.interpreted.length === 0) || item?.data?.params?.condition?.data?.interpreted === false || (item?.data?.params?.perimeter?.data?.interpreted && item?.data?.params?.perimeter?.data?.interpreted.length === 0))) ? "app__ruleset_rule_container_invalid" : ""}`}
                                                            key={"item_" + item.id}
                                                            id={"item_" + item.id}
                                                            index={index}
                                                            type="item"
                                                            subtype={item.type}
                                                        >
                                                            <div key={"title__" + categoryIndex + "_" + index}>
                                                                {item.type === "title" &&
                                                                    <Title key={"title__" + categoryIndex + "_" + index} rule_id={item.id} name={item.name} item={item} title_Update={title_Update} title_Delete={title_Delete} title_OpenClose={title_OpenClose} />
                                                                }
                                                                {item.type === "rule" &&
                                                                    <Rule key={"rule__" + categoryIndex + "_" + index} rule_id={item.id} name={item.name} data={item.data} ruleCatalog_Open={ruleCatalog_Open} rule_Update={rule_Update} rule_Delete={rule_Delete} condition_Delete={condition_Delete} perimeter_Delete={perimeter_Delete} capacity={capacity} highlightedRule={highlightedRule} setHighlightedRule={setHighlightedRule} rule={item} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} />
                                                                }
                                                            </div>
                                                        </Drag>
                                                    );
                                                })}
                                            </Drop>
                                            {/* <div className="app__ruleset_placeholder_container_box">
                                        <div className="app__ruleset_placeholder_container"><span className="app__ruleset_rule_condition_link" onClick={() => { title_Create(category.id) }}>Ajouter un titre</span></div>
                                        <div className="app__ruleset_placeholder_container"><span className="app__ruleset_rule_condition_link" onClick={() => { ruleCatalog_Open(category.id, "rule") }}>Ajouter une règle</span></div>
                                    </div> */}
                                            <div className="app__ruleset_placeholder_container"><span className="app__ruleset_rule_condition_link" style={{ marginRight: "8px" }} onClick={() => { title_Create(category.id) }}>Ajouter un titre</span> ou <span className="app__ruleset_rule_condition_link" style={{ marginLeft: "8px" }} onClick={() => { ruleCatalog_Open(category.id, "rule") }}>Ajouter une règle</span></div>
                                        </div>
                                    </Drag>
                                );
                            })}
                        </Drop>
                    </DragAndDrop>
                    <div className="app__ruleset_placeholder_container"><span className="app__ruleset_rule_condition_link" onClick={ruleGroup_Create}>Ajouter un ensemble de règles</span></div>
                </>
            }

        </>
    );
};

export default List;