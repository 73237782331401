import React, { useState } from 'react';

// Import CSS & assets
import ico_dots from '../../../../assets/ico/ico_dots.svg';
import ico_close from '../../../../assets/ico/ico_close.svg';
import ico_warning_red from '../../../../assets/ico/ico_warning_red.svg';
import ico_map_layers_highlight from '../../../../assets/ico/ico_map_layers_highlight.svg';

// Import components
import RuleVariable from './RuleVariable';
import RuleResult from './RuleResult';

// Import external functions
import * as nodeHelpers from '../../ruleEditor_elements/helpers/NodeHelpers';


const Rule = ({ rule_id, name, data, ruleCatalog_Open, rule_Update, rule_Delete, condition_Delete, perimeter_Delete, capacity, highlightedRule, setHighlightedRule, rule, mapDrawMode, setMapDrawMode, hiddenRule, setHiddenRule }) => {

    // Document actions
    const [isActions, setIsActions] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleClickDelete = () => {
        setIsActions(false);
        setIsDelete(true);
    }

    // Variable
    const updateVariable = (type, variableLabel, value, valueObject) => {
        console.log("UPDATING " + variableLabel + " to :", value);
        console.log("type", type);
        console.log("data", data);
        console.log("rule", rule);
        console.log("valueObject", valueObject);
        // document.getElementById(variableId).style.width = (value.length * 7.4).toString() + "px";
        // Get current data
        var newData = { ...data };
        var currentData = null;
        if (type === "condition" || type === "perimeter") {
            currentData = { ...data.params[type].data.data };
        }
        else if (type === "description") {
            currentData = { ...data };
        }
        console.log("currentData", currentData);

        // Search matching variable in description part
        var searchString = "";
        for (var i = 0; i < currentData.variables_description.length; i++) {
            if (currentData.variables_description[i].label === variableLabel) {
                // Update variable value
                currentData.variables_description[i].value = value;
                if (valueObject) {
                    currentData.variables_description[i].value = valueObject;
                }
                // Get searchString
                searchString = currentData.variables_description[i].searchString;
                break;
            }
        }
        // Search matching variable in input part
        for (var i = 0; i < currentData.variables_input.length; i++) {
            if (currentData.variables_input[i].label === variableLabel) {
                // Update variable value
                currentData.variables_input[i].value = value;
                if (valueObject) {
                    currentData.variables_input[i].value = valueObject;
                }
                // Get searchString
                searchString = currentData.variables_description[i].searchString;
            }
        }
        // Get variable inside main node value
        var mainNodeIndex = -1;
        var mainNodeValue = "";
        for (var i = 0; i < currentData.composition.nodes.length; i++) {
            if (currentData.composition.nodes[i].type.includes("Node_Rule_") || currentData.composition.nodes[i].type.includes("Node_Condition") || currentData.composition.nodes[i].type.includes("Node_Perimeter")) {
                mainNodeIndex = i;
                mainNodeValue = currentData.composition.nodes[i].data.source[0].value;
                break;
            }
        }
        if (mainNodeIndex >= 0) {
            // Update main node value with new variable value
            var stringValue = value.toString();
            var newMainNodeValue = replaceVariable(mainNodeValue, searchString, stringValue, 0);
            console.log("newMainNodeValue", newMainNodeValue);
            currentData.composition.nodes[mainNodeIndex].data.source[0].value = newMainNodeValue;
        }

        // Interpretation
        var currentData_interpreted = nodeHelpers.nodeInterpretor(currentData.composition.nodes[0].data.source, capacity, newData?.params?.perimeter);
        var rule_interpreted = null;


        // Replace current data if type !== rule
        if (type === "condition" || type === "perimeter") {
            newData.params[type].data.data = currentData;
            newData.params[type].data.interpreted = currentData_interpreted;
            // Rule interpretation
            // if (type === "condition" && currentData_interpreted === false) {
            if ((type === "condition" && currentData_interpreted === false) || (type === "perimeter" && newData?.params?.condition?.data?.interpreted === false)) {
                rule_interpreted = [];
            }
            // else if (type === "condition" && currentData_interpreted === true) {
            else {
                rule_interpreted = nodeHelpers.nodeInterpretor(newData.composition.nodes[0].data.source, capacity, newData?.params?.perimeter);
            }
        }
        else {
            rule_interpreted = currentData_interpreted;
            console.log("rule_interpreted", rule_interpreted);
            // Stringify three object
            if (Array.isArray(rule_interpreted)) {
                rule_interpreted.forEach(object => {
                    object = object.toJSON;
                });
            }
            else {
                rule_interpreted = rule_interpreted.toJSON;
            }
        }
        console.log("newData", newData);
        console.log("rule_interpreted", rule_interpreted);
        // Update all rule
        rule_Update(rule_id, newData, rule_interpreted);
    }

    function replaceVariable(mainNodeValue, searchString, value, start) {
        console.log("mainNodeValue", mainNodeValue);
        console.log("searchString", searchString);
        console.log("value", value);
        console.log("start", start);

        if (searchString === "" || searchString === undefined || searchString === null) {
            console.error("ERROR: SearchString is undefined");
            return mainNodeValue
        }

        var newString = mainNodeValue.substring(start, mainNodeValue.length);
        // Get startIndex
        var startIndex = newString.indexOf(searchString);
        if (startIndex >= 0) {
            // Get endIndex
            var newShortString = newString.substring(startIndex + searchString.length, newString.length);
            var endIndex = newShortString.indexOf("]");
            var count = 0;
            for (var i = 0; i < newShortString.length; i++) {
                if (newShortString[i] === "[") {
                    count++;
                }
                if (newShortString[i] === "]") {
                    count--;
                }
                if (count < 0) {
                    endIndex = i;
                    break;
                }
            }
            // Get left & right parts
            var leftPart = mainNodeValue.substring(0, start + startIndex) + searchString;
            var rightPart = newShortString.substring(endIndex, newShortString.length);
            // Update mainNodeValue
            mainNodeValue = leftPart + value + rightPart;
            // recursive
            mainNodeValue = replaceVariable(mainNodeValue, searchString, value, start + startIndex + searchString.length)
        }
        return mainNodeValue;
    }

    // const updateVariableParams = (type, variableLabel, value) => {
    //     console.log("UPDATING VARIABLE for '" + variableLabel + "' to :", value);
    //     // Get current data
    //     var newData = { ...data };
    //     // Search matching variable
    //     for (var i = 0; i < newData.params[type].data.data.variables_description.length; i++) {
    //         if (newData.params[type].data.data.variables_description[i].label === variableLabel) {
    //             // Update variable value
    //             newData.params[type].data.data.variables_description[i].value = value;
    //         }
    //     }
    //     for (var i = 0; i < newData.params[type].data.data.variables_input.length; i++) {
    //         if (newData.params[type].data.data.variables_input[i].label === variableLabel) {
    //             // Update variable value
    //             newData.params[type].data.data.variables_input[i].value = value;
    //         }
    //     }
    //     console.log("newData", newData);
    //     // Update all rule
    //     rule_Update(id, newData);
    // }


    return (
        <div id={"rule_element_" + rule_id} className="app__ruleset_rule">
            <div className="app__ruleset_title_header">
                <div className="app__ruleset_rule_label">{rule?.numerotation ? rule.numerotation + " " : ""}{name}</div>
                <div style={{ display: "flex", gap: "10px", position: "relative", top: "-11px" }}>
                    {(highlightedRule !== null && data.composition.nodes[0].data?.isMapVisible === true) &&
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers_highlight} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id={"sectiondata_switch_highlightedRule_" + rule_id} checked={highlightedRule === rule_id ? true : false} onChange={() => { highlightedRule === rule_id ? setHighlightedRule("all") : setHighlightedRule(rule_id) }} /><label className="app__map_layer_checkbox_label" htmlFor={"sectiondata_switch_highlightedRule_" + rule_id}>Toggle</label></div>
                        </div>
                    }
                    <div className="app__ruleset_title_button_container" onClick={() => { setIsDelete(true) }}>
                        {/* <img src={ico_dots} alt="dots" /> */}
                        <img src={ico_close} alt="dots" />
                    </div>
                </div>
            </div>
            {/* DESCRIPTION */}
            <div style={{ height: "8px" }}></div>
            <div className="app__ruleset_rule_description_container">
                {(data?.description_splitted && data.description_splitted.length > 0) ?
                    <div className="app__ruleset_rule_description_subcontainer">
                        <div className="app__ruleset_rule_description">
                            {data.description_splitted.map((description_part, index) => {
                                var variables_match_index = -1;
                                for (var i = 0; i < data.variables_description.length; i++) {
                                    if (data.variables_description[i].label === description_part) {
                                        variables_match_index = i;
                                        break;
                                    }
                                }
                                if (variables_match_index === -1) {
                                    return (
                                        description_part
                                    )
                                }
                                else {
                                    // if (data.variables_description[variables_match_index].type === "Node_UserInput_Number" || data.variables_description[variables_match_index].type === "Node_UserInput_Number_Range" || data.variables_description[variables_match_index].type === "Node_UserInput_Boolean" || data.variables_description[variables_match_index].type === "Node_UserInput_Bounds" || data.variables_description[variables_match_index].type === "Node_UserInput_Lands" || data.variables_description[variables_match_index].type === "Node_UserInput_PLUZone" || data.variables_description[variables_match_index].type === "Node_UserInput_Levels" || data.variables_description[variables_match_index].type === "Node_UserInput_Buildings") {
                                    if (data.variables_description[variables_match_index].type.includes("Node_UserInput_")) {
                                        return (
                                            <RuleVariable key={"var_" + rule_id + index} ruleId={rule_id} ruleLabel={rule.name} ruleType={"rule"} variable={data.variables_description[variables_match_index]} varType={"description"} updateVariable={updateVariable} capacity={capacity} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} />
                                        )
                                    }
                                    else if (data.variables_description[variables_match_index].type === "result") {
                                        return (
                                            <RuleResult rule={rule} />
                                        )
                                    }
                                    return (
                                        <span key={index} className="app__ruleset_rule_description_input">{description_part}</span>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    :
                    <div className="app__ruleset_rule_description">{data.description}</div>
                }
                {data?.image &&
                    <div className="app__ruleset_rule_image"><img src={data.image} /></div>
                }
            </div>
            {/* VARIABLES */}

            {/* CONDITION */}
            {data?.params?.condition?.value === true &&
                <>
                    {/* <div className="app__ruleset_rule_separator"></div> */}
                    {/* <div className="app__ruleset_rule_condition_title_container">Condition d'application de la règle :</div> */}
                    <div className="app__ruleset_rule_condition_container">
                        {data?.params?.condition?.data !== null ?
                            <>
                                <div className={`app__ruleset_rule_condition_box_full ${data?.params?.condition?.data?.interpreted === false ? "app__ruleset_rule_condition_box_full_invalid" : ""}`}>
                                    <div className="app__ruleset_rule_condition_box_full_header">
                                        <div>Cette règle s'applique uniquement si :</div>
                                        <img src={ico_close} alt="close" onClick={() => { condition_Delete(rule_id) }} />
                                    </div>
                                    <div>
                                        {(data?.params?.condition?.data?.data?.description_splitted && data.params.condition.data.data.description_splitted.length > 0) ?
                                            <div className="app__ruleset_rule_description">
                                                {data.params.condition.data.data.description_splitted.map((description_part, index) => {
                                                    var variables_match_index = -1;
                                                    for (var i = 0; i < data.params.condition.data.data.variables_description.length; i++) {
                                                        if (data.params.condition.data.data.variables_description[i].label === description_part) {
                                                            variables_match_index = i;
                                                            break;
                                                        }
                                                    }
                                                    if (variables_match_index === -1) {
                                                        return (
                                                            description_part
                                                        )
                                                    }
                                                    else {
                                                        // if (data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Number" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Number_Range" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Boolean" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Bounds" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Lands" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_PLUZone" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Levels" || data.params.condition.data.data.variables_description[variables_match_index].type === "Node_UserInput_Buildings") {
                                                        if (data.params.condition.data.data.variables_description[variables_match_index].type.includes("Node_UserInput_")) {
                                                            return (
                                                                <RuleVariable key={index} ruleId={rule_id} ruleLabel={rule.name} ruleType={"condition"} variable={data.params.condition.data.data.variables_description[variables_match_index]} varType={"condition"} updateVariable={updateVariable} capacity={capacity} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} />
                                                            )
                                                        }
                                                        return (
                                                            <span key={index} className="app__ruleset_rule_description_input">{description_part}</span>
                                                        )
                                                    }
                                                })}
                                            </div>
                                            :
                                            <div className="app__ruleset_rule_description">{data.description}</div>
                                        }
                                        {/* {nodeHelpers.nodeInterpretor([data.params.condition.data.data.composition.nodes[0].data.source[0]], capacity) === true ?
                                            <div className="app__ruleset_rule_description">Test=OK</div>
                                            :
                                            <div className="app__ruleset_rule_description">Test=NO</div>
                                        } */}
                                        {/* {data?.params?.condition?.data?.interpreted === true &&
                                            <div className="app__ruleset_rule_condition_result_green">applicable</div>
                                        } */}
                                        {data?.params?.condition?.data?.interpreted === false &&
                                            <div className="app__ruleset_rule_condition_result_red"><img src={ico_warning_red} alt="" />la condition n'est pas remplie</div>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="app__ruleset_rule_condition_box_full_inactive">
                                    {/* <div className="app__ruleset_rule_condition_box_full_header" style={{ fontWeight: "500" }}>Cette règle s'applique dans tous les cas.</div> */}
                                    <div className="app__ruleset_rule_description"><span className="app__ruleset_rule_condition_link" onClick={() => { ruleCatalog_Open(rule_id, "condition") }}>Ajouter une condition d'application</span></div>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
            {/* PERIMETER */}
            {data?.params?.perimeter?.value === true &&
                <>
                    {/* <div className="app__ruleset_rule_separator"></div> */}
                    {/* <div className="app__ruleset_rule_condition_title_container">Périmètre d'application de la règle :</div> */}
                    <div className="app__ruleset_rule_condition_container">
                        {data?.params?.perimeter?.data !== null ?
                            <>
                                <div className={`app__ruleset_rule_condition_box_full ${data?.params?.perimeter?.data?.interpreted && data?.params?.perimeter?.data?.interpreted.length === 0 ? "app__ruleset_rule_condition_box_full_invalid" : ""}`}>
                                    <div className="app__ruleset_rule_condition_box_full_header">
                                        <div>Cette règle s'applique uniquement sur :</div>
                                        <img src={ico_close} alt="close" onClick={() => { perimeter_Delete(rule_id) }} />
                                    </div>
                                    {(data?.params?.perimeter?.data?.data?.description_splitted && data.params.perimeter.data.data.description_splitted.length > 0) ?
                                        <div className="app__ruleset_rule_description">
                                            {data.params.perimeter.data.data.description_splitted.map((description_part, index) => {
                                                var variables_match_index = -1;
                                                for (var i = 0; i < data.params.perimeter.data.data.variables_description.length; i++) {
                                                    if (data.params.perimeter.data.data.variables_description[i].label === description_part) {
                                                        variables_match_index = i;
                                                        break;
                                                    }
                                                }
                                                if (variables_match_index === -1) {
                                                    return (
                                                        description_part
                                                    )
                                                }
                                                else {
                                                    // if (data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Number" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Number_Range" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Boolean" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Bounds" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Lands" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_PLUZone" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Levels" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Buildings" || data.params.perimeter.data.data.variables_description[variables_match_index].type === "Node_UserInput_Draw_Point") {
                                                    if (data.params.perimeter.data.data.variables_description[variables_match_index].type.includes("Node_UserInput_")) {
                                                        return (
                                                            <RuleVariable key={index} ruleId={rule_id} ruleLabel={rule.name} ruleType={"perimeter"} variable={data.params.perimeter.data.data.variables_description[variables_match_index]} varType={"perimeter"} updateVariable={updateVariable} capacity={capacity} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} />
                                                        )
                                                    }
                                                    else if (data.params.perimeter.data.data.variables_description[variables_match_index].type === "result") {
                                                        return (
                                                            <RuleResult rule={data?.params?.perimeter?.data} />
                                                        )
                                                    }
                                                    return (
                                                        <span key={index} className="app__ruleset_rule_description_input">{description_part}</span>
                                                    )
                                                }
                                            })}
                                        </div>
                                        :
                                        <div className="app__ruleset_rule_description">{data.description}</div>
                                    }
                                    {(data?.params?.perimeter?.data?.interpreted && data?.params?.perimeter?.data?.interpreted.length === 0) &&
                                        <div className="app__ruleset_rule_condition_result_red"><img src={ico_warning_red} alt="" />le périmètre est invalide</div>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="app__ruleset_rule_condition_box_full_inactive">
                                    {/* <div className="app__ruleset_rule_condition_box_full_header" style={{ fontWeight: "500" }}>Cette règle s'applique sur l'ensemble de la surface du terrain.</div> */}
                                    <div className="app__ruleset_rule_description"><span className="app__ruleset_rule_condition_link" onClick={() => { ruleCatalog_Open(rule_id, "perimeter") }}>Ajouter un périmètre d'application</span></div>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
            {/* ACTIONS */}
            {isActions &&
                <div className="app__ruleset_document_actions_container scale-up-topright" onMouseLeave={() => { setIsActions(false) }}>
                    <div className="app__landsvalidation_popup_button_second" onClick={handleClickDelete}>Supprimer cette règle</div>
                </div>
            }
            {isDelete &&
                <div className="app__ruleset_rule_actions_container" onMouseLeave={() => { setIsDelete(false) }}>
                    <div className="app__bound_type_modify_title_big">Supprimer cette règle ?</div>
                    <div className="app__ruleset_document_deletion_container">
                        <div className="app__landsvalidation_popup_button" onClick={() => { setIsDelete(false) }}>Conserver</div>
                        <div className="app__landsvalidation_popup_button_second" onClick={() => { rule_Delete(rule_id) }}>Supprimer</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Rule;