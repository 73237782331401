// Import dependencies
import React from 'react';

// Import CSS & assets
import './Input.css';
import ico_visible from '../../../assets/ico/ico_visible.svg';
import ico_hidden from '../../../assets/ico/ico_hidden.svg';
import ico_close from '../../../assets/ico/ico_close.svg';


const Input = ({ type, name, label, value, defaultValue, isResetable, handleChange, handleShow, handleKeyUp, handleClear, handleBlur, isDark, readOnly, autofocus }) => {
    return (
        <div className="common__input_container">
            {readOnly === true ?
                <input type={type} className={isDark ? "common__input_field_dark" : "common__input_field"} placeholder={label} name={name} id={name} required autoFocus={autofocus} onChange={handleChange} onKeyUp={handleKeyUp} onBlur={handleBlur} value={value} defaultValue={defaultValue} readOnly />
                :
                <input type={type} className={isDark ? "common__input_field_dark" : "common__input_field"} placeholder={label} name={name} id={name} required autoFocus={autofocus} onChange={handleChange} onKeyUp={handleKeyUp} onBlur={handleBlur} value={value} defaultValue={defaultValue} />
            }
            <label htmlFor={name} className="form__label">{label}</label>
            {/* Show / Hide icon for passwords */}
            {((name === 'password' || name === 'confirmPassword') && type === 'password') && (
                <img className='auth_field_ico' src={ico_visible} alt='visible' onClick={handleShow} />
            )}
            {((name === 'password' || name === 'confirmPassword') && type === 'text') && (
                <img className='auth_field_ico' src={ico_hidden} alt='hidden' onClick={handleShow} />
            )}
            {(isResetable && value !== "") && (
                <img className='auth_field_ico' src={ico_close} alt='reset' onClick={handleClear} />
            )}
        </div>
    );
};

export default Input;