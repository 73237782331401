import React, { useState, useEffect } from 'react';
import { capaGetById, capaCopy, capaImport } from '../../../actions/capa';
import { useDispatch } from 'react-redux';


// Import CSS & assets
import './Capas.css';

// Import external functions
import * as other_helpers from '../../../helpers/Other_helpers';

import MiniMap from '../../common/miniMapAdmin/MiniMap';
import ico_copy from '../../../assets/ico/ico_copy.svg';


const Capas = ({ user, capa_list, capaOrder, setCapaOrder }) => {

    const width_list = [27, 31, 51];
    const left_list = [0, 43, 90];
    const [capaType, setCapaType] = useState(2);
    const [copyModal, setCopyModal] = useState(null);
    const dispatch = useDispatch();

    const handleCapaOrderChange = (new_order) => {
        if (new_order !== capaOrder) {
            setCapaOrder(new_order);
        }
    }

    // Copy capa
    const [copying, setCopying] = useState(false);
    const copyCapa = (capaId) => {
        setCopying(true);
        var userId = "";
        if (user?.result?._id) { userId = user.result._id };
        const formData = { userId, toAdmin: true };
        dispatch(capaCopy(capaId, formData, handleSuccessCopy, handleFail));
    }

    // Copy JSON
    const [copyingJSON, setCopyingJSON] = useState(false);
    const [copiedJSON, setCopiedJSON] = useState(false);
    const copyJSON = (capaId) => {
        if (copyingJSON === false && copiedJSON === false) {
            setCopyingJSON(true);
            getCapacity(capaId);
        }
    }
    const getCapacity = (id) => {
        console.log("-FETCHING CAPA- : Loading...");
        // Check if user is connected and is inside a team
        dispatch(capaGetById(id, user?.teams[0]?._id, handleCapaGetSuccess, handleCapaGetFail));
    }
    // Get response from Capa fetching : success
    const handleCapaGetSuccess = (data) => {
        console.log("-FETCHING CAPA- : Success", data);
        setCopiedJSON(true);
        setCopyingJSON(false);
        var capa_string = JSON.stringify(data);
        navigator.clipboard.writeText(capa_string);
        setTimeout(() => {
            setCopiedJSON(false);
        }, 2000);
    };
    // Get response from Capa fetching : error
    const handleCapaGetFail = (error) => {
        console.log("-FETCHING CAPA- : Error", error?.response?.data?.message);

    }

    // Import Capa
    const [isImportVisible, setIsImportVisible] = useState(false);
    const [isImportValid, setIsImportValid] = useState({ state: "null" });
    const [parsedImport, setParsedImport] = useState(null);
    const handleJsonChange = (e) => {
        setIsImportValid({ ...isImportValid, state: "loading" });
        console.log("Check", e.target.value);
        var check = importCheck(e.target.value);
        console.log("Result", check);
        if (check.result === false) {
            setIsImportValid({ ...isImportValid, state: "invalid" });
        }
        else if (check.result === true) {
            setIsImportValid({ ...isImportValid, state: "valid" });
        }
        else if (check.result === null) {
            setIsImportValid({ ...isImportValid, state: "null" });
        }
    }
    const importCheck = (capa_string) => {
        var check = false;
        var capa_json = {};
        console.log("test", capa_string);
        if (capa_string === "") {
            return { result: null, capa_json }
        }
        try {
            capa_json = JSON.parse(capa_string);
            console.log("JSON", capa_json);
            check = true;
            if (!capa_json?._id) { console.log("INVALID: No id"); check = false; }
            if (!capa_json?.toSave) { console.log("INVALID: No toSave"); check = false; }
            if (!capa_json?.title) { console.log("INVALID: No title"); check = false; }
            if (!capa_json?.description && capa_json?.description !== "") { console.log("INVALID: No description"); check = false; }
            if (!capa_json?.creationUserId) { console.log("INVALID: No creationUserId"); check = false; }
            if (!capa_json?.creationDate) { console.log("INVALID: No creationDate"); check = false; }
            if (!capa_json?.modificationUserId) { console.log("INVALID: No modificationUserId"); check = false; }
            if (!capa_json?.modificationDate) { console.log("INVALID: No modificationDate"); check = false; }
            if (!capa_json?.step && capa_json?.step !== 0) { console.log("INVALID: No step"); check = false; }
            if (!capa_json?.landBase) { console.log("INVALID: No landBase"); check = false; }
            if (!capa_json?.city && capa_json?.city !== null) { console.log("INVALID: No city"); check = false; }
            if (!capa_json?.rules) { console.log("INVALID: No rules"); check = false; }
            if (!capa_json?.buildable) { console.log("INVALID: No buildable"); check = false; }
            // MODEL
            // id: { type: String },
            // simplified: { type: Object },
            // toSave: { type: Object, default: { autoSave: false, action: "", value: "" }, required: true },
            // toUpdate: { type: Boolean, default: false },
            // title: { type: String, default: "Nouvelle étude capacitaire" },
            // description: { type: String, default: "" },
            // creationUserId: { type: String, required: true },
            // creationDate: { type: Date, required: true },
            // modificationUserId: { type: String, required: true },
            // modificationDate: { type: Date, required: true },
            // modifications: { type: [Object] },
            // teams: { type: [String] },
            // step: { type: Number, default: 0 },
            // landBase: { type: Object, default: { lands: [], union: { } } },
            // geocoderResult: { type: Object },
            // city: { type: Object, default: null },
            // risks: { type: Object },
            // context: { type: Object },
            // rules: { type: Object, default: { ruleset: [{ id: "ruleGroup_0", name: "Règlement", items: [] }] } },
            // buildable: { type: Object, default: { volume: { parameters: { }, interpreted: [] } } },
        } catch (error) {
            console.log(error);
        }
        return { result: check, capa_json }
    }
    const importCapacity = () => {
        // Check
        var check = importCheck(document.getElementById("import_text")?.value);
        if (check.result !== true) {
            console.log("JSON non valide");
            return
        }
        var userId = "";
        if (user?.result?._id) { userId = user.result._id };
        var capa_data = check.capa_json;
        const formData = { userId, capa_data };
        dispatch(capaImport(formData, handleCapaImportSuccess, handleCapaImportFail));
    }
    const handleCapaImportSuccess = (data) => {
        console.log("SUCCESS", data);
        window.location.reload();
    }
    const handleCapaImportFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    // Loading
    const handleSuccessCopy = (data) => {
        console.log("SUCCESS COPY", data);
        window.location.reload();
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    return (
        <>
            <div className="app__admin_teams_title_container">
                <div className="app__admin_title">Études capacitaires</div>
                <div className="app__admin_capa_top_container">
                    <div className="app__admin_capa_type_container" style={{ opacity: isImportVisible ? 0 : 1 }}>
                        <div className="app__admin_capa_type_indicator" style={{ width: width_list[capaType] + 24 + 'px', left: left_list[capaType] + 'px' }}></div>
                        <div id="capa_type_0" className={`app__admin_capa_type ${capaType === 0 ? "app__admin_capa_type_selected" : ""}`} onClick={() => { setCapaType(0) }}>Liste</div>
                        <div id="capa_type_1" className={`app__admin_capa_type ${capaType === 1 ? "app__admin_capa_type_selected" : ""}`} onClick={() => { setCapaType(1) }}>Carte</div>
                        <div id="capa_type_2" className={`app__admin_capa_type ${capaType === 2 ? "app__admin_capa_type_selected" : ""}`} onClick={() => { setCapaType(2) }}>Heatmap</div>
                    </div>
                    <div className="app__admin_capa_top_button" onClick={() => { setIsImportVisible(true) }}>+ Importer une étude</div>
                </div>
            </div>
            {/* LIST */}
            {(isImportVisible === false && capaType === 0) &&
                <>
                    <div className="app__admin_users_labels">
                        <div className="app__admin_capa_col00"><span style={{ cursor: 'pointer' }} onClick={() => { handleCapaOrderChange("capa_title") }}>Titre{capaOrder === "capa_title" && " ▽"}</span>, <span style={{ cursor: 'pointer' }} onClick={() => { handleCapaOrderChange("capa_id") }}>ID{capaOrder === "capa_id" && " ▽"}</span> et <span style={{ cursor: 'pointer' }} onClick={() => { handleCapaOrderChange("capa_date") }}>date de création{capaOrder === "capa_date" && " ▽"}</span></div>
                        <div className="app__admin_capa_col1"><span style={{ cursor: 'pointer' }} onClick={() => { handleCapaOrderChange("team_name") }}>Équipe{capaOrder === "team_name" && " ▽"}</span></div>
                        <div className="app__admin_capa_col2" style={{ opacity: 1 }}>Action</div>
                    </div>
                    <div className="admin__capas_container">
                        {capa_list.map((capa, index) => {
                            return (
                                <div className="app__admin_users_container" key={index}>
                                    {index > 0 &&
                                        <div className="app__admin_users_separator"></div>
                                    }
                                    {copyModal === capa.id &&
                                        <div className="app__admin_capa_body_modal" onMouseLeave={() => { setCopyModal(null) }}>
                                            {copying ?
                                                <div>Duplication en cours ...</div>
                                                :
                                                <>
                                                    <div>Voulez-vous dupliquer cette étude dans l'équipe Admin ?</div>
                                                    <div className="app__admin_capa_body_modal_buttons">
                                                        <div className="app__admin_capa_button_green" onClick={() => copyCapa(capa.id)}>Dupliquer</div>
                                                        <div className="app__admin_capa_button_red" onClick={() => { setCopyModal(null) }}>Annuler</div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                    <div className="app__admin_capa_body">
                                        <div className="app__admin_users_title">
                                            {capa.title}
                                        </div>
                                        <div className="app__admin_capa_body2">
                                            <div className="app__admin_capa_col0">
                                                {capa.id} - {new Date(capa.creationDate).toLocaleDateString()}
                                            </div>
                                            <div className="app__admin_capa_col1">
                                                {capa?.team &&
                                                    <>
                                                        {capa.team}
                                                    </>
                                                }
                                            </div>
                                            <div className="app__admin_capa_col2">
                                                {/* {capa?.land_area &&
                                                    <>
                                                        {other_helpers.get_pretty_num(capa.land_area.toFixed(2))} m²
                                                    </>
                                                } */}
                                                <div>
                                                    <a className="app__admin_capa_button" href={"/capastudy/" + capa.id} target='_blank'>Ouvrir</a>
                                                    <div className="app__admin_capa_button" style={{ left: "66px" }} onClick={() => { setCopyModal(capa.id) }}><img src={ico_copy} /></div>
                                                </div>
                                                <div className="app__admin_capa_button_small" onClick={() => { copyJSON(capa.id) }}>{copyingJSON ? "Copie en cours..." : copiedJSON ? "✔" : "Copier le JSON"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
            {/* MAP */}
            {(isImportVisible === false && (capaType === 1 || capaType === 2)) &&
                <div className="app__admin_capa_map_container">
                    <MiniMap capacityFullList={capa_list} capaType={capaType} />
                </div>
            }
            {/* IMPORT */}
            {isImportVisible &&
                <div className="app__admin_capa_import_container">
                    <textarea id="import_text" className="app__admin_capa_import_txt" placeholder="Copiez le JSON de l'étude ici" onChange={handleJsonChange}></textarea>
                    <div className="app__admin_capa_import_buttons_container">
                        {isImportValid?.state !== "valid" &&
                            <div className="app__admin_capa_import_button_disabled">{isImportValid?.state === "invalid" ? "JSON non valide ⚠️" : "Importer"}</div>
                        }
                        {isImportValid?.state === "valid" &&
                            <div className="app__admin_capa_import_button_green" onClick={() => { importCapacity() }}>Importer</div>
                        }
                        <div className="app__admin_capa_import_button_red" onClick={() => { setIsImportVisible(false); setIsImportValid({ ...isImportValid, state: "null" }); }}>Annuler</div>
                    </div>
                </div>
            }
        </>
    );
};

export default Capas;