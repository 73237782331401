import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ chartData, chartOptions }) => {

    // var chartOptions = {
    //     plugins: {
    //         legend: {
    //             display: true,
    //             position: 'bottom',
    //             align: 'start'
    //         },
    //     }
    // }
    if (chartData?.datasets) {
        return (
            <Doughnut data={chartData} options={chartOptions} style={{ position: "relative", top: "0px", left: "0px" }} />
        );
    }
};

export default DoughnutChart;