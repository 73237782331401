import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';

const Node_Study_PLUZone = ({ id, data }) => {
    // console.log("CAPA", data.capacity); 


    const handleChange = (changed_Id, changed_Value) => {
        console.log("changed_Id", changed_Id);
        console.log("changed_Value", changed_Value);

        var value = data.source[0].value;

        if (changed_Value === true) {
            // Add selection
            console.log("ADD");
            if (value === null) {
                // If null => set to empty
                value = "Node_Study_PLUZone[]";
            }
            value = value.substring(0, value.indexOf("]")) + "|" + changed_Id + "|" + "]";
        }
        else if (changed_Value === false) {
            // Remove selection
            console.log("REMOVE");
            if (value !== null) {
                // Do something only if not null
                var changed_Index = value.indexOf("|" + changed_Id + "|");
                var changed_Length = changed_Id.toString().length;
                var left_part = value.substring(0, changed_Index);
                var right_part = value.substring(changed_Index + changed_Length + 2);
                console.log("pre value", value);
                console.log("left_part", left_part);
                console.log("right_part", right_part);
                value = left_part + right_part;
                if (value === "Node_Study_PLUZone[]") {
                    // Reset to null if empty
                    value = null;
                }
            }
        }


        if (data.source[0].value !== value) {
            data.source[0].value = value;
            data.handleChange(id, "source_0", value);
        }
    }

    const getNbSelected = (value) => {
        var totalNb = 0;
        if (value !== null && value !== "Node_Study_PLUZone[]") {
            var matched = value.match(/\|/g);
            if (matched !== null) {
                totalNb = matched.length / 2;
            }
        }
        console.log("totalNb", totalNb);
        return totalNb;
    }



    return (
        <div id={"block_" + id} className="ruleeditor__node_container" style={{ maxWidth: "184px" }}>
            <div className="ruleeditor__node_title">
                <div className="ruleeditor__node_title_label">{data.label}</div>
                <div className="ruleeditor__node_title_close"><img src={close} alt="close_block" onClick={() => (data.handleDelete(id))} /></div>
            </div>
            <div className="ruleeditor__node_center">
                <div className="ruleeditor__node_description">Zones PLU</div>
                <div className="ruleeditor__node_body">
                    <div>
                        {data.capacity.rules.gpu_data.zoneUrba.map((zone, index) => {
                            var isSelected = false;
                            if (data.source[0].value !== null && data.source[0].value.includes("|" + zone.id + "|")) {
                                isSelected = true;
                            }
                            return (
                                <div key={index} className="ruleeditor__input_checkbox_container"><input className="ruleeditor__input_checkbox" type="checkbox" id={"cb_subtype_" + zone.id} onChange={(e) => { handleChange(zone.id, e.target.checked) }} checked={isSelected} /><label className="ruleeditor__input_checkbox_label" htmlFor={"cb_subtype_" + zone.id}>Zone {zone.properties.libelle}</label></div>
                            )
                        })}
                    </div>
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    <div className="ruleeditor__handle_source">{data.source[0].value ? getNbSelected(data.source[0].value) === 1 ? "1 zone sélectionnée" : getNbSelected(data.source[0].value) + " zones sélectionnées" : "Aucune zone sélectionnée"}</div>
                    <div className={`ruleeditor__handle_source_type ${data.source[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.source[0].acceptance}</div>
                </div>
            </div>
            <Handle type="source" position={Position.Right} style={{ top: 'calc(100% - 11px - 10px)' }} id="source_0" />
        </div>
    );
};

export default Node_Study_PLUZone;