import * as map_helpers from '../components/app/map/Map_helpers';
import * as helpers from './Other_helpers';
import * as simulation_helpers from './Simulation_helpers';
import * as turf from '@turf/turf';

// Import global
import * as global from '../global.js';
const environment = global.get_environment();


export const get_combinations = (capacity) => {

    var result = simulation_helpers.get_combinations_rework(capacity);
    if (environment !== "DEV") {
        var filteredResult = [];
        result.forEach(zone => {
            // var filteredzone = zone.filter(combi => combi.validity === true);
            var filteredzone = [];
            zone.forEach((combi, combi_index) => {
                if (combi.validity === true) {
                    var new_id = "";
                    var indexOf = combi.id.indexOf("-");
                    if (indexOf > -1) {
                        new_id = combi.id.substring(0, indexOf) + "-" + filteredzone.length.toString();
                    }
                    else {
                        new_id = filteredzone.length.toString();
                    }
                    filteredzone.push({...combi, id: new_id})
                }
            })
            filteredResult.push(filteredzone);
        })
        result = filteredResult;
    }

    return result

    // Safety check
    if (!capacity?.buildable?.volume?.levels && !capacity.buildable.volume.levels.length > 1) {
        console.log("UNABLE TO SIMULATE");
        return;
    }
    if (!capacity.buildable.volume.levels[1]?.workingSurfaces && !capacity.buildable.volume.levels[1].workingSurfaces.length > 0) {
        console.log("UNABLE TO SIMULATE");
        return;
    }


    // Get parameters
    const buildingWidth = capacity?.buildable?.volume?.parameters?.max_building_width || 10;
    const buildingOffsetMain_type = capacity?.buildable?.volume?.parameters?.min_offset_facade_main || { type: "recul", value: 6 };
    const buildingOffsetMain = get_buildingOffsetFromType(capacity, buildingOffsetMain_type);
    const buildingOffsetSecond_type = capacity?.buildable?.volume?.parameters?.min_offset_facade_edge || { type: "recul", value: 4 };
    const buildingOffsetSecond = get_buildingOffsetFromType(capacity, buildingOffsetSecond_type);


    // __________Working Lines
    // Create working lines from working surfaces
    var workingLines = []
    var workingLinesGroups = []
    capacity.buildable.volume.levels[1].workingSurfaces.forEach(workingSurface => {
        if (workingSurface.properties.area > (buildingWidth * buildingWidth)) {
            // console.log("TEST CLOCKWISE", turf.booleanClockwise(turf.polygonToLine(workingSurface)));
            var currentWorkingLines = [];
            var currentworkingLinesGroups = [];
            for (var i = 1; i < workingSurface.geometry.coordinates[0].length; i++) {
                currentWorkingLines.push(turf.lineString([workingSurface.geometry.coordinates[0][i - 1], workingSurface.geometry.coordinates[0][i]]));
                var line_coords = [map_helpers.mercator_to_local(workingSurface.geometry.coordinates[0][i - 1], capacity.landBase.union.bbox.origin), map_helpers.mercator_to_local(workingSurface.geometry.coordinates[0][i], capacity.landBase.union.bbox.origin)];
                var line_data = get_line_data(capacity, line_coords);
                currentworkingLinesGroups.push(line_data);
            }
            workingLines.push(currentWorkingLines);
            workingLinesGroups.push(currentworkingLinesGroups);
        }
        else {
            workingLinesGroups.push([]);
        }
    })
    console.log("workingLines", workingLines);
    console.log("workingLinesGroups", workingLinesGroups);


    // __________Testing Lines
    // Create testing lines from working lines
    const boundOffset = 0.1;
    var testingLinesTurf = [];
    var testingLinesGroups = [];
    workingLinesGroups.forEach((workingLinesGroup, workingSurfaces_Index) => {
        var testingLinesGroup = [];
        var workingLine_Index = 0;
        workingLinesGroup.forEach((workingLine, index) => {
            console.log("workingLine index", index);
            var currentTestingLine = get_lineOffset_data(capacity, workingLine, (buildingWidth / 2) + boundOffset, capacity.buildable.volume.levels[1].workingSurfaces[workingSurfaces_Index]);
            if (currentTestingLine === null) { return }
            currentTestingLine.line_footprint = get_line_footprint(capacity, buildingWidth, currentTestingLine, (buildingWidth / 2), capacity.buildable.volume.levels[1].workingSurfaces[workingSurfaces_Index]);
            // currentTestingLine.line_maxHeight = get_line_maxHeight(currentTestingLine, capacity.buildable.volume.levels);
            currentTestingLine.id = "line_" + workingLine_Index;
            // currentTestingLine.lineParal_subLines = get_subLines(capacity, currentTestingLine, buildingWidth, buildingOffsetMain, capacity.buildable.volume.levels[1].workingSurfaces[workingSurfaces_Index]);
            testingLinesGroup.push(currentTestingLine);
            testingLinesTurf.push(turf.lineString(currentTestingLine.line_coords_global));
            workingLine_Index++;
        })
        testingLinesGroups.push(testingLinesGroup);
    })
    console.log("testingLinesGroups", testingLinesGroups);


    // __________Main Lines Combinaitions
    // var mainLinesCombinationsGroups = [];
    var mainLinesFootprintsGroups = [];
    testingLinesGroups.forEach((testingLinesGroup, testingLinesGroup_Index) => {
        // var mainLinesCombinationsGroup = [];
        var mainLinesFootprintsGroup = [];

        // Get list of all testing line ids
        var testingLines_ids = [];
        testingLinesGroup.forEach(testingLine => {
            testingLines_ids.push(testingLine.id);
        });

        // Get ids combinations
        var testingLines_ids_combinations = get_lines_all_combinations(testingLines_ids);
        console.log("testingLines_ids_combinations", testingLines_ids_combinations);


        // Get detailed combinations
        var index = 0;
        for (var i = 0; i < testingLines_ids_combinations.length; i++) {
            console.log("__________________ COMBINATION ", i);
            var linesDetailed = get_lines_detailed_combination(capacity, testingLines_ids_combinations[i], testingLinesGroup, capacity.buildable.volume.levels[1].workingSurfaces[testingLinesGroup_Index]);
            var detailed_combi_mainLines = get_combination_mainLines_all(capacity, linesDetailed, buildingWidth, capacity.buildable.volume.levels[1].workingSurfaces[testingLinesGroup_Index]);
            // var detailed_combi_footprint_buffer = get_combination_footprint_buffer(capacity, detailed_combi_mainLines, capacity.buildable.volume.levels[1].workingSurfaces[testingLinesGroup_Index], capacity.buildable.volume.levels[1].availableSurfaces, buildingOffsetMain, buildingOffsetSecond, buildingWidth);
            // var detailed_combi_levels_area = get_combination_levels(capacity, detailed_combi_footprint_buffer.footprints, capacity.buildable.volume.levels, buildingWidth);
            // // if (detailed_combi_levels_area.total_area > 0) { // Keep only the ones with area > 0
            // mainLinesCombinationsGroup.push({
            //     id: index,
            //     id_list: testingLines_ids_combinations[i],
            //     line_list: linesDetailed,
            //     mainLines: detailed_combi_mainLines,
            //     mainLines_footprint: detailed_combi_footprint_buffer.footprints,
            //     mainLines_buffer: detailed_combi_footprint_buffer.buffers,
            //     mainLines_levels: detailed_combi_levels_area.level_list,
            //     mainLines_area: detailed_combi_levels_area.total_area,
            // });
            // NEW VERSION ------
            var detailed_combi_footprint_buffer = get_combination_footprint_buffer2(capacity, detailed_combi_mainLines, capacity.buildable.volume.levels[1].workingSurfaces[testingLinesGroup_Index], capacity.buildable.volume.levels[1].availableSurfaces, buildingOffsetMain, buildingOffsetSecond, buildingWidth);
            if (detailed_combi_footprint_buffer.footprints.length > 0) {
                mainLinesFootprintsGroup.push({
                    id: index,
                    footprints: detailed_combi_footprint_buffer.footprints,
                    // buffers: detailed_combi_footprint_buffer.buffers,
                    area: detailed_combi_footprint_buffer.area,
                    centroid: detailed_combi_footprint_buffer.centroid,
                    validity: detailed_combi_footprint_buffer?.validity,
                    message: detailed_combi_footprint_buffer?.message,
                });
                // -------
                index++;
            }
            // }
            console.log("__________________");
        }

        // mainLinesCombinationsGroups.push(mainLinesCombinationsGroup);
        // Filter footrpints to get reed of area = 0 and same footprints
        var mainLinesFootprintsGroup_filtered = filterCombinations(mainLinesFootprintsGroup);
        mainLinesFootprintsGroups.push(mainLinesFootprintsGroup_filtered);



    })
    // console.log("mainLinesCombinationsGroups", mainLinesCombinationsGroups);

    // Flatten footprints to get all combinations possible with all zones
    var mainLinesFootprintsGroups_flattened = flattenCombinations(mainLinesFootprintsGroups);

    // Create all combinations
    var combinationsList = [];
    for (var combi_i = 0; combi_i < mainLinesFootprintsGroups_flattened.length; combi_i++) {

        // var detailed_combi_levels_area = get_combination_levels(capacity, mainLinesFootprintsGroups_flattened[combi_i].footprints, capacity.buildable.volume.levels, buildingWidth);
        // mainLinesFootprintsGroups_flattened[combi_i].levels_area = detailed_combi_levels_area;
        console.log("__________ COMBI ", combi_i);
        var toaster_text = document.getElementById("toaster__text");
        if (toaster_text) {
            toaster_text.innerHTML = "Génération des simulations de construction sur le terrain.<br>Combinaison " + combi_i + "/" + (mainLinesFootprintsGroups_flattened.length - 1);
        }
        var combination_buildings = get_buildings_of_combination(capacity, mainLinesFootprintsGroups_flattened[combi_i].footprints, capacity.buildable.volume.levels, buildingWidth, buildingOffsetMain, buildingOffsetSecond, mainLinesFootprintsGroups_flattened[combi_i].validity, mainLinesFootprintsGroups_flattened[combi_i].message);
        if (combination_buildings.buildings.length > 0) {
            combinationsList.push({
                id: combinationsList.length,
                old_id: mainLinesFootprintsGroups_flattened[combi_i].id,
                buildings: combination_buildings.buildings,
                parking: combination_buildings.parking,
                area: combination_buildings.area_total,
                area_real: combination_buildings.area_total_real,
                groundArea: combination_buildings.ground_area_total,
                groundArea_real: combination_buildings.ground_area_total_real,
                parkingArea: combination_buildings.parking_area_total,
                parkingArea_real: combination_buildings.parking_area_total_real,

                validity: combination_buildings?.validity,
                message: combination_buildings?.message,

                // mainLines_levels: detailed_combi_levels_area.level_list,
                // mainLines_area: detailed_combi_levels_area.total_area,
            })
        }

    }
    console.log("mainLinesFootprintsGroups", mainLinesFootprintsGroups);

    console.log("mainLinesFootprintsGroups_flattened", mainLinesFootprintsGroups_flattened);

    console.log("combinationsList", combinationsList);





    // Flatten results
    // var finalCombinations = mainLinesCombinationsGroups;
    // var finalCombinations = flattenCombinations2(mainLinesCombinationsGroups);
    // console.log("finalCombinations", finalCombinations);


    // Result
    return [combinationsList];
    // return finalCombinations;

}


// _____________________________________________________________________________________________________________________________________________

// PARAMETERS

const get_buildingOffsetFromType = (capacity, buildingOffset_type) => {
    var value = null;

    if (buildingOffset_type?.type === "recul") {
        value = buildingOffset_type?.value
    }
    else if (buildingOffset_type?.type === "prospect" && capacity?.buildable?.volume?.parameters?.max_height) {
        value = capacity?.buildable?.volume?.parameters?.max_height / buildingOffset_type?.value
    }

    if (value === null || value === undefined) { value = 6 }

    return value
}

const get_line_maxHeight = (line_data, levels) => {
    // Create turf line
    var line_turf = turf.lineString(line_data.line_coords_global);
    var line_buffer = turf.buffer(line_turf, 0.0005, { steps: 1 });

    var minArea = 8;
    var maxHeight = 0;
    var matched = false;

    // Check each level
    for (var i = levels.length - 1; i >= 0; i--) {
        for (var j = 0; j < levels[i].workingSurfaces.length; j++) {
            var intersection = turf.intersect(line_buffer, levels[i].workingSurfaces[j]);
            if (intersection !== null && turf.area(intersection) >= minArea) {
                maxHeight = levels[i].elevation;
                matched = true;
                break;
            }
        }
        if (matched) { break }
    }

    return maxHeight

}



// LINES DATA

const get_line_data = (capacity, line_coords) => {
    const line_type = get_line_type(line_coords[0][0], line_coords[1][0], line_coords[0][1], line_coords[1][1]);
    var line_length = null;
    var line_coef = null;
    var line_origin = null;
    var linePerp_coef = null;
    var linePerp_start_origin = null;
    var linePerp_end_origin = null;
    var line_coords_global = [map_helpers.local_to_mercator(line_coords[0], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(line_coords[1], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix)];

    if (line_type === "linear") {
        line_length = get_line_length(line_coords);
        line_coef = get_line_coef(line_coords);
        line_origin = get_line_origin(line_coords[0], line_coef);
        linePerp_coef = get_linePerp_coef(line_coef);
        linePerp_start_origin = get_line_origin(line_coords[0], linePerp_coef);
        linePerp_end_origin = get_line_origin(line_coords[1], linePerp_coef);
    }
    return {
        line_type: line_type,
        line_length: line_length,
        line_coef: line_coef,
        line_origin: line_origin,
        line_coords_local: line_coords,
        line_coords_global: line_coords_global,
        linePerp_coef: linePerp_coef,
        linePerp_start_origin: linePerp_start_origin,
        linePerp_end_origin: linePerp_end_origin
    }
}

const get_line_type = (x1, x2, y1, y2) => {
    if (x1 === x2) {
        return "vertical"
    }
    else if (y1 === y2) {
        return "horizontal"
    }
    else {
        return "linear"
    }
}

const get_line_coef = (line_coords) => {
    const line_coef = (line_coords[1][1] - line_coords[0][1]) / (line_coords[1][0] - line_coords[0][0]);
    return line_coef
}

const get_line_origin = (line_coords, line_coef) => {
    const line_origin = line_coords[1] - line_coef * line_coords[0];
    return line_origin
}

const get_line_length = (line_coords) => {
    if (line_coords === null || line_coords === undefined || line_coords.length === 0) {
        return 0
    }
    // distnace = sqrt( (x1 - x2)² + (y1 - y2)² )
    const line_length = Math.sqrt(Math.pow((line_coords[0][0] - line_coords[1][0]), 2) + Math.pow((line_coords[1][1] - line_coords[0][1]), 2));
    return line_length
}

const get_linePerp_coef = (line_coef) => {
    const linePerp_coef = -1 / line_coef;
    return linePerp_coef
}

const get_subLines = (capacity, line_data, buildingWidth, buildingOffsetMain, workingSurface) => {
    var subLines = [];
    var totalOffset = buildingWidth + buildingOffsetMain;
    // Try to create 10 subLines
    for (var i = 1; i <= 10; i++) {
        var subLine = get_lineOffset_data(capacity, line_data, totalOffset, workingSurface);
        if (subLine === null) { break }
        subLine.line_footprint = get_line_footprint(capacity, buildingWidth, subLine, (buildingWidth / 2), workingSurface);
        // subLine.line_maxHeight = get_line_maxHeight(subLine, capacity.buildable.volume.levels);
        subLine.id = line_data.id + "_" + (i - 1);
        subLines.push(subLine);
        totalOffset += buildingWidth + buildingOffsetMain;
    }

    return subLines
}

const get_lineReversed_data = (line_data) => {
    // Get new values
    var coords_local = line_data.line_coords_local.reverse();
    var coords_global = line_data.line_coords_global.reverse();
    var perp_end_origin = line_data.linePerp_start_origin;
    var perp_start_origin = line_data.linePerp_end_origin;

    // Replace new data
    var new_data = { ...line_data, line_coords_local: coords_local, line_coords_global: coords_global, linePerp_start_origin: perp_start_origin, linePerp_end_origin: perp_end_origin };

    return new_data
}

const get_line_from_id = (line_id, testingLinesGroup) => {
    var line = null;
    for (var i = 0; i < testingLinesGroup.length; i++) {
        if (testingLinesGroup[i].id === line_id) {
            line = testingLinesGroup[i];
            break
        }
    }

    return line
}

const is_point_on_line = (point_coords, line_data) => {
    var x_test = false;
    var x_point = point_coords[0];
    var x_line_min = line_data.line_coords_local[0][0];
    var x_line_max = line_data.line_coords_local[1][0];
    if (x_line_min > x_line_max) {
        x_line_min = line_data.line_coords_local[1][0];
        x_line_max = line_data.line_coords_local[0][0];
    }
    if (x_point >= x_line_min && x_point <= x_line_max) {
        x_test = true;
    }

    var y_test = false;
    var y_point = point_coords[1];
    var y_line_min = line_data.line_coords_local[0][1];
    var y_line_max = line_data.line_coords_local[1][1];
    if (y_line_min > y_line_max) {
        y_line_min = line_data.line_coords_local[1][1];
        y_line_max = line_data.line_coords_local[0][1];
    }
    if (y_point >= y_line_min && y_point <= y_line_max) {
        y_test = true;
    }

    return (x_test === true && y_test === true)
}

const get_lines_angle = (lines) => {
    for (var i = 0; i < lines.length; i++) {
        var i_prev = i - 1;
        if (i_prev < 0) {
            i_prev = lines.length - 1;
        }

        // TURF VERSION
        var L1_bearing = turf.bearing(turf.point(lines[i].line_coords_global[0]), turf.point(lines[i].line_coords_global[1]));
        var L2_bearing = turf.bearing(turf.point(lines[i_prev].line_coords_global[0]), turf.point(lines[i_prev].line_coords_global[1]));
        var bearing_angle = L2_bearing - L1_bearing + 180;
        if (bearing_angle > 360) {
            bearing_angle -= 360;
        }
        if (bearing_angle < -360) {
            bearing_angle += 360;
        }
        if (bearing_angle < 0) {
            bearing_angle += 360;
        }
        lines[i].line_bearing = L1_bearing;
        lines[i].line_angle_start = bearing_angle

    }

    return lines
}

const checkAngleFootprint = (lines) => {

    for (var i = 0; i < lines.length; i++) {
        if (lines[i].line_angle_start > 315) {
            console.log("FOOTPRINT WITH TOO BIG ANGLE");
            return true
        }
    }

    return false
}


// LINES OFFSET DATA

const get_lineOffset_origin = (line_data, offset) => {
    var lineOffset_origin = offset * Math.sqrt(line_data.line_coef * line_data.line_coef + 1) + line_data.line_origin;
    if (line_data.line_coords_local[0][0] > line_data.line_coords_local[1][0]) {
        lineOffset_origin = -offset * Math.sqrt(line_data.line_coef * line_data.line_coef + 1) + line_data.line_origin;
    }
    return lineOffset_origin
}

const get_lineOffset_data = (capacity, line_data, offset, workingSurface, isStartBound, isEndBound) => {
    // workingSurface = capacity.buildable.volume.levels[1].workingSurfaces[0];
    var line_type = null;
    var line_coef = null;
    var line_origin = null;
    var line_coords = [];
    var line_coords_global = [];
    var linePerp_coef = null;
    var linePerp_start_origin = null;
    var linePerp_end_origin = null;
    var line_connexions = {
        start_inside: null,
        start_outside: null,
        end_inside: null,
        end_outside: null,
    }
    if (line_data.line_type === "linear") {
        line_type = "linear";
        line_coef = line_data.line_coef;
        line_origin = get_lineOffset_origin(line_data, offset);
        // EITHER Get coords for line that intersects working surface boundaries
        if (workingSurface) {
            var lineOffset_coords = get_line_coords_from_equation(line_coef, line_origin, 200);
            var lineOffset_coords_global = [map_helpers.local_to_mercator(lineOffset_coords[0], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(lineOffset_coords[1], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix)];
            var lineOffset_intersected = get_offsetLine_intersection_WorkingSurface(turf.lineString(lineOffset_coords_global), workingSurface, line_data);
            if (lineOffset_intersected === null) {
                console.log("line is outside workingSurface");
                return null
            }
            line_coords_global = (lineOffset_intersected).geometry.coordinates;
            line_coords = [map_helpers.mercator_to_local(line_coords_global[0], capacity.landBase.union.bbox.origin), map_helpers.mercator_to_local(line_coords_global[1], capacity.landBase.union.bbox.origin)];
            line_coords_global = [map_helpers.local_to_mercator(line_coords[0], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(line_coords[1], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix)];

            if (isStartBound === false) {
                console.log("Start Coord not matching bound");
                var line_coords_start_local = get_coords_intersection_two_equations({ line_coef, line_origin }, { line_coef: line_data.linePerp_coef, line_origin: line_data.linePerp_start_origin });
                line_coords = [line_coords_start_local, line_coords[1]];
                var line_coords_start_global = map_helpers.local_to_mercator(line_coords_start_local, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
                line_coords_global = [line_coords_start_global, line_coords_global[1]];
            }
            if (isEndBound === false) {
                console.log("End Coord not matching bound");
                var line_coords_end_local = get_coords_intersection_two_equations({ line_coef, line_origin }, { line_coef: line_data.linePerp_coef, line_origin: line_data.linePerp_end_origin });
                line_coords = [line_coords[0], line_coords_end_local];
                var line_coords_end_global = map_helpers.local_to_mercator(line_coords_end_local, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
                line_coords_global = [line_coords_global[0], line_coords_end_global];
            }
        }
        // OR Get coords for line that is perpendicular to original
        else {
            var line_coords_start = get_coords_intersection_two_equations({ line_coef, line_origin }, { line_coef: line_data.linePerp_coef, line_origin: line_data.linePerp_start_origin });
            var line_coords_end = get_coords_intersection_two_equations({ line_coef, line_origin }, { line_coef: line_data.linePerp_coef, line_origin: line_data.linePerp_end_origin });
            line_coords = [line_coords_start, line_coords_end];
            line_coords_global = [map_helpers.local_to_mercator(line_coords[0], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator(line_coords[1], capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix)];
        }



        // Get perpendicular line equation
        linePerp_coef = line_data.linePerp_coef;
        linePerp_start_origin = get_line_origin(line_coords[0], linePerp_coef);
        linePerp_end_origin = get_line_origin(line_coords[1], linePerp_coef);

    }
    return {
        line_type: line_type,
        line_coef: line_coef,
        line_origin: line_origin,
        line_coords_local: line_coords,
        line_coords_global: line_coords_global,
        // line_connexions: line_connexions,
        linePerp_coef: linePerp_coef,
        linePerp_start_origin: linePerp_start_origin,
        linePerp_end_origin: linePerp_end_origin
    }
}

const get_offsetLine_intersection_WorkingSurface = (workingLine, workingSurface, originalLine) => {
    var intersections = turf.lineIntersect(workingLine, turf.polygonToLine(workingSurface));
    if (intersections?.features && intersections.features.length >= 2) {
        // Get distances from start and end point
        var point_start = turf.point(originalLine.line_coords_global[0]);
        var point_end = turf.point(originalLine.line_coords_global[1]);
        var items = [];
        var distance_start_min = {
            distance: 1000,
            index: -1
        }
        var distance_end_min = {
            distance: 1000,
            index: -1
        }
        var index = 0;
        for (var i = 0; i < intersections.features.length; i++) {
            if (intersections.features[i].geometry.type === "Point") {
                var distance_start = turf.distance(intersections.features[i], point_start);
                if (distance_start < distance_start_min.distance) {
                    // Update
                    distance_start_min.index = index;
                    distance_start_min.distance = distance_start
                }
                var distance_end = turf.distance(intersections.features[i], point_end);
                if (distance_end < distance_end_min.distance) {
                    // Update
                    distance_end_min.index = index;
                    distance_end_min.distance = distance_end
                }
                items.push({
                    coords: intersections.features[i].geometry.coordinates,
                    distance_start: distance_start,
                    distance_end: distance_end,
                })
                index++;
            }
        }

        // Check if 2 elements are not the same
        if (distance_start_min.index === distance_end_min.index) {
            // If closer to start
            if (items[distance_start_min.index].distance_start < items[distance_start_min.index].distance_end) {
                // Reset end search
                distance_end_min = {
                    distance: 1000,
                    index: -1
                }
                // Search for second closer point from end
                items.forEach((item, index) => {
                    if (index !== distance_start_min.index && item.distance_end < distance_end_min.distance) {
                        // Update
                        distance_end_min.index = index;
                        distance_end_min.distance = item.distance_end;
                    }
                })
            }
            // If closer to end
            else {
                // Reset start search
                distance_start_min = {
                    distance: 1000,
                    index: -1
                }
                // Search for second closer point from end
                items.forEach((item, index) => {
                    if (index !== distance_end_min.index && item.distance_start < distance_start_min.distance) {
                        // Update
                        distance_start_min.index = index;
                        distance_start_min.distance = item.distance_start;
                    }
                })
            }
        }

        // return turf.lineString([intersections.features[0].geometry.coordinates, intersections.features[1].geometry.coordinates])
        return turf.lineString([items[distance_start_min.index].coords, items[distance_end_min.index].coords])
    }
    else {
        return null
    }
}


// COORDS

const get_coords_intersection_two_equations = (line1_data, line2_data) => {
    // y1 = a1 * x1 + b1 & y2 = a2 * x2 + b2 => y1 = y2 so => a1 * x1 + b1 = a2 * x2 + b2 => x (a1 - a2) = b2 - b1 => x = (b2 - b1) / (a1 - a2)
    var x = (line2_data.line_origin - line1_data.line_origin) / (line1_data.line_coef - line2_data.line_coef);
    var y = line1_data.line_coef * x + line1_data.line_origin;
    return [x, y]
}

const get_line_coords_from_equation = (line_coef, line_origin, bbox_lengthH) => {
    var start_x = -10
    var start_coords = [start_x, (line_coef * start_x) + line_origin];
    var end_x = bbox_lengthH - start_x;
    var end_coords = [end_x, (line_coef * end_x) + line_origin];
    return [start_coords, end_coords]
}


// FOORPTINT

const get_line_footprint = (capacity, buildingWidth, line_data, offset, workingSurface, isStartBound, isEndBound) => {
    // Get inside line
    var lineInside = get_lineOffset_data(capacity, line_data, offset, workingSurface, isStartBound, isEndBound);
    var lineInside_type = "bounds";
    if (lineInside === null) {
        lineInside = get_lineOffset_data(capacity, line_data, offset);
        lineInside_type = "perp";
    }
    lineInside.line_side = "in";
    // Get oustide line
    var lineOutside = get_lineOffset_data(capacity, line_data, -offset, workingSurface, isStartBound, isEndBound);
    var lineOutside_type = "bounds";
    if (lineOutside === null) {
        lineOutside = get_lineOffset_data(capacity, line_data, -offset);
        lineOutside_type = "perp";
    }
    lineOutside.line_side = "out";
    lineOutside = get_lineReversed_data(lineOutside);
    // Check if there is not too big difference between in and out line length
    var lineInside_length = get_line_length(lineInside.line_coords_local);
    var lineOutside_length = get_line_length(lineOutside.line_coords_local);
    // if (lineInside_length > 1.5 * lineOutside_length || lineOutside_length > 1.5 * lineInside_length) {
    if (Math.abs(lineOutside_length - lineInside_length) > (2 * buildingWidth)) {
        console.log("Reaffect offset lines because too big difference");
        if (lineInside_type !== "perp") {
            lineInside = get_lineOffset_data(capacity, line_data, offset);
            lineInside.line_side = "in";
        }
        if (lineOutside_type !== "perp") {
            lineOutside = get_lineOffset_data(capacity, line_data, -offset);
            lineOutside.line_side = "out";
            lineOutside = get_lineReversed_data(lineOutside);
        }
    }
    // Create 2 edges to join inside and outside
    var lineEdge1 = get_line_data(capacity, [lineInside.line_coords_local[1], lineOutside.line_coords_local[0]]);
    lineEdge1.line_side = "edge";
    var lineEdge2 = get_line_data(capacity, [lineOutside.line_coords_local[1], lineInside.line_coords_local[0]]);
    lineEdge2.line_side = "edge";

    // Create footprint
    var footprint = {
        lines: [lineInside, lineEdge1, lineOutside, lineEdge2],
        coords_local: [lineInside.line_coords_local[0], lineInside.line_coords_local[1], lineEdge1.line_coords_local[1], lineOutside.line_coords_local[1], lineEdge2.line_coords_local[1]],
        coords_global: [lineInside.line_coords_global[0], lineInside.line_coords_global[1], lineEdge1.line_coords_global[1], lineOutside.line_coords_global[1], lineEdge2.line_coords_global[1]],
    }

    return footprint

}


// COMBINATIONS

const get_lines_all_combinations = (lines_list) => {
    var all_combinations = [];
    for (var i = 1; i <= lines_list.length; i++) {
        all_combinations = all_combinations.concat(helpers.k_combinations(lines_list, i));
    }

    return all_combinations
}

const get_lines_detailed_combination = (capacity, linesList, testingLinesGroup, workingSurface) => {
    var detailedLinesList = [];
    for (var i = 0; i < linesList.length; i++) {
        // Get line from Testing Lines
        var line_Index = parseInt(linesList[i].substring(linesList[i].indexOf("_") + 1));
        var line = testingLinesGroup[line_Index];
        if (line === undefined) {
            continue;
        }

        // Defines connexions
        var start_in = null;
        var start_out = null;
        var end_in = null;
        var end_out = null;
        if (linesList.length > 1) {
            // Start
            var linePrev_Index = i - 1;
            if (linePrev_Index < 0) { linePrev_Index = linesList.length - 1 }
            // Check if crossing point is inside workingSurface
            var linePrev = get_line_from_id(linesList[linePrev_Index], testingLinesGroup);
            var intersection_coords_local = get_coords_intersection_two_equations(line, linePrev);
            var intersection_coords_global = map_helpers.local_to_mercator(intersection_coords_local, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
            var intersectionPoint = turf.point(intersection_coords_global);
            var intersectionTest = turf.booleanPointInPolygon(intersectionPoint, workingSurface);
            // Check if crossing point is closer to start
            var intersectionDistance_start = turf.distance(intersectionPoint, turf.point(line.line_coords_global[0])) * 1000;
            var intersectionDistance_end = turf.distance(intersectionPoint, turf.point(line.line_coords_global[1])) * 1000;
            var distanceTest = intersectionDistance_start < intersectionDistance_end;
            if (intersectionTest && distanceTest) {
                start_in = { line_id: linesList[linePrev_Index], side: "in" };
                start_out = { line_id: linesList[linePrev_Index], side: "out" };
                // Check continuity
                if (i === 0 || (linePrev.connexions.end_in?.line_id === linesList[i] && linePrev.connexions.end_out?.line_id === linesList[i])) {

                }
                else {
                    console.log("DISCONTINUITY", i);
                    // Correct previous ends to preserve continuity
                    linePrev.connexions.end_in = { line_id: linesList[i], side: "in" };
                    linePrev.connexions.end_out = { line_id: linesList[i], side: "out" };
                    // Change previous starts if the now are equal to ends
                    if (linePrev.connexions.start_in?.line_id === linesList[i] && linePrev.connexions.start_out?.line_id === linesList[i]) {
                        linePrev.connexions.start_in = null;
                        linePrev.connexions.start_out = null;
                    }
                }
            }
            // End
            var lineNext_Index = i + 1;
            if (lineNext_Index > linesList.length - 1) { lineNext_Index = 0 }
            // Check if crossing point is inside workingSurface
            var lineNext = get_line_from_id(linesList[lineNext_Index], testingLinesGroup);
            var intersection_coords_local = get_coords_intersection_two_equations(line, lineNext);
            var intersection_coords_global = map_helpers.local_to_mercator(intersection_coords_local, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
            var intersectionPoint = turf.point(intersection_coords_global);
            var intersectionTest = turf.booleanPointInPolygon(intersectionPoint, workingSurface);
            // Check if crossing point is closer to end
            var intersectionDistance_start = turf.distance(intersectionPoint, turf.point(line.line_coords_global[0])) * 1000;
            var intersectionDistance_end = turf.distance(intersectionPoint, turf.point(line.line_coords_global[1])) * 1000;
            var distanceTest = intersectionDistance_start >= intersectionDistance_end;
            if (intersectionTest && distanceTest) {
                end_in = { line_id: linesList[lineNext_Index], side: "in" };
                end_out = { line_id: linesList[lineNext_Index], side: "out" };
            }
        }


        line.connexions = {
            start_in: start_in,
            start_out: start_out,
            end_in: end_in,
            end_out: end_out,
        };
        detailedLinesList.push(line);
    }

    console.log("detailedLinesList", detailedLinesList);

    return detailedLinesList
}

const get_combination_mainLines = (combination) => {
    // Get starting lines
    var startIndexes = [];
    for (var i = 0; i < combination.length; i++) {
        if ((combination[i].connexions.start_in === null || combination[i].connexions.start_in?.line_id === "perp") && (combination[i].connexions.start_out === null || combination[i].connexions.start_out?.line_id === "perp")) {
            startIndexes.push(i);
        }
    }

    // Create main lines
    var mainLines = [];
    for (var j = 0; j < startIndexes.length; j++) {
        var mainLine = [];
        for (var i = 0; i < combination.length; i++) {
            // Get real index
            var realIndex = startIndexes[j] + i;
            if (realIndex > combination.length - 1) {
                realIndex -= combination.length
            }
            // Add line
            mainLine.push(combination[realIndex]);
            // Check if end of line
            if ((combination[realIndex].connexions.end_in === null || combination[realIndex].connexions.end_in?.line_id === "perp") && (combination[realIndex].connexions.end_out === null || combination[realIndex].connexions.end_out?.line_id === "perp")) {
                mainLines.push(mainLine);
                break
            }
        }
    }

    return mainLines
}

const get_combination_mainLines_all = (capacity, combination, buildingWidth, workingSurface) => {
    var mainLines = get_combination_mainLines(combination);

    // Check if closed circles /!\ COULD BE A BETTER TEST => doesnt work if ther is a closed circle + other lines
    if (mainLines.length === 0 && combination.length > 2) {
        console.log("Closed circle", [...combination]);
        // Get the longest line and split into two parts
        var maxLength = 0;
        var maxIndex = 0;
        for (var i = 0; i < combination.length; i++) {
            // distance between two points : d = sqrt( (x2 - x1)² + (y2 - y1) ² )
            var lineLength = Math.sqrt(Math.pow((combination[i].line_coords_local[1][0] - combination[i].line_coords_local[0][0]), 2) + Math.pow((combination[i].line_coords_local[1][1] - combination[i].line_coords_local[0][1]), 2));
            // var lineLength = turf.distance(turf.point(combination[i].line_coords_global[0]), turf.point(combination[i].line_coords_global[1])) * 1000;
            if (lineLength > maxLength) {
                maxLength = lineLength;
                maxIndex = i;
            }
        }
        // Get middle point
        var middlePoint_x = combination[maxIndex].line_coords_local[0][0] + ((combination[maxIndex].line_coords_local[1][0] - combination[maxIndex].line_coords_local[0][0]) / 2);
        if (combination[maxIndex].line_coords_local[0][0] < combination[maxIndex].line_coords_local[1][0]) {
            middlePoint_x = combination[maxIndex].line_coords_local[1][0] + ((combination[maxIndex].line_coords_local[0][0] - combination[maxIndex].line_coords_local[1][0]) / 2);
        }
        var middlePoint_y = combination[maxIndex].line_coef * middlePoint_x + combination[maxIndex].line_origin;

        // Create two parts of half line
        var halfLine_start = get_line_data(capacity, [combination[maxIndex].line_coords_local[0], [middlePoint_x, middlePoint_y]]);
        halfLine_start.connexions = {
            start_in: combination[maxIndex].connexions.start_in,
            start_out: combination[maxIndex].connexions.start_out,
            end_in: null,
            end_out: null,
        }
        halfLine_start.line_footprint = get_line_footprint(capacity, buildingWidth, halfLine_start, (buildingWidth / 2), workingSurface, true, false);
        // halfLine_start.line_maxHeight = get_line_maxHeight(halfLine_start, capacity.buildable.volume.levels);
        halfLine_start.id = combination[maxIndex].id + "s";

        var halfLine_end = get_line_data(capacity, [[middlePoint_x, middlePoint_y], combination[maxIndex].line_coords_local[1]]);
        halfLine_end.connexions = {
            start_in: null,
            start_out: null,
            end_in: combination[maxIndex].connexions.end_in,
            end_out: combination[maxIndex].connexions.end_out,
        }
        halfLine_end.line_footprint = get_line_footprint(capacity, buildingWidth, halfLine_end, (buildingWidth / 2), workingSurface, false, true);
        // halfLine_end.line_maxHeight = get_line_maxHeight(halfLine_end, capacity.buildable.volume.levels);
        halfLine_end.id = combination[maxIndex].id + "e";

        // Update combination
        var newCombination = [];
        var maxIndex_prev = maxIndex - 1;
        if (maxIndex_prev < 0) { maxIndex_prev = combination.length - 1 }
        var maxIndex_next = maxIndex + 1;
        if (maxIndex_next >= combination.length) { maxIndex_next = 0 }
        for (var i = 0; i < combination.length; i++) {
            if (i === maxIndex_prev) {
                var lineModif = combination[i];
                lineModif.connexions.end_in = {
                    line_id: halfLine_start.id,
                    side: "in",
                };
                lineModif.connexions.end_out = {
                    line_id: halfLine_start.id,
                    side: "out",
                };
                newCombination.push(lineModif);
            }
            else if (i === maxIndex) {
                newCombination.push(halfLine_start);
                newCombination.push(halfLine_end);
            }
            else if (i === maxIndex_next) {
                var lineModif = combination[i];
                lineModif.connexions.start_in = {
                    line_id: halfLine_end.id,
                    side: "in",
                };
                lineModif.connexions.start_out = {
                    line_id: halfLine_end.id,
                    side: "out",
                };
                newCombination.push(lineModif);
            }
            else {
                newCombination.push(combination[i]);
            }
        }

        // Re Create mainLines
        mainLines = get_combination_mainLines(newCombination);
    }

    // Check validity
    var check = check_combination_mainLines(mainLines);

    if (check === true) {
        return mainLines
    }
    else {
        return []
    }
}

const get_combination_footprint_buffer = (capacity, mainLines, workingSurface, availableSurfaces, buffer_main, buffer_second, buildingWidth) => {

    // Get footprint
    var footprints = [];
    var area = 0;
    var centroid = "";
    var buffers = [];
    var takenArea = 0;
    for (var i = 0; i < mainLines.length; i++) {
        var footprint = get_mainLine_footprint(capacity, mainLines[i], workingSurface, availableSurfaces, buffers, buffer_main, takenArea, buildingWidth);
        for (var j = 0; j < footprint.length; j++) {
            if (footprint[j].lines.length > 0) {
                footprints.push(footprint[j]);
                area += footprint[j].area;
                takenArea += footprint[j].area;
                buffers.push(get_mainLine_buffer(capacity, footprint[j], buffer_main, buffer_second));
            }
        }
    }

    return { footprints, buffers, area }

}

const get_combination_footprint_buffer2 = (capacity, mainLines, workingSurface, availableSurfaces, buffer_main, buffer_second, buildingWidth) => {

    // Get footprint
    var footprints = [];
    var area = 0;
    var buffers = [];
    var takenArea = 0;

    var validity = true;
    var message = "";

    for (var i = 0; i < mainLines.length; i++) {
        var footprints_and_data = get_mainLine_footprint(capacity, mainLines[i], workingSurface, availableSurfaces, buffers, buffer_main, takenArea, buildingWidth);
        var footprint = footprints_and_data.footprint;

        validity = footprints_and_data.validity;
        message = footprints_and_data.message;

        for (var j = 0; j < footprint.length; j++) {
            if (footprint[j].lines.length > 0) {
                footprint[j].buffer = get_mainLine_buffer(capacity, footprint[j], buffer_main, buffer_second);
                footprint[j].levels = get_footprint_levels(capacity, footprint[j], capacity?.buildable?.volume?.levels, capacity?.buildable?.volume?.levels_all);

                footprints.push(footprint[j]);
                area += footprint[j].area;
                takenArea += footprint[j].area;
            }
        }
    }

    if (footprints.length <= 0) {
        console.log("ERROR no footprint : ", message);
        return { footprints: [], buffers: [], area: 0, validity, message }
    }

    // Sort by bigger footprint to smaller
    footprints.sort(function (a, b) {
        return b.area - a.area;
    })

    // Check if buffer is not erasing entire building
    if (footprints.length > 1) {
        var buffer_polygon = turf.polygon([footprints[0].buffer.coords_global]);
        for (var i = 1; i < footprints.length; i++) {
            var footprint_polygon = turf.polygon([footprints[i].coords_global]);
            var difference = turf.difference(footprint_polygon, buffer_polygon);
            if (difference === null || turf.area(difference) < (buildingWidth * buildingWidth / 2) || turf.area(difference) < turf.area(footprint_polygon) * 0.5) {
                console.log("ERROR a footprint is erased by buffers", footprints);
                return { footprints: [], buffers: [], area: 0 }
            }
            else if (i < footprints.length - 1) {
                buffer_polygon = turf.union(buffer_polygon, turf.polygon([footprints[i].buffer.coords_global]));
            }
        }
    }

    // Get centoid final
    console.log("footprints", footprints);
    var centroid_final = footprints[0].centroid;
    if (footprints.length > 1) {
        var centroid_list = [];
        for (var i = 1; i < footprints.length; i++) {
            centroid_list.push(footprints[i].centroid);
        }
        var center = turf.center(turf.points(centroid_list));
        centroid_final = center?.geometry?.coordinates;
    }

    return { footprints, buffers, area, centroid: centroid_final, validity, message }

}

const get_level_footrpints = (capacity, level, footprints, footprints_poly, buildingWidth, target_area) => {
    console.log("LEVEL", level.elevation)

    // Initialize values
    var level_current = {
        elevation: level.elevation,
        footprints: [],
        area: 0,
    }


    // Create unique surface
    var surface_union = null;
    if (level.workingSurfaces.length > 0) {
        surface_union = level.workingSurfaces[0];
        if (level.workingSurfaces.length > 1) {
            for (var i = 1; i < level.workingSurfaces.length; i++) {
                surface_union = turf.union(surface_union, level.workingSurfaces[i]);
            }
        }
    }


    // Create footprints
    var level_footprints = [];
    var level_footprints_area = [];
    var level_area = 0;
    var last_area = 0;
    if (surface_union !== null) {
        var footprints_list = footprints;
        if (footprints_poly !== null) {
            footprints_list = footprints_poly;
        }
        footprints_list.forEach((footprint, index) => {
            var footprint_polygon = null;
            if (footprint?.coords_global) {
                footprint_polygon = turf.polygon([footprint.coords_global]);
            }
            else if (footprint?.type === "Feature") {
                footprint_polygon = footprint;
            }
            var footprint_intersection = turf.intersect(surface_union, footprint_polygon);
            if (footprint_intersection !== null) {
                if (footprint_intersection?.geometry?.type === "MultiPolygon") {
                    var footrpint_area_sum = 0;
                    footprint_intersection.geometry.coordinates.forEach((coords, index2) => {
                        var footprint_poly = turf.polygon(coords);
                        var footprint_area = turf.area(footprint_poly);
                        if ((footprint_area + footrpint_area_sum) > footprint?.area) { footprint_area = footprint.area - footrpint_area_sum }
                        else if ((footprint_area + footrpint_area_sum) > footprint?.properties?.area) { footprint_area = footprint.properties.area - footrpint_area_sum }
                        if (footprint_area >= (buildingWidth * buildingWidth / 2)) {
                            footprint_poly.properties.area = footprint_area;
                            footprint_poly.properties.real_area = turf.area(footprint_poly);
                            footprint_poly.properties.footprint_index = index;
                            if (target_area === null || target_area > level_area) {
                                level_area += footprint_area;
                                last_area = footprint_area;
                                level_footprints.push(footprint_poly);
                                level_footprints_area.push(footprint_area);
                            }
                        }
                    })
                }
                else if (footprint_intersection?.geometry?.type === "Polygon") {
                    var footprint_area = turf.area(footprint_intersection);
                    if (footprint_area > footprint?.area) { footprint_area = footprint.area }
                    else if (footprint_area > footprint?.properties?.area) { footprint_area = footprint.properties.area }
                    if (footprint_area >= (buildingWidth * buildingWidth / 2)) {
                        footprint_intersection.properties.area = footprint_area;
                        footprint_intersection.properties.real_area = turf.area(footprint_intersection);
                        footprint_intersection.properties.footprint_index = index;
                        if (target_area === null || target_area > level_area) {
                            level_area += footprint_area;
                            last_area = footprint_area;
                            level_footprints.push(footprint_intersection);
                            level_footprints_area.push(footprint_area);
                        }
                    }
                }
            }
        })

        // Check max area total
        if (target_area !== null && target_area + 1 < level_area) {
            console.log("!!-!!-!!-!! LEVEL AREA", level_area, "BIGGER THAN TARGET", target_area);
            var area_to_keep = target_area;
            console.log("area_to_keep", area_to_keep);
            var area_min = buildingWidth * buildingWidth / 2;
            console.log("area_min", area_min);
            var area_to_remove = level_area - area_to_keep;
            console.log("area_to_remove", area_to_remove);

            // Get max nb of buildings
            var max_nb_footrpint = Math.floor(target_area / area_min);
            if (max_nb_footrpint > level_footprints.length) {
                max_nb_footrpint = level_footprints.length
            }

            var level_footprints_revised = [];
            var level_area_revised = 0;
            for (var footprint_index = 0; footprint_index < max_nb_footrpint; footprint_index++) {
                var footprint_target_area = (target_area - level_area_revised) / (max_nb_footrpint - footprint_index);
                var reducers = reduceFootprint(capacity, footprints[level_footprints[footprint_index].properties.footprint_index], level_footprints[footprint_index], surface_union, footprint_target_area, buildingWidth);
                if (reducers !== null) {
                    level_footprints_revised.push(reducers);
                    level_area_revised += reducers.properties.area;
                }
            }

            level_footprints = level_footprints_revised;
            level_area = level_area_revised;

        }
    }

    level_current.footprints = level_footprints;
    level_current.area = level_area;
    console.log("LEVEL footrpints", level_footprints);

    return level_current
}


const get_buildings_from_footprint_at_level = (capacity, level, footprint, realfootprints_underlevel, buildingWidth, target_area) => {
    // console.log("level", level);
    try {

        // Set variables;
        var realfootprints = [];
        var realfootprints_area = 0;

        // Create unique surface
        var surface_union = null;
        if (level.workingSurfaces.length > 0) {
            surface_union = level.workingSurfaces[0];
            if (level.workingSurfaces.length > 1) {
                for (var i = 1; i < level.workingSurfaces.length; i++) {
                    surface_union = turf.union(surface_union, level.workingSurfaces[i]);
                }
            }
        }
        // console.log("surface_union", surface_union);

        // Create footprint polygon
        var footprint_polygon = null;
        if (realfootprints_underlevel !== null) {
            footprint_polygon = realfootprints_underlevel;
        }
        else {
            if (footprint?.coords_global) {
                footprint_polygon = turf.polygon([footprint.coords_global]);
            }
            else if (footprint?.type === "Feature") {
                footprint_polygon = footprint;
            }
        }
        // console.log("footprint_polygon", footprint_polygon);

        // TO DO : TAKE realfootprints_underlevel if exists

        // Get intersection of footrpint and surface union
        var footprint_intersection = turf.intersect(surface_union, footprint_polygon);
        // console.log("footprint_intersection", footprint_intersection);

        if (footprint_intersection !== null) {

            // Get only polygon
            if (footprint_intersection?.geometry?.type === "MultiPolygon") {
                footprint_intersection = turf.polygon(footprint_intersection.geometry.coordinates[0]);
            }

            var footprint_area_current = turf.area(footprint_intersection);

            // Adapt polygon
            if (target_area !== null && footprint_area_current > target_area && footprint?.area_to_remove) {
                for (var zone_i = 0; zone_i < footprint.area_to_remove.length; zone_i++) {
                    var reducers = reduceFootprint(capacity, footprint.original_footprint, footprint_intersection, capacity.buildable.volume.parameters.buildable_super_zones[footprint.area_to_remove[zone_i].buildable_super_zones_index].perimeter, footprint.area_to_remove[zone_i].area_max, buildingWidth);
                    if (reducers !== null) {
                        footprint_intersection = reducers;
                    }
                    else {
                        console.log("ERROR Building reduced is invalid");
                        return { realfootprints: [], realfootprints_area: 0 }
                    }
                }
            }

            // Set properties
            var footprint_area_real = turf.area(footprint_intersection);
            var footprint_area = turf.area(footprint_intersection);
            // if (footprint_area > footprint?.area) { footprint_area = footprint.area }
            // else if (footprint_area > footprint?.properties?.area) { footprint_area = footprint.properties.area }
            if (footprint_area >= (buildingWidth * buildingWidth / 2)) {
                if (!footprint_intersection?.properties?.area) {
                    footprint_intersection.properties.area = footprint_area;
                }
                if (!footprint_intersection?.properties?._realarea) {
                    footprint_intersection.properties.real_area = footprint_area_real;
                }
                // footprint_intersection.properties.footprint_index = index;
                // if (target_area === null || target_area > level_area) {
                realfootprints_area += footprint_intersection.properties.area;
                // last_area = footprint_area;
                realfootprints.push(footprint_intersection);
                // level_footprints_area.push(footprint_area);
                // }
            }



            // // If multiple intersections
            // if (footprint_intersection?.geometry?.type === "MultiPolygon") {
            //     // var footrpint_area_sum = 0;
            //     footprint_intersection.geometry.coordinates.forEach((coords, index2) => {
            //         var footprint_poly = turf.polygon(coords);
            //         var footprint_area_real = turf.area(footprint_poly);
            //         var footprint_area = turf.area(footprint_poly);
            //         // if ((footprint_area + footrpint_area_sum) > footprint?.area) { footprint_area = footprint.area - footrpint_area_sum }
            //         // else if ((footprint_area + footrpint_area_sum) > footprint?.properties?.area) { footprint_area = footprint.properties.area - footrpint_area_sum }
            //         if (footprint_area >= (buildingWidth * buildingWidth / 2)) {
            //             footprint_poly.properties.area = footprint_area;
            //             footprint_poly.properties.real_area = footprint_area_real;
            //             // footprint_poly.properties.footprint_index = index;
            //             // if (target_area === null || target_area > level_area) {
            //             realfootprints_area += footprint_area;
            //             // last_area = footprint_area;
            //             realfootprints.push(footprint_poly);
            //             // level_footprints_area.push(footprint_area);
            //             // }
            //         }
            //     })
            // }
            // // If one intersection
            // else if (footprint_intersection?.geometry?.type === "Polygon") {
            //     var footprint_area_real = turf.area(footprint_intersection);
            //     var footprint_area = turf.area(footprint_intersection);
            //     // if (footprint_area > footprint?.area) { footprint_area = footprint.area }
            //     // else if (footprint_area > footprint?.properties?.area) { footprint_area = footprint.properties.area }
            //     if (footprint_area >= (buildingWidth * buildingWidth / 2)) {
            //         footprint_intersection.properties.area = footprint_area;
            //         footprint_intersection.properties.real_area = footprint_area_real;
            //         // footprint_intersection.properties.footprint_index = index;
            //         // if (target_area === null || target_area > level_area) {
            //         realfootprints_area += footprint_area;
            //         // last_area = footprint_area;
            //         realfootprints.push(footprint_intersection);
            //         // level_footprints_area.push(footprint_area);
            //         // }
            //     }
            // }
        }

        // console.log("realfootprints", realfootprints);
        return { realfootprints, realfootprints_area }

    } catch (error) {

        console.log("ERROR at get_buildings_from_footprint_at_level", error);
        return { realfootprints: [], realfootprints_area: 0 }

    }

}

const get_combination_levels = (capacity, footprints, levels, buildingWidth) => {
    // Initialize values
    var level_list = [];
    var total_area = 0;

    // Pre calculate target areas
    var level_list_area = Array(levels.length).fill(null);
    if (capacity?.buildable?.volume?.parameters?.max_area_total && capacity?.buildable?.volume?.parameters?.max_area_total !== null) {
        var area_level_medium = capacity?.buildable?.volume?.parameters?.max_area_total / (levels.length - 1);
        var up_level_area = 0;
        var up_level_nb = 0;
        // Get real target area for each level
        for (var i = levels.length - 1; i > 0; i--) {
            var level_i_area = area_level_medium;
            if (levels[i].area < level_i_area) {
                level_i_area = levels[i].area;
            }
            level_list_area[i] = level_i_area;
            up_level_nb++;
            up_level_area += level_i_area;
            area_level_medium = (capacity?.buildable?.volume?.parameters?.max_area_total - up_level_area) / (levels.length - 1 - up_level_nb);
        }
        console.log("level_list_area", level_list_area);
        // IF DIFFERENCE OF AVAILABLE SURFACES => VOLUME NOT STRAIGHT => PRECALCULATE FOOTRPINTS AREAS REAL TO GET PRORATA TARGET AREA
    }

    // for each levels, get all footprints, intersection with all working (or available) surfaces
    for (var level_index = 1; level_index < levels.length; level_index++) {

        var sub_level_footprints = null;
        if (level_index > 1) {
            sub_level_footprints = level_list[level_index - 2].footprints;
        }

        var level_current = null;
        try {
            level_current = get_level_footrpints(capacity, levels[level_index], footprints, sub_level_footprints, buildingWidth, level_list_area[level_index]);
            level_list.push(level_current);
            if (level_current.elevation > 0.01) {
                total_area += level_current.area;
            }
        } catch (error) {
            console.log("LEVEL ERROR", error);
            break;
        }

    }

    if (level_list.length > 0) {
        var ground_level = { ...level_list[0] };
        ground_level.elevation = 0.01;
        level_list.unshift(ground_level);
    }


    return { level_list, total_area }
}




// MAIN LINES

const check_combination_mainLines = (mainLines) => {
    var taken = [];
    var tested = 0;

    for (var i = 0; i < mainLines.length; i++) {
        for (var j = 0; j < mainLines[i].length; j++) {
            tested++;
            if (!taken.includes(mainLines[i][j].id)) {
                taken.push(mainLines[i][j].id);
            }
            else {
                console.log("INVALID MAIN LINES");
                return false
            }
        }
    }

    if (tested === taken.length) {
        return true
    }
    else {
        console.log("INVALID MAIN LINES");
        return false
    }
}

const get_mainLine_buffer = (capacity, footprint, buffer_main, buffer_second) => {
    var buffer = get_line_footprint_buffer(capacity, footprint, buffer_main, buffer_second);
    return buffer
}

const get_line_footprint_buffer = (capacity, line_footprint, buffer_main, buffer_second) => {
    // Get buffers
    var mainBuffer = buffer_main;
    var secondBuffer = buffer_second || buffer_main;
    if (buffer_second === 0) {
        secondBuffer = buffer_second;
    }
    // Get lines
    var lines = [];
    var coords_global = [];
    var coords_local = [];
    for (var i = 0; i < line_footprint.lines.length; i++) {
        // Get offset depending on line side
        var offset = secondBuffer;
        if (line_footprint.lines[i].line_side === "in" || line_footprint.lines[i].line_side === "out") {
            offset = mainBuffer;
        }
        // Get offset line
        var line = get_lineOffset_data(capacity, line_footprint.lines[i], offset);
        line.line_side = line_footprint.lines[i].line_side;
        // Create join line to link line i-1 and line i
        if (i > 0) {
            var linePrevious = lines[lines.length - 1];
            if (linePrevious.line_coords_local[1] !== line.line_coords_local[0]) {
                // JOIN LINE
                // var lineJoin = get_line_data(capacity, [linePrevious.line_coords_local[1], line.line_coords_local[0]]);
                // lineJoin.line_side = "join";
                // lines.push(lineJoin);
                // coords_global.push(lineJoin.line_coords_global[1]);
                // coords_local.push(lineJoin.line_coords_local[1]);
                // INTERSECTION BETWEEN IN OR OUT LINE AND EDGE LINE
                var intersectionCoordsLocal = get_coords_intersection_two_equations(linePrevious, line);
                // Update previous line end point
                var linePreviousNew = get_line_data(capacity, [linePrevious.line_coords_local[0], intersectionCoordsLocal]);
                linePreviousNew.line_side = linePrevious.line_side;
                lines[lines.length - 1] = linePreviousNew;
                // Update current line start point
                var lineNew = get_line_data(capacity, [intersectionCoordsLocal, line.line_coords_local[1]]);
                lineNew.line_side = line.line_side;
                line = lineNew;
            }
        }
        else {
            coords_global.push(line.line_coords_global[0]);
            coords_local.push(line.line_coords_local[0]);
        }
        // Get intersetion if last line
        if (i === line_footprint.lines.length - 1) {
            var lineNext = lines[0];
            if (lineNext.line_coords_local[0] !== line.line_coords_local[1]) {
                // INTERSECTION BETWEEN IN OR OUT LINE AND EDGE LINE
                var intersectionCoordsLocal = get_coords_intersection_two_equations(line, lineNext);
                // Update previous line start point
                var lineNextNew = get_line_data(capacity, [intersectionCoordsLocal, lineNext.line_coords_local[1]]);
                lineNextNew.line_side = lineNext.line_side;
                lines[0] = lineNextNew;
                // Update current line end point
                var lineNew = get_line_data(capacity, [line.line_coords_local[0], intersectionCoordsLocal]);
                lineNew.line_side = line.line_side;
                line = lineNew;
            }
        }
        // Push line
        lines.push(line);
        coords_global.push(line.line_coords_global[1]);
        coords_local.push(line.line_coords_local[1]);
        // Create join line to link last line with first one
        // if (i === line_footprint.lines.length - 1) {
        //     var lineNext = lines[0];
        //     if (lineNext.line_coords_local[0] !== line.line_coords_local[1]) {
        //         // JOIN LINE
        //         var lineJoin = get_line_data(capacity, [line.line_coords_local[1], lineNext.line_coords_local[0]]);
        //         lineJoin.line_side = "join";
        //         lines.push(lineJoin);
        //         coords_global.push(lineJoin.line_coords_global[1]);
        //         coords_local.push(lineJoin.line_coords_local[1]);
        //     }
        // }
    }

    // Get coords
    coords_local = [];
    coords_global = [];
    for (var i = 0; i < lines.length; i++) {
        if (i === 0) {
            coords_local.push(lines[i].line_coords_local[0]);
            coords_global.push(lines[i].line_coords_global[0]);
        }
        coords_local.push(lines[i].line_coords_local[1]);
        coords_global.push(lines[i].line_coords_global[1]);
    }

    // Create footprint_offset
    var footprint_offset = {
        lines: lines,
        coords_local: coords_local,
        coords_global: coords_global,
    }

    return footprint_offset

}

const get_mainLine_footprint = (capacity, mainLine, workingSurface, availableSurfaces, buffers, buffer_main, takenArea, buildingWidth) => {
    var lines = [];
    var coords_local = [];
    var coords_global = [];

    var create_first_edge = true;
    var begin_out_index = mainLine.length - 1;
    var start_out_coords_local = null;

    var validity = true;
    var message = "";

    // First loop for in side
    for (var i = 0; i < mainLine.length; i++) {
        var footprint_line_index = 0; // The "in" side line of footprint
        // Start
        var start_coords_local = [];
        var start_connexion = mainLine[i].connexions.start_in;
        if (start_connexion === null) { // If connexion is null => get line_footprint with side "in" (index = 0)
            start_coords_local = mainLine[i].line_footprint.lines[footprint_line_index].line_coords_local[0];
        }
        else if (start_connexion?.line_id === "perp") {
            var crossing_line = {
                line_coef: mainLine[i].linePerp_coef,
                line_origin: mainLine[i].linePerp_start_origin,
            }
            start_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
            if (start_connexion?.value && start_connexion.value !== 0) {
                var basic_line = get_line_data(capacity, [start_coords_local, mainLine[i].line_coords_local[0]]);
                crossing_line = get_lineOffset_data(capacity, basic_line, -start_connexion.value);
                start_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
            }
        }
        else {
            var crossing_line = get_line_from_id(start_connexion.line_id, mainLine);
            start_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line.line_footprint.lines[footprint_line_index]);
        }
        // End
        var end_coords_local = [];
        var end_connexion = mainLine[i].connexions.end_in;
        if (end_connexion === null) { // If connexion is null => get line_footprint with side "in" (index = 0)
            end_coords_local = mainLine[i].line_footprint.lines[footprint_line_index].line_coords_local[1];
        }
        else if (end_connexion?.line_id === "perp") {
            var crossing_line = {
                line_coef: mainLine[i].linePerp_coef,
                line_origin: mainLine[i].linePerp_end_origin,
            }
            end_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
            if (end_connexion?.value && end_connexion.value !== 0) {
                var basic_line = get_line_data(capacity, [end_coords_local, mainLine[i].line_coords_local[1]]);
                crossing_line = get_lineOffset_data(capacity, basic_line, end_connexion.value);
                end_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
            }
        }
        else {
            var crossing_line = get_line_from_id(end_connexion.line_id, mainLine);
            end_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line.line_footprint.lines[footprint_line_index]);
        }
        // Line
        var line = get_line_data(capacity, [start_coords_local, end_coords_local]);
        if (i > 1) {
            var start_point = turf.point(line.line_coords_global[0]);
            // KINK CHECK -- Check if line is not crossing previous in lines
            var checkLine = turf.lineString(coords_global.slice(0, -1));
            var line_cross_in = turf.lineIntersect(turf.lineString(line.line_coords_global), checkLine);
            var check_line_cross_in = line_cross_in.features.length > 0;
            if (check_line_cross_in === true) {
                console.log("CROSSING LINE", line_cross_in);
                // TO DO : get exact line (closer intersection) + replace this line with new end point as intersection + delete lines between + create this new line with start point as intersection
                // Get intersection point & line
                var line_in = null;
                var line_index = null;
                var intersection_point = null;
                var intersection_distance_from_start = 100000000;
                for (var k = 1; k < checkLine.geometry.coordinates.length; k++) {
                    var intersection = turf.lineIntersect(turf.lineString(line.line_coords_global), turf.lineString([checkLine.geometry.coordinates[k - 1], checkLine.geometry.coordinates[k]]));
                    if (intersection.features.length > 0) {
                        var distance_k = turf.distance(start_point, intersection.features[0]) * 1000;
                        if (distance_k < intersection_distance_from_start) {
                            intersection_point = line_cross_in.features[0];
                            intersection_distance_from_start = distance_k;
                            line_in = lines[k - 1];
                            line_index = k - 1;
                        }
                    }
                }

                // Check it is coming out from footprint and not coming in (then it is a buffer concern) => if distance to crossing_offset > distance to crossing then it is kink
                var test_offset_line = get_lineOffset_data(capacity, line_in, 0.1);
                var intersection_offset = turf.lineIntersect(turf.lineString(line.line_coords_global), turf.lineString(test_offset_line.line_coords_global));
                if (intersection_offset.features.length > 0) {
                    var distance_offset = turf.distance(start_point, intersection_offset.features[0]) * 1000;
                    if (distance_offset > intersection_distance_from_start) {
                        console.log("CROSSING FROM IN TO OUT");

                        // Recreate crossing "in" line
                        var crossing_line_end_coords_local = map_helpers.mercator_to_local(intersection_point.geometry.coordinates, capacity.landBase.union.bbox.origin);
                        var crossing_line = get_line_data(capacity, [lines[line_index].line_coords_local[0], crossing_line_end_coords_local]);
                        crossing_line.line_side = "in";
                        lines[line_index] = crossing_line;
                        coords_local[line_index + 1] = crossing_line.line_coords_local[1];
                        coords_global[line_index + 1] = crossing_line.line_coords_global[1];

                        // Delete "in" lines between crossing and current one
                        lines.length = line_index + 1;
                        coords_local.length = line_index + 2;
                        coords_global.length = line_index + 2;

                        // Recreate the current "in" line
                        line = get_line_data(capacity, [crossing_line_end_coords_local, end_coords_local]);
                    }
                    else {
                        console.log("CROSSING FROM OUT TO IN (1)");
                        console.log("INVALID FOOTPRINT");
                        // return {
                        //     lines: [],
                        //     coords_local: [],
                        //     coords_global: [],
                        //     area: 0,
                        // }
                        return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : croisement de lignes OUT TO IN" };
                    }
                }
                else {
                    console.log("CROSSING FROM OUT TO IN (2)");
                    console.log("INVALID FOOTPRINT");
                    // return {
                    //     lines: [],
                    //     coords_local: [],
                    //     coords_global: [],
                    //     area: 0,
                    // }
                    return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : croisement de lignes OUT TO IN" };
                }
            }


            // BUFFER CHECK -- Check if line is not crossing previous in lines buffer
            var checkLine_buffer = turf.buffer(checkLine, buffer_main / 1000, { steps: 1 });
            // Problem if start point is outside buffer && line is crossing buffer
            start_point = turf.point(line.line_coords_global[0]);
            var check_start_outside = !turf.booleanPointInPolygon(start_point, checkLine_buffer);
            var line_cross = turf.lineIntersect(turf.lineString(line.line_coords_global), turf.polygonToLine(checkLine_buffer));
            var check_line_cross = line_cross.features.length > 0;
            if (check_start_outside === true && check_line_cross === true && mainLine[i].id.slice(-1) !== "s" && mainLine[i].id.slice(-1) !== "e") { // id check in case of closing circle
                console.log("CROSSING BUFFER", line_cross);
                if (i < mainLine.length - 1) {
                    // Buffer crossing before last mailLine => INVALID FOOTPRINT
                    console.log("INVALID FOOTPRINT");
                    // return {
                    //     lines: [],
                    //     coords_local: [],
                    //     coords_global: [],
                    //     area: 0,
                    // }
                    return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : crossing buffer" };

                }
                // Get intersection point & buffer line
                var buffer_line_coords_global = [];
                var intersection_line = null;
                var intersection_point = null;
                var intersection_distance_from_start = 100000000;
                // for (var k = 1; k < checkLine_buffer.geometry.coordinates[0].length; k++) {
                //     var intersection = turf.lineIntersect(turf.lineString(line.line_coords_global), turf.lineString([checkLine_buffer.geometry.coordinates[0][k - 1], checkLine_buffer.geometry.coordinates[0][k]]));
                //     if (intersection.features.length > 0) {
                //         var distance_k = turf.distance(start_point, intersection.features[0]) * 1000;
                //         if (distance_k < intersection_distance_from_start) {
                //             intersection_point = line_cross.features[0];
                //             intersection_distance_from_start = distance_k;
                //             buffer_line_coords_global = turf.getCoords(turf.lineString([checkLine_buffer.geometry.coordinates[0][k - 1], checkLine_buffer.geometry.coordinates[0][k]]));
                //         }
                //     }
                // }
                for (var k = 0; k < lines.length - 1; k++) {
                    var checkLine_offset = get_lineOffset_data(capacity, lines[k], buffer_main);
                    var intersection = turf.lineIntersect(turf.lineString(line.line_coords_global), turf.lineString(checkLine_offset.line_coords_global));

                    if (intersection.features.length > 0) {
                        var distance_k = turf.distance(start_point, intersection.features[0]) * 1000;
                        if (distance_k < intersection_distance_from_start) {
                            intersection_line = checkLine_offset;
                            intersection_point = line_cross.features[0];
                            intersection_distance_from_start = distance_k;
                            buffer_line_coords_global = turf.getCoords(turf.lineString([checkLine_buffer.geometry.coordinates[0][k - 1], checkLine_buffer.geometry.coordinates[0][k]]));
                        }
                    }
                }
                if (intersection_point !== null) {

                    // var buffer_line = get_line_data(capacity, [map_helpers.mercator_to_local(buffer_line_coords_global[0], capacity.landBase.union.bbox.origin), map_helpers.mercator_to_local(buffer_line_coords_global[1], capacity.landBase.union.bbox.origin)]);
                    var buffer_line = intersection_line;

                    // Recreate "in" line
                    end_coords_local = map_helpers.mercator_to_local(intersection_point.geometry.coordinates, capacity.landBase.union.bbox.origin);
                    line = get_line_data(capacity, [start_coords_local, end_coords_local]);
                    line.line_side = "in";
                    lines.push(line);
                    coords_local.push(line.line_coords_local[1]);
                    coords_global.push(line.line_coords_global[1]);

                    // Get "out" intersection
                    start_out_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[2], buffer_line);

                    // Set begin_out_index
                    begin_out_index = i;

                    break;
                }
            }
        }

        line.line_side = "in";
        lines.push(line);
        if (i === 0) {
            coords_local.push(line.line_coords_local[0]);
            coords_global.push(line.line_coords_global[0]);
        }
        coords_local.push(line.line_coords_local[1]);
        coords_global.push(line.line_coords_global[1]);
    }

    // Second loop for out side & edges
    for (var i = begin_out_index; i >= 0; i--) {
        var footprint_line_index = 2; // The "out" side line of footprint
        // Start
        var start_coords_local = [];
        if (i === begin_out_index && start_out_coords_local != null) {
            start_coords_local = start_out_coords_local;
        }
        else {
            var start_connexion = mainLine[i].connexions.end_out; // START & END ARE INVERTED BECAUSE HERE IS WORKING CLOCKWISE
            if (start_connexion === null) { // If connexion is null => get line_footprint
                start_coords_local = mainLine[i].line_footprint.lines[footprint_line_index].line_coords_local[0];
            }
            else if (start_connexion?.line_id === "perp") {
                var crossing_line = {
                    line_coef: mainLine[i].linePerp_coef,
                    line_origin: mainLine[i].linePerp_end_origin,
                }
                start_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
                if (start_connexion?.value && start_connexion.value !== 0) {
                    var basic_line = get_line_data(capacity, [start_coords_local, mainLine[i].line_coords_local[1]]);
                    crossing_line = get_lineOffset_data(capacity, basic_line, -start_connexion.value);
                    start_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
                }
            }
            else {
                var crossing_line = get_line_from_id(start_connexion.line_id, mainLine);
                start_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line.line_footprint.lines[footprint_line_index]);
            }
        }
        // End
        var end_coords_local = [];
        var end_connexion = mainLine[i].connexions.start_out; // START & END ARE INVERTED BECAUSE HERE IS WORKING CLOCKWISE
        if (end_connexion === null) { // If connexion is null => get line_footprint
            end_coords_local = mainLine[i].line_footprint.lines[footprint_line_index].line_coords_local[1];
        }
        else if (end_connexion?.line_id === "perp") {
            var crossing_line = {
                line_coef: mainLine[i].linePerp_coef,
                line_origin: mainLine[i].linePerp_start_origin,
            }
            end_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
            if (end_connexion?.value && end_connexion.value !== 0) {
                var basic_line = get_line_data(capacity, [end_coords_local, mainLine[i].line_coords_local[0]]);
                crossing_line = get_lineOffset_data(capacity, basic_line, end_connexion.value);
                end_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line);
            }
        }
        else {
            var crossing_line = get_line_from_id(end_connexion.line_id, mainLine);
            end_coords_local = get_coords_intersection_two_equations(mainLine[i].line_footprint.lines[footprint_line_index], crossing_line.line_footprint.lines[footprint_line_index]);
        }
        // First Edge
        // if (i === (mainLine.length - 1)) {
        if (create_first_edge === true) {
            var edgeLine = get_line_data(capacity, [lines[lines.length - 1].line_coords_local[1], start_coords_local]);
            edgeLine.line_side = "edge";
            lines.push(edgeLine);
            coords_local.push(edgeLine.line_coords_local[1]);
            coords_global.push(edgeLine.line_coords_global[1]);

            create_first_edge = false;
        }
        // Line
        var line = get_line_data(capacity, [start_coords_local, end_coords_local]);
        line.line_side = "out";
        lines.push(line);
        coords_local.push(line.line_coords_local[1]);
        coords_global.push(line.line_coords_global[1]);
        // Last Edge
        if (i === 0) {
            var edgeLine = get_line_data(capacity, [end_coords_local, lines[0].line_coords_local[0]]);
            edgeLine.line_side = "edge";
            lines.push(edgeLine);
            coords_local.push(edgeLine.line_coords_local[1]);
            coords_global.push(edgeLine.line_coords_global[1]);
        }
    }

    // CHECK VALIDITY
    // Check kinks if not closed circle
    if (checkKinkedFootprint(coords_global, mainLine[0])) {
        console.log("INVALID FOOTPRINT from kinked polygon");
        // return [{
        //     lines: [],
        //     coords_local: [],
        //     coords_global: [],
        //     area: 0,
        // }]
        // return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : kinked polygon" };
        validity = false;
        message = "La géométrie n'est pas réalisable : kinked polygon";
    }
    // Check if no angle too big (more than 315 deg)
    lines = get_lines_angle(lines);
    if (checkAngleFootprint(lines) === true) {
        console.log("INVALID FOOTPRINT from too big angles");
        // return [{
        //     lines: [],
        //     coords_local: [],
        //     coords_global: [],
        //     area: 0,
        // }]
        // return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : angle > 315 deg" };
        validity = false;
        message = "La géométrie n'est pas réalisable : angle > 315 deg";
    }
    // Check if out lines are not too big compare to in lines and vice versa
    var inLine_length = 0;
    var outLine_length = 0;
    var edgeLine_length = 0;
    for (var line_i = 0; line_i < lines.length; line_i++) {
        if (lines[line_i].line_side === "in") {
            inLine_length += lines[line_i].line_length;
        }
        else if (lines[line_i].line_side === "out") {
            outLine_length += lines[line_i].line_length;
        }
        else {
            edgeLine_length += lines[line_i].line_length;
        }
    }
    if (inLine_length + edgeLine_length < outLine_length * 0.75) {
        console.log("INVALID FOOTPRINT from too big difference from outlines and inlines");
        console.log("inLine_length", inLine_length);
        console.log("outLine_length", outLine_length);
        console.log("edgeLine_length", edgeLine_length);
        // return [{
        //     lines: [],
        //     coords_local: [],
        //     coords_global: [],
        //     area: 0,
        // }]
        // return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : inLine length > outLine length" };
        validity = false;
        message = "La géométrie n'est pas réalisable : inLine length > outLine length";
    }


    // Create current footrint
    var original_footprint = {
        lines: lines,
        coords_local: coords_local,
        coords_global: coords_global,
        area: turf.area(turf.polygon([coords_global])),
        lengths: {
            in: inLine_length,
            out: outLine_length,
            edge: edgeLine_length,
            total: inLine_length + outLine_length + edgeLine_length
        }
    }
    var currentFootrpints = [{
        lines: lines,
        coords_local: coords_local,
        coords_global: coords_global,
        area: turf.area(turf.polygon([coords_global])),
        lengths: {
            in: inLine_length,
            out: outLine_length,
            edge: edgeLine_length,
            total: inLine_length + outLine_length + edgeLine_length
        },
        original_footprint: original_footprint
    }];

    // Compare to current working Surface
    // Get intersection area
    var intersection = turf.intersect(turf.polygon([coords_global]), workingSurface);
    var intersection_area = turf.area(intersection);
    if (currentFootrpints[0].area > intersection_area) {
        console.log("ADAPT FOOTPRINT TO CURRENT WORKING SURFACE", intersection);
        // Get only polygon type
        if (intersection?.geometry?.type === "MultiPolygon") {
            console.log("----- INTERSECTION IS MULTIPOLYGON");
            intersection = turf.polygon(intersection?.geometry?.coordinates[0]);
        }
        if (intersection?.geometry?.type !== "Polygon") {
            console.log("ERROR : Building is not a polygon");
            // return { buildings: [], area_total: 0, area_total_real: 0 }
            return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : building is not 'polygon' shape" };
        }
        var recreatedFootrpint = recreateFootprint(capacity, intersection, lines);
        if (recreatedFootrpint.lines.length > 0) {
            currentFootrpints = [{
                lines: recreatedFootrpint.lines,
                coords_local: recreatedFootrpint.coords_local,
                coords_global: recreatedFootrpint.coords_global,
                lengths: recreatedFootrpint.lengths,
                area: turf.area(turf.polygon([recreatedFootrpint.coords_global])),
                original_footprint: original_footprint
            }];
        }
        else {
            console.log("ERROR : Building is invalid after recreation");
            // return [{
            //     lines: [],
            //     coords_local: [],
            //     coords_global: [],
            //     area: 0,
            // }]
            return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : recréation du polygone impossible" };
        }
    }

    // Compare to buffers
    if (buffers.length > 0) {
        var footprint = turf.polygon([coords_global]);
        for (var i = 0; i < buffers.length; i++) {
            if (footprint !== null) {
                footprint = turf.difference(footprint, turf.polygon([buffers[i].coords_global]));
            }
        }
        console.log("footprint diff", footprint);
        if (footprint === null) {
            console.log("ERROR : FOOTPRINT DIFF BY BUFFERS IS NOT A POLYGON", footprint);
            // return [{
            //     lines: [],
            //     coords_local: [],
            //     coords_global: [],
            //     area: 0,
            // }]
            return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : buffer erase polygon" };
        }
        // Get only polygon type
        if (footprint?.geometry?.type === "MultiPolygon") {
            footprint = turf.polygon(intersection?.geometry?.coordinates[0]);
        }
        if (footprint?.geometry?.type !== "Polygon") {
            console.log("ERROR : Building is not a polygon");
            // return { buildings: [], area_total: 0, area_total_real: 0 }
            return { footprint: [], validity: false, message: "La géométrie n'est pas réalisable : building is not 'polygon' shape" };
        }

        if (footprint.geometry.type === "Polygon") {
            var recreatedFootrpint = recreateFootprint(capacity, footprint, lines);
            // coords_global = recreatedFootrpint.coords_global;
            // coords_local = recreatedFootrpint.coords_local;
            // lines = recreatedFootrpint.lines;
            currentFootrpints = [{
                lines: recreatedFootrpint.lines,
                coords_local: recreatedFootrpint.coords_local,
                coords_global: recreatedFootrpint.coords_global,
                lengths: recreatedFootrpint.lengths,
                area: turf.area(turf.polygon([recreatedFootrpint.coords_global])),
                original_footprint: original_footprint,
            }];
        }
        // else if (footprint.geometry.type === "MultiPolygon") {
        //     var footprintList = [];
        //     for (var i = 0; i < footprint.geometry.coordinates.length; i++) {
        //         var footprint_i = turf.polygon(footprint.geometry.coordinates[i]);
        //         var recreatedFootrpint = recreateFootprint(capacity, footprint_i, lines);
        //         footprintList.push({
        //             lines: recreatedFootrpint.lines,
        //             coords_local: recreatedFootrpint.coords_local,
        //             coords_global: recreatedFootrpint.coords_global,
        //             lengths: recreatedFootrpint.lengths,
        //             area: turf.polygon([recreatedFootrpint.coords_global]),
        //             // area: turf.area(footprint_i),
        //             original_footprint: original_footprint,
        //         });
        //     }
        //     // return footprintList
        //     currentFootrpints = footprintList;
        // }
    }
    // Check alignments
    if (capacity?.buildable?.volume?.parameters?.alignments && capacity?.buildable?.volume?.parameters?.alignments.length > 0) {
        var alignCheck = null;
        capacity?.buildable?.volume?.parameters?.alignments.forEach(alignment => {
            // Check buffer line
            var alignCheck_line = null;
            if (alignment?.bounds_buffers_line && alignment?.bounds_buffers_line.length > 0) {
                for (var align_i = 0; align_i < alignment?.bounds_buffers_line.length; align_i++) {
                    var align_buffer = alignment.bounds_buffers_line[align_i];
                    var alignCheck_line_current = false;
                    for (line_i = 0; line_i < currentFootrpints[0].lines.length; line_i++) {
                        if ((alignment?.facades?.main === true && currentFootrpints[0].lines[line_i]?.line_side === "out") || (alignment?.facades?.second === true && currentFootrpints[0].lines[line_i]?.line_side === "edge")) {
                            var line_buffer = turf.buffer(turf.lineString(currentFootrpints[0].lines[line_i].line_coords_global), 0.0001, { steps: 1 });
                            var intersection = turf.intersect(align_buffer, line_buffer);
                            console.log("LINE", line_i, "intersection", intersection);
                            if (intersection === null) {
                                continue;
                            }
                            console.log("turf.area(intersection)", turf.area(intersection), "turf.area(line_buffer)", turf.area(line_buffer), "turf.area(align_buffer)", turf.area(align_buffer));
                            if (turf.area(intersection) >= Math.min(turf.area(line_buffer) * 0.3, turf.area(align_buffer) * 0.7)) {
                                alignCheck_line_current = true;
                                break;
                            }
                        }
                    }
                    if (alignCheck_line_current === false) {
                        alignCheck_line = false;
                        if (alignment.alignAll === true) {
                            break;
                        }
                    }
                    else if (alignCheck_line_current === true) {
                        alignCheck_line = true;
                        if (alignment.alignAll === false) {
                            break;
                        }
                    }
                }
            }
            console.log("alignCheck_line", alignCheck_line);


            // Check buffer offset max
            var alignCheck_offsetMax = null;
            if (alignCheck_line === false && alignment?.bounds_buffers_offset_max && alignment?.bounds_buffers_offset_max.length > 0) {
                for (var align_i = 0; align_i < alignment?.bounds_buffers_offset_max.length; align_i++) {
                    var align_buffer = alignment.bounds_buffers_offset_max[align_i];
                    var alignCheck_offsetMax_current = false;
                    for (line_i = 0; line_i < currentFootrpints[0].lines.length; line_i++) {
                        if ((alignment?.facades?.main === true && currentFootrpints[0].lines[line_i]?.line_side === "in") || (alignment?.facades?.main === true && currentFootrpints[0].lines[line_i]?.line_side === "out") || (alignment?.facades?.second === true && currentFootrpints[0].lines[line_i]?.line_side === "edge")) {
                            var line_buffer = turf.buffer(turf.lineString(currentFootrpints[0].lines[line_i].line_coords_global), 0.0001, { steps: 1 });
                            var intersection = turf.intersect(align_buffer, line_buffer);
                            var bearing = turf.bearing(turf.point(currentFootrpints[0].lines[line_i].line_coords_global[0]), currentFootrpints[0].lines[line_i].line_coords_global[1]);
                            if (bearing < 0) { bearing += 180 };
                            console.log("LINE", line_i, "intersection", intersection, "bearing", bearing);
                            if (intersection === null) {
                                continue;
                            }
                            console.log("turf.area(intersection)", turf.area(intersection), "turf.area(line_buffer)", turf.area(line_buffer), "turf.area(align_buffer)", turf.area(align_buffer));
                            if ((bearing >= align_buffer.properties.bearing - 5 & bearing <= align_buffer.properties.bearing + 5) && turf.area(intersection) >= Math.min(turf.area(line_buffer) * 0.3, turf.area(align_buffer) * 0.7)) {
                                alignCheck_offsetMax_current = true;
                                break;
                            }
                        }
                    }
                    if (alignCheck_offsetMax_current === false) {
                        alignCheck_offsetMax = false;
                        if (alignment.alignAll === true) {
                            break;
                        }
                    }
                    else if (alignCheck_offsetMax_current === true) {
                        alignCheck_offsetMax = true;
                        if (alignment.alignAll === false) {
                            break;
                        }
                    }
                }
            }
            console.log("alignCheck_offsetMax", alignCheck_offsetMax);

            // Check buffer offset min
            var alignCheck_offsetMin = null;
            if (alignCheck_line === false && alignment?.bounds_buffers_offset_min && alignment?.bounds_buffers_offset_min.length > 0) {
                for (var align_i = 0; align_i < alignment?.bounds_buffers_offset_min.length; align_i++) {
                    var align_buffer = turf.difference(capacity?.landBase?.union?.geometry, alignment.bounds_buffers_offset_min[align_i]);
                    // var align_buffer = alignment.bounds_buffers_offset_min[align_i];
                    var alignCheck_offsetMin_current = false;
                    for (line_i = 0; line_i < currentFootrpints[0].lines.length; line_i++) {
                        if ((alignment?.facades?.main === true && currentFootrpints[0].lines[line_i]?.line_side === "in") || (alignment?.facades?.main === true && currentFootrpints[0].lines[line_i]?.line_side === "out") || (alignment?.facades?.second === true && currentFootrpints[0].lines[line_i]?.line_side === "edge")) {
                            var line_buffer = turf.buffer(turf.lineString(currentFootrpints[0].lines[line_i].line_coords_global), 0.0001, { steps: 1 });
                            var intersection = turf.intersect(align_buffer, line_buffer);
                            console.log("LINE", line_i, "intersection", intersection);
                            if (intersection === null) {
                                alignCheck_offsetMin_current = false;
                                break;
                            }
                            console.log("turf.area(intersection)", turf.area(intersection), "turf.area(line_buffer)", turf.area(line_buffer), "turf.area(align_buffer)", turf.area(align_buffer));
                            if (turf.area(intersection) >= Math.min(turf.area(line_buffer) * 0.3, turf.area(align_buffer) * 0.7)) {
                                alignCheck_offsetMin_current = true;
                                // break;
                            }
                            else {
                                alignCheck_offsetMin_current = false;
                                break;
                            }
                        }
                    }
                    if (alignCheck_offsetMin_current === false) {
                        alignCheck_offsetMin = false;
                        if (alignment.alignAll === true) {
                            break;
                        }
                    }
                    else if (alignCheck_offsetMin_current === true) {
                        alignCheck_offsetMin = true;
                        if (alignment.alignAll === false) {
                            break;
                        }
                    }
                }
            }
            console.log("alignCheck_offsetMin", alignCheck_offsetMin);


            // Final check
            alignCheck = false;
            if (alignCheck_line === true) {
                alignCheck = true;
            }
            if (alignCheck_offsetMax === true) {
                alignCheck = true;
            }
            if (alignCheck_offsetMin === false) {
                alignCheck = false;
            }
        })

        if (alignCheck === false) {
            console.log("ERROR : Building is not valid for alignments");
            // return { buildings: [], area_total: 0, area_total_real: 0 }
            // return { footprint: [], validity: false, message: "Règle PLU : La combinaison ne respecte pas la règle d'alignement" };
            validity = false;
            message = "Règle PLU : La combinaison ne respecte pas la règle d'alignement";
        }

    }


    // Adapt footprint to buildable area
    // for (var i = 0; i < currentFootrpints.length; i++) {
    //     // Get the minimized version of i footprint
    //     var minimizedFootrpint = adaptFootprint(capacity, currentFootrpints[i], availableSurfaces, takenArea, buildingWidth);
    //     console.log("minimizedFootrpint", minimizedFootrpint);
    //     // var minimizedFootrpint = minimizeFootprint(capacity, currentFootrpints[i], availableSurfaces, takenArea, buildingWidth);
    //     if (minimizedFootrpint !== null && minimizedFootrpint.area > (buildingWidth * buildingWidth / 2)) {
    //         minimizedFootrpint.original_footprint = original_footprint;
    //         currentFootrpints[i] = minimizedFootrpint;
    //         takenArea += minimizedFootrpint.area;
    //     }
    //     else {
    //         currentFootrpints[i] = {
    //             lines: [],
    //             coords_local: [],
    //             coords_global: [],
    //             area: 0,
    //         };
    //     }
    // }

    // Get footprint max_height && centroid
    for (var i = 0; i < currentFootrpints.length; i++) {
        if (currentFootrpints[i].area > 0) {
            var maxHeightFootrpint = getMaxHeightFootrpint(capacity, currentFootrpints[i]);
            currentFootrpints[i].max_height = maxHeightFootrpint;
            var centroid = turf.centroid(turf.polygon([currentFootrpints[i].coords_global]));
            var centroid_coords = centroid?.geometry?.coordinates;
            currentFootrpints[i].centroid = centroid_coords;
        }
    }


    console.log("currentFootrpints", currentFootrpints);

    // Send result
    return { footprint: currentFootrpints, validity, message }
}



// FOORPTINTS

const checkKinkedFootprint = (coords_global, mainLine0) => {
    var kinks = turf.kinks(turf.polygon([coords_global]));
    if (kinks.features.length > 0) {
        console.log("KINKED POLYGON", kinks);
        // Check if closed circle
        if (mainLine0?.id && (mainLine0.id.slice(-1) === "s" || mainLine0.id.slice(-1) === "e")) {
            // If closed circle and kinks = 2 features => nominal
            if (kinks.features.length === 2) {
                console.log("KINKED POLYGON BUT CLOSED CIRCLE");
                return false
            }
        }

        return true
    }
    else {
        return false
    }
}

const reduceFootprint = (capacity, footprint_model, surface_intersect, surface_check_area, area_max, buildingWidth) => {
    // footprint model = footprint original to work with lines
    // surface_intersect = footprint current that may be cutted off by buffers or buildable volume
    // surface_check_area = surface on which the area_max has to be matched
    console.log("footprint_model", footprint_model);
    console.log("surface_intersect", surface_intersect);
    console.log("surface_check_area", surface_check_area);
    console.log("area_max", area_max);

    var matching_current = turf.intersect(surface_intersect, surface_check_area);
    var area_current = 0;
    if (matching_current !== null) {
        area_current = turf.area(matching_current);
    }
    console.log("area_current", area_current);
    var area_to_remove = area_current - area_max;
    console.log("area_to_remove", area_to_remove);

    if (area_to_remove <= 0) {
        console.log("AREA TO REMOVE IS NEGATIVE OR NULL");
        return surface_intersect
    }

    if (footprint_model?.original_footprint) {
        footprint_model = footprint_model.original_footprint;
    }

    // Get edges to reduce (the ones that are inside surface_check_area)
    var edges_to_reduce = [];
    footprint_model.lines.forEach((line, index) => {
        if (line?.line_side === "edge") {
            var line_turf = turf.lineString(line.line_coords_global);
            var line_turf_buffer = turf.buffer(line_turf, 0.0001, { steps: 1 });
            var intersection_line = turf.intersect(line_turf_buffer, surface_check_area);
            if (intersection_line !== null && turf.area(intersection_line) >= turf.area(line_turf_buffer) * 0.45) {
                // Get start & end lines
                var index_next = index + 1;
                var index_prev = index - 1;
                var line_next = null;
                var line_prev = null;
                var smallest_line = null;
                for (var i = 0; i < footprint_model.lines.length; i++) {
                    if (index_next > footprint_model.lines.length - 1) { index_next = 0 }
                    if (index_prev < 0) { index_prev = footprint_model.lines.length - 1 }

                    if (line_next === null && (footprint_model.lines[index_next].line_side === "in" || footprint_model.lines[index_next].line_side === "out")) {
                        line_next = footprint_model.lines[index_next];
                        line_next.length = get_line_length(line_next.line_coords_local);
                    }
                    if (line_prev === null && (footprint_model.lines[index_prev].line_side === "in" || footprint_model.lines[index_prev].line_side === "out")) {
                        line_prev = footprint_model.lines[index_prev];
                        line_prev.length = get_line_length(line_prev.line_coords_local);
                    }

                    if (line_next !== null && line_prev !== null) {
                        if (line_prev.length <= line_next.length) {
                            smallest_line = "line_prev";
                        }
                        else {
                            smallest_line = "line_next";
                        }
                        break;
                    }

                    index_next++;
                    index_prev--;
                }

                edges_to_reduce.push({
                    edges: [line],
                    line_next: line_next,
                    line_prev: line_prev,
                    smallest_line: smallest_line
                });


            }
        }
    })
    console.log("edges_to_reduce", edges_to_reduce);


    // Create reducers
    var reducers = [];
    var reducers_show = [];
    var area_all_reducers = 0;

    edges_to_reduce.forEach((edge, index) => {

        // Get the external perp line
        var edge_coords_global = [];
        // Test first one : nextPerp line matching prev line
        var match_next_prev = get_coords_intersection_two_equations({ line_coef: edge.line_next.linePerp_coef, line_origin: edge.line_next.linePerp_start_origin }, edge.line_prev);
        if (!is_point_on_line(match_next_prev, edge.line_prev)) {
            var match_next_prev_coords_global = map_helpers.local_to_mercator(match_next_prev, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
            edge_coords_global = [edge.line_next.line_coords_global[0], match_next_prev_coords_global];
        }
        else {
            var match_prev_next = get_coords_intersection_two_equations({ line_coef: edge.line_prev.linePerp_coef, line_origin: edge.line_prev.linePerp_end_origin }, edge.line_next);
            var match_prev_next_coords_global = map_helpers.local_to_mercator(match_prev_next, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
            edge_coords_global = [match_prev_next_coords_global, edge.line_prev.line_coords_global[1]];
        }

        // Create reducing surface
        var reducing_surface_coords_global = [];
        var distance_max = 0;
        if (edge.smallest_line === "line_next") {
            var matching_point = get_coords_intersection_two_equations({ line_coef: edge.line_next.linePerp_coef, line_origin: edge.line_next.linePerp_end_origin }, edge.line_prev);
            var matching_point_coords_global = map_helpers.local_to_mercator(matching_point, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
            reducing_surface_coords_global = [edge.line_next.line_coords_global[0], edge.line_next.line_coords_global[1], matching_point_coords_global, edge.line_prev.line_coords_global[1], edge.line_next.line_coords_global[0]];
            distance_max = edge.line_next.length;
        }
        else if (edge.smallest_line === "line_prev") {
            var matching_point = get_coords_intersection_two_equations({ line_coef: edge.line_prev.linePerp_coef, line_origin: edge.line_prev.linePerp_start_origin }, edge.line_next);
            var matching_point_coords_global = map_helpers.local_to_mercator(matching_point, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
            reducing_surface_coords_global = [edge.line_next.line_coords_global[0], matching_point_coords_global, edge.line_prev.line_coords_global[0], edge.line_prev.line_coords_global[1], edge.line_next.line_coords_global[0]];
            distance_max = edge.line_prev.length;
        }
        if (reducing_surface_coords_global.length > 3) {
            var reducing_surface_turf = turf.polygon([reducing_surface_coords_global]);
            var reducing_surface_buffer = turf.buffer(reducing_surface_turf, 0.0001, { steps: 1 });
            var reducing_surface_turf_matched = turf.intersect(reducing_surface_buffer, surface_intersect);
            var reducing_surface_turf_matched_buffer = turf.buffer(reducing_surface_turf_matched, 0.0001, { steps: 1 });
            // reducers_show.push(reducing_surface_turf_matched);
            if (reducing_surface_turf_matched !== null) {
                var reducing_surface_turf_matched_area = turf.area(reducing_surface_turf_matched);
                area_all_reducers += reducing_surface_turf_matched_area;
                // Create edge turf
                var edge_turf = turf.lineString(edge_coords_global);
                reducers.push({
                    edge_turf: edge_turf,
                    reducing_surface_turf_matched: reducing_surface_turf_matched_buffer,
                    area_total: reducing_surface_turf_matched_area,
                    distance_max: distance_max
                });
            }
        }
    })

    // Check if area_all_reducers is bigger enought to delete area_to_remove
    if (area_to_remove > area_all_reducers) {
        console.log("/!\\ NOT ENOUGHT SPACE IN REDUCERS TO MATCH TARGET AREA", area_all_reducers);
        return null
    }

    // Get target area to remove for each reducer
    var area_to_remove_stay = 0;
    var reducer_biggest_area_stay = 0;
    var reducer_biggest_index = null;
    reducers.forEach((reducer, index) => {
        // Get min distance 
        var distance_start = turf.pointToLineDistance(turf.point(reducer.edge_turf.geometry.coordinates[0]), turf.polygonToLine(surface_intersect));
        var distance_end = turf.pointToLineDistance(turf.point(reducer.edge_turf.geometry.coordinates[1]), turf.polygonToLine(surface_intersect));
        var distance = Math.min(distance_start, distance_end);
        reducer.distance_min = parseFloat((distance * 1000).toFixed(2));
        // Get area to remove on prorata of reducers total area
        var area_to_remove_reducer = (reducer.area_total / area_all_reducers) * area_to_remove;
        // Check if area to remove is not bigger than reducer's area
        if (area_to_remove_reducer >= reducer.area_total) {
            area_to_remove_stay += area_to_remove_reducer - reducer.area_total;
            area_to_remove_reducer = reducer.area_total;
        }
        reducer.area_to_remove = area_to_remove_reducer;
        // Get biggest reducers to add area to remove stay if any
        if ((reducer.area_to_remove - reducer.area_total) > reducer_biggest_area_stay) {
            reducer_biggest_area_stay = (reducer.area_to_remove - reducer.area_total);
            reducer_biggest_index = index;
        }
    })

    // Add staying area if needed
    if (area_to_remove_stay > 0 && reducer_biggest_index !== null && reducer_biggest_area_stay < area_to_remove_stay) {
        console.log("- - - - - - - - - Adjusting reducers");
        reducers[reducer_biggest_index].area_to_remove += area_to_remove_stay;
    }

    // Create reducers shapes and subtract from surface_intersect
    var surface_footprint = surface_intersect;
    reducers.forEach((reducer, index) => {
        // Reducer is the total area
        if (reducer.area_to_remove >= reducer.area_total) {
            // var surface_buffer = turf.buffer(reducer.reducing_surface_turf_matched, 0.001, { steps: 1 });
            // reducer.shape_turf = surface_buffer;
            var surface_to_subtract = reducer.reducing_surface_turf_matched;
            reducers_show.push(surface_to_subtract);
            if (surface_to_subtract !== null && surface_footprint !== null) {
                surface_footprint = turf.difference(surface_footprint, surface_to_subtract);
                reducer.area_removed = turf.area(surface_to_subtract);
            }
        }
        // reducer is the edge buffer
        else {
            var length_estimated_effective = reducer.area_to_remove / buildingWidth;
            var length_estimated = reducer.distance_min + length_estimated_effective;
            if (length_estimated > 0) {
                var edge_buffer = turf.buffer(reducer.edge_turf, 0.001 * length_estimated, { steps: 1 });
                // reducer.shape_turf = edge_buffer;
                var surface_to_subtract = turf.intersect(reducer.reducing_surface_turf_matched, edge_buffer);
                // reducers_show.push(surface_to_subtract);
                if (surface_to_subtract !== null && surface_footprint !== null) {
                    var surface_to_subtract_area = turf.area(surface_to_subtract);
                    // Check if reducer if not big enought
                    if (reducer.area_to_remove - surface_to_subtract_area > buildingWidth / 2) {
                        // Not big enought => bet bigger reducer
                        var length_estimated_effective_new = (length_estimated_effective * reducer.area_to_remove / surface_to_subtract_area);
                        length_estimated = reducer.distance_min + length_estimated_effective_new;
                        edge_buffer = turf.buffer(reducer.edge_turf, 0.001 * length_estimated, { steps: 1 });
                        surface_to_subtract = turf.intersect(reducer.reducing_surface_turf_matched, edge_buffer);
                        if (surface_to_subtract !== null) {
                            surface_to_subtract_area = turf.area(surface_to_subtract);
                            // Check if reducer if too big
                            if (reducer.area_to_remove < surface_to_subtract_area) {
                                // too big => bet smaller reducer
                                var length_estimated_effective_new_add = length_estimated_effective + ((length_estimated_effective_new - length_estimated_effective) * reducer.area_to_remove / surface_to_subtract_area);
                                length_estimated = reducer.distance_min + (length_estimated_effective_new_add * 0.9);
                                edge_buffer = turf.buffer(reducer.edge_turf, 0.001 * length_estimated, { steps: 1 });
                                surface_to_subtract = turf.intersect(reducer.reducing_surface_turf_matched, edge_buffer);
                                if (surface_to_subtract !== null) {
                                    surface_footprint = turf.difference(surface_footprint, surface_to_subtract);
                                    reducer.area_removed = turf.area(surface_to_subtract);
                                    reducers_show.push(surface_to_subtract);
                                }
                            }
                            else {
                                surface_footprint = turf.difference(surface_footprint, surface_to_subtract);
                                reducer.area_removed = turf.area(surface_to_subtract);
                                reducers_show.push(surface_to_subtract);
                            }
                        }
                    }
                    else {
                        surface_footprint = turf.difference(surface_footprint, surface_to_subtract);
                        reducer.area_removed = turf.area(surface_to_subtract);
                        reducers_show.push(surface_to_subtract);
                    }
                }
            }
        }
        console.log("foortprint area at", index, turf.area(surface_footprint));
    })
    console.log("reducers", reducers);

    if (surface_footprint !== null) {
        console.log("foortprint area final", turf.area(surface_footprint));
        var matching_current_new = turf.intersect(surface_footprint, surface_check_area);
        var new_area_current = 0;
        if (matching_current_new !== null) {
            new_area_current = turf.area(matching_current_new);
        }
        console.log("new_area_current", new_area_current);
        var new_area = turf.area(surface_footprint);
        var new_area_no_current = new_area - new_area_current;
        console.log("new_area_no_current", new_area_no_current);
        if (new_area_no_current < buildingWidth * 2) { new_area_no_current = 0 }
        var new_area_revised = area_max + new_area_no_current;
        surface_footprint.properties.real_area = new_area;
        surface_footprint.properties.area = new_area_revised;
        // surface_footprint.properties.reducers_show = reducers_show;
        return surface_footprint
    }
    else {
        return null
    }

    return reducers_show
}

const adaptFootprint = (capacity, footprint, availableSurfaces, takenArea, buildingWidth) => {
    // availableSurfaces.forEach(availableSurface => {
    for (var aS = 0; aS < availableSurfaces.length; aS++) {
        if (footprint === null) {
            break;
        }
        var availableSurface = availableSurfaces[aS];
        if (availableSurface.properties.buildableArea < availableSurface.properties.availableArea) { // If availableSurface is fully buildable = do nothing
            // Get intersection surface between footprint and current availableSurface
            var intersection = turf.intersect(turf.polygon([footprint.coords_global]), availableSurface);
            if (intersection !== null) { // If no intersection = do nothing
                // Get interesction area
                var intersection_area = turf.area(intersection);
                if (intersection_area > (availableSurface.properties.buildableArea - takenArea)) {
                    var area_to_remove = intersection_area - (availableSurface.properties.buildableArea - takenArea);
                    console.log("TOO LARGE - intersection_area is bigger than buildable area, need to remove", area_to_remove);
                    console.log("intersection_area", intersection_area);
                    console.log("full_area", turf.area(turf.polygon([footprint.coords_global])));
                    console.log("buildableArea", availableSurface.properties.buildableArea);
                    console.log("takenArea", takenArea);

                    var surface_intersect = turf.polygon([footprint.coords_global]);
                    var footprint_turf = reduceFootprint(capacity, footprint, surface_intersect, availableSurface, availableSurface.properties.buildableArea - takenArea, buildingWidth);

                    if (footprint_turf !== null) {
                        var footprint_recreated = recreateFootprint(capacity, footprint_turf, footprint.lines);
                        footprint_recreated.area = footprint_turf.properties.area;
                        footprint_recreated.real_area = footprint_turf.properties.real_area;
                        footprint_recreated.original_footprint = footprint;
                        console.log("footprint_recreated", footprint_recreated);
                        footprint = footprint_recreated;
                    }
                    else {
                        footprint = null
                    }
                }
            }
        }
    }

    // Result
    return footprint
}


const minimizeFootprint = (capacity, footprint, availableSurfaces, takenArea, buildingWidth) => {
    // Test if area is not bigger than buildable area
    // If yes =>    reduce the starting segment (if close circle => minimum reducing is buffer_main)
    //              Modify combination by reducing ending segment
    // If not =>    Create sub combinations with subMainLines

    // availableSurfaces.forEach(availableSurface => {
    for (var aS = 0; aS < availableSurfaces.length; aS++) {
        var availableSurface = availableSurfaces[aS];
        if (availableSurface.properties.buildableArea < availableSurface.properties.availableArea) { // If availableSurface is fully buildable = do nothing
            // Get intersection surface between footprint and current availableSurface
            var intersection = turf.intersect(turf.polygon([footprint.coords_global]), availableSurface);
            if (intersection !== null) { // If no intersection = do nothing
                // Get interesction area
                var intersection_area = turf.area(intersection);
                if (intersection_area > (availableSurface.properties.buildableArea - takenArea)) {
                    var area_to_remove = intersection_area - (availableSurface.properties.buildableArea - takenArea);
                    console.log("TOO LARGE - intersection_area is bigger than buildable area, need to remove", area_to_remove);
                    console.log("intersection_area", intersection_area);
                    console.log("full_area", turf.area(turf.polygon([footprint.coords_global])));
                    console.log("buildableArea", availableSurface.properties.buildableArea);
                    console.log("takenArea", takenArea);
                    // Check each edge if it is inside the availableSurface
                    for (var i = 0; i < footprint.lines.length; i++) {
                        if (footprint.lines[i].line_side === "edge") {
                            var line = turf.lineString(footprint.lines[i].line_coords_global);
                            var intersection_line = turf.intersect(turf.buffer(line, 0.0001, { steps: 1 }), availableSurface);
                            if (intersection_line !== null) { // Use the first edge line that intersects availableSurface
                                // Get lines to drop
                                var dropIndex = [];
                                // Get previousLine
                                var prevIndex = i - 1;
                                if (prevIndex < 0) { prevIndex = footprint.lines.length - 1 }
                                var prevLine = footprint.lines[prevIndex];
                                if (prevLine.line_side !== "in" && prevLine.line_side !== "out") {
                                    // If prevLine is not a main line => try previous one
                                    dropIndex.push(prevIndex);
                                    prevIndex--;
                                    if (prevIndex < 0) { prevIndex = footprint.lines.length - 1 }
                                    prevLine = footprint.lines[prevIndex];
                                }
                                // Get nextLine
                                var nextIndex = i + 1;
                                if (nextIndex > footprint.lines.length - 1) { nextIndex = 0 }
                                var nextLine = footprint.lines[nextIndex];
                                if (nextLine.line_side !== "in" && nextLine.line_side !== "out") {
                                    // If nextLine is not a main line => try next one
                                    dropIndex.push(nextIndex);
                                    nextIndex++;
                                    if (nextIndex > footprint.lines.length - 1) { nextIndex = 0 }
                                    nextLine = footprint.lines[nextIndex];
                                }
                                // Set inLine and outLine from previous and next lines
                                var inLine = prevLine;
                                var outLine = nextLine;
                                var inDirection = "start";
                                if (inLine.line_side !== "in") { inLine = nextLine; outLine = prevLine; inDirection = "end" }
                                console.log("inLine", inLine);
                                console.log("outLine", outLine);
                                // Check if removing previous inline is enought, because if not, no need to continue this combi is not viable
                                var linePerpOrigin = inLine.linePerp_start_origin;
                                var newStartPoint_coords = inLine.line_coords_local[0];
                                var newStartPoint_coords_global = inLine.line_coords_global[0];
                                if (inDirection === "end") { linePerpOrigin = inLine.linePerp_end_origin; newStartPoint_coords = inLine.line_coords_local[1]; newStartPoint_coords_global = inLine.line_coords_global[1] }
                                var newEndPoint_coords = get_coords_intersection_two_equations({ line_coef: inLine.linePerp_coef, line_origin: linePerpOrigin }, outLine);
                                var newEndPoint_coords_global = map_helpers.local_to_mercator(newEndPoint_coords, capacity.landBase.union.bbox.origin, capacity.landBase.union.bbox.matrix);
                                // var testPolygon_coords = footprint.lines[i].line_coords_global.concat([newEndPoint_coords_global, newStartPoint_coords_global, footprint.lines[i].line_coords_global[0]]);
                                var testPolygon_coords = [inLine.line_coords_global[1], outLine.line_coords_global[0], newEndPoint_coords_global, newStartPoint_coords_global, inLine.line_coords_global[1]];
                                // if (inDirection === "end") { testPolygon_coords = footprint.lines[i].line_coords_global.concat([newStartPoint_coords_global, newEndPoint_coords_global, footprint.lines[i].line_coords_global[0]]) }
                                if (inDirection === "end") { testPolygon_coords = [outLine.line_coords_global[1], inLine.line_coords_global[0], newStartPoint_coords_global, newEndPoint_coords_global, outLine.line_coords_global[1]] }
                                var removable_area = turf.area(turf.polygon([testPolygon_coords]));
                                console.log("removable_area", removable_area);
                                // console.log("removable_area_true", turf.area(turf.intersect(turf.polygon([testPolygon_coords]), availableSurface)));

                                // Check if removable area is enought to get to the target area to remove
                                if (removable_area >= area_to_remove) { // Keep going only if max removable is greater or equal to area to remove
                                    // Get length to keep to get just enought area to be valid
                                    var area_to_keep = removable_area - area_to_remove;
                                    var length_to_keep = area_to_keep / buildingWidth;
                                    // length_to_keep = 0.01;
                                    console.log("length_to_keep", length_to_keep);
                                    // Get offset perp line by offset = length to keep
                                    var linePerp = {
                                        line_coef: inLine.linePerp_coef,
                                        line_origin: linePerpOrigin,
                                        line_type: inLine.line_type,
                                        line_coords_local: [newStartPoint_coords, newEndPoint_coords]
                                    }
                                    var linePerpOffset = get_lineOffset_data(capacity, linePerp, length_to_keep);
                                    // Get new start and end coords
                                    var finalStartPoint_coords = get_coords_intersection_two_equations(linePerpOffset, inLine);
                                    var finalEndPoint_coords = get_coords_intersection_two_equations(linePerpOffset, outLine);
                                    // Get new Edge
                                    var newEdge = get_line_data(capacity, [finalStartPoint_coords, finalEndPoint_coords]);
                                    // Recreate footprint by replacing the edge
                                    var newFootprintLines = [];
                                    var newFootprintCoordsLocal = [];
                                    var newFootprintCoordsGlobal = [];
                                    for (var j = 0; j < footprint.lines.length; j++) {
                                        var currentLine = footprint.lines[j];

                                        if (j === i) {
                                            currentLine = newEdge;
                                        }
                                        else if (j === prevIndex) {
                                            currentLine.line_coords_global[1] = newEdge.line_coords_global[0];
                                            currentLine.line_coords_local[1] = newEdge.line_coords_local[0];
                                        }
                                        else if (j === nextIndex) {
                                            currentLine.line_coords_global[0] = newEdge.line_coords_global[1];
                                            currentLine.line_coords_local[0] = newEdge.line_coords_local[1];
                                        }

                                        if (!dropIndex.includes(j)) {
                                            newFootprintLines.push(currentLine);
                                        }

                                        // if (j === 0) {
                                        //     newFootprintCoordsLocal.push(currentLine.line_coords_local[0]);
                                        //     newFootprintCoordsGlobal.push(currentLine.line_coords_global[0]);
                                        // }
                                        // newFootprintCoordsLocal.push(currentLine.line_coords_local[1]);
                                        // newFootprintCoordsGlobal.push(currentLine.line_coords_global[1]);
                                    }
                                    newFootprintCoordsLocal.push(newFootprintLines[0].line_coords_local[0]);
                                    newFootprintCoordsGlobal.push(newFootprintLines[0].line_coords_global[0]);
                                    for (var j = 0; j < newFootprintLines.length; j++) {
                                        newFootprintCoordsLocal.push(newFootprintLines[j].line_coords_local[1]);
                                        newFootprintCoordsGlobal.push(newFootprintLines[j].line_coords_global[1]);
                                    }
                                    var newFootprintArea = turf.area(turf.polygon([newFootprintCoordsGlobal]));
                                    console.log("newFootprintArea", newFootprintArea);
                                    // Make exact area
                                    newFootprintArea = availableSurface.properties.buildableArea - takenArea;
                                    footprint = {
                                        lines: newFootprintLines,
                                        coords_global: newFootprintCoordsGlobal,
                                        coords_local: newFootprintCoordsLocal,
                                        area: newFootprintArea,
                                        max_area: true
                                    }

                                    // Check kinked
                                    if (checkKinkedFootprint(newFootprintCoordsGlobal, footprint.lines[0])) {
                                        footprint = {
                                            lines: [],
                                            coords_local: [],
                                            coords_global: [],
                                            area: 0,
                                        }
                                    }

                                }
                                else {
                                    // Make this footprint invalid
                                    footprint = {
                                        lines: [],
                                        coords_local: [],
                                        coords_global: [],
                                        area: 0,
                                    }
                                }

                                break
                            }
                        }
                    }
                }
            }
        }
    }
    // })

    return footprint
}

const recreateFootprint = (capacity, footprint, lines) => {
    // Set in clockwise way
    footprint = turf.rewind(footprint, { reverse: true });
    // Recreate data
    var new_coords_global = footprint.geometry.coordinates[0];
    var new_coords_local = [];
    new_coords_global.forEach(coords => {
        new_coords_local.push(map_helpers.mercator_to_local(coords, capacity.landBase.union.bbox.origin));
    })
    var new_lines = [];
    for (var i = 1; i < new_coords_local.length; i++) {
        var new_line = get_line_data(capacity, [new_coords_local[i - 1], new_coords_local[i]]);
        new_line.line_side = "edge";
        var new_line_turf = turf.lineString([new_coords_global[i - 1], new_coords_global[i]]);
        // Search for same parameters in full footprint to get matching lines
        for (var j = 0; j < lines.length; j++) {
            var jline_turf = turf.lineString(lines[j].line_coords_global);
            var overlap = turf.lineOverlap(new_line_turf, jline_turf, { tolerance: 0.001 });
            if (overlap.features.length > 0) {
                new_line.line_side = lines[j].line_side;
                break;
            }
            // if ((new_line.line_coef).toFixed(2) === (lines[j].line_coef).toFixed(2) && (new_line.line_origin).toFixed(0) === (lines[j].line_origin).toFixed(0)) {
            //     new_line.line_side = lines[j].line_side;
            //     break;
            // }
        }
        new_lines.push(new_line);
    }

    // Simplify by deleting small lines
    var new_coords_global_simplified = [];
    var new_coords_local_simplified = [];
    var new_lines_simplified = [];
    for (var i = 0; i < new_lines.length; i++) {
        if (new_lines[i].line_length >= 0.1) {
            new_lines_simplified.push(new_lines[i]);
            new_coords_global_simplified.push(new_lines[i].line_coords_global[0]);
            new_coords_local_simplified.push(new_lines[i].line_coords_local[0]);
        }
    }
    new_coords_global_simplified.push(new_lines_simplified[0].line_coords_global[0]);
    new_coords_local_simplified.push(new_lines_simplified[0].line_coords_local[0]);

    // Get lines angles
    new_lines_simplified = get_lines_angle(new_lines_simplified);

    // Check if no angle too big (more than 315 deg)
    if (checkAngleFootprint(new_lines_simplified) === true) {
        console.log("ERROR Building is having too big angle");
        new_coords_global_simplified = [];
        new_coords_local_simplified = [];
        new_lines_simplified = [];
    }

    // Get lines length totals
    var inLine_length = 0;
    var outLine_length = 0;
    var edgeLine_length = 0;
    for (var line_i = 0; line_i < new_lines_simplified.length; line_i++) {
        if (new_lines_simplified[line_i].line_side === "in") {
            inLine_length += new_lines_simplified[line_i].line_length;
        }
        else if (new_lines_simplified[line_i].line_side === "out") {
            outLine_length += new_lines_simplified[line_i].line_length;
        }
        else {
            edgeLine_length += new_lines_simplified[line_i].line_length;
        }
    }
    if ((inLine_length + outLine_length) * 0.75 < edgeLine_length) {
        console.log("ERROR Building is having too much edges");
        new_coords_global_simplified = [];
        new_coords_local_simplified = [];
        new_lines_simplified = [];
    }

    return {
        // coords_global: new_coords_global,
        // coords_local: new_coords_local,
        // lines: new_lines,
        coords_global: new_coords_global_simplified,
        coords_local: new_coords_local_simplified,
        lines: new_lines_simplified,
        lengths: {
            in: inLine_length,
            out: outLine_length,
            edge: edgeLine_length,
            total: inLine_length + outLine_length + edgeLine_length
        },
    }
}


const getMaxHeightFootrpint = (capacity, footprint) => {
    var footprint_max_height = {
        building: null,
        facade: null,
    }
    // Get footprint polygon
    var footprint_polygon = turf.polygon([footprint.coords_global]);

    // MAX HEIGHT BUILDING
    var max_height_building_global = capacity?.buildable?.volume?.parameters?.max_height;
    var max_height_building_zones = capacity?.buildable?.volume?.parameters?.max_height_building_zones;
    footprint_max_height.building = max_height_building_global;
    if (capacity?.buildable?.volume?.parameters?.max_height_building_zones && max_height_building_zones.length > 0) {
        max_height_building_zones.forEach(max_height_zone => {
            // Check if height of zone is smaller thant current max height
            if (max_height_zone?.height < footprint_max_height.building && max_height_zone?.perimeter) {
                // Check if footrpint is overlapping this height zone
                var intersection = turf.intersect(footprint_polygon, max_height_zone.perimeter);
                if (intersection !== null && turf.area(intersection) >= (turf.area(footprint_polygon) / 2)) {
                    footprint_max_height.building = max_height_zone?.height;
                }
            }
        })
    }

    // MAX HEIGHT FACADE
    var max_height_facade_global = capacity?.buildable?.volume?.parameters?.max_height_facade;
    var max_height_facade_zones = capacity?.buildable?.volume?.parameters?.max_height_facade_zones;
    footprint_max_height.facade = max_height_facade_global;
    if (capacity?.buildable?.volume?.parameters?.max_height_facade_zones && max_height_facade_zones.length > 0) {
        max_height_facade_zones.forEach(max_height_zone => {
            // Check if height of zone is smaller thant current max height
            if (max_height_zone?.height < footprint_max_height.facade && max_height_zone?.perimeter) {
                // Check if footrpint is overlapping this height zone
                var intersection = turf.intersect(footprint_polygon, max_height_zone.perimeter);
                if (intersection !== null && turf.area(intersection) >= (turf.area(footprint_polygon) / 2)) {
                    footprint_max_height.facade = max_height_zone?.height;
                }
            }
        })
    }
    if (footprint_max_height.facade > footprint_max_height.building) {
        footprint_max_height.facade = footprint_max_height.building;
    }

    return footprint_max_height

}




// MERGE AND ELAG

const flattenCombinations2 = (combinationsGroups) => {
    var conbinationsGroupsElaged = [];
    var conbinationsGroupsElaged_id = [];

    combinationsGroups.forEach(combinationsGroup => {
        var doubleCheck = [];
        var combinationsGroupElaged = [];
        var combinationsGroupElaged_id = [];

        combinationsGroup.forEach(combination => {
            if (combination?.mainLines_levels && combination.mainLines_levels.length > 1 && combination.mainLines_levels[1]?.footprints) {
                var valueCheck = combination.mainLines_area.toFixed(0) + "_" + combination.mainLines_levels[1].footprints.length;
                if (!doubleCheck.includes(valueCheck) && combination.mainLines_area > 10) {
                    doubleCheck.push(valueCheck);
                    var combinationCondensed = {
                        old_id: combination.id,
                        id: combinationsGroupElaged.length,
                        mainLines_levels: combination.mainLines_levels,
                        mainLines_area: combination.mainLines_area
                    }
                    combinationsGroupElaged_id.push(combinationCondensed.id);
                    combinationsGroupElaged.push(combinationCondensed);
                }
            }
        })

        // console.log("doubleCheck", doubleCheck);
        conbinationsGroupsElaged.push(combinationsGroupElaged);
        conbinationsGroupsElaged_id.push(combinationsGroupElaged_id);
    })

    // Merge test
    var merge_combi = helpers.l_combinations(conbinationsGroupsElaged_id);
    console.log("merge_combi", merge_combi);

    return conbinationsGroupsElaged

}

const filterCombinations = (combinationsGroup) => {

    var doubleCheck = [];
    var combinationsGroupElaged = [];
    var combinationsGroupElaged_id = [];

    combinationsGroup.forEach(combination => {
        if (combination?.footprints && combination.footprints.length > 0) {
            var valueCheck = Math.round(combination.area) + "_" + combination.footprints.length + "_" + Math.round(combination.centroid[0] * 100000) + ";" + Math.round(combination.centroid[1] * 100000);
            if (!doubleCheck.includes(valueCheck) && combination.area > 10) {
                doubleCheck.push(valueCheck);
                combinationsGroupElaged_id.push(combinationsGroupElaged.length);
                combinationsGroupElaged.push(combination);
            }
        }
    })

    console.log("doubleCheck", doubleCheck, combinationsGroup.length, combinationsGroupElaged.length);


    return combinationsGroupElaged

}

const flattenCombinations = (combinationsGroups) => {

    // Create id lists
    var combinationsGroups_id = [];
    combinationsGroups.forEach(combinationsGroup => {
        var combinationsGroup_id = [...Array(combinationsGroup.length).keys()];
        combinationsGroup_id.unshift(null);
        combinationsGroups_id.push(combinationsGroup_id);
    })
    console.log("combinationsGroups_id", combinationsGroups_id);

    // Create flatten id list
    var combinationsGroups_id_flattened = helpers.l_combinations(combinationsGroups_id);
    console.log("combinationsGroups_id_flattened", combinationsGroups_id_flattened);

    // Create flatten list
    var combinationsGroups_flattened = [];
    for (var i = 1; i < combinationsGroups_id_flattened.length; i++) {
        var current_combination = {
            id: i - 1,
            area: 0,
            footprints: [],
            // buffers: [],
        };
        combinationsGroups_id_flattened[i].forEach((id, index) => {
            if (id !== null) {
                current_combination.area += combinationsGroups[index][id].area;
                current_combination.footprints = current_combination.footprints.concat(combinationsGroups[index][id].footprints);
                // current_combination.buffers = current_combination.buffers.concat(combinationsGroups[index][id].buffers);
                current_combination.validity = combinationsGroups[index][id].validity;
                current_combination.message = combinationsGroups[index][id].message;
            }
        })
        // Sort by bigger footprint to smaller
        current_combination.footprints.sort(function (a, b) {
            return b.area - a.area;
        })

        combinationsGroups_flattened.push(current_combination);
    }

    console.log("combinationsGroups_flattened", combinationsGroups_flattened);

    return combinationsGroups_flattened

}



// _________ NEW VERSION

// GET ALL REAL FOOTRPINTS FROM FOOTPRINTS
const get_buildings_of_combination = (capacity, footprints, levels, buildingWidth, buffer_main, buffer_second, data_validity, data_message) => {

    var realfootprints = [];
    var realfootprints_area = 0;
    var realfootprints_area_real = 0;
    var realfootprints_groundArea = 0;
    var realfootprints_groundArea_real = 0;
    var realfootprints_parkingArea = 0;
    var realfootprints_parkingArea_real = 0;
    var realfootrpints_groundArea_list = [];

    var buffers_data = [];
    var buffers_binom = {};

    var buffers_ids = [];

    var validity = data_validity;
    var message = data_message;

    // Buffers
    if (footprints.length > 1) {

        for (var foot_i = 0; foot_i < footprints.length; foot_i++) {
            buffers_ids.push(foot_i);
            var buffer_intersection_area = 0;
            var buffer_after_index = [];
            var buffer_impact_index = [];
            var buffer_impact_detail = [];
            var buffer_intersection_lines = {
                in: 0,
                out: 0,
                edge: 0
            };
            var buffer_intersection_list = [];
            var buffer_intersection_overlap = [];
            // Create buffer polygon
            var buffer_polygon = turf.polygon([footprints[foot_i].buffer.coords_global]);

            for (var foot_j = 0; foot_j < footprints.length; foot_j++) {
                if (foot_j === foot_i) {
                    continue;
                }

                var buffer_impact_detail_j = {
                    index: foot_j,
                    intersection_area: 0,
                    lines: { in: 0, out: 0, edge: 0, bonus: 0 },
                    list: [],
                    overlap: [],
                    score: 0,
                }

                // Create footprint polygon
                var footprint_polygon = turf.polygon([footprints[foot_j].coords_global]);
                var intersection = turf.intersect(buffer_polygon, footprint_polygon);

                if (intersection !== null) {
                    // if (intersection !== null && turf.area(intersection) >= (buildingWidth / 2)) {
                    buffer_intersection_area += turf.area(intersection);
                    buffer_impact_detail_j.intersection_area += turf.area(intersection);
                    buffer_impact_index.push(foot_j);

                    // Check lines intersection
                    var line_intersection = turf.polygonToLine(intersection);
                    var line_buffer = turf.polygonToLine(buffer_polygon);
                    var overlapping = turf.lineOverlap(line_intersection, line_buffer, { tolerance: 0.001 });
                    buffer_intersection_overlap.push({
                        index: foot_j,
                        overlap: overlapping.features
                    });
                    buffer_impact_detail_j.overlap = overlapping.features;


                    // Check each line intersection
                    for (var line_index = 0; line_index < footprints[foot_j].lines.length; line_index++) {
                        var line_polygon = turf.buffer(turf.lineString(footprints[foot_j].lines[line_index].line_coords_global), 0.0001, { steps: 1 });
                        var overlap = turf.intersect(buffer_polygon, line_polygon);

                        if (overlap !== null) {
                            var points = {
                                start: turf.booleanPointInPolygon(turf.point(footprints[foot_j].lines[line_index].line_coords_global[0]), buffer_polygon),
                                end: turf.booleanPointInPolygon(turf.point(footprints[foot_j].lines[line_index].line_coords_global[1]), buffer_polygon),
                            };
                            if (points.start === false && points.end === false) {
                                buffer_after_index.push(foot_j);
                                buffer_intersection_lines.in += 1500; // MALUS
                                buffer_impact_detail_j.lines.bonus += 1500;
                            }
                            if (points.start === true && points.end === true && footprints[foot_j].lines[line_index].line_side === "edge") {
                                buffer_intersection_lines.in -= 1000; // BONUS
                                buffer_impact_detail_j.lines.bonus -= 1000;
                            }
                            if (points.start === true && points.end === false && line_index > 0 && buffer_impact_detail_j.list[line_index - 1]?.points?.end === true && buffer_impact_detail_j.list[line_index - 1]?.points?.start === false && overlapping.features.length >= 2) {
                                buffer_after_index.push(foot_j);
                                buffer_intersection_lines.in += 1000; // MALUS
                                buffer_impact_detail_j.lines.bonus += 1000;
                            }

                            // Check if it erase completely an out or in line => stop
                            if (points.start === true && points.end === true && (footprints[foot_j].lines[line_index].line_side === "in" || footprints[foot_j].lines[line_index].line_side === "out")) {
                                console.log("ERROR : Buildings are too close");
                                // return { buildings: [], area_total: 0, area_total_real: 0 }
                                validity = false;
                                message = "Paramètre non satisfait : buildings are too close";
                            }

                            buffer_intersection_list.push({
                                area_overlap: turf.area(overlap),
                                area_line: turf.area(line_polygon),
                                side: footprints[foot_j].lines[line_index].line_side,
                                points: points
                            });
                            buffer_impact_detail_j.list.push({
                                area_overlap: turf.area(overlap),
                                area_line: turf.area(line_polygon),
                                side: footprints[foot_j].lines[line_index].line_side,
                                points: points
                            });

                            if (footprints[foot_j].lines[line_index].line_side === "in") {
                                buffer_intersection_lines.in += turf.area(overlap);
                                buffer_impact_detail_j.lines.in += turf.area(overlap);
                            }
                            else if (footprints[foot_j].lines[line_index].line_side === "out") {
                                buffer_intersection_lines.out += turf.area(overlap);
                                buffer_impact_detail_j.lines.out += turf.area(overlap);
                            }
                            else {
                                buffer_intersection_lines.edge += turf.area(overlap);
                                buffer_impact_detail_j.lines.edge += turf.area(overlap);
                            }
                        }
                        else {
                            buffer_intersection_list.push(null);
                            buffer_impact_detail_j.list.push(null);
                        }
                    }

                }
                buffer_impact_detail_j.score = buffer_impact_detail_j.lines.in + buffer_impact_detail_j.lines.out + buffer_impact_detail_j.lines.bonus;
                buffer_impact_detail.push(buffer_impact_detail_j);
                buffers_binom[foot_i + "-" + foot_j] = buffer_impact_detail_j.score;
            }
            buffers_data.push({
                index: foot_i,
                area: buffer_intersection_area,
                lines: buffer_intersection_lines,
                list: buffer_intersection_list,
                after_index: buffer_after_index,
                impact_index: buffer_impact_index,
                overlap: buffer_intersection_overlap,
                detail: buffer_impact_detail,
            })
        }
        // // Sort by smaller buffer to bigger
        // buffers_data.sort(function (a, b) {
        //     return (a.lines.in + a.lines.out) - (b.lines.in + b.lines.out);
        //     // return a.area - b.area;
        // })
        console.log("buffers_binom", buffers_binom);

        // Combinations
        var buffers_combi = helpers.m_combinations(buffers_ids);
        console.log("buffers_combi", buffers_combi);
        var buffers_combi_scores = [];
        var buffers_combi_min_score = null;
        var buffers_combi_min = null;
        for (var combi_i = 0; combi_i < buffers_combi.length; combi_i++) {
            var combi = buffers_combi[combi_i];
            var score = 0;
            for (var buff_i = 0; buff_i < combi.length - 1; buff_i++) {
                for (var foot_i = buff_i + 1; foot_i < combi.length; foot_i++) {
                    var combi_key = combi[buff_i] + "-" + combi[foot_i];
                    score += buffers_binom[combi_key];
                }
            }
            buffers_combi_scores.push({
                combi_index: combi_i,
                combi: combi,
                score: score
            })
            if (combi_i === 0) {
                buffers_combi_min_score = score;
                buffers_combi_min = combi;
            }
            else if (score < buffers_combi_min_score) {
                buffers_combi_min_score = score;
                buffers_combi_min = combi;
            }
        }
        console.log("buffers_combi_scores", buffers_combi_scores);
        console.log("buffers_combi_min", buffers_combi_min);

        // Get final buffer list ordered
        var buffers_data_ordered = [];
        buffers_combi_min.forEach(buffer_index => {
            buffers_data_ordered.push(buffers_data[buffer_index]);
        })
        buffers_data = buffers_data_ordered;
        console.log("buffers_data", buffers_data);
    }

    // Get footrpint without buffer intersection
    var footprints_buffered = [...footprints];
    for (var buffer_i = 0; buffer_i < buffers_data.length; buffer_i++) {
        var buffer_data = buffers_data[buffer_i];
        if (buffer_data.area <= 0) {
            continue;
        }

        if (!footprints_buffered[buffer_data.index]?.buffer?.coords_global) {
            continue;
        }
        // Create buffer polygon
        var buffer_polygon = turf.polygon([footprints_buffered[buffer_data.index].buffer.coords_global]);

        for (var footprint_i = 0; footprint_i < footprints_buffered.length; footprint_i++) {
            if (buffer_data.impact_index.includes(footprint_i)) {
                var footprint = footprints_buffered[footprint_i];
                // Get turf polygon
                if (footprint?.coords_global) {
                    footprint = turf.polygon([footprint.coords_global]);
                }

                // Get intersection
                var difference = turf.difference(footprint, buffer_polygon);

                // Check the result is not emprty
                if (difference === null) {
                    console.log("ERROR : Building erased by buffer");
                    return { buildings: [], area_total: 0, area_total_real: 0 }
                }

                // Check area
                if (turf.area(difference) >= (turf.area(footprint) - (buildingWidth / 2))) {
                    // continue;
                }
                else if (turf.area(difference) < (buildingWidth * buildingWidth)) {
                    console.log("ERROR : Building erased by buffer too small");
                    return { buildings: [], area_total: 0, area_total_real: 0 }
                }

                // Get only polygon type
                if (difference?.geometry?.type === "MultiPolygon") {
                    difference = turf.polygon(difference?.geometry?.coordinates[0]);
                }
                if (difference?.geometry?.type !== "Polygon") {
                    console.log("ERROR : Building after buffer is not a polygon");
                    return { buildings: [], area_total: 0, area_total_real: 0 }
                }

                // Recreate footprint
                var recreatedFootrpint = recreateFootprint(capacity, difference, footprints[[footprint_i]].lines);
                if (recreatedFootrpint.lines.length <= 0) {
                    console.log("ERROR : Building after buffer is not a valid");
                    return { buildings: [], area_total: 0, area_total_real: 0 }
                }
                recreatedFootrpint.buffer = get_mainLine_buffer(capacity, recreatedFootrpint, buffer_main, buffer_second);
                recreatedFootrpint.original_footprint = footprints[[footprint_i]].original_footprint;
                recreatedFootrpint.max_height = footprints[[footprint_i]].max_height;
                recreatedFootrpint.levels = footprints[[footprint_i]].levels;

                footprints_buffered[footprint_i] = recreatedFootrpint;
            }

        }

    }
    console.log("footprints_buffered", footprints_buffered);



    var footprints = footprints_buffered;


    // Get area to remove
    for (var zone_i = 0; zone_i < capacity?.buildable?.volume?.parameters?.buildable_super_zones.length; zone_i++) {
        // Check zone ratio is not equal or bigger than 1, so no area to remove
        if (capacity?.buildable?.volume?.parameters?.buildable_super_zones[zone_i].ratio >= 1) {
            continue;
        }

        var intersection_area_total = 0;
        var footprints_intersection_area = [];
        var footprints_index = [];

        // Check intersection with each footprint
        for (var footprint_i = 0; footprint_i < footprints.length; footprint_i++) {
            var intersection = turf.intersect(capacity?.buildable?.volume?.parameters?.buildable_super_zones[zone_i].perimeter, turf.polygon([footprints[footprint_i].coords_global]));
            if (intersection === null || turf.area(intersection) < 1) {
                continue;
            }
            var area = turf.area(intersection);
            intersection_area_total += area;
            footprints_intersection_area.push(area);
            footprints_index.push(footprint_i);
        }

        // Check if intersection_area_total is bigger than buildable area
        if (intersection_area_total <= capacity?.buildable?.volume?.parameters?.buildable_super_zones[zone_i].buildableArea) {
            continue;
        }

        var area_to_remove_total = intersection_area_total - capacity?.buildable?.volume?.parameters?.buildable_super_zones[zone_i].buildableArea;

        // Set area_to_remove
        footprints_index.forEach((footprint_index, index) => {
            if (!footprints[footprint_index]?.area_to_remove) {
                footprints[footprint_index].area_to_remove = [];
                footprints[footprint_index].area_to_remove_total = 0;
            }
            var area_to_remove = footprints_intersection_area[index] / intersection_area_total * area_to_remove_total;
            footprints[footprint_index].area_to_remove.push({
                buildable_super_zones_index: zone_i,
                area_to_remove: area_to_remove,
                area_max: footprints_intersection_area[index] - area_to_remove
            });
            footprints[footprint_index].area_to_remove_total += area_to_remove;

            // Check if footrpint area is big enought
            if (area_to_remove >= (footprints[footprint_index].area - (buildingWidth * buildingWidth))) {
                console.log("ERROR : Building area is too small after reducing");
                // return { buildings: [], area_total: 0, area_total_real: 0 }
                validity = false;
                message = "Paramètre non satisfait : building area too small";
            }
        })

    }


    // // Get SDP MAX from parking constraints
    // if (capacity?.buildable?.volume?.parameters?.parking?.min_area > 0 && capacity?.buildable?.volume?.parameters?.parking?.type_sub === false && capacity?.buildable?.volume?.parameters?.parking?.type_ext === true) {
    //     // Parking only possible outside => reduce SDP

    //     // Get total projected SDP
    //     var sdp_total_projected = 0;
    //     var sdp_ground_projected = 0;
    //     // Set area_to_remove
    //     for (var footprint_i = 0; footprint_i < footprints.length; footprint_i++) {
    //         if (footprints[footprint_i]?.area_to_remove_total) {
    //             sdp_total_projected += ((footprints[footprint_i].area - footprints[footprint_i]?.area_to_remove_total) * (footprints[footprint_i].levels.length - 1));
    //             sdp_ground_projected += (footprints[footprint_i].area - footprints[footprint_i]?.area_to_remove_total);
    //         }
    //         else {
    //             sdp_total_projected += ((footprints[footprint_i].area) * (footprints[footprint_i].levels.length - 1));
    //             sdp_ground_projected += (footprints[footprint_i].area);
    //         }
    //     }
    //     console.log("sdp_total_projected", sdp_total_projected, "sdp_ground_projected", sdp_ground_projected);

    //     // Get total projected
    //     var parkingArea_projected = capacity?.buildable?.volume?.parameters?.parking?.min_area;
    //     if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio") {
    //         parkingArea_projected = capacity?.buildable?.volume?.parameters?.parking?.min_area * sdp_total_projected;
    //     }
    //     var availableArea_projected = capacity?.landBase?.union?.area - sdp_ground_projected; // Should not take land area but land buildable area

    //     console.log("parkingArea_projected", parkingArea_projected, "availableArea_projected", availableArea_projected);

    //     if (parkingArea_projected > 0.9 * availableArea_projected) {
    //         // parkingArea = ratio * SDP or fixed value
    //         // availableArea = landArea - SDP_ground
    //         // SDP = alpha * SDP_ground
    //         // alpha = sdp_ground_projected / sdp_total_projected

    //         // GOAL : parkingArea <= availableArea
    //         // parkingArea = landArea - SDP_ground = landArea - (SDP / alpha)

    //         // If fixed value : SDP = (landArea - parkingArea) * alpha
    //         // If ratio value : SDP = landArea / (ratio - alpha)

    //         var alpha = sdp_ground_projected / sdp_total_projected;
    //         console.log("alpha", alpha);

    //         var SDP_max_projected = (capacity?.landBase?.union?.area - capacity?.buildable?.volume?.parameters?.parking?.min_area) * alpha;
    //         if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio") {
    //             SDP_max_projected = capacity?.landBase?.union?.area / (capacity?.buildable?.volume?.parameters?.parking?.min_area - alpha);
    //         }

    //         console.log("SDP_max_projected", SDP_max_projected);

    //     }
    // }


    // Get real footrpint for each footprint at each level
    for (var footprint_i = 0; footprint_i < footprints.length; footprint_i++) {
        var footprint = footprints[footprint_i];

        var realfootprints_current = [];

        for (var level_index = 1; level_index < footprint.levels.length; level_index++) {
            var level = footprint.levels[level_index];
            var realfootprints_underlevel = null;
            if (level_index > 1) {
                realfootprints_underlevel = footprints[footprint_i].levels[level_index - 1].realfootprints[0];
            }
            var target_area = null;
            if (level_index === 1) {
                target_area = 0;
            }

            var footprints_real_and_area = get_buildings_from_footprint_at_level(capacity, level, footprint, realfootprints_underlevel, buildingWidth, target_area);

            // Check if footrpint is available
            if (footprints_real_and_area.realfootprints_area <= 0) {
                console.log("ERROR : Building area is null or negative", footprints_real_and_area);
                return { buildings: [], area_total: 0, area_total_real: 0 }
            }

            // Add data to level
            footprints[footprint_i].levels[level_index].realfootprints = footprints_real_and_area.realfootprints;
            footprints[footprint_i].levels[level_index].realfootprints_area = footprints_real_and_area.realfootprints_area;

            // Add data to realfootprint
            for (var i = 0; i < footprints_real_and_area.realfootprints.length; i++) {
                // If first level => initalize current realfootrpint
                if (level_index === 1) {
                    realfootprints_current.push({
                        levels: [],
                        area: 0,
                        area_real: 0
                    })
                }
                if (realfootprints_current.length >= i + 1) {
                    var level_height = level.elevation;
                    if (level_index > 1) {
                        level_height = Math.round(100 * (level.elevation - footprint.levels[level_index - 1].elevation)) / 100;
                    }
                    realfootprints_current[i].levels.push({
                        elevation: level.elevation,
                        height: level_height,
                        polygon: footprints_real_and_area.realfootprints[i],
                    });
                    realfootprints_current[i].area += footprints_real_and_area.realfootprints[i].properties.area;
                    realfootprints_current[i].area_real += footprints_real_and_area.realfootprints[i].properties.real_area;
                    realfootprints_area += footprints_real_and_area.realfootprints[i].properties.area;
                    realfootprints_area_real += footprints_real_and_area.realfootprints[i].properties.real_area;
                    if (level_index === 1) {
                        realfootprints_groundArea += footprints_real_and_area.realfootprints[i].properties.area;
                        realfootrpints_groundArea_list.push(footprints_real_and_area.realfootprints[i].properties.area);
                        realfootprints_groundArea_real += footprints_real_and_area.realfootprints[i].properties.real_area;

                    }
                }
            }
        }

        if (capacity?.buildable?.volume?.parameters?.parking?.type === "value") {
            realfootprints_parkingArea = capacity?.buildable?.volume?.parameters?.parking?.min_area;
            realfootprints_parkingArea_real = capacity?.buildable?.volume?.parameters?.parking?.min_area;
        }
        else if (capacity?.buildable?.volume?.parameters?.parking?.type === "ratio") {
            realfootprints_parkingArea = realfootprints_area * capacity?.buildable?.volume?.parameters?.parking?.min_area;
            realfootprints_parkingArea_real = realfootprints_area_real * capacity?.buildable?.volume?.parameters?.parking?.min_area;
        }


        realfootprints = realfootprints.concat(realfootprints_current);

    }

    console.log("footprints", footprints);
    console.log("buildings", realfootprints);

    // UNDERGROUND
    var underground = {
        levels: [],
        area: 0,
        area_real: 0
    };
    if (capacity?.buildable?.volume?.parameters?.parking?.type_sub === true) {
        // Get parkingArea_ground = 90% of avauilable ground area
        var availableArea_ground = 0.9 * (capacity?.landBase?.union?.area - realfootprints_groundArea);
        var parkingArea_ground = 0;
        if (availableArea_ground >= 50 && capacity?.buildable?.volume?.parameters?.parking?.type_ext === true) {
            parkingArea_ground = availableArea_ground;
        }
        // Get parkingArea_underground_target
        var parkingArea_underground_target = realfootprints_parkingArea - parkingArea_ground;

        // Get all possible shapes
        var undergroundShapes = [];
        var points_list = [];
        realfootprints.forEach(building => {
            undergroundShapes.push(building.levels[0].polygon);
            building.levels[0].polygon.geometry.coordinates[0].forEach(building_point => {
                points_list.push(turf.point(building_point));
            })
        })
        var hull = turf.convex(turf.featureCollection(points_list));
        hull.properties.area = turf.area(hull);
        undergroundShapes.push(hull);

        // Sort by smaller to bigger
        undergroundShapes.sort(function (a, b) {
            return a.properties.area - b.properties.area;
        })

        console.log("undergroundShapes", undergroundShapes);

        // Get best fitting shape
        // var nb_level = 1;
        for (var nb_level = 1; nb_level < 6; nb_level++) {
            for (var i = 0; i < undergroundShapes.length; i++) {
                if (undergroundShapes[i].properties.area * nb_level >= parkingArea_underground_target) {
                    for (var j = 0; j < nb_level; j++) {
                        underground.levels.push({ elevation: (j * -3), height: 3, polygon: undergroundShapes[i] });
                        underground.area += undergroundShapes[i].properties.area;
                        underground.area_real += undergroundShapes[i].properties.area;
                    }

                    break;
                }
            }

            if (underground.levels.length > 0) {
                break;
            }
        }

        console.log("underground", underground);
        // Check if footrpint is available
        if (underground.levels.length === 0) {
            console.log("ERROR : Building parking need is too big");
            // return { buildings: [], area_total: 0, area_total_real: 0 }
            validity = false;
            message = "Paramètre non satisfait : parking need is too big";
        }
    }

    // PARKING
    var parking = {
        ext: {
            area: 0,
            area_real: 0,
            nb_place: 0,
        },
        sub: {
            area: 0,
            area_real: 0,
            nb_place: 0,
            levels: [],
        },
    }

    if (underground.levels.length > 0) {
        parking.sub.area = underground.area;
        parking.sub.area_real = underground.area_real;
        parking.sub.nb_place = Math.floor(underground.area / 30);
        parking.sub.levels = underground.levels;
    }
    if (capacity?.buildable?.volume?.parameters?.parking?.type_ext === true) {
        parking.ext.area = realfootprints_parkingArea - parking.sub.area;
        parking.ext.area_real = realfootprints_parkingArea_real - parking.sub.area_real;
        parking.ext.nb_place = Math.floor(parking.ext.area / 25);
    }
    console.log("parking", parking);




    // Pre calculate target areas
    // var level_list_area = Array(levels.length).fill(null);
    // if (capacity?.buildable?.volume?.parameters?.max_area_total && capacity?.buildable?.volume?.parameters?.max_area_total !== null) {
    //     var area_level_medium = capacity?.buildable?.volume?.parameters?.max_area_total / (levels.length - 1);
    //     var up_level_area = 0;
    //     var up_level_nb = 0;
    //     // Get real target area for each level
    //     for (var i = levels.length - 1; i > 0; i--) {
    //         var level_i_area = area_level_medium;
    //         if (levels[i].area < level_i_area) {
    //             level_i_area = levels[i].area;
    //         }
    //         level_list_area[i] = level_i_area;
    //         up_level_nb++;
    //         up_level_area += level_i_area;
    //         area_level_medium = (capacity?.buildable?.volume?.parameters?.max_area_total - up_level_area) / (levels.length - 1 - up_level_nb);
    //     }
    //     console.log("level_list_area", level_list_area);
    //     // IF DIFFERENCE OF AVAILABLE SURFACES => VOLUME NOT STRAIGHT => PRECALCULATE FOOTRPINTS AREAS REAL TO GET PRORATA TARGET AREA
    // }

    // // for each levels, get all footprints, intersection with all working (or available) surfaces
    // for (var level_index = 1; level_index < levels.length; level_index++) {

    //     var sub_level_footprints = null;
    //     if (level_index > 1) {
    //         sub_level_footprints = level_list[level_index - 2].footprints;
    //     }

    //     var level_current = null;
    //     try {
    //         level_current = get_level_footrpints(capacity, levels[level_index], footprints, sub_level_footprints, buildingWidth, level_list_area[level_index]);
    //         level_list.push(level_current);
    //         if (level_current.elevation > 0.01) {
    //             total_area += level_current.area;
    //         }
    //     } catch (error) {
    //         console.log("LEVEL ERROR", error);
    //         break;
    //     }

    // }

    // if (level_list.length > 0) {
    //     var ground_level = { ...level_list[0] };
    //     ground_level.elevation = 0.01;
    //     level_list.unshift(ground_level);
    // }


    return { buildings: realfootprints, area_total: realfootprints_area, area_total_real: realfootprints_area_real, parking_area_total: realfootprints_parkingArea, parking_area_total_real: realfootprints_parkingArea_real, ground_area_total: realfootprints_groundArea, ground_area_total_real: realfootprints_groundArea_real, parking: parking, validity, message }
}



const get_footprint_levels = (capacity, footprint, levels, levels_all) => {

    var footprint_levels = [];

    if (footprint?.max_height?.facade === capacity?.buildable?.volume?.parameters?.max_height_facade) {
        footprint_levels = [...levels];
    }
    else {
        var top_level_height = capacity?.buildable?.volume?.parameters?.levelHeight;
        if (capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) {
            top_level_height = capacity?.buildable?.volume?.parameters?.min_height_level?.top;
        }
        else if (capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0) {
            top_level_height = capacity?.buildable?.volume?.parameters?.min_height_level?.current;
        }
        var top_level_elevation = footprint?.max_height?.facade - top_level_height;
        var top_level_elevation_real = 0;
        var under_top = true;
        for (var level_i = 0; level_i < levels.length; level_i++) {
            if (under_top === true && levels[level_i].elevation <= top_level_elevation) {
                footprint_levels.push({ ...levels[level_i] });
                top_level_elevation_real = levels[level_i].elevation;
            }
            else {
                if (under_top === true && levels[level_i].elevation > top_level_elevation) {
                    under_top = false;
                    break
                }
                // if (under_top === false && levels[level_i].elevation >= (top_level_elevation_real + top_level_height)) {
                //     var modified_level = { ...levels[level_i] };
                //     modified_level.elevation = top_level_elevation_real + top_level_height;
                //     footprint_levels.push(modified_level);
                //     break;
                // }
            }
        }
        var under_top = true;
        for (var level_i = 0; level_i < levels_all.length; level_i++) {
            if (under_top === true && levels_all[level_i].elevation > top_level_elevation) {
                under_top = false;
            }
            if (under_top === false && levels_all[level_i].elevation >= (top_level_elevation_real + top_level_height)) {
                var modified_level = { ...levels_all[level_i] };
                modified_level.elevation = top_level_elevation_real + top_level_height;
                footprint_levels.push(modified_level);
                break;
            }
        }
    }

    return footprint_levels
}

