import React from 'react';

// Import CSS
import './Title.css'

const Title = ({ title }) => {
    return (
        <div className="title__container">
            <div />
            <h1>{title}</h1>
        </div>
    );
};

export default Title;