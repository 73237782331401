import React, { useState } from 'react';

// Import components
import DoughnutChart from '../../../charts/DoughnutChart';
import Bound from './Bound';

// Import CSS & assets
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';

// Import external functions
import * as helpers from '../../../../helpers/Other_helpers';

const Bounds = ({ capacity, setCapacity, mapLayers, setMapLayers, setBoundUpdateTracker }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }
    // LAYER : Refresh
    const layer_Refresh = (layer) => {
        if (mapLayers[layer]["isVisible"] === true) {
            console.log("REFRESH LAYER", layer);
            // Get state
            var mapLayersState = mapLayers;
            // Update state
            mapLayersState[layer]["isVisible"] = false;
            // Update original
            setMapLayers({ ...mapLayersState });

            setTimeout(() => {
                // Update state
                mapLayersState[layer]["isVisible"] = true;
                // Update original
                setMapLayers({ ...mapLayersState });
            }, 100);
        }
    }

    const [currentModifyOpen, setCurrentModifyOpen] = useState(-1);

    const updateBound = (bound, bound_index, action, value, new_boungs_types) => {
        // Set new landbase model
        var new_landBase = { ...capacity.landBase };
        // Get updated bound
        new_landBase.union.bounds_groups[bound_index] = bound;
        // Get new bouds_types
        new_landBase.union.bounds_types = new_boungs_types;
        // Get new calculated values
        var limit_total_length = 0;
        var limit_separation_length = 0;
        var limit_public_length = 0;
        var limit_nc_length = 0;
        for (var i = 0; i < capacity.landBase.union.bounds_groups.length; i++) {
            limit_total_length += capacity.landBase.union.bounds_groups[i].length;
            if (capacity.landBase.union.bounds_groups[i].context_analysis.result.type === "separation") {
                limit_separation_length += capacity.landBase.union.bounds_groups[i].length;
            }
            else if (capacity.landBase.union.bounds_groups[i].context_analysis.result.type === "way") {
                limit_public_length += capacity.landBase.union.bounds_groups[i].length;
            }
            else {
                limit_nc_length += capacity.landBase.union.bounds_groups[i].length;
            }
        }
        new_landBase.calculated_values = {
            limit_total_length: limit_total_length,
            limit_separation_length: limit_separation_length,
            limit_public_length: limit_public_length,
            limit_nc_length: limit_nc_length
        }


        // Update capacity
        // setCapacity({ ...capacity, landBase: new_landBase, toSave: { autoSave: false, action: action, value: value, keys: [] } });
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("landBase");
        toSaveData.autoSave = false;
        toSaveData.action = action;
        toSaveData.value = value;
        setCapacity({ ...capacity, landBase: new_landBase, toSave: toSaveData });
        // setCapacity({ ...capacity, landBase: new_landBase, toSave: { autoSave: false, action: action, value: value, keys: ["landBase"] } });

        // Update map
        // layer_Refresh("boundsType");

        // Track updates
        setBoundUpdateTracker((Date.now()).toString());
    }

    var chartData = {
        labels: ['Linéaire de limite séparative', 'Linéaire de limite sur voie', 'Linéaire de limite de type "Autre"'],
        datasets: [
            {
                label: 'Types de limites',
                data: [capacity?.landBase?.calculated_values?.limit_separation_length.toFixed(2), capacity?.landBase?.calculated_values?.limit_public_length.toFixed(2), capacity?.landBase?.calculated_values?.limit_nc_length.toFixed(2)],
                backgroundColor: [
                    '#004861',
                    '#007384',
                    '#00a097',
                ],
                borderColor: "#FFF",
                hoverBorderColor: "#FFF",
                borderWidth: 2,
                hoverBorderWidth: 6
            },
        ],
    };

    var chartOptions = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                align: 'start'
            },
        }
    }

    return (
        <div className="app__sectiondata_widget_layout_B">
            <div>
                <div className="app__sectiondata_widget_title_container">
                    <div className="app__sectiondata_widget_title">Détail des limites</div>
                    {capacity?.step > 0 &&
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch00" checked={mapLayers.boundsType.isVisible} onChange={() => { layer_Toggle("boundsType") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch00">Toggle</label></div>
                        </div>
                    }
                </div>
                <div className="app__sectiondata__widget">
                    <div className={`${window.innerWidth > 550 ? "app__sectiondata_widget_layout_A" : ""}`} style={{ justifyContent: "space-between" }}>
                        <div className="app__sectiondata_widget_layout_B">
                            <div className="app__sectiondata_widget_value_container">
                                <div className="app__sectiondata_widget_title">Linéaire total de limite</div>
                                <div className="app__sectiondata_widget_value_text">{capacity?.landBase?.calculated_values ? helpers.get_pretty_num((capacity.landBase.calculated_values.limit_total_length).toFixed(2)) : 0}</div>
                                <div className="app__sectiondata_widget_value_legend">m</div>
                            </div>
                            <div className="app__sectiondata_widget_value_container">
                                <div className="app__sectiondata_widget_title">Détail des linéaires de limite par type</div>
                                <div className="app__bound_container">
                                    <div className="app__bound_title_container">
                                        <div className="app__bound_title app_bound_col00">Type de limite</div>
                                        <div className="app__bound_title app_bound_col10">Linéaire total</div>
                                    </div>
                                    <div className="app__bound_body_container_small">
                                        <div className="app__bound_body_value_submain app_bound_col00">
                                            <div className="app__bound_type_container">
                                                {window.innerWidth > 550 &&
                                                    <div className="app__type_color color_sep"></div>
                                                }
                                                <div className="app__bound_body_value">Limite séparative</div>
                                            </div>
                                        </div>
                                        <div className="app__bound_body_value app_bound_col10">{helpers.get_pretty_num(capacity?.landBase?.calculated_values?.limit_separation_length.toFixed(2))} m</div>
                                    </div>
                                </div>
                                <div className="app__bound_container">
                                    <div className="app__bound_line_container"></div>
                                    <div className="app__bound_body_container_small">
                                        <div className="app__bound_body_value_submain app_bound_col00">
                                            <div className="app__bound_type_container">
                                                {window.innerWidth > 550 &&
                                                    <div className="app__type_color color_pub"></div>
                                                }
                                                <div className="app__bound_body_value">Limite sur voie</div>
                                            </div>
                                        </div>
                                        <div className="app__bound_body_value app_bound_col10">{helpers.get_pretty_num(capacity?.landBase?.calculated_values?.limit_public_length.toFixed(2))} m</div>
                                    </div>
                                </div>
                                <div className="app__bound_container">
                                    <div className="app__bound_line_container"></div>
                                    <div className="app__bound_body_container_small">
                                        <div className="app__bound_body_value_submain app_bound_col00">
                                            <div className="app__bound_type_container">
                                                {window.innerWidth > 550 &&
                                                    <div className="app__type_color color_nc"></div>
                                                }
                                                <div className="app__bound_body_value">Limite de type "Autre"</div>
                                            </div>
                                        </div>
                                        <div className="app__bound_body_value app_bound_col10">{helpers.get_pretty_num(capacity?.landBase?.calculated_values?.limit_nc_length.toFixed(2))} m</div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="app__sectiondata_widget_value_container">
                                <div className="app__sectiondata_widget_title">Linéaire de limite séparative</div>
                                <div className="app__sectiondata_widget_value_text_medium">{capacity?.landBase?.calculated_values ? (capacity.landBase.calculated_values.limit_separation_length).toFixed(2) : 0}</div>
                                <div className="app__sectiondata_widget_value_legend">m</div>
                            </div>
                            <div className="app__sectiondata_widget_value_container">
                                <div className="app__sectiondata_widget_title">Linéaire de limite sur voie publique</div>
                                <div className="app__sectiondata_widget_value_text_medium">{capacity?.landBase?.calculated_values ? (capacity.landBase.calculated_values.limit_public_length).toFixed(2) : 0}</div>
                                <div className="app__sectiondata_widget_value_legend">m</div>
                            </div> */}
                        </div>
                        {window.innerWidth > 550 &&
                            <div className="app__sectiondata_widget_chart_container" style={{ maxWidth: "230px" }}>
                                {(capacity?.landBase?.union?.bounds_groups && capacity.landBase.union.bounds_groups.length > 0 && capacity?.landBase?.calculated_values) &&
                                    <DoughnutChart chartData={chartData} chartOptions={chartOptions} />
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            {(capacity?.landBase?.union?.bounds_groups && capacity.landBase.union.bounds_groups.length > 0) &&
                <div className="app__sectiondata__widget">
                    <div className="app__sectiondata_widget_title">Liste des limites</div>
                    <div className="">
                        {capacity?.landBase?.union?.bounds_groups.map((item, index) => {
                            return (
                                <Bound key={index} bound={item} index={index} bounds_types={capacity.landBase.union.bounds_types} updateBound={updateBound} currentModifyOpen={currentModifyOpen} setCurrentModifyOpen={setCurrentModifyOpen} capacity={capacity} />
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    );
};

export default Bounds;