// Import dependencies
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { userUpdate } from '../../../actions/users';
import { capaGetByUserAndTeam, capaCreate } from '../../../actions/capa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { browserName } from 'react-device-detect';


// Import CSS & assets
import './Dashboard.css';
import ico_dashboard_outline from '../../../assets/ico/ico_dashboard_outline.svg';
import ico_dashboard_fill from '../../../assets/ico/ico_dashboard_fill.svg';
import ico_user_outline from '../../../assets/ico/ico_user_outline.svg';
import ico_user_fill from '../../../assets/ico/ico_user_fill.svg';
import ico_home_outline from '../../../assets/ico/ico_home_outline.svg';
import ico_home_fill from '../../../assets/ico/ico_home_fill.svg';
import ico_combi_outline from '../../../assets/ico/ico_combi_outline.svg';
import ico_combi_fill from '../../../assets/ico/ico_combi_fill.svg';
import ico_arrow from '../../../assets/ico/ico_arrow_up.svg';
import building from '../../../assets/building_small.png';
import logo_card from '../../../assets/logo_card.svg';


// Import components
import Header from '../../../components/app/header/Header';
import Navbar from '../../../components/app/navbar/Navbar';
import Card from '../../../components/app/card/Card';
import Filter from '../../../components/app/filter/Filter';
import Input from '../../../components/common/input/Input';
import NewCapa from '../../../components/app/newCapa/NewCapa';
import ModalIosApp from '../../../components/common/modaliosapp/ModalIosApp';
import ErrorPage from '../../../components/common/errorPage/ErrorPage';
import DoughnutChart from '../../../components/charts/DoughnutChart';
import HorizontalBarChart from '../../../components/charts/HorizontalBarChart';
import MiniMap from '../../../components/common/miniMap/MiniMap';
import NotAllowed from '../../auth/notallowed/NotAllowed';


// Import external functions
import * as helpers from '../../../helpers/Other_helpers';

const Dashboard = ({ version, environment }) => {

    // console.log("USER INFO", navigator);
    // Set body color
    // document.body.style.background = "#f5f5f5";

    // ERROR MANAGEMENT
    const [isError, setIsError] = useState(false);
    const createError = () => {
        console.log("CREATE ERROR");
        setIsError(true);
    }



    // Set global funcitons
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Global variables
    const layout = [
        {
            title_long: "Profil",
            title_short: "Profil",
            ico_outline: ico_user_outline,
            ico_fill: ico_user_fill,
            isVisible: true
        },
        // {
        //     title_long: "Mon équipe",
        //     title_short: "EQUIPE",
        //     ico_outline: ico_team_outline,
        //     ico_fill: ico_team_fill,
        //     isVisible: true
        // },
        {
            title_long: "Études Capacitaires",
            title_short: "Études",
            ico_outline: ico_combi_outline,
            ico_fill: ico_combi_fill,
            isVisible: true
        },
    ]
    const isApp = false;

    // Set hooks
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [targetSection, setTargetSection] = useState(null);
    const [currentSection, setCurrentSection] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(checkModal());
    // Header & Footer height
    const [headerHeight, setHeaderHeight] = useState(80);
    const [footerHeight, setFooterHeight] = useState(0);


    // TEST CAPACITY
    const [capacityFullList, setCapacityFullList] = useState(["fetching"]);
    const [capacityFilteredList, setCapacityFilteredList] = useState([]);
    const [capacityFilteredLimit, setCapacityFilteredLimit] = useState(100);
    const [isLeft, setIsLeft] = useState(true);
    const [gridGap, setGridGap] = useState(30);
    const [capacitySearch, setCapacitySearch] = useState("");
    useEffect(() => {
        var list = [];
        for (var i = 0; i < 20; i++) {
            list.push(
                {
                    id: i,
                    title: "Etude numéro " + i,
                    description: "Description de l'étude " + i,
                    city: "Ville " + i,
                    PLUZone: "Zone " + i,
                }
            );
        }
        // setCapacityFullList(list);
        // setCapacityFilteredList(list);
    }, []);
    function getIsLeft() {
        var gap = 30;
        var card_width = 260;
        var card_container = document.getElementById('card_container');
        var card_container_width = card_container ? card_container.offsetWidth : 0;
        var inline_max = Math.floor((card_container_width + gap) / (card_width + gap));
        var inline_gap = (card_container_width - (inline_max * card_width)) / (inline_max - 1);
        if (capacityFilteredList.length < inline_max) {
            setIsLeft(true);
            setGridGap(inline_gap);
        }
        else {
            setIsLeft(false);
            setGridGap(30);
        }
    }
    useEffect(() => {
        console.log("CHANGE FILTERED LIST", capacityFilteredList);
        getIsLeft();
    }, [capacityFilteredList]);
    // Resize window
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, { passive: true });
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        // console.log("RESIZE");
        // Check header's height
        setHeaderHeight(document.getElementById('header')?.offsetHeight || 80);
        // Check footer's height
        if (window.innerWidth < 550) {
            setFooterHeight(80);
        }
        else {
            setFooterHeight(0);
        }
        // Check dashboard height
        checkDashboardHeight();
    }
    const openCapa = (capaId) => {
        // navigate('/capacity/' + capaId);
        navigate('/capastudy/' + capaId);
    }

    // Set hook functions
    // On load : Set input values for user firstname and lastname
    // useEffect(() => {
    // document.getElementById('firstName').value = user?.result?.firstName;
    // document.getElementById('lastName').value = user?.result?.lastName;
    // }, []);
    // On load : Get capacities list from user id and team id
    useEffect(() => {
        // Get minimum nb of items to fetch
        var limit = 5;
        if (window.innerWidth > 1000) {
            limit = Math.floor((window.innerWidth - 150) / 290) * 2 - 1;
        }
        // console.log("limit", limit);
        setCapacityFilteredLimit(limit);
        var teamId = "";
        if (user?.teams && user.teams.length > 0) { teamId = user.teams[0]._id };
        const filter = {
            userId: user?.result?._id,
            teamId: teamId,
            limit: limit
        }
        dispatch(capaGetByUserAndTeam(filter, handleCapaGetSuccess, handleCapaGetFail));
    }, []);

    const switchCapacityFilteredLimit = () => {
        if (capacityFilteredLimit === null) {
            setCapacityFilteredLimit(Math.floor((window.innerWidth - 150) / 290) * 2 - 1);
        }
        else {
            setCapacityFilteredLimit(null);
        }
    }

    // Create new capa
    const createNewCapa = () => {
        var userId = "";
        if (user?.result?._id) { userId = user.result._id };
        var teams = [];
        if (user?.teams && user.teams.length > 0) { teams = [user.teams[0]._id] };
        const formData = { userId, teams };
        dispatch(capaCreate(formData, handleSuccess, handleFail));
    }
    // Loading
    const handleSuccess = (data) => {
        console.log("SUCCESS", data);
        navigate(`/capastudy/${data.result._id}`);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    // Set specific funciton
    // Dashboard dimensions
    const checkDashboardHeight = () => {
        var newValue = getDashboardHeight();
        if (newValue !== dashboardHeight) {
            setDashboardHeight(newValue);
        }
    }
    const getDashboardHeight = () => {
        var header_height = document.getElementById('header')?.offsetHeight || 80;
        var bottom_margin = 30;
        var title_height = 70;
        return (window.innerHeight - header_height - title_height)
    }
    const [dashboardHeight, setDashboardHeight] = useState(getDashboardHeight());
    // Check device
    function checkModal() {
        if (browserName === "Mobile Safari" && window.navigator.standalone === false) {
            return true;
        }
        else {
            return false;
        }
    }
    // Change section
    const updateCurrentSection = (value) => {
        setCurrentSection(value);
    }
    // Get random avatar
    function getRandomAvatar(length, type) {
        if (type === "initials") {
            return 'https://avatars.dicebear.com/api/initials/' + user?.result?.firstName.charAt(0) + user?.result?.lastName.charAt(0) + '.svg?b=%23062134';
        }
        else {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return 'https://avatars.dicebear.com/api/adventurer-neutral/' + result + '.svg?scale=90';
        }
    }
    // Get custom avatar
    const handleFileRead = async (event) => {
        // Get file from input
        const file = event.target.files[0];
        console.log(file);
        // Convert to base64
        const base64 = await convertBase64(file);
        console.log(base64);
        // Set base64 as avatar
        handleUserChangeAvatar(base64);
        // Reset input
        document.getElementById("avatar_input").value = "";
    }
    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    // Update user data
    const handleUserChangeAvatarInitials = async () => {

        var base64 = await getInitialsB64(user?.result?.firstName, user?.result?.lastName);

        const newData = {
            firstName: user?.result?.firstName,
            lastName: user?.result?.lastName,
            avatar: base64,
        }
        if (newData.avatar !== "" && newData.avatar !== user?.result?.avatar) {
            handleUserUpdate(newData);
        }
        else {
            console.log("profile pic is already initals");
        }
    }
    const getInitialsB64 = async (firstName, lastName) => {
        var initals = "";
        if (firstName && firstName.length > 0) {
            initals += firstName[0];
        }
        if (lastName && lastName.length > 0) {
            initals += lastName[0];
        }

        var svg_string = '<svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="200" height="200" fill="#062134"/><text fill="white" xml:space="preserve" style="white-space: pre" font-family="Helvetica" font-size="100" letter-spacing="0.em" text-anchor="middle" x="100" y="135">' + initals + '</text></svg>';
        const base64 = await convertBase64(new Blob([svg_string], { type: 'image/svg+xml' }));

        return base64
    }
    const handleUserChangeAvatar = (avatar) => {
        const newData = {
            firstName: user?.result?.firstName,
            lastName: user?.result?.lastName,
            avatar: avatar,
        }
        if (newData.avatar !== "" && newData.avatar !== user?.result?.avatar) {
            handleUserUpdate(newData);
        }
        else {
            console.log("user.avatar invalid");
        }
    }
    const handleUserChangeFirstName = async (e) => {
        var value = e.target.value;

        if (value.length === 0) {
            console.log("cannot be empty");
            document.getElementById('firstName').value = user?.result?.firstName;
            return
        }
        else if (value.length === 1) {
            value = e.target.value.toUpperCase();
        }
        else {
            value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase();
        }

        const newData = {
            firstName: value,
            lastName: user?.result?.lastName,
            avatar: user?.result?.avatar,
        }
        if (newData.firstName !== "" && newData.firstName !== user?.result?.firstName) {
            if (user?.result?.avatar.includes("data:image/svg+xml;base64,")) {
                console.log("UPDATE PP");
                newData.avatar = await getInitialsB64(newData.firstName, user?.result?.lastName);
            }
            handleUserUpdate(newData);
            document.getElementById('firstName').value = value;
        }
        else {
            console.log("user.firstName invalid");
            document.getElementById('firstName').value = user?.result?.firstName;
        }
    }
    const handleUserChangeLastName = async (e) => {
        var value = e.target.value;

        if (value.length === 0) {
            console.log("cannot be empty");
            document.getElementById('lastName').value = user?.result?.firstName;
            return
        }
        else {
            value = e.target.value.toUpperCase();
        }

        const newData = {
            firstName: user?.result?.firstName,
            lastName: value,
            avatar: user?.result?.avatar,
        }
        if (newData.lastName !== "" && newData.lastName !== user?.result?.lastName) {
            if (user?.result?.avatar.includes("data:image/svg+xml;base64,")) {
                console.log("UPDATE PP");
                newData.avatar = await getInitialsB64(user?.result?.firstName, newData.lastName);
            }
            handleUserUpdate(newData);
            document.getElementById('lastName').value = value;
        }
        else {
            console.log("user.lastName invalid");
            document.getElementById('lastName').value = user?.result?.lastName;
        }
    }
    const handleUserUpdate = (userData) => {
        console.log("updating user", userData);
        dispatch(userUpdate(user?.result?._id, { userData }, handleUpdateSuccess, handleUpdateFail));
    }
    const handleUpdateSuccess = (data) => {
        console.log("SUCCESS", data);
        setUser(JSON.parse(localStorage.getItem('profile')));
    }
    const handleUpdateFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }
    const handleCapaGetSuccess = (data) => {
        console.log("SUCCESS");
        console.log("SUCCESS", data);
        setCapacityFullList(data);
        setCapacityFilteredList(data);
        getChartData(data);
    }
    const handleCapaGetFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    // CHART
    const getChartData = (list) => {
        var nbStudyUser = list.filter(study => study.creationUserId === user?.result?._id);

        var studyByUser = list.filter(study => study.creationUserId === user?.result?._id);

        // Don't change chartData if no data
        if (studyByUser.length === 0) {
            return;
        }

        // Get last study
        var studyLastTime = Date.parse(studyByUser[studyByUser.length - 1].creationDate);
        // console.log("studyLast", studyLastTime);
        var today = new Date();
        // console.log("today", today);
        // console.log("Jour", today.getDay());
        var week_limit = today.getDay() * (1000 * 60 * 60 * 24);
        var month1_limit = today.getDate() * (1000 * 60 * 60 * 24);
        var month3_limit = month1_limit + (2 * 30 * (1000 * 60 * 60 * 24));
        var nbDaySinceToday = Math.ceil((today - studyLastTime) / (1000 * 60 * 60 * 24));
        // console.log("nbDaySinceToday", nbDaySinceToday);

        var studyUser = [0, 0, 0, 0];
        var studyAll = [0, 0, 0, 0];
        list.forEach(study => {
            if (study.creationUserId === user?.result?._id) {
                // Add to all
                studyUser[0]++;
                // Add to last 3 months
                if (Date.parse(study.creationDate) > (today - month3_limit)) {
                    studyUser[1]++;
                }
                // Add to last month
                if (Date.parse(study.creationDate) > (today - month1_limit)) {
                    studyUser[2]++;
                }
                // Add to last week
                if (Date.parse(study.creationDate) > (today - week_limit)) {
                    studyUser[3]++;
                }
            }
            // Add to all
            studyAll[0]++;
            // Add to last 3 months
            if (Date.parse(study.creationDate) > (today - month3_limit)) {
                studyAll[1]++;
            }
            // Add to last month
            if (Date.parse(study.creationDate) > (today - month1_limit)) {
                studyAll[2]++;
            }
            // Add to last week
            if (Date.parse(study.creationDate) > (today - week_limit)) {
                studyAll[3]++;
            }
        })
        // console.log("studyUser", studyUser);
        // console.log("studyAll", studyAll);

        setBarChartData({
            labels: ["Toutes les études", "Etudes réalisées les 3 derniers mois", "Etudes réalisées ce moi-ci", "Etudes réalisées cette semaine"],
            datasets: [
                {
                    label: "Etudes de l'équipe",
                    data: studyAll,
                    backgroundColor: ["#062132", "#062132", "#062132", "#062132"],
                    borderColor: "#F1F1F1",
                    hoverBorderColor: "#F1F1F1",
                    borderWidth: 1,
                    hoverBorderWidth: 1
                },
                {
                    label: 'Mes études',
                    data: studyUser,
                    backgroundColor: ["#1ecd97", "#1ecd97", "#1ecd97", "#1ecd97"],
                    // backgroundColor: ["#00b398", "#00b398", "#00b398", "#00b398"],
                    borderColor: "#F1F1F1",
                    hoverBorderColor: "#F1F1F1",
                    borderWidth: 1,
                    hoverBorderWidth: 1
                },
            ],
        })


        // setChartData({
        //     labels: ["Mes études", "Autre études"],
        //     datasets: [
        //         {
        //             label: '',
        //             data: [nbStudyUser.length, list.length - nbStudyUser.length],
        //             backgroundColor: [
        //                 '#062132',
        //                 '#00b398',
        //             ],
        //             borderColor: "#F1F1F1",
        //             hoverBorderColor: "#F1F1F1",
        //             borderWidth: 1,
        //             hoverBorderWidth: 1
        //         },
        //     ],
        // });
    }

    const [chartData, setChartData] = useState({
        labels: ["Mes études", "Autre études"],
        datasets: [
            {
                label: '',
                data: [0, 0],
                backgroundColor: [
                    '#062132',
                    '#00b398',
                ],
                borderColor: "#F1F1F1",
                hoverBorderColor: "#F1F1F1",
                borderWidth: 1,
                hoverBorderWidth: 1
            },
        ],
    });
    const chartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                align: 'middle'
            },
            // legend: {
            //     display: true,
            //     position: 'bottom',
            //     align: 'middle'
            // },
        }
    }
    const barChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
        },
        scales: {
            xAxis: {
                display: false,
                // stacked: true,
            },
            yAxis: {
                display: true,
                // stacked: true,
                ticks: {
                    stepSize: 1
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
    }
    const [barChartData, setBarChartData] = useState({
        labels: ["Toutes les études", "Etudes réalisées les 3 derniers mois", "Etudes réalisées ce moi-ci", "Etudes réalisées cette semaine"],
        datasets: [
            {
                label: "Etudes de l'équipe",
                data: [0, 0, 0, 0],
                backgroundColor: ["#062132", "#062132", "#062132", "#062132"],
                borderColor: "#F1F1F1",
                hoverBorderColor: "#F1F1F1",
                borderWidth: 1,
                hoverBorderWidth: 1
            },
            {
                label: 'Mes études',
                data: [0, 0, 0, 0],
                backgroundColor: ["#1ecd97", "#1ecd97", "#1ecd97", "#1ecd97"],
                // backgroundColor: ["#00b398", "#00b398", "#00b398", "#00b398"],
                borderColor: "#F1F1F1",
                hoverBorderColor: "#F1F1F1",
                borderWidth: 1,
                hoverBorderWidth: 1
            },
        ],
    });


    function prepareEmail() {
        var subject = "Demande de rattachement à une équipe";
        var body = "Bonjour,\r\n\r\nJe souhaite que mon compte <" + user?.result?.email + "> soit rattaché à une équipe afin de pouvoir utiliser pleinement l'outil.\r\n\r\nMerci de me recontacter à ce sujet.";
        var uri = "mailto:contact@fractal-buildings.com?subject=";
        uri += encodeURIComponent(subject);
        uri += "&body=";
        uri += encodeURIComponent(body);
        window.open(uri);
    }


    if (user?.token && (!user?.teams || user?.teams.length <= 0)) {
        return (
            <NotAllowed version={version} environment={environment} />
        )
    }
    else {
        return (
            <div>
                <Header title="Dashboard" isApp={isApp} userHook={user} />
                <Navbar layout={layout} isApp={isApp} targetSection={targetSection} setTargetSection={setTargetSection} currentSection={currentSection} updateCurrentSection={updateCurrentSection} version={version} environment={environment} />
                {isModalVisible &&
                    <ModalIosApp handleClose={setIsModalVisible} />
                }
                {isError &&
                    <ErrorPage setIsError={setIsError} version={version} />
                }
                <div id="section_separator" className="dashboard__sectionseparator" style={{ top: headerHeight - 30 }}></div>
                <div id="section_data" className="dashboard__sectiondata" style={{ top: headerHeight, height: window.innerHeight - headerHeight - footerHeight }}>
                    <div id='section_0' className="dashboard__sectiondata_container">
                        <div className="dashboard__sectiondata_title_container">
                            <h3>{layout[0]['title_long']}</h3>
                            <div className="dashboard__sectiondata_title_bar"></div>
                        </div>
                        <div id="section_body_container_user_data" className="dashboard__sectiondata_body_container">
                            <div className="app__section_body" style={{ height: dashboardHeight + "px" }}>
                                <div className="app__section_dashboard">
                                    <div className="app__section_body_col12">
                                        <div className="app__section_body_col1">
                                            <div className="app__section_body_col1_row1 app__widget">
                                                <div className="app__widget2_title" onClick={() => { createError() }}>Informations personnelles</div>
                                                <div className="dashboard__user_container">
                                                    <div className="app__userdata_avatar_container">
                                                        <div className="app__userdata_avatar">
                                                            <img src={user?.result?.avatar} alt="user_pp" />
                                                        </div>
                                                        <div className="app__userdata_avatar_container_buttons">
                                                            {/* <button onClick={() => { handleUserChangeAvatar(getRandomAvatar(10, "initials")) }}>Utiliser mes initiales</button> */}
                                                            <button onClick={() => { handleUserChangeAvatarInitials() }}>Utiliser mes initiales</button>
                                                            <span>ou</span>
                                                            {/* <button onClick={() => { handleUserChangeAvatar(getRandomAvatar(10, "adventurer-neutral")) }}>Générer un avatar aléatoire</button>
                                                            <span>ou</span> */}
                                                            <label htmlFor="avatar_input">Choisir une image</label>
                                                            <input type="file" id="avatar_input" name="avatar" accept="image/png, image/jpeg" onChange={e => handleFileRead(e)} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="app__userdata_avatar_container">
                                                    <img src={user?.result?.avatar} alt="user_pp" />
                                                    <div className="app__userdata_avatar_container_buttons">
                                                        <button onClick={() => { handleUserChangeAvatar(getRandomAvatar(10, "initials")) }}>Utiliser mes initiales</button>
                                                        <span>ou</span>
                                                        <button onClick={() => { handleUserChangeAvatar(getRandomAvatar(10, "adventurer-neutral")) }}>Générer un avatar aléatoire</button>
                                                        <span>ou</span>
                                                        <label htmlFor="avatar_input">Choisir une image</label>
                                                        <input type="file" id="avatar_input" name="avatar" accept="image/png, image/jpeg" onChange={e => handleFileRead(e)} />
                                                    </div>
                                                </div> */}
                                                    <div className="app__userdata_info_container">
                                                        <div className="app__userdata_info_subcontainer">
                                                            <Input type='text' name='firstName' label='Prénom' defaultValue={user?.result?.firstName} handleBlur={handleUserChangeFirstName} isDark={true} />
                                                            <Input type='text' name='lastName' label='Nom' defaultValue={user?.result?.lastName} handleBlur={handleUserChangeLastName} isDark={true} />
                                                        </div>
                                                        {window.innerWidth > 550 &&
                                                            <Input type='text' name='email' label='Adresse mail' value={user?.result?.email} readOnly={true} isDark={true} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="app__section_body_col1_row2 app__widget">
                                                {(!user?.teams || user?.teams.length <= 0) &&
                                                    <div className="app__section_hide"></div>
                                                }
                                                {capacityFullList.length === 1 && capacityFullList[0] === "fetching" ?
                                                    <>
                                                        <div className="app__widget2_title">Statistiques personnelles</div>
                                                        <div className="app__sectiondata_loading_container_box">
                                                            <div className="app__sectiondata_loading"></div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div>
                                                            <div className="app__widget2_title">Statistiques personnelles</div>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div className="dashboard__value_container">
                                                                    <div className="dashboard__value_text">{capacityFullList.filter(study => study.creationUserId === user?.result?._id).length}</div>
                                                                    <div className="dashboard__value_unit">études<br></br>capacitaires</div>
                                                                </div>
                                                                <div className="dashboard__value_container">
                                                                    {capacityFullList.filter(study => study.creationUserId === user?.result?._id).length > 0 ?
                                                                        <div className="dashboard__value_text">{helpers.get_pretty_num(Math.round((capacityFullList.filter(study => study.creationUserId === user?.result?._id)).map(study => study?.simplified?.buildable_area || 0).reduce((prev, next) => prev + next)))}</div>
                                                                        :
                                                                        <div className="dashboard__value_text">0</div>
                                                                    }
                                                                    <div className="dashboard__value_unit">m² constructibles</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {window.innerHeight > 840 &&
                                                            <>
                                                                <div className="app__sectiondata_widget_spacer_10"></div>
                                                                <div style={{ width: "100%", height: "calc(100% - 116px)", maxHeight: "180px" }}>
                                                                    {/* <DoughnutChart chartData={chartData} chartOptions={chartOptions} /> */}
                                                                    <HorizontalBarChart chartData={barChartData} chartOptions={barChartOptions} />
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
    
                                            </div>
                                        </div>
                                        <div className="app__section_body_col2">
                                            {(!user?.teams || user?.teams.length <= 0) &&
                                                <div className="app__section_hide">
                                                    <div className="app__sectiondata_loading_text" style={{ width: "70%" }}>
                                                        Vous devez être Premium pour utiliser toutes les fonctionnalités de PropLab.
                                                    </div>
                                                    <div className="app__section_hide_buttons">
                                                        <div className="combi__launch_button" onClick={() => { prepareEmail() }}>Passer Premium</div>
                                                        <div className="combi__launch_button_white" onClick={() => { prepareEmail() }}>Essayer gratuitement</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="app__section_body_col2_row1 app__widget dashboard__container_dark" style={{ position: "relative" }}>
                                                <div className="dashboard__container_img">
                                                    {(user?.teams && user?.teams.length > 0 && user?.teams[0]?.image && user?.teams[0]?.image !== "") ?
                                                        <img className="dashboard__container_img_logo" src={user?.teams[0]?.image} alt="" />
                                                        :
                                                        <img src={building} alt="" />
                                                    }
                                                </div>
                                                <div className="dashboard__container_body">
                                                    <div className="app__widget2_title">Équipe</div>
                                                    {(user?.teams && user?.teams.length > 0) &&
                                                        <div className="dashboard__team_name">{user?.teams[0]?.name}</div>
                                                    }
                                                    {(user?.users && user.users.length > 0) &&
                                                        <div className="dashboard__team_preview_list">
                                                            {user.users.map((member, index) => {
                                                                return (
                                                                    <div key={"member_pic_" + index} className="dashboard__team_preview" style={{ left: (index * 15) + "px" }}>
                                                                        {member?._id === user?.result?._id ?
                                                                            <img src={user?.result?.avatar} alt="" />
                                                                            :
                                                                            <img src={member.avatar} alt="" />
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="app__section_body_col2_row2 app__widget">
                                                {user?.users &&
                                                    <>
                                                        <div className="app__widget2_title">Membres de l'équipe</div>
                                                        <div className="dashboard__team_member_list">
                                                            {user.users.map((member, index) => {
                                                                return (
                                                                    <div key={"member_line_" + index} className="dashboard__team_member_container">
                                                                        {member?._id === user?.result?._id ?
                                                                            <>
                                                                                <div className="dashboard__team_member_img dashboard__team_member_col0"><img src={user?.result?.avatar} alt="" /></div>
                                                                                <div className="dashboard__team_member_text dashboard__team_member_col1">{user?.result?.firstName + " " + user?.result?.lastName}</div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="dashboard__team_member_img dashboard__team_member_col0"><img src={member.avatar} alt="" /></div>
                                                                                <div className="dashboard__team_member_text dashboard__team_member_col1">{member.firstName + " " + member.lastName}</div>
                                                                            </>
                                                                        }
                                                                        {window.innerWidth > 550 &&
                                                                            <>
                                                                                <div className="dashboard__team_member_col2">{member.email}</div>
                                                                                {capacityFullList.length === 1 && capacityFullList[0] === "fetching" ?
                                                                                    <div className="dashboard__team_member_col3"></div>
                                                                                    :
                                                                                    <>
                                                                                        {capacityFullList.filter(study => study.creationUserId === member._id).length === 0 ?
                                                                                            <div className="dashboard__team_member_col3">Aucune étude</div>
                                                                                            :
                                                                                            <div className="dashboard__team_member_col3">{capacityFullList.filter(study => study.creationUserId === member._id).length} études</div>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                }
                                                {window.innerWidth > 550 &&
                                                    <>
                                                        <div className="app__sectiondata_widget_spacer_30"></div>
                                                        <div className="app__widget2_title">Statistiques de l'équipe</div>
                                                        <div className="dashboard__team_stats_container">
                                                            {capacityFullList.length === 1 && capacityFullList[0] === "fetching" ?
                                                                <div className="app__sectiondata_loading_container_box" style={{ flex: "0.7" }}>
                                                                    <div className="app__sectiondata_loading"></div>
                                                                </div>
                                                                :
                                                                <div className="dashboard__team_stats_data_container">
                                                                    {/* <div className="dashboard__team_stats_data_subcontainer"> */}
                                                                    <div className="dashboard__value_container">
                                                                        <div className="dashboard__value_text">{user?.users ? user?.users.length : "0"}</div>
                                                                        <div className="dashboard__value_unit">membres</div>
                                                                    </div>
                                                                    <div className="dashboard__value_container">
                                                                        <div className="dashboard__value_text">{capacityFullList.length}</div>
                                                                        <div className="dashboard__value_unit">études<br />capacitaires</div>
                                                                    </div>
                                                                    {/* </div> */}
                                                                    {/* <div className="dashboard__value_container">
                                                                        <div className="dashboard__value_text">{helpers.get_pretty_num(Math.round(capacityFullList.map(study => study?.simplified?.land_area || 0).reduce((prev, next) => prev + next)))}</div>
                                                                        <div className="dashboard__value_unit">m² étudiés</div>
                                                                    </div> */}
                                                                    <div className="dashboard__value_container">
                                                                        {capacityFullList.length > 0 ?
                                                                            <div className="dashboard__value_text">{helpers.get_pretty_num(Math.round(capacityFullList.map(study => study?.simplified?.buildable_area || 0).reduce((prev, next) => prev + next)))}</div>
                                                                            :
                                                                            <div className="dashboard__value_text">0</div>
                                                                        }
                                                                        <div className="dashboard__value_unit">m² constructibles</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="dashboard__team_stats_map_container">
                                                                <MiniMap capacityFullList={capacityFullList} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {window.innerWidth > 550 ?
                                        <div className="app__section_body_col3 app__widget">
                                            {(!user?.teams || user?.teams.length <= 0) &&
                                                <div className="app__section_hide">
                                                </div>
                                            }
                                            <div className="app__widget2_title">Dernières études capacitaires</div>
                                            <div className="dashboard__study_list_container">
                                                <div className="dashboard__study_list">
                                                    {capacityFullList.length === 1 && capacityFullList[0] === "fetching" ?
                                                        <div className="app__sectiondata_loading_container_box">
                                                            <div className="app__sectiondata_loading"></div>
                                                        </div>
                                                        :
                                                        <>
                                                            {capacityFullList.length === 0 ?
                                                                <div className="dashboard__study_empty">
                                                                    <div className="app__sectiondata_loading_text" style={{ maxWidth: "270px" }}>
                                                                        <span style={{ fontSize: "20px" }}>👍</span><br></br>Commencez dès maintenant en créant votre première étude capacitaire
                                                                    </div>
                                                                    <div className="combi__launch_button" onClick={() => { createNewCapa() }}>Nouvelle étude capacitaire</div>
                                                                </div>
                                                                :
                                                                <>
                                                                    {capacityFullList.slice(0, 6).map((capaData, index) => {
                                                                        // if (capacityFullList === null || index < capacityFullList) {
                                                                        return (
                                                                            <div key={"mini_capa" + index} className="dashboard__study_container" onClick={() => { openCapa(capaData._id) }}>
                                                                                <div className="dashboard__study_land_container">
                                                                                    {(capaData?.simplified?.poly?.bounds_groups && capaData?.simplified?.poly?.bounds_groups.length) > 0 ?
                                                                                        <svg width="800" height="800" viewBox="-20 -20 840 840" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <polygon points={helpers.get_svgCoords_from_boundsGroupsList(capaData?.simplified?.poly?.bounds_groups, capaData?.simplified?.poly?.bbox).join(" ")} fillRule="evenodd" fill="#FFFFFF" fillOpacity="0.1" stroke="white" strokeWidth="8" />
                                                                                            {capaData?.simplified?.poly?.bounds_groups.map((item, index) => {
                                                                                                return (
                                                                                                    <circle key={"circle_" + index} cx={helpers.get_svgCoords_from_localCoords([item.start_coord_local], capaData?.simplified?.poly?.bbox)[0][0]} cy={helpers.get_svgCoords_from_localCoords([item.start_coord_local], capaData?.simplified?.poly?.bbox)[0][1]} r="14" fill="white" />
                                                                                                )
                                                                                            })}
                                                                                        </svg>
                                                                                        :
                                                                                        <img src={logo_card} alt="" />
                                                                                    }
                                                                                </div>
                                                                                <div className="dashboard__study_info_container">
                                                                                    <div className="dashboard__study_info_title">{capaData.title}</div>
                                                                                    {/* <div className="dashboard__study_info_description">Description de la nouvelle étude</div> */}
                                                                                    <div className="app__card_bottom_tags">
                                                                                        {capaData?.simplified?.city &&
                                                                                            <span>{capaData?.simplified.city}</span>
                                                                                        }
                                                                                        {(capaData?.simplified?.zones && capaData?.simplified?.zones !== "") &&
                                                                                            <span>{capaData?.simplified?.zones}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        // }
                                                                    })}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {/* <div className="dashboard__study_container">
                                                    <div className="dashboard__study_land_container"></div>
                                                    <div className="dashboard__study_info_container">
                                                        <div className="dashboard__study_info_title">Nouvelle étude capacitaire</div>
                                                        <div className="dashboard__study_info_description">Description de la nouvelle étude</div>
                                                    </div>
                                                </div>
                                                <div className="dashboard__study_container"></div>
                                                <div className="dashboard__study_container"></div>
                                                <div className="dashboard__study_container"></div>
                                                <div className="dashboard__study_container"></div> */}
                                                </div>
                                                {capacityFullList.length > 0 &&
                                                    <div className="dashboard__study_new_container">
                                                        <div className="dashboard__study_new_btn" onClick={() => { createNewCapa() }}><span style={{ fontSize: '24px' }}>+</span>⠀Nouvelle étude</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="app__sectiondata_widget_spacer_1"></div>
                                    }
    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='section_1' className="dashboard__sectiondata_container">
                        <div className="dashboard__sectiondata_title_container" style={{ marginBottom: "0px" }}>
                            <h3>{layout[1]['title_long']}</h3>
                            <div className="dashboard__sectiondata_title_bar"></div>
                        </div>
                        <div className="dashboard__sectiondata_body_container">
                            {capacityFullList.length === 1 && capacityFullList[0] === "fetching" ?
                                <div id="card_container" className="dashboard__sectiondata_loading_container">
                                    <div className="app__sectiondata_loading"></div>
                                    <div className="app__sectiondata_loading_text">Nous récupérons vos études et celles de votre équipe</div>
                                </div>
                                :
                                <>
                                    <div className="app__section_filter_container">
                                        {(!user?.teams || user?.teams.length <= 0) &&
                                            <div className="app__section_hide"></div>
                                        }
                                        <Filter capacityFullList={capacityFullList} setCapacityFilteredList={setCapacityFilteredList} capacitySearch={capacitySearch} setCapacitySearch={setCapacitySearch} user={user} />
                                    </div>
                                    {/* <motion.div layout id="card_container" className={isLeft ? 'app__section_card_container_left' : 'app__section_card_container_space'}> */}
                                    <div id="card_container" className={isLeft ? 'app__section_card_container_left' : 'app__section_card_container_space'} style={{ columnGap: gridGap + "px" }}>
                                        {(!user?.teams || user?.teams.length <= 0) ?
                                            <div className="app__section_hide">
                                                <div className="app__sectiondata_loading_text" style={{ width: "70%" }}>
                                                    Vous devez être Premium pour utiliser toutes les fonctionnalités de PropLab.
                                                </div>
                                                <div className="app__section_hide_buttons">
                                                    <div className="combi__launch_button" onClick={() => { prepareEmail() }}>Passer Premium</div>
                                                    <div className="combi__launch_button_white" onClick={() => { prepareEmail() }}>Essayer gratuitement</div>
                                                </div>
                                            </div>
                                            :
                                            <NewCapa user={user} />
                                        }
                                        {/* <AnimatePresence> */}
                                        {capacityFilteredList.map((item, index) => {
                                            if (capacityFilteredLimit === null || index < capacityFilteredLimit) {
                                                return (
                                                    <Card key={"main_capa" + index} capaData={item} user={user} capacityFullList={capacityFullList} setCapacityFullList={setCapacityFullList} setCapacityFilteredList={setCapacityFilteredList} />
                                                )
                                            }
                                        })}
                                        {/* </AnimatePresence> */}
                                    </div>
                                    {capacityFullList.length > capacityFilteredLimit &&
                                        <div className="app__context_housing_detail_button_container" onClick={() => { switchCapacityFilteredLimit() }} style={{ marginTop: "6px" }}>
                                            {capacityFilteredLimit === null &&
                                                <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                            <div className="app__context_housing_detail_button">{capacityFilteredLimit === null ? "Afficher moins d'études capacitaires" : "Afficher toutes les études capacitaires"}</div>
                                            {capacityFilteredLimit !== null &&
                                                <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                            }
                                        </div>
                                    }
                                    {/* </motion.div> */}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="app__section_footer_mobile"></div>
            </div >
        );
    }
};

export default Dashboard;