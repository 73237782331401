// Import dependencies
import React from 'react';

// Import CSS & Assets
import './InputMultiLine.css';

const InputMultiLine = ({ name, label, value, isResetable, handleChange, handleShow, handleKeyUp, handleClear, handleBlur, isDark, readOnly }) => {
    return (
        <div className="common__inputmultiline_container">
            {readOnly === true ?
                <textarea className={isDark ? "common__input_field_dark" : "common__input_field"} placeholder={label} name={name} id={name} required onChange={handleChange} onKeyUp={handleKeyUp} onBlur={handleBlur} value={value} readOnly />
                :
                <textarea className={isDark ? "common__input_field_dark" : "common__input_field"} placeholder={label} name={name} id={name} required onChange={handleChange} onKeyUp={handleKeyUp} onBlur={handleBlur} value={value} />
            }
            <label htmlFor={name} className="form__label">{label}</label>
        </div>
    );
};

export default InputMultiLine;