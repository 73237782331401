import React, { useCallback, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';


const Node_Perimeter_Placeholder = ({ id, data }) => {

    const rule_list = data.rule_list;

    const [isListVisible, setIsListVisible] = useState(false);

    const setNode = (node) => {
        // console.log("data", data);
        // console.log("node", node);

        // Get all accepeted
        var accepted = [];
        node.data.target.forEach(target => {
            accepted.push(target.accepted);
        })
        // Replace
        data.handleReplace(id, node, accepted);
    }


    return (
        <div id={"block_" + id} className="ruleeditor__node_ph_container_rule">
            <div className="ruleeditor__node_ph_description">
                Ajouter un périmètre
            </div>
            <div className="ruleeditor__node_ph_body">
                {/* <div className="ruleeditor__node_ph_body_button" onClick={() => { setIsListVisible(true) }}>+</div> */}
                <div className="ruleeditor__node_ph_body_button" onClick={() => { setNode(rule_list.perimeter.items[0]) }}>+</div>
            </div>
            {/* {isListVisible &&
                <div className="ruleeditor__node_ph_list_container_rule scale-up-top" onMouseLeave={() => { setIsListVisible(true) }}>
                    <div className="ruleeditor__node_ph_list_container_label">Liste des règles disponibles :</div>
                    <div className="ruleeditor__node_ph_list_container_close"><img src={close} alt="close_list" onClick={() => { setIsListVisible(false) }} /></div>
                    {Object.keys(rule_list).map((item, index) => {
                        if (item === "perimeter") {
                            return (
                                <div key={"group_" + index} className="ruleeditor__node_ph_list_group_container">
                                    <div className="ruleeditor__node_ph_list_group_label">{rule_list[item].label}</div>
                                    <div className="ruleeditor__node_ph_list_item_container">
                                        {rule_list[item].items.map((item, index) => {
                                            return (
                                                <div key={"item_" + index} className="ruleeditor__node_ph_list_item" onClick={() => { setNode(item) }}>
                                                    <div className="ruleeditor__node_ph_list_item_label">{item.data.label}</div>
                                                    <div className="ruleeditor__node_ph_list_item_description">{item.data.description}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            } */}
        </div>
    );
};

export default Node_Perimeter_Placeholder;