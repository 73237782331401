import React from "react";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component {
    // constructor(props) {
    //   super(props);
    //   this.state = { hasError: false };
    // }

    state = { hasError: false, errorMessage: "" };

    static getDerivedStateFromError(error) {
        // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
        return { hasError: true, errorMessage: error };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
        console.log(error, errorInfo);
        this.state.errorMessage = error?.message;
    }

    render() {
        if (this.state.hasError) {
            // Vous pouvez afficher n'importe quelle UI de repli.
            // return <h1>Something went wrong.</h1>;
            return <ErrorPage errorMsg={this.state.errorMessage} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary