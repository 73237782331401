import React, { useState, useEffect } from 'react';

// Import CSS
import './Indicators.css';

// Import external functions
import * as other_helpers from '../../../helpers/Other_helpers';

const Indicators = ({ teams, teamsInactive, capas, users, testedArea, buildableArea, teamFilter, teamName }) => {

    const [mytime, setMytime] = useState(0);

    useEffect(() => {
        if (window.innerWidth < 550) {
            // create a interval and get the id
            const myInterval = setInterval(() => {
                setMytime((prevTime) => (prevTime + 1) >= 4 ? 0 : (prevTime + 1));
            }, 3000);
            // clear out the interval using the id when unmounting the component
            return () => clearInterval(myInterval);
        }
    }, []);

    useEffect(() => {
        console.log("New Time", mytime * window.innerWidth);
        document.getElementById('app__admin_indicators').scroll({
            top: 0,
            left: (mytime * window.innerWidth) - (mytime * 20),
            behavior: "smooth",
        });
    }, [mytime])

    return (
        <div className='app__header_indicators_container_main'>
            <div id='app__admin_indicators' className='app__header_indicators_container'>
                <div className="app__header_indicators_element_green">
                    <div className="app__header_indicators_subelement">
                        {teamFilter !== null ?
                            <>
                                <div className="app__header_indicators_element_title_green">Équipe</div>
                                <div className="app__header_indicators_element_value_green_small">{teamName}</div>
                            </>
                        :
                            <>
                                <div className="app__header_indicators_element_title_green">Équipes</div>
                                <div className="app__header_indicators_element_value_green">{teams.length - teamsInactive}</div>
                            </>                        }
                    </div>
                    <div className="app__header_indicators_subelement">
                        <div className="app__header_indicators_element_title_green">Utilisateurs</div>
                        <div className="app__header_indicators_element_value_green">{users.length}</div>
                    </div>
                </div>
                <div className="app__header_indicators_element">
                    <div className="app__header_indicators_element_title">Études capacitaires</div>
                    <div className="app__header_indicators_element_value">{capas.length}</div>
                </div>
                <div className="app__header_indicators_element">
                    <div className="app__header_indicators_element_title">Surface étudiée</div>
                    <div className="app__header_indicators_element_value">{other_helpers.get_pretty_num(testedArea.toFixed(0))} <span className="app__header_indicators_element_subvalue">m²</span></div>
                </div>
                <div className="app__header_indicators_element">
                    <div className="app__header_indicators_element_title">Surface constructible</div>
                    <div className="app__header_indicators_element_value">{other_helpers.get_pretty_num(buildableArea.toFixed(0))} <span className="app__header_indicators_element_subvalue">m²</span></div>
                </div>
            </div>
        </div>
    );
};

export default Indicators;