import React, { useState } from 'react';

// Import CSS & Assets
import './Geocoder.css';
import GeocoderResult from './GeocoderResult';
import close from '../../../../assets/ico/ico_close.svg';


// Import components
import Modification from './GeocoderResult';

const Geocoder = ({ capacity, setCapacity, isMapExpanded }) => {

    // Hooks
    const [isLoading, setIsLoading] = useState(false);
    const [geocoderResults, SetGeocoderResults] = useState([]);

    // Functions
    const handleKeyDown = (e) => {
        // Check if key is "Enter"
        if (e.keyCode === 13) {
            fetchGeocoder(document.getElementById("geocoder_search").value);
        }
        // Check if key is "Escape"
        else if (e.keyCode === 27) {
            resetResults();
        }
    }
    const getSearchType = (search_query) => {
        var search_type = "address";
        if (search_query.length === 14) {
            // console.log("Parcel ???");
            // console.log("Code Insee", search_query.substring(0, 5));
            // console.log("CHECK : Code Insee", /[0-9]{5}/.test(search_query.substring(0, 5)));
            // console.log("Prefixe", search_query.substring(5, 8));
            // console.log("Section", search_query.substring(8, 10));
            // console.log("CHECK : Section", (/[A-Z0]{2}/.test(search_query.substring(8, 10))) && search_query.substring(8, 10) !== "00");
            // console.log("Numero", search_query.substring(10));
            // console.log("CHECK : Numero", !isNaN(parseInt(search_query.substring(10))));
            var search_type_test = true;
            // Check CODE INSEE
            if (!(/[0-9]{5}/.test(search_query.substring(0, 5)))) {
                console.log("INVALID : Code Insee");
                search_type_test = false
            }
            // Check PREFIXE
            if (!(/[A-Z0-9]{3}/.test(search_query.substring(5, 8)))) {
                console.log("INVALID : Prefixe");
                search_type_test = false
            }
            // Check SECTION
            if (!((/[A-Z0]{2}/.test(search_query.substring(8, 10))) && search_query.substring(8, 10) !== "00")) {
                console.log("INVALID : Section");
                search_type_test = false
            }
            if (!(/[0-9]{4}/.test(search_query.substring(10)))) {
                console.log("INVALID : Numero");
                search_type_test = false
            }
            // Set variable
            if (search_type_test) {
                search_type = "land";
            }
        }
        return search_type;
    }
    const fetchGeocoder = (search_query) => {
        // Set loading hook
        setIsLoading(true);
        // console.log("Fetch for :", search_query);
        // Get query type
        var search_type = getSearchType(search_query);
        // console.log("SEARCH TYPE :", search_type);
        // Get fetch URL
        let url = "https://api-adresse.data.gouv.fr/search/?q=" + (search_query.split(' ').join('+'));
        if (search_type === "land") {
            url = "https://apicarto.ign.fr/api/cadastre/parcelle?code_insee=" + search_query.substring(0, 5) + "&section=" + search_query.substring(8, 10) + "&numero=" + search_query.substring(10) + "&_limit=5";
        }
        fetch(url, { "method": "get" })
            .then((httpResponse) => {
                if (httpResponse.ok) {
                    return httpResponse.json();
                } else {
                    return Promise.reject("Fetch did not succeed");
                }
            })
            .then(json => {
                // console.log("Success", json);
                handleFetchSuccess(json, search_type);
            });
    }
    const handleFetchSuccess = (result, search_type) => {
        // Check if same result that actual search (for autocompletion) for type "address" only
        if ((search_type === "address" && document.getElementById("geocoder_search").value === result.query) || search_type === "land") {
            // Set loading hook
            setIsLoading(false);
            // Set results
            SetGeocoderResults(result.features);
        }
        else {
            console.log("Fetch response is not up to date");
        }
    }
    const selectResult = (index) => {
        // console.log("Selected", index);
        // Set label into geocoder input
        if (geocoderResults[index].properties?.label) {
            document.getElementById("geocoder_search").value = geocoderResults[index].properties.label;
        }
        else {
            document.getElementById("geocoder_search").value = geocoderResults[index].properties.idu;
        }
        // Update capacity
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.autoSave = false;
        setCapacity({ ...capacity, geocoderResult: geocoderResults[index], toSave: toSaveData });
        // Reset geocoder results
        SetGeocoderResults([]);
    }

    const resetResults = () => {
        setIsLoading(false);
        SetGeocoderResults([]);
        document.getElementById("geocoder_search").value = "";
    }

    return (
        <>
            {capacity?.step === 0 &&
                <div className={`app__geocoder ${(isMapExpanded === true) ? "app__geocoder_left" : ""}`}>
                    <div className="app__sectiondata__widget app___geocoder_container">
                        <div className="app__geocoder_loader_container">
                            <div className={`app__geocoder_loader_line ${isLoading ? "app__geocoder_loader_line_loading" : ""}`}></div>
                            <div className={`app__geocoder_loader_circle ${isLoading ? "app__geocoder_loader_circle_loading" : ""}`}></div>
                        </div>
                        <input className="app__sectiondata_widget_inputSingleLine" type="text" id="geocoder_search" name="geocoder_search" placeholder="Recherchez une adresse, une commune ou une parcelle" autoComplete="off" onKeyDown={handleKeyDown}></input>
                        <img className={`app__geocoder_close ${geocoderResults.length === 0 ? "app__geocoder_close_hidden" : ""}`} src={close} alt="" onClick={resetResults} />
                    </div>
                    <div className="app__geocoder_results_container">
                        {geocoderResults.map((item, index) => {
                            return (
                                <GeocoderResult key={index} result={item} selectResult={selectResult} index={index} />
                            )
                        })}
                    </div>
                </div>
            }
        </>
    );
};

export default Geocoder;