import React, { useState, useEffect } from 'react';

// Import CSS & assets
import ico_rule_edit from '../../../../assets/ico/ico_rule_edit.svg';

// Import external functions
import * as map_helpers from '../../map/Map_helpers';

const RuleVariable = ({ ruleId, ruleLabel, ruleType, variable, varType, updateVariable, capacity, mapDrawMode, setMapDrawMode, hiddenRule, setHiddenRule }) => {

    const levels_list = [
        { id: "ground", label: "Rez-de-Chaussée" },
        { id: "current", label: "Etages courants" },
        { id: "top", label: "Dernier étage" },
        { id: "sublevels", label: "Niveaux de sous-sol" },
    ];

    const getCategory = () => {
        var result = null;
        if (variable.type === "Node_UserInput_Bounds" || variable.type === "Node_UserInput_Buildings") {
            result = variable.value.substring(0, variable.value.indexOf("__"));
        }
        return result;
    }
    const [selectionCategory, setSelectionCategory] = useState(getCategory());
    const handleCategoryChange = (newCategory) => {
        // Reset selection
        updateCurrentValue(newCategory + "__");
        // Update state
        setSelectionCategory(newCategory);
    }

    const handleCheckboxChange = (checkboxId, checked) => {
        //console.log("CHECKED for" + checkboxId, checked);
        console.log("OLD VALUE", currentValue);
        var newCurrentValue = currentValue;
        if (checked === true && !currentValue.includes("|" + checkboxId + "|")) {
            newCurrentValue = currentValue + "|" + checkboxId + "|";
            //console.log("ADD", newCurrentValue);
        }
        else if (checked === false && currentValue !== null && currentValue.includes("|" + checkboxId + "|")) {
            var changed_Index = currentValue.indexOf("|" + checkboxId + "|");
            var changed_Length = checkboxId.toString().length;
            var left_part = currentValue.substring(0, changed_Index);
            var right_part = currentValue.substring(changed_Index + changed_Length + 2);
            newCurrentValue = left_part + right_part;
            //console.log("REMOVE", newCurrentValue);
        }
        console.log("NEW VALUE", newCurrentValue);
        updateCurrentValue(newCurrentValue);
    }


    const getPrettyCurrentValue = () => {
        //console.log(capacity);
        var value = variable.value;
        // console.log("variable value", value);
        if (variable.type === "Node_UserInput_Bounds") {
            // Get values
            var category = variable.value.substring(0, variable.value.indexOf("__"));
            //console.log("category", category);
            var filters = variable.value.substring(variable.value.indexOf("__") + 2, variable.value.length);
            //console.log("filters", filters);
            // Get bounds list
            var bounds = [];
            if (category === "item") {
                capacity.landBase.union.bounds_groups.forEach((bound_group, index) => {
                    if (filters.includes("|" + bound_group._id + "|")) {
                        bounds.push("Limite N°" + (index + 1));
                    }
                })
                // Check if all items
                if (bounds.length === capacity.landBase.union.bounds_groups.length) {
                    bounds = ["Toutes les limites"];
                }

            }
            else if (category === "type") {
                Object.keys(capacity.landBase.union.bounds_types).forEach((boundType, index) => {
                    var bounds_type = [];
                    Object.keys(capacity.landBase.union.bounds_types[boundType].subtypes).forEach(boundSubType => {
                        if (filters.includes("|" + capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id + "|")) {
                            bounds_type.push(capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].label);
                        }
                    })
                    // Check if all items
                    if (bounds_type.length > 1 && bounds_type.length === Object.keys(capacity.landBase.union.bounds_types[boundType].subtypes).length) {
                        if (index === 0) {
                            bounds.push("Toutes les limites séparatives");
                        }
                        else if (index === 1) {
                            bounds.push("Toutes les limites sur voie");
                        }
                        else {
                            bounds.push("Toutes les limites de type 'Autre'");
                        }
                    }
                    else {
                        bounds = bounds.concat(bounds_type);
                    }
                })
            }
            //console.log("bounds", bounds);
            // Get string join
            var jointure = "";
            for (var i = 0; i < bounds.length; i++) {
                if (i === 0) {
                    jointure = bounds[0];
                }
                else if (i === bounds.length - 1) {
                    jointure = jointure + " et " + bounds[i];
                }
                else {
                    jointure = jointure + ", " + bounds[i];
                }
            }
            value = jointure;
        }
        if (variable.type === "Node_UserInput_Lands") {
            // Get lands list
            var lands = [];
            capacity.landBase.lands.forEach((land, index) => {
                if (variable.value.includes("|" + land?.generated_id + "|")) {
                    lands.push("Parcelle " + land?.properties?.section + "-" + land?.properties?.numero);
                }
            })
            // Check if all items
            if (lands.length >= capacity.landBase.lands.length) {
                lands = ["Toutes les parcelles"];
            }
            // Get string join
            var jointure = "";
            for (var i = 0; i < lands.length; i++) {
                if (i === 0) {
                    jointure = lands[0];
                }
                else if (i === lands.length - 1) {
                    jointure = jointure + " et " + lands[i];
                }
                else {
                    jointure = jointure + ", " + lands[i];
                }
            }
            if (jointure === "") { jointure = "Aucune parcelle sélectionnée" }
            value = jointure;
        }
        if (variable.type === "Node_UserInput_PLUZone") {
            // Get lands list
            var zones = [];
            capacity.rules.gpu_data.zoneUrba.forEach((zone, index) => {
                if (variable.value.includes("|" + zone?.id + "|")) {
                    zones.push("Zone " + zone?.properties?.libelle);
                }
            })
            // Get string join
            var jointure = "";
            for (var i = 0; i < zones.length; i++) {
                if (i === 0) {
                    jointure = zones[0];
                }
                else if (i === zones.length - 1) {
                    jointure = jointure + " et " + zones[i];
                }
                else {
                    jointure = jointure + ", " + zones[i];
                }
            }
            if (jointure === "") { jointure = "Aucune zone sélectionnée" }
            value = jointure;
        }
        if (variable.type === "Node_UserInput_Levels") {
            // Get lands list
            var levels = [];
            levels_list.forEach((level, index) => {
                if (variable.value.includes("|" + level?.id + "|")) {
                    levels.push(level?.label);
                }
            })
            // Check if all items
            if (levels.length >= levels_list.length) {
                levels = ["Tous les niveaux d'infratsructure et de superstructure"];
            }
            else if (variable.value.includes("|ground|") && variable.value.includes("|current|") && variable.value.includes("|top|")) {
                levels = ["Tous les niveaux de superstructure"];
            }
            else if (variable.value.includes("|ground|") && variable.value.includes("|current|")) {
                levels = ["Rez-de-Chaussée et étages courants"];
            }
            else if (variable.value.includes("|top|") && variable.value.includes("|current|")) {
                levels = ["Tous les niveaux de superstructure sauf le RDC"];
            }
            // Get string join
            var jointure = "";
            for (var i = 0; i < levels.length; i++) {
                if (i === 0) {
                    jointure = levels[0];
                }
                else if (i === levels.length - 1) {
                    jointure = jointure + " et " + levels[i];
                }
                else {
                    jointure = jointure + ", " + levels[i];
                }
            }
            if (jointure === "") { jointure = "Aucun niveau sélectionné" }
            value = jointure;
        }
        if (variable.type === "Node_UserInput_Buildings") {
            // Get building list
            var buildings = [];
            capacity.landBase.buildings.buildings_land.forEach((building_index, index) => {
                if (variable.value.includes("|" + building_index + "|")) {
                    if (buildings.length === 0) {
                    }
                    buildings.push("N°" + index);
                }
            })
            if (buildings.length === 1) {
                buildings[0] = "Construction " + buildings[0];
            }
            else if (buildings.length > 1) {
                buildings[0] = "Constructions " + buildings[0];
            }
            // Check if all items
            if (variable.value.includes("|buildings_land|") && variable.value.includes("|buildings_close|")) {
                buildings.push("Constructions existantes sur le terrain et à proximité");
            }
            else {
                if (variable.value.includes("|buildings_land|")) {
                    buildings.push("Constructions existantes sur le terrain");
                }
                if (variable.value.includes("|buildings_close|")) {
                    buildings.push("Constructions existantes à proximité du terrain");
                }
            }
            // Get string join
            var jointure = "";
            for (var i = 0; i < buildings.length; i++) {
                if (i === 0) {
                    jointure = buildings[0];
                }
                else if (i === buildings.length - 1) {
                    jointure = jointure + " et " + buildings[i];
                }
                else {
                    jointure = jointure + ", " + buildings[i];
                }
            }
            if (jointure === "") { jointure = "Aucune construction sélectionnée" }
            value = jointure;
        }
        if (variable.type === "Node_UserInput_Draw_Point") {
            value = "Aucun point";
            if (variable.value.length === 1) {
                value = "1 point placé sur la carte";
            }
            else if (variable.value.length > 1) {
                value = variable.value.length + " points placés sur la carte";
            }
        }
        if (variable.type === "Node_UserInput_Draw_Line") {
            value = "Aucune ligne";
            if (variable.value.length === 1) {
                value = "1 ligne tracée sur la carte";
            }
            else if (variable.value.length > 1) {
                value = variable.value.length + " lignes tracées sur la carte";
            }
        }
        if (variable.type === "Node_UserInput_Draw_Polygon") {
            value = "Aucune surface";
            if (variable.value.length === 1) {
                value = "1 surface tracée sur la carte";
            }
            else if (variable.value.length > 1) {
                value = variable.value.length + " surfaces tracées sur la carte";
            }
        }

        //console.log("value", value);

        return value;
    }

    const [currentValue, setCurrentValue] = useState(variable.value);
    const [prettyCurrentValue, setPrettyCurrentValue] = useState(getPrettyCurrentValue(variable.value));

    const updateCurrentValue = (newValue) => {
        // Reset to min or max if newValue is outside range
        if (newValue !== "" && variable.type === "Node_UserInput_Number_Range" && newValue < variable.data[2]) {
            setCurrentValue(variable.data[2]);
        }
        else if (newValue !== "" && variable.type === "Node_UserInput_Number_Range" && newValue > variable.data[3]) {
            setCurrentValue(variable.data[3]);
        }
        else {
            setCurrentValue(newValue);
        }
    }

    const [isEditing, setIsEditing] = useState(false);
    const openEdition = () => {
        setCurrentValue(variable.value);
        setSelectionCategory(getCategory());
        setIsEditing(true);
    }

    const [isDrawing, setIsDrawing] = useState(false);
    const openDraw = (type) => {
        console.log("OPEN DRAW FROM VARIABLE", type, variable);
        console.log("capacity", capacity);
        setIsDrawing(true);
        var draw_data = {
            type: "FeatureCollection",
            features: []
        }
        if (variable?.value && variable.value.length > 0) {
            variable.value.forEach(value_i => {
                if (value_i?.userData?.turfData?.type === "Feature") {
                    draw_data.features.push(value_i.userData.turfData);
                }
            })
        }
        setMapDrawMode({ mode: type, data: draw_data, target: { rule_Id: ruleId, rule_label: ruleLabel, rule_type: ruleType, var_label: variable.label } });
        setHiddenRule(ruleId);
    }
    useEffect(() => {
        if (mapDrawMode?.target?.rule_Id === ruleId && mapDrawMode?.target?.var_label === variable.label) {

            if (mapDrawMode?.mode === "update") {
                console.log("UPDATE VARIABLE FROM DRAWING", mapDrawMode);

                // Create value OJECT
                var draw_data_new = JSON.parse(JSON.stringify(mapDrawMode.data));
                var variable_data_new_obj = [];
                draw_data_new.features.forEach(feature => {
                    var feature_new = { userData: { turfData: feature } };
                    variable_data_new_obj.push(feature_new);
                })

                // Ceate value STRING
                // var variable_data_new_string = "Node_Input_Number[-15";
                var variable_data_new_string = "";
                if (draw_data_new?.features.length > 0) {
                    // Get type
                    var draw_data_type = "";
                    if (draw_data_new.features[0]?.geometry?.type === "Point") {
                        draw_data_type = "point_";
                    }
                    else if (draw_data_new.features[0]?.geometry?.type === "LineString") {
                        draw_data_type = "line_";
                    }
                    else if (draw_data_new.features[0]?.geometry?.type === "Polygon") {
                        draw_data_type = "polygon_";
                    }
                    // Get coords
                    if (draw_data_type !== "") {
                        var draw_data_coords = [];
                        draw_data_new.features.forEach(feature => {
                            if (feature?.geometry?.coordinates) {
                                // var coords_global = feature.geometry.coordinates;
                                // console.log("coords_global", coords_global);
                                // var coords_local = map_helpers.mercator_to_local(coords_global, capacity.landBase.union.center.geometry.coordinates, 100);
                                // console.log("coords_local", coords_local);
                                // draw_data_coords.push(coords_local);
                                draw_data_coords.push(feature.geometry.coordinates);
                            }
                        })
                        var draw_data_coords_string = JSON.stringify(draw_data_coords);
                        var draw_data_coords_string_replaced = draw_data_coords_string.replace(/\[/g, '(').replace(/\]/g, ')');
                        console.log("draw_data_coords_string", draw_data_coords_string_replaced);

                        variable_data_new_string = "Node_2DElement_MapInput[" + draw_data_type + draw_data_coords_string_replaced + "]";
                    }
                }

                updateVariable(varType, variable.label, variable_data_new_string, variable_data_new_obj);
                setIsDrawing(false);
                setCurrentValue(variable_data_new_obj);
                setPrettyCurrentValue(getPrettyCurrentValue(variable_data_new_obj));
                setMapDrawMode({ mode: null, data: null, target: null });
            }

        }

        if (mapDrawMode?.mode === null) {
            setIsDrawing(false);
        }
    }, [mapDrawMode.mode]);

    const closeAndUpdateCurrentValue = (newValue) => {
        setCurrentValue(newValue);
        closeEdit(newValue);
    }

    // useEffect(() => {
    //     if (isEditing === true) {
    //         var select_element = document.getElementById(ruleId + "_var_" + variable.label + "_input_boolean");
    //         console.log("CLIKED ON", select_element);
    //         setTimeout(function () {
    //             var event = new MouseEvent('mousedown');
    //             select_element.dispatchEvent(event);
    //         }, 200)
    //     }
    // }, [isEditing])

    const closeEdit = (newValue) => {
        // Check if currentValue is correct
        var isCorrect = true;
        if (currentValue === "" || currentValue === null) { isCorrect = false };
        if ((variable.type === "Node_UserInput_Number" || variable.type === "Node_UserInput_Number_Range") && isNaN(currentValue)) { isCorrect = false };
        if (variable.type === "Node_UserInput_Bounds" && currentValue.indexOf("|") === -1) { isCorrect = false };
        if (variable.type === "Node_UserInput_Buildings" && currentValue.indexOf("|") === -1) { isCorrect = false };
        // Do stuff
        if (isCorrect) {
            // Set real value
            var realValue = currentValue;
            if (newValue) {
                realValue = newValue;
            }
            if (variable.type === "Node_UserInput_Number") {
                realValue = parseFloat(currentValue);
            }
            if (variable.type === "Node_UserInput_Boolean") {
                if (realValue === "true") {
                    realValue = true;
                }
                else {
                    realValue = false;
                }
            }
            // Check if current value is different from variable.value
            //console.log("realValue", realValue);
            //console.log("variable.value", variable.value);
            if (realValue !== variable.value) {
                //console.log("New Value");
                // if (varType === "condition") {
                //     updateVariableParams(varType, variable.label, realValue);
                // }
                // else if (varType === "perimeter") {
                //     updateVariableParams(varType, variable.label, realValue);
                // }
                // else {
                //     updateVariable(variable.label, realValue);
                // }
                updateVariable(varType, variable.label, realValue);
                // Get pretty value
                if (variable.type === "Node_UserInput_Bounds" || variable.type === "Node_UserInput_Lands" || variable.type === "Node_UserInput_PLUZone" || variable.type === "Node_UserInput_Levels" || variable.type === "Node_UserInput_Buildings") {
                    setPrettyCurrentValue(getPrettyCurrentValue(realValue));
                }
            }
            else {
                console.log("NO CHANGE : realValue === previousValue");
            }
        }
        else {
            setCurrentValue(variable.value);
        }
        setIsEditing(false);
    }

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement?.id.includes(`${ruleId}_var_${variable.label}_input_`)) {
                closeEdit();
            }
        }, "100")
    }

    return (
        <>
            {variable.type === "Node_UserInput_Number" &&
                <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }} style={{ width: (currentValue.toString().length * 7.4 + 20).toString() + "px" }}>
                    {variable.value}
                </span>
            }
            {variable.type === "Node_UserInput_Number_Range" &&
                <>
                    {isEditing ?
                        <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }} style={{ width: (currentValue.toString().length * 7.4 + 122).toString() + "px" }}>
                            {variable.value}
                        </span>
                        :
                        <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }} style={{ width: (currentValue.toString().length * 7.4 + 20).toString() + "px" }}>
                            {variable.value}
                        </span>
                    }
                </>
            }
            {variable.type === "Node_UserInput_Boolean" &&
                <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }}>
                    {variable.value === true ?
                        "Oui"
                        :
                        "Non"
                    }
                </span>
            }
            {variable.type === "Node_UserInput_Bounds" &&
                <>
                    {isEditing && (document.getElementById(`${ruleId}_var_${variable.label}`).offsetLeft <= (document.getElementById(`${ruleId}_var_${variable.label}`).parentElement.offsetWidth - 210)) ?
                        <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }} style={{ width: "210px" }}>
                            {/* Placeholder */}
                            {prettyCurrentValue}
                        </span>
                        :
                        <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }}>
                            {prettyCurrentValue}
                        </span>
                    }
                </>

            }
            {variable.type === "Node_UserInput_Lands" &&
                <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }}>
                    {prettyCurrentValue}
                </span>
            }
            {variable.type === "Node_UserInput_PLUZone" &&
                <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }}>
                    {prettyCurrentValue}
                </span>
            }
            {variable.type === "Node_UserInput_Levels" &&
                <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }}>
                    {prettyCurrentValue}
                </span>
            }
            {variable.type === "Node_UserInput_Buildings" &&
                <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openEdition() }}>
                    {prettyCurrentValue}
                </span>
            }
            {variable.type === "Node_UserInput_Draw_Point" &&
                <>
                    {isDrawing === true ?
                        <>
                            <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder app__ruleset_rule_variable_placeholder_drawing">
                                <img className="app__ruleset_rule_variable_placeholder_ico" src={ico_rule_edit} alt="" />en cours d'édition
                            </span>
                        </>
                        :
                        <>
                            <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openDraw("point") }}>
                                {prettyCurrentValue}
                            </span>
                        </>
                    }
                </>
            }
            {variable.type === "Node_UserInput_Draw_Line" &&
                <>
                    {isDrawing === true ?
                        <>
                            <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder app__ruleset_rule_variable_placeholder_drawing">
                                <img className="app__ruleset_rule_variable_placeholder_ico" src={ico_rule_edit} alt="" />en cours d'édition
                            </span>
                        </>
                        :
                        <>
                            <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openDraw("line") }}>
                                {prettyCurrentValue}
                            </span>
                        </>
                    }
                </>
            }
            {variable.type === "Node_UserInput_Draw_Polygon" &&
                <>
                    {isDrawing === true ?
                        <>
                            <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder app__ruleset_rule_variable_placeholder_drawing">
                                <img className="app__ruleset_rule_variable_placeholder_ico" src={ico_rule_edit} alt="" />en cours d'édition
                            </span>
                        </>
                        :
                        <>
                            <span id={`${ruleId}_var_${variable.label}`} className="app__ruleset_rule_variable_placeholder" onClick={() => { openDraw("polygon") }}>
                                {prettyCurrentValue}
                            </span>
                        </>
                    }
                </>
            }
            {isEditing &&
                <>
                    <div className="app__ruleset_rule_description_hide"></div>
                    <div className="app__ruleset_rule_variable_input_container" style={{ top: document.getElementById(`${ruleId}_var_${variable.label}`).offsetTop + 0 + "px", left: (variable.type === "Node_UserInput_Number_Range" || variable.type === "Node_UserInput_Bounds") && document.getElementById(`${ruleId}_var_${variable.label}`).offsetLeft > (document.getElementById(`${ruleId}_var_${variable.label}`).parentElement.offsetWidth - 210) ? "auto" : document.getElementById(`${ruleId}_var_${variable.label}`).offsetLeft + "px", right: (variable.type === "Node_UserInput_Number_Range" || variable.type === "Node_UserInput_Bounds") && document.getElementById(`${ruleId}_var_${variable.label}`).offsetLeft > (document.getElementById(`${ruleId}_var_${variable.label}`).parentElement.offsetWidth - 210) ? "0px" : "auto" }}>
                        {/* INPUT NUMBER */}
                        {variable.type === "Node_UserInput_Number" &&
                            <input id={`${ruleId}_var_${variable.label}_input_number`} className="app__ruleset_rule_variable_input_element_number" autoFocus type="number" onChange={(e) => { updateCurrentValue(e.target.value) }} onBlur={() => { closeEdit() }} onKeyUp={(e) => { if (e.key === "Enter") { closeEdit() } }} defaultValue={variable.value} style={{ border: "none", width: (currentValue.toString().length * 7.4 + 20).toString() + "px" }} />
                        }
                        {/* INPUT NUMBER RANGE */}
                        {variable.type === "Node_UserInput_Number_Range" &&
                            <div className="app__ruleset_rule_variable_input_container_box">
                                <input id={`${ruleId}_var_${variable.label}_input_range`} className="app__ruleset_rule_variable_input_element_range" type='range' min={variable.data[2]} max={variable.data[3]} step={1} value={currentValue} onChange={(e) => { updateCurrentValue(e.target.value) }} onBlur={() => { handleBlur() }} style={{ position: "relative", top: "-1.5px" }}></input>
                                <input id={`${ruleId}_var_${variable.label}_input_number`} className="app__ruleset_rule_variable_input_element_number" autoFocus type="number" onChange={(e) => { updateCurrentValue(e.target.value) }} onBlur={() => { handleBlur() }} onKeyUp={(e) => { if (e.key === "Enter") { closeEdit() } }} value={currentValue} style={{ border: "none", width: (currentValue.toString().length * 7.4 + 10).toString() + "px", minWidth: "0px" }} />
                            </div>
                        }
                        {/* INPUT BOOLEAN */}
                        {variable.type === "Node_UserInput_Boolean" &&
                            // <input id={`${ruleId}_var_${variable.label}_input`} className="app__ruleset_rule_variable_input_element_number" autoFocus type="number" onChange={(e) => { updateCurrentValue(e.target.value) }} onBlur={() => { closeEdit() }} defaultValue={variable.value} style={{ border: "none", width: (currentValue.toString().length * 7.4 + 12).toString() + "px" }} />
                            <select id={`${ruleId}_var_${variable.label}_input_boolean`} className="app__ruleset_rule_variable_input_element_dropdown" autoFocus onChange={(e) => { closeAndUpdateCurrentValue(e.target.value) }} onBlur={(e) => { closeAndUpdateCurrentValue(e.target.value) }} defaultValue={variable.value}>
                                <option value="true">Oui</option>
                                <option value="false">Non</option>
                            </select>
                        }
                        {/* INPUT LIMITS */}
                        {variable.type === "Node_UserInput_Bounds" &&
                            <div style={{ padding: "1px 10px 4px", width: "210px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Sélection des limites par</div>
                                <div className="ruleeditor__node_category_container">
                                    <div tabIndex={0} id={`${ruleId}_var_${variable.label}_input_category0`} className={selectionCategory === "type" ? "app__ruleset_rule_variable_input_category_selected" : "app__ruleset_rule_variable_input_category_unselected"} onClick={() => { handleCategoryChange("type") }} onBlur={() => { handleBlur() }}>types</div>
                                    <div tabIndex={0} id={`${ruleId}_var_${variable.label}_input_category1`} className={selectionCategory === "item" ? "app__ruleset_rule_variable_input_category_selected" : "app__ruleset_rule_variable_input_category_unselected"} onClick={() => { handleCategoryChange("item") }} onBlur={() => { handleBlur() }}>éléments</div>
                                </div>
                                {/* LIMITS BY ITEM */}
                                {selectionCategory === "item" &&
                                    <>
                                        <div style={{ marginTop: "8px" }}></div>
                                        {capacity.landBase.union.bounds_groups.map((boundGroup, index1) => {
                                            var isSelected = false;
                                            if (currentValue !== null && currentValue.includes("|" + boundGroup._id + "|")) {
                                                isSelected = true;
                                            }
                                            return (
                                                <div key={index1} className="ruleeditor__input_checkbox_container"><input autoFocus={index1 === 0 ? true : false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_${boundGroup._id}`} onChange={(e) => { handleCheckboxChange(boundGroup._id, e.target.checked) }} onBlur={() => { handleBlur() }} checked={isSelected} /><label id={`${ruleId}_var_${variable.label}_input_${boundGroup._id}_label`} tabIndex={0} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_${boundGroup._id}`} style={{ color: "#062134", fontSize: "11px" }}>Limite N°{index1 + 1}</label></div>
                                            )
                                        })}
                                    </>
                                }
                                {/* LIMITS BY TYPE */}
                                {selectionCategory === "type" &&
                                    <div>
                                        {Object.keys(capacity.landBase.union.bounds_types).map((boundType, index1) => {
                                            return (
                                                <>
                                                    <div key={index1} style={{ marginTop: "8px" }}></div>
                                                    <div key={index1 + "_title"} className="ruleeditor__input_title" style={{ color: "#062134" }}>{capacity.landBase.union.bounds_types[boundType].label}</div>
                                                    <div>
                                                        {Object.keys(capacity.landBase.union.bounds_types[boundType].subtypes).map((boundSubType, index2) => {
                                                            var isSelected = false;
                                                            if (currentValue !== null && currentValue.includes("|" + capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id + "|")) {
                                                                isSelected = true;
                                                            }
                                                            return (
                                                                <div key={index2} className="ruleeditor__input_checkbox_container"><input autoFocus={(index1 === 0 && index2 === 0) ? true : false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_${capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id}`} onChange={(e) => { handleCheckboxChange(capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id, e.target.checked) }} onBlur={() => { handleBlur() }} checked={isSelected} /><label id={`${ruleId}_var_${variable.label}_input_${capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id}_label`} tabIndex={0} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_${capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id}`} style={{ color: "#062134", fontSize: "11px" }}>{capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].label}</label></div>
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                        {/* INPUT LANDS */}
                        {variable.type === "Node_UserInput_Lands" &&
                            <div style={{ padding: "1px 10px 4px", width: "210px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Sélection des parcelles</div>
                                {capacity.landBase.lands.map((land, index1) => {
                                    var isSelected = false;
                                    if (currentValue !== null && currentValue.includes("|" + land.generated_id + "|")) {
                                        isSelected = true;
                                    }
                                    return (
                                        <div key={index1} className="ruleeditor__input_checkbox_container"><input autoFocus={index1 === 0 ? true : false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_${land.generated_id}`} onChange={(e) => { handleCheckboxChange(land.generated_id, e.target.checked) }} onBlur={() => { handleBlur() }} checked={isSelected} /><label id={`${ruleId}_var_${variable.label}_input_${land.generated_id}_label`} tabIndex={0} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_${land.generated_id}`} style={{ color: "#062134", fontSize: "11px" }}>Parcelle {land.properties.section}-{land.properties.numero}</label></div>
                                    )
                                })}
                            </div>
                        }
                        {/* INPUTS PLU ZONE */}
                        {variable.type === "Node_UserInput_PLUZone" &&
                            <div style={{ padding: "1px 10px 4px", width: "210px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Sélection des zones PLU</div>
                                {capacity.rules.gpu_data.zoneUrba.map((zone, index1) => {
                                    var isSelected = false;
                                    if (currentValue !== null && currentValue.includes("|" + zone.id + "|")) {
                                        isSelected = true;
                                    }
                                    return (
                                        <div key={index1} className="ruleeditor__input_checkbox_container"><input autoFocus={index1 === 0 ? true : false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_${zone.id}`} onChange={(e) => { handleCheckboxChange(zone.id, e.target.checked) }} onBlur={() => { handleBlur() }} checked={isSelected} /><label id={`${ruleId}_var_${variable.label}_input_${zone.id}_label`} tabIndex={0} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_${zone.id}`} style={{ color: "#062134", fontSize: "11px" }}>Zone {zone.properties.libelle}</label></div>
                                    )
                                })}
                            </div>
                        }
                        {/* INPUTS LEVELS */}
                        {variable.type === "Node_UserInput_Levels" &&
                            <div style={{ padding: "1px 10px 4px", width: "210px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Sélection des niveaux</div>
                                {levels_list.map((level, index1) => {
                                    var isSelected = false;
                                    if (currentValue !== null && currentValue.includes("|" + level.id + "|")) {
                                        isSelected = true;
                                    }
                                    return (
                                        <div key={index1} className="ruleeditor__input_checkbox_container"><input autoFocus={index1 === 0 ? true : false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_${level.id}`} onChange={(e) => { handleCheckboxChange(level.id, e.target.checked) }} onBlur={() => { handleBlur() }} checked={isSelected} /><label id={`${ruleId}_var_${variable.label}_input_${level.id}_label`} tabIndex={0} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_${level.id}`} style={{ color: "#062134", fontSize: "11px" }}>{level.label}</label></div>
                                    )
                                })}
                            </div>
                        }
                        {/* INPUTS BUILDINGS */}
                        {variable.type === "Node_UserInput_Buildings" &&
                            <div style={{ padding: "1px 10px 4px", width: "210px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Sélection des constructions par</div>
                                <div className="ruleeditor__node_category_container">
                                    <div tabIndex={0} id={`${ruleId}_var_${variable.label}_input_category0`} className={selectionCategory === "type" ? "app__ruleset_rule_variable_input_category_selected" : "app__ruleset_rule_variable_input_category_unselected"} onClick={() => { handleCategoryChange("type") }} onBlur={() => { handleBlur() }}>types</div>
                                    <div tabIndex={0} id={`${ruleId}_var_${variable.label}_input_category1`} className={selectionCategory === "item" ? "app__ruleset_rule_variable_input_category_selected" : "app__ruleset_rule_variable_input_category_unselected"} onClick={() => { handleCategoryChange("item") }} onBlur={() => { handleBlur() }}>éléments</div>
                                </div>
                                {/* BUILDINGS BY ITEM */}
                                {selectionCategory === "item" &&
                                    <>
                                        <div style={{ marginTop: "8px" }}></div>
                                        {capacity.landBase.buildings.buildings_land.map((building_index, index1) => {
                                            var isSelected = false;
                                            if (currentValue !== null && currentValue.includes("|" + building_index + "|")) {
                                                isSelected = true;
                                            }
                                            return (
                                                <div key={index1} className="ruleeditor__input_checkbox_container"><input autoFocus={index1 === 0 ? true : false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_${building_index}`} onChange={(e) => { handleCheckboxChange(building_index, e.target.checked) }} onBlur={() => { handleBlur() }} checked={isSelected} /><label id={`${ruleId}_var_${variable.label}_input_${building_index}_label`} tabIndex={0} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_${building_index}`} style={{ color: "#062134", fontSize: "11px" }}>Construction N°{index1 + 1}</label></div>
                                            )
                                        })}
                                    </>
                                }
                                {/* BUILDINGS BY TYPE */}
                                {selectionCategory === "type" &&
                                    <div>
                                        <div className="ruleeditor__input_checkbox_container"><input autoFocus={true} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_existing_land`} onChange={(e) => { handleCheckboxChange("buildings_land", e.target.checked) }} onBlur={() => { handleBlur() }} checked={(currentValue !== null && currentValue.includes("|buildings_land|")) ? true : false} /><label id={`${ruleId}_var_${variable.label}_input_existing_land_label`} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_existing_land`} style={{ color: "#062134", fontSize: "11px" }}>Constructions existantes sur le terrain (non démolies)</label></div>
                                        <div className="ruleeditor__input_checkbox_container"><input autoFocus={false} className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={`${ruleId}_var_${variable.label}_input_existing_close`} onChange={(e) => { handleCheckboxChange("buildings_close", e.target.checked) }} onBlur={() => { handleBlur() }} checked={(currentValue !== null && currentValue.includes("|buildings_close|")) ? true : false} /><label id={`${ruleId}_var_${variable.label}_input_existing_close_label`} className="ruleeditor__input_checkbox_label" htmlFor={`${ruleId}_var_${variable.label}_input_existing_close`} style={{ color: "#062134", fontSize: "11px" }}>Constructions existantes à proximité du terrain</label></div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default RuleVariable;