// Import dependencies
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { capaGetById, capaUpdate } from '../../../actions/capa';
import { rulesGetByTeamId } from '../../../actions/teams';
import * as turf from '@turf/turf';

// Import components
import Header from '../../../components/app/header/Header';
import Navbar from '../../../components/app/navbar/Navbar';
import Map from '../../../components/app/map/Map';
import ErrorPage from '../../../components/common/errorPage/ErrorPage';
import ToasterContainer from '../../../components/common/toaster/ToasterContainer';
import { TitleAndDescription, Location, Modifications } from '../../../components/app/capaStudy_elements';
import { Geocoder } from '../../../components/app/capaStudy_elements';
import { LandsDetail, LandsValidation, BoundsDetail, Topo, Buildings } from '../../../components/app/capaStudy_elements';
import { Risks } from '../../../components/app/capaStudy_elements';
import { Context } from '../../../components/app/capaStudy_elements';
import { List } from '../../../components/app/capaStudy_elements';
import { BuildableDetails } from '../../../components/app/capaStudy_elements';
import { Combinations } from '../../../components/app/capaStudy_elements';
import RuleCatalog from '../../../components/app/ruleCatalog_elements/RuleCatalog';

// Import CSS & Assets
import './CapaStudy.css';
import logo_card from '../../../assets/logo.svg';
import ico_home_outline from '../../../assets/ico/ico_home_outline.svg';
import ico_home_fill from '../../../assets/ico/ico_home_fill.svg';
import ico_land_outline from '../../../assets/ico/ico_land_outline.svg';
import ico_land_fill from '../../../assets/ico/ico_land_fill.svg';
import ico_risk_outline from '../../../assets/ico/ico_risk_outline.svg';
import ico_risk_fill from '../../../assets/ico/ico_risk_fill.svg';
import ico_stats_outline from '../../../assets/ico/ico_stats_outline.svg';
import ico_stats_fill from '../../../assets/ico/ico_stats_fill.svg';
import ico_rules_outline from '../../../assets/ico/ico_rules_outline.svg';
import ico_rules_fill from '../../../assets/ico/ico_rules_fill.svg';
import ico_capa_outline from '../../../assets/ico/ico_capa_outline.svg';
import ico_capa_fill from '../../../assets/ico/ico_capa_fill.svg';
import ico_combi_outline from '../../../assets/ico/ico_combi_outline.svg';
import ico_combi_fill from '../../../assets/ico/ico_combi_fill.svg';
import ico_reload from '../../../assets/ico/ico_reload.svg';

// Import external functions
import * as map_helpers from '../../../components/app/map/Map_helpers';
import * as fetch_helpers from '../../../helpers/Fetch_helpers';
import * as IFC_helpers from '../../../helpers/IFC_helpers';
import * as buildable_helpers from '../../../helpers/Buildable_helpers';
import * as combination_helpers from '../../../helpers/Combination_helpers';
import * as other_helpers from '../../../helpers/Other_helpers';
import * as node_helpers from '../../../components/app/ruleEditor_elements/helpers/NodeHelpers';
import * as global from '../../../global.js';
const environment = global.get_environment();

// Async save
var save_counter = -1;

const CapaStudy = ({ version }) => {

    // ANALYSE JSON SIZE
    const optimizeCapa = (capa) => {

        var capa_optim = other_helpers.compressCapa(capa);
        getCapaSize(capa_optim);

    }

    const getCapaSize = (capa) => {

        // TEST SIZE

        // const size_full = JSON.stringify(capa).length;
        // console.log("FULL size :", size_full);

        // var size_details = [];
        // var size_landbase = 1;
        // var size_context = 1;
        // var size_rules = 1;

        // for (var key of Object.keys(capa)) {
        //     const size_1 = JSON.stringify(capa[key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_full) });
        //     if (key === "landBase") { size_landbase = size_1 }
        //     if (key === "context") { size_context = size_1 }
        //     if (key === "rules") { size_rules = size_1 }
        // }

        // console.table(size_details);


        // LANDBASE
        // var size_details_landbase = [];

        // for (var key of Object.keys(capa.landBase)) {
        //     const size_1 = JSON.stringify(capa["landBase"][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_landbase.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        // }

        // console.log("LANDBASE details");
        // console.table(size_details_landbase);

        // LANDBASE > BUILDINGS
        // var size_details_landbase_buildings = [];
        // if (capa?.landBase?.buildings?.buildings) {
        //     for (var key of Object.keys(capa.landBase.buildings.buildings[0])) {
        //         const size_1 = JSON.stringify(capa["landBase"]["buildings"]["buildings"][0][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_landbase_buildings.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        //     }
        // }

        // console.log("LANDBASE BUILDINGS details");
        // console.table(size_details_landbase_buildings);

        // LANDBASE > UNION
        // var size_details_landbase_union = [];

        // for (var key of Object.keys(capa.landBase.union)) {
        //     const size_1 = JSON.stringify(capa["landBase"]["union"][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_landbase_union.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        // }

        // console.log("LANDBASE UNION details");
        // console.table(size_details_landbase_union);

        // LANDBASE > UNION > BOUNDS GROUP
        // var size_details_landbase_union_bounds = [];

        // for (var key of Object.keys(capa.landBase.union.bounds_groups[0])) {
        //     const size_1 = JSON.stringify(capa["landBase"]["union"]["bounds_groups"][0][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_landbase_union_bounds.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_landbase) });
        // }

        // console.log("LANDBASE UNION BOUNDS details");
        // console.table(size_details_landbase_union_bounds);


        // CONTEXT
        // var size_details_context = [];

        // if (capa?.context) {
        //     for (var key of Object.keys(capa.context)) {
        //         const size_1 = JSON.stringify(capa["context"][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_context.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_context) });
        //     }

        //     console.log("CONTEXT details");
        //     console.table(size_details_context);
        // }


        // // RULES
        // var size_details_rules = [];

        // if (capa?.rules) {
        //     for (var key of Object.keys(capa.rules)) {
        //         const size_1 = JSON.stringify(capa["rules"][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_rules.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_rules) });
        //     }

        //     console.log("RULES details");
        //     console.table(size_details_rules);
        // }

        // // RULES > GPU DATA
        // var size_details_rules_gpu = [];

        // if (capa?.rules?.gpu_data) {
        //     for (var key of Object.keys(capa.rules.gpu_data)) {
        //         const size_1 = JSON.stringify(capa["rules"]["gpu_data"][key]).length;
        //         // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //         size_details_rules_gpu.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_rules) });
        //     }

        //     console.log("RULES GPU details");
        //     console.table(size_details_rules_gpu);
        // }


        // CONTEXT > DVF
        // var size_details_context_dvf = [];

        // for (var key of Object.keys(capa.context.dvf)) {
        //     const size_1 = JSON.stringify(capa["context"]["dvf"][key]).length;
        //     // console.log(key + " size : " + size_1 + " (" + Math.round(100 * size_1 / size_full) + "%)");
        //     size_details_context_dvf.push({ 'Key': key, 'Size': size_1, 'Percentage': Math.round(100 * size_1 / size_context) });
        // }

        // console.log("CONTEXT DVF details");
        // console.table(size_details_context_dvf);
    }

    // TEST LIST
    const [categories, setCategories] = useState([
        {
            id: "q101",
            name: "Réglement littéral de test",
            items: []
        },
    ]);

    const [isRuleCatalogOpen, setIsRuleCatalogOpen] = useState(false);
    // useEffect(() => {
    //     console.log("-UPDATE RULE CATALOG-", isRuleCatalogOpen);
    // }, [isRuleCatalogOpen]);


    // ERROR MANAGEMENT
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const createError = () => {
        console.log("CREATE ERROR");
        setIsError(true);
    }
    const setErrorPage = (errorMessage) => {
        setErrorMsg(errorMessage);
        setIsError(true);
    }


    // TOASTER MANAGEMENT
    const [toastersData, setToastersData] = useState({
        land: { title: "Données terrain", text: "Analyse des données du terrain : limites, topographie et constructions exitantes.", status: "done" },
        risks: { title: "Risques", text: "Analyse des risques rattachés au terrain : risques naturels et industriels, sismicité, sols argileux, canalisations et pollution du sol.", status: "done" },
        context: { title: "Données contextuelles", text: "Analyse des données contextuelles du terrain : statistiques de population, marché immobilier et présence de monuments historiques.", status: "done" },
        gpu: { title: "Données PLU", text: "Récupération des données d'urbanisme issues du Géoportail de l'Urbanisme.", status: "done" },
        buildable: { title: "Capacité constructible", text: "Calcul de la capacité constructible du terrain.", status: "done" },
        combinations: { title: "Simulations de construction", text: "Génération des simulations de construction sur le terrain.", status: "done" },
        save: { title: "Enregistrement", text: "Enregistrement des dernières modifications apportées à l'étude.", status: "done" },
        // items: []
    });
    // const addToaster = (toasterId) => {
    //     console.log("ADD TOASTER", toasterId);
    //     var oldData = { ...toastersData };
    //     oldData[toasterId].status = "loading";
    //     setToastersData(oldData);


    //     // var oldItems = [...toastersData.items];
    //     // console.log("ADD TOASTER old", oldItems);
    //     // var newItems = oldItems.concat(newToasterData);
    //     // console.log("ADD TOASTER new", newItems);
    //     // setToastersData({ ...toastersData, items: newItems });
    // }
    // const finishToaster = (toasterId) => {
    //     console.log("FINISH TOASTER", toasterId);
    //     var oldData = { ...toastersData };
    //     oldData[toasterId].status = "success";
    //     setToastersData(oldData);

    //     setTimeout(() => {
    //         removeToaster(toasterId);
    //     }, 3000);


    //     // var oldItems = [...toastersData.items];
    //     // console.log("REMOVE TOASTER old", oldItems);
    //     // for (var i = 0; i < oldItems.length; i++) {
    //     //     if (oldItems[i]?.id === toasterId) {

    //     //     }
    //     // }
    //     // var newItems = oldItems;
    //     // newItems.shift();
    //     // console.log("REMOVE TOASTER new", newItems);
    //     // setToastersData({ ...toastersData, items: newItems });
    // }
    // const removeToaster = (toasterId) => {
    //     var oldData = { ...toastersData };
    //     oldData[toasterId].status = "done";
    //     setToastersData(oldData);
    // }
    const handleToaster = (actionList) => {
        // console.log("HANDLE TOASTER", actionList);
        var oldData = { ...toastersData };
        actionList.forEach(action => {
            oldData[action.key].status = action.status;
            if (action.status === "success" || action.status === "error") {
                setTimeout(() => {
                    handleToaster([{ key: action.key, status: "done" }]);
                }, 3000);
            }
        })
        setToastersData(oldData);
    }
    useEffect(() => {
        // console.log("toastersData", toastersData);
    }, [toastersData]);



    // MAP UPDATE MANAGEMENT
    const [globalUpdateTracker, setGlobalUpdateTracker] = useState({ value: 0, item: "" });

    const [mapDrawMode, setMapDrawMode] = useState({ mode: null, data: null, target: null });
    useEffect(() => {
        // console.log("mapDrawMode", mapDrawMode);
    }, [mapDrawMode]);


    // Set body color
    // document.body.style.background = "#f5f5f5";

    // Navigate & Dispatch
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // __________________________________ GLOBAL STUDY VARIABLES
    const [targetSection, setTargetSection] = useState(null);
    const [capacity, setCapacity] = useState();
    const [isAccessDenied, setIsAccessDenied] = useState(false);
    // Get Capa ID from URL
    const { id } = useParams();
    // Fetch Capa data
    // useEffect(() => {
    //     console.log("-FETCHING CAPA- : Loading...");
    //     dispatch(capaGetById(id, handleCapaGetSuccess, handleCapaGetFail));
    // }, []);
    const getCapacity = () => {
        console.log("-FETCHING CAPA- : Loading...");
        var user = JSON.parse(localStorage.getItem('profile'));
        // Check if user is connected and is inside a team
        if (user?.teams && user?.teams.length > 0) {
            dispatch(capaGetById(id, user?.teams[0]?._id, handleCapaGetSuccess, handleCapaGetFail));
        }
    }
    // Get response from Capa fetching : success
    const handleCapaGetSuccess = (data) => {
        console.log("-FETCHING CAPA- : Success", data);
        data.toSave = { autoSave: false, action: "", value: "" };
        setCapacity(data);
        // Fetch rules
        getRules();
        // Interpret ThreeJS Objects
        if (data?.landBase?.union) {
            getInterpretedObjects(data, false);
        }

        // SIZE
        getCapaSize(data);
    };
    // Get response from Capa fetching : error
    const handleCapaGetFail = (error) => {
        console.log("-FETCHING CAPA- : Error", error?.response?.data?.message);
        // If id doesn't exists => back to dashboard
        if (error?.response?.status === 404) {
            // console.log("Redirecting to Dashboard");
            navigate('/dashboard');
        }
        if (error?.response?.status === 403) {
            // console.log("Access forbiden");
            // navigate('/dashboard');
            setIsAccessDenied(true);
        }
        // If session expired => back to signin
        if (error?.response?.data?.message === "La session a expiré.") {
            // console.log("Redirecting to SignIn");
            setTimeout(() => {
                navigate('/auth?type=signin&cause=expired&source=' + window.location.pathname);
            }, 500);
        }
    }
    // Get user
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));


    //__________ UI - GLOBAL PAGE STRUCTURE

    // UI : Variables
    const hSeparator = 30;
    // var hHeader = 80;
    var hHeader = document.getElementById('header')?.offsetHeight || 80;
    const hMobileFooter = 80;
    const hLimit = window.innerHeight / 6;

    // UI : Navbar
    const layout_data = {
        layout: [
            {
                title_long: "Informations générales",
                title_short: "Infos Générales",
                ico_outline: ico_home_outline,
                ico_fill: ico_home_fill,
                isVisible: false
            },
            {
                title_long: "Terrain",
                title_short: "Terrain",
                ico_outline: ico_land_outline,
                ico_fill: ico_land_fill,
                isVisible: false
            },
            {
                title_long: "Risques",
                title_short: "Risques",
                ico_outline: ico_risk_outline,
                ico_fill: ico_risk_fill,
                isVisible: false
            },
            {
                title_long: "Données contextuelles",
                title_short: "Contexte",
                ico_outline: ico_stats_outline,
                ico_fill: ico_stats_fill,
                isVisible: false
            },
            {
                title_long: "Règles d'urbanisme",
                title_short: "Règles",
                ico_outline: ico_rules_outline,
                ico_fill: ico_rules_fill,
                isVisible: false
            },
            {
                title_long: "Capacité constructible",
                title_short: "Capacité",
                ico_outline: ico_capa_outline,
                ico_fill: ico_capa_fill,
                isVisible: false
            },
            {
                title_long: "Simulations de construction",
                title_short: "Simulation",
                ico_outline: ico_combi_outline,
                ico_fill: ico_combi_fill,
                isVisible: false
            },
        ]
    };
    const [navigation, setNavigation] = useState(layout_data);

    // UI : Section
    const [currentSection, setCurrentSection] = useState(0);
    const updateCurrentSection = (value) => {
        setCurrentSection(value);
    }
    const getSectionMinHeight = () => {
        var header_height = document.getElementById('header')?.offsetHeight || 80;
        var margin_bottom = 20;
        var margin_top = 10;
        var padding = 15 * 2;
        var other = 55;
        return (window.innerHeight - header_height - margin_bottom - margin_top - padding - other);
    }
    const [sectionMinHeight, setSectionMinHeight] = useState(getSectionMinHeight());


    // UI : Drag comportment
    const getSectionSeparatorStyle = () => {
        if (window.innerWidth > 1050) {
            return { top: hHeader - hSeparator, heightMessageTop: 0, heightMessageBottom: 0 };
        }
        else {
            return { top: window.innerHeight / 2, heightMessageTop: 0, heightMessageBottom: 0 };
        }
    }
    const [sectionSeparatorStyle, setSectionSeparatorStyle] = useSpring(getSectionSeparatorStyle);
    const getSectionDataStyle = () => {
        if (window.innerWidth > 1050) {
            return { top: hHeader, height: window.innerHeight - hHeader, opacity: 1 };
        }
        else if (window.innerWidth < 550) {
            return { top: (window.innerHeight / 2) + hSeparator, height: (window.innerHeight / 2) - hSeparator - hMobileFooter, opacity: 1 };
        }
        else {
            return { top: (window.innerHeight / 2) + hSeparator, height: (window.innerHeight / 2) - hSeparator, opacity: 1 };
        }
    }
    const [sectionDataStyle, setSectionDataStyle] = useSpring(getSectionDataStyle);
    const getSectionMapStyle = () => {
        if (window.innerWidth > 1050) {
            return { top: hHeader, height: window.innerHeight - hHeader, opacity: 1 };
        }
        else {
            return { top: hHeader, height: (window.innerHeight / 2) - hHeader, opacity: 1 };
        }
    }
    const [sectionMapStyle, setSectionMapStyle] = useSpring(getSectionMapStyle);
    const dragSectionSeparator = useDrag((params) => {
        handleDrag(params);
    });
    const handleDrag = (params) => {
        if (window.innerWidth <= 1050) {
            const y = params.xy[1] - 15;
            // Update hHeader
            hHeader = document.getElementById("header").offsetHeight;

            if (params.dragging) {
                if (y <= 80) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(hHeader);
                    // Update sectionData
                    sectionDataStyle.top.set(hHeader + hSeparator);
                    if (window.innerWidth < 550) {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                    }
                    else {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator);
                    }
                    // Update sectionMap
                    sectionMapStyle.height.set(0);
                }
                else if (window.innerWidth >= 550 && y >= window.innerHeight - hSeparator) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(window.innerHeight - hSeparator);
                    // Update sectionData
                    sectionDataStyle.top.set(window.innerHeight);
                    sectionDataStyle.height.set(0);
                    // Update sectionMap
                    sectionMapStyle.height.set(window.innerHeight - hHeader - hSeparator);
                }
                else if (window.innerWidth < 550 && y >= window.innerHeight - hSeparator - hMobileFooter) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(window.innerHeight - hSeparator - hMobileFooter);
                    // Update sectionData
                    sectionDataStyle.top.set(window.innerHeight - hMobileFooter);
                    sectionDataStyle.height.set(0);
                    // Update sectionMap
                    sectionMapStyle.height.set(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                }
                else {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.set(y);
                    sectionSeparatorStyle.heightMessageTop.start(0);
                    sectionSeparatorStyle.heightMessageBottom.start(0);
                    // Update sectionData
                    sectionDataStyle.top.set(y + hSeparator);
                    if (window.innerWidth < 550) {
                        sectionDataStyle.height.set(window.innerHeight - y - hSeparator - hMobileFooter);
                    }
                    else {
                        sectionDataStyle.height.set(window.innerHeight - y - hSeparator);
                    }
                    if (window.innerWidth < 550 && y >= window.innerHeight - hSeparator - hLimit - hMobileFooter) {
                        sectionDataStyle.opacity.set(0);
                    }
                    else if (window.innerWidth >= 550 && y >= window.innerHeight - hSeparator - hLimit) {
                        sectionDataStyle.opacity.set(0);
                    }
                    else {
                        sectionDataStyle.opacity.set(1);
                    }
                    // Update sectionMap
                    sectionMapStyle.height.set(y - hHeader);
                    if (y <= hHeader + hLimit) {
                        sectionMapStyle.opacity.set(0);
                    }
                    else {
                        sectionMapStyle.opacity.set(1);
                    }
                    // Resize map
                    setMapResize(y);
                }
            }
            else {
                // On stop dragging check if it is in top limit part
                if (y <= hHeader + hLimit) {
                    // Update sectionSeparator
                    sectionSeparatorStyle.top.start(hHeader);
                    sectionSeparatorStyle.heightMessageTop.start(12);
                    // Update sectionData
                    sectionDataStyle.top.start(hHeader + hSeparator);
                    if (window.innerWidth < 550) {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                    }
                    else {
                        sectionDataStyle.height.set(window.innerHeight - hHeader - hSeparator);
                    }
                }
                // On stop dragging check if it is in bottom limit part (for mobile)
                if (window.innerWidth < 550) {
                    if (y >= window.innerHeight - hSeparator - hMobileFooter - hLimit) {
                        // Update sectionSeparator
                        sectionSeparatorStyle.top.start(window.innerHeight - hSeparator - hMobileFooter);
                        sectionSeparatorStyle.heightMessageBottom.start(12);
                        // Update sectionMap
                        sectionMapStyle.height.start(window.innerHeight - hHeader - hSeparator - hMobileFooter);
                        // Resize map
                        var interval = 0;
                        var mapResizer = setInterval(() => {
                            interval += 10;
                            setMapResize(interval);
                        }, 10);
                        setTimeout(
                            () => { clearInterval(mapResizer); },
                            500);
                    }
                }
                // On stop dragging check if it is in bottom limit part (for tablet & desktop)
                else {
                    if (y >= window.innerHeight - hSeparator - hLimit) {
                        // Update sectionSeparator
                        sectionSeparatorStyle.top.start(window.innerHeight - hSeparator);
                        sectionSeparatorStyle.heightMessageBottom.start(12);
                        // Update sectionMap
                        sectionMapStyle.height.start(window.innerHeight - hHeader - hSeparator);
                        // Resize map
                        var interval = 0;
                        var mapResizer = setInterval(() => {
                            interval += 10;
                            setMapResize(interval);
                        },
                            10);
                        setTimeout(
                            () => { clearInterval(mapResizer); },
                            500);
                    }
                }
            }
        }
    }

    // UI : Resize window
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, { passive: true });
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        // console.log("RESIZE");
        // Update hHeader
        hHeader = document.getElementById("header").offsetHeight;
        // Resize elements
        setSectionMinHeight(getSectionMinHeight());
        if (window.innerWidth > 1050) {
            // Reset styles to get it back to normal large UI
            // Update sectionSeparator
            sectionSeparatorStyle.top.set(hHeader - hSeparator);
            sectionSeparatorStyle.heightMessageTop.set(0);
            sectionSeparatorStyle.heightMessageBottom.set(0);
            // Update sectionData
            sectionDataStyle.top.set(hHeader);
            sectionDataStyle.height.set(window.innerHeight - hHeader);
            sectionDataStyle.opacity.set(1);
            // Update sectionMap
            sectionMapStyle.top.set(hHeader);
            sectionMapStyle.height.set(window.innerHeight - hHeader);
            sectionMapStyle.opacity.set(1);
        }
        else {
            handleDrag({ xy: [0, document.getElementById('section_separator').offsetTop + 15], dragging: true });
            if (document.getElementById('section_separator').offsetTop === hHeader - hSeparator || document.getElementById('section_separator').offsetTop === Math.round(window.innerHeight / 2)) {
                // Reset styles to get it back to normal small UI
                // Update sectionSeparator
                sectionSeparatorStyle.top.set(window.innerHeight / 2);
                sectionSeparatorStyle.heightMessageTop.set(0);
                sectionSeparatorStyle.heightMessageBottom.set(0);
                // Update sectionData
                sectionDataStyle.top.set((window.innerHeight / 2) + hSeparator);
                sectionDataStyle.height.set((window.innerHeight / 2 - hSeparator));
                sectionDataStyle.opacity.set(1);
                // Update sectionMap
                sectionMapStyle.top.set(hHeader);
                sectionMapStyle.height.set((window.innerHeight / 2) - hHeader);
                sectionMapStyle.opacity.set(1);
            }
        }
        setMapResize(window.innerWidth * window.innerHeight);
    }

    // UI : Use handleDrag on load if mobile
    useEffect(() => {
        if (window.innerWidth < 550) {
            // console.log("MOBILE RESIZE");
            handleDrag({ xy: [0, document.getElementById('section_separator').offsetTop + 15], dragging: true });
        }
    }, []);

    //_________ MAP

    // MAP : Ready
    const [isMapReady, setIsMapReady] = useState(false);
    useEffect(() => {
        // console.log("MAP READY", isMapReady);
        if (isMapReady) {
            getCapacity();
        }
    }, [isMapReady]);

    // MAP : Resize
    const [mapResize, setMapResize] = useState(0);
    const [isMapExpanded, setIsMapExpanded] = useState(false);

    // MAP : Layers
    const [mapLayers, setMapLayers] = useState(
        {
            satellite: {
                isVisible: false,
                label: "Vue satellite"
            },
            OSM_Buildings: {
                isVisible: true,
                label: "Bâtiments (Open Street Map)"
            },
            cityLands: {
                isVisible: false,
                label: "Parcelles"
            },
            IGN_Buildings: {
                isVisible: false,
                label: "Bâtiments (Cadastre)"
            },
            IGN_BuildingsLand: {
                isVisible: false,
                label: "Bâtiments (Cadastre)"
            },
            IGN_BuildingsNoland: {
                isVisible: false,
                label: "Bâtiments (Cadastre)"
            },
            union: {
                isVisible: true,
                label: "Terrain d'assiette"
            },
            boundsType: {
                isVisible: false,
                label: "Détail des limites"
            },
            topo_3D: {
                isVisible: false,
                label: "Topographie 3D"
            },
            topo_profile: {
                isVisible: false,
                label: "Profil altimétrique"
            },
            risks_earthquake: {
                isVisible: false,
                label: "Zones de sismicité"
            },
            risks_clay: {
                isVisible: false,
                label: "Zone d'aléa de retrait-gonflement des sols argileux"
            },
            risks_pipes: {
                isVisible: false,
                label: "Canalisations de matières dangereuses"
            },
            risks_basias: {
                isVisible: false,
                label: "Sites pollués"
            },
            context_merimee: {
                isVisible: false,
                label: "Monuments historiques"
            },
            context_sitadel: {
                isVisible: false,
                label: "Demandes d'autorisation d'urbanisme"
            },
            context_isodistance: {
                isVisible: false,
                label: "Surfaces couvertes en 5 minutes en voiture et 10 minutes à pied"
            },
            context_dvf: {
                isVisible: false,
                label: "Transactions immobilières"
            },
            rules: {
                isVisible: false,
                label: "Règles d'urbanisme"
            },
            zones_urba: {
                isVisible: false,
                label: "Zones PLU"
            },
            buildable_volume: {
                isVisible: false,
                label: "Volume constructible"
            },
        }
    );

    // LAYER : Refresh
    const layer_Refresh = (layer) => {
        if (mapLayers[layer]["isVisible"] === true) {
            // console.log("REFRESH LAYER", layer);
            // Get state
            var mapLayersState = mapLayers;
            // Update state
            mapLayersState[layer]["isVisible"] = false;
            // Update original
            setMapLayers({ ...mapLayersState });

            setTimeout(() => {
                // Update state
                mapLayersState[layer]["isVisible"] = true;
                // Update original
                setMapLayers({ ...mapLayersState });
            }, 100);
        }
    }

    //__________ CAPACITY

    // CAPACITY : Step
    const [currentStep, setCurrentStep] = useState(-1);
    useEffect(() => {
        // console.log("!!! OFFICIAL STEP CHANGE", currentStep);
        if (currentStep === 0) {
            // Set other sections accessible
            var nav = navigation;
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            setNavigation({ ...nav });
        }
        else if (currentStep >= 1) {
            // Set other sections accessible
            var nav = navigation;
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            nav.layout[2].isVisible = true;
            nav.layout[3].isVisible = true;
            nav.layout[4].isVisible = true;
            nav.layout[5].isVisible = true;
            nav.layout[6].isVisible = false;
            if (capacity?.buildable?.volume?.interpreted && capacity?.buildable?.volume?.interpreted.length > 0 && capacity?.buildable?.volume?.interpreted[0]?.userData) {
                nav.layout[6].isVisible = true;
            }
            setNavigation({ ...nav });
        }
    }, [currentStep]);

    // CAPACITY : Track updates
    useEffect(() => {
        if (capacity === undefined) { return }

        console.log("-UPDATING CAPA- : Updated", capacity);
        // console.log("-UPDATING CAPA- : Updated");
        // Check if autosave
        if (capacity?.toSave?.autoSave === true) {
            saveCapacity();
        }
        else {
            // CHANGING STEP ELEMENTS

            // CHANGING TO STEP 1
            if (capacity?.step === 1) {
                // Hide STEP 0 elements : landsPolys, landsLabels, landDashLines
                // Set STEP 1 elements : landLines, flytoLand

                // Get location
                // if (!capacity?.landBase?.location) {
                //     var landBase = capacity.landBase;
                //     landBase.location = "fetching";
                //     setCapacity({ ...capacity, landBase: landBase, toSave: { autoSave: false, action: "", value: "" } });
                // }

                //____ TOPO
                // Fetch
                // if (capacity?.landBase?.topo === "fetching" || capacity?.landBase?.topo === "error") {
                if (capacity?.landBase?.topo === "fetching") {
                    fetchTopo();
                }
                // Set fetching state
                if ((capacity?.landBase?.location && capacity.landBase.location !== "fetching") && !capacity?.landBase?.topo) {
                    console.log("!!!!!!!!!! SET TOPO FETCHING");
                    // addToaster("land");
                    handleToaster([{ key: "land", status: "loading" }]);
                    var landBase = capacity.landBase;
                    landBase.topo = "fetching";
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("landBase");
                    toSaveData.autoSave = false;
                    setCapacity({ ...capacity, landBase: landBase, toSave: toSaveData });
                }


                //____ RISKS
                // Fetch
                if (capacity?.risks === "fetching") {
                    fetchRisks();
                }
                // Set fetching state
                if (capacity?.landBase?.topo && capacity.landBase.topo !== "fetching" && (!capacity?.risks || capacity.risks === "")) {
                    console.log("!!!!!!!!!! SET RISK FETCHING");
                    // addToaster("risks");
                    // finishToaster("land");
                    handleToaster([{ key: "risks", status: "loading" }, { key: "land", status: "success" }]);
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("risks");
                    toSaveData.autoSave = false;
                    setCapacity({ ...capacity, risks: "fetching", toSave: toSaveData });
                }


                //____ CONTEXT
                // Set fetching state
                if (capacity?.risks && capacity.risks !== "fetching" && (!capacity?.context || capacity.context === "")) {
                    console.log("!!!!!!!!!! SET CONTEXT FETCHING");
                    // addToaster("context");
                    // finishToaster("risks");
                    handleToaster([{ key: "context", status: "loading" }, { key: "risks", status: "success" }]);
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("context");
                    toSaveData.keys.push("landBase");
                    toSaveData.keys.push("city");
                    toSaveData.autoSave = true;
                    setCapacity({ ...capacity, context: "fetching", toSave: toSaveData });
                }

                //____ GPU
                // Fetch
                if (capacity?.rules?.gpu_data === "fetching") {
                    fetchGPU();
                }
                // Set fetching state
                if (capacity?.context && capacity.context !== "fetching" && (!capacity?.rules?.gpu_data || capacity.rules?.gpu_data === "")) {
                    console.log("!!!!!!!!!! SET GPU FETCHING");
                    // addToaster("gpu");
                    // finishToaster("context");
                    handleToaster([{ key: "gpu", status: "loading" }, { key: "context", status: "success" }]);
                    var rules = capacity.rules;
                    rules.gpu_data = "fetching";
                    var toSaveData = { ...capacity.toSave };
                    if (!toSaveData?.keys) { toSaveData.keys = [] }
                    toSaveData.keys.push("rules");
                    toSaveData.autoSave = false;
                    setCapacity({ ...capacity, rules: rules, toSave: toSaveData });
                }


                //____ BUILDABLE
                // Fetch
                if (capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels === "fetching") {
                    // if (capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels.length === 0) {
                    console.log("!!!!!!!!!! SET BUILDABLE FETCHING");
                    getBuildableData();
                }

                //____ COMBINATIONS
                // Fetch
                if (capacity?.buildable?.combinations && capacity.buildable.combinations === "fetching") {
                    // addToaster("combinations");
                    handleToaster([{ key: "combinations", status: "loading" }]);
                    // if (capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels.length === 0) {
                    console.log("!!!!!!!!!! SET COMBINATION FETCHING");
                    getCombinationData();
                }

            }
        }

        // Update currentStep
        if (capacity?.step >= 0) {
            setCurrentStep(capacity.step);
        }

        // Show/Hide combination section
        if (capacity?.step > 0 && capacity?.buildable?.volume?.interpreted && capacity?.buildable?.volume?.interpreted.length > 0 && capacity?.buildable?.volume?.interpreted[0]?.userData) {
            var nav = navigation;
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            nav.layout[2].isVisible = true;
            nav.layout[3].isVisible = true;
            nav.layout[4].isVisible = true;
            nav.layout[5].isVisible = true;
            nav.layout[6].isVisible = true;
            setNavigation({ ...nav });
        }
        else if (capacity?.step > 0) {
            var nav = navigation;
            nav.layout[0].isVisible = true;
            nav.layout[1].isVisible = true;
            nav.layout[2].isVisible = true;
            nav.layout[3].isVisible = true;
            nav.layout[4].isVisible = true;
            nav.layout[5].isVisible = true;
            nav.layout[6].isVisible = false;
            setNavigation({ ...nav });
        }

    }, [capacity]);

    // CAPACITY : Check frequency save
    useEffect(() => {
        const interval = setInterval(() => {
            // Decrement save counter
            save_counter--;

            console.log('CHECK IF SAVE', save_counter);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    // CAPACITY : Check save on unload
    useEffect(() => {
        const handleUnload = () => {
            console.log('CHECK IF SAVE ON CLOSE', save_counter);
        };
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);

    // CAPACITY : Autosave in DB
    const saveCapacity = () => {
        console.log("-SAVING CAPA- : Optimizing...");
        // return
        // Get optimized version
        var capaToUpdate = other_helpers.compressCapa(capacity);

        // Save to db
        console.log("-SAVING CAPA- : Loading...", capaToUpdate);
        // addToaster("save");
        if (capaToUpdate?.toSave?.action !== "") {
            handleToaster([{ key: "save", status: "loading" }]);
        }
        else {
            // console.log("HIDDEN AUTO SAVE");
        }
        dispatch(capaUpdate(capacity._id, { capaToUpdate, userId: user.result._id }, handleUpdateSuccess, handleUpdateFail));

        // Reset autosave
        // setCapacity({ ...capacity, toSave: { autoSave: false, action: "", value: "" } });
    }
    const handleUpdateSuccess = (data) => {
        console.log("-SAVING CAPA- : Success", data);
        // finishToaster("save");
        handleToaster([{ key: "save", status: "success" }]);

        var capacity_data = data.result;
        // capacity_data.toSave = false;
        if (capacity_data?.toUpdate === true) {
            console.log("CAPA toUpdate TRUE");
            // /!\ Should be better to update only what had been modififed in backend (not the optimized removed elements)
            setCapacity({ ...capacity_data, toUpdate: false });
        }
        else {
            // SET CAPACITY ONLY WITH THE MODIFICATIONS
            console.log("UPDATING MODIFS", capacity);
            var capa_new = { ...capacity };
            capa_new.toSave = { autoSave: false, action: "", value: "", keys: [] };
            capa_new.toUpdate = false;
            if (data?.result) {
                Object.keys(data?.result).forEach(key => {
                    capa_new[key] = data.result[key];
                })
            }
            // capa_new.modifications = capacity_data.modifications;
            // setCapacity({ ...capacity, toSave: { autoSave: false, action: "", value: "", keys: [] }, toUpdate: false, modifications: capacity_data.modifications });
            setCapacity(capa_new);
        }
    }
    const handleUpdateFail = (error) => {
        console.log("-SAVING CAPA- : Error", error?.response?.data?.message);
        handleToaster([{ key: "save", status: "error" }]);
        // If session expired => back to signin
        if (error?.response?.data?.message === "La session a expiré.") {
            // console.log("Redirecting to SignIn");
            setTimeout(() => {
                navigate('/auth?type=signin&cause=expired&source=' + window.location.pathname);
            }, 500);
        }
    }

    // CAPACITY : Get Interpreted Object
    const getInterpretedObjects = (capa, relaunch_buildable) => {

        // Interpret rules
        if (capa?.rules?.ruleset && capa.rules.ruleset.length > 0) {
            // console.log("INTERPRETING THREE OBJECTS");
            capa.rules.ruleset.forEach(ruleset => {
                ruleset.items.forEach(item => {
                    if (item?.type === "rule") {
                        if (item?.data?.params?.condition?.data?.interpreted === false) {
                            item.interpreted = [];
                        }
                        else {
                            // Check if not already interpreted (to optimize)
                            // console.log("XXXXXXXXXXXXXX - RULE", item);
                            if (!item?.interpreted || item.interpreted.length === 0 || item.interpreted[0] === "removed") {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                            // Check if rule has to be re interpreted even if already interpreted => use the variable of max_height or limits
                            else if (item?.data?.composition?.nodes && item?.data?.composition?.nodes.length > 0 && (item?.data?.composition?.nodes[0]?.data?.source[0]?.value.includes("Node_Study_Buildable_Height[]") || item?.data?.composition?.nodes[0]?.data?.source[0]?.value.includes("Node_Study_Bounds["))) {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                        }
                    }
                })
            })
        }

        // Interpret volume
        // if ((capa?.buildable?.volume?.interpreted && capa.buildable.volume.interpreted[0] === "removed") || !capa?.buildable?.volume?.parameters?.max_area_total) {
        if ((capa?.buildable?.volume?.interpreted && capa.buildable.volume.interpreted[0] !== "removed") || !capa?.buildable?.volume?.parameters?.max_area_total) {

            // Get rules that affects calculations
            var rules_effect_parameters = buildable_helpers.get_rules_effect(capa);
            // console.log("--_--_--_--_ rules_effect", rules_effect_parameters.rules_effect);
            // console.log("--_--_--_--_ volume_parameters", rules_effect_parameters.volume_parameters);

            capa.buildable.volume.parameters = rules_effect_parameters.volume_parameters;

        }



        // Interpret rules again to update to change of max height
        if (rules_effect_parameters?.max_height_change === true && capa?.rules?.ruleset && capa.rules.ruleset.length > 0) {
            // console.log("INTERPRETING THREE OBJECTS");
            capa.rules.ruleset.forEach(ruleset => {
                ruleset.items.forEach(item => {
                    if (item?.type === "rule") {
                        if (item?.data?.params?.condition?.data?.interpreted === false) {
                            item.interpreted = [];
                        }
                        else {
                            // Check if not already interpreted (to optimize)
                            // console.log("XXXXXXXXXXXXXX - RULE", item);
                            if (!item?.interpreted || item.interpreted.length === 0 || item.interpreted[0] === "removed") {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                            // Check if rule has to be re interpreted even if already interpreted => use the variable of max_height or limits
                            else if (item?.data?.composition?.nodes && item?.data?.composition?.nodes.length > 0 && (item?.data?.composition?.nodes[0]?.data?.source[0]?.value.includes("Node_Study_Buildable_Height[]"))) {
                                // console.log("XXXXXXXXXXXXXX - INTERPRET RULE");
                                item.interpreted = node_helpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capa, item?.data?.params?.perimeter);
                            }
                        }
                    }
                })
            })
        }

        // Interpret buildable volume
        // if (capa?.landBase?.union?.geometry?.geometry?.type === "Polygon") {
        //     var buildable_interpreted = node_helpers.buildableInterpretor(capa);
        //     console.log("buildable_interpreted", buildable_interpreted);
        //     capa.buildable.volume.interpreted = buildable_interpreted;
        // }

        // Set capacity
        setCapacity(capa);

        // Update tracker
        setRulesetUpdateTracker((Date.now()).toString());

        // Update map
        // layer_Refresh("rules");

        getInterpretedVolume(capa, relaunch_buildable);
    }
    const getInterpretedVolume = (capa, relaunch_buildable) => {

        var newBuildable = { ...capa.buildable };
        // Interpret buildable volume
        if (capa?.landBase?.union?.geometry?.geometry?.type === "Polygon") {
            var buildable_interpreted = node_helpers.buildableInterpretor(capa);
            // console.log("buildable_interpreted", buildable_interpreted);
            newBuildable.volume.interpreted = buildable_interpreted;
        }
        // Remove levels to force calcul again
        if (relaunch_buildable === true || !capa?.buildable?.volume?.levels) {
            newBuildable.volume.levels = "fetching";
        }
        // Set capacity
        var save = { ...capa.toSave };
        var rules = { ...capa.rules };
        rules.state = "done";
        // save.autoSave = true;
        setCapacity({ ...capa, rules: rules, toSave: save, buildable: newBuildable });

        setTimeout(() => {
            // Set volume tracker
            setVolumeUpdateTracker((Date.now()).toString());
        }, 500);
    }

    //__________ FETCH

    // FETCH : Topo
    const fetchTopo = async () => {
        var topo_majic_buildings = await fetch_helpers.get_topo_majic_buildings(capacity);
        // console.log("topo_majic_buildings", topo_majic_buildings);
        var landBase = capacity.landBase;
        landBase.topo = topo_majic_buildings.topo;
        landBase.lands = topo_majic_buildings.majic;
        landBase.buildings = topo_majic_buildings.buildings;
        // Remove unecessary data
        if (landBase?.buildings_osm && landBase.buildings !== "error" && landBase?.buildings?.buildings && landBase.buildings.buildings.length > 0) {
            // delete landBase.buildings_osm;
        }
        // Set buildable volume max height
        var buildable = capacity.buildable;
        var maxHeight = -1;
        if (topo_majic_buildings?.buildings?.buildings_close && topo_majic_buildings.buildings.buildings_close.length > 0) {
            for (var i = 0; i < topo_majic_buildings.buildings.buildings_close.length; i++) {
                if (topo_majic_buildings.buildings.buildings[topo_majic_buildings.buildings.buildings_close[i]]?.properties?.height_max > maxHeight) {
                    maxHeight = topo_majic_buildings.buildings.buildings[topo_majic_buildings.buildings.buildings_close[i]]?.properties?.height_max;
                }
            }
        }
        // if (maxHeight >= 3) {
        //     buildable.volume.parameters.max_height = parseFloat((maxHeight + 0.02).toFixed(2));
        // }
        // Set buildable volume parameters
        if (!buildable?.volume?.parameters) {
            buildable.volume.parameters = {};
        }
        if (!buildable?.volume?.parameters?.max_height_database) {
            buildable.volume.parameters.max_height_database = parseFloat((maxHeight).toFixed(2));
        }
        if (!buildable?.volume?.parameters?.max_height) {
            if (maxHeight > 3) {
                buildable.volume.parameters.max_height = parseFloat((maxHeight).toFixed(2));
                buildable.volume.parameters.max_height_type = "database";
            }
            else { // DEFAULT VALUE OF max_height
                buildable.volume.parameters.max_height = 3.00;
                buildable.volume.parameters.max_height_type = "default";
            }
        }

        // Update capacity
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("buildable");
        toSaveData.keys.push("landBase");
        toSaveData.autoSave = true;
        setCapacity({ ...capacity, buildable: buildable, landBase: landBase, toSave: toSaveData });
    }

    const fetchRisks = async () => {
        // console.log("STOP TEST HERE");
        // return
        var risks = await fetch_helpers.get_risks(capacity.landBase.union.center.geometry.coordinates);
        // console.log("risks", risks);
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("risks");
        toSaveData.autoSave = true;
        setCapacity({ ...capacity, risks: risks, toSave: toSaveData });
        // setCapacity({ ...capacity, risks: risks, context: "fetching", toSave: { autoSave: true, action: "", value: "" } });
    }

    const fetchGPU = async () => {
        // console.log("FETCHING GPU");
        var gpu_data = await fetch_helpers.get_gpu(capacity.landBase);
        // var gpu_data = await fetch_helpers.get_gpu(capacity.landBase.union);
        // console.log("gpu_data", gpu_data);
        var rules = capacity.rules;
        rules.gpu_data = gpu_data;
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("rules");
        toSaveData.keys.push("all");
        toSaveData.autoSave = true;
        setCapacity({ ...capacity, rules: rules, toSave: toSaveData });
        // finishToaster("gpu");
        handleToaster([{ key: "buildable", status: "loading" }, { key: "gpu", status: "success" }]);


        // Auto calculate buildable
        // addToaster("buildable");
        getInterpretedObjects(capacity, true);
    }

    const getBuildableData = () => {
        var volumeData = buildable_helpers.get_buildable_data(capacity);
        var buildable = capacity.buildable;
        buildable.volume = volumeData;
        buildable.combinations = ["removed"];
        setHighlightedCombination([]);
        // finishToaster("buildable");
        handleToaster([{ key: "buildable", status: "success" }]);
        // NOT FORGET TO GET BACK SAVING
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("buildable");
        toSaveData.autoSave = true; // TO CHANGE TO TRUE WHEN RECETTE OVER
        // console.log("FINAL --toSaveData", toSaveData);
        // setCapacity({ ...capacity, buildable: buildable, toSave: { autoSave: true, action: "", value: "" } });
        setCapacity({ ...capacity, buildable: buildable, toSave: toSaveData });
    }

    const getCombinationData = () => {
        // Reset list parameters
        setHighlightedCombination([]);
        var list_select = document.getElementById('orderBy');
        if (list_select !== null) {
            list_select.value = "byId";
        }

        // Launch algo after 200 msec
        setTimeout(() => {
            var combinations = combination_helpers.get_combinations(capacity);
            var buildable = { ...capacity.buildable };
            buildable.combinations = combinations;
            // console.log("buildable", buildable);
            // finishToaster("combinations");
            handleToaster([{ key: "combinations", status: "success" }]);
            // NOT FORGET TO GET BACK SAVING
            // setCapacity({ ...capacity, buildable: buildable, toSave: { autoSave: true, action: "", value: "" } });
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("buildable");
            toSaveData.autoSave = true;
            setCapacity({ ...capacity, buildable: buildable, toSave: toSaveData });
        }, 200);
    }


    // _____ RULES
    const [rulesCatalogTeam, setRulesCatalogTeam] = useState(
        {
            rulesCatalog: [],
            rulesTeam: []
        }
    );
    const getRules = () => {
        // console.log("-FETCHING RULES- : Loading...");
        dispatch(rulesGetByTeamId(user.teams[0]._id, handleRulesGetSuccess, handleRulesGetFail));
    }
    // Get response from Rules fetching : success
    const handleRulesGetSuccess = (data) => {
        // console.log("-FETCHING RULES- : Success", data);
        rulesUpdate(data);
    };
    // Get response from Rules fetching : error
    const handleRulesGetFail = (error) => {
        console.log("-FETCHING RULES- : Error", error.response.data);
    }
    const rulesUpdate = (rulesCatalogTeam) => {
        setRulesCatalogTeam({
            ...rulesCatalogTeam,
            rulesCatalog: rulesCatalog_Filter(rulesCatalogTeam.rulesCatalog),
            rulesTeam: rulesCatalogTeam.rulesTeam
        })
    }
    const rulesCatalog_Filter = (rulesCatalog) => {
        // console.log("RULES CATALOG TO FILTER", rulesCatalog);

        // Sort
        var rulesCatalogOrdered = [...rulesCatalog];
        rulesCatalogOrdered.sort(function (a, b) {
            if (a.title < b.title) { return -1; }
            if (a.title > b.title) { return 1; }
            return 0;
        })

        var rulesPopular = [];
        var rulesFiltered = {};
        var rulesFilteredTitles = [];
        rulesCatalogOrdered.forEach(rule => {
            // Popular
            if (rule.popular === true) {
                rulesPopular.push(rule);
            }
            // Filter
            if (!rulesFilteredTitles.includes(rule.category)) {
                rulesFilteredTitles.push(rule.category);
                rulesFiltered[rule.category] = [];
            }
            rulesFiltered[rule.category].push(rule);
        });
        // Sort titles
        rulesFilteredTitles.sort();
        // Create final list
        var rulesFinal = {
            "popular": rulesPopular,
        }
        rulesFilteredTitles.forEach(title => {
            rulesFinal[title] = rulesFiltered[title];
        });

        // console.log("RULES CATALOG FILTERED", rulesFinal);
        return rulesFinal;
    }

    const [selectedRuleGroup, setSelectedRuleGroup] = useState(null);
    const [ruleCatalogType, setRuleCatalogType] = useState("rule");

    const ruleCatalog_Open = (ruleGroup_Id, type) => {
        setRuleCatalogType(type);
        setSelectedRuleGroup(ruleGroup_Id);
        setIsRuleCatalogOpen(true);
    }

    const [ruleUpdateTracker, setRuleUpdateTracker] = useState(0);
    useEffect(() => {
        if (ruleUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL RULE CHANGE", ruleUpdateTracker);

            // Derivated step : get buildable volume
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            // Update just volume
            // getInterpretedVolume(capacity);
            // Or update all three
            getInterpretedObjects(capacity, true);
        }

    }, [ruleUpdateTracker]);

    const [rulesetUpdateTracker, setRulesetUpdateTracker] = useState(0);
    useEffect(() => {
        if (rulesetUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL RULESET CHANGE", rulesetUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: rulesetUpdateTracker, item: "rule" });
        }

    }, [rulesetUpdateTracker]);

    const [highlightedRule, setHighlightedRule] = useState(null);
    const [hiddenRule, setHiddenRule] = useState(null);

    const [volumeUpdateTracker, setVolumeUpdateTracker] = useState(0);
    useEffect(() => {
        if (volumeUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL VOLUME CHANGE", volumeUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: volumeUpdateTracker, item: "volume" });

            // Derivated step : get combinations TO DO

        }
    }, [volumeUpdateTracker]);

    const [highlightedSitadel, setHighlightedSitadel] = useState(null);



    // BOUNDS
    const [boundUpdateTracker, setBoundUpdateTracker] = useState(0);
    useEffect(() => {
        if (boundUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL BOUND CHANGE", boundUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: boundUpdateTracker, item: "bound" });

            // Derivated step : get buildable volume
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            // Update just volume
            // getInterpretedVolume(capacity);
            // Or update all three
            getInterpretedObjects(capacity, true);
        }

    }, [boundUpdateTracker]);

    // BUILDINGS
    const [buildingUpdateTracker, setBuildingUpdateTracker] = useState(0);
    useEffect(() => {
        if (buildingUpdateTracker !== 0) {
            // console.log("!!!!!! OFFICIAL BUILDING CHANGE", buildingUpdateTracker);
            // Global update
            setGlobalUpdateTracker({ value: buildingUpdateTracker, item: "building" });

            // Derivated step : get buildable volume
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            // Update just volume
            // getInterpretedVolume(capacity);
            // Or update all three
            getInterpretedObjects(capacity, true);
        }

    }, [buildingUpdateTracker]);


    // COMBINATIONS
    const [highlightedCombination, setHighlightedCombination] = useState([]);



    // RELOAD DATA
    const reloadData = (dataType) => {
        if (dataType === "landBase") {
            var landBase = capacity.landBase;
            landBase.topo = "fetching";
            setCapacity({ ...capacity, landBase: landBase, toSave: { autoSave: false, action: "", value: "" } });
        }
        if (dataType === "risks") {
            setCapacity({ ...capacity, risks: "fetching", toSave: { autoSave: false, action: "", value: "" } });
        }
        if (dataType === "context") {
            setCapacity({ ...capacity, context: "fetching", toSave: { autoSave: true, action: "", value: "" } });
        }
        if (dataType === "plu") {
            var rules = capacity.rules;
            rules.gpu_data = "fetching";
            setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "", value: "" } });
        }
    }


    // IFC GENERATION
    const createIFC = () => {
        var schema = {
            land: true,
            topo: true,
            buildable_volume: true,
            buildings: true,
        };

        const link = document.createElement("a");
        const content = IFC_helpers.get_ifc_data(capacity, schema);
        const file = new Blob([content], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        link.download = "sample.ifc";
        link.click();
        URL.revokeObjectURL(link.href);
    }


    // Update capa data
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <div>
            {isError &&
                <ErrorPage setIsError={setIsError} errorMsg={errorMsg} version={version} />
            }
            {/* <RuleEditorContainer /> */}
            {isRuleCatalogOpen &&
                <RuleCatalog ruleGroup_Id={selectedRuleGroup} currentType={ruleCatalogType} setCurrentType={setRuleCatalogType} setIsRuleCatalogOpen={setIsRuleCatalogOpen} capacity={capacity} setCapacity={setCapacity} user={user} rulesCatalogTeam={rulesCatalogTeam} rulesUpdate={rulesUpdate} setRuleUpdateTracker={setRuleUpdateTracker} />
            }
            {isModalOpen &&
                <div className="app__update_capa_modal_container">
                    <div className="app__update_capa_modal" onClick={() => { setIsModalOpen(false) }}>

                    </div>
                </div>
            }
            <ToasterContainer toastersData={toastersData} />
            {/* <RuleCatalog documentId={"test"} setIsRuleCatalogOpen={setIsRuleCatalogOpen} /> */}
            <Navbar layout={navigation.layout} isApp={true} targetSection={targetSection} setTargetSection={setTargetSection} currentSection={currentSection} updateCurrentSection={updateCurrentSection} version={version} isMapExpanded={isMapExpanded} setIsMapExpanded={setIsMapExpanded} />
            <Header title="Etude Capacitaire" isApp={true} capacity={capacity} />
            {/* SECTION DATA */}
            <animated.div style={{ top: sectionDataStyle.top, height: sectionDataStyle.height, opacity: sectionDataStyle.opacity }} id="section_data" className={`app__sectiondata_UI ${(isMapReady === false || isMapExpanded === true) ? "app__sectiondata_hidden_UI" : ""}`}>
                {/* SECTION DATA PART 0 */}
                <div id='section_0' className="app__sectiondata_container_UI">
                    {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                        <div className="app__section_data_disabled"></div>
                    }
                    {(isMapReady && capacity?.title) ?
                        <>
                            <div className="app__sectiondata_title_container_UI">
                                {window.innerWidth > 1050 &&
                                    <div className="app__sectiondata_widget_spacer_15"></div>
                                }
                                <div className="app__sectiondata_title_label">{navigation.layout[0]['title_long']}</div>
                                <div className="app__sectiondata_title_bar"></div>
                            </div>
                            <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                                <TitleAndDescription capacity={capacity} setCapacity={setCapacity} />
                                <div className="app__sectiondata_widget_spacer"></div>
                                <Location capacity={capacity} setCapacity={setCapacity} />
                                <Modifications capacity={capacity} user={user} />
                                {/* {environment !== "PROD" &&
                                    <>
                                        <div className="app__sectiondata_widget_spacer"></div>
                                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <div style={{ cursor: "pointer" }} onClick={() => { setIsModalOpen(true) }}>Mettre à jour l'étude <span className="app__tag">préprod</span></div>
                                        </div>
                                    </>
                                } */}
                                {environment !== "PROD" &&
                                    <div onClick={() => { createIFC() }}>TEST DOWNLOAD</div>
                                }
                            </div>
                        </>
                        :
                        <>
                            {isAccessDenied ?
                                <>
                                    <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                                        <div className="app__sectiondata_loading_container">
                                            <div className="app__sectiondata_loading_text"><span style={{ fontSize: "20px" }}>😥</span><br></br>Vous n'avez pas le droit d'accéder à cette étude</div>
                                            <div className="combi__launch_button" onClick={() => { navigate('/dashboard') }}>Retourner au Dashboard</div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                                    <div className="app__sectiondata_loading_container">
                                        <div className="app__sectiondata_loading"></div>
                                        <div className="app__sectiondata_loading_text">Nous récupérons les données de cette étude</div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {/* SECTION DATA PART 1 */}
                <div id='section_1' className="app__sectiondata_container_UI">
                    {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                        <div className="app__section_data_disabled"></div>
                    }
                    {(isMapReady && capacity?.title) &&
                        <>
                            <div className="app__sectiondata_title_container_UI">
                                {window.innerWidth > 1050 &&
                                    <div className="app__sectiondata_widget_spacer_15"></div>
                                }
                                <div className="app__sectiondata_title_subcontainer">
                                    <div className="app__sectiondata_title_label">{navigation.layout[1]['title_long']}</div>
                                    {user?.access?.land === true &&
                                        <div className="app__sectiondata_title_reload">
                                            <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                            <img src={ico_reload} alt="" onClick={() => { reloadData("landBase") }} />
                                        </div>
                                    }
                                </div>
                                <div className="app__sectiondata_title_bar"></div>
                            </div>
                            <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                                <LandsDetail capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} user={user} />
                                <div className="app__sectiondata_widget_spacer"></div>
                                <BoundsDetail capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} setBoundUpdateTracker={setBoundUpdateTracker} />
                                {capacity?.landBase?.topo === "fetching" &&
                                    <div className="app__sectiondata_loading_container">
                                        <div className="app__sectiondata_loading"></div>
                                        <div className="app__sectiondata_loading_text">Nous analysons les données du terrain</div>
                                    </div>
                                }
                                {user?.access?.land === true ?
                                    <>
                                        {(capacity?.landBase?.topo && capacity.landBase.topo !== "fetching" && capacity.landBase.topo !== "error") &&
                                            <Topo capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} />
                                        }
                                        {(capacity?.landBase?.buildings && capacity.landBase.buildings !== "error") &&
                                            <Buildings capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} setBuildingUpdateTracker={setBuildingUpdateTracker} />
                                        }
                                    </>
                                    :
                                    <div className="app__sectiondata_noaccess_container">
                                        <div className="app__sectiondata_noaccess_container_centered">
                                            <div style={{ fontSize: "26px" }}>😓</div>
                                            <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès aux données analytiques du terrain.<br /></div>
                                        </div>
                                        <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                        <div className="app__sectiondata_noaccess_text_small">- les propriétaires (personnes morales) des parcelles sélectionnées</div>
                                        {(capacity?.landBase?.lands && capacity?.landBase?.lands.length > 0 && capacity?.landBase?.lands.filter(element => { if (element?.majic) { return true; } return false; }).length > 0) &&
                                            <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.landBase?.lands.filter(element => { if (element?.majic) { return true; } return false; }).length} propriétaire{capacity?.landBase?.lands.filter(element => { if (element?.majic) { return true; } return false; }).length > 1 && "s"}</div>
                                        }
                                        <div className="app__sectiondata_noaccess_text_small">- le maillage topographique du terrain et le dénivelé maximum</div>
                                        <div className="app__sectiondata_noaccess_text_small">- le détail des bâtiments existants sur le terrain (nature, superficie, hauteur, DPE)</div>
                                        {(capacity?.landBase?.buildings?.buildings_land && capacity?.landBase?.buildings?.buildings_land.length > 0) &&
                                            <div className="app__sectiondata_noaccess_text_note">→ nous avons analysé {capacity?.landBase?.buildings?.buildings_land.length} bâtiment{capacity?.landBase?.buildings?.buildings_land.length > 1 && "s"}</div>
                                        }
                                        <div className="app__sectiondata_noaccess_text_small">- le coefficient d'imperméabilisation du terrain</div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
                {/* SECTION DATA PART 2 */}
                {currentStep >= 1 &&
                    <div id='section_2' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_subcontainer">
                                <div className="app__sectiondata_title_label">{navigation.layout[2]['title_long']}</div>
                                {user?.access?.risks === true &&
                                    <div className="app__sectiondata_title_reload">
                                        <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                        <img src={ico_reload} alt="" onClick={() => { reloadData("risks") }} />
                                    </div>
                                }
                            </div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                            {user?.access?.risks === true ?
                                <Risks capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} />
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès aux données de l'analyse des risques.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- les risques naturels et industriels identifiés par GéoRisques</div>
                                    {((capacity?.risks?.natural && capacity?.risks?.natural.length > 1) || (capacity?.risks?.industrial && capacity?.risks?.industrial.length > 0)) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.risks?.natural.length + capacity?.risks?.industrial.length} risque{(capacity?.risks?.natural.length + capacity?.risks?.industrial.length) > 1 && "s"}</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- le risque de sismicité</div>
                                    <div className="app__sectiondata_noaccess_text_small">- le risque lié au retrait-gonflement des sols argileux</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la présence de canalisations de matières dangereuses</div>
                                    <div className="app__sectiondata_noaccess_text_small">- le risque de pollution du sol</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 3 : CONTEXT */}
                {currentStep >= 1 &&
                    <div id='section_3' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_subcontainer">
                                <div className="app__sectiondata_title_label">{navigation.layout[3]['title_long']}</div>
                                {user?.access?.context === true &&
                                    <div className="app__sectiondata_title_reload">
                                        <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                        <img src={ico_reload} alt="" onClick={() => { reloadData("context") }} />
                                    </div>
                                }
                            </div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                            {user?.access?.context === true ?
                                <Context capacity={capacity} mapLayers={mapLayers} setMapLayers={setMapLayers} highlightedSitadel={highlightedSitadel} />
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès aux données de l'analyse contextuelle.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la zone ABC</div>
                                    <div className="app__sectiondata_noaccess_text_small">- les statistiques de la population (CSP, âge moyen, composition des ménages)</div>
                                    <div className="app__sectiondata_noaccess_text_small">- le détail du parc immobilier de la commune (typologies, surfaces, pièces...)</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la liste des transactions immobilières des parcelles du terrain et aux alentours</div>
                                    {(capacity?.context?.dvf?.dvf_transactions_land && capacity?.context?.dvf?.dvf_transactions_land.length > 0) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.context?.dvf?.dvf_transactions_land.length} transaction{capacity?.context?.dvf?.dvf_transactions_land.length > 1 && "s"} sur les parcelles du terrain</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- la présence de monuments historiques aux alentours du terrain</div>
                                    <div className="app__sectiondata_noaccess_text_small">- les différentes commodités à proximité du terrain</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 4 : RULES */}
                {currentStep >= 1 &&
                    <div id='section_4' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_subcontainer">
                                <div className="app__sectiondata_title_label">{navigation.layout[4]['title_long']}</div>
                                {user?.access?.context === true &&
                                    <div className="app__sectiondata_title_reload">
                                        <div className="app__sectiondata_title_reload_text">Mettre à jour les données externes</div>
                                        <img src={ico_reload} alt="" onClick={() => { reloadData("plu") }} />
                                    </div>
                                }
                            </div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                            {user?.access?.rules === true ?
                                <List capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} categories={categories} setCategories={setCategories} ruleCatalog_Open={ruleCatalog_Open} setRuleUpdateTracker={setRuleUpdateTracker} highlightedRule={highlightedRule} setHighlightedRule={setHighlightedRule} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} />
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès à la fonctionnalité <strong>Urbanisme</strong>.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Cette fonctionnalité permet :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- un accès direct aux documents d'urbanisme (PLU, PADD, OAP...)</div>
                                    {(capacity?.rules?.gpu_data?.files && Object.keys(capacity?.rules?.gpu_data?.files).length > 0) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons agrégé {Object.keys(capacity?.rules?.gpu_data?.files).reduce((acc, arr) => acc += capacity.rules.gpu_data.files[arr].length, 0)} documents</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- l'analyse du zonage PLU du terrain</div>
                                    {(capacity?.rules?.gpu_data?.zoneUrba && capacity?.rules?.gpu_data?.zoneUrba.length > 0) &&
                                        <div className="app__sectiondata_noaccess_text_note">→ nous avons identifié {capacity?.rules?.gpu_data?.zoneUrba.length > 1 ? "les zones" : "la zone"} PLU du terrain</div>
                                    }
                                    <div className="app__sectiondata_noaccess_text_small">- l'utilisation du catalogue de règles PLU permettant d'agir sur le volume constructible</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la création de vos propres règles pour adapter le volume constructible</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 5 : BUILDABLE */}
                {currentStep >= 1 &&
                    <div id='section_5' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_label">{navigation.layout[5]['title_long']}</div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                            {/* <div className="app__sectiondata_loading_container">
                                <div className="app__sectiondata_loading_logo"><img src={logo_card} alt="" /></div>
                                <div className="app__sectiondata_loading_text">Cette section est en cours de développement</div>
                            </div> */}
                            <BuildableDetails capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} handleToaster={handleToaster} />
                        </div>
                    </div>
                }
                {/* SECTION DATA PART 6 : COMBINATIONS */}
                {(currentStep >= 1 && capacity?.buildable?.volume?.interpreted && capacity?.buildable?.volume?.interpreted.length > 0 && capacity?.buildable?.volume?.interpreted[0]?.userData) &&
                    <div id='section_6' className="app__sectiondata_container_UI">
                        {(mapDrawMode?.mode && mapDrawMode.mode !== null) &&
                            <div className="app__section_data_disabled"></div>
                        }
                        <div className="app__sectiondata_title_container_UI">
                            {window.innerWidth > 1050 &&
                                <div className="app__sectiondata_widget_spacer_15"></div>
                            }
                            <div className="app__sectiondata_title_label">{navigation.layout[6]['title_long']}</div>
                            <div className="app__sectiondata_title_bar"></div>
                        </div>
                        <div className="app__sectiondata_body_container_UI" style={{ minHeight: sectionMinHeight }}>
                            {user?.access?.simulations === true ?
                                <Combinations capacity={capacity} setCapacity={setCapacity} mapLayers={mapLayers} setMapLayers={setMapLayers} highlightedCombination={highlightedCombination} setHighlightedCombination={setHighlightedCombination} />
                                :
                                <div className="app__sectiondata_noaccess_container">
                                    <div className="app__sectiondata_noaccess_container_centered">
                                        <div style={{ fontSize: "26px" }}>😓</div>
                                        <div className="app__sectiondata_noaccess_text">Votre abonnement ne donne pas accès à la fonctionnalité <strong>Simulation de constructions</strong>.<br /></div>
                                    </div>
                                    <div className="app__sectiondata_noaccess_text_small">Ces données contiennent :</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la génération intelligente de dizaines de constructions dans le volume constructible</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la possibilité de combiner plusieurs simulations</div>
                                    <div className="app__sectiondata_noaccess_text_small">- la possibilité de trier les simulations pour optimiser la surface construite</div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </animated.div>
            {/* SECTION SEPARATOR */}
            <animated.div {...dragSectionSeparator()} style={{ top: sectionSeparatorStyle.top, touchAction: 'none', }} id="section_separator" className="app__sectionseparator">
                <animated.div style={{ height: sectionSeparatorStyle.heightMessageTop }} id="section_separator_messageTop" className="app__sectionseparator_message"><div className="app__sectionseparator_message_anim_top"></div><div>Descendre pour afficher la carte</div></animated.div>
                <div className="app__sectionseparator_handler"></div>
                <animated.div style={{ height: sectionSeparatorStyle.heightMessageBottom }} id="section_separator_messageBottom" className="app__sectionseparator_message"><div className="app__sectionseparator_message_anim_bottom"></div><div>Monter pour afficher les données</div></animated.div>
            </animated.div>
            {/* SECTION MAP */}
            <animated.div style={{ top: sectionMapStyle.top, height: sectionMapStyle.height, opacity: sectionMapStyle.opacity }} id="section_map" className="app__sectionmap_UI">
                <Geocoder capacity={capacity} setCapacity={setCapacity} isMapExpanded={isMapExpanded} />
                <Map isMapReady={isMapReady} setIsMapReady={setIsMapReady} isMapExpanded={isMapExpanded} setIsMapExpanded={setIsMapExpanded} isHeaderBanner={false} currentSection={currentSection} currentStep={currentStep} capacity={capacity} setCapacity={setCapacity} mapResize={mapResize} setTargetSection={setTargetSection} mapLayers={mapLayers} setMapLayers={setMapLayers} ruleUpdateTracker={ruleUpdateTracker} highlightedRule={highlightedRule} setHighlightedRule={setHighlightedRule} volumeUpdateTracker={volumeUpdateTracker} highlightedCombination={highlightedCombination} setHighlightedCombination={setHighlightedCombination} setErrorPage={setErrorPage} globalUpdateTracker={globalUpdateTracker} mapDrawMode={mapDrawMode} setMapDrawMode={setMapDrawMode} hiddenRule={hiddenRule} setHiddenRule={setHiddenRule} user={user} setHighlightedSitadel={setHighlightedSitadel} />
                <LandsValidation capacity={capacity} setCapacity={setCapacity} isMapExpanded={isMapExpanded} />
            </animated.div>
        </div>
    );
};

export default CapaStudy;