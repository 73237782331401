import React, { useState, useEffect } from 'react';

// Import CSS & Assets
import './Bound.css';

// Import external functions
import * as helpers from '../../../../helpers/Other_helpers';

const Bound = ({ bound, index, bounds_types, updateBound, currentModifyOpen, setCurrentModifyOpen, capacity }) => {

    const [isModifiyOpen, setIsModifyOpen] = useState(false);

    const [currentType, setCurrentType] = useState(bound.context_analysis.result.type);
    const [currentSubType, setCurrentSubType] = useState(bound.context_analysis.result.subtype);
    const [currentElements, setCurrentElements] = useState(bound.context_analysis.result.elements);

    // var type = "Type indéfini";
    // var elements = "";
    // if (bound.context_analysis.result.type === "separation") {
    //     type = "Limite séparative";
    //     if (bound.context_analysis.result.elements.length === 1) {
    //         elements = "Parcelle " + bound.context_analysis.result.elements[0].properties.section + "-" + bound.context_analysis.result.elements[0].properties.numero;
    //     }
    //     else if (bound.context_analysis.result.elements.length > 1) {
    //         elements = "Parcelles"
    //         for (var i = 0; i < bound.context_analysis.result.elements.length; i++) {
    //             elements = elements + " " + bound.context_analysis.result.elements[i].properties.section + "-" + bound.context_analysis.result.elements[i].properties.numero;
    //         }
    //     }
    // }
    // else if (bound.context_analysis.result.type === "public") {
    //     type = "Limite sur voie publique";
    //     elements = bound.context_analysis.result.elements[0].properties.name;
    // }

    useEffect(() => {
        // Reset if another modisy is opened on another bound
        if (currentModifyOpen !== index) {
            handleResetBound();
        }
    }, [currentModifyOpen]);

    const handleOpenModify = () => {
        setIsModifyOpen(true);
        setCurrentModifyOpen(index);
    }

    const handleTypeChange = (newType) => {
        setCurrentType(newType);
        setCurrentSubType(Object.keys(bounds_types[newType]["subtypes"])[0]);
        setCurrentElements([]);
    }

    const handleElementsChange = (changedElement) => {
        var newElements = [];
        var isAdd = true;
        currentElements.forEach(element => {
            if (element.id !== changedElement.id) {
                newElements.push(element);
            }
            else {
                isAdd = false;
            }
        });
        if (isAdd === true) {
            newElements.push(changedElement);
        }
        setCurrentElements([...newElements]);
    }

    const handleUpdateBound = () => {
        // Create nex bound model
        var newBound = { ...bound };
        newBound.context_analysis.result.type = currentType;
        newBound.context_analysis.result.subtype = currentSubType;
        newBound.context_analysis.result.elements = currentElements;
        // Set modification elements & new_bounds_type
        var new_boungs_types = { ...bounds_types };
        var action = "Modification de la Limite N°" + (index + 1) + " en";
        var value = "";
        if (currentSubType === "other") {
            // Add custom subtype
            var new_subType_id = "custom_" + Date.now().toString();
            var new_subType_label = document.getElementById("new_subType_label").value;
            newBound.context_analysis.result.subtype = new_subType_id;
            new_boungs_types[currentType]["subtypes"][new_subType_id] = {
                id: new_subType_id,
                label: new_subType_label
            }
            value = new_subType_label;
        }
        else {
            value = bounds_types[currentType]["subtypes"][currentSubType]["label"];
        }
        // Add details to type
        var value_details = "";
        newBound.context_analysis.result.elements.forEach(item => {
            var separator = " ";
            if (value_details === "") { separator = "" }
            if (item?.name) {
                value_details = value_details + separator + item.name;
            }
            else if (newBound.context_analysis.result.type === "separation" && item?.properties?.section && item?.properties?.numero) {
                value_details = value_details + separator + item.properties.section + "-" + item.properties.numero;
            }
            else if (newBound.context_analysis.result.type === "way" && item?.properties?.name) {
                value_details = value_details + separator + item.properties.name;
            }
        })
        if (value_details !== "") {
            value = value + " (" + value_details + ")";
        }


        updateBound(newBound, index, action, value, new_boungs_types);
        // Close pop-up
        setIsModifyOpen(false);
    }

    const handleResetBound = () => {
        setCurrentType(bound.context_analysis.result.type);
        setCurrentSubType(bound.context_analysis.result.subtype);
        setCurrentElements(bound.context_analysis.result.elements);
        // Close pop-up
        setIsModifyOpen(false);
    }


    return (
        <div className="app__bound_container">
            {index === 0 ?
                <div className="app__bound_title_container">
                    <div className="app__bound_title app_bound_col0">Numéro de limite</div>
                    <div className="app__bound_title app_bound_col1">Longueur</div>
                    <div className="app__bound_title app_bound_col2">Type</div>
                </div>
                :
                <div className="app__bound_line_container"></div>
            }
            <div className="app__bound_body_container">
                <div className="app__bound_body_value_main app_bound_col0">Limite N°{index + 1}</div>
                <div className="app__bound_body_value app_bound_col1">{helpers.get_pretty_num(bound.length.toFixed(2))} m</div>
                <div className="app__bound_body_value_container app_bound_col2">
                    <div className="app__bound_type_container">
                        {/* <div className={`app__type_color ${bound.context_analysis.result.type === "separation" ? "color_sep" : bound.context_analysis.result.type === "public" ? "color_pub" : "color_nc"}`}></div> */}
                        {bounds_types &&
                            <div className="app__bound_body_value">{bounds_types[bound.context_analysis.result.type]["subtypes"][bound.context_analysis.result.subtype]["label"]}</div>
                        }
                    </div>
                    {bound.context_analysis.result.elements.length > 0 &&
                        <>
                            {bound.context_analysis.result.type === "separation" ?
                                <div className="app__bound_body_value_second">
                                    {bound.context_analysis.result.elements.length === 1 ?
                                        "Parcelle " + (bound.context_analysis.result.elements[0]?.name || (bound.context_analysis.result.elements[0]?.properties?.section + "-" + bound.context_analysis.result.elements[0]?.properties?.numero))
                                        :
                                        "Parcelles" + bound.context_analysis.result.elements.map(land => {
                                            return (
                                                " " + (land?.name || (land?.properties?.section + "-" + land?.properties?.numero))
                                            )
                                        })
                                    }
                                </div>
                                :
                                // <div className="app__bound_body_value_second">{bound.context_analysis.result.elements[0]?.name || bound.context_analysis.result.elements[0]?.properties?.name}</div>
                                <div className="app__bound_body_value_second">
                                    {bound.context_analysis.result.elements.length === 1 ?
                                        bound.context_analysis.result.elements[0]?.name || bound.context_analysis.result.elements[0]?.properties?.name
                                        :
                                        bound.context_analysis.result.elements.map((road, index) => {
                                            var separator = "";
                                            if (index > 0) { separator = ", " }
                                            if (index === bound.context_analysis.result.elements.length - 1) { separator = " et " }
                                            return (
                                                separator + (road?.name || road?.properties?.name)
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>
                    }
                    {capacity.step > 0 &&
                        <div className="app__bound_type_modify_button_container">
                            <div className="app__landsvalidation_popup_button" onClick={() => { handleOpenModify() }}>Modifier</div>
                        </div>
                    }
                    {isModifiyOpen &&
                        <div className="app__bound_type_modify_container scale-up-topright">
                            <div className="app__bound_type_modify_title_big">Modification de la Limite N°{index + 1}</div>
                            <div className="app__bound_type_modify_title">Type</div>
                            <select className="app__bound_type_modify_select" id="id" onChange={(e) => { handleTypeChange(e.target.value) }} defaultValue={currentType}>
                                <option value="separation">Limite séparative</option>
                                <option value="way">Limite sur voie</option>
                                <option value="nc">Autre</option>
                            </select>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                            <div className="app__bound_type_modify_title">Sous-type</div>
                            <select className="app__bound_type_modify_select" id="id" onChange={(e) => { setCurrentSubType(e.target.value) }} defaultValue={currentSubType}>
                                {Object.keys(bounds_types[currentType]["subtypes"]).map((subType_id, index) => {
                                    return (
                                        <option key={index} value={subType_id}>{bounds_types[currentType]["subtypes"][subType_id]["label"]}</option>
                                    )
                                })}
                                <option value="other">Autre</option>
                            </select>
                            {currentSubType === "other" &&
                                <input id="new_subType_label" className="app__bound_type_modify_input_text" placeholder="Entrez un nouveau sous-type" type="text" onChange={(e) => { }} />
                            }
                            {currentType === "separation" &&
                                <>
                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                    <div className="app__bound_type_modify_title">Parcelles adjacentes</div>
                                    <div className="app__bound_type_modify_checkbox_container">
                                        {bound.context_analysis.lands.map((land, index) => {
                                            var isSelected = false;
                                            for (var i = 0; i < currentElements.length; i++) {
                                                if (currentElements[i].id === land?.data?.id || currentElements[i].id === land?.id) {
                                                    isSelected = true;
                                                    break;
                                                }
                                            }
                                            return (
                                                <div key={index} className="app__bound_type_modify_checkbox_line"><input className="app__bound_type_modify_checkbox" type="checkbox" id={"cb_land_" + index} onChange={() => { handleElementsChange(land.data) }} checked={isSelected} /><label className="app__bound_type_modify_checkbox_label" htmlFor={"cb_land_" + index}>Parcelle {land.data.name}</label></div>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                            {currentType === "way" &&
                                <>
                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                    <div className="app__bound_type_modify_title">Voie concernée</div>
                                    <div className="app__bound_type_modify_checkbox_container">
                                        {bound.context_analysis.roads.map((road, index) => {
                                            var isSelected = false;
                                            for (var i = 0; i < currentElements.length; i++) {
                                                if (currentElements[i].id === road?.data?.id || currentElements[i].id === road?.id) {
                                                    isSelected = true;
                                                    break;
                                                }
                                            }
                                            return (
                                                <div key={index} className="app__bound_type_modify_checkbox_line"><input className="app__bound_type_modify_checkbox" type="checkbox" id={"cb_road_" + index} onChange={() => { handleElementsChange(road.data) }} checked={isSelected} /><label className="app__bound_type_modify_checkbox_label" htmlFor={"cb_road_" + index}>{road.data.name}</label></div>
                                            )
                                        })}
                                    </div>
                                </>
                            }

                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div className="app__bound_type_modify_buttons_container">
                                <div className="app__landsvalidation_popup_button" onClick={() => { handleUpdateBound() }}>Valider</div>
                                <div className="app__landsvalidation_popup_button_second" onClick={() => { handleResetBound() }}>Annuler</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Bound;