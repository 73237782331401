// Import dependencies
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';

// Import external functions
import * as map_helpers from '../../app/map/Map_helpers';

// CSS
import './MiniMap.css'

// Set mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoidGlqb3giLCJhIjoiY2s4azRibzF6MDEzZDNuczduZW1jbzB0biJ9.JAGdImb16q69S3kEn0KcCg';

var study_popup = null;
var pointMin = [100, 100];
var pointMax = [-100, -100];

const MiniMap = ({ capacityFullList, capaType }) => {

    // __________ MAP PARAMETERS

    // MAP : Global variables
    const miniMapContainer = useRef(null);
    const miniMap = useRef(null);

    const [isMapLoaded, setIsMapLoaded] = useState(false);



    // MAP : On load 
    useEffect(() => {
        // Check if map is already initialized
        if (miniMap.current) return;
        // If not => Initialize it
        miniMap.current = new mapboxgl.Map({
            container: miniMapContainer.current,
            style: 'mapbox://styles/tijox/cke0dn2v61bfq19lb2p69mei2',
            center: [2.2, 46.4],
            zoom: 4,
            pitch: 0
        });
        // Set interactions
        polygon_Interaction();

        miniMap.current.addControl(new mapboxgl.FullscreenControl());
        miniMap.current.addControl(new mapboxgl.NavigationControl());

        // Set loaded state and ready state
        miniMap.current.on('load', () => {
            setIsMapLoaded(true);
        });
    });

    // Set polygons
    useEffect(() => {
        if (isMapLoaded === true && capacityFullList.length > 0 && capacityFullList[0] !== "fetching") {
            // console.log("SHOW POLY ON MINI MAP", capacityFullList);
            polygon_Show(capacityFullList);
        }
    }, [capacityFullList, isMapLoaded, capaType]);

    const polygon_Interaction = () => {
        miniMap.current.on('mouseenter', 'layerStudy', () => { miniMap.current.getCanvas().style.cursor = 'pointer' });
        miniMap.current.on('mouseleave', 'layerStudy', () => { miniMap.current.getCanvas().style.cursor = '' });
        miniMap.current.on('click', 'layerStudy', (e) => { polygon_Click(e) });
        miniMap.current.on('mouseenter', 'layerStudy_center', () => { miniMap.current.getCanvas().style.cursor = 'pointer' });
        miniMap.current.on('mouseleave', 'layerStudy_center', () => { miniMap.current.getCanvas().style.cursor = '' });
        miniMap.current.on('click', 'layerStudy_center', (e) => { polygon_Click(e) });
    }

    const polygon_Click = (e) => {
        console.log("CLICKED", e.features[0]);
        console.log("CLICKED", e.lngLat);

        var title = '<span style="color:#062134;font-size:15px;line-height:15px;font-weight:700"><strong>' + e.features[0].properties.title + '</strong></span><br>';
        var team = "";
        if (e.features[0].properties.team !== "") {
            team = '<span style="color:#062134;font-weight:500;">' + e.features[0].properties.team + '</span><br>';
        }
        // Add popup to map
        study_popup = new mapboxgl.Popup()
            // .setLngLat(e.lngLat)
            .setLngLat({
                lat: e.features[0].properties.center_lat,
                lng: e.features[0].properties.center_lng,
            })
            .setHTML('<div style="font-family:Manrope">' + title + team + '<div style="background-color:#1ecd97;color:#FFF;font-weight:600;padding:3px 8px;width:fit-content;border-radius:20px"><a href="/capastudy/' + e.features[0].properties.id + '" target="_blank">' + "Ovrir l'étude" + '</a></div></div>')
            .addTo(miniMap.current);

        // Fit miniMap
        var offset = 0.0005;
        miniMap.current.fitBounds([
            [e.features[0].properties.center_lng - offset, e.features[0].properties.center_lat - offset], // southwestern corner of the bounds
            [e.features[0].properties.center_lng + offset, e.features[0].properties.center_lat + offset], // northeastern corner of the bounds
        ]);
    }

    const polygon_Show = (list) => {
        console.log("list", list);
        var polyList = [];
        var boundList = [];
        var centerList = [];
        pointMin = [100, 100];
        pointMax = [-100, -100];
        list.forEach(poly => {
            if (poly?.land_center) {
                var point = poly?.land_center;
                point.properties = {
                    title: poly.title,
                    id: poly.id,
                    team: poly.team,
                    center_lat: poly?.land_center?.geometry?.coordinates[1],
                    center_lng: poly?.land_center?.geometry?.coordinates[0],
                };
                centerList.push(point);
                if (poly?.land_center?.geometry?.coordinates[0] < pointMin[0]) {
                    pointMin[0] = poly?.land_center?.geometry?.coordinates[0];
                }
                if (poly?.land_center?.geometry?.coordinates[1] < pointMin[1]) {
                    pointMin[1] = poly?.land_center?.geometry?.coordinates[1];
                }
                if (poly?.land_center?.geometry?.coordinates[0] > pointMax[0]) {
                    pointMax[0] = poly?.land_center?.geometry?.coordinates[0];
                }
                if (poly?.land_center?.geometry?.coordinates[1] > pointMax[1]) {
                    pointMax[1] = poly?.land_center?.geometry?.coordinates[1];
                }
            }
            // if (poly?.landBase?.union?.bounds_groups && poly.landBase.union.bounds_groups.length > 0) {
            //     var polyCoords = [];
            //     poly.landBase.union.bounds_groups.forEach(bound => {
            //         polyCoords.push(bound.start_coord_global);
            //         if (bound?.start_coord_global && bound.start_coord_global[0] < pointMin[0]) {
            //             pointMin[0] = bound.start_coord_global[0];
            //         }
            //         if (bound?.start_coord_global && bound.start_coord_global[1] < pointMin[1]) {
            //             pointMin[1] = bound.start_coord_global[1];
            //         }
            //         if (bound?.start_coord_global && bound.start_coord_global[0] > pointMax[0]) {
            //             pointMax[0] = bound.start_coord_global[0];
            //         }
            //         if (bound?.start_coord_global && bound.start_coord_global[1] > pointMax[1]) {
            //             pointMax[1] = bound.start_coord_global[1];
            //         }
            //     })
            //     polyCoords.push(poly.landBase.union.bounds_groups[0].start_coord_global);
            //     var polygon = turf.polygon([polyCoords]);
            //     var polygon_bbox = turf.bbox(polygon);
            //     var polygon_center = turf.centerOfMass(polygon);
            //     var polygon_properties = {
            //         title: poly.title,
            //         description: poly.description,
            //         id: poly._id,
            //         center_lng: turf.getCoord(polygon_center)[0],
            //         center_lat: turf.getCoord(polygon_center)[1],
            //         bbox_x_min: turf.bbox(polygon)[0],
            //         bbox_y_min: turf.bbox(polygon)[1],
            //         bbox_x_max: turf.bbox(polygon)[2],
            //         bbox_y_max: turf.bbox(polygon)[3],
            //     }
            //     polygon.properties = polygon_properties;
            //     polygon_center.properties = polygon_properties;
            //     polyList.push(polygon);
            //     boundList.push(turf.polygonToLine(polygon));
            //     centerList.push(polygon_center);
            // }
        });
        // Get offset for min and max
        var line = turf.lineString([pointMin, pointMax]);
        var bbox = turf.bbox(line);
        var bboxPolygon = turf.bboxPolygon(bbox);
        var buffered = turf.buffer(bboxPolygon, 20, { steps: 1 });
        var bbox2 = turf.bbox(buffered);
        pointMin = [bbox2[0], bbox2[1]];
        pointMax = [bbox2[2], bbox2[3]];
        // console.log("min", pointMin);
        // console.log("max", pointMax);
        // Remove layers
        if (miniMap.current.getLayer('layerStudy')) {
            miniMap.current.removeLayer('layerStudy');
        }
        if (miniMap.current.getLayer('layerStudy_bounds')) {
            miniMap.current.removeLayer('layerStudy_bounds');
        }
        if (miniMap.current.getLayer('layerStudy_center')) {
            miniMap.current.removeLayer('layerStudy_center');
        }
        if (miniMap.current.getLayer('layerStudy_heat')) {
            miniMap.current.removeLayer('layerStudy_heat');
        }
        // Remove source
        if (miniMap.current.getSource('sourceStudy')) {
            miniMap.current.removeSource('sourceStudy');
        }
        // Add source
        miniMap.current.addSource('sourceStudy', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: centerList,
            }
        });
        // Add layer polys
        // miniMap.current.addLayer({
        //     id: 'layerStudy',
        //     type: 'fill',
        //     source: 'sourceStudy',
        //     paint: {
        //         'fill-color': '#062134',
        //         'fill-opacity': 0.2,
        //     },
        //     filter: ['in', '$type', 'Polygon']
        // });
        // Add layer bounds
        // miniMap.current.addLayer({
        //     id: 'layerStudy_bounds',
        //     type: 'line',
        //     source: 'sourceStudy',
        //     layout: {
        //         'line-cap': 'round',
        //         'line-join': 'round'
        //     },
        //     paint: {
        //         'line-color': '#062134',
        //         'line-width': 2.5,
        //     },
        //     filter: ['in', '$type', 'LineString']
        // });
        // Add layer centers
        if (capaType === 1) {
            miniMap.current.addLayer({
                id: 'layerStudy_center',
                type: 'circle',
                source: 'sourceStudy',
                // filter: ['!', ['has', 'point_count']],
                filter: ['in', '$type', 'Point'],
                paint: {
                    'circle-color': '#062134',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#fff'
                }
            });
        }
        if (capaType === 2) {
            miniMap.current.addLayer({
                id: 'layerStudy_center',
                type: 'circle',
                source: 'sourceStudy',
                minzoom: 14,
                // filter: ['!', ['has', 'point_count']],
                filter: ['in', '$type', 'Point'],
                paint: {
                    'circle-color': '#062134',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#fff'
                }
            });
            miniMap.current.addLayer(
                {
                    id: 'layerStudy_heat',
                    type: 'heatmap',
                    source: 'sourceStudy',
                    maxzoom: 15,
                    paint: {
                        // increase weight as diameter breast height increases
                        'heatmap-weight': {
                            property: 'dbh',
                            type: 'exponential',
                            stops: [
                                [1, 0],
                                [62, 1]
                            ]
                        },
                        // increase intensity as zoom level increases
                        'heatmap-intensity': {
                            stops: [
                                [11, 1],
                                [15, 3]
                            ]
                        },
                        // assign color values be applied to points depending on their density
                        'heatmap-color': [
                            'interpolate',
                            ['linear'],
                            ['heatmap-density'],
                            // 0,
                            // 'rgba(236,222,239,0)',
                            // 0.2,
                            // // 'rgb(208,209,230)',
                            // '#88FF9F',
                            // 0.4,
                            // // 'rgb(166,189,219)',
                            // '#7EFEB6',
                            // 0.6,
                            // // 'rgb(103,169,207)',
                            // '#78FEC5',
                            // 0.8,
                            // // 'rgb(28,144,153)'
                            // '#6FFEDB',
                            0,
                            'rgba(33,102,172,0)',
                            0.2,
                            'rgb(103,169,207)',
                            0.4,
                            'rgb(209,229,240)',
                            0.6,
                            'rgb(253,219,199)',
                            0.8,
                            'rgb(239,138,98)',
                            1,
                            'rgb(178,24,43)'
                        ],
                        // increase radius as zoom increases
                        'heatmap-radius': {
                            stops: [
                                [11, 15],
                                [15, 20]
                            ]
                        },
                        // decrease opacity to transition into the circle layer
                        'heatmap-opacity': {
                            default: 1,
                            stops: [
                                [14, 1],
                                [15, 0]
                            ]
                        }
                    }
                },
                'waterway-label'
            );
        }


        // Fit miniMap
        polygon_fit();
    }

    const polygon_fit = () => {
        // remove popup
        if (study_popup !== null) {
            study_popup.remove();
            study_popup = null;
        }
        // Move
        if (pointMin[0] !== 100) {
            var offset = 0.05;
            miniMap.current.fitBounds([
                [pointMin[0] - offset, pointMin[1] - offset], // southwestern corner of the bounds
                [pointMax[0] + offset, pointMax[1] + offset], // northeastern corner of the bounds
            ]);
        }
    }




    return (
        <div id="miniMap" className="minimap__container">
            <div ref={miniMapContainer} className="minimap__map" onMouseLeave={() => { polygon_fit() }} />

        </div>
    );
};

export default MiniMap;