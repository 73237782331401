import { GET, UPDATE } from '../constants/actionTypes';
import * as api from '../api';

export const usersGet = (handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.teamsGet();
        const action = { type: GET, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const userUpdate = (id, userData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.userUpdate(id, userData);
        const action = { type: UPDATE, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const usersGetAll = (handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.usersGet();
        const action = { type: GET, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}