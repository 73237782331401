import React from 'react';

const RuleResult = ({ rule }) => {
    return (
        <>
            {rule?.interpreted && rule.interpreted.length > 0 && rule.interpreted[0]?.userData?.result &&
                <span className="app__ruleset_rule_result_placeholder">
                    {rule.interpreted[0]?.userData?.result}
                </span>
            }
        </>
    );
};

export default RuleResult;