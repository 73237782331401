import React, { useState } from 'react';

// Import CSS & assets
import ico_dots from '../../../../assets/ico/ico_dots.svg';
import ico_close from '../../../../assets/ico/ico_close.svg';

const Document = ({ id, name, item, title_Create, ruleCatalog_Open, ruleGroup_Update, ruleGroup_Delete }) => {

    const handleTitleChange = (newTitle) => {
        if (name !== newTitle) {
            ruleGroup_Update(id, newTitle, "Renommage de l'ensemble de règles '" + name + "' en", newTitle);
        }
    }

    // Document actions
    const [isActions, setIsActions] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleClickTitle = () => {
        title_Create(id);
        setIsActions(false);
    }

    const handleClickRule = () => {
        ruleCatalog_Open(id, "rule");
        setIsActions(false);
    }

    const handleClickDelete = () => {
        setIsActions(false);
        setIsDelete(true);
    }

    return (
        <div className="app__ruleset_document">
            <div className="app__ruleset_document_header">
                {/* <div className="app__ruleset_document_title">{name}</div> */}
                {(item?.numerotation && item?.numerotation !== "0.") &&
                <span className="app__ruleset_document_title_numerotation">{item?.numerotation}</span>
                }
                <input className="app__ruleset_document_title_input" type="text" name="title" placeholder="Titre de l'ensemble de règles" onChange={(e) => { }} onBlur={(e) => { handleTitleChange(e.target.value) }} defaultValue={name}></input>
                {id !== "doc_0" && id !== "ruleGroup_0" &&
                    <div className="app__ruleset_document_button_container" onClick={() => { setIsDelete(true) }}>
                        <img src={ico_close} alt="dots" />
                    </div>
                }
            </div>
            {isActions &&
                <div className="app__ruleset_document_actions_container scale-up-topright" onMouseLeave={() => { setIsActions(false) }}>
                    <div className="app__landsvalidation_popup_button" onClick={handleClickTitle}>+ Ajouter un titre</div>
                    <div className="app__landsvalidation_popup_button" onClick={handleClickRule}>+ Ajouter une règle</div>
                    {id !== "doc_0" && id !== "ruleGroup_0" &&
                        <div className="app__landsvalidation_popup_button_second" style={{ marginTop: "6px" }} onClick={handleClickDelete}>Supprimer cet ensemble de règles</div>
                    }
                </div>
            }
            {isDelete &&
                <div className="app__ruleset_document_actions_container" style={{ height: (document.getElementById(id + "_container").offsetHeight + 15) + "px" }}>
                    <div className="app__bound_type_modify_title_big" style={{ color: "#062134", fontWeight: "600" }}>Supprimer cet ensemble de règles ?</div>
                    <div className="app__ruleset_document_deletion_container">
                        <div className="app__landsvalidation_popup_button" onClick={() => { setIsDelete(false) }}>Conserver</div>
                        <div className="app__landsvalidation_popup_button_second" onClick={() => { ruleGroup_Delete(id) }}>Supprimer</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Document;