export const getRulesNumerotation = (rules) => {
    var ruleGroup_num = rules.ruleset.length > 1 ? 0 : -1;
    var rule_num_main = 0;
    var rule_num_second = 0;
    var isSecond = false;

    rules.ruleset.forEach(ruleGroup => {
        // Increment
        ruleGroup_num++;
        // Reset
        rule_num_main = 0;
        rule_num_second = 0;
        isSecond = false;
        // Add to group object
        ruleGroup.numerotation = ruleGroup_num + ".";
        // Loop inside group
        ruleGroup.items.forEach(item => {
            console.log("item", item);
            console.log("isSecond", isSecond);
            // Check position
            if (item?.type === "title") {isSecond = false}
            // Increment
            if (isSecond === true) {
                rule_num_second++;
            }
            else {
                rule_num_main++;
                rule_num_second = 0;
            }
            // Get num
            var num = "";
            if (ruleGroup_num > 0) {
                num = ruleGroup_num + ".";
            }
            num = num + rule_num_main + ".";
            if (isSecond === true) {
                num = num + rule_num_second + ".";
            }
            // Add to item object
            item.numerotation = num;

            if (item?.type === "title") {isSecond = true}
        })
    })

    return rules
}