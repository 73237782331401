import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { teamUpdate } from '../../../actions/teams';

// Import CSS & assets
import './Users.css';
import ico_verified from '../../../assets/ico/ico_verified.svg';

const Users = ({ users, userOrder, setUserOrder, teams, updateTeams }) => {

    const dispatch = useDispatch();

    function checkEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const handleUserOrderChange = (new_order) => {
        if (new_order !== userOrder) {
            setUserOrder(new_order);
        }
    }

    const [showTeams, setShowTeams] = useState(null);
    const handleSwitchShowTeams = (toShow) => {
        setShowTeams(toShow);
    }
    const [isModal, setIsModal] = useState(false);
    const [teamToAttach, setTeamToAttach] = useState(null);
    const [userToAttach, setUserToAttach] = useState(null);
    const handleClickAttachUserToTeam = (team, user) => {
        handleSwitchShowTeams(null);
        setTeamToAttach(team);
        setUserToAttach(user);
        setIsModal(true);
    }

    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    // Error
    const [errorMessage, setErrorMessage] = useState('');

    // Loading
    const handleLoading = () => {
        setIsLoading(true);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }
    const handleLoadedSuccess = () => {
        setIsLoading(false);
        setIsLoadedSuccess(true);
        // Reset button & clear form
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
            clear();
        }, 500);
        // Fetch all teams again to update UI
        updateTeams();
    }
    const handleSuccess = () => {
        console.log("SUCCESS");
        handleLoadedSuccess();
    }
    const handleLoadedFail = () => {
        setIsLoading(false);
        setIsLoadedFail(true);
        // Reset button
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
        }, 4000);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleLoadedFail();
    }
    // Clear
    const clear = () => {
        setIsLoading(false);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
        setIsModal(false);
        setUserToAttach(null);
        setTeamToAttach(null);
    }

    const handleSubmit_addUser = (e) => {
        e.preventDefault(); // no refresh

        var newUser = userToAttach.email;

        if (newUser !== "" && checkEmail(newUser)) {
            // Check if user is already attached
            var newUserLower = newUser.toLowerCase();
            var user = userToAttach;
            console.log("user", user);
            if (user === undefined || !user?.team || user?.team === "") {
                // Set loading button
                handleLoading();
                // Check all required fields are not empty
                console.log("SUBMIT ADD NEW USER", newUserLower);
                // Create members
                var team = teamToAttach;
                var members = [...team.members];
                members.push(newUserLower);
                // Create name
                var name = team.name;
                // Create status
                var isInactive = team.isInactive;
                // Create image
                var image = team?.image || "";
                // Create data
                var data = { name, members, isInactive, image };
                // Create data
                var data = { name, members };
                console.log("data", data);
                // Send
                dispatch(teamUpdate(team._id, { data }, handleSuccess, handleFail));
            }
            else {
                setErrorMessage("Cet utilisateur est déja rattaché à l'équipe : " + user?.team);
                setTimeout(() => {
                    setErrorMessage("");
                }, 1500);
            }
        }
        else {
            setErrorMessage("Cet email est invalide");
            setTimeout(() => {
                setErrorMessage("");
            }, 1500);
        }
    }

    return (
        <>
            <div className="app__admin_title">Utilisateurs</div>
            <div className="app__admin_users_labels">
                <div className="app_admin_users_label0"><span style={{cursor:'pointer'}} onClick={ () => {handleUserOrderChange("user_name")}}>Nom{userOrder === "user_name" && " ▽"}</span>, <span style={{cursor:'pointer'}} onClick={ () => {handleUserOrderChange("user_mail")}}>email{userOrder === "user_mail" && " ▽"}</span> et <span style={{cursor:'pointer'}} onClick={ () => {handleUserOrderChange("user_date")}}>date d'inscription{userOrder === "user_date" && " ▽"}</span></div>
                <div className="app_admin_users_label1"><span style={{cursor:'pointer'}} onClick={ () => {handleUserOrderChange("team_name")}}>Équipe{userOrder === "team_name" && " ▽"}</span></div>
                <div className="app_admin_users_label2"><span style={{cursor:'pointer'}} onClick={ () => {handleUserOrderChange("nb_capa")}}>Études{userOrder === "nb_capa" && " ▽"}</span></div>
            </div>
            <div className="admin__users_container">
                {users.map((user, index) => {
                    return (
                        <div className="app__admin_users_container" key={index}>
                            {index > 0 &&
                                <div className="app__admin_users_separator"></div>
                            }
                            <div className="app__admin_users_body">
                                <div className="app__admin_users_body2">
                                    <img src={user.avatar} alt="" className="app__admin_users_avatar" />
                                    <div>
                                        <div className="app__admin_users_title">
                                            {user.firstName} {user.lastName}
                                        </div>
                                        <div className="app__admin_users_mail">
                                            {user.email}
                                            {user.validated === true &&
                                                <img src={ico_verified} />
                                            }
                                            {user?.creationDate &&
                                                <> - {new Date(user.creationDate).toLocaleDateString()}</>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {user?.team ?
                                    <div className="app__admin_users_team">{user.team}</div>
                                    :
                                    <div className="app__admin_users_noteam">
                                        <span onClick={() => { handleSwitchShowTeams(user.id) }}>Non rattaché</span>
                                    {showTeams === user.id &&
                                        <div className="app__admin_users_noteam_list" onMouseLeave={() => { handleSwitchShowTeams(null) }}>
                                            Rattacher l'utilisateur à :
                                            {teams.map((team, team_index) => {
                                                if (team?.isInactive === false) {
                                                    return (
                                                        <div key={"team_to_attach_" + team_index} className="app__admin_users_noteam_list_item" onClick={() => { handleClickAttachUserToTeam(team, user) }}>{team.name}</div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    }
                                    </div>
                                }
                                <div className="app__admin_users_capa">
                                    {(userOrder === "nb_capa" && index === 0) && "🥇 "}
                                    {(userOrder === "nb_capa" && index === 1) && "🥈 "}
                                    {(userOrder === "nb_capa" && index === 2) && "🥉 "}
                                    {user.capa.length}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {isModal &&
                <div className="app__admin_users_modal">
                    <div className="app__admin_users_modal_box">
                        <div style={{textAlign:'center', marginBottom:'6px'}}>Voulez-vous ajouter l'utilisateur <span style={{ color: '#ababab' }}>{userToAttach?.firstName} {userToAttach?.lastName}</span> à l'équipe <span style={{ color: '#ababab' }}>{teamToAttach.name}</span> ?</div>
                        <div className="app__admin_team_modal_buttons">
                            <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit_addUser}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Ajouter"}</div>
                            <div className="app__admin_team_modal_button_red" onClick={() => { clear() }}>Annuler</div>
                            <div className="app__admin_team_modal_message">{errorMessage}</div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Users;