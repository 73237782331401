import React from 'react';
import { Handle, Position } from 'react-flow-renderer';


// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';


const Node_Input_Number = ({ id, data }) => {

    const handle_InputChange = (e) => {
        var value = null;
        if (!isNaN(parseFloat(e.target.value))) {
            value = "Node_Input_Number[" + parseFloat(e.target.value) + "]";
        }
        data.source[0].value = value;
        data.handleChange(id, "source_0", value);
    };

    return (
        <div id={"block_" + id} className="ruleeditor__node_container">
            <div className="ruleeditor__node_title">
                <div className="ruleeditor__node_title_label">{data.label}</div>
                <div className="ruleeditor__node_title_close"><img src={close} alt="close_block" onClick={() => (data.handleDelete(id))} /></div>
            </div>
            <div className="ruleeditor__node_center">
                <div className="ruleeditor__node_description">{data.description}</div>
                <div className="ruleeditor__node_body">
                    <input className="ruleeditor__handle_source_input" type="number" onChange={(e) => { handle_InputChange(e) }} />
                    <div className={`ruleeditor__handle_source_type ${data.source[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.source[0].acceptance}</div>
                </div>
            </div>
            <Handle type="source" position={Position.Right} style={{ top: 'calc(100% - 11px - 17px)' }} id="source_0" />
        </div>
    );
};

export default Node_Input_Number;