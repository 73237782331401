import React, { useState } from 'react';

import ico_file from '../../../../assets/ico/ico_file.svg';

const FileGroup = ({ file_group, index_group, files }) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="app__ruleset_group_container">
            <div className="app__ruleset_group_header" onClick={() => { setIsOpen(!isOpen) }}>
                <div className={`app__ruleset_group__header_ico ${isOpen ? "app__ruleset_group__header_ico_open" : ""}`}>></div>
                <div>{file_group}</div>
            </div>
            <div className={`app__ruleset_group ${isOpen ? "app__ruleset_group_open" : ""}`}>
                {files[file_group].map((file, index_file) => {
                    return (
                        <div key={index_group + "_" + index_file}>
                            <div className="app__ruleset_file_container">
                                <a href={file.url} target="_blanck">
                                    <div className="app__ruleset_ico"><img src={ico_file} alt="ico_file" /></div>
                                </a>
                                <a href={file.url} target="_blanck">
                                    <div className="app__bound_body_value">{file.title}</div>
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default FileGroup;