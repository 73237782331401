// Import dependencies
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendContact } from '../../../actions/auth';


// Import CSS & assets
import './Contact.css';
import send_mask from '../../../assets/send_mask.svg';

// Import components
import Input from '../../common/input/Input';
import InputMultiLine from '../../common/inputMultiLine/InputMultiLine';


const Contact = () => {

    // Set global functions
    const dispatch = useDispatch();

    // Set constants
    const initialFormData = {
        name: '',
        email: '',
        message: '',
    };

    // Set hooks
    const [formData, setFormData] = useState(initialFormData);
    // Data validation
    const [isConfirmValid, setIsConfirmValid] = useState(false);
    // Loading
    const [isStarting, setIsStarting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    const [message, setMessage] = useState("‎");

    // Set specific funcitons
    // Changes on form
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    // Check mail format
    const checkEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    // Check data validity
    const checkData = () => {
        if (isLoading) {
            setMessage("Votre message est en chemin");
            setTimeout(() => {
                setMessage("‎");
            }, "3000")
            return false;
        }
        else if (isLoadedSuccess) {
            setMessage("Nous avons bien reçu votre message");
            setTimeout(() => {
                setMessage("‎");
            }, "3000")
            return false;
        }
        else if (formData.name === "" || formData.email === "" || formData.message === "") {
            setMessage("Veuillez remplir tous les champs");
            setTimeout(() => {
                setMessage("‎");
            }, "3000")
            return false;
        }
        else if (!checkEmail(formData.email)) {
            setMessage("Veuillez renseigner une adresse mail valide");
            setTimeout(() => {
                setMessage("‎");
            }, "3000")
            return false;
        }
        else {
            setMessage("‎");
            return true;
        }
    }
    // Submit form
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        if (checkData()) {
            console.log("-SUBMIT MESSAGE- : Loading...");
            setIsStarting(true);
            setTimeout(() => {
                setIsLoading(true);
                setIsStarting(false);
                setTimeout(() => {
                    dispatch(sendContact({ contactData: formData }, handleLoadedSuccess, handleLoadedFail));
                }, 1000)
            }, 500)
        }
    }
    // Return form submission
    const handleLoadedSuccess = (data) => {
        console.log("-SUBMIT MESSAGE- : Success", data);
        setIsStarting(false);
        setIsLoading(false);
        setIsLoadedFail(false);
        setIsLoadedSuccess(true);
        setMessage("‎");
    }
    const handleLoadedFail = (error) => {
        console.log("-SUBMIT MESSAGE- : Error", error);
        setIsStarting(false);
        setIsLoading(false);
        setIsLoadedFail(true);
        setIsLoadedSuccess(false);
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
        }, 4000);
    }

    return (
        <div className="landing__contact section__padding">
            <a className="anchor" id="contact"></a>
            <div className="landing__contact_container">
                <div className="landing__contact_text_container">
                    <h1 className="gradient__text">Contactez-nous</h1>
                    <p>
                        Vous souhaitez plus d'informations, planifier une démonstration ou essayer notre solution ?
                    </p>
                    <p>
                        Contactez-nous en utilisant ce formulaire, notre équipe commerciale reviendra rapidement vers vous.
                    </p>
                </div>
                <div className="landing__contact_form_container">
                    <div className="landing__contact_form_box">
                        {/* <form autoComplete='off' noValidate onSubmit={handleSubmit}> */}
                        <form autoComplete='off' noValidate>
                            <div className="landing__contact_form_box_name">
                                <Input type='text' name='name' label='Nom et Prénom' handleChange={handleChange} />
                            </div>
                            <div className="landing__contact_form_box_mail">
                                <Input type='text' name='email' label='Adresse mail' handleChange={handleChange} />
                            </div>
                            <div className="landing__contact_form_box_message">
                                <InputMultiLine name='message' label='Message' handleChange={handleChange} />
                            </div>
                            <div className="landing__contact_form_box_button">
                                <div className={`landing__contact_form_button ${isStarting ? "landing__contact_form_button_starting" : isLoading ? "landing__contact_form_button_loading" : isLoadedSuccess ? "landing__contact_form_button_loading_success" : isLoadedFail ? "landing__contact_form_button_loading_fail" : ""}`} onClick={handleSubmit}>
                                    <div>{isLoading ? "" : isLoadedSuccess ? "Merci! Message bien reçu 👍" : isLoadedFail ? "Il y a eu un problème 😔" : isConfirmValid ? 'OK' : 'Envoyer votre message'}</div>
                                    {(isLoading === false && isLoadedFail === false && isLoadedSuccess === false) &&
                                        <img src={send_mask} />
                                    }
                                </div>
                                {/* <input className={`landing__contact_form_button ${isLoading ? "landing__contact_form_button_loading" : isLoadedSuccess ? "landing__contact_form_button_loading_success" : isLoadedFail ? "landing__contact_form_button_loading_fail" : ""}`} type="submit" value={isLoading ? "" : isLoadedSuccess ? "Merci! Message bien reçu 👍" : isLoadedFail ? "Il y a eu un problème 😔" : isConfirmValid ? 'OK' : 'Envoyer votre message'} /> */}
                                <p className='landing__contact_form_message'>{message}</p>
                            </div>
                        </form>
                        {/* <button onClick={() => { setIsStarting(true) }}>START</button>
                        <button onClick={() => { setIsStarting(false); setIsLoading(true) }}>LOADING</button>
                        <button onClick={handleLoadedSuccess}>SUCCESS</button>
                        <button onClick={handleLoadedFail}>FAIL</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;