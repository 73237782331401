import React from 'react';

// Import CSS & assets
import './Footer.css';
import linkedin from '../../../assets/logo/logo_linkedin.svg';


const Footer = () => {
    return (
        <div className="landing__footer section__padding">
            <div className="landing__footer_bloc_left">
                <div className="landing__footer_brand">PROPLAB</div>
                <div className="landing__footer_text">by Fractal Buildings</div>
                <div className="landing__footer_logo_container">
                    <a href="https://www.linkedin.com/company/prop-lab/">
                        <img src={linkedin} alt="" className="landing__footer_logo" />
                    </a>
                </div>
                <div className="landing__footer_text">Tous droits réservés</div>
            </div>
            <div className="landing__footer_bloc_middle">
                <div className="landing__footer_bloc">
                    <div className="landing__footer_title">Navigation</div>
                    <div className="landing__footer_link">Accueil</div>
                    <div className="landing__footer_link">Solution</div>
                    <div className="landing__footer_link">Fonctionnalités</div>
                    <div className="landing__footer_link">Contact</div>
                </div>
                <div className="landing__footer_bloc">
                    <div className="landing__footer_title">Clients</div>
                    <div className="landing__footer_link">Promoteurs immobiliers</div>
                    <div className="landing__footer_link">Architectes</div>
                    <div className="landing__footer_link">Urbanistes</div>
                    <div className="landing__footer_link">Marchands de biens</div>
                </div>
                <div className="landing__footer_bloc">
                    <div className="landing__footer_title">Informations</div>
                    <div className="landing__footer_link">Mentions légales</div>
                    <div className="landing__footer_link">Conditions générales d'utilisation</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;