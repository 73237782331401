import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';

const Node_Study_Bounds = ({ id, data }) => {
    // console.log("CAPA", data.capacity); 

    const [selectionCategory, setSelectionCategory] = useState("type");
    const handleCategoryChange = (newCategory) => {
        // Reset selection
        var value = null;
        if (data.source[0].value !== value) {
            data.source[0].value = value;
            data.handleChange(id, "source_0", value);
        }
        // Update state
        setSelectionCategory(newCategory);
    }

    const handleChange = (changed_Id, changed_Value) => {
        console.log("changed_Id", changed_Id);
        console.log("changed_Value", changed_Value);

        var value = data.source[0].value;

        if (changed_Value === true) {
            // Add selection
            console.log("ADD");
            if (value === null) {
                // If null => set to empty
                value = "Node_Study_Bounds[]";
            }
            value = value.substring(0, value.indexOf("]")) + "|" + changed_Id + "|" + "]";
        }
        else if (changed_Value === false) {
            // Remove selection
            console.log("REMOVE");
            if (value !== null) {
                // Do something only if not null
                var changed_Index = value.indexOf("|" + changed_Id + "|");
                var changed_Length = changed_Id.length;
                var left_part = value.substring(0, changed_Index);
                var right_part = value.substring(changed_Index + changed_Length + 2);
                value = left_part + right_part;
                if (value === "Node_Study_Bounds[]" || value === "Node_Study_Bounds[" + selectionCategory + "__]") {
                    // Reset to null if empty
                    value = null;
                }
            }
        }

        // Add selectionCategory
        if (value !== null && value.indexOf("__") === -1) {
            var value = "Node_Study_Bounds[" + selectionCategory + "__" + value.substring(18, value.length);
        }

        if (data.source[0].value !== value) {
            data.source[0].value = value;
            data.handleChange(id, "source_0", value);
        }
    }

    const getNbSelected = (value) => {
        var totalNb = 0;
        if (value !== null && value !== "Node_Study_Bounds[]") {
            var matched = value.match(/\|/g);
            if (matched !== null) {
                totalNb = matched.length / 2;
            }
        }
        console.log("totalNb", totalNb);
        return totalNb;
    }



    return (
        <div id={"block_" + id} className="ruleeditor__node_container" style={{ maxWidth: "184px" }}>
            <div className="ruleeditor__node_title">
                <div className="ruleeditor__node_title_label">{data.label}</div>
                <div className="ruleeditor__node_title_close"><img src={close} alt="close_block" onClick={() => (data.handleDelete(id))} /></div>
            </div>
            <div className="ruleeditor__node_center">
                <div className="ruleeditor__node_description">Limites du terrain d'étude, sélectionnées par :</div>
                <div className="ruleeditor__node_body">
                    <div className="ruleeditor__node_category_container">
                        <div className={selectionCategory === "type" ? "ruleeditor__node_category_selected" : "ruleeditor__node_category_unselected"} onClick={() => { handleCategoryChange("type") }}>types</div>
                        <div className={selectionCategory === "item" ? "ruleeditor__node_category_selected" : "ruleeditor__node_category_unselected"} onClick={() => { handleCategoryChange("item") }}>éléments</div>
                    </div>
                    {/* TYPE LIST */}
                    {selectionCategory === "type" &&
                        <div>
                            {Object.keys(data.capacity.landBase.union.bounds_types).map((boundType, index1) => {
                                return (
                                    <>
                                        <div style={{ marginTop: "8px" }}></div>
                                        <div className="ruleeditor__input_title">{data.capacity.landBase.union.bounds_types[boundType].label}</div>
                                        <div>
                                            {Object.keys(data.capacity.landBase.union.bounds_types[boundType].subtypes).map((boundSubType, index2) => {
                                                var isSelected = false;
                                                if (data.source[0].value !== null && data.source[0].value.includes("|" + data.capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id + "|")) {
                                                    isSelected = true;
                                                }
                                                return (
                                                    // <div>{data.capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].label}</div>
                                                    <div key={index2} className="ruleeditor__input_checkbox_container"><input className="ruleeditor__input_checkbox" type="checkbox" id={"cb_subtype_" + data.capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id} onChange={(e) => { handleChange(data.capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id, e.target.checked) }} checked={isSelected} /><label className="ruleeditor__input_checkbox_label" htmlFor={"cb_subtype_" + data.capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].id}>{data.capacity.landBase.union.bounds_types[boundType].subtypes[boundSubType].label}</label></div>
                                                )
                                            })}
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    }
                    {/* ITEM LIST */}
                    {selectionCategory === "item" &&
                        <div>
                            <div style={{ marginTop: "8px" }}></div>
                            <div className="ruleeditor__input_title">Liste des limites</div>
                            {data.capacity.landBase.union.bounds_groups.map((boundGroup, index1) => {
                                var isSelected = false;
                                if (data.source[0].value !== null && data.source[0].value.includes("|" + boundGroup._id + "|")) {
                                    isSelected = true;
                                }
                                return (
                                    <div key={index1} className="ruleeditor__input_checkbox_container"><input className="ruleeditor__input_checkbox" type="checkbox" id={"cb_subtype_" + boundGroup._id} onChange={(e) => { handleChange(boundGroup._id, e.target.checked) }} checked={isSelected} /><label className="ruleeditor__input_checkbox_label" htmlFor={"cb_subtype_" + boundGroup._id}>Limite N°{index1 + 1}</label></div>
                                )
                            })}
                        </div>
                    }
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    {selectionCategory === "type" &&
                        <div className="ruleeditor__handle_source">{data.source[0].value ? getNbSelected(data.source[0].value) === 1 ? "1 type sélectionné" : getNbSelected(data.source[0].value) + " types sélectionnés" : "Aucun type sélectionné"}</div>
                    }
                    {selectionCategory === "item" &&
                        <div className="ruleeditor__handle_source">{data.source[0].value ? getNbSelected(data.source[0].value) === 1 ? "1 limite sélectionnée" : getNbSelected(data.source[0].value) + " limites sélectionnées" : "Aucune limite sélectionnée"}</div>
                    }
                    <div className={`ruleeditor__handle_source_type ${data.source[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.source[0].acceptance}</div>
                </div>
            </div>
            <Handle type="source" position={Position.Right} style={{ top: 'calc(100% - 11px - 10px)' }} id="source_0" />
        </div>
    );
};

export default Node_Study_Bounds;