// Import dependencies
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Import external functions
import reducers from './reducers';

// Import components
import App from './App';

// Import CSS
import './index.css';

// Create root
const root = createRoot(document.getElementById('root'));

// Create store from Redux
const store = createStore(reducers, compose(applyMiddleware(thunk)));

// Render
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);