// Import dependencies
import React, { useState, useEffect } from 'react';
import decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import CSS & assets
import logo from '../../../assets/logo.svg';
import ico_menu_open from '../../../assets/ico/ico_menu_open.svg';
import ico_menu_close from '../../../assets/ico/ico_menu_close.svg';
import './Navbar.css';

// Import variables
import { LOGOUT } from '../../../constants/actionTypes';

const Navbar = ({ origin }) => {

    // Set global functions
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // Set hooks
    // User
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    // Menu
    const [toggleMenu, setToggleMenu] = useState(false);
    // Background
    const [toggleBackground, setToggleBackground] = useState(false);
    const [toggleFullSize, setToggleFullSize] = useState(false);

    // On load function
    useEffect(() => {
        // Get users' token
        const token = user?.token;
        // If token exists
        if (token) {
            // Decode the token
            const decodedToken = decode(token);
            // Check if token is still valid
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                console.log("User token expired");
                logout();
            }
        }
    });

    // Set specific functions
    const logout = () => {
        // Dispatch log out action
        dispatch({ type: LOGOUT });
        // Unset user
        setUser(null);
    }

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setToggleBackground(true);
        }
        else {
            setToggleBackground(false);
        }
    }
    window.addEventListener('scroll', handleScroll);

    const ScrollToSection = (section_id) => {
        document.getElementById(section_id).scrollIntoView();
    }

    const handleSignUp = () => {
        setToggleFullSize(true);
        setTimeout(() => {
            navigate('/auth?type=signup' + (origin !== null ? '&origin=' + origin : ''));
        }, 1200);
    }

    const handleSignIn = () => {
        setToggleFullSize(true);
        setTimeout(() => {
            navigate('/auth?type=signin' + (origin !== null ? '&origin=' + origin : ''));
        }, 1200);
    }

    // RETURN
    return (
        <div className={`landing__navbar ${toggleBackground ? 'landing__navbar_bg' : ''} ${toggleFullSize ? 'landing__navbar_fullsize' : ''}`}>
            <div className="landing__navbar-container">
                <div className="landing__navbar-links">
                    <div className="landing__navbar-links_logo">
                        <img src={logo} alt='logo' />
                    </div>
                    <div className={`landing__navbar-links_container ${toggleFullSize ? 'landing__navbar-links_container_fullsize' : ''}`}>
                        <p onClick={() => { ScrollToSection("home") }}>Accueil</p>
                        <p onClick={() => { ScrollToSection("solution") }}>Solution</p>
                        <p onClick={() => { ScrollToSection("features") }}>Fonctionnalités</p>
                        <p onClick={() => { ScrollToSection("contact") }}>Contact</p>
                    </div>
                </div>
                <div className={`landing__navbar-sign ${toggleFullSize ? 'landing__navbar-sign_fullsize' : ''}`}>
                    {user ?
                        <button className="landing__navbar-sign_button" type="button" onClick={() => { navigate('/dashboard') }}><img className="landing__navbar-sign_userpp" src={user?.result?.avatar} alt="user_pp" /><div>Accéder à l'application</div></button>
                        :
                        <>
                            <p onClick={handleSignIn}>Se connecter</p>
                            <button type="button" onClick={handleSignUp}>S'inscrire</button>
                        </>
                    }
                </div>
                <div className="landing__navbar-menu">
                    {toggleMenu
                        ? <img src={ico_menu_close} alt="menu_close" onClick={() => setToggleMenu(false)} />
                        : <img src={ico_menu_open} alt="menu_open" onClick={() => setToggleMenu(true)} />}
                    {toggleMenu && (
                        <div className="landing__navbar-menu_container scale-up-center">
                            <div className="landing__navbar-menu_container-links">
                                <p onClick={() => { ScrollToSection("home") }}>Accueil</p>
                                <p onClick={() => { ScrollToSection("solution") }}>Solution</p>
                                <p onClick={() => { ScrollToSection("features") }}>Fonctionnalités</p>
                                <p onClick={() => { ScrollToSection("contact") }}>Contact</p>
                            </div>
                            <div className="landing__navbar-menu_container-links-sign">
                                {user ?
                                    <button type="button" onClick={() => { navigate('/dashboard') }}>Accéder à l'application</button>
                                    :
                                    <>
                                        <p onClick={handleSignIn}>Se connecter</p>
                                        <button type="button" onClick={handleSignUp}>S'inscrire</button>
                                    </>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;