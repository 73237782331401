// Import dependencies
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from '@turf/turf';

// Import external functions
import * as map_helpers from '../../app/map/Map_helpers';

// CSS
import './MiniMap.css'
import ico_map_measure from '../../../assets/ico/ico_map_measure.svg';
import ico_map_measurestop from '../../../assets/ico/ico_map_measurestop.svg';

// Set mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoidGlqb3giLCJhIjoiY2s4azRibzF6MDEzZDNuczduZW1jbzB0biJ9.JAGdImb16q69S3kEn0KcCg';

var study_popup = null;
var pointMin = [100, 100];
var pointMax = [-100, -100];

const MiniMap = ({ capacityFullList }) => {

    // __________ MAP PARAMETERS

    // MAP : Global variables
    const miniMapContainer = useRef(null);
    const miniMap = useRef(null);
    const draw = useRef(null);

    const [isMapLoaded, setIsMapLoaded] = useState(false);



    // MAP : On load 
    useEffect(() => {
        // Check if map is already initialized
        if (miniMap.current) return;
        // If not => Initialize it
        miniMap.current = new mapboxgl.Map({
            container: miniMapContainer.current,
            style: 'mapbox://styles/tijox/cke0dn2v61bfq19lb2p69mei2',
            center: [2.2, 46.4],
            zoom: 4,
            pitch: 0
        });
        // Set interactions
        polygon_Interaction();

        // miniMap.current.addControl(new mapboxgl.FullscreenControl());
        miniMap.current.addControl(new mapboxgl.NavigationControl());

        // Set loaded state and ready state
        miniMap.current.on('load', () => {
            setIsMapLoaded(true);
        });

        // DRAW
        // draw.current = new MapboxDraw({
        //     displayControlsDefault: false,
        //     // Select which mapbox-gl-draw control buttons to add to the map.
        //     controls: {
        //         line_string: true,
        //         polygon: true,
        //         trash: true
        //     },
        //     // Set mapbox-gl-draw to draw by default.
        //     // The user does not have to click the polygon control button first.
        //     // defaultMode: 'draw_polygon'
        // });
        // miniMap.current.addControl(draw.current);
        // miniMap.current.on('draw.create', draw_create);
        // miniMap.current.on('draw.delete', draw_delete);
        // miniMap.current.on('draw.update', draw_update);
        // miniMap.current.on('draw.modechange', draw_change);
    });

    // DRAW
    // const [drawMarkers, setDrawMarkers] = useState([]);

    // const [isMeasureVisible, setIsMeasureVisible] = useState(false);
    // const handleMeasureSwitch = () => {
    //     if (isMeasureVisible === true) {
    //         draw_delete_all();
    //         draw.current.changeMode("simple_select");
    //     }
    //     setIsMeasureVisible(!isMeasureVisible);
    // }
    // const [isMeasureLength, setIsMeasureLength] = useState(false);
    // const startMeasureLength = () => {
    //     setIsMeasureLength(true);
    //     draw_delete_all();
    //     draw.current.changeMode("draw_line_string");
    // }
    // const [isMeasureArea, setIsMeasureArea] = useState(false);
    // const startMeasureArea = () => {
    //     setIsMeasureArea(true);
    //     draw_delete_all();
    //     draw.current.changeMode("draw_polygon");
    // }


    // const draw_create = () => {
    //     const draw_data = draw.current.getAll();
    //     console.log("DRAW CREATE", draw_data);
    //     if (draw_data?.features.length > 0 && draw_data?.features[0]?.geometry?.type === "LineString") {
    //         var length = turf.length(draw_data?.features[0]) * 1000;
    //         var midPoint = turf.along(draw_data?.features[0], length / 2000);
    //         var markers = [];
    //         var el = document.createElement('div');
    //         el.className = 'marker';
    //         el.innerHTML = '<div style="padding:4px 8px;font-family:Manrope;color:#FFF;background-color:#062134;border-radius:6px"><span style="">Longueur : ' + length.toFixed(2) + ' m</span></div>';
    //         markers.push(new mapboxgl.Marker(el, { offset: [0, -20] })
    //             .setLngLat(turf.getCoord(midPoint))
    //             .addTo(miniMap.current))
    //         setDrawMarkers(markers);
    //     }
    //     if (draw_data?.features.length > 0 && draw_data?.features[0]?.geometry?.type === "Polygon") {
    //         var area = turf.area(draw_data?.features[0]);
    //         var midPoint = turf.centroid(draw_data?.features[0]);
    //         var markers = [];
    //         var el = document.createElement('div');
    //         el.className = 'marker';
    //         el.innerHTML = '<div style="padding:4px 8px;font-family:Manrope;color:#FFF;background-color:#062134;border-radius:6px"><span style="">Surface : ' + area.toFixed(2) + ' m²</span></div>';
    //         markers.push(new mapboxgl.Marker(el)
    //             .setLngLat(turf.getCoord(midPoint))
    //             .addTo(miniMap.current))
    //         setDrawMarkers(markers);
    //     }
    // }
    // const draw_delete = () => {
    //     const draw_data = draw.current.getAll();
    //     console.log("DRAW DELETE", draw_data);
    // }
    // const draw_change = (e) => {
    //     console.log("DRAW CHANGE", e);
    //     if (e.mode !== "draw_line_string") {
    //         setIsMeasureLength(false);
    //     }
    //     if (e.mode !== "draw_polygon") {
    //         setIsMeasureArea(false);
    //     }
    // }
    // const draw_update = () => {
    //     const draw_data = draw.current.getAll();
    //     console.log("DRAW UPDATE", draw_data);
    // }
    // const draw_delete_all = () => {
    //     console.log("DRAW", draw.current);
    //     draw.current.deleteAll();
    //     // Remove markers
    //     for (var i = 0; i < drawMarkers.length; i++) {
    //         drawMarkers[i].remove();
    //     }
    // }


    // Set polygons
    useEffect(() => {
        if (isMapLoaded === true && capacityFullList.length > 0 && capacityFullList[0] !== "fetching") {
            // console.log("SHOW POLY ON MINI MAP", capacityFullList);
            polygon_Show(capacityFullList);
        }
    }, [capacityFullList, isMapLoaded]);

    const polygon_Interaction = () => {
        miniMap.current.on('mouseenter', 'layerStudy', () => { miniMap.current.getCanvas().style.cursor = 'pointer' });
        miniMap.current.on('mouseleave', 'layerStudy', () => { miniMap.current.getCanvas().style.cursor = '' });
        miniMap.current.on('click', 'layerStudy', (e) => { polygon_Click(e) });
        miniMap.current.on('mouseenter', 'layerStudy_center', () => { miniMap.current.getCanvas().style.cursor = 'pointer' });
        miniMap.current.on('mouseleave', 'layerStudy_center', () => { miniMap.current.getCanvas().style.cursor = '' });
        miniMap.current.on('click', 'layerStudy_center', (e) => { polygon_Click(e) });
    }

    const polygon_Click = (e) => {
        console.log("CLICKED", e.features[0]);
        console.log("CLICKED", e.lngLat);

        var title = '<span style="color:#062134;font-size:15px;font-weight:700"><strong>' + e.features[0].properties.title + '</strong></span><br>';
        var description = "";
        if (e.features[0].properties.description !== "") {
            description = '<span style="color:#062134;font-weight:500;">' + e.features[0].properties.description + '</span><br>';
        }

        // Add popup to map
        study_popup = new mapboxgl.Popup({ offset: [0, -10] })
            // .setLngLat(e.lngLat)
            .setLngLat({
                lat: e.features[0].properties.center_lat,
                lng: e.features[0].properties.center_lng,
            })
            .setHTML('<div style="font-family:Manrope">' + title + description + '<div style="background-color:#1ecd97;color:#FFF;font-weight:600;padding:3px 8px;width:fit-content;border-radius:20px"><a href="/capastudy/' + e.features[0].properties.id + '" target="">' + "Ovrir l'étude" + '</a></div></div>')
            .addTo(miniMap.current);

        // Fit miniMap
        // var offset = 0.0002;
        // miniMap.current.fitBounds([
        //     [e.features[0].properties.bbox_x_min - offset, e.features[0].properties.bbox_y_min - offset], // southwestern corner of the bounds
        //     [e.features[0].properties.bbox_x_max + offset, e.features[0].properties.bbox_y_max + offset], // northeastern corner of the bounds
        // ])
        miniMap.current.flyTo({
            pitch: 50,
            center: [e.features[0].properties.center_lng, e.features[0].properties.center_lat],
            zoom: 17,
            essential: true
        });
    }

    const polygon_Show = (list) => {
        var polyList = [];
        var boundList = [];
        var centerList = [];
        pointMin = [100, 100];
        pointMax = [-100, -100];
        list.forEach(poly => {
            if (poly?.simplified?.poly?.bounds_groups && poly.simplified.poly.bounds_groups.length > 0) {
                var polyCoords = [];
                poly.simplified.poly.bounds_groups.forEach(bound => {
                    polyCoords.push(bound.start_coord_global);
                    if (bound?.start_coord_global && bound.start_coord_global[0] < pointMin[0]) {
                        pointMin[0] = bound.start_coord_global[0];
                    }
                    if (bound?.start_coord_global && bound.start_coord_global[1] < pointMin[1]) {
                        pointMin[1] = bound.start_coord_global[1];
                    }
                    if (bound?.start_coord_global && bound.start_coord_global[0] > pointMax[0]) {
                        pointMax[0] = bound.start_coord_global[0];
                    }
                    if (bound?.start_coord_global && bound.start_coord_global[1] > pointMax[1]) {
                        pointMax[1] = bound.start_coord_global[1];
                    }
                })
                polyCoords.push(poly.simplified.poly.bounds_groups[0].start_coord_global);
                var polygon = turf.polygon([polyCoords]);
                var polygon_bbox = turf.bbox(polygon);
                var polygon_center = turf.centerOfMass(polygon);
                var polygon_properties = {
                    title: poly.title,
                    description: poly.description,
                    id: poly._id,
                    center_lng: turf.getCoord(polygon_center)[0],
                    center_lat: turf.getCoord(polygon_center)[1],
                    bbox_x_min: turf.bbox(polygon)[0],
                    bbox_y_min: turf.bbox(polygon)[1],
                    bbox_x_max: turf.bbox(polygon)[2],
                    bbox_y_max: turf.bbox(polygon)[3],
                }
                polygon.properties = polygon_properties;
                polygon_center.properties = polygon_properties;
                polyList.push(polygon);
                boundList.push(turf.polygonToLine(polygon));
                centerList.push(polygon_center);
            }
        });
        // Get offset for min and max
        var line = turf.lineString([pointMin, pointMax]);
        var bbox = turf.bbox(line);
        var bboxPolygon = turf.bboxPolygon(bbox);
        var buffered = turf.buffer(bboxPolygon, 20, { steps: 1 });
        var bbox2 = turf.bbox(buffered);
        pointMin = [bbox2[0], bbox2[1]];
        pointMax = [bbox2[2], bbox2[3]];
        // console.log("min", pointMin);
        // console.log("max", pointMax);
        // Remove layers
        if (miniMap.current.getLayer('layerStudy')) {
            miniMap.current.removeLayer('layerStudy');
        }
        if (miniMap.current.getLayer('layerStudy_bounds')) {
            miniMap.current.removeLayer('layerStudy_bounds');
        }
        if (miniMap.current.getLayer('layerStudy_center')) {
            miniMap.current.removeLayer('layerStudy_center');
        }
        // Remove source
        if (miniMap.current.getSource('sourceStudy')) {
            miniMap.current.removeSource('sourceStudy');
        }
        // Add source
        miniMap.current.addSource('sourceStudy', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: polyList.concat(boundList.concat(centerList)),
            }
        });
        // Add layer polys
        miniMap.current.addLayer({
            id: 'layerStudy',
            type: 'fill',
            source: 'sourceStudy',
            paint: {
                'fill-color': '#062134',
                'fill-opacity': 0.2,
            },
            filter: ['in', '$type', 'Polygon']
        }, 'building-extrusion');
        // Add layer bounds
        miniMap.current.addLayer({
            id: 'layerStudy_bounds',
            type: 'line',
            source: 'sourceStudy',
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': '#062134',
                'line-width': 2.5,
            },
            filter: ['in', '$type', 'LineString']
        }, 'building-extrusion');
        // Add layer centers
        miniMap.current.addLayer({
            id: 'layerStudy_center',
            type: 'circle',
            source: 'sourceStudy',
            // filter: ['!', ['has', 'point_count']],
            filter: ['in', '$type', 'Point'],
            paint: {
                'circle-color': '#062134',
                'circle-radius': 6,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
            }
        });

        // Fit miniMap
        polygon_fit();
    }

    const polygon_fit = () => {
        // remove popup
        if (study_popup !== null) {
            study_popup.remove();
            study_popup = null;
        }
        // Move
        if (pointMin[0] !== 100) {
            var offset = 0.05;

            miniMap.current.flyTo({
                pitch: 0,
                essential: true
            });

            setTimeout(() => {
                miniMap.current.fitBounds([
                    [pointMin[0] - offset, pointMin[1] - offset], // southwestern corner of the bounds
                    [pointMax[0] + offset, pointMax[1] + offset], // northeastern corner of the bounds
                ]);
            }, 500);
        }
    }




    return (
        <div id="miniMap" className="minimap__container">
            {/* <div onClick={() => { draw_delete_all() }}>DELETE ALL</div> */}
            <div id="map_button_container_3" className="app__map_button_container3">
                {/* <div style={{ position: "relative" }}>
                    <div className="app__map_button_single" style={{ left: "260px" }} onClick={handleMeasureSwitch}>
                        <div className="app__map_button_img"><img src={isMeasureVisible ? ico_map_measurestop : ico_map_measure} alt="ico_map_layers" /></div>
                    </div>
                    {isMeasureVisible &&
                        <div className={`app__map_button_container_trans ${window.innerWidth > 550 ? "scale-up-right" : "scale-up-left"}`} style={{}}>
                            <div className={`app__map_button_single_inside ${isMeasureLength === true ? "app__map_button_active" : ""}`} onClick={startMeasureLength}>LONGUEUR</div>
                            <div className={`app__map_button_single_inside ${isMeasureArea === true ? "app__map_button_active" : ""}`} onClick={startMeasureArea}>SURFACE</div>
                        </div>
                    }
                </div> */}
            </div>
            <div ref={miniMapContainer} className="minimap__map" onMouseLeave={() => { polygon_fit() }} />
            {/* <link rel="stylesheet" href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.0/mapbox-gl-draw.css" type="text/css" /> */}
        </div>
    );
};

export default MiniMap;