// Import dependencies
import React from 'react';
import moment from 'moment';
import 'moment/locale/fr.js';

// Import CSS & Assets
import './Modification.css';

const Modification = ({ modification, userData, position, total, isDetailed, setIsDetailed }) => {

    // Set moment to french time
    moment.locale('fr');

    // Defines variables
    var time_text = "Modifée";
    if (position === "first") { time_text = "Créée" }
    else if (position === "last") { time_text = "Modifiée pour la dernière fois" }

    // Functions
    const handleSimpleLink = (link) => {
        document.location.href = link;
    }

    return (
        <>
            {(position === "last" && total > 2) &&
                <div className={`app__modification_showmore_container ${isDetailed ? "app__modification_showmore_container_hidden" : ""}`}>
                    <div className="app__modification_line_container">
                        <div className="app__modification_showmore_line_top"></div>
                        <div className="app__modification_showmore_line_point-1"></div>
                        <div className="app__modification_showmore_line_point-2"></div>
                        <div className="app__modification_showmore_line_point-3"></div>
                        <div className="app__modification_showmore_line_bottom"></div>
                    </div>
                    <div className="app__modification_showmore_text" onClick={() => { setIsDetailed(true) }}>
                        Afficher le détail des modifications ({total} actions)
                    </div>
                </div>
            }
            <div className={`app__modification ${isDetailed ? "" : position === "middle" ? "app__modification_hidden" : ""}`}>
                <div className={`app__modification_line_container ${total === 1 ? "app__modification_line_container_hidden" : ""}`}>
                    <div className="app__modification_line_point"></div>
                    {position !== "last" &&
                        <div className="app__modification_line_bottom"></div>
                    }
                    {position !== "first" &&
                        <div className="app__modification_line_top"></div>
                    }
                </div>
                <div className="app__modification_container">
                    <div className="app__modification_user_container">
                        <div className="app__modification_user_img_container">
                            <img src={userData.avatar} alt="user_pp" />
                        </div>
                        <div className="app__modification_user_data_container">
                            <div className="app__modification_user_data_time">{time_text} {moment(modification.time).startOf().fromNow()}</div>
                            <div className="app__modification_user_data_person">par {userData.name}</div>
                        </div>
                    </div>
                    <div className={`app__modification_comment_container ${(position === "last" || total === 1) ? "app__modification_comment_container_nomargin" : ""}`}>
                        <div className="app__modification_comment_action">
                            {modification?.action}
                        </div>
                        {(modification?.value && !modification?.data) &&
                            <div className="app__modification_comment_value">
                                {modification?.value}
                            </div>
                        }
                        {(modification?.value && modification?.data?.type === "simple-link") &&
                            <div className="app__modification_comment_value_link" onClick={() => { handleSimpleLink(modification?.data?.param) }}>
                                {modification?.value}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modification;