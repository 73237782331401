// Import dependencies
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import variables
import { LOGOUT } from '../../../constants/actionTypes';

// Import CSS & assets
import logo from '../../../assets/logo.svg';
import user_pp from '../../../assets/user_pp_default.png';

const NotAllowed = ({ version, environment }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = (reason) => {
        // console.log(window.location);
        // Dispatch log out action
        dispatch({ type: LOGOUT });
        // Go back to home page
        navigate('/auth?type=signin&source=' + window.location.pathname);
    }

    // Set hooks
    // User
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    // User advanced info
    const [toogleAdvancedInfo, setToogleAdvancedInfo] = useState(false);


    function prepareEmail() {
        var subject = "Demande de passage Premium";
        var body = "Bonjour,\r\n\r\nJe souhaite pouvoir profiter de toutes les fonctionnalités de PropLab.\r\n\r\nMerci de me recontacter à ce sujet.";
        var uri = "mailto:contact@fractal-buildings.com?subject=";
        uri += encodeURIComponent(subject);
        uri += "&body=";
        uri += encodeURIComponent(body);
        window.open(uri);
    }

    return (
        <div className='auth__auth'>
            <div className='auth__header'>
                <div className="auth__header-container">
                    <div className="auth__header-logo" onClick={() => {navigate('/')}}>
                        <div className="auth__header-logo_box">
                            <img src={logo} alt='logo' />
                        </div>
                    </div>
                    <div onMouseLeave={() => { setToogleAdvancedInfo(false) }} className="app__header_info_user_container">
                        <div onClick={() => { setToogleAdvancedInfo(true) }} className={`app__header_info_user_basic_container ${toogleAdvancedInfo ? 'app__header_info_user_basic_container_focus' : ''}`}>
                            <div className={`app__header_info_user_basic_text ${toogleAdvancedInfo ? 'app__header_info_user_basic_text_focus' : ''}`}>
                                <div className="app__header_info_user_basic_text_name" style={{color:'#FFF'}}>
                                    {user?.result?.firstName}
                                </div>
                                <div className="app__header_info_user_basic_text_group" style={{color:'#FFF'}}>
                                    {(user?.teams && user?.teams.length > 0) ?
                                        user.teams[0].name :
                                        "Compte gratuit"
                                    }
                                </div>
                            </div>
                            <div className="app__header_info_user_basic_img">
                                {user?.result ?
                                    <img src={user?.result?.avatar} alt="user_pp" />
                                    :
                                    <img src={user_pp} alt="user_pp" />
                                }
                            </div>
                        </div>
                        {toogleAdvancedInfo &&
                            <div className={`app__header_info_user_container_visible scale-up-topright app__header__info_dark`}>
                                <div className={`app__header_info_user_advanced_container ${toogleAdvancedInfo ? 'app__header_info_user_advanced_visible' : 'app__header_info_user_advanced_hidden'}`}>
                                    <div className={`app__header_info_user_advanced_spacer app__header_info_user_advanced_spacer_dark`}></div>
                                    <div onClick={logout} className="app__header_info_user_advanced_button">
                                        Déconnexion
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='auth__body_container auth__body_container_visible'>
                <div className="auth__activateform">
                    <h1>Bienvenue {user?.result?.firstName} ! 👋</h1>
                    <div className="app__sectiondata_widget_spacer_30"></div>
                    <h4 className='auth__activateform_message-error'>Pour accéder à toutes les fonctionnalités de PropLab vous devez avoir un compte Premium.</h4>
                    <h4 className='auth__activateform_message-error'>Suite à votre inscription notre équipe commerciale va vous contacter.</h4>
                    <div className="app__sectiondata_widget_spacer_10"></div>
                    <div className="combi__launch_button" onClick={() => { prepareEmail() }}>Contacter l'équipe commerciale</div>
                </div>
            </div>
        </div>
    );
};

export default NotAllowed;