// Import dependencies
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { teamCreate } from '../../../actions/teams';

// Import components
import NewTeam from './NewTeam';
import Team from './Team';
import Input from '../../common/input/Input';


// Import CSS & assets
import './Teams.css';

const Teams = ({ teams, teamsInactive, updateTeams, var_users, var_usersMailIndex, teamFilter, setTeamFilter }) => {

    // Set global functions
    const dispatch = useDispatch();

    // Hooks
    const [addingTeam, setAddingTeam] = useState(false);
    const [newTeam, setNewTeam] = useState("");
    const [showInactive, setShowInactive] = useState(false);
    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    // Error
    const [errorMessage, setErrorMessage] = useState('');

    // Set specific functions
    const handleChange_newTeam = (e) => {
        setNewTeam(e.target.value);
    }
    // Loading
    const handleLoading = () => {
        setIsLoading(true);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }
    const handleLoadedSuccess = () => {
        setIsLoading(false);
        setIsLoadedSuccess(true);
        // Reset button & clear form
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
            clear();
        }, 500);
        // Fetch all teams again to update UI
        updateTeams();
    }
    const handleSuccess = () => {
        console.log("SUCCESS");
        handleLoadedSuccess();
    }
    const handleLoadedFail = () => {
        setIsLoading(false);
        setIsLoadedFail(true);
        // Reset button
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
        }, 4000);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleLoadedFail();
    }
    // Submit form
    const handleSubmit = (e) => {
        e.preventDefault(); // no refresh

        if (newTeam !== "") {
            // Set loading button
            handleLoading();
            // Check all required fields are not empty
            console.log("SUBMIT", newTeam);
            dispatch(teamCreate({ name: newTeam }, handleSuccess, handleFail));
        }
    }
    // Clear
    const clear = () => {
        setAddingTeam(false);
        setNewTeam("");
        setIsLoading(false);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }


    return (
        <>
            <div className="app__admin_teams_title_container">
                <div className="app__admin_title">Équipes</div>
                <div className={`app__admin_team_button ${addingTeam ? "app__admin_team_button_hide" : ""}`} style={{ width: '120px', marginRight: '10px' }} onClick={() => { setAddingTeam(true) }}>+ Nouvelle équipe</div>
                {/* <NewTeam updateTeams={updateTeams} /> */}
            </div>
            <div className={`admin__teams_container ${addingTeam ? "admin__teams_container_hide" : ""}`}>
                {teams.map((team) => {
                    if (showInactive === true || (showInactive === false && (!team?.isInactive || team?.isInactive === false))) {
                        return (
                            <Team key={team._id} team={team} updateTeams={updateTeams} var_users={var_users} var_usersMailIndex={var_usersMailIndex} teamFilter={teamFilter} setTeamFilter={setTeamFilter} />
                        )
                    }
                })}
                {teamsInactive > 0 &&
                    <div className="app__admin_teams_inactive" onClick={() => { setShowInactive(!showInactive) }}>-- {showInactive === false ? "Afficher" : "Masquer"} {teamsInactive === 1 ? "l'équipe inactive" : "les " + teamsInactive + " équipes inactives"} --</div>
                }
            </div>
            {/* MODAL */}
            {addingTeam === true &&
                <div className="app__admin_teams_modal">
                    Ajouter une nouvelle équipe
                    <div className="app__admin_team_modal_input" style={{ marginTop: '10px' }}>
                        <Input type='text' name='new_member' label="Nom de la nouvelle équipe" value={newTeam} handleChange={handleChange_newTeam} autofocus={true} />
                    </div>
                    <div className="app__admin_team_modal_buttons">
                        <div className={`app__admin_team_modal_button ${isLoading ? "app__admin_team_modal_button_loading" : ""} ${isLoadedSuccess ? "app__admin_team_modal_button_loading_success" : ""} ${isLoadedFail ? "app__admin_team_modal_button_loading_fail" : ""}`} onClick={handleSubmit}>{isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : "Ajouter"}</div>
                        <div className="app__admin_team_modal_button_red" onClick={() => { clear() }}>Annuler</div>
                    </div>
                </div>
            }
        </>
    );
};

export default Teams;