// Import dependencies
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capaGetById, capaUpdate } from '../../../actions/capa';
import moment from 'moment';
import 'moment/locale/fr.js';



// Import components
import Header from '../../../components/app/header/Header';
import Navbar from '../../../components/app/navbar/Navbar';
import Map from '../../../components/app/map/Map';
import Input from '../../../components/common/input/Input';
import InputMultiLine from '../../../components/common/inputMultiLine/InputMultiLine';


// Import CSS & Assets
import './Capa.css';
import ico_home_outline from '../../../assets/ico/ico_home_outline.svg';
import ico_home_fill from '../../../assets/ico/ico_home_fill.svg';


const Capa = () => {

    // Set moment to french time
    moment.locale('fr');

    // Set global funcitons
    const dispatch = useDispatch();

    // Global variables
    const { id } = useParams();
    const layout_data = {
        layout: [
            {
                title_long: "Informations générales",
                title_short: "GENERAL",
                ico_outline: ico_home_outline,
                ico_fill: ico_home_fill,
                isVisible: true
            },
            {
                title_long: "Terrain",
                title_short: "TERRAIN",
                ico_outline: ico_home_outline,
                ico_fill: ico_home_fill,
                isVisible: true
            },
            {
                title_long: "Données contextuelles",
                title_short: "CONTEXTE",
                ico_outline: ico_home_outline,
                ico_fill: ico_home_fill,
                isVisible: false
            },
        ]
    };
    const isApp = true;

    // Set hooks
    const [navigation, setNavigation] = useState(layout_data);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [capacity, setCapacity] = useState();
    const [currentSection, setCurrentSection] = useState(0);
    const [isHeaderBanner, setIsHeaderbanner] = useState(checkHeaderBanner());
    const [currentStep, setCurrentStep] = useState(0);
    const [userCreation, setUserCreation] = useState({
        name: "",
        img: ""
    });
    const [userModification, setUserModification] = useState({
        name: "",
        img: ""
    });
    const [landSelectionList, setLandSelectionList] = useState({ lands: [], area: 0 });

    // Set specific funciton
    // On load : Get capacity from id
    useEffect(() => {
        dispatch(capaGetById(id, handleCapaGetSuccess, handleCapaGetFail));
    }, []);

    // Get capa data
    const handleCapaGetSuccess = (data) => {
        console.log("SUCCESS RETURN", data);
        data.toSave = false;
        setCapacity(data);
        changeCurrentStep(data.step);
        // Get user creation
        if (data.creationUserId === user?.result?._id) {
            setUserCreation({
                name: "par vous",
                img: user?.result?.avatar
            });
        }
        else {
            var user_creation = null;
            if (user.users && user.users.length > 0) {
                for (var i = 0; i < user.users.length; i++) {
                    if (data.creationUserId === user.users[i]["_id"]) {
                        user_creation = user.users[i];
                    }
                }
            }
            if (user_creation !== null) {
                setUserCreation({
                    name: "par " + user_creation.firstName,
                    img: user_creation.avatar
                });
            }
        }
        // Get user modification
        if (data.modificationUserId === user?.result?._id) {
            setUserModification({
                name: "par vous",
                img: user?.result?.avatar
            });
        }
        else {
            var user_modification = null;
            if (user.users && user.users.length > 0) {
                for (var i = 0; i < user.users.length; i++) {
                    if (data.modificationUserId === user.users[i]["_id"]) {
                        user_modification = user.users[i];
                    }
                }
            }
            if (user_modification !== null) {
                setUserModification({
                    name: "par " + user_modification.firstName,
                    img: user_modification.avatar
                });
            }
        }
    }
    // Fail on capa data load
    const handleCapaGetFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    // Check banner message
    function checkHeaderBanner() {
        if (user?.result?.emailValidation === false || !(user?.teams && user?.teams.length > 0)) {
            return true;
        }
        else {
            return false;
        }
    }
    // Change section
    const updateCurrentSection = (value) => {
        setCurrentSection(value);
    }
    // Change currentStep
    const changeCurrentStep = (newStep) => {
        setCurrentStep(newStep);
        // Update navbar
        if (newStep > 0) {
            layout_data["layout"][2]["isVisible"] = true;
        }
        setNavigation(layout_data);
        // Update UI
        if (newStep > 0) {
        }
    }
    // Change capacity
    useEffect(() => {
        console.log("UPDATED CAPACITY");
        textareaResize();
        // Save
        if (capacity?.toSave && capacity.toSave === true) {
            console.log("TO SAVE");
            saveCapacity();
        }
    }, [capacity]);
    // Save capacity
    const saveCapacity = () => {
        console.log("UPDATING CAPA", capacity);
        dispatch(capaUpdate(capacity._id, { capacity, userId: user.result._id }, handleUpdateSuccess, handleUpdateFail));
    }
    const handleUpdateSuccess = (data) => {
        console.log("SAVE SUCCESS", data);
        var capacity_data = data.result;
        capacity_data.toSave = false;
        setCapacity(capacity_data);
        changeCurrentStep(capacity_data.step);
    }
    const handleUpdateFail = (error) => {
        console.log("SAVE FAIL", error.response.data.message);
    }

    // _________________________________________________ INFOS GENERALES
    // Change Title
    const titleHandleChange = (e) => {
        setCapacity({ ...capacity, title: e.target.value, toSave: false });
    }

    // Change Description
    const descriptionHandleChange = (e) => {
        setCapacity({ ...capacity, description: e.target.value, toSave: false });
    }

    // Resize Description
    const textareaResize = (e) => {
        var element = document.getElementById('capa_description');
        element.style.height = 'auto';
        element.style.height = (element.scrollHeight) + 'px';
    }




    // _________________________________________________ TERRAIN
    // Validate lands
    const handleLandValidation = () => {
        setCapacity({ ...capacity, landBase: landSelectionList, step: 1, toSave: true });
        console.log("Updated");
    }


    // DRAGGABLE ELEMENT
    const [dragPosition, setDragPosition] = useState("middle");
    const [dragData, setDragData] = useState({
        diffX: 0,
        diffY: 0,
        dragging: false
    });
    const [mapResize, setMapResize] = useState(0);
    // Mousemove
    // useEffect(() => {
    //     window.addEventListener('mousemove', dragging, { passive: true });
    //     return () => {
    //         window.removeEventListener('mousemove', dragging);
    //     };
    // }, []);
    const dragStart = (e) => {
        document.getElementById('section_top').classList.remove = "app__placeholder_line_container_transotion";

        var dX = e.screenX - document.getElementById('section_top').getBoundingClientRect().left;
        var dY = e.screenY - document.getElementById('section_top').getBoundingClientRect().top;
        console.log("Diff:", { dX, dY });
        console.log("ElementX:", document.getElementById('section_top').getBoundingClientRect().left);
        console.log("ElementY:", document.getElementById('section_top').getBoundingClientRect().top);
        setDragData({
            diffX: dX,
            diffY: dY,
            dragging: true
        });
    }
    const dragTest = (e) => {
        console.log("DragTest:", e.screenY);
    }
    const dragging = (e) => {
        if (dragData.dragging === true) {
            var valX = e.screenX - dragData.diffX;
            var valY = e.screenY - dragData.diffY;
            console.log("Drag:", { valX, valY });
            if (valY <= 80) {

            }
            else if (valY >= window.innerHeight - 30) {

            }
            else {
                document.getElementById('section_top').style.top = valY + "px";
                document.getElementById('section_map').setAttribute("style", "height:" + (valY - 80) + "px");
                document.getElementById('section_master').setAttribute("style", "top:" + (valY + 30) + "px; height:" + (window.innerHeight - valY - 30) + "px");
                setMapResize(valY);
            }
            // Show & hide elements
            if (valY <= 100) {
                document.getElementById('section_map').classList.add("app__map_hidden");
            }
            else {
                document.getElementById('section_map').classList.remove("app__map_hidden");
            }

        }
    }
    const dragEnd = (e) => {
        setDragData({
            diffX: 0,
            diffY: 0,
            dragging: false
        });
        var valX = e.screenX - dragData.diffX;
        var valY = e.screenY - dragData.diffY;
        if (valY <= 100) {
            console.log("Stick to top");
            setDragPosition("top");
            document.getElementById('section_top').classList.add = "app__placeholder_line_container_transotion";
            document.getElementById('section_top').style.top = "80px";
        }
        else if (valY >= window.innerHeight - 50) {
            console.log("Stick to bottom");
            setDragPosition("bottom");
        }
    }


    return (
        <div>
            <Navbar layout={navigation.layout} isApp={isApp} currentSection={currentSection} updateCurrentSection={updateCurrentSection} />
            <Header title="Etude Capacitaire" isApp={isApp} />
            <Map isHeaderBanner={isHeaderBanner} currentSection={currentSection} currentStep={currentStep} capacity={capacity} setLandSelectionList={setLandSelectionList} mapResize={mapResize} />
            {/* SECTION PLACEHOLDER FOR MOBILE VIEW */}
            <div id="section_top" className="app__placeholder_line_container" onMouseDown={dragStart} onTouchStart={dragStart} onMouseMove={dragging} onTouchMove={dragging} onMouseUp={dragEnd} onTouchEnd={dragEnd}>
                <div className="app__placeholder_line"></div>
            </div>
            {/* SECTION MASTER CONTAINER */}
            <div id="section_master" className="app__section_master_container">
                {/* SECTION 0 */}
                {/* <div id='section_0' className={`app__section ${isApp ? 'app__padding_small' : 'app__padding_large'} ${isHeaderBanner ? 'app__padding_header_large' : 'app__padding_header_small'}`}> */}
                <div id='section_0' className="app__section">
                    {/* <div className="app__section_container app_section_container_side"> */}
                    <div className="app__section_container">
                        {/* <div className={`app__section_title_container ${isHeaderBanner ? 'app__top_header_large' : 'app__top_header_small'}`}> */}
                        <div className="app__section_title_container">
                            <h3 onClick={() => changeCurrentStep(0)}>{navigation.layout[0]['title_long']} - STEP {currentStep}</h3>
                            <div className="app__section__title_bar"></div>
                        </div>
                        <div className="app__section_body_container">
                            <div className="app__section_body2">
                                <div className="app__section_body_title_container">
                                    <div className="app__section_body_1_container_first app__widget2">
                                        <div className="app__widget2_title">Titre</div>
                                        <div className="app__widget2_input_container">
                                            <input className="app__widget2_input" type="text" id="capa_title" value={capacity?.title} onChange={titleHandleChange} onBlur={() => { saveCapacity() }} />
                                        </div>
                                    </div>
                                    <div className="app__section_body_1_container_first app__widget2">
                                        <div className="app__widget2_title">Description</div>
                                        <div className="app__widget2_input_container">
                                            <textarea className="app__widget2_multinput" id="capa_description" placeholder="Vous pouvez entrer une description pour cette étude capacitaire" onInput={textareaResize} value={capacity?.description} onChange={descriptionHandleChange} onBlur={() => { saveCapacity() }}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="app__section_body_user_container">
                                    Crée {moment(capacity?.creationDate).startOf().fromNow()} {userCreation.name}
                                    {userCreation.img !== "" &&
                                        <img src={userCreation.img} alt="user_img" />
                                    }, et modifiée pour la dernière fois {moment(capacity?.modificationDate).startOf().fromNow()} {userModification.name}
                                    {userModification.img !== "" &&
                                        <img src={userModification.img} alt="user_img" />
                                    }.
                                    {/* <div>
                                    Crée {moment(capacity?.creationDate).startOf().fromNow()} {userCreation.name}
                                    {userCreation.img !== "" &&
                                        <div className="app__section_body_user_container_img"><img src={userCreation.img} alt="user_img" /></div>
                                    }
                                </div> */}
                                    {/* <div>
                                    Modifiée {moment(capacity?.modificationDate).startOf().fromNow()} {userModification.name}
                                    {userModification.img !== "" &&
                                        <div className="app__section_body_user_container_img"><img src={userModification.img} alt="user_img" /></div>
                                    }
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION 1 */}
                {/* <div id='section_1' className={`app__section ${isApp ? 'app__padding_small' : 'app__padding_large'} ${isHeaderBanner ? 'app__padding_header_large' : 'app__padding_header_small'}`}> */}
                <div id='section_1' className="app__section">
                    {/* <div className="app__section_container app_section_container_side"> */}
                    <div className="app__section_container">
                        <div className={`app__section_title_container ${isHeaderBanner ? 'app__top_header_large' : 'app__top_header_small'}`}>
                            <h3 onClick={() => changeCurrentStep(1)}>{navigation.layout[1]['title_long']} - STEP {currentStep}</h3>
                            <div className="app__section__title_bar"></div>
                        </div>
                        <div className="app__section_body_container">
                            <div className="app__section_body2">
                                {(currentStep === 0 && landSelectionList.lands.length === 0) ?
                                    <div className="app__section_body2_message">🚨 Veuillez sélectionner au moins une parcelle sur la carte</div>
                                    :
                                    (currentStep === 0 && landSelectionList.union.outerLine.type === "FeatureCollection") &&
                                    <div className="app__section_body2_message">🚨 Veuillez sélectionner des parcelles adjacentes</div>
                                }
                                <div className="app__section_body_subtitle">
                                    Caractéristiques générales et composition
                                </div>
                                <div className="app__section_body_23_container">
                                    <div className="app__section_body_23_container_first app__widget2">
                                        <div className="app__widget2_title">Surface totale du terrain</div>
                                        <div className="app__section_body_23_container_first_body">
                                            <div className="app__widget2_indicator_container">
                                                <div className="app__widget2_indicator_value">{landSelectionList.area.toFixed(1)}</div>
                                                <div className="app__widget2_indicator_unit">m²</div>
                                            </div>
                                            <div className="app__widget2_indicator_container">
                                                <div className="app__widget2_indicator_value">{(landSelectionList.area / 10000).toFixed(3)}</div>
                                                <div className="app__widget2_indicator_unit">ha</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="app__section_body_23_container_second app__widget2">
                                        <div className="app__widget2_title">Nombre de parcelles</div>
                                        <div className="app__widget2_indicator_container">
                                            <div className="app__widget2_indicator_value">{landSelectionList.lands.length}</div>
                                            <div className="app__widget2_indicator_unit">
                                                {landSelectionList.lands.length < 2 ? 'parcelle' : 'parcelles'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="app__section_body_1_container">
                                    <div className="app__section_body_1_container_first app__widget2">
                                        <div className="app__widget2_title">Détail des parcelles</div>
                                        <div className="app__section_body_1_container_first_body">
                                            <div className="app__widget2_list_titles_container">
                                                {/* <div>Schéma</div> */}
                                                <div>Identifiant</div>
                                                <div>Section</div>
                                                <div>Numéro</div>
                                                <div>Surface</div>
                                            </div>
                                            {landSelectionList.lands.map((item, index) => {
                                                return (
                                                    <div className="app__widget2_list_item_container" key={index}>
                                                        {/* <div></div> */}
                                                        <div>{item.properties.id}</div>
                                                        <div>{item.properties.section}</div>
                                                        <div>{item.properties.numero}</div>
                                                        <div>{(item.area).toFixed(2)} m²</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                {/* BUTTON VALIDATE LANDS */}
                                {(landSelectionList.lands.length > 0 && landSelectionList.union.outerLine.type === "Feature" && currentStep === 0) &&
                                    <div className="app__capamap_button_container">
                                        <div onClick={() => { handleLandValidation() }} className="app__capamap_button">
                                            VALIDER CES PARCELLES
                                        </div>
                                    </div>
                                }

                                {/* TEST SVG */}
                                <div>
                                    {landSelectionList?.union?.bounds_groups.length}
                                    <svg width="200" height="200" viewBox="-10 -10 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        {landSelectionList?.union?.bounds_groups.map((item, index) => {
                                            // return <circle cx={bound.start_coord_local[0]} cy={bound.start_coord_local[1]} r="5" fill="red" />
                                            return <line key={index} x1={item.start_coord_local[0]} y1={-item.start_coord_local[1] + 150} x2={item.end_coord_local[0]} y2={-item.end_coord_local[1] + 150} stroke="red" strokeWidth="4" />
                                        })}
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION 2 */}
                {
                    navigation.layout[2]['isVisible'] === true &&
                    <div id='section_2' className={`app__section ${isApp ? 'app__padding_small' : 'app__padding_large'} ${isHeaderBanner ? 'app__padding_header_large' : 'app__padding_header_small'}`}>
                        <div className="app__section_container app_section_container_side">
                            <div className={`app__section_title_container ${isHeaderBanner ? 'app__top_header_large' : 'app__top_header_small'}`}>
                                <h3>{navigation.layout[2]['title_long']}</h3>
                                <div className="app__section__title_bar"></div>
                            </div>
                            <div className="app__section_body_container">
                                <div className="app__section_body2">

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    );
};

export default Capa;