// Import dependencies
import React, { useState, useEffect } from 'react';

// Import components
import Title from '../../common/title/Title';

// Import CSS & assets
import './Steps.css';
import browser_mockup from '../../../assets/browser_mockup.svg';
import img1 from '../../../assets/etape_01.png';
import img2 from '../../../assets/etape_02.png';
import img3 from '../../../assets/etape_03.png';
import Mockup from './Mockup';



const Steps = () => {

    // Current section for landing__steps_sticky
    const [sectionCurrent, setSectionCurrent] = useState(img1);

    // HandleScroll
    const handleScroll = () => {
        var section_2_top = document.getElementById("section_2").getBoundingClientRect().top;
        var section_3_top = document.getElementById("section_3").getBoundingClientRect().top;
        var img_top = document.getElementById("img_container").getBoundingClientRect().top;
        var img_bottom = document.getElementById("img_container").getBoundingClientRect().bottom;
        var img_middle = img_top + (img_bottom - img_top) / 2;
        if (window.innerWidth < 900) {
            img_middle = window.innerHeight / 2;
        }

        if (section_3_top <= img_middle) {
            setSectionCurrent(img3);
            console.log("img_middle", img_middle);
        }
        else if (section_2_top <= img_middle) {
            setSectionCurrent(img2);
            console.log("img_middle", img_middle);
        }
        else {
            setSectionCurrent(img1);
            console.log("img_middle", img_middle);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className="landing__steps section__padding">
            <a className="anchor" id="steps"></a>
            <div className="landing__steps_heading">
                <h1 className="gradient__text">Un outil simple,</h1>
                <h1 className="gradient__text">des possibilités infinies.</h1>
            </div>
            <div className="landing__steps_sticky__box">
                <div className="landing__steps_sticky__img_box">
                    <div id="img_container" className="landing__steps_sticky__img_container">
                        <img className="landing__steps_sticky__img_mockup" src={browser_mockup} alt='browser_mockup' />
                        <img className="landing__steps_sticky__img" src={sectionCurrent} alt='image_landing__steps_sticky' />
                        {/* <Mockup /> */}
                    </div>
                </div>
                <div className="landing__steps_sticky__text_box">
                    <div id="section_1" className={`landing__steps_sticky__text_section ${sectionCurrent === img1 ? "landing__steps_sticky__text_section_show" : ""}`}>
                        <div className="landing__steps_sticky__text_num_box">
                            <div className="landing__steps_sticky__text_num_title">ETAPE</div>
                            <div className="landing__steps_sticky__text_num">01</div>
                        </div>
                        <div className="landing__steps_sticky__text__description_box">
                            <div className="landing__steps_sticky__text__description_box_title">Sélectionnez et analysez le terrain</div>
                            {sectionCurrent === img1 &&
                                <>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Sélection rapide des parcelles que vous souhaitez étudier</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Avec ou sans remembrement</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Accès au cadastre à jour, et disponible sur la france entière</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Calcul automatique de la surface totale et des linéaires de limites</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Récupération des propriétaires des parcelles (si personne morale)</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Génération de la topographie 3D du terrain</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Récupération des bâtiments existants et analyse de leur nature (typologie, surface, hauteur, matériaux, DPE...)</div>
                                </>}
                        </div>
                    </div>
                    <div className={`landing__steps_sticky__img_section_container ${sectionCurrent === img1 ? "landing__steps_sticky__img_section_container_show" : ""}`}>
                        <img className="landing__steps_sticky__img_section_mockup" src={browser_mockup} alt='browser_mockup' />
                        <img className="landing__steps_sticky__img_section" src={img1} alt='image_landing__steps_sticky' />
                    </div>
                    <div id="section_2" className={`landing__steps_sticky__text_section ${sectionCurrent === img2 ? "landing__steps_sticky__text_section_show" : ""}`}>
                        <div className="landing__steps_sticky__text_num_box">
                            <div className="landing__steps_sticky__text_num_title">ETAPE</div>
                            <div className="landing__steps_sticky__text_num">02</div>
                        </div>
                        <div className="landing__steps_sticky__text__description_box">
                            <div className="landing__steps_sticky__text__description_box_title">Obtenez une vision claire du contexte</div>
                            {sectionCurrent === img2 &&
                                <>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Définition des risques liés à la géographie du terrain (sismicité, sols argileux...)</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Vérification de la présence de canalisations de matière dangereuse</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Identification du risque que le sol soit pollué</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Récupération des données statistiques de la population (répartition des CSP, âge moyen, composition moyenne d'un ménage...)</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Récupération des données statistiques du parc immobilier (typologies, surfaces moyennes, nombre de pièces, année de construction...)</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Analyse des transactions immobilières dans le quartier</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Vérification de la présence de monuments historiques</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Identification des commodités à proximité du terrain</div>
                                </>}
                        </div>
                    </div>
                    <div className={`landing__steps_sticky__img_section_container ${sectionCurrent === img2 ? "landing__steps_sticky__img_section_container_show" : ""}`}>
                        <img className="landing__steps_sticky__img_section_mockup" src={browser_mockup} alt='browser_mockup' />
                        <img className="landing__steps_sticky__img_section" src={img2} alt='image_landing__steps_sticky' />
                    </div>
                    <div id="section_3" className={`landing__steps_sticky__text_section ${sectionCurrent === img3 ? "landing__steps_sticky__text_section_show" : ""}`}>
                        <div className="landing__steps_sticky__text_num_box">
                            <div className="landing__steps_sticky__text_num_title">ETAPE</div>
                            <div className="landing__steps_sticky__text_num">03</div>
                        </div>
                        <div className="landing__steps_sticky__text__description_box">
                            <div className="landing__steps_sticky__text__description_box_title">Visualisez le volume constructible</div>
                            {sectionCurrent === img3 &&
                                <>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Récupération des documents d'urbanismes applicables (PLU, PADD, OAP...)</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Accès direct au Géoportail de l'Urbanisme</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Ajout simple et rapide des règles d'urbanisme</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Modification instantanée des règles d'urbanisme</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Création de nouvelles règles spécifiques</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Visualisation 3D des règles</div>
                                    <div className="landing__steps_sticky__text__description_box_body scale-up-left">Visualisation 3D du volume constructible</div>
                                </>}
                        </div>
                    </div>
                    <div className={`landing__steps_sticky__img_section_container ${sectionCurrent === img3 ? "landing__steps_sticky__img_section_container_show" : ""}`}>
                        <img className="landing__steps_sticky__img_section_mockup" src={browser_mockup} alt='browser_mockup' />
                        <img className="landing__steps_sticky__img_section" src={img3} alt='image_landing__steps_sticky' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Steps;