import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import * as turf from '@turf/turf';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Import external functions
import * as nodeHelpers from '../helpers/NodeHelpers';

function fitCameraToObject(camera, object) {

    const offset = 1.25;

    const boundingBox = new THREE.Box3();

    // get bounding box of object - this will be used to setup controls and camera
    boundingBox.setFromObject(object);

    const size = {
        x: boundingBox.max.x - boundingBox.min.x,
        y: boundingBox.max.y - boundingBox.min.y,
        z: boundingBox.max.z - boundingBox.min.z,
    };

    // get the max side of the bounding box (fits to width OR height as needed )
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2));

    cameraZ *= offset; // zoom out a little so that objects don't fill the screen

    camera.position.z = cameraZ;
    camera.position.y = - 3.5 * cameraZ;
}


var scene;
var camera;
var controls;
var renderer;

const ThreeFrame = ({ inputData }) => {

    // Set ref container
    const refContainer = useRef();

    // Use effect to laod it once
    useEffect(() => {

        // Create scene
        scene = new THREE.Scene();

        // Create camera
        camera = new THREE.PerspectiveCamera(75, refContainer.current.clientWidth / refContainer.current.clientHeight, 0.1, 1000);
        camera.position.z = 5;
        camera.position.y = -10;
        const initialCameraPosition = new THREE.Vector3(
            camera.position.x,
            camera.position.y,
            camera.position.z
        );

        // Create renderer
        renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        });
        renderer.setSize(refContainer.current.clientWidth, refContainer.current.clientHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        refContainer.current.appendChild(renderer.domElement);

        // Create lights
        var directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight1.position.set(-10, -10, 20).normalize();
        scene.add(directionalLight1);
        var ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
        scene.add(ambientLight);

        // Create controls
        controls = new OrbitControls(camera, renderer.domElement);
        controls.minPolarAngle = 0;
        controls.maxPolarAngle = Math.PI * 0.5;

        // Set up axis to Z
        THREE.Object3D.DefaultUp.set(0.0, 0.0, 1.0);

        var req = null;
        var animate = function () {
            req = requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };
        animate();

        // return () => {refContainer.current.removeChild(renderer.domElement)};
        return () => {
            cancelAnimationFrame(req);
            renderer.dispose();
        };
    }, []);


    // Update from inputData
    useEffect(() => {

        if (inputData.source[0].value !== null) {

            console.log("inputData", inputData);


            // Get values
            // const valueSplitted = inputData.target[0].value.split("|");
            // const nodeType = valueSplitted[0];
            // const nodeValues = valueSplitted[1].split("__");
            // console.log("THREE - nodeType", nodeType);
            // console.log("THREE - nodeValues", nodeValues);

            // Remove existing objects
            for (var i = scene.children.length - 1; i >= 0; i--) {
                var obj = scene.children[i];
                scene.remove(obj);
            }

            // Create lights
            var directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.4);
            directionalLight1.position.set(-10, -10, 20).normalize();
            scene.add(directionalLight1);
            var directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.4);
            directionalLight2.position.set((inputData.capacity.landBase.union.bbox.lengthH + 10), -10, 20).normalize();
            scene.add(directionalLight2);
            var directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.4);
            directionalLight3.position.set((inputData.capacity.landBase.union.bbox.lengthH / 2), (inputData.capacity.landBase.union.bbox.lengthV + 10), 20).normalize();
            scene.add(directionalLight3);
            var ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
            scene.add(ambientLight);

            // Add Land
            const landObjects = nodeHelpers.getLandMesh(inputData.capacity);
            landObjects.forEach(object => {
                scene.add(object);
            });
            // fitCameraToObject(camera, landObject);

            // Creat new objects
            var RuleObjects = nodeHelpers.nodeInterpretor(inputData.source, inputData.capacity);
            console.log("RuleObjects", RuleObjects);
            RuleObjects.forEach(object => {
                scene.add(object);
            });

            // // Force up axis
            // THREE.Object3D.DefaultUp.set(0.0, 0.0, 1.0);
            // // Set controls
            // controls = new OrbitControls(camera, renderer.domElement);
            // controls.minPolarAngle = 0;
            // controls.maxPolarAngle = Math.PI * 0.5;


            renderer.render(scene, camera);

        }
        else {

            // Remove existing objects
            for (var i = scene.children.length - 1; i >= 0; i--) {
                var obj = scene.children[i];
                scene.remove(obj);
            }

        }

    }, [inputData]);


    return (
        <>
            <div style={{ height: "100%", width: "100%", position: "relative" }}
                ref={refContainer}>
            </div>
        </>
    );
};

export default ThreeFrame;