// Import dependencies
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Import external function
import { teamsGet } from '../../../actions/teams';
import { usersGetAll } from '../../../actions/users';
import { capaGetAll } from '../../../actions/capa';

// Import CSS
import './Admin.css';
import ico_check from '../../../assets/ico/ico_check_loading.svg';

// Import components
import Header from '../../../components/app/header/Header';
import Teams from '../../../components/admin/teams/Teams';
import Indicators from '../../../components/admin/indicators/Indicators';
import Users from '../../../components/admin/users/Users';
import Capas from '../../../components/admin/capas/Capas';

var var_teams = [];
var var_teamsIndex = {};

var var_users = [];
var var_usersIndex = {};
var var_usersMailIndex = {};

var var_capa = [];
var var_capaIndex = {};

const Admin = () => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [teams, setTeams] = useState([]);
    const [teamsIndex, setTeamsIndex] = useState({});
    const [teamsInactive, setTeamsInactive] = useState(0);
    const [users, setUsers] = useState([]);
    const [usersIndex, setUsersIndex] = useState({});
    const [usersMailIndex, setUsersMailIndex] = useState({});
    const [capa, setCapa] = useState([]);
    const [capaIndex, setCapaIndex] = useState({});
    const [teamFilter, setTeamFilter] = useState(null);

    const [testedArea, setTestedArea] = useState(0);
    const [buildableArea, setBuildableArea] = useState(0);

    const [userOrder, setUserOrder] = useState("nb_capa");
    const [capaOrder, setCapaOrder] = useState("capa_date");

    const [analysedTeams, setAnalysedTeams] = useState([]);
    const [analysedUsers, setAnalysedUsers] = useState([]);
    const [analysedCapas, setAnalysedCapas] = useState([]);

    const [loadedTeams, setLoadedTeams] = useState(false);
    const [loadedUsers, setLoadedUsers] = useState(false);
    const [loadedCapa, setLoadedCapa] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const width_list = [50, 73, 123];
    const left_list = [0, 66, 157];
    const [selection, setSelection] = useState(0);

    // Set global functions
    const dispatch = useDispatch();

    // On load
    useEffect(() => {
        getTeams();
    }, [])


    // TEAMS
    const getTeams = () => {
        dispatch(teamsGet(handleTeamsSuccess, handleTeamsFail));
    }
    const handleTeamsSuccess = (response) => {
        console.log("SUCCESS get Teams", response);
        // Sort alphabetically
        response.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        })

        setTeams(response);
        var_teams = response;
        // Get index
        var index_teams = {};
        response.forEach((element, index) => {
            index_teams[element._id] = index;
        })
        var_teamsIndex = index_teams;
        // Continue
        getUsers();
        // Loading
        setLoadedTeams(true);
    }
    const handleTeamsFail = (error) => {
        console.log("FAIL get Teams", error.response.data.message);
    }
    const updateTeams = () => {
        dispatch(teamsGet(handleTeamsUpdateSuccess, handleTeamsFail));
    }
    const handleTeamsUpdateSuccess = (response) => {
        console.log("SUCCESS get Teams", response);
        // Sort alphabetically
        response.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        })

        setTeams(response);
        var_teams = response;
        // Get index
        var index_teams = {};
        response.forEach((element, index) => {
            index_teams[element._id] = index;
        })
        var_teamsIndex = index_teams;
        // Continue
        analyseData();
        // Loading
        setLoadedTeams(true);
    }

    useEffect(() => {
        if (loaded === true) {
            console.log("New team filter", teamFilter);
            analyseData(teamFilter);
        }
    }, [teamFilter])

    useEffect(() => {
        if (loaded === true) {
            console.log("New user order", userOrder);
            analyseData(teamFilter);
        }
    }, [userOrder])

    useEffect(() => {
        if (loaded === true) {
            console.log("New capa order", capaOrder);
            analyseData(teamFilter);
        }
    }, [capaOrder])



    // USERS
    const getUsers = () => {
        dispatch(usersGetAll(handleUsersSuccess, handleUsersFail));
    }
    const handleUsersSuccess = (response) => {
        console.log("SUCCESS get Users", response);
        setUsers(response);
        // Get index
        var index_users = {};
        var index_mail_users = {};
        response.forEach((element, index) => {
            index_users[element.id] = index;
            index_mail_users[element.email] = index;
            element.capa = [];
        })
        var_users = response;
        var_usersIndex = index_users;
        var_usersMailIndex = index_mail_users;
        // Continue
        getCapa();
        // Loading
        setLoadedUsers(true);
    }
    const handleUsersFail = (error) => {
        console.log("FAIL get Users", error.response.data.message);
    }


    // CAPA
    const getCapa = () => {
        dispatch(capaGetAll(handleCapaSuccess, handleCapaFail));
    }
    const handleCapaSuccess = (response) => {
        console.log("SUCCESS get Capa", response);
        setCapa(response);
        var_capa = response;
        // Get index
        var index_capa = {}
        response.forEach((element, index) => {
            index_capa[element.id] = index;
        })
        var_capaIndex = index_capa;
        // Continue
        analyseData();
        // Loading
        setLoadedCapa(true);
    }
    const handleCapaFail = (error) => {
        console.log("FAIL get Capa", error.response.data.message);
    }


    // ANALYSIS
    const analyseData = (filter) => {

        // console.log("var_teams", var_teams);
        // console.log("var_users", var_users);
        // console.log("var_usersIndex", var_usersIndex);
        // console.log("var_usersMailIndex", var_usersMailIndex);
        // console.log("var_capa", var_capa);

        // ANALYSE USERS
        var_users.forEach((analysed_user, index) => {
            analysed_user.team = "";
            analysed_user.capa = [];
        })
        var var_users_filtered = [];

        // ANALYSE TEAMS
        var inactive = 0;
        var_teams.forEach((analysed_team, index) => {
            analysed_team.users = [];
            analysed_team.users_email = [];
            analysed_team.capa = [];
            analysed_team.members.forEach(element2 => {
                var user = var_users[var_usersMailIndex[element2]];
                if (user !== undefined) {
                    analysed_team.users.push(user);
                    analysed_team.users_email.push(user.email);
                    var_users[var_usersMailIndex[element2]].team = analysed_team.name;
                    if (analysed_team?.isInactive === true) {
                        var_users[var_usersMailIndex[element2]].team = var_users[var_usersMailIndex[element2]].team + " (inactive)";
                    }
                    if (filter === analysed_team._id) {
                        var_users_filtered.push(user);
                    }
                }
            })
            if (analysed_team?.isInactive === true) {
                inactive++;
            }
        })
        setTeamsInactive(inactive);

        // ANALYSE CAPA
        var var_capa_filtered = [];
        var sum_testedArea = 0;
        var sum_buildableArea = 0;
        var_capa.forEach((analysed_capa, index) => {
            if (analysed_capa?.teams && analysed_capa.teams.length > 0) {
                var team_id = analysed_capa.teams[0];
                var team_index = var_teamsIndex[team_id];
                var_teams[team_index].capa.push(analysed_capa);
                analysed_capa.team = var_teams[team_index].name;
                if (filter === team_id) {
                    var_capa_filtered.push(analysed_capa);
                    if (analysed_capa?.land_area) {
                        sum_testedArea += analysed_capa?.land_area;
                    }
                    if (analysed_capa?.buildable_area) {
                        sum_buildableArea += analysed_capa?.buildable_area;
                    }
                }
                else if (!filter) {
                    if (analysed_capa?.land_area) {
                        sum_testedArea += analysed_capa?.land_area;
                    }
                    if (analysed_capa?.buildable_area) {
                        sum_buildableArea += analysed_capa?.buildable_area;
                    }
                }
            }
            var user_id = analysed_capa.creationUserId;
            var user_index = var_usersIndex[user_id];
            if (user_index !== undefined) {
                var_users[user_index].capa.push(analysed_capa);
            }
        })


        // Filter
        if (filter) {
            console.log("FILTERING", var_users_filtered);
        }
        else {
            var_users_filtered = [...var_users];
            var_capa_filtered = [...var_capa];
        }

        // Sort teams
        // var_teams.sort(function (a, b) {
        //     return a.name.localeCompare(b.name);
        // })

        // Sort users 
        var var_users_sorted = [...var_users_filtered];
        if (userOrder === "nb_capa") {
            var_users_sorted.sort(function (a, b) {
                return b["capa"].length - a["capa"].length;
            });
        }
        else if (userOrder === "team_name") {
            var_users_sorted.sort(function (a, b) {
                return a.team.localeCompare(b.team);
            });
        }
        else if (userOrder === "user_name") {
            var_users_sorted.sort(function (a, b) {
                return a.firstName.localeCompare(b.firstName);
            });
        }
        else if (userOrder === "user_mail") {
            var_users_sorted.sort(function (a, b) {
                return a.email.localeCompare(b.email);
            });
        }
        else if (userOrder === "user_date") {
            var_users_sorted.sort(function (a, b) {
                return (new Date(b.creationDate)) - (new Date(a.creationDate));
            });
        }

        // Sort capa
        var var_capa_sorted = [...var_capa_filtered];
        if (capaOrder === "capa_date") {
            var_capa_sorted.sort(function (a, b) {
                return (new Date(b.creationDate)) - (new Date(a.creationDate));
            });
        }
        else if (capaOrder === "capa_title") {
            var_capa_sorted.sort(function (a, b) {
                return a.title.localeCompare(b.title);
            });
        }
        else if (capaOrder === "capa_id") {
            var_capa_sorted.sort(function (a, b) {
                return a.id.localeCompare(b.id);
            });
        }
        else if (capaOrder === "team_name") {
            var_capa_sorted.sort(function (a, b) {
                return a.team.localeCompare(b.team);
            });
        }

        setAnalysedTeams(var_teams);
        setAnalysedUsers(var_users_sorted);
        setAnalysedCapas(var_capa_sorted);
        setTestedArea(sum_testedArea);
        setBuildableArea(sum_buildableArea);

        // Loading
        setLoadedData(true);
        setTimeout(() => {
            setLoaded(true);
        }, 500);
    }



    return (
        <div className='app__admin__container'>
            <Header title="Administration" isApp={true} userHook={user} isDark={true} />
            {loaded === true ?
                <>
                    <Indicators teams={teams} teamsInactive={teamsInactive} capas={analysedCapas} users={analysedUsers} testedArea={testedArea} buildableArea={buildableArea} teamFilter={teamFilter} teamName={var_teams[var_teamsIndex[teamFilter]]?.name} />
                    {window.innerWidth <= 1200 &&
                        <div className="app__admin_detail_selection_container">
                            <div className="app__admin_detail_selection_subcontainer">
                                <div className={`app__admin_detail_selection ${selection === 0 ? "app__admin_detail_selection_selected" : ""}`} onClick={() => { setSelection(0) }}>Équipes</div>
                                <div className={`app__admin_detail_selection ${selection === 1 ? "app__admin_detail_selection_selected" : ""}`} onClick={() => { setSelection(1) }}>Utilisateurs</div>
                                <div className={`app__admin_detail_selection ${selection === 2 ? "app__admin_detail_selection_selected" : ""}`} onClick={() => { setSelection(2) }}>Études capacitaires</div>
                                <div className="app__admin_detail_selector" style={{ width: width_list[selection] + 24 + 'px', left: left_list[selection] + 'px' }}></div>
                            </div>
                        </div>
                    }
                    <div className="app__admin_detail_container">
                        {(window.innerWidth > 1050 || selection === 0) &&
                            <div className="app__admin_detail_teams_container">
                                <Teams teams={analysedTeams} teamsInactive={teamsInactive} updateTeams={updateTeams} var_users={var_users} var_usersMailIndex={var_usersMailIndex} teamFilter={teamFilter} setTeamFilter={setTeamFilter} />
                            </div>
                        }
                        <div className="app__admin_detail_other_container">
                            {(window.innerWidth > 1050 || selection === 1) &&
                                <div className="app__admin_detail_users_container">
                                    <Users users={analysedUsers} userOrder={userOrder} setUserOrder={setUserOrder} teams={teams} updateTeams={updateTeams} />
                                </div>
                            }
                            {(window.innerWidth > 1050 || selection === 2) &&
                                <div className="app__admin_detail_capas_container">
                                    <Capas user={user} capa_list={analysedCapas} capaOrder={capaOrder} setCapaOrder={setCapaOrder} />
                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <>
                    {/* LOADING */}
                    <div className="app__admin_loading_container">
                        <div className="app__admin_loading_modal">
                            <div className="app__admin_loading_title">Chargement du dashboard d'administration</div>
                            <div className="app__admin_loading_items_container">
                                <div className="app__admin_loading_item">
                                    <div className={`${loadedTeams ? "app__admin_loading_item_done" : "app__admin_loading_item_circle"}`}><img src={ico_check} alt="" /></div>
                                    Récupération des équipes
                                </div>
                                <div className="app__admin_loading_item">
                                    <div className={`${loadedUsers ? "app__admin_loading_item_done" : "app__admin_loading_item_circle"}`}><img src={ico_check} alt="" /></div>
                                    Récupération des utilisateurs
                                </div>
                                <div className="app__admin_loading_item">
                                    <div className={`${loadedCapa ? "app__admin_loading_item_done" : "app__admin_loading_item_circle"}`}><img src={ico_check} alt="" /></div>
                                    Récupération des études capacitaires
                                </div>
                                <div className="app__admin_loading_item">
                                    <div className={`${loadedData ? "app__admin_loading_item_done" : "app__admin_loading_item_circle"}`}><img src={ico_check} alt="" /></div>
                                    Analyse et traitement des données
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Admin;