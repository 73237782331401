import React, { useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';

// Import components
import ThreeFrame from '../threejs/ThreeFrame';

// Import external functions
import * as nodeHelpers from '../helpers/NodeHelpers';
import * as helpers from '../../../../helpers/Other_helpers';

const Node_Rule_Building_Height = ({ id, data }) => {

    useEffect(() => {
        console.log("INPUT DATA CHANGED", data);
        // Check if new result
        var value = null;
        if (data.target[0].value !== null && data.target[1].value !== null && data.target[2].value) {
            value = "Node_Rule_Building_Height[" + data.target[0].value + "__" + data.target[1].value + "__" + data.target[2].value + "]";
        }
        if (data.source[0].value !== value) {
            data.source[0].value = value;
            data.handleChange(id, "source_0", value);
        }
    }, [data])

    return (
        <div id={"rule_" + id} className="ruleeditor__node_container_rule">
            <div className="ruleeditor__node_title">
                <div className="ruleeditor__node_title_label">{data.label}</div>
                <div className="ruleeditor__node_title_close"><img src={close} alt="close_block" onClick={() => (data.handleDelete(id))} /></div>
            </div>
            <div className="ruleeditor__node_container_rule_body">
                <div className="ruleeditor__node_center">
                    <div className="ruleeditor__node_description">{data.description}</div>
                    <div className="ruleeditor__node_body_box">
                        <div className="ruleeditor__node_body">
                            <div className="ruleeditor__handle_target">{data.target[0].label}</div>
                            <div className={`ruleeditor__handle_target_type ${data.target[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.target[0].accepted.join(" ")}</div>
                            <div className="ruleeditor__handle_target">{data.target[1].label}</div>
                            <div className={`ruleeditor__handle_target_type ${data.target[1].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.target[1].accepted.join(" ")}</div>
                            <div className="ruleeditor__handle_target">{data.target[2].label}</div>
                            <div className={`ruleeditor__handle_target_type ${data.target[2].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.target[2].accepted.join(" ")}</div>
                        </div>
                        <div className="ruleeditor__node_body_right">
                            {(data.target[0].value !== null && data.target[1].value !== null && data.target[2].value !== null) ?
                                <div className="ruleeditor__node_body_right_value">
                                    <div className="ruleeditor__node_body_right_value_main">{helpers.get_pretty_num(nodeHelpers.nodeInterpretor([data.target[2]], data.capacity), 2)}</div>
                                    <div className="ruleeditor__node_body_right_value_second">m</div>
                                </div>
                                :
                                <div className="ruleeditor__node_body_right_value">
                                    <div className="ruleeditor__node_body_right_value_main">-</div>
                                    <div className="ruleeditor__node_body_right_value_second">m</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="ruleeditor__node_three_container nowheel">
                    <ThreeFrame inputData={data} />
                </div> */}
            </div>
            <Handle type="target" position={Position.Left} style={{ top: 'calc(100% - 11px - 75px)' }} id="target_0" />
            <Handle type="target" position={Position.Left} style={{ top: 'calc(100% - 11px - 46px)' }} id="target_1" />
            <Handle type="target" position={Position.Left} style={{ top: 'calc(100% - 11px - 17px)' }} id="target_2" />
        </div>
    );
};

export default Node_Rule_Building_Height;