// Import dependencies
import React, { useState, useEffect } from 'react';

// Import components
import Input from '../../common/input/Input';

//Import CSS & assets
import './Filter.css';
import ico_close from '../../../assets/ico/ico_close.svg';
import ico_filters_lines from '../../../assets/ico/ico_filters_lines.svg';
import ico_filters_circle_1 from '../../../assets/ico/ico_filters_circle_1.svg';
import ico_filters_circle_2 from '../../../assets/ico/ico_filters_circle_2.svg';
import ico_filters_circle_3 from '../../../assets/ico/ico_filters_circle_3.svg';

const Filter = ({ capacityFullList, setCapacityFilteredList, capacitySearch, setCapacitySearch, user }) => {

    // Set hooks
    const [filters, setFilters] = useState(false);
    const [myCapa, setMyCapa] = useState(false);

    // Set specific functions
    // Search
    const resetSearch = () => {
        setCapacitySearch("");
    }
    // Changes on search input
    const handleChange = (e) => {
        console.log(e.target.value);
        setCapacitySearch(e.target.value);
    }
    const switchFilters = () => {
        var filters_old = filters;
        setFilters(!filters_old);
    }
    const setNewSearch = () => {
        // Set full list
        // var filtered = capacityFullList;
        var filtered = [];
        // Search with text
        if (capacitySearch !== "") {
            // filtered = filtered.filter((capacity) => (capacity.title.toLowerCase()).includes(capacitySearch.toLowerCase()));
            capacityFullList.forEach(item => {
                // Search on title and description
                if (item.title.toLowerCase().includes(capacitySearch.toLowerCase()) || item.description.toLowerCase().includes(capacitySearch.toLowerCase())) {
                    filtered.push(item);
                }
                // Search on city
                else if (item?.simplified?.city && item.simplified.city.toLowerCase().includes(capacitySearch.toLowerCase())) {
                    filtered.push(item);
                }
            })
        }
        else {
            filtered = capacityFullList;
        }
        // Search with my
        if (myCapa === true) {
            filtered = filtered.filter((capacity) => capacity.creationUserId === user.result._id);
        }
        // Set result
        setCapacityFilteredList(filtered);
    }
    useEffect(() => {
        setNewSearch();
    }, [capacitySearch]);
    useEffect(() => {
        setNewSearch();
    }, [myCapa]);

    return (
        <div className='app__filter_container'>
            <div className="app__filter_ico_container" onClick={() => { switchFilters() }}>
                <img src={ico_filters_lines} alt="filters_lines" />
                <img className={filters ? 'app__filter_ico_circle_1_moved' : 'app__filter_ico_circle_1'} src={ico_filters_circle_1} alt="filters_circle_1" />
                <img className={filters ? 'app__filter_ico_circle_2_moved' : 'app__filter_ico_circle_2'} src={ico_filters_circle_2} alt="filters_circle_2" />
                <img className={filters ? 'app__filter_ico_circle_3_moved' : 'app__filter_ico_circle_3'} src={ico_filters_circle_3} alt="filters_circle_3" />

            </div>
            <div className="app__filter_body_container">
                <div className={`app__filter_body ${filters ? 'app__filter_body_visible' : ''}`}>
                    <div className="app__filter_input_container">
                        {/* <Input type='text' name='search' label='Rechercher' value={capacitySearch} isResetable={true} handleChange={handleChange} handleClear={resetSearch} isDark={true} /> */}
                        <div className="common__input_container">
                            <input type="text" className="common__input_field_dark2" placeholder="Rechercher une étude capacitaire" name="capaSearch" id="capaSearch" onChange={handleChange} value={capacitySearch} />
                            <label htmlFor="capaSearch" className="form__label">Rechercher une étude capacitaire</label>
                            {/* Show / Hide icon for passwords */}
                            {(capacitySearch !== "") && (
                                <img className='auth_field_ico' src={ico_close} alt='reset' onClick={resetSearch} />
                            )}
                        </div>
                    </div>
                    <div className="app__filter_creator_container">
                        <div className={`app__filter_indicator ${myCapa ? 'app__filter_indicator_1' : 'app__filter_indicator_2'}`}></div>
                        <div className={`app__filter_creator_1 ${myCapa ? 'app__filter_creator_selected' : ''}`} onClick={() => { setMyCapa(true) }}>Uniquement mes études</div>
                        <div className={`app__filter_creator_2 ${myCapa ? '' : 'app__filter_creator_selected'}`} onClick={() => { setMyCapa(false) }}>Toutes les études de l'équipe</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filter;