// Import dependencies
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signin, signup, forget } from '../../../actions/auth';

// Import components
import Input from '../../common/input/Input';
import Logo from '../../common/logo/Logo';

// Import CSS & assets
import './AuthForm.css';
import close from '../../../assets/ico/ico_close.svg';

const AuthForm = ({ predefinedType, source, origin }) => {

    // Set constants
    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    // Set hooks
    // Form
    const [formData, setFormData] = useState(initialFormData);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isConfirmValid, setIsConfirmValid] = useState(false);
    const [isConfirmValidNbChar, setIsConfirmValidNbChar] = useState(false);
    const [isConfirmValidLowCase, setIsConfirmValidLowCase] = useState(false);
    const [isConfirmValidUppCase, setIsConfirmValidUppCase] = useState(false);
    const [isConfirmValidNum, setIsConfirmValidNum] = useState(false);
    const [isConfirmValidSpecChar, setIsConfirmValidSpecChar] = useState(false);
    // ActionType
    const [actionType, setActionType] = useState(predefinedType);
    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    // Error
    const [errorMessage, setErrorMessage] = useState('');
    // Logo
    const [logoAnimate, setLogoAnimate] = useState(false);
    // User
    const [user, setUser] = useState(null);



    // Set global functions
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // Set specific funcitons
    // Changes on form
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleKeyUp = (e) => {
        checkConfirm();
    }
    const checkConfirm = () => {
        // Check current password
        formData.password.length >= 8 ? setIsConfirmValidNbChar(true) : setIsConfirmValidNbChar(false);
        (/[a-z]/).test(formData.password) ? setIsConfirmValidLowCase(true) : setIsConfirmValidLowCase(false);
        (/[A-Z]/).test(formData.password) ? setIsConfirmValidUppCase(true) : setIsConfirmValidUppCase(false);
        (/[0-9]/).test(formData.password) ? setIsConfirmValidNum(true) : setIsConfirmValidNum(false);
        (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(formData.password) ? setIsConfirmValidSpecChar(true) : setIsConfirmValidSpecChar(false);
        // Check second password
        (formData.password === formData.confirmPassword && formData.password !== '') ? setIsConfirmValid(true) : setIsConfirmValid(false);

    }
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    // Switch actionType
    const switchSignMode = () => {
        if (actionType === "signIn") {
            setActionType("signUp");
        }
        else {
            setActionType("signIn");
        }
        // setIsSignup(!isSignup);
        setShowPassword(false);
        setShowConfirmPassword(false);
    }
    const switchResetMode = () => {
        setActionType("resetPassword");
    }
    // Loading
    const handleLoading = () => {
        setIsLoading(true);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }
    const handleLoadedSuccess = () => {
        setIsLoading(false);
        setIsLoadedSuccess(true);
        // Redirect if its sign in success or sign up from admin add
        if (actionType === 'signIn' || (actionType === 'signUp' && (origin && origin.includes("adm_add")))) {
            setUser(JSON.parse(localStorage.getItem('profile')));
            var cause = document.getElementById('auth__cause');
            if (cause !== null) { cause.style.opacity = 0 }
            setTimeout(() => {
                showLogo();
            }, 1000);
            setTimeout(() => {
                if (source !== null) {
                    navigate(source);
                }
                else {
                    navigate('/dashboard');
                }
            }, 5000);
        }
    }
    const handleSuccess = () => {
        console.log("SUCCESS");
        handleLoadedSuccess();
    }
    const handleLoadedFail = () => {
        setIsLoading(false);
        setIsLoadedFail(true);
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
        }, 4000);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleLoadedFail();
    }
    // Submit form
    const handleSubmit = async (e) => {
        e.preventDefault(); // no refresh
        // Set loading button
        handleLoading();
        // Check all required fields are not empty
        var test = false;
        if (actionType === "signUp") {
            // Capitalize first letters of firstName
            var modifiedFirstName = formData.firstName.toLowerCase();
            var arr = modifiedFirstName.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            modifiedFirstName = arr.join(" ");
            arr = modifiedFirstName.split("-");
            for (i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            modifiedFirstName = arr.join("-");
            formData.firstName = modifiedFirstName;
            // Capitalize all lastName
            const modifiedLastName = formData.lastName.toUpperCase();
            formData.lastName = modifiedLastName;
            // Lower mail
            if (origin && origin.includes("adm_add")) {
                formData.email = origin.substring(8);
            }
            const modifiedEmail = formData.email.toLowerCase();
            formData.email = modifiedEmail;
            // Check
            if (formData.firstName !== "" && formData.lastName !== "" && formData.email !== "" && formData.password !== "" && formData.confirmPassword !== "") test = true;
        }
        else if (actionType === "signIn") {
            // Lower mail
            const modifiedEmail = formData.email.toLowerCase();
            formData.email = modifiedEmail;
            // Check
            if (formData.email !== "" && formData.password !== "") test = true;
        }
        else {
            // Check
            if (formData.email !== "") test = true;
        }
        if (test) {
            if (actionType === "signUp") {
                // Add avatar
                var avatar = await getInitialsB64(formData.firstName, formData.lastName);
                formData.avatar = avatar;
                // Add origin
                formData.origin = origin;
                // Send signUp
                console.log("SUBMIT SIGN UP", formData);
                dispatch(signup(formData, navigate, handleSuccess, handleFail)); // navigate sert a faire une redirection après
            }
            else if (actionType === "signIn") {
                console.log("SUBMIT SIGN IN", formData);
                // Send signIn
                dispatch(signin(formData, navigate, handleSuccess, handleFail)); // navigate sert a faire une redirection après
            }
            else {
                // Send resetPassword
                console.log("SUBMIT FORGET", formData);
                dispatch(forget(formData, navigate, handleSuccess, handleFail)); // navigate sert a faire une redirection après

            }
        }
        else {
            console.log(formData);
            handleFail({ response: { data: { message: "Veuillez remplir tous les champs." } } });
        }
    }
    // Animated logo
    const showLogo = () => {
        setLogoAnimate(true);
    }

    // Avatar
    const getInitialsB64 = async (firstName, lastName) => {
        var initals = "";
        if (firstName && firstName.length > 0) {
            initals += firstName[0];
        }
        if (lastName && lastName.length > 0) {
            initals += lastName[0];
        }

        var svg_string = '<svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="200" height="200" fill="#062134"/><text fill="white" xml:space="preserve" style="white-space: pre" font-family="Helvetica" font-size="100" letter-spacing="0.em" text-anchor="middle" x="100" y="135">' + initals + '</text></svg>';
        const base64 = await convertBase64(new Blob([svg_string], { type: 'image/svg+xml' }));

        return base64
    }
    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    // RETURN
    return (
        <>
            <div className={`auth__authform ${isLoadedSuccess ? "auth__authform_hidden" : ""}`}>
                <div className="auth__close_container">
                    <img onClick={() => { navigate('/') }} src={close} alt="close" />
                </div>
                <h5>{actionType === 'signUp' ? 'Inscription' : actionType === 'signIn' ? 'Connexion' : actionType === 'resetPassword' ? 'Mot de passe oublié' : ''}</h5>
                <form autoComplete='off' noValidate onSubmit={handleSubmit}>
                    <div>
                        <div className={`auth__authform_box_name ${actionType === 'signUp' ? "auth__authform_box_visible" : "auth__authform_box_hidden"}`}>
                            <Input type='text' name='firstName' label='Prénom' handleChange={handleChange} />
                            <Input type='text' name='lastName' label='Nom' handleChange={handleChange} />
                        </div>
                        <div className="auth__authform_box_mail">
                            {(origin && origin.includes("adm_add")) ?
                                <Input type='text' name='email' label='Adresse mail' handleChange={handleChange} readOnly={true} value={origin.substring(8)} defaultValue={origin.substring(8)} />
                                :
                                <Input type='text' name='email' label='Adresse mail' handleChange={handleChange} />
                            }
                        </div>
                        <div className={`auth__authform_box_password ${actionType === 'resetPassword' ? "auth__authform_box_hidden" : "auth__authform_box_visible"}`}>
                            <Input type={showPassword ? 'text' : 'password'} name='password' label='Mot de passe' handleChange={handleChange} handleShow={handleShowPassword} handleKeyUp={handleKeyUp} />
                            <p className='auth__authform_forget' onClick={switchResetMode}>{actionType === 'signIn' ? 'Mot de passe oublié ?' : ''}</p>
                        </div>
                        <div className={`auth__authform_box_password ${actionType === 'signUp' ? "auth__authform_box_visible2" : "auth__authform_box_hidden"}`}>
                            <div className="auth__authform_box_password_check"><div className={`auth__authform_box_password_check_circle ${isConfirmValidNbChar === true ? "auth__authform_box_password_check_circle_valid" : ""}`}>✔</div>Contient au moins 8 caractères</div>
                            <div className="auth__authform_box_password_check"><div className={`auth__authform_box_password_check_circle ${isConfirmValidLowCase === true ? "auth__authform_box_password_check_circle_valid" : ""}`}>✔</div>Contient au moins 1 lettre minuscule</div>
                            <div className="auth__authform_box_password_check"><div className={`auth__authform_box_password_check_circle ${isConfirmValidUppCase === true ? "auth__authform_box_password_check_circle_valid" : ""}`}>✔</div>Contient au moins 1 lettre majuscule</div>
                            <div className="auth__authform_box_password_check"><div className={`auth__authform_box_password_check_circle ${isConfirmValidNum === true ? "auth__authform_box_password_check_circle_valid" : ""}`}>✔</div>Contient au moins 1 chiffre</div>
                            <div className="auth__authform_box_password_check" style={{ marginBottom: "12px" }}><div className={`auth__authform_box_password_check_circle ${isConfirmValidSpecChar === true ? "auth__authform_box_password_check_circle_valid" : ""}`}>✔</div>Contient au moins 1 caractère spécial</div>
                            <Input type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' label={isConfirmValid ? 'Vérifier le mot de passe ✔' : 'Vérifier le mot de passe'} handleChange={handleChange} handleShow={handleShowConfirmPassword} handleKeyUp={handleKeyUp} />
                        </div>
                        <div className="auth__authform_box_button">
                            <input className={`auth__authform_button ${isLoading ? "loading" : ""} ${isLoadedSuccess ? "loadedSuccess" : ""} ${isLoadedFail ? "loadedFail" : ""}`} type="submit" value={isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : actionType === 'signUp' ? "Inscription" : actionType === 'signIn' ? 'Connexion' : 'Renouveler mon mot de passe'} />
                            <p className='auth__authform_message'>{isLoadedFail ? errorMessage : "‎"}</p>
                        </div>
                    </div>
                    <p className='auth__authform_link' onClick={switchSignMode}>{actionType === 'signUp' ? "Vous avez déja un compte? Connectez-vous." : actionType === 'signIn' ? "Vous n'avez pas encore de compte? Inscrivez-vous." : "Vous vous souvenez de votre mot de passe? Connectez-vous."}</p>
                </form>
                {/* <button onClick={handleLoading}>LOADING</button>
                <button onClick={handleLoadedSuccess}>SUCCESS</button>
                <button onClick={handleLoadedFail}>FAIL</button> */}
            </div>
            <div className={`auth__authform_final ${isLoadedSuccess && "auth__authform_final_visible"}`}>
                {actionType === 'signIn' &&
                    <Logo state={logoAnimate} />
                }
                <h1>{actionType === 'signUp' ? `Bienvenue ${formData.firstName}! 👋` : actionType === 'signIn' ? `Bonjour ${user?.result?.firstName}! 👋` : 'Un trou de mémoire ? 😥'}</h1>
                <h4>{(actionType === 'signUp' && origin && origin.includes("adm_add")) ? "Découvrez votre Dashboard dans quelques secondes." : actionType === 'signUp' ? "Il ne vous reste plus qu'à activer votre compte en cliquant sur le lien envoyé par email." : actionType === 'resetPassword' ? "Renouvelez votre mot de passe en cliquant sur le lien envoyé par email." : "Nous sommes heureux de vous revoir."}</h4>
            </div>
        </>
    );
};

export default AuthForm;