import axios from 'axios';

// Import global
import * as global from '../global.js';
const environment = global.get_environment();


// Define base url
var base_url = '';
if (environment === "DEV") { base_url = 'http://localhost:5000'; }
if (environment === "PREPROD") { base_url = 'https://proplab-api.herokuapp.com/'; }
if (environment === "PROD") { base_url = 'https://api.proplab.fr/'; }
const API = axios.create({ baseURL: base_url });

// const urlPosts = '/posts';
const urlAuth = '/user';
const urlUsers = '/user';
const urlTeams = '/teams';
const urlCapa = '/capa';

// Interceptor (for middleware to get the user token)
API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
        // if user logged in, add the token to the request
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    // Keep going and send req
    return req;
});


// API functions for auth
export const signIn = (formData) => API.post(`${urlAuth}/signin`, formData);
export const signUp = (formData) => API.post(`${urlAuth}/signup`, formData);
export const activateAccount = (activationToken) => API.patch(`${urlAuth}/activate`, activationToken);
export const sendValidationMail = (userId) => API.patch(`${urlAuth}/send-activation`, userId);
export const forgetPassword = (formData) => API.post(`${urlAuth}/forget`, formData);
export const resetPassword = (resetData) => API.patch(`${urlAuth}/reset`, resetData);
export const sendContactMail = (contactData) => API.patch(`${urlAuth}/send-contact`, contactData);
export const usersGet = () => API.get(urlAuth);


// API function for user
export const userUpdate = (id, userData) => API.patch(`${urlUsers}/${id}`, userData);

// API functions for teams
export const teamsGet = () => API.get(urlTeams);
export const teamGetById = (id) => API.get(`${urlTeams}/getbyid/${id}`);
export const teamCreate = (formData) => API.post(`${urlTeams}/create`, formData);
export const teamUpdate = (id, formData) => API.patch(`${urlTeams}/update/${id}`, formData);
export const ruleUpdate = (id, formData) => API.patch(`${urlTeams}/update_rule/${id}`, formData);
export const rulesGetByTeamId = (id) => API.get(`${urlTeams}/get_rules/${id}`);

// API for capacities
export const capaCreate = (formData) => API.post(`${urlCapa}/create`, formData);
export const capaImport = (formData) => API.post(`${urlCapa}/import`, formData);
export const capaGetAll = () => API.get(urlCapa);
export const capaGetByUserAndTeam = (filter) => API.get(`${urlCapa}/userandteam?userId=${filter.userId}&teamId=${filter.teamId}&limit=${filter.limit}`);
export const capaGetById = (id, team_id) => API.get(`${urlCapa}/${id}?teamId=${team_id}`);
export const capaUpdate = (id, formData) => API.patch(`${urlCapa}/update/${id}`, formData);
export const capaDelete = (id) => API.delete(`${urlCapa}/delete/${id}`);
export const capaCopy = (id, formData) => API.patch(`${urlCapa}/copy/${id}`, formData);
