import React, { useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';

// Import external functions
import * as nodeHelpers from '../helpers/NodeHelpers';

const Node_Condition = ({ id, data }) => {

    useEffect(() => {
        console.log("INPUT DATA CHANGED", data);
        // Check if new result
        var value = null;
        if (data.target[0].value !== null) {
            value = "Node_Condition[" + data.target[0].value + "]";
        }
        if (data.source[0].value !== value) {
            data.source[0].value = value;
            data.handleChange(id, "source_0", value);
        }
    }, [data])

    return (
        <div id={"rule_" + id} className="ruleeditor__node_container_rule">
            <div className="ruleeditor__node_title">
                <div className="ruleeditor__node_title_label">{data.label}</div>
                <div className="ruleeditor__node_title_close"><img src={close} alt="close_block" onClick={() => (data.handleDelete(id))} /></div>
            </div>
            <div className="ruleeditor__node_container_rule_body">
                <div className="ruleeditor__node_center">
                    <div className="ruleeditor__node_description">{data.description}</div>
                    <div className="ruleeditor__node_body">
                        <div className="ruleeditor__handle_target">{data.target[0].value === null ? data.target[0].label : nodeHelpers.nodeInterpretor([data.target[0]], data.capacity) === true ? data.target[0].label + " : Vrai, la règle est applicable" : nodeHelpers.nodeInterpretor([data.target[0]], data.capacity) === false ? data.target[0].label + " : Faux, la règle n'est pas applicable" : data.target[0].label}</div>
                        <div className={`ruleeditor__handle_target_type ${data.target[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.target[0].accepted.join(" ")}</div>
                    </div>
                </div>
            </div>
            <Handle type="target" position={Position.Left} style={{ top: 'calc(100% - 11px - 17px)' }} id="target_0" />
        </div>
    );
};

export default Node_Condition;