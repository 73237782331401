import { CAPA_GETALL, CAPA_GETBYUSERANDTEAM, CAPA_GETBYID, CAPA_CREATE, CAPA_UPDATE, CAPA_DELETE, CAPA_COPY } from '../constants/actionTypes';
import * as api from '../api';

export const capaGetAll = (handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaGetAll();
        const action = { type: CAPA_GETALL, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const capaGetByUserAndTeam = (filter, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaGetByUserAndTeam(filter);
        const action = { type: CAPA_GETBYUSERANDTEAM, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const capaGetById = (id, team_id, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaGetById(id, team_id);
        const action = { type: CAPA_GETBYID, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const capaCreate = (formData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaCreate(formData);
        const action = { type: CAPA_CREATE, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const capaImport = (formData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaImport(formData);
        const action = { type: CAPA_CREATE, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const capaUpdate = (id, formData, handleSuccess, handleFail) => async (dispatch) => {
    var startTime = new Date();
    try {
        const { data } = await api.capaUpdate(id, formData);
        const action = { type: CAPA_UPDATE, payload: data };
        dispatch(action);
        handleSuccess(data);
        var endTime = new Date();
        var timeElapsed = endTime - startTime;
        console.log("updating duration:", timeElapsed + " ms");
    } catch (error) {
        console.log(error);
        handleFail(error);
        // var endTime = new Date();
        // var timeElapsed = endTime - startTime;
        // console.log("time:", timeElapsed);
    }
}

export const capaDelete = (id, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaDelete(id);
        const action = { type: CAPA_DELETE, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const capaCopy = (id, formData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.capaCopy(id, formData);
        const action = { type: CAPA_COPY, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}