import { USER_CREATE, USER_CREATE_AUTH, AUTH, RESET, SENDMAIL, UPDATE, USER_CONTACT } from '../constants/actionTypes';
import * as api from '../api';


export const signin = (formData, navigate, handleSuccess, handleFail) => async (dispatch) => {
    try {
        // log in the user
        const { data } = await api.signIn(formData);
        const action = { type: AUTH, payload: data };
        dispatch(action);
        // go to homepage
        //navigate('/');
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const signup = (formData, navigate, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.signUp(formData);
        var action = { type: USER_CREATE, payload: data };
        if (data?.result?.emailValidation === true) {
            action = { type: USER_CREATE_AUTH, payload: data };
        }
        dispatch(action);
        // go to homepage
        //navigate('/');
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const activate = (activationToken, handleSuccess, handleFail) => async (dispatch) => {
    try {
        console.log("actions - activationToken:", activationToken);
        const { data } = await api.activateAccount(activationToken);
        const action = { type: AUTH, payload: data };
        dispatch(action);
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const resendMail = (userId, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.sendValidationMail(userId);
        const action = { type: SENDMAIL, payload: data };
        dispatch(action);
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const forget = (formData, navigate, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.forgetPassword(formData);
        const action = { type: RESET, payload: data };
        dispatch(action);
        // go to homepage
        //navigate('/');
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const reset = (formData, resetToken, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const resetData = { formData, resetToken };
        console.log("actions - resetData:", resetData);
        const { data } = await api.resetPassword(resetData);
        const action = { type: RESET, payload: data };
        dispatch(action);
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const sendContact = (contactData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.sendContactMail(contactData);
        const action = { type: USER_CONTACT, payload: data };
        dispatch(action);
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}