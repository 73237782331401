// Import dependencies
import React, { useState, useEffect } from 'react';
// import people from '../../assets/people.png';

// Import components

// Import CSS & assets
import './Header.css';
import title from '../../../assets/title.svg';
import building from '../../../assets/building_big.png';

const Header = () => {

    const headlines = ["Ne loupez plus aucun lead foncier", "Révolutionnez le développement immobilier", "Maximisez vos projets immobiliers"];
    const [headline, setHeadline] = useState(headlines[0]);
    const [mytime, setMytime] = useState(0);

    useEffect(() => {
        // create a interval and get the id
        const myInterval = setInterval(() => {
            setMytime((prevTime) => (prevTime + 1) >= 3 ? 0 : (prevTime + 1));
        }, 4000);
        // clear out the interval using the id when unmounting the component
        return () => clearInterval(myInterval);
    }, []);

    useEffect(() => {
        console.log("New Time", mytime);
        setHeadline(headlines[mytime]);
    }, [mytime])


    return (
        <div className="landing__header" id="home">
            <div className="landing__header_background_image">
                <img src={building} alt='building' />
            </div>
            <div className="landing__header_body">
                {/* <h1 className="gradient__text">PROPLAB</h1> */}
                <img src={title} alt='title' />
                {mytime === 0 &&
                    <p className='landing__headline_0'>{headline}</p>
                }
                {mytime === 1 &&
                    <p className='landing__headline_0'>{headline}</p>
                }
                {mytime === 2 &&
                    <p className='landing__headline_0'>{headline}</p>
                }

                <div className="landing__header_body_cta">
                    {/* <div className="landing__header_gody_mail_input">
                    <Input type='text' name='email' label='Adresse email' />
                </div> */}
                    <button type="button">Essayer gratuitement</button>
                </div>
            </div>
            <div className='landing__header_scroll'></div>

        </div>
    )
};

export default Header;
