import { TEAM_GET, TEAM_CREATE, TEAM_UPDATE } from '../constants/actionTypes';
import * as api from '../api';

export const teamsGet = (handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.teamsGet();
        const action = { type: TEAM_GET, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const teamGetById = (id, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.teamGetById(id);
        const action = { type: TEAM_GET, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const teamCreate = (formData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.teamCreate(formData);
        const action = { type: TEAM_CREATE, payload: data };
        dispatch(action);
        handleSuccess();
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}


export const teamUpdate = (id, formData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.teamUpdate(id, formData);
        const action = { type: TEAM_UPDATE, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const ruleUpdate = (id, formData, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.ruleUpdate(id, formData);
        const action = { type: TEAM_UPDATE, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}

export const rulesGetByTeamId = (id, handleSuccess, handleFail) => async (dispatch) => {
    try {
        const { data } = await api.rulesGetByTeamId(id);
        const action = { type: TEAM_GET, payload: data };
        dispatch(action);
        handleSuccess(data);
    } catch (error) {
        console.log(error);
        handleFail(error);
    }
}