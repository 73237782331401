import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ruleUpdate } from '../../../actions/teams';


// Import components
import RuleEditor from '../ruleEditor_elements/RuleEditor';
import Rule from './Rule';

// Import CSS & assets
import './RuleCatalog.css';
import close from '../../../assets/ico/ico_close.svg';
import * as lists from './Lists';
import * as nodeHelpers from '../ruleEditor_elements/helpers/NodeHelpers';
import * as rulesHelpers from '../ruleEditor_elements/helpers/RulesHelpers';

// Get lists
const block_list = lists.get_blockList();
const rule_list = lists.get_ruleList();



const RuleCatalog = ({ ruleGroup_Id, currentType, setCurrentType, setIsRuleCatalogOpen, capacity, setCapacity, user, rulesCatalogTeam, rulesUpdate, setRuleUpdateTracker }) => {

    // Set global funcitons
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // SECTIONS
    const [currentSlide, setCurrentSlide] = useState(0);
    const [indicatorOffset, setIndicatorOffset] = useState({ top: 0, left: 0 });
    const handleSectionSlide = (targetIndex) => {
        setCurrentSlide(targetIndex);
        getIndicatorOffset(targetIndex);
        resetImport();
        if (targetIndex !== 1) {
            resetRule();
        }
    }
    const getIndicatorOffset = (targetIndex) => {
        var indicatorWidth = 60;
        var item = document.getElementById('rulecatalog_label_' + targetIndex);
        setIndicatorOffset({ top: (item.offsetTop + item.offsetHeight + 8), left: (item.offsetLeft + (item.offsetWidth / 2) - (indicatorWidth / 2)) });
    }
    useEffect(() => {
        handleSectionSlide(0);
    }, []);


    // Close
    const closeRuleCatalog = () => {
        setIsRuleCatalogOpen(false);
    }

    // Help Mode
    const [helpMode, setHelpMode] = useState(true);

    // Element type
    // const [currentType, setCurrentType] = useState("rule");
    const handleTypeChange = (newType) => {
        setCurrentType(newType);
        // Replace indicator
        setTimeout(() => {
            getIndicatorOffset(currentSlide);
            resetRule();
        }, 100)
    }

    // _____ FILTER CATALOG
    const [filterString, setFilterString] = useState("");
    const [filteredRulesCatalog, setFilteredRulesCatalog] = useState({ ...rulesCatalogTeam.rulesCatalog });
    const [filteredRulesTeam, setFilteredRulesTeam] = useState([...rulesCatalogTeam.rulesTeam]);
    const handleFilterChange = (newFilter) => {
        // Update filterString
        setFilterString(newFilter);
        // Reset rules list if newFilter = ""
        if (newFilter === "") {
            setFilteredRulesCatalog({ ...rulesCatalogTeam.rulesCatalog });
            setFilteredRulesTeam([...rulesCatalogTeam.rulesTeam]);
        }
        // Filter rules list
        else {
            // Set new values
            var newFilteredRulesCatalog = {};
            var newFilteredRulesTeam = [];
            // Get filtered rules of catalog
            Object.keys(rulesCatalogTeam.rulesCatalog).forEach(category => {
                var newFilteredCategory = [];
                rulesCatalogTeam.rulesCatalog[category].forEach(rule => {
                    if (rule.title.toLowerCase().includes(newFilter.toLowerCase()) || rule.description.toLowerCase().includes(newFilter.toLowerCase())) {
                        newFilteredCategory.push(rule);
                    }
                })
                if (newFilteredCategory.length > 0) {
                    newFilteredRulesCatalog[category] = newFilteredCategory;
                }
            })
            // Get filtered rules of team
            rulesCatalogTeam.rulesTeam.forEach(rule => {
                if (rule.title.toLowerCase().includes(newFilter.toLowerCase()) || rule.description.toLowerCase().includes(newFilter.toLowerCase())) {
                    newFilteredRulesTeam.push(rule);
                }
            })
            // Update
            setFilteredRulesCatalog({ ...newFilteredRulesCatalog });
            setFilteredRulesTeam([...newFilteredRulesTeam]);
        }
    }
    // On load : Reset filter
    useEffect(() => {
        setFilteredRulesCatalog({ ...rulesCatalogTeam.rulesCatalog });
        setFilteredRulesTeam([...rulesCatalogTeam.rulesTeam]);
        setFilterString("");
    }, []);
    // On rules update (new rule or updated one) : Relaunch filter
    useEffect(() => {
        console.log("Update view", rulesCatalogTeam);
        handleFilterChange(filterString);
    }, [rulesCatalogTeam]);

    // _____ NEW RULE
    const [newRuleId, setNewRuleId] = useState(null);
    const [newRuleTitle, setNewRuleTitle] = useState("");
    useEffect(() => {
        getRuleValidity();
    }, [newRuleTitle]);
    const [newRuleImage, setNewRuleImage] = useState(null);
    const [newRuleDescription, setNewRuleDescription] = useState("");
    useEffect(() => {
        getRuleValidity();
    }, [newRuleDescription]);
    const [newRuleComposition, setNewRuleComposition] = useState(
        {
            nodes: [],
            edges: []
        }
    );
    useEffect(() => {
        getRuleValidity();
    }, [newRuleComposition]);
    const [newRuleCategory, setNewRuleCategory] = useState("");
    const [newRulePopular, setNewRulePopular] = useState(false);
    const [newRuleIsValid, setNewRuleIsValid] = useState(false);
    useEffect(() => {
        console.log("IsRuleValid", newRuleIsValid);
    }, [newRuleIsValid]);
    // Check rule validity
    const getRuleValidity = () => {
        // Get rule block index
        var rule_block_index = -1;
        for (var i = 0; i < newRuleComposition.nodes.length; i++) {
            if ((newRuleComposition.nodes[i].type !== "Node_Rule_Placeholder" && newRuleComposition.nodes[i].type !== "Node_Condition_Placeholder" && newRuleComposition.nodes[i].type !== "Node_Perimeter_Placeholder") && (newRuleComposition.nodes[i].type.includes("Node_Rule_") || newRuleComposition.nodes[i].type.includes("Node_Condition") || newRuleComposition.nodes[i].type.includes("Node_Perimeter"))) {
                rule_block_index = i;
                break;
            }
        }
        // Get rule targets values check
        var rule_check = false;
        if (rule_block_index > -1) {
            rule_check = true;
            newRuleComposition.nodes[rule_block_index].data.target.forEach(target => {
                if (target.value === null) {
                    rule_check = false;
                }
            });

        }

        if (newRuleTitle !== "" && newRuleDescription !== "" && rule_check === true) {
            setNewRuleIsValid(true);
        }
        else {
            setNewRuleIsValid(false);
        }
    }
    // Reset new rule's data
    const resetRule = () => {
        console.log("RESET RULE");
        // Reset fields
        setNewRuleId(null);
        setNewRuleTitle("");
        setNewRuleImage(null);
        document.getElementById("rulecatalog_title").value = "";
        setNewRuleDescription("");
        document.getElementById("rulecatalog_description").value = "";
        setNewRuleComposition({
            nodes: [],
            edges: []
        });
        setNewRuleCategory("");
        document.getElementById("rulecatalog_category").value = "";
        setNewRulePopular(false);
        // Reset is valid
        getRuleValidity();
    }



    // Drag from node library
    const onDragStart = (event, node_type, group_id) => {
        event.dataTransfer.setData('application/reactflow', group_id + "--" + node_type);
        event.dataTransfer.effectAllowed = 'move';
    };

    // Get rule model
    const ruleGetModel = () => {
        // Get id
        var newId = newRuleId;
        if (newId === null) {
            newId = "rule_" + Date.now().toString();
        }
        // Get main node
        var ruleNode = null;
        for (var i = 0; i < newRuleComposition.nodes.length; i++) {
            if (newRuleComposition.nodes[i].type.includes("Node_Rule_") || newRuleComposition.nodes[i].type.includes("Node_Condition") || newRuleComposition.nodes[i].type.includes("Node_Perimeter")) {
                ruleNode = newRuleComposition.nodes[i];
            }
        }
        console.log("ruleNode", ruleNode);
        // Get variables
        var variables = [];
        variables = nodeHelpers.nodeInterpretorVariables(ruleNode.data.source, capacity);
        console.log("ruleNode.data.source", ruleNode.data.source);
        console.log("variables", variables);
        var variables_description = [];
        var variables_input = [];
        variables.forEach(variable => {
            if (newRuleDescription.indexOf("{" + variable.label + "}") >= 0) {
                variable.startIndex = newRuleDescription.indexOf("{" + variable.label + "}");
                variables_description.push(variable);
            }
            else {
                variables_input.push(variable);
            }
        })
        // Get result variable
        if (newRuleDescription.indexOf("{result}") >= 0) {
            var result_var = {
                "type": "result",
                "label": "result",
                "startIndex": newRuleDescription.indexOf("{result}")
            }
            variables_description.push(result_var);
        }
        // Get params
        var params = nodeHelpers.nodeInterpretorRuleParams(ruleNode.data.source, capacity);

        // Create model
        const ruleData = {
            id: newId,
            title: newRuleTitle,
            description: newRuleDescription,
            description_splitted: newRuleDescription.split(/[{}]/),
            category: newRuleCategory,
            popular: newRulePopular,
            composition: newRuleComposition,
            creatorId: user.result._id,
            // variables: variables,
            variables_description: variables_description,
            variables_input: variables_input,
            params: params,
            image: newRuleImage
        }

        // Erase heavy data from nodes
        ruleData.composition.nodes.forEach(node => {     // A REMETTRE ABSOLUMENT !!!
            node.data.capacity = null;
            node.data.handleChange = null;
            node.data.handleDelete = null;
        });
        console.log("ruleData", ruleData);

        return ruleData;
    }

    // Add rule
    const ruleAdd = () => {
        console.log("ADD");

        // Save to catalog
        handleRuleUpdate();

        // Get rule model
        var ruleData = ruleGetModel();

        // Add to study
        rule_Add(ruleData);
    }

    // Save rule
    const ruleSave = () => {
        console.log("SAVE");
        handleRuleUpdate();
        handleSectionSlide(0);
    }

    const handleRuleUpdate = () => {
        // Get rule model
        var ruleData = ruleGetModel();

        // Send to backend
        dispatch(ruleUpdate(user.teams[0]._id, { ruleData }, handleRuleUpdateSuccess, handleRuleUpdateFail));
    }
    const handleRuleUpdateSuccess = (data) => {
        console.log("SUCCESS", data);
        // setRulesCatalogTeam({
        //     rulesCatalog: data.rulesCatalog,
        //     rulesTeam: data.rulesTeam
        // })
        rulesUpdate(data);
    }
    const handleRuleUpdateFail = (error) => {
        console.log("FAIL", error.response.data.message);
        // If session expired => back to signin
        if (error?.response?.data?.message === "La session a expiré.") {
            console.log("Redirecting to SignIn");
            setTimeout(() => {
                navigate('/auth?type=signin&cause=expired&source=' + window.location.pathname);
            }, 500);
        }
    }


    // ADD RULE TO CAPA
    const rule_Add = (ruleData) => {
        // Hard copy
        var ruleData_new = JSON.parse(JSON.stringify(ruleData));

        // Set ids
        ruleData_new.catalogId = ruleData.id;
        ruleData_new.id = "ruleData_" + (Date.now()).toString();

        // Reset default values
        var variables_text = " (";
        var variables = ruleData_new.variables_description.concat(ruleData_new.variables_input);
        variables.forEach((variable, variable_index) => {
            if (variable?.type === "Node_UserInput_Draw_Point") {
                var mainNodeValue_new = getNewMainNodeValue(ruleData_new, variable, "Node_2DElement_MapInput[point_()]");
                console.log("mainNodeValue_new", mainNodeValue_new);
                ruleData_new.composition.nodes[0].data.source[0].value = mainNodeValue_new;
            }
            else if (variable?.type === "Node_UserInput_Draw_Line") {
                var mainNodeValue_new = getNewMainNodeValue(ruleData_new, variable, "Node_2DElement_MapInput[line_()]");
                console.log("mainNodeValue_new", mainNodeValue_new);
                ruleData_new.composition.nodes[0].data.source[0].value = mainNodeValue_new;
            }
            else if (variable?.type === "Node_UserInput_Draw_Polygon") {
                var mainNodeValue_new = getNewMainNodeValue(ruleData_new, variable, "Node_2DElement_MapInput[polygon_()]");
                console.log("mainNodeValue_new", mainNodeValue_new);
                ruleData_new.composition.nodes[0].data.source[0].value = mainNodeValue_new;
            }
            if (variable_index > 0 && variable_index === variables.length - 1) { variables_text = variables_text + " et "}
            else if (variable_index > 0) { variables_text = variables_text + ", "}
            variables_text = variables_text + variable.label + " = " + variable.value
        })
        variables_text = variables_text + ")";

        // Interpret
        if (currentType === "rule") {
            console.log("ADD RULE to " + ruleGroup_Id, ruleData_new);
            // Create new element model
            // var ruleData_new = { ...ruleData };
            var ruleNew = { id: (Date.now()).toString(), name: ruleData_new.title, type: "rule", data: ruleData_new };
            ruleNew.interpreted = nodeHelpers.nodeInterpretor(ruleData_new.composition.nodes[0].data.source, capacity);
            // Get current rules
            var rules = capacity.rules;
            // Get current ruleset
            var ruleset = rules.ruleset;
            // Search ruleset
            ruleset.forEach(ruleGroup => {
                if (ruleGroup.id === ruleGroup_Id) {
                    // Add rule
                    ruleGroup.items.push(ruleNew);
                }
            })
            // Set numerotation
            rules = rulesHelpers.getRulesNumerotation(rules);
            // Update capacity
            // NOT FORGET TO GET BACK SAVING
            setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: "Ajout de la règle", value: ruleData.title + variables_text, keys: ["rules"] } });
            // setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: "Ajout de la règle", value: ruleData.title } });
            setRuleUpdateTracker((Date.now()).toString());
            // Clsoe rule catalog
            setIsRuleCatalogOpen(false);
        }
        else if ((currentType === "condition" || currentType === "perimeter") && !ruleGroup_Id.includes("ruleGroup_")) {
            console.log("ADD CONDITION OR PERIMETER to " + ruleGroup_Id, ruleData_new);
            // Create new element model
            var ruleNew = { id: (Date.now()).toString(), name: ruleData_new.title, type: currentType, data: ruleData_new };
            ruleNew.interpreted = nodeHelpers.nodeInterpretor(ruleData_new.composition.nodes[0].data.source, capacity);
            // Get current rules
            var rules = capacity.rules;
            // Get current ruleset
            var ruleset = rules.ruleset;
            // Search rule
            var ruleTitle = "";
            var saveText = "Modification de la règle";
            ruleset.forEach(ruleGroup => {
                ruleGroup.items.forEach(item => {
                    if (item.id === ruleGroup_Id) {
                        ruleTitle = item.name;
                        // Create params if does not exists
                        if (!item?.data?.params) {
                            item.data.params = {
                                "condition": {
                                    "value": false,
                                    "data": null
                                },
                                "perimeter": {
                                    "value": false,
                                    "data": null
                                }
                            }
                        }
                        // Update params
                        if (currentType === "condition") {
                            item.data.params.condition = {
                                value: true,
                                data: ruleNew
                            }
                            saveText = "Ajout d'une condition d'application à la règle";
                        }
                        else if (currentType === "perimeter") {
                            item.data.params.perimeter = {
                                value: true,
                                data: ruleNew
                            }
                            saveText = "Ajout d'un périmètre d'application à la règle";
                        }
                        // Interprete
                        if ((currentType === "condition" && ruleNew.interpreted === false) || (currentType === "perimeter" && item?.data?.params?.condition?.data?.interpreted === false)) {
                            item.interpreted = [];
                        }
                        else if (currentType === "perimeter") {
                            item.interpreted = nodeHelpers.nodeInterpretor(item.data.composition.nodes[0].data.source, capacity, item?.data?.params?.perimeter);
                        }
                    }
                })
            })
            // Update capacity
            setCapacity({ ...capacity, rules: rules, toSave: { autoSave: false, action: saveText, value: ruleTitle + variables_text, keys: ["rules"] } });
            // setCapacity({ ...capacity, rules: rules, toSave: { autoSave: true, action: saveText, value: ruleTitle } });
            setRuleUpdateTracker((Date.now()).toString());
            // Clsoe rule catalog
            setIsRuleCatalogOpen(false);
        }
    }

    const getNewMainNodeValue = (ruleData_new, variable, replaceString) => {
        console.log("REPLACE DEFAULT VALUE", variable);
        var mainNodeValue = ruleData_new.composition.nodes[0].data.source[0].value;
        console.log("mainNodeValue", mainNodeValue);
        console.log("searchString", variable.searchString);
        var startIndex = mainNodeValue.indexOf(variable.searchString);
        console.log("startIndex", startIndex);
        var newShortString = mainNodeValue.substring(startIndex + variable.searchString.length, mainNodeValue.length);
        var endIndex = newShortString.indexOf("]");
        var count = 0;
        for (var i = 0; i < newShortString.length; i++) {
            if (newShortString[i] === "[") {
                count++;
            }
            if (newShortString[i] === "]") {
                count--;
            }
            if (count < 0) {
                endIndex = i;
                break;
            }
        }
        console.log("endIndex", endIndex);
        var leftPart = mainNodeValue.substring(0, startIndex) + variable.searchString;
        var rightPart = newShortString.substring(endIndex, newShortString.length);

        var mainNodeValue_new = leftPart + replaceString + rightPart;

        return mainNodeValue_new
    }

    // MODIFY RULE
    const rule_Modify = (ruleData) => {
        console.log("MODIFY RULE", ruleData);

        // Set fields
        setNewRuleId(ruleData.id);
        setNewRuleTitle(ruleData.title);
        setNewRuleImage(ruleData?.image || null);
        document.getElementById("rulecatalog_title").value = ruleData.title;
        setNewRuleDescription(ruleData.description);
        document.getElementById("rulecatalog_description").value = ruleData.description;
        setNewRuleCategory(ruleData.category);
        document.getElementById("rulecatalog_category").value = ruleData.category;
        setNewRulePopular(ruleData.popular);
        // Set is valid
        getRuleValidity();
        // Set composition
        setNewRuleComposition(ruleData.composition);
        // Go to section 1
        handleSectionSlide(1);
    }

    // DELETE RULE
    const rule_Delete = (ruleData) => {
        ruleData.delete = true;
        console.log("DELETE RULE ID", ruleData);

        // Send to backend
        dispatch(ruleUpdate(user.teams[0]._id, { ruleData }, handleRuleUpdateSuccess, handleRuleUpdateFail));

    }

    // IMPORT RULE
    const [isRuleValid, setIsRuleValid] = useState({state: ""});
    const [importRule, setImportRule] = useState("");
    const [parsedRule, setParsedRule] = useState(null);
    const handleJsonChange = (e) => {
        setIsRuleValid({...isRuleValid, state: "loading"});
        setImportRule(e.target.value);
    }
    useEffect(() => {
        var isValid = checkRuleValidity(importRule);
    }, [importRule]);

    const checkRuleValidity = (rule) => {
        if (rule === "") {
            setIsRuleValid({...isRuleValid, state: "null"});
            setParsedRule(null);
        }
        else {
            try {
                var rule_parsed = JSON.parse(rule);
                console.log("rule_parsed", rule_parsed);
                var rule_parsed_title = rule_parsed?.title;
                var rule_parsed_description = rule_parsed?.description_splitted;
                var rule_parsed_image = rule_parsed?.image || null;
                setIsRuleValid({...isRuleValid, state: "valid", title: rule_parsed_title, description_splitted: rule_parsed_description, image: rule_parsed_image});
                setParsedRule(rule_parsed);
            } catch (e) {
                console.log("Error parsing JSON", e);
                setIsRuleValid({...isRuleValid, state: "invalid"});
                setParsedRule(null);
            }
        }
    }

    const resetImport = () => {
        document.getElementById("rulecatalog_import").value = "";
        setIsRuleValid({...isRuleValid, state: "null"});
        setImportRule("");
    }

    const rule_Import = () => {
        var ruleData = JSON.parse(importRule);
        // Change ID
        ruleData.id = "rule_" + Date.now().toString();
        // Change user ID
        ruleData.creatorId = user.result._id;

        console.log("IMPORT", ruleData);

        // Send to backend
        dispatch(ruleUpdate(user.teams[0]._id, { ruleData }, handleRuleUpdateSuccess, handleRuleUpdateFail));
        // Back to first page of catalog 
        handleSectionSlide(0);
    }


    // Image
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const handleFileRead = async (event) => {
        setIsLoadingImage(true);
        // Get file from input
        const file = event.target.files[0];
        console.log(file);
        // Convert to base64
        const base64 = await convertBase64(file);
        console.log(base64);
        // Set base64 as image
        // handleSubmit_changeImageTeam(base64);
        // Reset input
        // document.getElementById("newRule_image").value = "";

        setNewRuleImage(base64);

        setIsLoadingImage(false);
    }
    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }



    return (
        <div className="app__rulecatalog_back">
            {/* <div className="app__rulecatalog_container scale-up-center"> */}
            <div className="app__rulecatalog_container">
                <div className="app__rulecatalog_close_container" onClick={closeRuleCatalog}><img src={close} alt="close" /></div>
                <div className="app__rulecatalog_header_container">
                    <div className="app__rulecatalog_header_subcontainer">
                        <div id="rulecatalog_label_0" className={`app__rulecatalog_header_label ${currentSlide === 0 ? "app__rulecatalog_header_label_focus" : ""}`} onClick={() => { handleSectionSlide(0) }}>{currentType === "rule" ? "Catalogue de règles" : currentType === "condition" ? "Catalogue de conditions" : "Catalogue de périmètres"}</div>
                        {window.innerWidth > 550 &&
                            <div id="rulecatalog_label_1" className={`app__rulecatalog_header_label ${currentSlide === 1 ? "app__rulecatalog_header_label_focus" : ""}`} onClick={() => { handleSectionSlide(1) }}>{currentType === "rule" ? "Créer une règle" : currentType === "condition" ? "Créer une condition" : "Créer un périmètre"}</div>
                        }
                        {(window.innerWidth > 550 && user?.result?.email.includes("@fractal-buildings.com")) &&
                            <div id="rulecatalog_label_2" className={`app__rulecatalog_header_label ${currentSlide === 2 ? "app__rulecatalog_header_label_focus" : ""}`} onClick={() => { handleSectionSlide(2) }}>{currentType === "rule" ? "Importer une règle" : currentType === "condition" ? "Importer une condition" : "Importer un périmètre"}</div>
                        }
                        <div className={window.innerWidth > 550 ? "app__rulecatalog_header_indicator" : "app__rulecatalog_header_indicator_hidden"} style={{ top: indicatorOffset.top + 'px', left: indicatorOffset.left + 'px' }}></div>
                    </div>
                </div>
                {user?.result?.email.includes("@fractal-buildings.com") &&
                    <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: "center", color: "lightgrey", fontSize: "12px", margin: "4px 0px 10px" }}>
                        <div className={`app__rulecatalog_type_btn ${currentType === "rule" ? "app__rulecatalog_type_btn_selected" : ""}`} onClick={() => { handleTypeChange("rule") }}>Règle</div>
                        <div className={`app__rulecatalog_type_btn ${currentType === "condition" ? "app__rulecatalog_type_btn_selected" : ""}`} onClick={() => { handleTypeChange("condition") }}>{window.innerWidth > 550 ? "Condition d'application" : "Condition"}</div>
                        <div className={`app__rulecatalog_type_btn ${currentType === "perimeter" ? "app__rulecatalog_type_btn_selected" : ""}`} onClick={() => { handleTypeChange("perimeter") }}>{window.innerWidth > 550 ? "Périmètre d'application" : "Périmètre"}</div>
                    </div>
                }
                <div className="app__rulecatalog_body_container">
                    {/* SECTION CATALOG */}
                    <div className={`app__rulecatalog_section app__rulecatalog_section_moved_${currentSlide}`}>
                        {/* RULES FILTER */}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="app__rulecatalog_filter_container">
                                <div className="common__input_container">
                                    {/* <input className="app__rulecatalog_filter" style={{ color: 'lightgrey' }} type="text" id="rulecatalog_filter" name="filter" placeholder="Rechercher une règle" onChange={(e) => { }}></input> */}
                                    <input type="text" className="app__rulecatalog_filter" placeholder="Rechercher" name="ruleCatalogFilter" id="ruleCatalogFilter" value={filterString} onChange={(e) => { handleFilterChange(e.target.value) }} autoComplete="off"></input>
                                    <label htmlFor="ruleCatalogFilter" className="app__rulecatalog_filter_label">{currentType === "rule" ? "Rechercher une règle" : currentType === "condition" ? "Rechercher une condition" : currentType === "perimeter" ? "Rechercher un périmètre" : "Rechercher"}</label>
                                    <img className={filterString !== "" ? "app__rulecatalog_filter_ico" : "app__rulecatalog_filter_ico app__rulecatalog_filter_ico_hidden"} src={close} alt='reset' onClick={() => { handleFilterChange("") }} />
                                </div>
                            </div>
                        </div>
                        <div className="app__sectiondata_widget_spacer_30"></div>
                        {/* POPULAR RULES */}
                        {(filteredRulesCatalog?.popular && filteredRulesCatalog.popular.length > 0) &&
                            <section>
                                <div className="app__rulecatalog_title_container">
                                    <div className="app__rulecatalog_title">{currentType === "rule" ? "Règles les plus populaires" : currentType === "condition" ? "Conditions les plus populaires" : "Périmètres les plus populaires"}</div>
                                </div>
                                <div className="app__rulecatalog_rule_grid_pop">
                                    {filteredRulesCatalog.popular.map((rule, index) => {
                                        var test = false;
                                        for (var j = 0; j < rule.composition.nodes.length; j++) {
                                            if (currentType === "rule" && rule.composition.nodes[j].type.indexOf("Node_Rule") === 0) {
                                                test = true;
                                                break;
                                            }
                                            if (currentType === "condition" && rule.composition.nodes[j].type.indexOf("Node_Condition") === 0) {
                                                test = true;
                                                break;
                                            }
                                            if (currentType === "perimeter" && rule.composition.nodes[j].type.indexOf("Node_Perimeter") === 0) {
                                                test = true;
                                                break;
                                            }
                                        }
                                        if (test === true) {
                                            return (
                                                <Rule key={"rule_" + index} rule={rule} user={user} currentType={currentType} showUser={false} isPopular={true} rule_Add={rule_Add} rule_Modify={rule_Modify} rule_Delete={rule_Delete} />
                                            )
                                        }
                                        // return (
                                        //     <Rule style={{ marginLeft: index === 0 ? "20px" : "", marginRight: index === (filteredRulesCatalog.popular.length - 1) ? "20px" : "" }} rule={rule} user={user} currentType={currentType} showUser={false} rule_Add={rule_Add} rule_Modify={rule_Modify} rule_Delete={rule_Delete} />
                                        // )
                                    })}
                                </div>
                                <div className="app__sectiondata_widget_spacer_30"></div>
                            </section>
                        }
                        <div className="app__rulecatalog_subsection">
                            {/* RULES OF CATALOG BY CATEGORY */}
                            {Object.keys(filteredRulesCatalog).map((category, index) => {
                                var testCategory = false;
                                for (var i = 0; i < filteredRulesCatalog[category].length; i++) {
                                    for (var j = 0; j < filteredRulesCatalog[category][i].composition.nodes.length; j++) {
                                        if (currentType === "rule" && filteredRulesCatalog[category][i].composition.nodes[j].type.indexOf("Node_Rule") === 0) {
                                            testCategory = true;
                                            break;
                                        }
                                        if (currentType === "condition" && filteredRulesCatalog[category][i].composition.nodes[j].type.indexOf("Node_Condition") === 0) {
                                            testCategory = true;
                                            break;
                                        }
                                        if (currentType === "perimeter" && filteredRulesCatalog[category][i].composition.nodes[j].type.indexOf("Node_Perimeter") === 0) {
                                            testCategory = true;
                                            break;
                                        }
                                    }
                                    if (testCategory === true) {
                                        break;
                                    }
                                }
                                if (category !== "popular" && testCategory === true) {
                                    return (
                                        <section key={"section_" + index}>
                                            {/* {(filteredRulesCatalog?.popular && filteredRulesCatalog.popular.length > 0) &&
                                            <div className="app__sectiondata_widget_spacer_40"></div>
                                        } */}
                                            <div className="app__rulecatalog_title_container">
                                                <div className="app__rulecatalog_title">{category}</div>
                                            </div>
                                            <div className="app__rulecatalog_rule_grid">
                                                {filteredRulesCatalog[category].map((rule, rule_index) => {
                                                    var test = false;
                                                    for (var j = 0; j < rule.composition.nodes.length; j++) {
                                                        if (currentType === "rule" && rule.composition.nodes[j].type.indexOf("Node_Rule") === 0) {
                                                            test = true;
                                                            break;
                                                        }
                                                        if (currentType === "condition" && rule.composition.nodes[j].type.indexOf("Node_Condition") === 0) {
                                                            test = true;
                                                            break;
                                                        }
                                                        if (currentType === "perimeter" && rule.composition.nodes[j].type.indexOf("Node_Perimeter") === 0) {
                                                            test = true;
                                                            break;
                                                        }
                                                    }
                                                    if (test === true) {
                                                        return (
                                                            <Rule key={"rule2_" + rule_index} rule={rule} user={user} currentType={currentType} showUser={false} isPopular={false} rule_Add={rule_Add} rule_Modify={rule_Modify} rule_Delete={rule_Delete} />
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </section>
                                    )
                                }
                            })}
                            {Object.keys(filteredRulesCatalog).length === 0 &&
                                <div className="app__rulecatalog_message">
                                    <div style={{ fontSize: "20px" }}>😓</div>
                                    {window.innerWidth < 550 ?
                                        <div>{currentType === "rule" ? "Aucune règle du catalogue ne correspond à votre recherche." : currentType === "condition" ? "Aucune condition du catalogue ne correspond à votre recherche." : "Aucun périmètre du catalogue ne correspond à votre recherche."}</div>
                                        :
                                        <>
                                            {currentType === "rule" ?
                                                <div>Aucune règle du catalogue ne correspond à votre recherche, <span className="app__ruleset_rule_condition_link" onClick={() => { handleSectionSlide(1) }}>créez en une nouvelle</span>.</div>
                                                :
                                                currentType = "condition" ?
                                                    <div>Aucune condition du catalogue ne correspond à votre recherche, <span className="app__ruleset_rule_condition_link" onClick={() => { handleSectionSlide(1) }}>créez en une nouvelle</span>.</div>
                                                    :
                                                    <div>Aucun périmètre du catalogue ne correspond à votre recherche, <span className="app__ruleset_rule_condition_link" onClick={() => { handleSectionSlide(1) }}>créez en un nouveau</span>.</div>
                                            }
                                        </>}
                                </div>
                            }
                            {/* RULES OF TEAM */}
                            {filteredRulesTeam.length > 0 &&
                                <section>
                                    <div className="app__sectiondata_widget_spacer_40"></div>
                                    <div className="app__rulecatalog_title_container">
                                        <div className="app__rulecatalog_title">{currentType === "rule" ? "Règles créées par mon équipe" : currentType === "condition" ? "Conditions créées par mon équipes" : "Périmètres créés par mon équipe"}</div>
                                    </div>
                                    <div className="app__rulecatalog_rule_grid">
                                        {filteredRulesTeam.map((rule, index) => {
                                            var test = false;
                                            for (var j = 0; j < rule.composition.nodes.length; j++) {
                                                if (currentType === "rule" && rule.composition.nodes[j].type.indexOf("Node_Rule") === 0) {
                                                    test = true;
                                                    break;
                                                }
                                                if (currentType === "condition" && rule.composition.nodes[j].type.indexOf("Node_Condition") === 0) {
                                                    test = true;
                                                    break;
                                                }
                                                if (currentType === "perimeter" && rule.composition.nodes[j].type.indexOf("Node_Perimeter") === 0) {
                                                    test = true;
                                                    break;
                                                }
                                            }
                                            if (test === true) {
                                                return (
                                                    <Rule key={"rule_" + index} rule={rule} user={user} currentType={currentType} showUser={true} isPopular={false} rule_Add={rule_Add} rule_Modify={rule_Modify} rule_Delete={rule_Delete} />
                                                )
                                            }
                                        })}
                                    </div>
                                </section>
                            }
                        </div>
                    </div>
                    {/* SECTION : RULE CREATION */}
                    <div className={`app__rulecatalog_section app__rulecatalog_section_moved_${currentSlide}`}>
                        {/* <div>
                            <div className="app__rulecatalog_title">Titre</div>
                            <input className="app__sectiondata_widget_inputSingleLine" style={{ color: 'lightgrey' }} type="text" id="rulecatalog_title" name="title" placeholder="Veuillez entrer le titre de la règle" required onChange={(e) => { setNewRuleTitle(e.target.value) }}></input>
                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div className="app__rulecatalog_title">Description</div>
                            <textarea className="app__sectiondata_widget_inputMultipleLine" style={{ color: 'lightgrey' }} id="rulecatalog_description" name="description" placeholder="Veuillez entrer la description de la règle" rows="1" onChange={(e) => { setNewRuleDescription(e.target.value) }}></textarea>
                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div className="app__rulecatalog_title">Catégorie</div>
                            <input className="app__sectiondata_widget_inputSingleLine" style={{ color: 'lightgrey' }} type="text" id="rulecatalog_category" name="title" placeholder="Veuillez entrer la catégorie de la règle" onChange={(e) => { setNewRuleCategory(e.target.value) }}></input>
                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div className="app__rulecatalog_title">Règle populaire</div>
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="rulecatalog_popular" checked={newRulePopular} onChange={() => { setNewRulePopular(!newRulePopular) }} /><label className="app__map_layer_checkbox_label" htmlFor="rulecatalog_popular">Toggle</label></div>
                            <div className="app__sectiondata_widget_spacer_20"></div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="app__rulecatalog_title">Composition</div>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <div className="app__rulecatalog_title">Mode assisté</div>
                                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="switch_helpMode" checked={helpMode} onChange={() => { setHelpMode(!helpMode) }} /><label className="app__map_layer_checkbox_label" htmlFor="switch_helpMode">Toggle</label></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="app__rulecatalog_ruleditor">
                            <div className="app__rulecatalog_ruleditor_data_container">
                                <div className="app__rulecatalog_ruleditor_data_subcontainer">
                                    <div className="app__rulecatalog_ruleditor_data_subcontainer_3">
                                        <div className="app__rulecatalog_title">Titre</div>
                                        <input className="app__sectiondata_widget_inputSingleLine" style={{ color: 'lightgrey' }} type="text" id="rulecatalog_title" name="title" placeholder="Veuillez entrer le titre de la règle" onChange={(e) => { setNewRuleTitle(e.target.value) }}></input>
                                        <div className="app__sectiondata_widget_spacer_20"></div>
                                        <div className="app__rulecatalog_title">Catégorie</div>
                                        <input className="app__sectiondata_widget_inputSingleLine" style={{ color: 'lightgrey' }} type="text" id="rulecatalog_category" name="title" placeholder="Veuillez entrer la catégorie de la règle" onChange={(e) => { setNewRuleCategory(e.target.value) }}></input>
                                        {user?.result?.email.includes("@fractal-buildings.com") &&
                                            <>
                                                <div className="app__sectiondata_widget_spacer_20"></div>
                                                <div style={{display: 'flex', gap: '6px'}}>
                                                    <div className="app__rulecatalog_title">Règle populaire</div>
                                                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="rulecatalog_popular" checked={newRulePopular} onChange={() => { setNewRulePopular(!newRulePopular) }} /><label className="app__map_layer_checkbox_label" htmlFor="rulecatalog_popular">Toggle</label></div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="app__rulecatalog_ruleditor_data_subcontainer_3">
                                        <div className="app__rulecatalog_title">Description</div>
                                        <textarea className="app__sectiondata_widget_inputMultipleLine" style={{ color: 'lightgrey', height: '100%' }} id="rulecatalog_description" name="description" placeholder="Veuillez entrer la description de la règle" rows="1" onChange={(e) => { setNewRuleDescription(e.target.value) }}></textarea>
                                    </div>
                                    {user?.result?.email.includes("@fractal-buildings.com") &&
                                        <div className="app__rulecatalog_ruleditor_data_subcontainer_3" style={{maxWidth: "140px"}}>
                                            <div className="app__rulecatalog_title">Image</div>
                                            <div className="app__rulecatalog_image_container">
                                                {newRuleImage !== null ?
                                                    <>
                                                        <img className="app__rulecatalog_image_container_box" src={newRuleImage} alt="" />
                                                        <div className="app__rulecatalog_image_container_box_modal">
                                                            {isLoadingImage === false ?
                                                                <>
                                                                    <div className="app__admin_team_subcontainer_image_box_modal_button" onClick={() => setNewRuleImage(null)}>Supprimer</div>
                                                                    <span>ou</span>
                                                                    <div>
                                                                        <label className="app__admin_team_subcontainer_image_box_modal_button" htmlFor={"newRule_image"}>Modifier</label>
                                                                        <input type="file" id={"newRule_image"} name="avatar" accept="image/png, image/jpeg" style={{ display: "none" }} onChange={e => handleFileRead(e)} />
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <span>Chargement...</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <label className={`app__rulecatalog_image_placeholder ${isLoadingImage ? "app__rulecatalog_image_placeholder_loading" : ""}`} htmlFor={"newRule_image"}>{isLoadingImage === false ? "Ajouter une image" : "Chargement..."}</label>
                                                        <input type="file" id={"newRule_image"} name="avatar" accept="image/png, image/jpeg" style={{ display: "none" }} onChange={e => handleFileRead(e)} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* <div className="app__sectiondata_widget_spacer_20"></div>
                                <div className="app__sectiondata_widget_spacer_20"></div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: "10px" }}>
                                    <div style={{ flex: "1" }}>
                                        <div className="app__rulecatalog_title">Catégorie</div>
                                        <input className="app__sectiondata_widget_inputSingleLine" style={{ color: 'lightgrey' }} type="text" id="rulecatalog_category" name="title" placeholder="Veuillez entrer la catégorie de la règle" onChange={(e) => { setNewRuleCategory(e.target.value) }}></input>
                                    </div>
                                    <div>
                                        <div className="app__rulecatalog_title">Règle populaire</div>
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="rulecatalog_popular" checked={newRulePopular} onChange={() => { setNewRulePopular(!newRulePopular) }} /><label className="app__map_layer_checkbox_label" htmlFor="rulecatalog_popular">Toggle</label></div>
                                    </div>
                                </div> */}
                                <div className="app__sectiondata_widget_spacer_20"></div>
                                <div style={{ display: 'flex', gap: '10px', position: "relative" }}>
                                    <div className="app__rulecatalog_title">Composition</div>
                                    <div className="app__rulecatalog_title" style={{ color: "#5f5e5e", cursor:'pointer',}} onClick={() => { setHelpMode(!helpMode) }}>Mode assisté : {helpMode ? "activé" : "désactivé"}</div>
                                    {/* <div style={{ display: 'flex', gap: '6px', position: "absolute", top: "30px", left: "6px", zIndex: "10000" }}>
                                        <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="switch_helpMode" checked={helpMode} onChange={() => { setHelpMode(!helpMode) }} /><label className="app__map_layer_checkbox_label" htmlFor="switch_helpMode">Toggle</label></div>
                                        <div style={{ fontSize: "12px", fontWeight: "500", color: "grey" }}>Mode assisté</div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="app__rulecatalog_ruleditor_compo_container">
                                <div className="app__rulecatalog_ruleditor_compo_container_sized">
                                    <div className="app__rulecatalog_ruleditor_compo_container_sized_section">
                                        <div className="app__ruleeditor__container">
                                            <RuleEditor block_list={{ ...block_list }} rule_list={{ ...rule_list }} capacity={capacity} helpMode={helpMode} newRuleComposition={newRuleComposition} setNewRuleComposition={setNewRuleComposition} currentType={currentType} />
                                        </div>
                                        {!helpMode &&
                                            <div className="app__ruleeditor_list_container">
                                                {Object.keys(block_list).map((item, index1) => {
                                                    return (
                                                        <div key={"group_" + index1} className="ruleeditor__node_ph_list_group_container">
                                                            <div className="ruleeditor__node_ph_list_group_title">{block_list[item].label}</div>
                                                            <div className="ruleeditor__node_ph_list_item_container">
                                                                {block_list[item].items.map((item, index2) => {
                                                                    return (
                                                                        <div key={"item_" + index2} className="ruleeditor__node_ph_list_item" onDragStart={(event) => onDragStart(event, item.node_type, item.group_id)} draggable>
                                                                            <div className="ruleeditor__node_ph_list_item_label">{item.data.label}</div>
                                                                            <div className="ruleeditor__node_ph_list_item_description">{item.data.description}</div>
                                                                            <div className="ruleeditor__node_ph_list_item_source">{item.data.source[0].acceptance}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                {index1 < (Object.keys(block_list).length - 1) &&
                                                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="app__ruleeditor__container">
                                <RuleEditor block_list={{ ...block_list }} rule_list={{ ...rule_list }} capacity={capacity} helpMode={helpMode} newRuleComposition={newRuleComposition} setNewRuleComposition={setNewRuleComposition} />
                            </div>
                            {!helpMode &&
                                <div className={`app__ruleeditor_list_container ${helpMode ? "app__ruleeditor_list_container_hidden2" : ""}`}>
                                    {Object.keys(block_list).map((item, index) => {
                                        return (
                                            <div key={"group_" + index} className="ruleeditor__node_ph_list_group_container">
                                                <div className="app__rulecatalog_title">{block_list[item].label}</div>
                                                <div className="ruleeditor__node_ph_list_item_container">
                                                    {block_list[item].items.map((item, index) => {
                                                        return (
                                                            <div key={"item_" + index} className="ruleeditor__node_ph_list_item" onDragStart={(event) => onDragStart(event, item.node_type, item.group_id)} draggable>
                                                                <div className="ruleeditor__node_ph_list_item_label">{item.data.label}</div>
                                                                <div className="ruleeditor__node_ph_list_item_description">{item.data.description}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            } */}
                        </div>

                        <div className="app__rulecatalog_buttons_container">
                            <div className={`app__rulecatalog_button ${newRuleIsValid ? "" : "app__rulecatalog_button_hidden"}`} onClick={() => { ruleAdd() }}>Ajouter à l'étude</div>
                            <div className={`app__rulecatalog_button ${newRuleIsValid ? "" : "app__rulecatalog_button_hidden"}`} onClick={() => { ruleSave() }}>Enregistrer dans le catalogue</div>
                        </div>
                    </div>
                    {/* SECTION : RULE IMPORT */}
                    <div className={`app__rulecatalog_section app__rulecatalog_section_moved_${currentSlide}`}>
                        <div className="app__rulecatalog_import_container">
                            <div className="app__rulecatalog_title">JSON</div>
                            <textarea id="rulecatalog_import" className="app__rulecatalog_textarea" name="textarea" onChange={handleJsonChange} placeholder={currentType === "rule" ? "Copiez le JSON de la règle ici" : currentType === "condition" ? "Copiez le JSON de la condition ici" : "Copiez le JSON du périmètre ici"}></textarea>
                            <div className="app__rulecatalog_import_preview_container">
                                {isRuleValid?.state === "null" &&
                                    <div className="app__rulecatalog_import_preview_message">Aucun JSON à analyser</div>
                                }
                                {isRuleValid?.state === "invalid" &&
                                    <div className="app__rulecatalog_import_preview_message_invalid">JSON non valide ⚠️</div>
                                }
                                {isRuleValid?.state === "loading" &&
                                    <div className="app__rulecatalog_import_preview_message">Analyse du JSON en cours...</div>
                                }
                                {isRuleValid?.state === "valid" &&
                                    <div>
                                        <div className="app__rulecatalog_rule_title">
                                            {isRuleValid?.title}
                                        </div>
                                        <div className="app__rulecatalog_rule_preview_body">
                                            <div className="app__rulecatalog_rule_description">
                                                {isRuleValid?.description_splitted &&
                                                    <>
                                                        {isRuleValid.description_splitted.map((text, index) => {
                                                            if (index % 2 === 0) {
                                                                return (
                                                                    <span key={"span_" + index}>{text}</span>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <span key={"span_" + index} className="app__rulecatalog_rule_description_variable">{text}</span>
                                                                )
                                                            }
                                                        })}
                                                    </>
                                                }
                                            </div>
                                            {isRuleValid?.image &&
                                                <div className="app__rulecatalog_rule_preview_image_container"><img src={isRuleValid?.image} /></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="app__rulecatalog_buttons_container">
                            <div className={`app__rulecatalog_button ${isRuleValid?.state === "valid" ? "" : "app__rulecatalog_button_hidden"}`} onClick={() => { rule_Import() }}>Enregistrer dans le catalogue</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RuleCatalog;