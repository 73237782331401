// FUNCTIONS

export const get_blockList = () => {
    return blockList;
}
export const get_ruleList = () => {
    return ruleList;
}


// DATA

const blockList = {
    input: {
        label: "Valeurs",
        id: "input",
        items: [
            {
                node_type: "Node_Input_String",
                group_id: "input",
                data: {
                    // Global data
                    label: "Texte",
                    description: "Texte prédéfini",
                    height: 105,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "txt",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "texte"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Input_Number",
                group_id: "input",
                data: {
                    // Global data
                    label: "Nombre",
                    description: "Nombre prédéfini",
                    height: 105,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "a",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Input_Boolean",
                group_id: "input",
                data: {
                    // Global data
                    label: "Booléen",
                    description: "Booléen prédéfini",
                    height: 105,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "a",
                            id: "source_0",
                            value: null,
                            default: "Node_Input_Boolean[true]",
                            acceptance: "booléen"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Input_Property",
                group_id: "input",
                data: {
                    // Global data
                    label: "Propriété",
                    description: "Propriété issue d'un élément",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Elément",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                                "ligne",
                                "surface",
                            ]
                        },
                        {
                            label: "Nom de la propriété",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Propriété",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "tous types"
                        }
                    ],
                }
            },
        ]
    },
    user_input: {
        label: "Variables : Valeurs",
        id: "user_input",
        items: [
            // {
            //     node_type: "Node_UserInput_String",
            //     group_id: "user_input",
            //     data: {
            //         // Global data
            //         label: "Texte",
            //         description: "Texte défini par l'utilisateur",
            //         height: 105,
            //         // Targets
            //         target: [],
            //         // Source
            //         source: [
            //             {
            //                 label: "txt",
            //                 id: "source_0",
            //                 value: null,
            //                 default: null,
            //                 acceptance: "texte"
            //             }
            //         ],
            //     }
            // },
            {
                node_type: "Node_UserInput_Number",
                group_id: "user_input",
                data: {
                    // Global data
                    label: "Variable : Nombre",
                    description: "Variable de type 'nombre' définie par l'utilisateur, avec une valeur par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Number_Range",
                group_id: "user_input",
                data: {
                    // Global data
                    label: "Variable : Nombre dans un intervalle",
                    description: "Variable de type 'nombre' définie par l'utilisateur, avec une valeur par défaut, une valuer minimum et une valeur maximum",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Valeur minimum",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Valeur maximum",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Boolean",
                group_id: "user_input",
                data: {
                    // Global data
                    label: "Variable : Booléen",
                    description: "Variable de type 'booléen' définie par l'utilisateur, avec une valeur par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "booléen"
                        }
                    ],
                }
            },
        ]
    },
    operator: {
        label: "Opérateurs de valeurs",
        id: "operator",
        items: [
            {
                node_type: "Node_Operator_Addition",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Addition",
                    description: "Additionne deux valeurs : A + B",
                    height: 150,
                    // Targets
                    target: [
                        {
                            label: "A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "A + B",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Substraction",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Soustraction",
                    description: "Soustrait deux valeurs : A - B",
                    height: 150,
                    // Targets
                    target: [
                        {
                            label: "A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "A - B",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Multiplication",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Multiplication",
                    description: "Multiplie deux valeurs : A x B",
                    height: 150,
                    // Targets
                    target: [
                        {
                            label: "A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "A x B",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Division",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Division",
                    description: "Divise deux valeurs : A / B",
                    height: 150,
                    // Targets
                    target: [
                        {
                            label: "A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "A / B",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Comparison",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Comparaison",
                    description: "Compare deux valeurs A et B et renvoie Vrai ou Faux",
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Comparaison",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "booléen"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Logical",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Porte logique",
                    description: "Compare deux valeurs A et B selon une porte logique et renvoie Vrai ou Faux",
                    height: 201,
                    // Targets
                    target: [
                        {
                            label: "A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Résultat",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "booléen"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Condition",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Condition",
                    description: "Renvoie la valeur A si le test de condition est Vrai, ou la valeur B si le test est Faux",
                    height: 201,
                    // Targets
                    target: [
                        {
                            label: "Test",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "A",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "tous types",
                            ]
                        },
                        {
                            label: "B",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "tous types",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Résultat",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "tous types"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Operator_Calcul_Area",
                group_id: "operator",
                data: {
                    // Global data
                    label: "Calcul d'aire",
                    description: "Calcule l'air d'une surface en m²",
                    height: 201,
                    // Targets
                    target: [
                        {
                            label: "Surface",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Aire",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
        ]
    },
    study: {
        label: "Eléments de l'étude",
        id: "study",
        items: [
            {
                node_type: "Node_Study_Land",
                group_id: "study",
                data: {
                    // Global data
                    label: "Terrain",
                    description: "Terrain de l'étude",
                    height: 105,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Terrain",
                            id: "source_0",
                            value: "Node_Study_Land[]",
                            default: "Node_Study_Land[]",
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Buildable_Height",
                group_id: "study",
                data: {
                    // Global data
                    label: "Hauteur du volume constructible",
                    description: "Hauteur du volume constructible",
                    height: 105,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Hauteur",
                            id: "source_0",
                            value: "Node_Study_Buildable_Height[]",
                            default: "Node_Study_Buildable_Height[]",
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Bounds",
                group_id: "study",
                data: {
                    // Global data
                    label: "Limites",
                    description: "Limites du terrain d'étude",
                    height: 275,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Aucune limite sélectionnée",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "ligne"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Lands",
                group_id: "study",
                data: {
                    // Global data
                    label: "Parcelles",
                    description: "Parcelles du terrain d'étude",
                    height: 275,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Aucune parcelle sélectionnée",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_PLUZone",
                group_id: "study",
                data: {
                    // Global data
                    label: "Zones PLU",
                    description: "Zones PLU",
                    height: 275,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Aucune zone sélectionnée",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Perimeter",
                group_id: "study",
                data: {
                    // Global data
                    label: "Périmètre d'application de la règle",
                    description: "Périmètre d'application de la règle",
                    height: 275,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Surface",
                            id: "source_0",
                            value: "Node_Study_Perimeter[]",
                            default: "Node_Study_Perimeter[]",
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Levels",
                group_id: "study",
                data: {
                    // Global data
                    label: "Niveaux de construction",
                    description: "Niveaux de construction",
                    height: 180,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Surface",
                            id: "source_0",
                            value: "Node_Study_Levels[]",
                            default: "Node_Study_Levels[]",
                            acceptance: "niveau"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Building_Area_Total",
                group_id: "study",
                data: {
                    // Global data
                    label: "Surface totale construite",
                    description: "Surface totale des constructions simulées",
                    height: 105,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Aire",
                            id: "source_0",
                            value: "Node_Study_Building_Area_Total[]",
                            default: "Node_Study_Building_Area_Total[]",
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Study_Existing_Buildings",
                group_id: "study",
                data: {
                    // Global data
                    label: "Constructions existantes",
                    description: "Constructions existantes sur le terrain ou à proximité",
                    height: 260,
                    // Targets
                    target: [],
                    // Source
                    source: [
                        {
                            label: "Aucune construction sélectionnée",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            // {
            //     node_type: "Node_Study_Buildings_Footprint",
            //     group_id: "study",
            //     data: {
            //         // Global data
            //         label: "Constructions existantes",
            //         description: "Constructions existantes sur le terrain ou à proximité",
            //         height: 260,
            //         // Targets
            //         target: [],
            //         // Source
            //         source: [
            //             {
            //                 label: "Aucune construction sélectionnée",
            //                 id: "source_0",
            //                 value: null,
            //                 default: null,
            //                 acceptance: "surface"
            //             }
            //         ],
            //     }
            // },
            // {
            //     node_type: "Node_Study_Buildings_Height",
            //     group_id: "study",
            //     data: {
            //         // Global data
            //         label: "Hauteurs des constructions",
            //         description: "Hauteurs des constructions existantes sur le terrain ou nouvelles constructions simulées",
            //         height: 260,
            //         // Targets
            //         target: [],
            //         // Source
            //         source: [
            //             {
            //                 label: "Hauteurs",
            //                 id: "source_0",
            //                 value: "Node_Study_Buildings_Height[]",
            //                 default: "Node_Study_Buildings_Height[]",
            //                 acceptance: "nombre"
            //             }
            //         ],
            //     }
            // },
            // {
            //     node_type: "Node_Study_BuildingsFP_Inside",
            //     group_id: "study",
            //     data: {
            //         // Global data
            //         label: "Constructions existantes sur le terrain",
            //         description: "Constructions existantes sur le terrain",
            //         height: 275,
            //         // Targets
            //         target: [],
            //         // Source
            //         source: [
            //             {
            //                 label: "Aucun type sélectionné",
            //                 id: "source_0",
            //                 value: null,
            //                 default: null,
            //                 acceptance: "ligne"
            //             }
            //         ],
            //     }
            // },
        ]
    },
    user_study: {
        label: "Variables : Eléments de l'étude",
        id: "user_study",
        items: [
            {
                node_type: "Node_UserInput_Bounds",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Limites",
                    description: "Limites du terrain définies par l'utilisateur, avec une sélection par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "ligne",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "ligne"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Lands",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Parcelles",
                    description: "Parcelles du terrain définies par l'utilisateur, avec une sélection par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_PLUZone",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Zones PLU",
                    description: "Zones PLU définies par l'utilisateur, avec une sélection par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Levels",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Niveaux de construction",
                    description: "Niveaux de construction sélectionnés par l'utilisateur, avec une sélection par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "niveau",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "niveau"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Buildings",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Constructions existantes",
                    description: "Constructions sélectionnées par l'utilisateur, avec une sélection par défaut",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Draw_Point",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Point placé",
                    description: "Point placé sur la carte par l'utilisateur",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "point"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Draw_Line",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Ligne tracée",
                    description: "Ligne tracée sur la carte par l'utilisateur",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "ligne",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "ligne"
                        }
                    ],
                }
            },
            {
                node_type: "Node_UserInput_Draw_Polygon",
                group_id: "user_study",
                data: {
                    // Global data
                    label: "Variable : Polygone tracé",
                    description: "Polygone tracé sur la carte par l'utilisateur",
                    height: 105,
                    // Targets
                    target: [
                        {
                            label: "Nom de la variable",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                        {
                            label: "Valeur par défaut",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Variable",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
        ]
    },
    element2D: {
        label: "Eléments 2D",
        id: "element2D",
        items: [
            {
                node_type: "Node_2DElement_Point",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Point",
                    description: "Crée un élément 2D de type 'Point'",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "X",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Y",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Point",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "point"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Line",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Ligne",
                    description: "Crée un élément 2D de type 'Ligne'",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Point 1",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                            ]
                        },
                        {
                            label: "Point 2",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Ligne",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "ligne"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Polygon",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Polygone",
                    description: "Crée un élément 2D de type 'Polygone'",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Point 1",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                            ]
                        },
                        {
                            label: "Point 2",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                            ]
                        },
                        {
                            label: "Point 3",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Polygone",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Triangle",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Triangle",
                    description: "Crée un élément 2D de forme triangulaire, de hauteur 'H' et de largeur 'L'",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Hauteur : H",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur : L",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface triangulaire",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Rectangle",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Rectangle",
                    description: "Crée un élément 2D de forme rectangulaire, de hauteur 'H' et de largeur 'L'",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Hauteur : H",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur : L",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface rectangulaire",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Trapeze",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Trapèze (4 points)",
                    description: "Crée un élément 2D de forme trapèzoïdale, de hauteur 'H', de grande largeur 'L1' et de petite largeur 'L2'",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Hauteur : H",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 1 : L1",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 2 : L2",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface trapèzoïdale",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Trapeze_5",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Trapèze (5 points)",
                    description: "Crée un élément 2D de forme trapèzoïdale, de hauteur 'H', de grande largeur 'L1'. Avec une largeur maximum du petit côté de 'L2'.",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Hauteur : H",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 1 : L1",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 2 : L2",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 3 : L3",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface trapèzoïdale",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DElement_Trapeze_6",
                group_id: "element2D",
                data: {
                    // Global data
                    label: "Trapèze (6 points)",
                    description: "Crée un élément 2D de forme trapèzoïdale, de hauteur 'H', de grande largeur 'L1'. Avec une largeur maximum du grand côté de 'L2' et une largeur maximum du petit côté de 'L3'.",
                    height: 200,
                    // Targets
                    target: [
                        {
                            label: "Hauteur : H",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 1 : L1",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 2 : L2",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 3 : L3",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur 4 : L4",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface trapèzoïdale",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
        ]
    },
    operator2D: {
        label: "Opérateurs d'élément 2D",
        id: "operator2D",
        items: [
            {
                node_type: "Node_2DOperator_Buffer",
                group_id: "operator2D",
                data: {
                    // Global data
                    label: "Buffer",
                    description: "Crée une zone tampon autour d'un élément 2D (point, ligne, surface), d'une largeur définie",
                    height: 155,
                    // Targets
                    target: [
                        {
                            label: "Element 2D",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "point",
                                "ligne",
                                "surface",
                            ]
                        },
                        {
                            label: "Largeur",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DOperator_Translation",
                group_id: "operator2D",
                data: {
                    // Global data
                    label: "Translation",
                    description: "Effectue une translation d'une surface, sur les axes X et Y",
                    height: 155,
                    // Targets
                    target: [
                        {
                            label: "Element 2D",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                        {
                            label: "Décalage X",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Décalage Y",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DOperator_Offset",
                group_id: "operator2D",
                data: {
                    // Global data
                    label: "Décalage",
                    description: "Effectue une translation perpendiculaire d'une ligne",
                    height: 155,
                    // Targets
                    target: [
                        {
                            label: "Ligne",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "ligne",
                            ]
                        },
                        {
                            label: "Décalage",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Ligne décalée",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "ligne"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DOperator_Union",
                group_id: "operator2D",
                data: {
                    // Global data
                    label: "Union",
                    description: "Définie une nouvelle surface à partir de l'union de deux surfaces A et B",
                    height: 155,
                    // Targets
                    target: [
                        {
                            label: "Surface A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                        {
                            label: "Surface B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface unifiée",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DOperator_Difference",
                group_id: "operator2D",
                data: {
                    // Global data
                    label: "Différence",
                    description: "Définie une nouvelle surface à partir d'une surface A à laquelle est soustraite une surface B",
                    height: 155,
                    // Targets
                    target: [
                        {
                            label: "Surface A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                        {
                            label: "Surface B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface restante",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_2DOperator_Intersect",
                group_id: "operator2D",
                data: {
                    // Global data
                    label: "Intersection",
                    description: "Définie une nouvelle surface à partir de l'intersection de deux surfaces A et B",
                    height: 155,
                    // Targets
                    target: [
                        {
                            label: "Surface A",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                        {
                            label: "Surface B",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface commune",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
        ]
    },
    operator3D: {
        label: "Opérateurs d'élément 3D",
        id: "operator3D",
        items: [
            {
                node_type: "Node_3DOperator_Extrusion",
                group_id: "operator3D",
                data: {
                    // Global data
                    label: "Extrusion selon l'axe Z",
                    description: "Extrude une surface selon l'axe Z à partir d'une hauteur minimum jusqu'à une hauteur maximum",
                    height: 201,
                    // Targets
                    target: [
                        {
                            label: "Surface",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                        {
                            label: "H min",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "H max",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Volume",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "volume"
                        }
                    ],
                }
            },
            {
                node_type: "Node_3DOperator_ExtrusionPath",
                group_id: "operator3D",
                data: {
                    // Global data
                    label: "Extrusion selon un chemin",
                    description: "Extrude une surface le long d'un chemin",
                    height: 201,
                    // Targets
                    target: [
                        {
                            label: "Surface",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                        {
                            label: "Chemin",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "ligne",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Volume",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "volume"
                        }
                    ],
                }
            },
            // {
            //     node_type: "Node_3DOperator_Watch",
            //     group_id: "operator3D",
            //     data: {
            //         // Global data
            //         label: "Volume constructible",
            //         description: "Affiche le volume constructible duquel est soustrait le volume V",
            //         height: 201,
            //         // Targets
            //         target: [
            //             {
            //                 label: "Volume V",
            //                 id: "target_0",
            //                 value: null,
            //                 default: null,
            //                 accepted: [
            //                     "volume",
            //                 ]
            //             },
            //         ],
            //         // Source
            //         source: [
            //             {
            //                 label: "Volume constructible",
            //                 id: "source_0",
            //                 value: null,
            //                 default: null,
            //                 acceptance: "volume"
            //             }
            //         ],
            //     }
            // },
        ]
    },
};







const ruleList = {
    buildable_volume: {
        label: "Volume constructible / inconstructible",
        items: [
            {
                node_type: "Node_Rule_Buildable_Volume",
                group_id: "buildable_volume",
                data: {
                    // Global data
                    label: "Volume constructible",
                    description: "Définition d'un volume en trois dimensions dans lequel la construction est autorisée",
                    isMapVisible: true,
                    isVolumeUsed: true,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Définit une hauteur maximum",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Condition d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Volume",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "volume",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Volume constructible",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "volume"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Unbuildable_Volume",
                group_id: "buildable_volume",
                data: {
                    // Global data
                    label: "Volume inconstructible",
                    description: "Définition d'un volume en trois dimensions dans lequel la construction est interdite",
                    isMapVisible: true,
                    isVolumeUsed: true,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Volume",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "volume",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Volume inconstructible",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "volume"
                        }
                    ],
                }
            },
        ]
    },
    buildable_area: {
        label: "Surface constructible / inconstructible",
        items: [
            {
                node_type: "Node_Rule_Buildable_Area",
                group_id: "buildable_area",
                data: {
                    // Global data
                    label: "Surface au sol constructible (non localisée)",
                    description: "Définition d'une surface au sol sur laquelle la construction est autorisée",
                    isMapVisible: true,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en superstructure",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en infrastructure",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Aire",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface constructible",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Unbuildable_Area",
                group_id: "buildable_area",
                data: {
                    // Global data
                    label: "Surface au sol inconstructible (non localisée)",
                    description: "Définition d'une surface au sol sur laquelle la construction est interdite",
                    isMapVisible: true,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en superstructure",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en infrastructure",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Aire",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface inconstructible",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
        ]
    },
    construction: {
        label: "Contraintes des constructions",
        items: [
            {
                node_type: "Node_Rule_Buildable_Area_Total",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Surface totale constructible (non localisée)",
                    description: "Définition d'une surface constructible maximum autorisée",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en superstructure",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en infrastructure",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Aire",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface constructible totle",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Building_Height",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Hauteur maximum des constructions",
                    description: "Définition la hauteur maximum des constructions",
                    isMapVisible: true,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Hauteur",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Hauteur maximum des constructions",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Facade_Height",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Hauteur maximum des façades de construction",
                    description: "Définition la hauteur de façade maximum des constructions",
                    isMapVisible: true,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Hauteur",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Hauteur maximum des façades",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Level_Height",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Hauteur maximum des niveaux de construction",
                    description: "Définition des hauteurs moyennes des niveaux de construction",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Niveau de construction",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "niveau",
                            ]
                        },
                        {
                            label: "Hauteur",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Hauteur moyenne d'un niveau de construction",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "règle"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Level_Nb",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Nombre de niveaux de construction",
                    description: "Définition du nombre maximum de niveaux de construction",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en superstructure",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Projection en infrastructure",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Nombre max de niveaux",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Nombre max de niveaux",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Level_Offset",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Retrait d'un niveau de construction",
                    description: "Définition du retrait d'un niveau par rapport à celui du dessous",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Largeur du retrait",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Nombre de niveaux (à partir du dernier)",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Retrait",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "règle"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Building_Offset",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Retraits en façade de bâtiments",
                    description: "Définition du retrait minimum entre bâtiments, selon le type de façade.",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Retrait en façade principale",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Retrait min en façade principale",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Retrait max en façade principale",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Retrait en façade pignon",
                            id: "target_5",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Retrait min en façade pignon",
                            id: "target_6",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Retrait max en façade pignon",
                            id: "target_7",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Retraits",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "règle"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Roof_Angle",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Pente de la toiture",
                    description: "Définition de la pente de la toiture",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Pente",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "pente de toiture",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_BoundAlign",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Alignement sur limite",
                    description: "Définition d'un alignement obligatoire sur une limite, avec ou sans un retrait minimum et maximum",
                    isMapVisible: true,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Limites sur lesquelles s'aligner",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "ligne",
                            ]
                        },
                        {
                            label: "Alignement sur toutes les limites (Oui) ou au moins une (Non)",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Alignement des façades principales",
                            id: "target_4",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Alignement des façades pignons",
                            id: "target_5",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Largeur de retrait minimum",
                            id: "target_6",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Largeur de retrait maximum",
                            id: "target_7",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Alignement sur limite",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Parking_Area_Total",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Surface de stationnement",
                    description: "Définition d'une surface minimum dédiée au stationnement",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Aire",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "nombre",
                            ]
                        },
                        {
                            label: "Type (value, ratio_area, ratio_spot)",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "texte",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Surface min de stationnement",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "nombre"
                        }
                    ],
                }
            },
            {
                node_type: "Node_Rule_Parking_Type",
                group_id: "construction",
                data: {
                    // Global data
                    label: "Typologie de stationnement",
                    description: "Définition des typologies de stationnement autorisées",
                    isMapVisible: false,
                    isVolumeUsed: false,
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Condition d'application",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Périmètre d'application",
                            id: "target_1",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Stationnement en extérieur",
                            id: "target_2",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                        {
                            label: "Stationnement en sous-sol",
                            id: "target_3",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Typologies de stationnement",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "règle"
                        }
                    ],
                }
            },
        ]
    },
    condition: {
        label: "Condition d'application",
        items: [
            {
                node_type: "Node_Condition",
                group_id: "condition",
                data: {
                    // Global data
                    label: "Condition d'application",
                    description: "Définition d'une condition d'application d'une règle",
                    height: 92,
                    // Targets
                    target: [
                        {
                            label: "Condition",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "booléen",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Condition d'application",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "booléen"
                        }
                    ],
                }
            },
        ]
    },
    perimeter: {
        label: "Périmètre d'application",
        items: [
            {
                node_type: "Node_Perimeter",
                group_id: "perimeter",
                data: {
                    // Global data
                    label: "Périmètre d'application",
                    description: "Définition d'un périmètre d'application d'une règle",
                    height: 190,
                    // Targets
                    target: [
                        {
                            label: "Périmètre",
                            id: "target_0",
                            value: null,
                            default: null,
                            accepted: [
                                "surface",
                            ]
                        },
                    ],
                    // Source
                    source: [
                        {
                            label: "Périmètre d'application",
                            id: "source_0",
                            value: null,
                            default: null,
                            acceptance: "surface"
                        }
                    ],
                }
            },
        ]
    },
};

