import React from 'react';

const WidgetMessage = ({ text }) => {
    return (
        <div className="app__sectiondata_widget_message_info">
            <div className="app__sectiondata_widget_message_info_ico"><div>⚠️</div></div>
            <div className="app__sectiondata_widget_message_info_text"><div className="app__sectiondata_widget_message_info_text_title">⚠️ Simulation invalide</div>{text}</div>
        </div>
    );
};

export default WidgetMessage;