import React from 'react';

import './Logo.css';

const Logo = ({ state: animate }) => {
    return (
        <div>
            <svg className="logo__container" height="100px" viewBox="-10 -10 1300 840" fill="url(#fill-body)" xmlns="http://www.w3.org/2000/svg">
                <path className={`${animate ? "logo__path1-animate" : ""}`} d="M462.276 123.214C466.214 121.558 470.58 121.207 474.732 122.213L1172.91 291.269C1183.64 293.868 1194.45 287.273 1197.05 276.537L1214.92 202.736C1217.52 192 1210.92 181.19 1200.19 178.591L464.959 0.561744C460.807 -0.443725 456.442 -0.0930362 452.503 1.56243L12.2556 186.604C2.07284 190.884 -2.71235 202.608 1.56761 212.791L30.9905 282.793C35.2704 292.976 46.9948 297.761 57.1776 293.481L462.276 123.214Z"
                    stroke="url(#fill-line)" strokeWidth="10" />
                <path className={`${animate ? "logo__path2-animate" : ""}`} d="M462.25 377.16C466.188 375.508 470.552 375.159 474.702 376.165L1114.92 531.408C1125.66 534.011 1136.47 527.419 1139.07 516.684L1156.97 442.888C1159.57 432.154 1152.98 421.342 1142.24 418.739L464.983 254.515C460.833 253.508 456.469 253.857 452.531 255.51L103.381 402.034C93.1955 406.308 88.4038 418.03 92.6781 428.215L122.062 498.234C126.336 508.419 138.058 513.211 148.243 508.936L462.25 377.16Z"
                    stroke="url(#fill-line)" strokeWidth="10" />
                <path className={`${animate ? "logo__path3-animate" : ""}`} d="M462.549 653.095C466.448 651.454 470.766 651.092 474.884 652.06L1054.55 788.349C1065.31 790.878 1076.07 784.21 1078.6 773.458L1095.98 699.539C1098.51 688.787 1091.84 678.021 1081.09 675.492L464.691 530.568C460.573 529.599 456.255 529.962 452.356 531.602L202.691 636.667C192.51 640.951 187.73 652.678 192.014 662.859L221.468 732.848C225.752 743.029 237.478 747.809 247.659 743.525L462.549 653.095Z"
                    stroke="url(#fill-line)" strokeWidth="10" />
                <defs>
                    <linearGradient id="fill-line" x1="1.41134e-05" y1="78" x2="1180.5" y2="849.5"
                        gradientUnits="userSpaceOnUse">
                        <stop className="logo__fill-line-stop1" offset="0" stopColor="#88ff9f" />
                        <stop className="logo__fill-line-stop2" offset="1" stopColor="#1ecd97" />
                    </linearGradient>
                    <linearGradient id="fill-body" x1="1.41134e-05" y1="78" x2="1180.5" y2="849.5"
                        gradientUnits="userSpaceOnUse">
                        <stop className={`logo__fill-body-stop1 ${animate ? "animate-stop1" : ""}`} offset="0" stopColor="#88ff9f" />
                        <stop className={`logo__fill-body-stop2 ${animate ? "animate-stop2" : ""}`} offset="1" stopColor="#1ecd97" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default Logo;