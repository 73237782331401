// Import dependencies
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


// Import CSS & assets
import './Activate.css';
import logo from '../../../assets/logo.svg';

// Import components
import ActivateForm from '../../../components/auth/activate/ActivateForm';

const Activate = () => {

    // Set global functions
    const navigate = useNavigate();

    // Set hooks
    const [isBodyVisible, setIsBodyVisible] = useState(true);

    // Set specific functions
    const handleBackHome = () => {
        setIsBodyVisible(false);
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }

    // RETURN
    return (
        <div>
            <div className='auth__auth'>
                <div className='auth__header'>
                    <div className="auth__header-container">
                        <div className="auth__header-logo" onClick={handleBackHome}>
                            <div className="auth__header-logo_box">
                                <img src={logo} alt='logo' />
                            </div>
                        </div>
                        <div className="auth__header-close">
                            {/* X */}
                        </div>
                    </div>
                </div>
                <div className={`auth__body_container ${isBodyVisible ? 'auth__body_container_visible' : 'auth__body_container_hidden'}`}>
                    <ActivateForm />
                </div>
            </div>
        </div>
    );
};

export default Activate;