export const UPDATE = 'UPDATE';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const GET = 'GET';
export const LIKE = 'LIKE';

export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_AUTH = 'USER_CREATE_AUTH';
export const USER_CONTACT = 'USER_CONTACT';
export const AUTH = 'AUTH';
export const RESET = 'RESET';
export const LOGOUT = 'LOGOUT';
export const SENDMAIL = 'SENDMAIL';

export const CAPA_GETALL = 'CAPA_GETALL';
export const CAPA_GETBYUSERANDTEAM = 'CAPA_GETBYUSERANDTEAM';
export const CAPA_GETBYID = 'CAPA_GETBYID';
export const CAPA_CREATE = 'CAPA_CREATE';
export const CAPA_UPDATE = 'CAPA_UPDATE';
export const CAPA_DELETE = 'CAPA_DELETE';
export const CAPA_COPY = 'CAPA_COPY';

export const TEAM_UPDATE = 'TEAM_UPDATE';
export const TEAM_CREATE = 'TEAM_CREATE';
export const TEAM_GET = 'TEAM_GET';