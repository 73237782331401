// Import dependencies
import * as THREE from 'three';
import { CSG } from 'three-csg-ts';
import * as turf from '@turf/turf';

// Import external functions
import * as map_helpers from '../components/app/map/Map_helpers';
import * as other_helpers from './Other_helpers';



export const get_buildable_data = (capacity) => {

    // Get elevations
    var levelHeight = 2.7;
    if (capacity?.buildable?.volume?.parameters?.levelHeight >= 1) {
        levelHeight = capacity?.buildable?.volume?.parameters?.levelHeight;
    }
    console.log("levelHeight", levelHeight);

    // Specific level height
    var levelHeight_ground = levelHeight;
    var levelHeight_current = levelHeight;
    var levelHeight_top = levelHeight;
    // Ground
    if (capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0) {
        levelHeight_ground = capacity?.buildable?.volume?.parameters?.min_height_level?.ground;
    }
    // Current
    if (capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0) {
        levelHeight_current = capacity?.buildable?.volume?.parameters?.min_height_level?.current;
    }
    // Top
    if (capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) {
        levelHeight_top = capacity?.buildable?.volume?.parameters?.min_height_level?.top;
    }
    console.log("levelHeight_ground", levelHeight_ground);
    console.log("levelHeight_current", levelHeight_current);
    console.log("levelHeight_top", levelHeight_top);

    // Get roof height
    var angle = capacity?.buildable?.volume?.parameters?.roof_angle || 0;
    var roofHeight = (angle / 45) * capacity?.buildable?.volume?.parameters?.max_building_width / 2;
    console.log("roofHeight", roofHeight);

    // Get higher horizontal plane of buildable volume
    // var higherPlane = getHigherPlanefromBuildable(capacity.buildable.volume.parameters.max_height, capacity.buildable.volume.interpreted[0]);
    var higherPlane = 3;
    if (capacity?.buildable?.volume?.parameters?.max_height >= 0) {
        higherPlane = capacity?.buildable?.volume?.parameters?.max_height;
    }
    if (capacity?.buildable?.volume?.parameters?.max_height_facade >= 0) {
        higherPlane = capacity?.buildable?.volume?.parameters?.max_height_facade;
        // Add attique levels
        if (capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level > 0) {
            higherPlane += levelHeight_top;
            higherPlane += Math.max(0, (capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level - 1) * levelHeight_current);
        }
        // Add roof
        higherPlane += roofHeight;
        // Check if not bigger than total height
        if (higherPlane > capacity?.buildable?.volume?.parameters?.max_height) {
            higherPlane = capacity?.buildable?.volume?.parameters?.max_height;
        }
    }
    console.log("higherPlane", higherPlane);


    // Get nb levels
    var levelMaxNb = Math.floor((higherPlane - roofHeight + 0.1) / levelHeight); // Add 10 cm to allow perfect fit
    console.log("levelMaxNb", levelMaxNb);
    if (capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0 || capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0 || capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) {
        var leftHeight = (higherPlane - roofHeight + 0.1);
        var levelNb = 0;
        // Count levels
        if (leftHeight >= levelHeight_ground) {
            leftHeight -= levelHeight_ground;
            levelNb++;
        }
        if (leftHeight >= levelHeight_top) {
            leftHeight -= levelHeight_top;
            levelNb++;
        }
        if (leftHeight >= levelHeight_current) {
            var nbCurrent = Math.floor(leftHeight / levelHeight_current);
            console.log("nbCurrent", nbCurrent);
            levelNb += nbCurrent;
        }

        levelMaxNb = levelNb;
    }
    if (capacity?.buildable?.volume?.parameters?.max_nb_level_super && capacity?.buildable?.volume?.parameters?.max_nb_level_super !== null && levelMaxNb > capacity?.buildable?.volume?.parameters?.max_nb_level_super) {
        levelMaxNb = capacity?.buildable?.volume?.parameters?.max_nb_level_super;
    }
    console.log("levelMaxNb", levelMaxNb);

    // Get elevations list
    var elevations = [0.01];
    var elevationCurrent = 0;
    for (var i = 1; i <= levelMaxNb; i++) {
        var levelHeightI = levelHeight_current;
        if (i === 1) {
            levelHeightI = levelHeight_ground;
        }
        else if (i === levelMaxNb) {
            levelHeightI = levelHeight_top;
        }
        elevationCurrent += levelHeightI;
        elevations.push(parseFloat(elevationCurrent.toFixed(2)));
        // elevations.push(parseFloat((i * levelHeight).toFixed(2)));
    }
    console.log("elevations", elevations);

    // Get buildable data for each elevation (by 2 to be optimized)
    var levels = [];
    for (var i = 0; i < elevations.length; i += 2) {
        // Get elevations to fetch
        var elevation0 = elevations[i];
        var elevation1 = null;
        if (i !== elevations.length - 1) {
            elevation1 = elevations[i + 1];
        }

        var levelBuildableData = getLevelBuildableData(capacity, elevation0, elevation1, capacity.buildable.volume.interpreted[0]);
        levels = levels.concat(levelBuildableData);
    }
    console.log("levels", levels);

    // Get levels_top
    var elevations_top = [];
    capacity?.buildable?.volume?.parameters?.max_height_facade_zones.forEach(zone => {
        if (!elevations.includes(zone.height) && !elevations_top.includes(zone.height) && zone.height !== undefined) {
            elevations_top.push(zone.height);
            // If top level height diff from current levels => add the top elevation of top level
            var max_top_elevation_index = null;
            var target_height = zone.height;
            if (capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level > 0) {
                target_height += levelHeight_top;
                target_height += Math.max(0, (capacity?.buildable?.volume?.parameters?.min_top_levels_offset?.nb_level - 1) * levelHeight_current);
            }
            for (var i = 1; i < levels.length; i++) {
                if (levels[i].elevation + levelHeight_top === target_height) { break }
                if (levels[i].elevation + levelHeight_top > target_height) { max_top_elevation_index = i - 1; break }
            }
            if (max_top_elevation_index !== null && !elevations.includes(levels[max_top_elevation_index].elevation + levelHeight_top)) {
                elevations_top.push(levels[max_top_elevation_index].elevation + levelHeight_top);
            }
        }
    })
    console.log("elevations_top", elevations_top);
    var levels_top = [];
    for (var i = 0; i < elevations_top.length; i += 2) {
        // Get elevations to fetch
        var elevation0 = elevations_top[i];
        var elevation1 = null;
        if (i !== elevations_top.length - 1) {
            elevation1 = elevations_top[i + 1];
        }

        var levelBuildableData = getLevelBuildableData(capacity, elevation0, elevation1, capacity.buildable.volume.interpreted[0]);
        levels_top = levels_top.concat(levelBuildableData);
    }
    var levels_all = levels.concat(levels_top);
    // Sort by lower to higher
    levels_all.sort(function (a, b) {
        return a.elevation - b.elevation;
    })


    // Get global data
    var maxBuildableAreaTotal = 0;
    var maxBuildableAreaTotalData = [];
    var maxBuildableAreaTotalLevels = [];

    var error_correction = 4;

    capacity.buildable.volume.parameters.max_area_total_zones.forEach((maxAreaZone, maxAreaZone_index) => {
        levels.forEach((level, level_index) => {
            if (level_index > 0) {
                if (maxAreaZone_index === 0) {
                    maxBuildableAreaTotalLevels.push(0);
                }

                if (level_index === 1) {
                    maxBuildableAreaTotalData.push({buildableAreaTotal: maxAreaZone.buildableArea, intersectionAreaTotal: 0, levels: []});
                }
                var level_current = [];
                var level_current_intersection_area = 0;
                level.availableSurfaces.forEach((availableSurface, availableSurface_index) => {
                    var intersection = turf.intersect(availableSurface, maxAreaZone.perimeter);
                    if (intersection !== null) {
                        var intersection_area = turf.area(intersection);
                        var maxAreaZone_level_intersection = turf.intersect(level.availableSurface, maxAreaZone.perimeter);
                        if (maxAreaZone_level_intersection !== null) {
                            var maxAreaZone_level_intersection_area = turf.area(maxAreaZone_level_intersection);
                            if (intersection_area > maxAreaZone_level_intersection_area - error_correction) { intersection_area = maxAreaZone_level_intersection_area }
                            var buildable_ratio = intersection_area / maxAreaZone_level_intersection_area;
                            level_current.push({intersection_area, buildable_ratio, intersection});
                            maxBuildableAreaTotalData[maxAreaZone_index].intersectionAreaTotal += intersection_area;
                            level_current_intersection_area += intersection_area;
                        }
                    }
                    else {
                        level_current.push(null);
                    }
                })
                var level_current_full = {intersection_area: level_current_intersection_area, availableSurfaces: level_current}
                maxBuildableAreaTotalData[maxAreaZone_index].levels.push(level_current_full);

            }
        })
    })
    maxBuildableAreaTotalData.forEach(maxAreaZone => {
        var maxBuildableArea_taken = 0;
        for (var level_index = maxAreaZone.levels.length - 1; level_index >= 0; level_index--) {
            var level_maxBuildableArea_average = (maxAreaZone.buildableAreaTotal - maxBuildableArea_taken) / (level_index + 1);
            console.log("level_index", level_index);
            var level_maxBuildableArea = 0;
            maxAreaZone.levels[level_index].availableSurfaces.forEach(availableSurface => {
                if (availableSurface !== null) {
                    var maxBuildableArea = availableSurface.buildable_ratio * (availableSurface.intersection_area / maxAreaZone.levels[level_index].intersection_area) * level_maxBuildableArea_average;
                    if (maxBuildableArea === Infinity || maxBuildableArea > availableSurface.intersection_area) { maxBuildableArea =  availableSurface.intersection_area}
                    maxBuildableAreaTotal += maxBuildableArea;
                    level_maxBuildableArea += maxBuildableArea;
                    availableSurface.maxBuildableArea = maxBuildableArea;
                    maxBuildableArea_taken += maxBuildableArea;
                }
            })
            maxBuildableAreaTotalLevels[level_index] += level_maxBuildableArea;
        }
        // maxAreaZone.levels.forEach((level, level_index) => {
        //     var level_maxBuildableArea = 0;
        //     level.forEach(availableSurface => {
        //         if (availableSurface !== null) {
        //             var maxBuildableArea = availableSurface.buildable_ratio * (availableSurface.intersection_area / maxAreaZone.intersectionAreaTotal) * maxAreaZone.buildableAreaTotal;
        //             if (maxBuildableArea === Infinity) { maxBuildableArea =  availableSurface.intersection_area}
        //             maxBuildableAreaTotal += maxBuildableArea;
        //             level_maxBuildableArea += maxBuildableArea;
        //             availableSurface.maxBuildableArea = maxBuildableArea;
        //         }
        //     })
        //     maxBuildableAreaTotalLevels[level_index] += level_maxBuildableArea;
        // })
    })
    console.log("maxBuildableAreaTotalData", maxBuildableAreaTotalData);
    console.log("maxBuildableAreaTotalLevels", maxBuildableAreaTotalLevels);
    console.log("maxBuildableAreaTotal", maxBuildableAreaTotal);


    
    var buildableAreaTotal = 0;
    levels.forEach((level, index) => {
        // if (index !== levels.length - 1) {
        if (index > 0) {
            // Addition buildable Area
            buildableAreaTotal += level.buildableArea;
            // Add maxBuildableArea
            level.maxBuildableArea = maxBuildableAreaTotalLevels[index - 1];
            // Add maxBuildableSurfaces
            if (!level?.maxBuildableSurfaces) { level.maxBuildableSurfaces = [] }
            maxBuildableAreaTotalData.forEach(maxAreaZone => {
                if (maxAreaZone.levels.length >= index) {
                    maxAreaZone.levels[index - 1].availableSurfaces.forEach(surface => {
                        var zone = surface.intersection;
                        zone.properties.availableArea = surface.intersection_area;
                        zone.properties.buildableArea = surface.maxBuildableArea;
                        zone.properties.ratio = surface.buildable_ratio;
                        level.maxBuildableSurfaces.push(zone);
                    })
                }
            })
        }
    })



    var volume = { ...capacity.buildable.volume };
    volume.levels = levels;
    volume.levels_all = levels_all;
    volume.parameters.buildableArea = buildableAreaTotal;
    volume.parameters.max_area_total = maxBuildableAreaTotal;
    volume.parameters.higherPlane = higherPlane;
    volume.parameters.levelHeight = levelHeight;


    return volume;

}






const getHigherPlanefromBuildable = (max_height, buildableMesh) => {
    var higherPlane = max_height;
    var buidable_coords = buildableMesh.geometry.attributes.position.array;
    for (var i = 0; i < buidable_coords.length; i += 9) {
        // Check if all 3 points are equals & igher than current higher plane
        if (buidable_coords[i + 2] === buidable_coords[i + 5] && buidable_coords[i + 5] === buidable_coords[i + 8] && buidable_coords[i + 8] > higherPlane) {
            higherPlane = buidable_coords[i + 8];
        }
    }
    return parseFloat(higherPlane.toFixed(2));
}

const getLevelBuildableData = (capacity, interceptorElevation0, interceptorElevation1, buildableMesh) => {
    console.log("--- Buildable Data between", interceptorElevation0, " and ", interceptorElevation1);
    // Create intersection volume
    var interceptor = getBuildableLevelInterceptor(capacity, interceptorElevation0, interceptorElevation1, buildableMesh);
    // console.log("interceptor", interceptor);

    // Perform calculations twice to optimize => 2 rounds
    var data = [];

    // Get base faces
    var interceptorCoords = interceptor?.geometry?.attributes?.position?.array;
    // console.log("interceptorCoords", interceptorCoords);
    var interceptorFaces0 = [];
    var interceptorFaces1 = [];
    var rounding = 8;
    for (var i = 0; i < interceptorCoords.length; i += 9) {
        // Check if all 3 points are on intercepetd level
        if (interceptorCoords[i + 2].toFixed(2) === interceptorElevation0.toFixed(2) && interceptorCoords[i + 5].toFixed(2) === interceptorElevation0.toFixed(2) && interceptorCoords[i + 8].toFixed(2) === interceptorElevation0.toFixed(2)) {
            // Create turf face
            var face = turf.polygon([[map_helpers.local_to_mercator([parseFloat(interceptorCoords[i].toFixed(rounding)), parseFloat(interceptorCoords[i + 1].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator([parseFloat(interceptorCoords[i + 3].toFixed(rounding)), parseFloat(interceptorCoords[i + 4].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator([parseFloat(interceptorCoords[i + 6].toFixed(rounding)), parseFloat(interceptorCoords[i + 7].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator([parseFloat(interceptorCoords[i].toFixed(rounding)), parseFloat(interceptorCoords[i + 1].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)]]);
            // Ensure last coords are same than first
            face.geometry.coordinates[0][face.geometry.coordinates[0].length - 1] = face.geometry.coordinates[0][0];
            // Buffer to get good union when faces are milimeters separated
            face = turf.buffer(face, 0.00005, { steps: 1 });
            // Truncate
            face = turf.truncate(face);
            // Add face to list
            interceptorFaces0.push(face);
        }
        else if (interceptorElevation1 !== null && interceptorCoords[i + 2].toFixed(2) === interceptorElevation1.toFixed(2) && interceptorCoords[i + 5].toFixed(2) === interceptorElevation1.toFixed(2) && interceptorCoords[i + 8].toFixed(2) === interceptorElevation1.toFixed(2)) {
            // Create turf face
            var face = turf.polygon([[map_helpers.local_to_mercator([parseFloat(interceptorCoords[i].toFixed(rounding)), parseFloat(interceptorCoords[i + 1].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator([parseFloat(interceptorCoords[i + 3].toFixed(rounding)), parseFloat(interceptorCoords[i + 4].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator([parseFloat(interceptorCoords[i + 6].toFixed(rounding)), parseFloat(interceptorCoords[i + 7].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix), map_helpers.local_to_mercator([parseFloat(interceptorCoords[i].toFixed(rounding)), parseFloat(interceptorCoords[i + 1].toFixed(rounding))], capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)]]);
            // Ensure last coords are same than first
            face.geometry.coordinates[0][face.geometry.coordinates[0].length - 1] = face.geometry.coordinates[0][0];
            // Buffer to get good union when faces are milimeters separated
            face = turf.buffer(face, 0.00005, { steps: 1 });
            // Truncate
            face = turf.truncate(face);
            // Add face to list
            interceptorFaces1.push(face);
        }
    }
    // console.log("interceptorFaces0", interceptorFaces0);
    // console.log("interceptorFaces1", interceptorFaces1);

    for (var round = 0; round < 2; round++) {
        // get elevation for this round
        var elevation = interceptorElevation0;
        var interceptorFaces = interceptorFaces0;
        if (round > 0) {
            elevation = interceptorElevation1;
            interceptorFaces = interceptorFaces1;
        }

        if (elevation === null) {
            break;
        }


        // Get union of faces
        var union = interceptorFaces[0];
        for (var i = 1; i < interceptorFaces.length; i++) {
            try {
                union = turf.union(turf.truncate(union, { precision: 8 }), turf.truncate(interceptorFaces[i], { precision: 8 }));
            } catch (error) {
                console.log("ERROR", error);
                continue
            }
        }

        if (union === undefined) {
            break;
        }

        // Split union if multipolygon and get a list
        // if (union?.geometry?.type === "MultiPolygon") {
        //     var union2 = [];
        //     union.geometry.coordinates.forEach(poly => {
        //         var polygon = turf.polygon(poly);
        //         if (turf.area(polygon) > 1) {
        //             var surface = turf.buffer(polygon, -0.00005, { steps: 1 });
        //             var intersect = null;
        //             if (surface !== undefined) {
        //                 // Check if inside land
        //                 intersect = turf.intersect(capacity.landBase.union.geometry, surface);
        //             }
        //             // console.log("intersect", intersect);
        //             if (intersect !== null) {
        //                 union2.push(intersect);
        //             }
        //         }
        //     })
        //     union = union2;
        // }
        // else {
        var surface = turf.buffer(union, -0.00005, { steps: 1 });
        var intersect = null;
        if (surface !== undefined) {
            // Check if inside land
            intersect = turf.intersect(capacity.landBase.union.geometry, surface);
        }
        // console.log("intersect", intersect);
        if (intersect !== null) {
            union = intersect;
            // union = [intersect];
        }
        else {
            union = null;
            // union = []
        }
        // }

        if (union === null) {
            break;
        }

        console.log("--- union", union, " at elevation", elevation);


        // Get available Unions by comparing to buildable super zones
        var availableUnions2 = [];
        var availableArea2 = 0;
        var buildableArea2 = 0;

        for (var zone_i = 0; zone_i < capacity.buildable.volume.parameters.buildable_super_zones.length; zone_i++) {

            // Take only zones with ratio bigger than 0
            if (capacity.buildable.volume.parameters.buildable_super_zones[zone_i].ratio > 0) {
                // Get surface
                var surface = turf.intersect(union, capacity.buildable.volume.parameters.buildable_super_zones[zone_i].perimeter);
                // Check if surface exists (intersection return a suarface and not null)
                if (surface !== null) {
                    // Get properties
                    var availableArea = turf.area(surface);
                    var buildableArea = Math.min(availableArea, capacity.buildable.volume.parameters.buildable_super_zones[zone_i].buildableArea);
                    var coords_global = [];
                    if (surface.geometry.type === "Polygon") {
                        coords_global = [surface.geometry.coordinates];
                    }
                    if (surface.geometry.type === "MultiPolygon") {
                        coords_global = surface.geometry.coordinates;
                    }
                    console.log("surface", surface);
                    console.log("coords_global", coords_global);
                    var coords_local = [];
                    var coords_holes_local = [];
                    var path_svg = [];
                    var path_svg_holes = [];
                    var centerCoords_global = [];
                    var centerCoords_local = [];
                    var centerCoords_svg = [];
                    var centerCoords_area = [];
                    coords_global.forEach(coords_poly => {
                        var coords_local_poly = [];
                        var centerCoords_global_poly = [];
                        var centerCoords_local_poly = [];
                        var coords_local_poly_holes = [];
                        var path_svg_poly = "";
                        var path_svg_poly_holes = [];
                        coords_poly.forEach((poly, index) => {
                            if (index === 0) {
                                poly.forEach(coords => {
                                    coords_local_poly.push(map_helpers.mercator_to_local(coords, capacity.landBase.union.bbox.origin));
                                })
                                path_svg_poly = other_helpers.get_svgPath_from_svgCoords(other_helpers.get_svgCoords_from_localCoords(coords_local_poly, capacity?.landBase?.union?.bbox), false);
                            }
                            else {
                                var coords_hole_local = [];
                                poly.forEach(coords => {
                                    coords_hole_local.push(map_helpers.mercator_to_local(coords, capacity.landBase.union.bbox.origin));
                                })
                                coords_local_poly_holes.push(coords_hole_local);
                                path_svg_poly_holes.push(other_helpers.get_svgPath_from_svgCoords(other_helpers.get_svgCoords_from_localCoords(coords_hole_local, capacity?.landBase?.union?.bbox), true));
                                path_svg_poly = path_svg_poly + other_helpers.get_svgPath_from_svgCoords(other_helpers.get_svgCoords_from_localCoords(coords_hole_local, capacity?.landBase?.union?.bbox), true);
                            }
                        })
                        coords_local.push(coords_local_poly);
                        centerCoords_global_poly = turf.getCoord(turf.centerOfMass(turf.polygon(coords_poly)));
                        centerCoords_global.push(centerCoords_global_poly);
                        centerCoords_local_poly = map_helpers.mercator_to_local(centerCoords_global_poly, capacity.landBase.union.bbox.origin);
                        centerCoords_local.push(centerCoords_local_poly);
                        centerCoords_svg.push(other_helpers.get_svgCoords_from_localCoords([centerCoords_local_poly], capacity?.landBase?.union?.bbox)[0]);
                        centerCoords_area.push(turf.area(turf.polygon(coords_poly)));
                        path_svg.push(path_svg_poly);
                        coords_holes_local.push(coords_local_poly_holes);
                        path_svg_holes.push(path_svg_poly_holes);
                    })
                    console.log("coords_local", coords_local);
                    console.log("coords_holes_local", coords_holes_local);
                    console.log("path_svg", path_svg);
                    console.log("path_svg_holes", path_svg_holes);

                    // var centerCoords_global = turf.getCoord(turf.centerOfMass(surface));
                    // var centerCoords_local = map_helpers.mercator_to_local(centerCoords_global, capacity.landBase.union.bbox.origin);
                    // var centerCoords_svg = other_helpers.get_svgCoords_from_localCoords([centerCoords_local], capacity?.landBase?.union?.bbox)[0];
                    // Add properties
                    surface.properties = {
                        availableArea,
                        buildableArea,
                        coords_global,
                        coords_local,
                        coords_svg,
                        centerCoords_global,
                        centerCoords_local,
                        centerCoords_svg,
                        centerCoords_area,
                        path_svg,
                        path_svg_holes
                    }
                    // Push to available list
                    availableUnions2.push(surface);
                    // Get total sums
                    availableArea2 += availableArea;
                    buildableArea2 += buildableArea;
                }
            }
        }

        console.log("availableUnions2", availableUnions2);

        // Get working Unions
        var workingUnions2 = [];
        if (union.geometry.type === "Polygon") {
            workingUnions2.push(union);
        }
        else if (union.geometry.type === "MultiPolygon") {
            union.geometry.coordinates.forEach(coords => {
                workingUnions2.push(turf.polygon(coords));
            })
        }
        console.log("workingUnions2", workingUnions2);
        // Simplify items
        var workingSurfaces2 = [];
        for (var workingUnion_i = 0; workingUnion_i < workingUnions2.length; workingUnion_i++) {
            var workingUnion = workingUnions2[workingUnion_i];

            // Check if area is bigger than 10 m²
            var workingUnionArea = turf.area(workingUnion);
            if (workingUnionArea < 10) {
                console.log("workingUnion is too small", workingUnionArea);
                continue
            }

            // workingUnion.geometry.coordinates.forEach(coords => {
            // if (coords.length > 1) { coords = [coords[0]] }
            // var buildableSurface = turf.polygon(coords);

            // Get outline
            var buildableSurfaceOutline = turf.polygonToLine(workingUnion);
            console.log("buildableSurfaceOutline", buildableSurfaceOutline);
            var buildableSurfaceOutline_out = buildableSurfaceOutline;
            var buildableSurfaceOutline_holes = [];
            if (buildableSurfaceOutline?.geometry?.type === "MultiLineString") {
                buildableSurfaceOutline_out = turf.lineString(buildableSurfaceOutline?.geometry?.coordinates[0]);
                buildableSurfaceOutline.geometry.coordinates.forEach((item, index) => {
                    if (index > 0) {
                        buildableSurfaceOutline_holes.push(turf.lineString(item));
                    }
                })
            }
            console.log("buildableSurfaceOutline_out", buildableSurfaceOutline_out);
            console.log("buildableSurfaceOutline_holes", buildableSurfaceOutline_holes);

            var simple = getLinesSimplified(buildableSurfaceOutline_out);
            console.log("simple", simple);

            var simple_holes = [];
            buildableSurfaceOutline_holes.forEach(item => {
                var simplified = getLinesSimplified(item);
                if (simplified.length < 4 || simplified[0][0] !== simplified[simplified.length - 1][0]) {
                    simplified = item.geometry.coordinates;
                }
                simple_holes.push(simplified);
            })
            console.log("simple_holes", simple_holes);


            if (simple.length >= 4) {
                // Add workingSurfaces
                var simple_concat = [simple].concat(simple_holes);
                var buildableSurfacePolygon = turf.polygon(simple_concat);
                var buildableSurfaceArea = turf.area(buildableSurfacePolygon);
                buildableSurfacePolygon.properties.area = buildableSurfaceArea;
                var coords_global = simple;
                var coords_local = [];
                var coords_svg = [];
                coords_global.forEach(coords => {
                    var localCoords = map_helpers.mercator_to_local(coords, capacity.landBase.union.bbox.origin);
                    coords_local.push(localCoords);
                })
                coords_svg = other_helpers.get_svgCoords_from_localCoords(coords_local, capacity?.landBase?.union?.bbox);
                buildableSurfacePolygon.properties.coords_local = coords_local;
                buildableSurfacePolygon.properties.coords_svg = coords_svg;
                buildableSurfacePolygon.properties.real_poly = workingUnions2[workingUnion_i];

                workingSurfaces2.push(buildableSurfacePolygon);
            }
            // })
        }
        console.log("workingSurfaces2", workingSurfaces2);







        // // Get intersection with rules Buildable / Unbuildable Area /!\ DONT FORGET TO GET MAX OF BUILDABLE AREA FROM RULE AND AREA OF INTERSECTION (in cas buildable would be bigger it needs to be limied to real area)
        // union = [union];
        // var availableUnions = [];
        // var workingUnions = [];
        // for (var i = 0; i < union.length; i++) {
        //     var currentUnion = union[i];
        //     var workingUnion = union[i];

        //     // Check each rule backwards
        //     for (var rulesetIndex = capacity.rules.ruleset.length - 1; rulesetIndex >= 0; rulesetIndex--) {
        //         for (var ruleIndex = capacity.rules.ruleset[rulesetIndex].items.length - 1; ruleIndex >= 0; ruleIndex--) {
        //             console.log(ruleIndex);
        //             var rule = capacity.rules.ruleset[rulesetIndex].items[ruleIndex];
        //             // Verify turfData exists
        //             if (!(rule?.interpreted && rule?.interpreted.length && rule.interpreted[0]?.userData?.turfData)) {
        //                 console.log("NO TURFDATA ON RULE");
        //                 continue;
        //             }
        //             // Verifiy currentUnion diff of null : still some area to apply the rule
        //             if (currentUnion === null) {
        //                 console.log("NO MORE SURFACE TO APPLY RULE");
        //                 continue;
        //             }

        //             // Check if rule has to be taken into account
        //             // BUILDABLE AREA
        //             if (rule?.data?.composition?.nodes && rule.data.composition.nodes[0].type === "Node_Rule_Buildable_Area") {
        //                 var ratio = null;
        //                 if (rule?.interpreted && rule?.interpreted.length > 0) {
        //                     ratio = rule.interpreted[0]?.userData?.buildableArea / rule.interpreted[0]?.userData?.availableArea;
        //                 }
        //                 console.log("ratio", ratio);
        //                 // If ratio >= 1 : all area is buildable
        //                 // If ratio is between 0 & 1 some part is buildable
        //                 // If ratio = 0 all area is unbuildable
        //                 if (ratio <= 0) {
        //                     // Update currentUnion to substract surface
        //                     currentUnion = turf.difference(currentUnion, rule.interpreted[0].userData.turfData);
        //                     workingUnion = turf.difference(workingUnion, rule.interpreted[0].userData.turfData);
        //                 }
        //                 else if (ratio < 1) {
        //                     // Get surface
        //                     var surface = turf.intersect(rule.interpreted[0].userData.turfData, currentUnion);
        //                     console.log("surface", surface);
        //                     // Check if surface exists (intersection return a suarface and not null)
        //                     if (surface !== null) {
        //                         var surfaceArea = turf.area(surface);
        //                         console.log("surface area", turf.area(surface));
        //                         // Check if surfaceArea is bigger than buildable area otherwise its 100% buildable
        //                         if (surfaceArea > rule.interpreted[0]?.userData?.buildableArea) {
        //                             // Add properties
        //                             surface.properties = {
        //                                 availableArea: surfaceArea,
        //                                 buildableArea: rule.interpreted[0]?.userData?.buildableArea
        //                             }
        //                             // Push to available list
        //                             availableUnions.push(surface);
        //                             // Update currentUnion to substract surface
        //                             currentUnion = turf.difference(currentUnion, rule.interpreted[0].userData.turfData);
        //                         }
        //                     }
        //                 }
        //             }
        //             // UNBUILDABLE AREA
        //             if (rule?.data?.composition?.nodes && rule.data.composition.nodes[0].type === "Node_Rule_Unbuildable_Area") {
        //                 var ratio = null;
        //                 if (rule?.interpreted && rule?.interpreted.length > 0) {
        //                     ratio = rule.interpreted[0]?.userData?.unbuildableArea / rule.interpreted[0]?.userData?.availableArea;
        //                 }
        //                 console.log("ratio", ratio);
        //                 console.log("currentUnion", currentUnion);
        //                 console.log("rule.interpreted[0]", rule.interpreted[0]);
        //                 // If ratio >= 1 : all area is unbuildable
        //                 // If ratio is between 0 & 1 some part is buildable (1 / ratio unbuildable)
        //                 // If ratio = 0 all area is buildable
        //                 if (ratio >= 1) {
        //                     // Update currentUnion to substract surface
        //                     currentUnion = turf.difference(currentUnion, rule.interpreted[0].userData.turfData);
        //                     workingUnion = turf.difference(workingUnion, rule.interpreted[0].userData.turfData);
        //                 }
        //                 else if (ratio > 0 && ratio < 1) {
        //                     // Get surface
        //                     var surface = turf.intersect(rule.interpreted[0].userData.turfData, currentUnion);
        //                     console.log("surface", surface);
        //                     // Check if surface exists (intersection return a surface and not null)
        //                     if (surface !== null) {
        //                         var surfaceArea = turf.area(surface);
        //                         console.log("surface area", turf.area(surface));
        //                         // Check if surfaceArea is bigger than buildable area otherwise its 100% buildable
        //                         if (surfaceArea > (rule.interpreted[0]?.userData?.availableArea - rule.interpreted[0]?.userData?.unbuildableArea)) {
        //                             // Add properties
        //                             surface.properties = {
        //                                 availableArea: surfaceArea,
        //                                 buildableArea: rule.interpreted[0]?.userData?.availableArea - rule.interpreted[0]?.userData?.unbuildableArea
        //                             }
        //                             // Push to available list
        //                             availableUnions.push(surface);
        //                             // Update currentUnion to substract surface
        //                             currentUnion = turf.difference(currentUnion, rule.interpreted[0].userData.turfData);
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     // Finish working Unions
        //     // Check if current union is valid
        //     if (workingUnion === null || workingUnion === undefined) {
        //         console.log("workingUnion is invalid", workingUnion);
        //         continue;
        //     }
        //     var workingUnionArea = turf.area(workingUnion);
        //     // Check if current is bigger than 10 m²
        //     if (workingUnionArea < 10) {
        //         console.log("workingUnion is too small", workingUnionArea);
        //         continue;
        //     }
        //     workingUnion.properties = {
        //         availableArea: workingUnionArea,
        //         buildableArea: workingUnionArea
        //     }
        //     workingUnions.push(workingUnion);
        //     // Check if current union is valid
        //     if (currentUnion === null || currentUnion === undefined) {
        //         console.log("currentUnion is invalid", currentUnion);
        //         continue;
        //     }
        //     // After checking all rules, add the rest of currentUnion
        //     var currentUnionArea = turf.area(currentUnion);
        //     // Check if current is bigger than 10 m²
        //     if (currentUnionArea < 10) {
        //         console.log("currentUnion is too small", currentUnionArea);
        //         continue;
        //     }
        //     currentUnion.properties = {
        //         availableArea: currentUnionArea,
        //         buildableArea: currentUnionArea
        //     }
        //     availableUnions.push(currentUnion);

        // }
        // console.log("availableUnions", availableUnions);
        // console.log("workingUnions", workingUnions);


        // // Check if it is multipolygon => split in simple polygon
        // // var availableUnionsFlat = [];
        // // availableUnions.forEach(feature => {
        // //     if (feature.geometry.type === "MultiPolygon") {
        // //         feature.geometry.coordinates.forEach(coords => {
        // //             var poly = turf.polygon(coords, { ...feature.properties });
        // //             availableUnionsFlat.push(poly);
        // //         })
        // //     }
        // //     else {
        // //         availableUnionsFlat.push(feature);
        // //     }
        // // })
        // // // console.log("availableUnionsFlat", availableUnionsFlat);
        // // availableUnions = availableUnionsFlat;

        // // var workingUnionsFlat = [];
        // // workingUnions.forEach(feature => {
        // //     if (feature.geometry.type === "MultiPolygon") {
        // //         feature.geometry.coordinates.forEach(coords => {
        // //             var poly = turf.polygon(coords, { ...feature.properties });
        // //             workingUnionsFlat.push(poly);
        // //         })
        // //     }
        // //     else {
        // //         workingUnionsFlat.push(feature);
        // //     }
        // // })
        // // // console.log("workingUnionsFlat", workingUnionsFlat);
        // // workingUnions = workingUnionsFlat;


        // // Create workingSurfaces by simplifing workingUnions
        // var workingSurfaces = [];
        // for (var j = 0; j < workingUnions.length; j++) {
        //     // Loop on each polygon of the surface (if polygon => 1, il multipolygon => several)
        //     var workingUnions_polygons_coords = [];
        //     if (workingUnions[j]?.geometry?.type === "Polygon") {
        //         workingUnions_polygons_coords.push(workingUnions[j].geometry.coordinates);
        //     }
        //     else if (workingUnions[j]?.geometry?.type === "MultiPolygon") {
        //         workingUnions[j].geometry.coordinates.forEach(coords => {
        //             workingUnions_polygons_coords.push(coords);
        //         })
        //     }
        //     console.log("workingUnions_polygons_coords", workingUnions_polygons_coords);


        //     workingUnions_polygons_coords.forEach(coords => {
        //         // workingUnions[j].geometry.coordinates.forEach(coords => {
        //         // if (coords.length !== 1) { coords = [coords] };
        //         if (coords.length > 1) { coords = [coords[0]] }
        //         console.log("coords", coords);
        //         var buildableSurface = turf.polygon(coords);
        //         // Get outline
        //         var buildableSurfaceOutline = turf.polygonToLine(buildableSurface);
        //         // Split outline
        //         var buildableSurfaceLines = [];
        //         for (var i = 1; i < buildableSurfaceOutline.geometry.coordinates.length; i++) {
        //             var line = turf.lineString([buildableSurfaceOutline.geometry.coordinates[i - 1], buildableSurfaceOutline.geometry.coordinates[i]]);
        //             // Simplify by not taking lines smallest than 1 meter
        //             if ((turf.length(line) * 1000) >= 1) {
        //                 buildableSurfaceLines.push(line);
        //             }
        //         }
        //         // Correct coords to erase discontinuities due to buffering
        //         var buildingLines = [];
        //         for (var i = 0; i < buildableSurfaceLines.length; i++) {
        //             var last = i - 1;
        //             if (i === 0) { last = buildableSurfaceLines.length - 1 }
        //             // Check if end point of last is different from start point of i
        //             if (buildableSurfaceLines[last].geometry.coordinates[1] !== buildableSurfaceLines[i].geometry.coordinates[0]) {
        //                 // Along last
        //                 var lastPoint = turf.destination(buildableSurfaceLines[last].geometry.coordinates[1], 0.01, turf.bearing(turf.point(buildableSurfaceLines[last].geometry.coordinates[0]), turf.point(buildableSurfaceLines[last].geometry.coordinates[1])));
        //                 var lastLine = turf.lineString([buildableSurfaceLines[last].geometry.coordinates[0], turf.getCoord(lastPoint)]);
        //                 // Along current
        //                 var nextPoint = turf.destination(buildableSurfaceLines[i].geometry.coordinates[0], 0.01, turf.bearing(turf.point(buildableSurfaceLines[i].geometry.coordinates[1]), turf.point(buildableSurfaceLines[i].geometry.coordinates[0])));
        //                 var nextLine = turf.lineString([turf.getCoord(nextPoint), buildableSurfaceLines[i].geometry.coordinates[1]]);
        //                 // Intersecting point
        //                 var intersection = turf.lineIntersect(lastLine, nextLine);
        //                 // Change values if intersection exists
        //                 if (intersection.features.length > 0) {
        //                     buildableSurfaceLines[last].geometry.coordinates[1] = turf.getCoord(intersection.features[0]);
        //                     buildableSurfaceLines[i].geometry.coordinates[0] = turf.getCoord(intersection.features[0]);
        //                 }
        //                 else {
        //                     // Else, Get midpoint
        //                     var midpoint = turf.midpoint(turf.point(buildableSurfaceLines[last].geometry.coordinates[1]), turf.point(buildableSurfaceLines[i].geometry.coordinates[0]));
        //                     buildableSurfaceLines[last].geometry.coordinates[1] = turf.getCoord(midpoint);
        //                     buildableSurfaceLines[i].geometry.coordinates[0] = turf.getCoord(midpoint);
        //                 }
        //             }
        //         }
        //         // Simplify by mergin lines with angle between 175 & 185 deg (almost straight)
        //         var anglePoints = [];
        //         for (var i = 0; i < buildableSurfaceLines.length; i++) {
        //             var last = i - 1;
        //             if (last === -1) { last = buildableSurfaceLines.length - 1 }
        //             var lastBearing = turf.bearing(turf.point(buildableSurfaceLines[last].geometry.coordinates[0]), buildableSurfaceLines[last].geometry.coordinates[1]);
        //             var iBearing = turf.bearing(turf.point(buildableSurfaceLines[i].geometry.coordinates[1]), buildableSurfaceLines[i].geometry.coordinates[0]);
        //             var angle = iBearing - lastBearing;
        //             if (angle < 0) { angle = angle + 360 }
        //             if (angle <= 175 || angle >= 185) {
        //                 anglePoints.push(buildableSurfaceLines[i].geometry.coordinates[0]);
        //             }
        //         }
        //         // console.log("anglePoints", anglePoints);
        //         var buildableSurfaceLinesSimplified = [];
        //         for (var i = 0; i < anglePoints.length; i++) {
        //             var next = i + 1;
        //             if (next === anglePoints.length) { next = 0 }
        //             buildableSurfaceLinesSimplified.push(turf.lineString([anglePoints[i], anglePoints[next]]));
        //         }
        //         if (anglePoints.length >= 3) {
        //             // Add workingSurfaces
        //             var buildableSurfacePolygon = turf.polygon([anglePoints.concat([anglePoints[0]])]);
        //             var buildableSurfaceArea = turf.area(buildableSurfacePolygon);
        //             buildableSurfacePolygon.properties.area = buildableSurfaceArea;
        //             var coords_global = anglePoints.concat([anglePoints[0]]);
        //             var coords_local = [];
        //             var coords_svg = [];
        //             var svgWidth = 800;
        //             coords_global.forEach(coords => {
        //                 var localCoords = map_helpers.mercator_to_local(coords, capacity.landBase.union.bbox.origin);
        //                 coords_local.push(localCoords);
        //                 // if (capacity.landBase.union.bbox.lengthH >= capacity.landBase.union.bbox.lengthV) {
        //                 //     var scaleFactor = svgWidth / capacity.landBase.union.bbox.lengthH;
        //                 //     var verticalOffset = (svgWidth - (capacity.landBase.union.bbox.lengthV * scaleFactor)) / 2;
        //                 //     coords_svg.push([localCoords[0] * scaleFactor, svgWidth - (localCoords[1] * scaleFactor) - verticalOffset]);
        //                 // }
        //                 // else {
        //                 //     var scaleFactor = svgWidth / capacity.landBase.union.bbox.lengthV;
        //                 //     var horizontalOffset = (svgWidth - (capacity.landBase.union.bbox.lengthH * scaleFactor)) / 2;
        //                 //     coords_svg.push([(localCoords[0] * scaleFactor) - horizontalOffset, svgWidth - (localCoords[1] * scaleFactor)]);
        //                 // }
        //             })
        //             coords_svg = other_helpers.get_svgCoords_from_localCoords(coords_local, capacity?.landBase?.union?.bbox);
        //             buildableSurfacePolygon.properties.coords_local = coords_local;
        //             buildableSurfacePolygon.properties.coords_svg = coords_svg;
        //             workingSurfaces.push(buildableSurfacePolygon);
        //         }
        //     })



        // }
        // console.log("workingSurfaces", workingSurfaces);

        // // Create availableSurfaces by simplifing availableUnions
        // var availableSurfaces = [];
        // var availableArea = 0;
        // var buildableArea = 0;
        // for (var j = 0; j < availableUnions.length; j++) {
        //     // Get areas
        //     availableArea += availableUnions[j].properties.availableArea;
        //     buildableArea += availableUnions[j].properties.buildableArea;
        //     // Loop on each polygon of the surface (if polygon => 1, il multipolygon => several)
        //     var allCoords_global = [];
        //     var allCoords_local = [];
        //     var allCoords_svg = [];

        //     var availableUnions_polygons_coords = [];
        //     if (availableUnions[j]?.geometry?.type === "Polygon") {
        //         availableUnions_polygons_coords.push(availableUnions[j].geometry.coordinates);
        //     }
        //     else if (availableUnions[j]?.geometry?.type === "MultiPolygon") {
        //         availableUnions[j].geometry.coordinates.forEach(coords => {
        //             availableUnions_polygons_coords.push(coords);
        //         })
        //     }
        //     console.log("availableUnions_polygons_coords", availableUnions_polygons_coords);

        //     availableUnions_polygons_coords.forEach(coords => {
        //         // availableUnions[j].geometry.coordinates.forEach(coords => {
        //         // if (coords.length !== 1) { coords = [coords] };
        //         if (coords.length > 1) { coords = [coords[0]] }
        //         console.log("coords", coords);
        //         var buildableSurface = turf.polygon(coords);
        //         // Get outline
        //         var buildableSurfaceOutline = turf.polygonToLine(buildableSurface);
        //         // Split outline
        //         var buildableSurfaceLines = [];
        //         for (var i = 1; i < buildableSurfaceOutline.geometry.coordinates.length; i++) {
        //             var line = turf.lineString([buildableSurfaceOutline.geometry.coordinates[i - 1], buildableSurfaceOutline.geometry.coordinates[i]]);
        //             // Simplify by not taking lines smallest than 1 meter
        //             // if ((turf.length(line) * 1000) >= 1) {
        //             buildableSurfaceLines.push(line);
        //             // }
        //         }
        //         // Correct coords to erase discontinuities due to buffering
        //         var buildingLines = [];
        //         for (var i = 0; i < buildableSurfaceLines.length; i++) {
        //             var last = i - 1;
        //             if (i === 0) { last = buildableSurfaceLines.length - 1 }
        //             // Check if end point of last is different from start point of i
        //             if (buildableSurfaceLines[last].geometry.coordinates[1] !== buildableSurfaceLines[i].geometry.coordinates[0]) {
        //                 // Along last
        //                 var lastPoint = turf.destination(buildableSurfaceLines[last].geometry.coordinates[1], 0.01, turf.bearing(turf.point(buildableSurfaceLines[last].geometry.coordinates[0]), turf.point(buildableSurfaceLines[last].geometry.coordinates[1])));
        //                 var lastLine = turf.lineString([buildableSurfaceLines[last].geometry.coordinates[0], turf.getCoord(lastPoint)]);
        //                 // Along current
        //                 var nextPoint = turf.destination(buildableSurfaceLines[i].geometry.coordinates[0], 0.01, turf.bearing(turf.point(buildableSurfaceLines[i].geometry.coordinates[1]), turf.point(buildableSurfaceLines[i].geometry.coordinates[0])));
        //                 var nextLine = turf.lineString([turf.getCoord(nextPoint), buildableSurfaceLines[i].geometry.coordinates[1]]);
        //                 // Intersecting point
        //                 var intersection = turf.lineIntersect(lastLine, nextLine);
        //                 // Change values if intersection exists
        //                 if (intersection.features.length > 0) {
        //                     buildableSurfaceLines[last].geometry.coordinates[1] = turf.getCoord(intersection.features[0]);
        //                     buildableSurfaceLines[i].geometry.coordinates[0] = turf.getCoord(intersection.features[0]);
        //                 }
        //                 else {
        //                     // Else, Get midpoint
        //                     var midpoint = turf.midpoint(turf.point(buildableSurfaceLines[last].geometry.coordinates[1]), turf.point(buildableSurfaceLines[i].geometry.coordinates[0]));
        //                     buildableSurfaceLines[last].geometry.coordinates[1] = turf.getCoord(midpoint);
        //                     buildableSurfaceLines[i].geometry.coordinates[0] = turf.getCoord(midpoint);
        //                 }
        //             }
        //         }
        //         // Simplify by merging lines with angle between 175 & 185 deg (almost straight)
        //         var anglePoints = [];
        //         for (var i = 0; i < buildableSurfaceLines.length; i++) {
        //             var last = i - 1;
        //             if (last === -1) { last = buildableSurfaceLines.length - 1 }
        //             var lastBearing = turf.bearing(turf.point(buildableSurfaceLines[last].geometry.coordinates[0]), buildableSurfaceLines[last].geometry.coordinates[1]);
        //             var iBearing = turf.bearing(turf.point(buildableSurfaceLines[i].geometry.coordinates[1]), buildableSurfaceLines[i].geometry.coordinates[0]);
        //             var angle = iBearing - lastBearing;
        //             if (angle < 0) { angle = angle + 360 }
        //             // console.log("angle", angle);
        //             if (angle <= 175 || angle >= 185) {
        //                 anglePoints.push(buildableSurfaceLines[i].geometry.coordinates[0]);
        //             }
        //         }
        //         // console.log("anglePoints", anglePoints);
        //         var buildableSurfaceLinesSimplified = [];
        //         for (var i = 0; i < anglePoints.length; i++) {
        //             var next = i + 1;
        //             if (next === anglePoints.length) { next = 0 }
        //             buildableSurfaceLinesSimplified.push(turf.lineString([anglePoints[i], anglePoints[next]]));
        //         }
        //         if (anglePoints.length >= 3) {
        //             // Add availableSurfaces
        //             var buildableSurfacePolygon = turf.polygon([anglePoints.concat([anglePoints[0]])]);
        //             var buildableSurfaceArea = turf.area(buildableSurfacePolygon);
        //             buildableSurfacePolygon.properties.area = buildableSurfaceArea;
        //             var coords_global = anglePoints.concat([anglePoints[0]]);
        //             var coords_local = [];
        //             var coords_svg = [];
        //             var svgWidth = 800;
        //             coords_global.forEach(coords => {
        //                 var localCoords = map_helpers.mercator_to_local(coords, capacity.landBase.union.bbox.origin);
        //                 coords_local.push(localCoords);
        //                 // if (capacity.landBase.union.bbox.lengthH >= capacity.landBase.union.bbox.lengthV) {
        //                 //     var scaleFactor = svgWidth / capacity.landBase.union.bbox.lengthH;
        //                 //     var verticalOffset = (svgWidth - (capacity.landBase.union.bbox.lengthV * scaleFactor)) / 2;
        //                 //     coords_svg.push([localCoords[0] * scaleFactor, svgWidth - (localCoords[1] * scaleFactor) - verticalOffset]);
        //                 // }
        //                 // else {
        //                 //     var scaleFactor = svgWidth / capacity.landBase.union.bbox.lengthV;
        //                 //     var horizontalOffset = (svgWidth - (capacity.landBase.union.bbox.lengthH * scaleFactor)) / 2;
        //                 //     coords_svg.push([(localCoords[0] * scaleFactor) - horizontalOffset, svgWidth - (localCoords[1] * scaleFactor)]);
        //                 // }
        //             })
        //             coords_svg = other_helpers.get_svgCoords_from_localCoords(coords_local, capacity?.landBase?.union?.bbox);
        //             buildableSurfacePolygon.properties.coords_local = coords_local;
        //             buildableSurfacePolygon.properties.coords_svg = coords_svg;
        //             allCoords_global.push(coords_global);
        //             allCoords_local.push(coords_local);
        //             allCoords_svg.push(coords_svg);
        //         }
        //     })
        //     // Add data
        //     availableUnions[j].properties.coords_global = allCoords_global;
        //     availableUnions[j].properties.coords_local = allCoords_local;
        //     availableUnions[j].properties.coords_svg = allCoords_svg;


        //     // Get center of surface for text
        //     var centerCoords_global = turf.getCoord(turf.centerOfMass(availableUnions[j]));
        //     var centerCoords_local = map_helpers.mercator_to_local(centerCoords_global, capacity.landBase.union.bbox.origin);
        //     // var centerCoords_svg = [];
        //     // var svgWidth = 800;
        //     // if (capacity.landBase.union.bbox.lengthH >= capacity.landBase.union.bbox.lengthV) {
        //     //     var scaleFactor = svgWidth / capacity.landBase.union.bbox.lengthH;
        //     //     var verticalOffset = (svgWidth - (capacity.landBase.union.bbox.lengthV * scaleFactor)) / 2;
        //     //     centerCoords_svg = [centerCoords_local[0] * scaleFactor, svgWidth - (centerCoords_local[1] * scaleFactor) - verticalOffset];
        //     // }
        //     // else {
        //     //     var scaleFactor = svgWidth / capacity.landBase.union.bbox.lengthV;
        //     //     var horizontalOffset = (svgWidth - (capacity.landBase.union.bbox.lengthH * scaleFactor)) / 2;
        //     //     centerCoords_svg = [(centerCoords_local[0] * scaleFactor) - horizontalOffset, svgWidth - (centerCoords_local[1] * scaleFactor)];
        //     // }
        //     var centerCoords_svg = other_helpers.get_svgCoords_from_localCoords([centerCoords_local], capacity?.landBase?.union?.bbox)[0];
        //     availableUnions[j].properties.centerCoords_local = centerCoords_local;
        //     availableUnions[j].properties.centerCoords_svg = centerCoords_svg;

        //     // Add to list
        //     availableSurfaces.push(availableUnions[j]);
        // }
        // console.log("availableSurfaces", availableSurfaces);

        // Get union availableSurface
        var availableSurface = {...availableUnions2[0]};
        if (availableUnions2.length > 1) {
            for (var i = 1; i < availableUnions2.length; i++) {
                availableSurface = turf.union(availableSurface, availableUnions2[i]);
            }
        }
        availableSurface.properties = {
            area: turf.area(availableSurface),
        }


        // return buildable data
        data.push({
            elevation: elevation,
            // workingSurfaces: workingSurfaces,
            workingSurfaces: workingSurfaces2,
            // availableSurfaces: availableSurfaces,
            availableSurfaces: availableUnions2,
            availableSurface: availableSurface,
            availableArea: availableArea2,
            buildableArea: buildableArea2,
        })
    }

    console.log("data", data);
    return data;
}

const getLinesSimplified = (buildableSurfaceOutline_out) => {
    // Split outline
    var buildableSurfaceLines = [];
    for (var i = 1; i < buildableSurfaceOutline_out.geometry.coordinates.length; i++) {
        var line = turf.lineString([buildableSurfaceOutline_out.geometry.coordinates[i - 1], buildableSurfaceOutline_out.geometry.coordinates[i]]);
        // Simplify by not taking lines smallest than 1 meter
        if ((turf.length(line) * 1000) >= 1) {
            buildableSurfaceLines.push(line);
        }
    }
    console.log("buildableSurfaceLines", buildableSurfaceLines);

    // Correct coords to erase discontinuities due to buffering
    for (var i = 0; i < buildableSurfaceLines.length; i++) {
        var last = i - 1;
        if (i === 0) { last = buildableSurfaceLines.length - 1 }
        // Check if end point of last is different from start point of i
        if (buildableSurfaceLines[last].geometry.coordinates[1] !== buildableSurfaceLines[i].geometry.coordinates[0]) {
            // Along last
            var lastPoint = turf.destination(buildableSurfaceLines[last].geometry.coordinates[1], 0.01, turf.bearing(turf.point(buildableSurfaceLines[last].geometry.coordinates[0]), turf.point(buildableSurfaceLines[last].geometry.coordinates[1])));
            var lastLine = turf.lineString([buildableSurfaceLines[last].geometry.coordinates[0], turf.getCoord(lastPoint)]);
            // Along current
            var nextPoint = turf.destination(buildableSurfaceLines[i].geometry.coordinates[0], 0.01, turf.bearing(turf.point(buildableSurfaceLines[i].geometry.coordinates[1]), turf.point(buildableSurfaceLines[i].geometry.coordinates[0])));
            var nextLine = turf.lineString([turf.getCoord(nextPoint), buildableSurfaceLines[i].geometry.coordinates[1]]);
            // Intersecting point
            var intersection = turf.lineIntersect(lastLine, nextLine);
            // Change values if intersection exists
            if (intersection.features.length > 0) {
                buildableSurfaceLines[last].geometry.coordinates[1] = turf.getCoord(intersection.features[0]);
                buildableSurfaceLines[i].geometry.coordinates[0] = turf.getCoord(intersection.features[0]);
            }
            else {
                // Else, Get midpoint
                var midpoint = turf.midpoint(turf.point(buildableSurfaceLines[last].geometry.coordinates[1]), turf.point(buildableSurfaceLines[i].geometry.coordinates[0]));
                buildableSurfaceLines[last].geometry.coordinates[1] = turf.getCoord(midpoint);
                buildableSurfaceLines[i].geometry.coordinates[0] = turf.getCoord(midpoint);
            }
        }
    }
    console.log("buildableSurfaceLines2", buildableSurfaceLines);


    // Simplify by mergin lines with angle between 175 & 185 deg (almost straight)
    var anglePoints = [];
    for (var i = 0; i < buildableSurfaceLines.length; i++) {
        var last = i - 1;
        if (last === -1) { last = buildableSurfaceLines.length - 1 }
        var lastBearing = turf.bearing(turf.point(buildableSurfaceLines[last].geometry.coordinates[0]), buildableSurfaceLines[last].geometry.coordinates[1]);
        var iBearing = turf.bearing(turf.point(buildableSurfaceLines[i].geometry.coordinates[1]), buildableSurfaceLines[i].geometry.coordinates[0]);
        var angle = iBearing - lastBearing;
        if (angle < 0) { angle = angle + 360 }
        if (angle <= 175 || angle >= 185) {
            anglePoints.push(buildableSurfaceLines[i].geometry.coordinates[0]);
        }
    }

    // Add first point to close it
    anglePoints = anglePoints.concat([anglePoints[0]])

    console.log("anglePoints", anglePoints);


    return anglePoints
}


const getBuildableLevelInterceptor = (capacity, height0, height1, buildableMesh) => {
    // Set point 0
    // var point0 = capacity.landBase.union.bbox.origin;
    var point0 = capacity.landBase.union.center.geometry.coordinates;
    // Create 3D shape of bbox
    // Get bbox local coordinates
    // console.log("bbox global coords: ", capacity.landBase.union.bbox.coordinates[0]);
    // console.log("bbox global coords scaled", turf.transformScale(turf.polygon(capacity.landBase.union.bbox.coordinates), 1.01).geometry.coordinates[0]);
    var bbox_coords_global = turf.transformScale(turf.polygon(capacity.landBase.union.bbox.coordinates), 1.1).geometry.coordinates[0];
    var bbox_coords = [];
    for (var i = 0; i < bbox_coords_global.length; i++) {
        var deltaX = (turf.distance(turf.point(bbox_coords_global[i]), turf.point([point0[0], bbox_coords_global[i][1]])) * 1000);
        if (bbox_coords_global[i][0] < point0[0]) {
            deltaX = -deltaX;
        }
        var deltaY = (turf.distance(turf.point(bbox_coords_global[i]), turf.point([bbox_coords_global[i][0], point0[1]])) * 1000);
        if (bbox_coords_global[i][1] < point0[1]) {
            deltaY = -deltaY;
        }
        bbox_coords.push([deltaX, deltaY]);
    }
    // console.log("bbox local coords: ", bbox_coords);
    // Shape and Geometry
    var bboxShape = new THREE.Shape();
    bboxShape.moveTo(bbox_coords[0][0], bbox_coords[0][1]);
    bboxShape.lineTo(bbox_coords[0][0], bbox_coords[0][1]);
    bboxShape.lineTo(bbox_coords[1][0], bbox_coords[1][1]);
    bboxShape.lineTo(bbox_coords[2][0], bbox_coords[2][1]);
    bboxShape.lineTo(bbox_coords[3][0], bbox_coords[3][1]);
    bboxShape.lineTo(bbox_coords[4][0], bbox_coords[4][1]);
    var bboxExtrudeSettings = {
        steps: 1,
        depth: height1 - height0,
        bevelEnabled: false
    };
    var bboxGeometry = new THREE.ExtrudeGeometry(bboxShape, bboxExtrudeSettings);
    bboxGeometry.translate(0, 0, height0);
    var bboxMaterial = new THREE.MeshPhongMaterial({
        color: 'red',
        transparent: true,
        opacity: 0.4
    });
    var bboxMesh = new THREE.Mesh(bboxGeometry, bboxMaterial);

    // Get intersection
    bboxMesh.updateMatrix();
    buildableMesh.updateMatrix();
    var interceptorMesh = CSG.intersect(bboxMesh, buildableMesh);


    return interceptorMesh;
}







// ________________________________________________ RULES

export const get_rules_effect = (capa) => {

    var rules_effect = {
        max_height: capa?.buildable?.volume?.parameters?.max_height || 0,
        max_height_building: 0,
        max_height_facade: 0,
        max_area_total: 0,
        max_nb_level_super: null,
        max_nb_level_infra: null,
        min_height_level_ground: 0,
        min_height_level_current: 0,
        min_height_level_top: 0,
        min_height_level_sublevels: 0,
        min_roof_angle: 0,
        max_height_building_zones: [],
        max_height_facade_zones: [],
        buildable_super_zones: [],
        buildable_infra_zones: [],
        max_area_total_zones: [],
        alignments: [],
        parking: {
            type: "value",
            type_ext: true,
            type_sub: true,
            value: 0,
            avg_area_spot_ext: 25,
            avg_area_spot_sub: 28,
            land_area_usage_ratio: 0.8
        },
        min_offset_facade_edge: null,
        min_offset_facade_main: null,
        min_top_levels_offset: {offset: 0, nb_level: 0}
    }

    var max_height_previous = JSON.parse(JSON.stringify(rules_effect.max_height));


    if (capa?.rules?.ruleset && capa.rules.ruleset.length > 0) {
        for (var i = 0; i < capa.rules.ruleset.length; i++) {
            for (var j = 0; j < capa.rules.ruleset[i].items.length; j++) {
                // Check if max height building
                // if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Buildable_Volume") {
                //     var test = node_helpers.nodeInterpretor([capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.data?.target[0]], capa, null);
                //     console.log("Max height potential", test);
                //     if (test === true && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j]?.interpreted.length > 0) {
                //         var interpretation = node_helpers.nodeInterpretor(capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.data?.source, capa, null);
                //         if (interpretation.length > 0 && interpretation[0]?.userData?.depth && interpretation[0]?.userData?.depth >= rules_effect.max_height) {
                //             console.log("OK", interpretation[0]?.userData?.depth);
                //             rules_effect.max_height = interpretation[0]?.userData?.depth;
                //         }
                //     }
                // }
                // Check if max height building
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Building_Height") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > rules_effect.max_height_building) {
                        rules_effect.max_height_building = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height;
                    }
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter.length > 0) {
                        var perim = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter[0];
                        if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter.length > 1) {
                            var perim_coords = [];
                            for (var k = 0; k < capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter.length; k++) {
                                perim_coords.push(capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter[k]?.geometry?.coordinates);
                            }
                            perim = turf.multiPolygon(perim_coords);
                        }
                        rules_effect.max_height_building_zones.push({
                            height: capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height,
                            perimeter: perim,
                        });
                    }
                }
                // Check if max height facade
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Facade_Height") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > rules_effect.max_height_facade) {
                        rules_effect.max_height_facade = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height;
                    }
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter.length > 0) {
                        var perim = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter[0];
                        if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter.length > 1) {
                            var perim_coords = [];
                            for (var k = 0; k < capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter.length; k++) {
                                perim_coords.push(capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.perimeter[k]?.geometry?.coordinates);
                            }
                            perim = turf.multiPolygon(perim_coords);
                        }
                        rules_effect.max_height_facade_zones.push({
                            height: capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height,
                            perimeter: perim,
                        });
                    }
                }
                // Check if max area total
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Buildable_Area_Total") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.isSuper === true && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.totalBuildableArea > rules_effect.max_area_total) {
                        rules_effect.max_area_total = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.totalBuildableArea;
                    }
                    rules_effect.max_area_total_zones.push({
                        ratio: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.totalBuildableArea / capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                        ratio_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.totalBuildableArea / capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                        perimeter: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.turfData,
                        perimeter_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.turfData,
                        area: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                        area_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                        buildableArea: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.totalBuildableArea,
                        buildableArea_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.totalBuildableArea,
                    });
                }
                // Check if max nb level super && infra
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Level_Nb") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.isSuper === true && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.nb_max > rules_effect.max_nb_level_super) {
                        rules_effect.max_nb_level_super = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.nb_max;
                    }
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.isInfra === true && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.nb_max > rules_effect.max_nb_level_infra) {
                        rules_effect.max_nb_level_infra = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.nb_max;
                    }
                }
                // Check if level height
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Level_Height") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.levels && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.levels.length > 0 && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > 0) {
                        if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.levels.includes("ground") && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > rules_effect.min_height_level_ground) {
                            rules_effect.min_height_level_ground = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height;
                        }
                        if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.levels.includes("current") && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > rules_effect.min_height_level_current) {
                            rules_effect.min_height_level_current = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height;
                        }
                        if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.levels.includes("top") && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > rules_effect.min_height_level_top) {
                            rules_effect.min_height_level_top = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height;
                        }
                        if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.levels.includes("sublevels") && capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height > rules_effect.min_height_level_sublevels) {
                            rules_effect.min_height_level_sublevels = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.height;
                        }
                    }
                }
                // Check if min roof angle
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Roof_Angle") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.roof_angle) {
                        rules_effect.min_roof_angle = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.roof_angle;
                    }
                }
                // Check if min top level offset
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Level_Offset") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.offset > rules_effect.min_top_levels_offset.offset) {
                        rules_effect.min_top_levels_offset.offset = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.offset;
                        rules_effect.min_top_levels_offset.nb_level = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.nb_level;
                    }
                }
                // Check if buildable zones
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && (capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Buildable_Area" || capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Unbuildable_Area")) {
                    var ratio = null;
                    if (capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Buildable_Area") {
                        ratio = capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.buildableArea / capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea;
                    }
                    else if (capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Unbuildable_Area") {
                        ratio = 1 - (capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.unbuildableArea / capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea);
                    }
                    if (ratio < 0) { ratio = 0 };
                    if (ratio > 1) { ratio = 1 };
                    if (capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.isSuper) {
                        rules_effect.buildable_super_zones.push({
                            ratio: ratio,
                            ratio_original: ratio,
                            perimeter: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.turfData,
                            perimeter_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.turfData,
                            area: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                            area_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                            buildableArea: ratio * capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                            buildableArea_original: ratio * capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                        });
                    }
                    if (capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.isInfra) {
                        rules_effect.buildable_infra_zones.push({
                            ratio: ratio,
                            ratio_original: ratio,
                            perimeter: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.turfData,
                            perimeter_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.turfData,
                            area: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                            area_original: capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                            buildableArea: ratio * capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                            buildableArea_original: ratio * capa.rules.ruleset[i].items[j]?.interpreted[0]?.userData?.availableArea,
                        });
                    }
                }
                // Check if min roof angle
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_BoundAlign") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.alignAll === true || capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.alignAll === false) {
                        rules_effect.alignments.push(capa.rules.ruleset[i].items[j].interpreted[0]?.userData);
                    }
                }
                // Check if min parking area
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Parking_Area_Total") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.value) {
                        rules_effect.parking.type = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.type;
                        rules_effect.parking.value = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.value;
                    }
                }
                // Check if parking type
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Parking_Type") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.availableArea) {
                        rules_effect.parking.type_ext = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.isExt;
                        rules_effect.parking.type_sub = capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.isSub;
                    }
                }
                // Check if offset
                if (capa.rules.ruleset[i].items[j].type === "rule" && capa.rules.ruleset[i].items[j]?.interpreted && capa.rules.ruleset[i].items[j].interpreted.length > 0 && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes && capa.rules.ruleset[i].items[j]?.data?.composition?.nodes[0]?.type === "Node_Rule_Building_Offset") {
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.offsetMain) {
                        rules_effect.min_offset_facade_main = { type: "recul", value: capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.offsetMain, origin: "rules" };
                    }
                    if (capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.offsetSecond) {
                        rules_effect.min_offset_facade_edge = { type: "recul", value: capa.rules.ruleset[i].items[j].interpreted[0]?.userData?.offsetSecond, origin: "rules" };
                    }
                }
            }
        }
    }



    // Initialize volume parameters
    var volume_parameters = {};
    if (capa?.buildable?.volume?.parameters) {
        volume_parameters = capa.buildable.volume.parameters;
    }


    // ______________ Get global values

    // _____ Max height global
    if (rules_effect.max_height_building < rules_effect.max_height_facade) {
        rules_effect.max_height_building = rules_effect.max_height_facade;
    }
    // Sort Max height zones
    rules_effect.max_height_building_zones.sort(function (a, b) { return b.height - a.height; })
    rules_effect.max_height_facade_zones.sort(function (a, b) { return b.height - a.height; })

    // _____ Max height building zones
    var max_height_zones = [];
    var basic_max_height = volume_parameters.max_height_database;
    // Initialize with total full land area
    max_height_zones.push({
        height: basic_max_height,
        perimeter: capa.landBase.union.geometry,
        area: turf.area(capa.landBase.union.geometry)
    })
    var all_zones = rules_effect.max_height_building_zones.concat(rules_effect.max_height_facade_zones);
    all_zones.forEach(zone_test => {
        var zones_to_add = [];
        max_height_zones.forEach(zone_exist => {
            console.log("zone_exist", zone_exist);
            console.log("zone_test", zone_test);
            if (zone_exist.area > 0 && (zone_test.height > zone_exist.height || zone_exist.height === volume_parameters.max_height_database)) {
                var intersection = turf.intersect(zone_test.perimeter, zone_exist.perimeter);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    if (intersection_area >= 1) {
                        // Add new zone
                        zones_to_add.push({
                            height: zone_test.height,
                            perimeter: intersection,
                            area: intersection_area
                        })
                        // Modify existing zone
                        if (intersection_area >= (zone_exist.area - 1)) {
                            zone_exist.area = 0;
                        }
                        else {
                            var difference = turf.difference(zone_exist.perimeter, zone_test.perimeter);
                            zone_exist.perimeter = difference;
                            zone_exist.area = turf.area(difference);
                        }
                    }
                }
            }
        });
        max_height_zones = max_height_zones.concat(zones_to_add);
    });
    console.log("max_height_zones", max_height_zones);
    // Groups zones by same height
    var max_height_zones_groups = {};
    max_height_zones.forEach(zone => {
        if (zone.area > 0) {
            if (!Object.keys(max_height_zones_groups).includes("h=" + zone.height)) {
                max_height_zones_groups["h=" + zone.height] = {
                    height: zone.height,
                    zones: [],
                    coords: []
                };
            }
            max_height_zones_groups["h=" + zone.height].zones.push(zone);
            if (zone.perimeter.geometry.type === "Polygon") {
                max_height_zones_groups["h=" + zone.height].coords.push(zone.perimeter.geometry.coordinates);
            }
            else if (zone.perimeter.geometry.type === "MultiPolygon") {
                max_height_zones_groups["h=" + zone.height].coords = max_height_zones_groups["h=" + zone.height].coords.concat(zone.perimeter.geometry.coordinates);
            }
        }
    });
    console.log("max_height_zones_groups", max_height_zones_groups);
    // Fusion zones
    var max_height_zones_final = [];
    Object.keys(max_height_zones_groups).forEach(list_key => {
        var zone_group = max_height_zones_groups[list_key];
        var perimeter = zone_group.zones[0].perimeter;
        if (zone_group.zones.length > 1) {
            for (var i = 1; i < zone_group.zones.length; i++) {
                perimeter = turf.union(perimeter, zone_group.zones[i].perimeter);
            }
        }
        max_height_zones_final.push({
            height: zone_group.height,
            perimeter: perimeter,
            area: turf.area(perimeter)
        })
    })
    max_height_zones_final.sort(function (a, b) {
        return b.height - a.height;
    })
    console.log("max_height_zones_final", max_height_zones_final);
    volume_parameters.max_height_building_zones = max_height_zones_final;

    // _____ Max height global
    rules_effect.max_height = null;
    if (max_height_zones_final.length > 0) {
        rules_effect.max_height = max_height_zones_final[0].height;
        volume_parameters.max_height = max_height_zones_final[0].height;
        if (max_height_zones_final[0].height === volume_parameters.max_height_database) {
            if (max_height_zones_final.length > 1) {
                volume_parameters.max_height_rules = max_height_zones_final[1].height;
            }
            else {
                volume_parameters.max_height_rules = null;
            }
            volume_parameters.max_height_type = "database";
        }
        else {
            volume_parameters.max_height_rules = max_height_zones_final[0].height;
            volume_parameters.max_height_type = "rules";
        }
    }
    else {
        volume_parameters.max_height = 0;
        volume_parameters.max_height_rules = null;
        volume_parameters.max_height_type = "default";
    }

    // _____ Check if max height changes
    var max_height_change = false;
    if (max_height_previous !== volume_parameters.max_height) {
        max_height_change = true;
    }

    // _____ Max height facade zones
    // var max_height_zones2 = [...max_height_zones_final];
    var max_height_zones2 = JSON.parse(JSON.stringify(max_height_zones_final));
    rules_effect.max_height_facade_zones.forEach(zone_test => {
        var zones_to_add = [];
        max_height_zones2.forEach(zone_exist => {
            if (zone_exist.area > 0 && zone_test.height < zone_exist.height) {
                var intersection = turf.intersect(zone_test.perimeter, zone_exist.perimeter);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    if (intersection_area >= 1) {
                        // Add new zone
                        zones_to_add.push({
                            height: zone_test.height,
                            perimeter: intersection,
                            area: intersection_area
                        })
                        // Modify existing zone
                        if (intersection_area >= (zone_exist.area - 1)) {
                            zone_exist.area = 0;
                        }
                        else {
                            var difference = turf.difference(zone_exist.perimeter, zone_test.perimeter);
                            zone_exist.perimeter = difference;
                            zone_exist.area = turf.area(difference);
                        }
                    }
                }
            }
        });
        max_height_zones2 = max_height_zones2.concat(zones_to_add);
    });
    var max_height_zones2_groups = {};
    max_height_zones2.forEach(zone => {
        if (zone.area > 0) {
            if (!Object.keys(max_height_zones2_groups).includes("h=" + zone.height)) {
                max_height_zones2_groups["h=" + zone.height] = {
                    height: zone.height,
                    zones: [],
                    coords: []
                };
            }
            max_height_zones2_groups["h=" + zone.height].zones.push(zone);
            if (zone.perimeter.geometry.type === "Polygon") {
                max_height_zones2_groups["h=" + zone.height].coords.push(zone.perimeter.geometry.coordinates);
            }
            else if (zone.perimeter.geometry.type === "MultiPolygon") {
                max_height_zones2_groups["h=" + zone.height].coords = max_height_zones2_groups["h=" + zone.height].coords.concat(zone.perimeter.geometry.coordinates);
            }
        }
    });
    var max_height_zones2_final = [];
    Object.keys(max_height_zones2_groups).forEach(list_key => {
        var zone_group = max_height_zones2_groups[list_key];
        var perimeter = zone_group.zones[0].perimeter;
        if (zone_group.zones.length > 1) {
            for (var i = 1; i < zone_group.zones.length; i++) {
                perimeter = turf.union(perimeter, zone_group.zones[i].perimeter);
            }
        }
        max_height_zones2_final.push({
            height: zone_group.height,
            perimeter: perimeter,
            area: turf.area(perimeter)
        })
    })
    max_height_zones2_final.sort(function (a, b) {
        return b.height - a.height;
    })
    volume_parameters.max_height_facade_zones = max_height_zones2_final;

    // _____ Max height facade global
    if (max_height_zones2_final.length > 0) {
        volume_parameters.max_height_facade = max_height_zones2_final[0].height;
    }
    else {
        volume_parameters.max_height_facade = 0;
    }

    // _____ Max area total zones
    rules_effect.max_area_total_zones.sort(function (a, b) {
        return b.area_original - a.area_original;
    })
    var max_area_total_zones = [];
    var land_area = turf.area(capa.landBase.union.geometry)
    max_area_total_zones.push({
        ratio: Infinity,
        ratio_original: Infinity,
        perimeter: capa.landBase.union.geometry,
        perimeter_original: capa.landBase.union.geometry,
        area: land_area,
        area_original: land_area,
        buildableArea: land_area * Infinity,
        buildableArea_original: land_area * Infinity,
    })
    var all_zones = rules_effect.max_area_total_zones;
    all_zones.forEach(zone_test => {
        var zones_to_add = [];
        max_area_total_zones.forEach(zone_exist => {
            if (zone_exist.area > 0 && zone_test.ratio < zone_exist.ratio) {
                var intersection = turf.intersect(zone_test.perimeter, zone_exist.perimeter);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    if (intersection_area >= 1) {
                        // var intersection_ratio = Math.min(zone_test.ratio, zone_exist.ratio);
                        var intersection_ratio = zone_test.ratio;
                        var intersection_buildableArea = intersection_area * intersection_ratio;
                        // Add new zone
                        zones_to_add.push({
                            ratio: intersection_ratio,
                            ratio_original: zone_test.ratio_original,
                            perimeter: intersection,
                            perimeter_original: zone_test.perimeter_original,
                            area: intersection_area,
                            area_original: zone_test.area_original,
                            buildableArea: intersection_buildableArea,
                            buildableArea_original: zone_test.buildableArea_original,
                        })
                        // Modify existing zone
                        if (intersection_area >= (zone_exist.area - 1)) {
                            zone_exist.area = 0;
                        }
                        else {
                            var difference = turf.difference(zone_exist.perimeter, zone_test.perimeter);
                            zone_exist.perimeter = difference;
                            zone_exist.area = turf.area(difference);
                            zone_exist.buildableArea -= intersection_buildableArea;
                            zone_exist.ratio = zone_exist.buildableArea / zone_exist.area;
                        }
                    }
                }
            }
        });
        max_area_total_zones = max_area_total_zones.concat(zones_to_add);
    });
    var max_area_total_zones_final = [];
    max_area_total_zones.forEach(zone => {
        if (zone.area > 0) {
            max_area_total_zones_final.push(zone);
        }
    })
    max_area_total_zones_final.sort(function (a, b) {
        return b.ratio - a.ratio;
    })
    volume_parameters.max_area_total_zones = max_area_total_zones_final;
    volume_parameters.max_area_total_zones_original = rules_effect.max_area_total_zones;

    // _____ Max area total global
    if (rules_effect.max_area_total > 0) {
        volume_parameters.max_area_total = rules_effect.max_area_total;
    }
    else {
        volume_parameters.max_area_total = null;
    }

    // _____ Max nb level super
    if (rules_effect.max_nb_level_super > 0) {
        volume_parameters.max_nb_level_super = rules_effect.max_nb_level_super;
    }
    else {
        volume_parameters.max_nb_level_super = null;
    }

    // _____ Max nb level infra
    if (rules_effect.max_nb_level_infra >= 0) {
        volume_parameters.max_nb_level_infra = rules_effect.max_nb_level_infra;
    }
    else {
        volume_parameters.max_nb_level_infra = null;
    }

    // _____ Min height level
    volume_parameters.min_height_level = {
        ground: null,
        current: null,
        top: null,
        sublevels: null,
    };
    if (rules_effect.min_height_level_ground > 0) {
        volume_parameters.min_height_level.ground = rules_effect.min_height_level_ground;
    }
    if (rules_effect.min_height_level_current > 0) {
        volume_parameters.min_height_level.current = rules_effect.min_height_level_current;
    }
    if (rules_effect.min_height_level_top > 0) {
        volume_parameters.min_height_level.top = rules_effect.min_height_level_top;
    }
    if (rules_effect.min_height_level_sublevels > 0) {
        volume_parameters.min_height_level.sublevels = rules_effect.min_height_level_sublevels;
    }

    // _____ Max building width
    volume_parameters.max_building_width = 15;

    // _____ Min offset facade main
    volume_parameters.min_offset_facade_main = rules_effect?.min_offset_facade_main || { type: "recul", value: 6, origin: "user" };

    // _____ Min offset facade edge
    volume_parameters.min_offset_facade_edge = rules_effect?.min_offset_facade_edge || { type: "recul", value: 4, origin: "user" };

    // _____ Min roof angle
    volume_parameters.roof_angle = rules_effect.min_roof_angle;

    // _____ Min Top Levels Offset
    volume_parameters.min_top_levels_offset = rules_effect.min_top_levels_offset;

    // _____ Alignments
    volume_parameters.alignments = rules_effect.alignments;

    // _____ Parking
    volume_parameters.parking = rules_effect.parking;

    // _____ Buildable super zones
    rules_effect.buildable_super_zones.sort(function (a, b) {
        return b.area_original - a.area_original;
    })
    var buildable_super_zones = [];
    var land_area = turf.area(capa.landBase.union.geometry)
    buildable_super_zones.push({
        ratio: 1,
        ratio_original: 1,
        perimeter: capa.landBase.union.geometry,
        perimeter_original: capa.landBase.union.geometry,
        area: land_area,
        area_original: land_area,
        buildableArea: land_area,
        buildableArea_original: land_area,
    })
    var all_zones = rules_effect.buildable_super_zones;
    all_zones.forEach(zone_test => {
        var zones_to_add = [];
        buildable_super_zones.forEach(zone_exist => {
            if (zone_exist.area > 0 && zone_test.ratio < zone_exist.ratio) {
                var intersection = turf.intersect(zone_test.perimeter, zone_exist.perimeter);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    if (intersection_area >= 1) {
                        // var intersection_ratio = Math.min(zone_test.ratio, zone_exist.ratio);
                        var intersection_ratio = zone_test.ratio;
                        var intersection_buildableArea = intersection_area * intersection_ratio;
                        // Add new zone
                        zones_to_add.push({
                            ratio: intersection_ratio,
                            ratio_original: zone_test.ratio_original,
                            perimeter: intersection,
                            perimeter_original: zone_test.perimeter_original,
                            area: intersection_area,
                            area_original: zone_test.area_original,
                            buildableArea: intersection_buildableArea,
                            buildableArea_original: zone_test.buildableArea_original,
                        })
                        // Modify existing zone
                        if (intersection_area >= (zone_exist.area - 1)) {
                            zone_exist.area = 0;
                        }
                        else {
                            var difference = turf.difference(zone_exist.perimeter, zone_test.perimeter);
                            zone_exist.perimeter = difference;
                            zone_exist.area = turf.area(difference);
                            zone_exist.buildableArea -= intersection_buildableArea;
                            zone_exist.ratio = zone_exist.buildableArea / zone_exist.area;
                        }
                    }
                }
            }
        });
        buildable_super_zones = buildable_super_zones.concat(zones_to_add);
    });
    var buildable_super_zones_final = [];
    buildable_super_zones.forEach(zone => {
        if (zone.area > 0) {
            buildable_super_zones_final.push(zone);
        }
    })
    buildable_super_zones_final.sort(function (a, b) {
        return b.ratio - a.ratio;
    })
    volume_parameters.buildable_super_zones = buildable_super_zones_final;
    volume_parameters.buildable_super_zones_original = rules_effect.buildable_super_zones;

    // _____ Buildable infra zones
    rules_effect.buildable_infra_zones.sort(function (a, b) {
        return b.area_original - a.area_original;
    })
    var buildable_super_zones = [];
    var land_area = turf.area(capa.landBase.union.geometry);
    buildable_super_zones.push({
        ratio: 1,
        ratio_original: 1,
        perimeter: capa.landBase.union.geometry,
        perimeter_original: capa.landBase.union.geometry,
        area: land_area,
        area_original: land_area,
        buildableArea: land_area,
        buildableArea_original: land_area,
    })
    var all_zones = rules_effect.buildable_infra_zones;
    all_zones.forEach(zone_test => {
        var zones_to_add = [];
        buildable_super_zones.forEach(zone_exist => {
            if (zone_exist.area > 0 && zone_test.ratio < zone_exist.ratio) {
                var intersection = turf.intersect(zone_test.perimeter, zone_exist.perimeter);
                if (intersection !== null) {
                    var intersection_area = turf.area(intersection);
                    if (intersection_area >= 1) {
                        // var intersection_ratio = Math.min(zone_test.ratio, zone_exist.ratio);
                        var intersection_ratio = zone_test.ratio;
                        var intersection_buildableArea = intersection_area * intersection_ratio;
                        // Add new zone
                        zones_to_add.push({
                            ratio: intersection_ratio,
                            ratio_original: zone_test.ratio_original,
                            perimeter: intersection,
                            perimeter_original: zone_test.perimeter_original,
                            area: intersection_area,
                            area_original: zone_test.area_original,
                            buildableArea: intersection_buildableArea,
                            buildableArea_original: zone_test.buildableArea_original,
                        })
                        // Modify existing zone
                        if (intersection_area >= (zone_exist.area - 1)) {
                            zone_exist.area = 0;
                        }
                        else {
                            var difference = turf.difference(zone_exist.perimeter, zone_test.perimeter);
                            zone_exist.perimeter = difference;
                            zone_exist.area = turf.area(difference);
                            zone_exist.buildableArea -= intersection_buildableArea;
                            zone_exist.ratio = zone_exist.buildableArea / zone_exist.area;
                        }
                    }
                }
            }
        });
        buildable_super_zones = buildable_super_zones.concat(zones_to_add);
    });
    var buildable_super_zones_final = [];
    buildable_super_zones.forEach(zone => {
        if (zone.area > 0) {
            buildable_super_zones_final.push(zone);
        }
    })
    buildable_super_zones_final.sort(function (a, b) {
        return b.ratio - a.ratio;
    })
    volume_parameters.buildable_infra_zones = buildable_super_zones_final;
    volume_parameters.buildable_infra_zones_original = rules_effect.buildable_super_zones;



    return {rules_effect, volume_parameters, max_height_change}
}

const get_treated_zones = () => {

}