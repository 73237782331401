import React, { useCallback, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';


const Node_Placeholder = ({ id, data }) => {

    // Create filtered block list
    const block_list = data.block_list;
    var block_list_filtered = {};
    Object.keys(block_list).forEach(group_key => {
        var group = block_list[group_key];
        var blocks = [];
        group.items.forEach(block => {
            var test = false;
            block.data.source.forEach(source => {
                if (data.accepted.includes(source.acceptance)) {
                    test = true;
                }
            });
            if (test === true) {
                blocks.push(block);
            }
        });
        if (blocks.length > 0) {
            var newGroup = {
                label: group.label,
                id: group.id,
                items: blocks
            }
            block_list_filtered[group.id] = (newGroup);
        }
    });

    const [isListVisible, setIsListVisible] = useState(false);

    const setNode = (node) => {
        // console.log("data", data);
        // console.log("node", node);

        // Get all accepeted
        var accepted = [];
        node.data.target.forEach(target => {
            accepted.push(target.accepted);
        })
        // Replace
        data.handleReplace(id, node, accepted);
    }


    return (
        <div id={"block_" + id} className="ruleeditor__node_ph_container">
            <div className="ruleeditor__node_ph_description">
                Ajouter un bloc
            </div>
            <div className="ruleeditor__node_ph_body">
                <div className="ruleeditor__node_ph_body_button" onClick={() => { setIsListVisible(true) }}>+</div>
            </div>
            {isListVisible &&
                <div className="ruleeditor__node_ph_list_container scale-up-top nowheel" onMouseLeave={() => { setIsListVisible(true) }}>
                    <div className="ruleeditor__node_ph_list_container_label">Liste des blocs disponibles :</div>
                    <div className="ruleeditor__node_ph_list_container_close"><img src={close} alt="close_list" onClick={() => { setIsListVisible(false) }} /></div>
                    <div className="ruleeditor__node_ph_list">
                        {Object.keys(block_list_filtered).map((item, index) => {
                            return (
                                <div key={"group_" + index} className="ruleeditor__node_ph_list_group_container">
                                    {index === 0 ?
                                        <div className="ruleeditor__node_ph_list_group_label_top">{block_list_filtered[item].label}</div>
                                        :
                                        <div className="ruleeditor__node_ph_list_group_label">{block_list_filtered[item].label}</div>
                                    }
                                    <div className="ruleeditor__node_ph_list_item_container">
                                        {block_list_filtered[item].items.map((item, index) => {
                                            if (item.data)
                                                return (
                                                    <div key={"item_" + index} className="ruleeditor__node_ph_list_item" onClick={() => { setNode(item) }}>
                                                        <div className="ruleeditor__node_ph_list_item_label">{item.data.label}</div>
                                                        <div className="ruleeditor__node_ph_list_item_description">{item.data.description}</div>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    );
};

export default Node_Placeholder;