import { Draggable } from "react-beautiful-dnd";

export const Drag = ({ id, index, type, subtype, ...props }) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => {
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
                        {(subtype === "title") &&
                            // <div className="app__sectiondata_widget_spacer_10"></div>
                            <div className={`app__sectiondata_widget_spacer_none ${index > 0 ? "app__sectiondata_widget_spacer_20" : "app__sectiondata_widget_spacer_0"}`}></div>
                        }
                        <div className={`${type === "category" ? "drag-handle_category" : "drag-handle_item"}`} {...provided.dragHandleProps}></div>
                        {props.children}
                    </div>
                );
            }}
        </Draggable>
    );
};