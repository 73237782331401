import { USER_CREATE,USER_CREATE_AUTH, AUTH, RESET, LOGOUT, SENDMAIL, USER_CONTACT } from '../constants/actionTypes';

const authReducer = (state = { authData: null }, action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem('profile', JSON.stringify({ ...action?.payload }));
            return { ...state, authData: action?.payload };
        case USER_CREATE:
            return { ...state, authData: action?.payload };
        case USER_CREATE_AUTH:
            console.log("USER_CREATE_AUTH", action);
            localStorage.setItem('profile', JSON.stringify({ ...action?.payload }));
            return { ...state, authData: action?.payload };
        case SENDMAIL:
            return { ...state, authData: null };
        case USER_CONTACT:
            return { ...state, authData: null };
        case RESET:
            localStorage.clear();
            return { ...state, authData: null };
        case LOGOUT:
            localStorage.clear();
            return { ...state, authData: null };
        default:
            return state;
    }
};

export default authReducer;