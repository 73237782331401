import { UPDATE, GET } from '../constants/actionTypes';

const usersReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case GET:
            return { ...state, user: action.payload.user };
        case UPDATE:
            // Get profile
            var profile = JSON.parse(localStorage.getItem('profile'));
            // Update only user
            profile.result = action?.payload?.result;
            // Set profile
            localStorage.setItem('profile', JSON.stringify(profile));
            return { ...state, users: [action?.payload?.result] };
        // return { ...state, users: state.users.map((user) => (user._id === action.payload._id ? action.payload : user)) };
        default:
            return state;
    }
};

export default usersReducer;