// Import dependencies
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { capaDelete, capaCopy } from '../../../actions/capa';
import moment from 'moment';
import 'moment/locale/fr.js';

// Import external functions
import * as helpers from '../../../helpers/Other_helpers';

// Import CSS & assets
import './Card.css';
import logo_card from '../../../assets/logo_card.svg';
import ico_delete from '../../../assets/ico/ico_delete.svg';
import ico_copy from '../../../assets/ico/ico_copy.svg';
import user_pp from '../../../assets/user_pp_default.png';

const Card = ({ capaData, user, capacityFullList, setCapacityFullList, setCapacityFilteredList }) => {

    
    // Set moment to french time
    moment.locale('fr');

    // Set global functions
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Set specific functions
    function getRandomAvatar(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return 'https://avatars.dicebear.com/api/adventurer-neutral/' + result + '.svg?scale=90';
    }
    const openCapa = (capaId) => {
        // navigate('/capacity/' + capaId);
        navigate('/capastudy/' + capaId);
    }
    const [userCreation, setUserCreation] = useState({
        name: "",
        img: ""
    });
    useEffect(() => {
        get_creationUser();
    }, [capaData]);
    const get_creationUser = () => {
        // Get user creation
        if (capaData.creationUserId === user?.result?._id) {
            setUserCreation({
                name: "par vous",
                img: user?.result?.avatar
            });
        }
        else {
            var user_creation = null;
            if (user.users && user.users.length > 0) {
                for (var i = 0; i < user.users.length; i++) {
                    if (capaData.creationUserId === user.users[i]["_id"]) {
                        user_creation = user.users[i];
                    }
                }
            }
            if (user_creation !== null) {
                setUserCreation({
                    name: "par " + user_creation.firstName,
                    img: user_creation.avatar
                });
            }
        }
    }
    // Delete capa
    const [deleting, setDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const deleteCapa = () => {
        setDeleting(true);
        dispatch(capaDelete(capaData._id, handleSuccessDelete, handleFail));
    }
    // Copy capa
    const [copying, setCopying] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const copyCapa = () => {
        setCopying(true);
        var userId = "";
        if (user?.result?._id) { userId = user.result._id };
        const formData = { userId, toAdmin: false };
        dispatch(capaCopy(capaData._id, formData, handleSuccessCopy, handleFail));
    }
    // Loading
    const handleSuccessDelete = (data) => {
        console.log("SUCCESS DELETE", data);
        setIsDeleted(true);
        setDeleting(false);
        // Remove capa from list
        var newCapaList = [];
        capacityFullList.forEach(capa => {
            if (capa._id !== capaData._id) {
                newCapaList.push(capa);
            }
        });
        setTimeout(() => {
            setIsDeleted(false);
            setDeleteValidation(false);
            setCopyValidation(false);
            setCapacityFullList(newCapaList);
            setCapacityFilteredList(newCapaList);
        }, 500);
    }
    const handleSuccessCopy = (data) => {
        console.log("SUCCESS COPY", data);
        setIsCopied(true);
        setCopying(false);
        // Add capa from list
        // var newCapaList = [data.result];
        // capacityFullList.forEach(capa => {
        //     newCapaList.push(capa);
        // });
        var newCapaList = [data.result].concat(capacityFullList);
        console.log('CAPA LIST', newCapaList);
        setDeleteValidation(false);
        setCopyValidation(false);
        setCapacityFullList(newCapaList);
        setCapacityFilteredList(newCapaList);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    // Set hooks
    const [pp_src, setPp_src] = useState(getRandomAvatar(10));
    const [deleteValidation, setDeleteValidation] = useState(false);
    const [copyValidation, setCopyValidation] = useState(false);

    if (capaData?._id) {
        return (
            // <motion.div layout animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} className="app__card_container">
            <div className={`app__card_container ${isDeleted === true ? "app__card_container_deleted" : ""}`}>
                <div className="app__card_top_container" onMouseLeave={() => { setDeleteValidation(false); setCopyValidation(false) }}>
                    <div className="app__card_top_title">
                        <h5>{capaData.title}</h5>
                    </div>
                    <div className="app__card_top_img">
                        {(capaData?.simplified?.poly?.bounds_groups && capaData?.simplified?.poly?.bounds_groups.length) > 0 ?
                            // <div>IMAGE</div>
                            <svg width="800" height="800" viewBox="-20 -20 840 840" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <polygon points={helpers.get_svgCoords_from_boundsGroupsList(capaData?.simplified?.poly?.bounds_groups, capaData?.simplified?.poly?.bbox).join(" ")} fillRule="evenodd" fill="#FFFFFF" fillOpacity="0.05" stroke="white" strokeWidth="8" />
                                {capaData?.simplified?.poly?.bounds_groups.map((item, index) => {
                                    return (
                                        <circle key={"circle_" + index} cx={helpers.get_svgCoords_from_localCoords([item.start_coord_local], capaData?.simplified?.poly?.bbox)[0][0]} cy={helpers.get_svgCoords_from_localCoords([item.start_coord_local], capaData?.simplified?.poly?.bbox)[0][1]} r="14" fill="white" />
                                    )
                                })}
                            </svg>
                            :
                            <img src={logo_card} alt="" />
                        }
                    </div>
                    <div className="app__card_top_button_container">
                        <div className="app__card_top_button" onClick={() => { openCapa(capaData._id) }}>Ouvrir</div>
                        <div className="app__card_top_button_small_green" onClick={() => { setCopyValidation(true) }}><img src={ico_copy} alt="copy" /></div>
                        <div className="app__card_top_button_small" onClick={() => { setDeleteValidation(true) }}><img src={ico_delete} alt="delete" /></div>
                    </div>
                    {deleteValidation &&
                        <div className="app__card_top_validation_container scale-up-center">
                            {deleting === false ?
                                <>
                                    <div className="app__card_top_validation_text">Voulez-vous supprimer cette étude ?</div>
                                    <div className="app__card_top_validation_button_red" onClick={() => { deleteCapa() }}>Supprimer</div>
                                    <div className="app__card_top_validation_button_green" onClick={() => { setDeleteValidation(false) }}>Conserver</div>
                                </>
                                :
                                <>
                                    <div className="app__card_top_validation_text">Suppression en cours...</div>
                                </>
                            }
                        </div>
                    }
                    {copyValidation &&
                        <div className="app__card_top_validation_container scale-up-center">
                            {copying === false ?
                                <>
                                    <div className="app__card_top_validation_text">Voulez-vous dupliquer cette étude ?</div>
                                    <div className="app__card_top_validation_button_green" onClick={() => { copyCapa() }}>Dupliquer</div>
                                    <div className="app__card_top_validation_button_red" onClick={() => { setCopyValidation(false) }}>Annuler</div>
                                </>
                                :
                                <>
                                    <div className="app__card_top_validation_text">Copie de l'étude en cours...</div>
                                </>
                            }
                        </div>
                    }
                </div>
                <div className="app__card_bottom_container">
                    <div className="app__card__bottom_body">
                        <div className="app__card_bottom_tags">
                            {capaData?.simplified?.city &&
                                <span>{capaData?.simplified.city}</span>
                            }
                            {(capaData?.simplified?.zones && capaData?.simplified?.zones !== "") &&
                                <span>{capaData?.simplified?.zones}</span>
                            }
                            {/* {(capaData?.simplified?.buildable_area && capaData?.simplified?.buildable_area > 0) &&
                                <span>{helpers.get_pretty_num(Math.round(capaData?.simplified?.buildable_area))} m² constructibles</span>
                            } */}
                        </div>
                        <div className="app__card_bottom_title">
                            <h5>{capaData.title}</h5>
                        </div>
                        <div className="app__card_bottom_description">
                            {capaData.description}
                        </div>
                    </div>
                    <div className="app__card_bottom_footer">
                        <div className="app__card_bottom_footer_userpp">
                            <img src={userCreation.img} alt="user_pp" />
                        </div>
                        <div className="app__card_bottom_footer_userinfos">
                            <div className="app__card_bottom_footer_userinfos_time">
                                Crée {moment(capaData.creationDate).startOf().fromNow()}
                            </div>
                            <div className="app__card_bottom_footer_userinfos_name">
                                {userCreation.name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // </motion.div>
        );
    }
};

export default Card;