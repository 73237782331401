// Import dependencies
import React from 'react';

// Import CSS & assets
import './CTA.css';


const CTA = () => {
    return (
        <div className="landing__cta section__padding">
            <div className="landing__cta_container">
                <div className="landing__cta_content">
                    <p onClick={() => { document.getElementById('contact').scrollIntoView() }}>Demander une démonstration</p>
                    <h3>Inscrivez-vous dès aujourd'hui et commencez à explorer des possibilités infinies.</h3>
                </div>
                <div className="landing__cta_btn">
                    <button type="button" onClick={() => { window.location.replace('/auth?type=signup'); }}>Essayer gratuitement</button>
                </div>
            </div>
        </div>
    );
};

export default CTA;