import React from 'react';

const WidgetInfo = ({ ico, title, text }) => {
    return (
        <div className="app__sectiondata_widget_value_info">
            <div className="app__sectiondata_widget_value_info_ico">{ico}</div>
            <div className="app__sectiondata_widget_value_info_text"><div className="app__sectiondata_widget_value_info_text_title">{ico} {title}</div>{text}</div>
        </div>
    );
};

export default WidgetInfo;