// Import dependencies
import React, { useEffect } from 'react';
import { browserName } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';


// Import CSS
import './Home.css';

// Import components
import Navbar from '../../../components/landing/navbar/Navbar';
import Header from '../../../components/landing/header/Header';
import Partners from '../../../components/landing/partners/Partners';
import Solution from '../../../components/landing/solution/Solution';
import Features from '../../../components/landing/features/Features';
import Steps from '../../../components/landing/steps/Steps';
import CTA from '../../../components/landing/cta/CTA';
import Contact from '../../../components/landing/contact/Contact';
import Footer from '../../../components/landing/footer/Footer';

const Home = () => {

    // Set hooks
    const [searchParams, setSearchParams] = useSearchParams();

    function getOrigin() {
        return searchParams.get("origin");
    }

    // Check if user is using mobile app
    if (browserName === "Mobile Safari" && window.navigator.standalone === true) {
        // Redirect to mobile home page
        window.location.replace('/mobile');
    }
    else {
        return (
            <div className='landing__home'>
                <Navbar origin={getOrigin()} />
                <Header />
                <Partners />
                <Solution />
                <Features />
                <Steps />
                <CTA />
                <Contact />
                <Footer />
            </div>
        );
    }
};

export default Home;