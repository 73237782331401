// Import dependencies
import React from 'react';

// Import CSS & Assets
import './ModalIosApp.css';
import logo from '../../../assets/logo/logo_app.svg';
import close from '../../../assets/ico/ico_close.svg';
import send from '../../../assets/ico/ico_send.svg';
import add from '../../../assets/ico/ico_add_home_screen.svg';

const ModalIosApp = ({ handleClose }) => {
    return (
        <div className="modal__background">
            <div className="modal__container scale-up-center">
                <div onClick={() => { handleClose(false) }} className="modal__close_container">
                    <img src={close} alt="close" />
                </div>
                <div className="modal__body_container">
                    <img className='modal__body_logo' src={logo} alt="logo_app" />
                    <h3>Proplab</h3>
                    <span className='modal__body_text'>Ajoutez cette application sur votre écran d'accueil pour une meilleure expérience.</span>
                    <span className='modal__body_instruction'>Touchez <img src={send} alt="send" /> puis 'Sur l'écran d'accueil <img src={add} alt="add" />'</span>
                </div>
            </div>
        </div>
    );
};

export default ModalIosApp;