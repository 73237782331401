// Import dependencies
import React, { useState } from 'react';
import { reset } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// Import CSS & assets
import './ResetForm.css';

// Import components
import Input from '../../common/input/Input';

const ResetForm = () => {

    // Get activation token from urm
    const { resetToken } = useParams();

    // Set global variables
    const initialFormData = {
        password: '',
        confirmPassword: ''
    };

    // Set global funcitons
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Set hooks
    // Form
    const [formData, setFormData] = useState(initialFormData);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isConfirmValid, setIsConfirmValid] = useState(false);
    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadedSuccess, setIsLoadedSuccess] = useState(false);
    const [isLoadedFail, setIsLoadedFail] = useState(false);
    // Error
    const [errorMessage, setErrorMessage] = useState('');

    // Set specific functions
    // Loading
    const handleLoading = () => {
        setIsLoading(true);
        setIsLoadedSuccess(false);
        setIsLoadedFail(false);
    }
    const handleLoadedSuccess = () => {
        setIsLoading(false);
        setIsLoadedSuccess(true);
        // Redirect if its sign in success
        setTimeout(() => {
            navigate('/auth?type=signin');
        }, 4000);
    }
    const handleSuccess = (msg) => {
        console.log("SUCCESS");
        handleLoadedSuccess();
    }
    const handleLoadedFail = () => {
        setIsLoading(false);
        setIsLoadedFail(true);
        setTimeout(() => {
            setIsLoadedSuccess(false);
            setIsLoadedFail(false);
        }, 4000);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleLoadedFail();
    }
    // Change input values
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    // Show / hide passwords
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    // Check confirm password
    const checkConfirm = () => {
        if (formData.password === formData.confirmPassword && formData.password !== '') {
            setIsConfirmValid(true);
        }
        else {
            setIsConfirmValid(false);
        }
    }
    const handleKeyUp = (e) => {
        checkConfirm();
    }
    // Submit
    const handleSubmit = (e) => {
        console.log("DATA:", formData);
        e.preventDefault(); // no refresh
        // Set loading button
        handleLoading();
        // Check all required fields are not empty
        var test = false;
        if (formData.password !== "" && formData.confirmPassword !== "") { test = true; }
        console.log(test);
        if (test) {
            console.log("SUBMIT RESET", formData);
            // Send reset
            dispatch(reset(formData, resetToken, handleSuccess, handleFail)); // navigate sert a faire une redirection après

        }
        else {
            handleFail({ response: { data: { message: "Veuillez remplir tous les champs." } } });
        }
    }

    return (
        <>
            <div className={`auth__resetform ${isLoadedSuccess && "auth__resetform_hidden"}`}>
                <h5>Changer mon mot de passe</h5>
                <form autoComplete='off' noValidate onSubmit={handleSubmit}>
                    <div>
                        <div className='auth__resetform_box_password'>
                            <Input type={showPassword ? 'text' : 'password'} name='password' label='Nouveau mot de passe' handleChange={handleChange} handleShow={handleShowPassword} handleKeyUp={handleKeyUp} />
                        </div>
                        <div className='auth__resetform_box_password'>
                            <Input type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' label={isConfirmValid ? 'Vérifier le nouveau mot de passe ✔' : 'Vérifier le nouveau mot de passe'} handleChange={handleChange} handleShow={handleShowConfirmPassword} handleKeyUp={handleKeyUp} />
                        </div>
                        <div className="auth__resetform_box_button">
                            <input className={`auth__resetform_button ${isLoading ? "loading" : ""} ${isLoadedSuccess ? "loadedSuccess" : ""} ${isLoadedFail ? "loadedFail" : ""}`} type="submit" value={isLoading ? "" : isLoadedSuccess ? "✔" : isLoadedFail ? "🗙" : 'Changer mon mot de passe'} />
                            <p className='auth__resetform_message'>{isLoadedFail ? errorMessage : "‎"}</p>
                        </div>
                    </div>
                    <p className='auth__resetform_link' onClick={() => { navigate('/auth?type=signin'); }}>Vous vous souvenez de votre mot de passe? Connectez-vous.</p >
                </form>
                {/* <button onClick={handleLoading}>LOADING</button>
                <button onClick={handleLoadedFail}>FAIL</button>
                <button onClick={handleLoadedSuccess}>SUCCESS</button> */}
            </div>
            <div className={`auth__resetform_final ${isLoadedSuccess && "auth__resetform_final_visible"}`}>
                <h1>Mot de passe modifié avec succès!</h1>
                <h4>Vous allez être redirigé vers la page de connexion.</h4>
            </div>
        </>
    );
};

export default ResetForm;