import React from 'react';

// Import CSS & Assets
import check from '../../../assets/ico/ico_toaster_check.svg';
import error from '../../../assets/ico/ico_toaster_error.svg';

const Toaster = ({ item }) => {
    return (
        <div className={`toaster__container ${item?.status === "success" ? "toaster__container_done" : ""} ${item?.status === "error" ? "toaster__container_error" : ""} slide-left`}>
            <div className="toaster__title">{item?.title}</div>
            <div id="toaster__text" className="toaster__text">{item?.text}</div>
            <div className="toaster__bottom">
                <div className="toaster__progress_container">
                    <div className={`toaster__progress_bar ${(item?.status === "success" || item?.status === "error") ? "toaster__progress_bar_full" : ""}`}></div>
                    <div className="toaster__progress_bar_ph"></div>
                </div>
                <div className="toaster__progress_icon">
                    {item?.status === "success" ?
                        <img src={check} alt="" />
                        :
                        item?.status === "error" ?
                            <img src={error} alt="" />
                            :
                            <div className="toaster__progress_loading"></div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Toaster;