import React, { useEffect, useState } from 'react';

// Import CSS & assets
import './exportation.css';

const Exportation = ({ capacity }) => {

    const [confLand, setConfLand] = useState(true);
    const [confTopo, setConfTopo] = useState(false);
    const [confBuildings, setConfBuildings] = useState(false);

    return (
        <div className="app__exportation_container scale-up-topright">
            <div className="app__exportation_title">Export IFC</div>
            <div className="app__exportation_3d_container"></div>
            <div>
                <div className="app__map_layer">
                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_land" checked={confLand} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_land" onClick={() => { setConfLand(!confLand) }}>Toggle</label></div>
                    <div className="app__map_layer_label">Terrain</div>
                </div>
                <div className="app__map_layer">
                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_topo" checked={confTopo} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_topo" onClick={() => { setConfTopo(!confTopo) }}>Toggle</label></div>
                    <div className="app__map_layer_label">Topographie</div>
                </div>
                <div className="app__map_layer">
                    <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="export_switch_buildings" checked={confBuildings} /><label className="app__map_layer_checkbox_label" htmlFor="export_switch_buildings" onClick={() => { setConfBuildings(!confBuildings) }}>Toggle</label></div>
                    <div className="app__map_layer_label">Constructions existantes</div>
                </div>
            </div>
        </div>
    );
};

export default Exportation;