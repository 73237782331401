// Import dependencies
import React from 'react';

// Import CSS & Assets
import './ErrorPage.css';
import oups from '../../../assets/oups.svg'

const ErrorPage = ({ setIsError, errorMsg, version }) => {

    function prepareEmail(error_data) {
        var subject = "Remontée de bug sur PropLab";
        var body = "Données concernant l'erreur:\r\n\r\nURL = " + window.location.href + "\r\nLOGS = " + error_data.toString();
        var uri = "mailto:contact@fractal-buildings.com?subject=";
        uri += encodeURIComponent(subject);
        uri += "&body=";
        uri += encodeURIComponent(body);
        window.open(uri);
    }

    console.log("ERROR", errorMsg);

    return (
        <div className="error__container">
            <div className="error__content_container">
                {/* <div className="error__content_title">OUPS !</div> */}
                <img className="error__content_title_img" src={oups} alt="" />
                <div className="error__content_subtitle">Une erreur est survenue</div>
                <div className="error__content_text">Notre application est en version bêta, nous travaillons activement pour corriger les derniers bugs 💪<br></br>N'hésitez pas à <span className="error__link" onClick={() => { prepareEmail(errorMsg) }}>contacter notre équipe</span> si le problème persiste.</div>
                {/* <div className="error__content_text">{errorMsg}</div> */}
                <div className="error__content_button_container">
                    <div className="error__content_button" onClick={() => { window.location.reload(false); }}>Recharger la page</div>
                    <div className="error__content_button" onClick={() => { window.location.href = "/dashboard"; }}>Retourner au Dashboard</div>
                </div>
            </div>
            <div className="error__version" onClick={() => { setIsError(false) }}>{version}</div>
        </div>
    );
};

export default ErrorPage;