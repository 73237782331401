import React from 'react';

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';

import { Scatter } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const ScatterChart = ({ chartData, chartOptions }) => {
    if (chartData?.datasets) {
        return (
            <Scatter options={chartOptions} data={chartData} />
        );
    }
};

export default ScatterChart;