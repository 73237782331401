import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, { Background, Controls, addEdge, applyEdgeChanges, applyNodeChanges, ReactFlowProvider, useReactFlow } from 'react-flow-renderer';

// Import custom nodes
import { Node_Rule_Placeholder, Node_Condition_Placeholder, Node_Perimeter_Placeholder, Node_Placeholder } from './nodes';
import { Node_Rule_Buildable_Volume, Node_Rule_Unbuildable_Volume, Node_Rule_Buildable_Area, Node_Rule_Buildable_Area_Total, Node_Rule_Unbuildable_Area, Node_Rule_Building_Height, Node_Rule_Facade_Height, Node_Rule_Level_Height, Node_Rule_Level_Nb, Node_Rule_Building_Offset, Node_Rule_Roof_Angle, Node_Rule_BoundAlign, Node_Rule_Parking_Area_Total, Node_Rule_Parking_Type, Node_Rule_Level_Offset } from './nodes';
import { Node_Condition } from './nodes';
import { Node_Perimeter } from './nodes';
import { Node_Input_String, Node_Input_Number, Node_Input_Boolean, Node_Input_Property } from './nodes';
import { Node_UserInput_Number, Node_UserInput_Number_Range, Node_UserInput_Boolean, Node_UserInput_Bounds, Node_UserInput_Lands, Node_UserInput_PLUZone, Node_UserInput_Levels, Node_UserInput_Buildings, Node_UserInput_Draw_Point, Node_UserInput_Draw_Line, Node_UserInput_Draw_Polygon } from './nodes';
import { Node_Operator_Addition, Node_Operator_Substraction, Node_Operator_Multiplication, Node_Operator_Division, Node_Operator_Comparison, Node_Operator_Logical, Node_Operator_Condition, Node_Operator_Calcul_Area } from './nodes';
import { Node_2DOperator_Buffer, Node_2DOperator_Translation, Node_2DOperator_Offset, Node_2DOperator_Union, Node_2DOperator_Difference, Node_2DOperator_Intersect } from './nodes';
import { Node_3DOperator_Extrusion, Node_3DOperator_ExtrusionPath, Node_3DOperator_Watch } from './nodes';
import { Node_Study_Land, Node_Study_Lands, Node_Study_Buildable_Height, Node_Study_BoundTypes, Node_Study_BoundItems, Node_Study_Bounds, Node_Study_Perimeter, Node_Study_PLUZone, Node_Study_Levels, Node_Study_Building_Area_Total, Node_Study_Existing_Buildings, Node_Study_Buildings_Footprint, Node_Study_Buildings_Height } from './nodes';
import { Node_2DElement_Point, Node_2DElement_Line, Node_2DElement_Polygon, Node_2DElement_Rectangle, Node_2DElement_Triangle, Node_2DElement_Trapeze, Node_2DElement_Trapeze_5, Node_2DElement_Trapeze_6 } from './nodes';

// Add custom nodes to types
const nodeTypes = {
    // PLACEHOLDERS
    Node_Rule_Placeholder: Node_Rule_Placeholder,
    Node_Condition_Placeholder: Node_Condition_Placeholder,
    Node_Perimeter_Placeholder: Node_Perimeter_Placeholder,
    Node_Placeholder: Node_Placeholder,
    // RULES
    Node_Rule_Buildable_Volume: Node_Rule_Buildable_Volume,
    Node_Rule_Unbuildable_Volume: Node_Rule_Unbuildable_Volume,
    Node_Rule_Buildable_Area: Node_Rule_Buildable_Area,
    Node_Rule_Buildable_Area_Total: Node_Rule_Buildable_Area_Total,
    Node_Rule_Unbuildable_Area: Node_Rule_Unbuildable_Area,
    Node_Rule_Building_Height: Node_Rule_Building_Height,
    Node_Rule_Facade_Height: Node_Rule_Facade_Height,
    Node_Rule_Level_Height: Node_Rule_Level_Height,
    Node_Rule_Level_Nb: Node_Rule_Level_Nb,
    Node_Rule_Building_Offset: Node_Rule_Building_Offset,
    Node_Rule_Roof_Angle: Node_Rule_Roof_Angle,
    Node_Rule_BoundAlign: Node_Rule_BoundAlign,
    Node_Rule_Parking_Area_Total: Node_Rule_Parking_Area_Total,
    Node_Rule_Parking_Type: Node_Rule_Parking_Type,
    Node_Rule_Level_Offset: Node_Rule_Level_Offset,
    // CONDITIONS
    Node_Condition: Node_Condition,
    // PERIMETERS
    Node_Perimeter: Node_Perimeter,
    // NODES
    // INPUTS
    Node_Input_String: Node_Input_String,
    Node_Input_Number: Node_Input_Number,
    Node_Input_Boolean: Node_Input_Boolean,
    Node_Input_Property: Node_Input_Property,
    // USER INPUTS
    Node_UserInput_Number: Node_UserInput_Number,
    Node_UserInput_Number_Range: Node_UserInput_Number_Range,
    Node_UserInput_Boolean: Node_UserInput_Boolean,
    Node_UserInput_Bounds: Node_UserInput_Bounds,
    Node_UserInput_Lands: Node_UserInput_Lands,
    Node_UserInput_PLUZone: Node_UserInput_PLUZone,
    Node_UserInput_Levels: Node_UserInput_Levels,
    Node_UserInput_Buildings: Node_UserInput_Buildings,
    Node_UserInput_Draw_Point: Node_UserInput_Draw_Point,
    Node_UserInput_Draw_Line: Node_UserInput_Draw_Line,
    Node_UserInput_Draw_Polygon: Node_UserInput_Draw_Polygon,
    // OPERATORS
    Node_Operator_Addition: Node_Operator_Addition,
    Node_Operator_Substraction: Node_Operator_Substraction,
    Node_Operator_Multiplication: Node_Operator_Multiplication,
    Node_Operator_Division: Node_Operator_Division,
    Node_Operator_Comparison: Node_Operator_Comparison,
    Node_Operator_Logical: Node_Operator_Logical,
    Node_Operator_Condition: Node_Operator_Condition,
    Node_Operator_Calcul_Area: Node_Operator_Calcul_Area,
    // 2D OPERATORS
    Node_2DOperator_Buffer: Node_2DOperator_Buffer,
    Node_2DOperator_Translation: Node_2DOperator_Translation,
    Node_2DOperator_Offset: Node_2DOperator_Offset,
    Node_2DOperator_Union: Node_2DOperator_Union,
    Node_2DOperator_Difference: Node_2DOperator_Difference,
    Node_2DOperator_Intersect: Node_2DOperator_Intersect,
    // 3D OPERATORS
    Node_3DOperator_Extrusion: Node_3DOperator_Extrusion,
    Node_3DOperator_ExtrusionPath: Node_3DOperator_ExtrusionPath,
    Node_3DOperator_Watch: Node_3DOperator_Watch,
    // STUDY ELEMENTS
    Node_Study_Land: Node_Study_Land,
    Node_Study_Lands: Node_Study_Lands,
    Node_Study_Buildable_Height: Node_Study_Buildable_Height,
    Node_Study_BoundTypes: Node_Study_BoundTypes,
    Node_Study_BoundItems: Node_Study_BoundItems,
    Node_Study_Bounds: Node_Study_Bounds,
    Node_Study_Perimeter: Node_Study_Perimeter,
    Node_Study_PLUZone: Node_Study_PLUZone,
    Node_Study_Levels: Node_Study_Levels,
    Node_Study_Building_Area_Total: Node_Study_Building_Area_Total,
    Node_Study_Existing_Buildings: Node_Study_Existing_Buildings,
    Node_Study_Buildings_Footprint: Node_Study_Buildings_Footprint,
    // Node_Study_Buildings_Height: Node_Study_Buildings_Height,
    // 2D ELEMENTS
    Node_2DElement_Point: Node_2DElement_Point,
    Node_2DElement_Line: Node_2DElement_Line,
    Node_2DElement_Polygon: Node_2DElement_Polygon,
    Node_2DElement_Rectangle: Node_2DElement_Rectangle,
    Node_2DElement_Triangle: Node_2DElement_Triangle,
    Node_2DElement_Trapeze: Node_2DElement_Trapeze,
    Node_2DElement_Trapeze_5: Node_2DElement_Trapeze_5,
    Node_2DElement_Trapeze_6: Node_2DElement_Trapeze_6,
};

// Variables
var currentEdges = [];
var currentNodes = [];
var currentHelpMode = true;
var currentFitting = false;





const Flow = ({ block_list, rule_list, capacity, helpMode, newRuleComposition, setNewRuleComposition, reactFlowWrapper, currentType }) => {



    // UI React Flow
    // const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const { fitView } = useReactFlow();
    const [isFittingAllowed, SetIsFittingAllowed] = useState(false);

    // Fitting View
    useEffect(() => {
        currentFitting = isFittingAllowed;
    }, [isFittingAllowed])


    // const { setViewport, zoomIn, zoomOut, fitView } = useReactFlow();

    const handleFitView = () => {
        console.log("Fit View");
        fitView({ duration: 800 });
    }

    // Help Mode
    useEffect(() => {
        currentHelpMode = helpMode;
    }, [helpMode])


    // _____ NODE FUNCTIONS : CONNECTION

    // // Connection start
    // const onConnectStart = (_, { nodeId, handleType, handleId }) => {
    //     // Get node
    //     var sourceType = "";
    //     for (var i = 0; i < currentNodes.length; i++) {
    //         if (currentNodes[i].id === nodeId) {
    //             for (var j = 0; j < currentNodes[i].data[handleType].length; j++) {
    //                 if (currentNodes[i].data[handleType][j].id === handleId) {
    //                     sourceType = currentNodes[i].data[handleType][j].acceptance;
    //                     break;
    //                 }
    //             }
    //             break;
    //         }
    //     }
    //     console.log("Selected type : ", sourceType);
    // };

    // // Connection End
    // const onConnectEnd = (event) => console.log('on connect end', event);

    // Conection validation
    const isValidConnection = (connection) => {
        // Get source acceptance & target accepted
        var sourceAcceptance = "";
        var targetAccepted = [];
        for (var i = 0; i < currentNodes.length; i++) {
            if (currentNodes[i].id === connection.source) {
                for (var j = 0; j < currentNodes[i].data.source.length; j++) {
                    if (currentNodes[i].data.source[j].id === connection.sourceHandle) {
                        sourceAcceptance = currentNodes[i].data.source[j].acceptance;
                        break;
                    }
                }
            }
            else if (currentNodes[i].id === connection.target) {
                for (var j = 0; j < currentNodes[i].data.target.length; j++) {
                    if (currentNodes[i].data.target[j].id === connection.targetHandle) {
                        targetAccepted = currentNodes[i].data.target[j].accepted;
                        break;
                    }
                }
            }
        }
        console.log("sourceAcceptance", sourceAcceptance);
        console.log("targetAccepted", targetAccepted);

        if (sourceAcceptance === "tous types" || targetAccepted[0] === "tous types" || targetAccepted.includes(sourceAcceptance)) {
            return true;
        }
        else {
            return false;
        }
    };



    // _____ NODE FUNCTIONS : HANDLERS

    // Handle Change
    const handleChange = (id, key, value) => {

        // Update current node
        updateNodeByID(id, key, value);

    };

    // Handle Replace
    const handleReplace = (id, node, accepeted) => {
        console.log("Replace", id);
        console.log("to", node);
        // Replace node
        const new_id = Date.now().toString();
        replaceNodeByID(id, new_id, node);
        // Add parent nodes
        if (currentHelpMode === true && node?.data?.target && node.data.target.length > 0) {
            var nodeHeight = 105;
            if (node.node_type.includes("Node_Rule_")) {
                nodeHeight = 190;
            }
            const positions = getParentPositions(id, new_id, node.data.target.length, nodeHeight);
            console.log("positions", positions);
            var index = 0;
            node.data.target.forEach(target => {
                addNodePlaceholder(positions[index], accepeted[index], new_id, index);
                index++;
            });
        }
    }

    // Handle Remove
    const handleDelete = (id) => {
        // Get node index
        var node_index = -1;
        for (var i = 0; i < currentNodes.length; i++) {
            if (currentNodes[i].id === id) {
                node_index = i;
                break;
            }
        }

        // Reset children values
        currentNodes[node_index].data.source.forEach(source => {
            var key = source.id;
            updateNodeByID(id, key, null);
        });

        // Remove node
        removeNode(id);

        // Add placeholder if needed
        if (currentNodes[node_index].type.includes("Node_Rule_")) {
            // Get current position
            var position = { x: 0, y: 0 };
            for (var i = 0; i < currentNodes.length; i++) {
                if (currentNodes[i].id === id) {
                    position = currentNodes[i].position;
                }
            }
            // Add rule placeholder
            addRulePlaceholder(position);
        }
    }

    const handleDeleteRule = (id) => {
        // Get current position
        var position = { x: 0, y: 0 };
        for (var i = 0; i < currentNodes.length; i++) {
            if (currentNodes[i].id === id) {
                position = currentNodes[i].position;
            }
        }
        // Delete rule
        handleDelete(id);
        // Add rule placeholder
        addRulePlaceholder(position);
    }


    // _____ NODE FUNCTIONS : ADD

    // Node add
    const addNode = (position, group_id, node_type) => {
        // Get data
        var node = null;
        var group_list = Object.keys(block_list);
        for (var i = 0; i < group_list.length; i++) {
            if (block_list[group_list[i]].id === group_id) {
                for (var j = 0; j < block_list[group_list[i]].items.length; j++) {
                    if (block_list[group_list[i]].items[j].node_type === node_type) {
                        node = { ...block_list[group_list[i]].items[j] };
                        break;
                    }
                }
                break;
            }
        }

        // Set default values
        node.data.target.forEach(target => {
            target.value = target.default;
        });
        node.data.source.forEach(source => {
            source.value = source.default;
        });
        console.log("node", node);

        // Add functions
        node.data.handleChange = handleChange;
        node.data.handleDelete = handleDelete;
        node.data.capacity = capacity;

        // Create model
        const newNode = {
            id: (Math.random() * Date.now()).toString(),
            type: node_type,
            dragHandle: '.ruleeditor__node_title',
            position,
            data: node.data,
        };

        // Update node list
        setNodes((nds) => nds.concat(newNode));
    };

    // Node placeholder add
    const addNodePlaceholder = (position, accepted, fromId, fromIndex) => {
        // Create model
        const newNode = {
            id: (Math.random() * Date.now()).toString(),
            type: "Node_Placeholder",
            position,
            data: {
                handleReplace: handleReplace,
                accepted: accepted,
                block_list: block_list,
                autoEdge: {
                    nodeId: fromId,
                    nodeTarget: "target_" + fromIndex
                }
            },
        };

        // Update node list
        setNodes((nds) => nds.concat(newNode));
    };

    // Rule placeholder add
    const addRulePlaceholder = (position) => {
        // Create model
        const newNode = {
            id: (Math.random() * Date.now()).toString(),
            type: "Node_Rule_Placeholder",
            position,
            data: {
                handleReplace: handleReplace,
                rule_list: rule_list
            },
        };

        // Update node list
        setNodes((nds) => nds.concat(newNode));
    };


    // _____ NODE FUNCTIONS : REPLACE

    // Node replace
    const replaceNodeByID = (id, new_id, newNode) => {
        var connection = null;
        // Update node
        setNodes((nds) =>
            nds.map((node) => {
                // If node.id different from id : return node without changes
                if (node.id !== id) {
                    return node;
                }
                // else : update to new node
                console.log("node", node);
                console.log("newNode", newNode);
                var placeholderHeight = 105;
                if (node.type === "Node_Rule_Placeholder") {
                    placeholderHeight = 190;
                }
                const newPosition = {
                    x: node.position.x,
                    y: node.position.y + (placeholderHeight / 2) - (newNode.data.height / 2)
                }
                const newNodeItem = {
                    id: new_id,
                    type: newNode.node_type,
                    dragHandle: '.ruleeditor__node_title',
                    position: newPosition,
                    data: newNode.data,
                };

                // Set default values
                newNodeItem.data.target.forEach(target => {
                    target.value = target.default;
                });
                newNodeItem.data.source.forEach(source => {
                    source.value = source.default;
                });
                console.log("node", node);

                // Add functions
                newNodeItem.data.handleChange = handleChange;
                newNodeItem.data.handleDelete = handleDelete;
                newNodeItem.data.capacity = capacity;

                // Check if auto edge
                if (node?.data?.autoEdge) {
                    connection = {
                        source: new_id,
                        sourceHandle: "source_0",
                        target: node.data.autoEdge.nodeId,
                        targetHandle: node.data.autoEdge.nodeTarget
                    }
                }

                // Send new node
                return newNodeItem
            })
        );
        // Set connection
        if (connection !== null) {
            console.log("AUTO EDGE", connection);
            setTimeout(() => {
                setConnection(connection);
            }, "200")
        }
        // Allow fit view
        SetIsFittingAllowed(true);
    }


    // _____ NODE FUNCTIONS : UPDATE

    // Node update
    const updateNodeByID = (id, key, value) => {
        console.log("Update node", id);
        console.log(key);
        console.log(value);

        // Update node list
        setNodes((nds) =>
            // Go th each node to get the one that had to be updated
            nds.map((node) => {
                // If node.id different from id : return node without changes
                if (node.id !== id) {
                    return node;
                }

                // else : updated data
                var data = { ...node.data };
                // data[key] = value;

                // Update source
                var data_source = [];
                for (var i = 0; i < data.source.length; i++) {
                    if (data.source[i].id === key) {
                        var newSource = { ...data.source[i] };
                        newSource.value = value;
                        data_source.push(newSource);
                    }
                    else {
                        data_source.push(data.source[i]);
                    }
                }
                data.source = data_source;

                // Update target
                var data_target = [];
                for (var i = 0; i < data.target.length; i++) {
                    if (data.target[i].id === key) {
                        var newTarget = { ...data.target[i] };
                        newTarget.value = value;
                        data_target.push(newTarget);
                    }
                    else {
                        data_target.push(data.target[i]);
                    }
                }
                data.target = data_target;

                // Creat model
                var newNode = { ...node, data: data };

                return newNode;
            })
        );

        // Update its children
        var children = getChildrenNodeByID(id);
        children.map((child) => {
            if (child.sourceHandle === key) {
                updateNodeByID(child.target, child.targetHandle, value);
            }
        });
    }


    // _____ NODE FUNCTIONS : REMOVE

    // Node remove
    const removeNode = (id) => {

        // Delete edges
        var newEdgeList = [];
        currentEdges.forEach(edge => {
            if (edge.source === id || edge.target === id) {
            }
            else {
                newEdgeList.push(edge);
            }
        });
        setEdges((eds) => [...newEdgeList]);

        // Wait until edges are removed to remove node
        setTimeout(() => {
            // Get node index
            var node_index = -1;
            for (var i = 0; i < currentNodes.length; i++) {
                if (currentNodes[i].id === id) {
                    node_index = i;
                    break;
                }
            }
            // Drop from nodeList
            var newNodeList = [];
            currentNodes.forEach(node => {
                if (node.id !== id) {
                    newNodeList.push(node);
                }
            });
            // Update list
            setNodes((nds) => [...newNodeList]);
        }, "200")
    }


    // _____ NODE FUNCTIONS : HELPERS

    // Node helper : get children
    const getChildrenNodeByID = (id) => {
        var children = [];
        currentEdges.map((edge) => {
            if (edge.source === id) {
                children.push(edge);
            }
        })
        return children;
    }

    // Node helper : get parent position
    const getParentPositions = (id, new_id, nb_parents, current_height) => {
        // const current_height = document.getElementById("block_" + new_id).offsetHeight;
        const x_offset = 90;
        const x_size = 180;
        const y_offset = 45;
        const y_size = 105;
        const y_total = y_size * nb_parents + y_offset * (nb_parents - 1);
        // var current_height = 105;
        var current_position = {};
        currentNodes.forEach(node => {
            if (node.id === id) {
                current_position = node.position;
            }
            else if (node.id === new_id) {
                current_height = node.data.height;
            }
        });
        const y_start = current_position.y + (current_height / 2) - (y_total / 2);
        var positions = [];
        for (var i = 0; i < nb_parents; i++) {
            positions.push({
                x: current_position.x - (x_size + x_offset),
                y: y_start + i * (y_size + y_offset)
            })
        }
        return positions;
    }


    // EDGE FUNCTION : REMOVE

    const removeEdgeFromTarget = (target, targetHandle) => {
        var newEdgeList = [];
        currentEdges.forEach(edge => {
            if (edge.target === target && edge.targetHandle === targetHandle) {
            }
            else {
                newEdgeList.push(edge);
            }
        });
        setEdges((eds) => [...newEdgeList]);
    }
















    // // RULE LIST
    // const rule_list = {
    //     buildable_volume: {
    //         label: "Volume constructible",
    //         items: [
    //             {
    //                 node_type: "Node_Rule_Buildable_Volume",
    //                 group_id: "buildable_volume",
    //                 data: {
    //                     // Global data
    //                     label: "Volume constructible qui est très long",
    //                     description: "Définition d'un volume en trois dimensions dans lequel la construction est autorisée",
    //                     capacity: capacity,
    //                     // Functions
    //                     handleChange: handleChange,
    //                     handleDelete: handleDeleteRule,
    //                     // Targets
    //                     target: [
    //                         {
    //                             label: "Volume",
    //                             id: "target_0",
    //                             value: null,
    //                             accepted: [
    //                                 "volume",
    //                             ]
    //                         },
    //                     ],
    //                     // Source
    //                     source: [],
    //                 }
    //             }
    //         ]
    //     }
    // };


    // DRAG N DROP BLOCK

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();


            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            const types = type.split("--");
            console.log("dataTransfer", event.dataTransfer);
            console.log("types", types);

            if (types.length !== 2) {
                console.log("INVALID DROP");
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            addNode(position, types[0], types[1]);

        },
        [reactFlowInstance]
    );



    const initialNodes = [
        {
            id: '10',
            type: 'Node_Rule_Placeholder',
            data: { handleReplace: handleReplace, rule_list: rule_list },
            position: { x: 0, y: 0 },
        },
    ];
    const initialNodes_Condition = [
        {
            id: '10',
            type: 'Node_Condition_Placeholder',
            data: { handleReplace: handleReplace, rule_list: rule_list },
            position: { x: 0, y: 0 },
        },
    ];
    const initialNodes_Perimeter = [
        {
            id: '10',
            type: 'Node_Perimeter_Placeholder',
            data: { handleReplace: handleReplace, rule_list: rule_list },
            position: { x: 0, y: 0 },
        },
    ];

    const initialEdges = [
        // { id: 'e4-5', source: '4', target: '5', animated: true },
        // { id: 'e2-3', source: '2', target: '3', animated: true },
    ];


    // Reset
    useEffect(() => {
        console.log("currentNodes", currentNodes);
        console.log("newRuleComposition", newRuleComposition);
        if (newRuleComposition?.nodes.length === 0) {
            console.log("RESET NODES");
            if (currentType === "rule") {
                setNodes(initialNodes);
                setEdges(initialEdges);
            }
            else if (currentType === "condition") {
                setNodes(initialNodes_Condition);
                setEdges(initialEdges);
            }
            if (currentType === "perimeter") {
                setNodes(initialNodes_Perimeter);
                setEdges(initialEdges);
            }
            // Fit view
            handleFitView();
        }
        else if ((newRuleComposition?.nodes.length !== currentNodes.length && newRuleComposition?.edges.length !== currentEdges.length) || (newRuleComposition?.nodes.length > 0 && currentNodes.length > 0 && newRuleComposition?.nodes[0]?.data?.source && currentNodes[0]?.data?.source && newRuleComposition?.nodes[0].data.source[0].value !== currentNodes[0].data.source[0].value)) {
            console.log("MODIFY RULE NODES");
            var newNodes = [...newRuleComposition.nodes];
            newNodes.forEach(node => {
                // Add functions
                node.data.handleChange = handleChange;
                node.data.handleDelete = handleDelete;
                node.data.capacity = capacity;
            });
            setNodes(newNodes);
            setEdges(newRuleComposition.edges);
        }
    }, [newRuleComposition])




    // Set inital hooks
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);

    // On change handlers
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    useEffect(() => {
        console.log("NODES CHANGE", nodes);
        currentNodes = nodes;
        setNewRuleComposition({
            nodes: currentNodes,
            edges: currentEdges
        });
        if (currentHelpMode === true) {
            // if (currentHelpMode === true && currentFitting === true) {
            // console.log("Fitting allowed", currentFitting);
            handleFitView();
            SetIsFittingAllowed(false);
        }
    }, [nodes])
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    // const onConnect = useCallback(
    //     (connection) => { setEdges((eds) => addEdge({ ...connection, animated: true }, eds)) },
    //     [setEdges]
    // );
    const onConnect = useCallback(
        (connection) => {
            // Create new edge array
            var newEdgeList = [];
            // Loop to get only edge not already connected to target
            currentEdges.forEach(edge => {
                if (edge.target === connection.target && edge.targetHandle === connection.targetHandle) {
                }
                else {
                    newEdgeList.push(edge);
                }
            });
            // Push new array
            newEdgeList.push({ ...connection, id: 'edge_' + (Math.random() * Date.now()).toString(), animated: true });
            // Set new list as official edges
            setEdges((eds) => [...newEdgeList]);
        },
        [setEdges]
    );
    const setConnection = (connection) => {
        console.log("CONNECTION", connection);
        if (isValidConnection(connection)) {
            onConnect(connection);
            // Get source data
            var source_value = {};
            currentNodes.map((node) => {
                if (node.id === connection.source) {
                    // source_value = node.data[connection.sourceHandle];
                    node.data.source.forEach(source => {
                        if (source.id === connection.sourceHandle) {
                            source_value = source.value;
                        }
                    });
                }
            })
            // Update target node
            handleChange(connection.target, connection.targetHandle, source_value);
        }
    }
    useEffect(() => {
        console.log("EDGES CHANGE", edges);
        currentEdges = edges;
        setNewRuleComposition({
            nodes: currentNodes,
            edges: currentEdges
        });
    }, [edges])



    return (
        <ReactFlow nodes={nodes} edges={edges} zoomOnScroll={true} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} onConnect={setConnection} nodeTypes={nodeTypes} onDragOver={onDragOver} onDrop={onDrop} onInit={setReactFlowInstance} fitView>
            <Controls />
            <Background />
        </ReactFlow>
    );

};



const RuleEditor = ({ block_list, rule_list, capacity, helpMode, newRuleComposition, setNewRuleComposition, currentType }) => {

    const reactFlowWrapper = useRef(null);




    return (
        // <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ width: '100%', height: '100%' }}>
        //     <ReactFlowProvider>
        //         <ReactFlow nodes={nodes} edges={edges} zoomOnScroll={false} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} onConnect={setConnection} nodeTypes={nodeTypes} onDragOver={onDragOver} onDrop={onDrop} onInit={setReactFlowInstance} fitView>
        //             <Controls />
        //             <Background />
        //             <button onClick={() => zoomIn({ duration: 800 })}>zoom in</button>
        //         </ReactFlow>
        //     </ReactFlowProvider>
        // </div>
        <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ width: '100%', height: '100%' }}>
            <ReactFlowProvider>
                <Flow block_list={block_list} rule_list={rule_list} capacity={capacity} helpMode={helpMode} newRuleComposition={newRuleComposition} setNewRuleComposition={setNewRuleComposition} reactFlowWrapper={reactFlowWrapper} currentType={currentType} />
            </ReactFlowProvider>
        </div>
    );
};

export default RuleEditor;