import React, { useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Import CSS & assets
import './Nodes.css';
import close from '../../../../assets/ico/ico_close.svg';

// Import external functions
import * as nodeHelpers from '../helpers/NodeHelpers';

const Node_2DOperator_Difference = ({ id, data }) => {

    useEffect(() => {
        // Check if new result
        var value = null;
        if (data.target[0].value !== null && data.target[1].value !== null) {
            var target0 = data.target[0].value;
            var target1 = data.target[1].value;
            value = "Node_2DOperator_Difference[" + target0 + "__" + target1 + "]";
        }

        if (data.source[0].value !== value) {
            data.source[0].value = value;
            data.handleChange(id, "source_0", value);
        }
    }, [data])

    return (
        <div id={"block_" + id} className="ruleeditor__node_container">
            <div className="ruleeditor__node_title">
                <div className="ruleeditor__node_title_label">{data.label}</div>
                <div className="ruleeditor__node_title_close"><img src={close} alt="close_block" onClick={() => (data.handleDelete(id))} /></div>
            </div>
            <div className="ruleeditor__node_center">
                <div className="ruleeditor__node_description">{data.description}</div>
                <div className="ruleeditor__node_body">
                    <div className="ruleeditor__handle_target">{data.target[0].label}</div>
                    <div className={`ruleeditor__handle_target_type ${data.target[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.target[0].accepted.join(" ")}</div>
                    <div className="ruleeditor__handle_target">{data.target[1].label}</div>
                    <div className={`ruleeditor__handle_target_type ${data.target[1].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.target[1].value ? nodeHelpers.nodeInterpretorAcceptance([data.target[1]], data.capacity) : data.target[1].accepted.join(" ")}</div>
                    <div className="ruleeditor__handle_source">{data.source[0].label}</div>
                    {/* <div className="ruleeditor__handle_source">{data.source[0].value ? data.source[0].value : data.source[0].label}</div> */}
                    <div className={`ruleeditor__handle_source_type ${data.source[0].value !== null ? "ruleeditor__handle_type_valid" : ""}`}>{data.source[0].value ? nodeHelpers.nodeInterpretorAcceptance([data.source[0]], data.capacity) : data.source[0].acceptance}</div>
                </div>
            </div>
            <Handle type="target" position={Position.Left} style={{ top: 'calc(100% - 11px - 75px)' }} id="target_0" />
            <Handle type="target" position={Position.Left} style={{ top: 'calc(100% - 11px - 46px)' }} id="target_1" />
            <Handle type="source" position={Position.Right} style={{ top: 'calc(100% - 11px - 17px)' }} id="source_0" />
        </div>
    );
};

export default Node_2DOperator_Difference;