import React from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const HorizontalBarChart = ({ chartData, chartOptions }) => {
    if (chartData?.datasets) {
        return (
            <Bar data={chartData} options={chartOptions} />
        );
    }
};

export default HorizontalBarChart;